import Worker from '../../../entities/worker';
import UseCase from '../../../../core/app/usecase';
import WorkerRepository from '../../../repositories/workerRepository';
import CreateWorkerParameters from './createWorkerParameter';

class CreateWorker implements UseCase<CreateWorkerParameters, Worker> {
	constructor(private repository: WorkerRepository) {}

	async Call({ companyId, worker, photo }: CreateWorkerParameters): Promise<Worker> {
		return await this.repository.createWorker(companyId, worker, photo);
	}
}

export default CreateWorker;
