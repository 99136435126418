import { COLORS } from "../../assets/theme/colors";
import { Text, TextProps } from "@chakra-ui/react";
import { formatDateBasedOnLanguage, formatDateTime } from "../../../utils";

type Props = {
  message?: string;
  author?: string;
  date?: Date;
  subTextProps?: TextProps;
  fontSize?: number;
} & TextProps;

const CommentMessage = ({
  message,
  author,
  date,
  fontSize = 14,
  subTextProps,
  ...textProps
}: Props) => {
  let result = message;
  const regex = /[@#]\[([^\]]+)\]\(([^)]+)\)/g;
  const tags = [];
  let match: any[];
  while ((match = regex.exec(message)) !== null) {
    tags.push({
      id: match[2],
      label: match[1],
      type: match[0].startsWith("@") ? "user" : "document",
    });
  }

  tags.forEach((tag) => {
    result = result.replace(
      `[${tag.label}](${tag.id})`,
      `<strong style="color: ${tag.type === "document" ? COLORS.sikuroBlue : COLORS.darkYellow};">${tag.label}</strong>`,
    );
  });

  return (
    <>
      <Text
        marginRight={2}
        fontSize={fontSize}
        whiteSpace="pre-wrap"
        dangerouslySetInnerHTML={{ __html: result }}
        {...textProps}
      />
      {(author || date) && (
        <Text fontSize={8} color={COLORS.gray} {...subTextProps}>
          {getSubText(author, date)}
        </Text>
      )}
    </>
  );
};

const getSubText = (author?: string, date?: Date) => {
  if (!author && !date) {
    return "";
  }
  if (author && date) {
    return `${author} - ${formatDateBasedOnLanguage(date, true)}`;
  }
  return author || formatDateBasedOnLanguage(date, true);
};

export default CommentMessage;
