import { useParams } from "react-router-dom";
import SupplierResourceView from "./SupplierResourceView";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SupplierWorkerViewModel from "../../viewmodels/suppliers/SupplierWorkerViewModel";
import { SupplierDocumentsProps } from "../../components/Views/common/SupplierDocumentsTable";

const SupplierWorkerDetailView = ({
  permissions,
}: {
  permissions: SupplierDocumentsProps["permissions"];
}) => {
  const { companyId } = useAuth();
  const { supplierId, resourceId } = useParams();

  const resourceColumns = [
    "lastName",
    "firstName",
    "dateOfBirth",
    "dateOfEmployment",
    "email",
    "fiscalCode",
    "placeOfBirth",
    "jobTitle",
    "phone",
  ];

  return (
    <SupplierResourceView
      viewModel={new SupplierWorkerViewModel(companyId, supplierId, resourceId)}
      columns={resourceColumns}
      permissions={permissions}
    />
  );
};

export default SupplierWorkerDetailView;
