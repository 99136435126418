import { Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../DeleteIcon";
import ActionButton, { ActionButtonProps } from "./ActionButton";

const DeleteButton = ({
  onClick,
  tooltipLabel = "delete",
  isDisabled,
}: ActionButtonProps & { tooltipLabel?: string; isDisabled?: boolean }) => {
  const { t } = useTranslation("documents");

  return (
    <Tooltip
      label={t(tooltipLabel, { ns: "common" })}
      aria-label={tooltipLabel}
    >
      <span>
        <ActionButton
          isDisabled={isDisabled}
          aria-label="delete"
          onClick={onClick}
          icon={<DeleteIcon />}
        />
      </span>
    </Tooltip>
  );
};

export default DeleteButton;
