import Vehicle from '../../../entities/vehicle';
import UseCase from '../../../../core/app/usecase';
import VehicleRepository from '../../../repositories/vehicleRepository';
import GetVehicleParameters from './getVehicleParameter';

class GetVehicle implements UseCase<GetVehicleParameters, Vehicle | undefined> {
	constructor(private repository: VehicleRepository) {}

	async Call({ companyId, vehicleId }: GetVehicleParameters): Promise<Vehicle | undefined> {
		return this.repository.getVehicleById(companyId, vehicleId);
	}
}

export default GetVehicle;
