import UseCase from "../../../../core/app/usecase";
import DocumentRepository from "../../../repositories/documentRepository";
import AiTask from "../../../entities/aiTask";


type GetAiTaskDetailParams = {
  companyId: string;
  taskId: string,
}

/**
 * Returns the list of all the existing Document.
 */
class GetAiTaskDetail implements UseCase<GetAiTaskDetailParams, AiTask> {
  constructor(private repository: DocumentRepository) { }

  async Call({companyId, taskId}: GetAiTaskDetailParams): Promise<AiTask> {
    return await this.repository.getAiTaskDetail(companyId, taskId);
  }
}

export default GetAiTaskDetail;