import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Select,
  SelectProps as ChakraSelectProps
} from "@chakra-ui/react";
import { Identifiable } from "../../../domain/entities/interfaces/identifiable";
import { Named } from "../../../domain/entities/interfaces/named";

type SelectProps = {
  type: "select";
  selectProps: ChakraSelectProps;
  items: Array<Identifiable & Named>;
};

type TextProps = {
  type: "text" | "number" | "email" | "password";
  inputProps: InputProps;
};

export type FieldAction = {
  icon: any;
  hint: string;
  handler: () => void;
};

type BaseProps = {
  label?: string;
  isRequired?: boolean;
  error?: false | string;
  inputProps?: InputProps;
  selectProps?: ChakraSelectProps;
  items?: Array<Identifiable & Named>;
  placeholder?: string;
  actions?: FieldAction[];
};

type Props = BaseProps & (SelectProps | TextProps);

const FormField = ({
  label,
  isRequired = false,
  error = false,
  type = "text",
  inputProps,
  selectProps,
  items,
  actions,
}: Props) => {
  return (
    <FormControl
      variant="floating"
      marginTop={8}
      borderColor="gray.400"
      isRequired={isRequired}
      isInvalid={!!error}
    >
      <InputGroup>
        {["text", "number", "email", "password"].includes(type) && (
          <Input {...inputProps} type={type} autoComplete="new-password" />
        )}
        {type === "select" && (
          <Select {...selectProps}>
            {items &&
              Array.isArray(items) &&
              items?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))}
          </Select>
        )}
        {actions &&
          actions.map((action, key) => (
            <InputRightElement key={key} width="10" mx={8}>
              <IconButton
                fontSize="2xl"
                outlineColor="transparent"
                backgroundColor="transparent"
                style={{ backgroundColor: "transparent" }}
                aria-label={action.hint}
                onClick={action.handler}
              >
                {action.icon}
              </IconButton>
            </InputRightElement>
          ))}
      </InputGroup>
      {label && (
        <FormLabel backgroundColor="white" borderRadius={4} color="gray.500">
          {label}
        </FormLabel>
      )}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormField;
