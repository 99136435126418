import { useMemo, useState } from "react";
import { RoleContext } from "../../../domain/entities/role";
import Site from "../../../domain/entities/site";
import { UserSiteRole } from "../../../domain/entities/user";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import UserSiteRolesViewModel from "../../viewmodels/users/UserSiteRolesViewModel";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";

const useUserSiteRolesViewModel = (userId: string, context: RoleContext) => {
  const { companyId } = useAuth();
  const [filters, setFilters] = useState<{ name: string; sort: SortMeta }>();
  const vm = useMemo(
    () => new UserSiteRolesViewModel(companyId, userId),
    [companyId, userId],
  );

  const { data: sites, isLoading: isLoadingSites } = useQuery<Site[], Error>(
    ["get-sites-userSiteRolesHook", companyId, userId, context],
    async () => {
      if (context === RoleContext.SITE) {
        return await vm.getSites();
      } else {
        return await vm.getWorkingSites();
      }
    },

    { retry: false, initialData: [] },
  );

  const {
    data: userSiteRoles,
    isLoading: isLoadingUserSiteRoles,
    refetch,
  } = useQuery<UserSiteRole[], Error>(
    [
      "get-user-site-roles-userSiteRolesHook",
      companyId,
      userId,
      filters,
      context,
    ],
    async () => vm.getUserSiteRoles({ ...filters, context }),
    { retry: false, initialData: [] },
  );

  return {
    sites,
    userSiteRoles,
    isLoading: isLoadingSites && isLoadingUserSiteRoles,
    context,
    siteRoleFilters: filters,
    setSiteRoleFilters: setFilters,
    refetchSiteRoles: refetch,
  };
};

export default useUserSiteRolesViewModel;
