import { useFormContext } from "react-hook-form";
import { FormValues } from "./WebHookContainer";
import { useTranslation } from "react-i18next";
import { MultiValue } from "react-select";
import React from "react";
import SelectField from "./SelectField";
import { co } from "@fullcalendar/core/internal-common";

interface Option {
  value: string;
  label: string;
}

interface SelectFieldProps {
  index: number;
  options: Option[];
}

const SiteSelectField = ({ index, options }: SelectFieldProps) => {
  const name = "siteIds";
  const { t } = useTranslation("webhooks");
  const { watch, clearErrors } = useFormContext<FormValues>();
  const fieldName = `webHooks.${index}.${name}` as const;
  const selectedEvents = watch(`webHooks.${index}.events`);
  const hasSiteEvents = !!selectedEvents.find((event) =>
    event.startsWith("site:"),
  );

  const handleChange =
    (onChange: (options: string[]) => void) =>
    (options: MultiValue<Option>) => {
      clearErrors(fieldName);
      const values = options.map((option) => option.value);
      onChange(values);
    };

  const getValue = (value: string[]) =>
    value.map((value) => ({ value, label: value }));

  return (
    <SelectField
      name={name}
      options={options}
      index={index}
      handleChange={handleChange}
      rules={{
        required: {
          value: hasSiteEvents,
          message: t(`errors.${name}Required`),
        },
      }}
      isDisabled={!hasSiteEvents}
      getValue={getValue}
    />
  );
};

export default SiteSelectField;
