import UseCase from '../../../core/app/usecase';
import { badgeFromInterval } from '../../entities/badge';
import BadgeRepository from '../../repositories/badgeRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	fromCode: number;
	toCode: number;
};

class GetSiteBadgesFromInterval implements UseCase<ARGS, badgeFromInterval> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, siteId, fromCode, toCode }: ARGS): Promise<badgeFromInterval> {
		return await this.badgeRepository.getSiteBadgeFromInterval(companyId, siteId, fromCode, toCode);
	}
}

export default GetSiteBadgesFromInterval;
