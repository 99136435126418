import UseCase from '../../../../core/app/usecase';
import Document from '../../../entities/document';
import ToolRepository from '../../../repositories/toolRepository';

type CreateToolDocumentParameters = {
	companyId: string;
	resourceId: string;
	documents: string[];
};

/**
 * Returns the list of all the existing Document.
 */
class CreateToolDocument implements UseCase<CreateToolDocumentParameters, void> {
	constructor(private repository: ToolRepository) {}

	async Call({ companyId, resourceId, documents }: CreateToolDocumentParameters): Promise<void> {
		return await this.repository.createToolDocument(companyId, resourceId, documents);
	}
}

export default CreateToolDocument;
