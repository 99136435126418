export enum SupplierEvaluationState {
	ELIGIBLE = 'eligible',
	NOT_ELIGIBLE = 'not_eligible',
	ELIGIBLE_WITH_INTEGRATION = 'eligible_with_integration',
	UNEVALUATED = 'unevaluated',
	// NOT_EVALUATED = "not_evaluated"
}
export enum CombinedEvaluationStates {
	UNEVALUATED = 'unevaluated',
	EXPIRED = 'expired',
	PENDING = 'pending',
	ELIGIBLE = 'eligible',
	NOT_ELIGIBLE = 'not_eligible',
	ELIGIBLE_WITH_INTEGRATION = 'eligible_with_integration',
	AVAILABLE = 'available',
	NOT_AVAILABLE = 'not_available',
	NOT_APPLICABLE = 'not_applicable',
}
