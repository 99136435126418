import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { SupplierResource } from '../../entities/supplierResource';
import { SiteChemicalParams } from '../../entities/siteChemical';

type ARGS = {
	companyId: string;
	supplierId: string;
	resourceId: string;
};
class GetCompanySupplierChemical implements UseCase<ARGS, SupplierResource<SiteChemicalParams>> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, resourceId }: ARGS): Promise<SupplierResource<SiteChemicalParams>> {
		return await this.supplierRepository.getCompanySupplierChemical(companyId, supplierId, resourceId);
	}
}

export default GetCompanySupplierChemical;
