import UseCase from '../../../core/app/usecase';
import { DocumentFileLogEntry } from '../../entities/documentLogEntry';
import DocumentRepository from '../../repositories/documentRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { GetSitesFilter } from '../../repositories/siteRepository';
import Site, { DocumentSites } from '../../entities/site';

interface Args {
	companyId: string;
	documentId: string;
	filter?: GetSitesFilter;
	sort?: SortMeta;
	pageParam?: number;
	includeWorkingSites?: boolean;
	supplierId?: string;
}

class GetDocumentSites implements UseCase<Args, DocumentSites> {
	constructor(private repository: DocumentRepository) {}
	async Call({ companyId, documentId, filter, sort, pageParam, includeWorkingSites, supplierId }: Args): Promise<DocumentSites> {
		return this.repository.getDocumentSites(companyId, documentId, sort, filter, pageParam, includeWorkingSites, supplierId);
	}
}

export default GetDocumentSites;
