import FormInput from "../../components/Common/FormInput";
import { euVatCodes, sizes } from "../../../constants";
import { vatCodeValidators } from "../../../infrastructure/utilities/validator";
import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";
import { FormType } from "../../hooks/Onboarding/useOnboardingViewModel";

type PROPS = {
  methods: UseFormReturn<FormType>;
  activeStep: number;
  businessTypes: string[];
  setSelectedBusinessType: (value: string) => void;
};

const SignUpCompanyStep1 = ({
  activeStep,
  methods,
  businessTypes,
  setSelectedBusinessType,
}: PROPS) => {
  const { t } = useTranslation("onboarding");

  const selectedCountry = methods.watch("vatCountry");
  const countrySelectProps = methods.register("vatCountry");
  setSelectedBusinessType(methods.watch("businessType"));

  const types = businessTypes.map((type) => ({
    name: t("ui.companyTypes." + type),
    id: type,
  }));

  return (
    <div style={{ display: activeStep === 0 ? "block" : "none" }}>
      <FormInput
        inputProps={methods.register("businessName", {
          required: t("errors.company.name is required"),
        })}
        label={t("ui.company name")}
        isRequired
        error={methods.formState.errors.businessName?.message ?? false}
        type="text"
      />
      <FormInput
        selectProps={{
          ...countrySelectProps,
          onChange: (e) => {
            countrySelectProps.onChange(e);
            methods.setValue("vat", "");
          },
        }}
        items={euVatCodes(t)}
        label={t("ui.vatCountry")}
        isRequired
        type="select"
      />
      <FormInput
        inputProps={methods.register("vat", {
          required: t("errors.vat is required"),
          pattern: {
            value: vatCodeValidators.get(selectedCountry),
            message: t(`vatFormatError.${selectedCountry}`, {
              ns: "companies",
            }),
          },
        })}
        label={t("ui.vat")}
        isRequired
        error={methods.formState.errors.vat?.message ?? false}
        type={selectedCountry === "IT" ? "number" : "text"}
      />
      <FormInput
        selectProps={methods.register("businessSize")}
        items={sizes(t)}
        label={t("ui.company size")}
        isRequired
        type="select"
      />

      <FormInput
        selectProps={methods.register("businessType")}
        placeholder={t("ui.companyTypePlaceholder")}
        items={types}
        label={t("ui.companyType")}
        isRequired
        type="select"
      />
      <FormInput
        inputProps={methods.register("pec", {
          required: t("errors.pec is required"),
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: t("errors.insert a valid pec"),
          },
        })}
        label={t("ui.pec")}
        isRequired
        error={methods.formState.errors.pec?.message ?? false}
        type="email"
      />
      <FormInput
        inputProps={methods.register("ccnl", {
          required: t("errors.company.ccnl is required"),
        })}
        label={t("ui.ccnl")}
        isRequired
        error={methods.formState.errors.ccnl?.message ?? false}
        type="text"
      />
    </div>
  );
};

export default SignUpCompanyStep1;
