import UseCase from '../../../core/app/usecase';
import BadgeRepository from '../../repositories/badgeRepository';
import Badge from '../../entities/badge';

type ARGS = {
	companyId: string;
	badge: Badge;
};

class CreateBadge implements UseCase<ARGS, Badge> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, badge }: ARGS): Promise<Badge> {
		return await this.badgeRepository.createBadge(companyId, badge);
	}
}

export default CreateBadge;
