import UseCase from '../../../core/app/usecase';
import DocumentTypeRepository from '../../repositories/documentTypeRepository';

type LinkTagToDocumentTypeParameters = {
	companyId: string;
	documentTypeId: string;
	tagId: string;
};

/**
 * Create the DocumentType given as parameter.
 *
 * @param type the DocumentType that will be created
 */
class UnlinkTagToDocumentType implements UseCase<LinkTagToDocumentTypeParameters, void> {
	constructor(private repository: DocumentTypeRepository) {}

	async Call({ companyId, documentTypeId, tagId }: LinkTagToDocumentTypeParameters): Promise<void> {
		return await this.repository.unlinkTagFromDocumentType(companyId, documentTypeId, tagId);
	}
}

export default UnlinkTagToDocumentType;
