import UseCase from '../../../core/app/usecase';
import SupplierRepository, { GetSupplierVehiclesFilters } from '../../repositories/supplierRepository';
import { PaginatedResults, SortMeta } from '../../entities/interfaces/paginatedResults';
import { SiteVehicleParams } from '../../entities/siteVehicle';
import { SupplierResource } from '../../entities/supplierResource';
type ARGS = {
	companyId: string;
	supplierId: string;
	page: number;
	perPage?: number;
	filter?: GetSupplierVehiclesFilters;
	sort?: SortMeta;
};
class GetCompanySupplierVehicles implements UseCase<ARGS, PaginatedResults<SupplierResource<SiteVehicleParams>>> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, page, filter, sort }: ARGS): Promise<PaginatedResults<SupplierResource<SiteVehicleParams>>> {
		return await this.supplierRepository.getCompanySupplierVehicles(companyId, supplierId, page, 25, filter, sort);
	}
}

export default GetCompanySupplierVehicles;
