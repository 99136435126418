import { makeRequirementsGroupRepository } from './repositories';
import { makeRequirementRepository } from '../requirements/repositories';
import UpdateRequirement from '../../../../domain/interactors/requirement/updateRequirement';
import GetRequirementsGroup from '../../../../domain/interactors/requirementsGroup/getRequirementsGroups';
import AddRequirementToGroup from '../../../../domain/interactors/requirementsGroup/addRequirementToGroup';
import DeleteRequirementsGroup from '../../../../domain/interactors/requirementsGroup/deleteRequirementGroup';
import CreateRequirementsGroup from '../../../../domain/interactors/requirementsGroup/createRequirementsGroup';
import UpdateRequirementsGroup from '../../../../domain/interactors/requirementsGroup/updateRequirementsGroup';
import GetRequirementGroupDetail from '../../../../domain/interactors/requirementsGroup/getRequirementGroupDetail';
import RemoveRequirementFromGroup from '../../../../domain/interactors/requirementsGroup/removeRequirementFromGroup';
import GetRequirementGroupVariants from '../../../../domain/interactors/requirementsGroup/getRequirementGroupVariants';
import CloneRequirementsGroup from '../../../../domain/interactors/requirementsGroup/cloneRequirementsGroup';
import GetPropagableSites from '../../../../domain/interactors/requirementsGroup/getPropagableSites';

export const makeCreateRequirementsGroup = () => {
	return new CreateRequirementsGroup(makeRequirementsGroupRepository());
};

export const makeGetRequirementsGroups = () => {
	return new GetRequirementsGroup(makeRequirementsGroupRepository());
};

export const makeGetRequirementGroupDetail = () => {
	return new GetRequirementGroupDetail(makeRequirementsGroupRepository());
};

export const makeUpdateRequirementsGroup = () => {
	return new UpdateRequirementsGroup(makeRequirementsGroupRepository());
};

export const makeDeleteRequirementsGroup = () => {
	return new DeleteRequirementsGroup(makeRequirementsGroupRepository());
};

export const makeAddRequirementToGroup = () => {
	return new AddRequirementToGroup(makeRequirementsGroupRepository());
};

export const makeRemoveRequirementFromGroup = () => {
	return new RemoveRequirementFromGroup(makeRequirementsGroupRepository());
};

export const makeGetRequirementsGroupVariants = () => {
	return new GetRequirementGroupVariants(makeRequirementsGroupRepository());
};

export const makeCloneRequirementsGroup = () => {
	return new CloneRequirementsGroup(makeRequirementsGroupRepository());
};

export const makeGetPropagableSites = () => {
	return new GetPropagableSites(makeRequirementsGroupRepository());
};
