import { FC } from "react";
import { Named } from "../../../domain/entities/interfaces/named";
import { Identifiable } from "../../../domain/entities/interfaces/identifiable";
import { Wrap, WrapItem } from "@chakra-ui/react";
import TagBadge from "../../components/Common/TagBadge";
import TagMoreButton from "../../components/Common/TagMoreButton";
import Tag from "../../../domain/entities/tag";

interface TagListProps<T extends Identifiable & Named> {
  tags: Array<T>;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  marginBottom?: number;
  onSelect?: (tag: T) => void;
  onDelete?: (tag: T) => void;
  onUpdate?: (tag: T) => void;
  editable?: boolean;
}

const TagList: FC<TagListProps<Identifiable & Named>> = ({
  tags,
  marginTop,
  marginLeft,
  marginRight,
  marginBottom,
  onSelect,
  onDelete,
  onUpdate,
  editable,
}) => {
  return (
    <Wrap
      spacing={2}
      marginTop={marginTop}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginBottom={marginBottom}
    >
      {Array.isArray(tags) &&
        tags.slice(0, 2).map((tag) => (
          <WrapItem key={tag.id} m={0}>
            <TagBadge
              tag={tag}
              onClick={onSelect}
              onDelete={onDelete}
              editable={editable}
              onUpdate={onUpdate}
            />
          </WrapItem>
        ))}

      {Array.isArray(tags) && tags.length > 2 && (
        <TagMoreButton
          tags={tags as Tag[]}
          onDelete={onDelete}
          onUpdate={onUpdate}
          editable={editable}
        />
      )}
    </Wrap>
  );
};

export default TagList;
