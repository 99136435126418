import UseCase from '../../../core/app/usecase';
import NotificationsRepository, { makeNotificationsRepository } from '../../repositories/notificationsRepository';

interface Args {
	companyId: string;
	notificationId: string;
}

/**
 * Permanently deletes a specific notification.
 */
class DeleteNotificationUseCase implements UseCase<Args, string> {
	constructor(private repository: NotificationsRepository) {}

	async Call(args: Args): Promise<string> {
		const { companyId, notificationId } = args;
		return await this.repository.delete(companyId, notificationId);
	}
}

export const makeDeleteNotificationUseCase = (): DeleteNotificationUseCase => {
	return new DeleteNotificationUseCase(makeNotificationsRepository());
};
