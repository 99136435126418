import UseCase from '../../../core/app/usecase';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { SiteRequirementsResource } from '../../entities/siteRequirementsResource';
import DocumentRepository, { GetSiteRequirementsResourceFilter } from '../../repositories/documentRepository';

interface Args {
	companyId: string;
	siteId: string;
	availableResources: string[];
	pageParam: number;
	sort: SortMeta;
	filter: GetSiteRequirementsResourceFilter;
}

class GetSiteRequirementsResource implements UseCase<Args, SiteRequirementsResource[]> {
	constructor(private repo: DocumentRepository) {}

	async Call({ companyId, siteId, availableResources, pageParam, sort, filter }): Promise<SiteRequirementsResource[]> {
		return this.repo.getSiteRequirementsResource(companyId, siteId, availableResources, pageParam, sort, filter);
	}
}

export default GetSiteRequirementsResource;
