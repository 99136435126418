import { container } from 'tsyringe';
import ServerTagRepository from '../../infrastructure/repositories/serverTagRepository';
import ServerSiteRepository from '../../infrastructure/repositories/serverSiteRepository';
import ServerToolRepository from '../../infrastructure/repositories/serverToolRepository';
import ServerBadgeRepository from '../../infrastructure/repositories/serverBadgeRepository';
import ServerRolesRepository from '../../infrastructure/repositories/serverRolesRepository';
import ServerUsersRepository from '../../infrastructure/repositories/serverUsersRepository';
import ServerWorkerRepository from '../../infrastructure/repositories/serverWorkerRepository';
import { ServerAuthRepository } from '../../infrastructure/repositories/serverAuthRepository';
import ServerCompanyRepository from '../../infrastructure/repositories/serverCompanyRepository';
import ServerMachineRepository from '../../infrastructure/repositories/serverMachineRepository';
import ServerVariantRepository from '../../infrastructure/repositories/serverVariantRepository';
import ServerVehicleRepository from '../../infrastructure/repositories/serverVehicleRepository';
import ServerChemicalRepository from '../../infrastructure/repositories/serverChemicalRepository';
import ServerDocumentRepository from '../../infrastructure/repositories/serverDocumentRepository';
import ServerSiteToolRepository from '../../infrastructure/repositories/serverSiteToolRepository';
import ServerSupplierRepository from '../../infrastructure/repositories/serverSupplierRepository';
import ServerTypologyRepository from '../../infrastructure/repositories/serverTypologyRepository';
import ServerSiteWorkerRepository from '../../infrastructure/repositories/serverSiteWorkerRepository';
import ServerBadgeReaderRepository from '../../infrastructure/repositories/serverBadgeReaderRepository';
import ServerRequirementRepository from '../../infrastructure/repositories/serverRequirementRepository';
import ServerSiteMachineRepository from '../../infrastructure/repositories/serverSiteMachineRepository';
import ServerSiteVehicleRepository from '../../infrastructure/repositories/serverSiteVehicleRepository';
import { ServerDocumentsDownloader } from '../../infrastructure/repositories/serverDocumentsDownloader';
import ServerDocumentTypeRepository from '../../infrastructure/repositories/serverDocumentTypeRepository';
import ServerSiteChemicalRepository from '../../infrastructure/repositories/serverSiteChemicalRepository';
import ServerDocumentPresetRepository from '../../infrastructure/repositories/serverDocumentPresetRepository';
import ServerSpecializationRepository from '../../infrastructure/repositories/serverSpecializationRepository';
import { ServerNotificationsRepository } from '../../infrastructure/repositories/serverNotificationsRepository';
import ServerRequirementsGroupRepository from '../../infrastructure/repositories/serverRequirementsGroupRepository';
import { ApiService } from '../../infrastructure/utilities/apiService';
import ServerStaffRepository from '../../infrastructure/repositories/serverStaffRepository';
import ServerDashboardRepository from '../../infrastructure/repositories/serverDashboard';
import ServerExportRepository from '../../infrastructure/repositories/serverExportRepository';
import { ServerActivationRepository } from '../../infrastructure/repositories/serverActivation';
import ServerAuditRepository from '../../infrastructure/repositories/serverAuditRepository';
import ServerWebHookRepository from '../../infrastructure/repositories/serverWebHookRepository';

export const InitDependencies = (apiService: ApiService) => {
	container.register('ApiService', { useClass: ApiService });
	container.register('ActivationRepository', { useValue: new ServerActivationRepository(apiService) });
	container.register('TagRepository', { useValue: new ServerTagRepository(apiService) });
	container.register('AuthRepository', { useValue: new ServerAuthRepository(apiService) });
	container.register('SiteRepository', { useValue: new ServerSiteRepository(apiService) });
	container.register('ToolRepository', { useValue: new ServerToolRepository(apiService) });
	container.register('BadgeRepository', { useValue: new ServerBadgeRepository(apiService) });
	container.register('RolesRepository', { useValue: new ServerRolesRepository(apiService) });
	container.register('UsersRepository', { useValue: new ServerUsersRepository(apiService) });
	container.register('StaffRepository', { useValue: new ServerStaffRepository(apiService) });
	container.register('ExportRepository', { useValue: new ServerExportRepository(apiService) });
	container.register('WorkerRepository', { useValue: new ServerWorkerRepository(apiService) });
	container.register('CompanyRepository', { useValue: new ServerCompanyRepository(apiService) });
	container.register('CompanyRepository', { useValue: new ServerCompanyRepository(apiService) });
	container.register('MachineRepository', { useValue: new ServerMachineRepository(apiService) });
	container.register('VariantRepository', { useValue: new ServerVariantRepository(apiService) });
	container.register('VehicleRepository', { useValue: new ServerVehicleRepository(apiService) });
	container.register('ChemicalRepository', { useValue: new ServerChemicalRepository(apiService) });
	container.register('DocumentRepository', { useValue: new ServerDocumentRepository(apiService) });
	container.register('SiteToolRepository', { useValue: new ServerSiteToolRepository(apiService) });
	container.register('SupplierRepository', { useValue: new ServerSupplierRepository(apiService) });
	container.register('TypologyRepository', { useValue: new ServerTypologyRepository(apiService) });
	container.register('DocumentsDownloader', { useValue: new ServerDocumentsDownloader(apiService) });
	container.register('DashboardRepository', { useValue: new ServerDashboardRepository(apiService) });
	container.register('SiteWorkerRepository', { useValue: new ServerSiteWorkerRepository(apiService) });
	container.register('BadgeReaderRepository', { useValue: new ServerBadgeReaderRepository(apiService) });
	container.register('RequirementRepository', { useValue: new ServerRequirementRepository(apiService) });
	container.register('SiteMachineRepository', { useValue: new ServerSiteMachineRepository(apiService) });
	container.register('SiteVehicleRepository', { useValue: new ServerSiteVehicleRepository(apiService) });
	container.register('SiteChemicalRepository', { useValue: new ServerSiteChemicalRepository(apiService) });
	container.register('DocumentTypesRepository', { useValue: new ServerDocumentTypeRepository(apiService) });
	container.register('NotificationsRepository', { useValue: new ServerNotificationsRepository(apiService) });
	container.register('SpecializationRepository', { useValue: new ServerSpecializationRepository(apiService) });
	container.register('DocumentsPresetsRepository', { useValue: new ServerDocumentPresetRepository(apiService) });
	container.register('RequirementsGroupRepository', { useValue: new ServerRequirementsGroupRepository(apiService) });
	container.register('AuditRepository', { useValue: new ServerAuditRepository(apiService) });
	container.register('WebHookRepository', { useValue: new ServerWebHookRepository(apiService) });
};
