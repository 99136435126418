import { useTranslation } from "react-i18next";
import TopBar from "../../Common/TopBar";

const TimeTableTopBar = () => {
  const { t } = useTranslation();

  return <TopBar title={t("timeTable", { ns: "navbar" })} />;
};

export default TimeTableTopBar;
