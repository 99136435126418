import MainNavBar from "../../components/Common/MainNavBar";
import MainLayout from "../../layout/MainLayout";
import NotificationsTopBar from "./NotificationsTopBar";
import { useEffect } from "react";
import { useApp } from "../../../app";
import { useAuth } from "../../providers/Auth0JWTProvider";

const NotificationsMainView = () => {
  const { updateContext } = useApp();
  const { updateSiteContext } = useAuth();

  useEffect(() => {
    updateContext({ topBarTitle: undefined, site: undefined });
    updateSiteContext(undefined);
  }, []);

  return <MainLayout header={<NotificationsTopBar />} nav={<MainNavBar />} />;
};

export default NotificationsMainView;
