import Tool from '../../../entities/tool';
import UseCase from '../../../../core/app/usecase';
import ToolRepository from '../../../repositories/toolRepository';
import GetToolParameters from './getToolParameter';

class GetTool implements UseCase<GetToolParameters, Tool | undefined> {
	constructor(private repository: ToolRepository) {}

	async Call({ companyId, toolId }: GetToolParameters): Promise<Tool | undefined> {
		return this.repository.getToolById(companyId, toolId);
	}
}

export default GetTool;
