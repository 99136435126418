import {CustomField} from "../../../domain/entities/customFields";
import {makeGetCustomFields, makeSetCustomFields} from "../../../core/app/factory/companies/usecases";

class CustomFieldsViewModel {
	private getCustomFieldsUseCase = makeGetCustomFields();
	private setCustomFieldsUseCase = makeSetCustomFields();

	constructor(private companyId: string) {}

	async getCustomFields(): Promise<CustomField[]> {
		return await this.getCustomFieldsUseCase.Call({ companyId: this.companyId });
	}

	async setCustomFields($customFields: CustomField[]) {
		await this.setCustomFieldsUseCase.Call({ companyId: this.companyId, customFields: $customFields });
	}
}

export { CustomFieldsViewModel };
