import { injectable } from 'tsyringe';
import VariantRepository from '../../domain/repositories/variantRepository';
import { ApiService } from '../utilities/apiService';
import Variant from '../../domain/entities/variant';
import { DocumentTypeCategory } from '../../domain/entities/documentTypeCategory.enum';

@injectable()
class ServerVariantRepository implements VariantRepository {
	constructor(private apiService: ApiService) { }

	async getVariants(companyId: string, type: DocumentTypeCategory, search?: string): Promise<Variant[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/variants?type=${type}${search ? `&search=${search}` : ''}`
		);
		const variants = await response.json();
		return variants['results'] ?? [];
	}

	async getRequirementVariants(companyId: string, requirementGroupId: string, type: DocumentTypeCategory): Promise<Variant[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${requirementGroupId}/variants?type=${type}`
		);
		const variants = await response.json();
		return variants['results'] ?? [];
	}

	async createVariant(companyId: string, variant: Variant): Promise<Variant> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/variants`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(variant),
		});
		return response.json();
	}

	async getSiteVariants(companyId: string, siteId: string, type: DocumentTypeCategory, search?: string): Promise<Variant[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirements/variants?type=${type}${search ? `&search=${search}` : ''
			}`
		);
		const variants = await response.json();
		return variants['results'] ?? [];
	}
}

export default ServerVariantRepository;
