import { useApp } from "../../../app";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SiteOptions, {
  ScheduleEmail,
} from "../../../domain/entities/siteOptions";
import SiteOptionsViewModel from "../../viewmodels/sites/SiteOptionsViewModel";
import { useEffect, useState } from "react";
import User from "../../../domain/entities/user";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { updateFilterWithDelete } from "../../../utils";
import { GetUsersSiteFilter } from "../../../domain/repositories/filters";
import { AddSiteUser } from "../../../domain/repositories/siteRepository";
import AuthViewModel from "../../viewmodels/users/AuithViewModel";
import { useNavigate } from "react-router-dom";

const useSiteOptionsViewModel = (siteId: string, isWorkingSite?: boolean) => {
  const navigate = useNavigate();
  const { context } = useApp();
  const { companyId } = useAuth();
  const viewModel = new SiteOptionsViewModel(companyId, siteId);
  const authViewModel = new AuthViewModel();
  const [scheduleActive, setScheduleActive] = useState(false);
  const [selectedDays, setSelectedDays] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [emails, setEmails] = useState<string[]>([]);
  const [error, setError] = useState<string>(undefined);
  const [time, setTime] = useState<string>();
  const [siteUsersFilter, setSiteUsersFilter] = useState<GetUsersSiteFilter>({})
  const [sort, setSort] = useState<SortMeta>()
  const [enableGetSiteUsers, setEnableGetSiteUsers] = useState(false)
  const [enableGetSiteAvailableUsers, setEnableGetSiteAvailableUsers] = useState(false)
  const [availableUsersFilter, setAvailableUsersFilter] = useState<GetUsersSiteFilter>({})
  const [availableUsersSort, setAvailableUsersSort] = useState<SortMeta>();
  const [currentUserId, setCurrentUserId] = useState<string>();
  const [userId, setUserId] = useState<string>();

  const getOptionsQuery = useQuery<SiteOptions>(
    ["get-site-options", companyId, siteId],
    async () => {
      return await viewModel.getSiteOptions();
    },
    {
      enabled: !isWorkingSite,
      initialData: context.site?.options,
    }
  );

  const getScheduleEmailQuery = useQuery<ScheduleEmail[]>(
    ["get-schedule-email", companyId, siteId],
    async () => await viewModel.getScheduleEmail(),
    {
      enabled: !isWorkingSite,
    }
  );

  const getSiteUsers = useInfiniteQuery<User[]>(
    ["get-site-users", companyId, siteId, siteUsersFilter, sort],
    async ({ pageParam = 1 }) => await viewModel.getSiteUsers(pageParam, siteUsersFilter, sort),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetSiteUsers || isWorkingSite
    }
  );

  const getSiteAvailableUsers = useInfiniteQuery<User[]>(
    ["get-site-available-users", companyId, siteId, availableUsersFilter, availableUsersSort, enableGetSiteAvailableUsers],
    async ({ pageParam = 1 }) => {
      return  await viewModel.getSiteAvailableUsers(pageParam, availableUsersFilter, availableUsersSort);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetSiteAvailableUsers,
      retry: 1
    }
  )

  const getSiteRoles = useQuery(
    ["get-site-available-users"],
    async () => {
      return await viewModel.getSiteRoles(isWorkingSite);
    },
    {
      retry: 1
    }
  )

  useEffect(() => {
    if (getScheduleEmailQuery.data) {
      setSelectedDays(getScheduleEmailQuery.data[0]?.days ?? {});
      setTime(getScheduleEmailQuery.data[0]?.dayHour ?? "");
      setEmails(getScheduleEmailQuery.data[0]?.emails ?? []);
      setScheduleActive(getScheduleEmailQuery.data[0]?.active ?? false);
    }
  }, [getScheduleEmailQuery.data]);

  const updateOptionsMutation = useMutation(
    (options: Partial<SiteOptions>) =>
      viewModel.updateSiteOptions({ ...getOptionsQuery.data, ...options }),
    {
      onError: (err: Error) => {
        console.error("cannot update site options", err), setError(err.message);
      },
      onSuccess: () => getOptionsQuery.refetch(),
    }
  );

  const updateScheduledEmailMutation = useMutation(
    () =>
      viewModel.updateScheduledEmail({
        type: "eligibles-resources",
        active: scheduleActive,
        emails: emails,
        dayHour: scheduleActive ? time : null,
        days: selectedDays,
      }),
    {
      onError: (err: Error) => {
        console.error("cannot update schedule email", err),
          setError(err.message);
      },
      onSuccess: () => {
        getScheduleEmailQuery.refetch();
      },
    }
  );

  const upsertSiteUserMutation = useMutation(
    (params: AddSiteUser) => {
      setUserId(params.userIds[0]);
      return viewModel.upsertSiteUser(params);
    },
    {
      onSuccess: async () => {
        if(currentUserId === userId){
          const permissions = await authViewModel.getUserSitePermissions(companyId, currentUserId, siteId);
          const hasRequiredPermissions = isWorkingSite ? permissions.some(p => p.id === "worksite:settings") : permissions.some(p => p.id === "sites:settings");
          if (hasRequiredPermissions) {
            getSiteUsers.refetch();
          } else {
            navigate('/overview');
          }
        }else{
          getSiteUsers.refetch();
        }
      }
    }
  );
  const deleteSiteUserMutation = useMutation(
    (userIds: string[]) => {
      setUserId(userIds[0]);
      return viewModel.deleteSiteUser(userIds)
    },  {
      onSuccess: async () => {
        if(currentUserId === userId){
          const permissions = await authViewModel.getUserSitePermissions(companyId, currentUserId, siteId);
          const hasRequiredPermissions = isWorkingSite ? permissions.some(p => p.id === "worksite:settings") : permissions.some(p => p.id === "sites:settings");
          if (hasRequiredPermissions) {
            getSiteUsers.refetch();
          } else {
            navigate('/overview');
          }
        }else{
          getSiteUsers.refetch();
        }
      }
    }
  );

  const upsertSiteUser = async (params: AddSiteUser) => {
    await upsertSiteUserMutation.mutateAsync(params);
  };
  const deleteSiteUser = async (userIds: string[]) => {
    await deleteSiteUserMutation.mutateAsync(userIds);
  };

  const siteUsers = getSiteUsers.data?.pages?.flat() ?? []
  const siteAvailableUsers = getSiteAvailableUsers.data?.pages?.flat() ?? []
  const siteRoles = getSiteRoles.data ?? []

  return {
    options: getOptionsQuery.data,
    scheduledEmail: getScheduleEmailQuery.data
      ? getScheduleEmailQuery.data[0]
      : [],
    getScheduleEmailQueryRefetch: getScheduleEmailQuery.refetch,
    isLoading: getOptionsQuery.isFetching || updateOptionsMutation.isLoading,
    updateOption: updateOptionsMutation.mutate,
    reload: getOptionsQuery.refetch,
    updateScheduledEmail: updateScheduledEmailMutation.mutate,
    setEmails,
    emails,
    time,
    setTime,
    setSelectedDays,
    selectedDays,
    scheduleActive,
    setScheduleActive,
    error,
    setError,
    siteUsers,
    setSort,
    sort,
    setSiteUsersFilter,
    siteUsersFilter,
    setEnableGetSiteUsers,
    isLoadingGetSiteUsers: getSiteUsers.isLoading || getSiteUsers.isFetching,
    usersFetchNextPage: getSiteUsers.fetchNextPage,
    usersHasNextPage: getSiteUsers.hasNextPage,
    updateSiteUsersFilter: (field: string, value: string) => updateFilterWithDelete(setSiteUsersFilter, field, value),
    siteAvailableUsers,
    hasNextSiteAvailableUsers: getSiteAvailableUsers.hasNextPage,
    fetchNextSiteAvailableUsers: getSiteAvailableUsers.fetchNextPage,
    isLoadingSiteAvailableUsers: getSiteAvailableUsers.isLoading || getSiteAvailableUsers.isFetching ,
    setEnableGetSiteAvailableUsers,
    availableUsersFilter,
    updateAvailableUsersFilter: (field: string, value: string) => updateFilterWithDelete(setAvailableUsersFilter, field, value),
    setAvailableUsersSort,
    availableUsersSort,
    siteRoles,
    upsertSiteUser,
    upsertSiteUserLoading: upsertSiteUserMutation.isLoading,
    deleteSiteUser,
    setCurrentUserId
  };
};

export default useSiteOptionsViewModel;
