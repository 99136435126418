import UseCase from '../../../../core/app/usecase';
import VehicleRepository from '../../../repositories/vehicleRepository';

type MigrateToMachineParameters = {
	companyId: string;
	vehicleId: string;
	typologyId: string;
};

class MigrateToMachine implements UseCase<MigrateToMachineParameters, string> {
	constructor(private repository: VehicleRepository) {}

	async Call({ companyId, vehicleId, typologyId }: MigrateToMachineParameters): Promise<string> {
		return await this.repository.migrateToMachine(companyId, vehicleId, typologyId);
	}
}

export default MigrateToMachine;
