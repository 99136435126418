import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import TimeTableDocuments from "../../../domain/entities/timeTableDocuments";
import { useAuth } from "../../providers/Auth0JWTProvider";
import TimeTableViewModel from "../../viewmodels/timeTable/TimeTableViewModel";

const useTimeTableViewModel = () => {
  const { companyId } = useAuth();
  const viewModel = new TimeTableViewModel();

  const date = new Date();
  const [startDate, setStartDate] = useState<Date>(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [endDate, setEndDate] = useState<Date>(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  );

  const { data: timeTableDocuments } = useQuery<TimeTableDocuments, Error>(
    ["get-timeTableDocuments", companyId, endDate],
    async () => {
      return await viewModel.getTimeTableDocuments(
        companyId,
        startDate,
        endDate,
      );
    },
  );

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    timeTableDocuments,
  };
};

export { useTimeTableViewModel };
