import { darken, lighten } from "polished";
import { TagLabel, Tag as ChakraTag } from "@chakra-ui/react";
import React from "react";
import { BadgeStatus } from "../../../domain/entities/badgeStatus.enum";
import { COLORS } from "../../assets/theme/colors";
import { useTranslation } from "react-i18next";

type StatusBadgeProps = {
  value: BadgeStatus;
  label?: string;
  width?: string;
};

const StatusMap = {
  [BadgeStatus.ACTIVE]: [COLORS.active, "badges"],
  [BadgeStatus.BLOCKED]: [COLORS.error, "badges"],
  [BadgeStatus.LOST]: [COLORS.gray, "badges"],
};

const StatusBadge = ({
  value,
  label,
  width = "max-content",
}: StatusBadgeProps) => {
  const [color = "#aaaaaa", ns = "common"] = value
    ? StatusMap[value]
    : [COLORS.gray, "badges"];
  const { t } = useTranslation(ns);
  return (
    <ChakraTag
      width={width}
      size="md"
      color={darken(0.2, color)}
      bg={lighten(0.2, color)}
    >
      <TagLabel style={{ cursor: "pointer", fontSize: "12px" }}>
        {t("title", { ns: "badges" })} {label ?? t(value)}
      </TagLabel>
    </ChakraTag>
  );
};

export default StatusBadge;
