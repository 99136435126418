import BadgeReader from './badgeReader';
import Badge from './badge';
import { Identifiable } from './interfaces/identifiable';
import Site from './site';

export enum BadgeLogEntryAction {
	ENTER = 'incoming',
	EXIT = 'outgoing',
}

export enum BadgeLogEntryActionResult {
	GRANTED = 'accepted',
	DENIED = 'denied',
}

export type BadgeLogEntry = Identifiable & {
	actionDate: Date;
	actionResult: BadgeLogEntryActionResult;
	reader: BadgeReader;
	badge: Badge;
	actionType: BadgeLogEntryAction;
	badgeReaderName: string;
	anomaly: boolean;
  isForced?: boolean;
	isManual?: boolean;
	resource?: string;
	company?: string;
	forcing?: {
		userName: string;
		expiresAt: string;
		note: string;
	};
};
