import UseCase from '../../../core/app/usecase';
import BadgeRepository from '../../repositories/badgeRepository';
import Badge from '../../entities/badge';
import Tag from '../../entities/tag';

type ARGS = {
	companyId: string;
	count: number;
	tags: Tag[];
};

class CreateMultipleBadgeQR implements UseCase<ARGS, Badge> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, count, tags }: ARGS): Promise<Badge> {
		return await this.badgeRepository.createMultipleBadgeQR(companyId, count, tags);
	}
}

export default CreateMultipleBadgeQR;
