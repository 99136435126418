import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Named } from "../../../domain/entities/interfaces/named";
import { Identifiable } from "../../../domain/entities/interfaces/identifiable";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";

export type InputAnimatedSelectProps = {
  id: string;
  label: string;
  width?: string;
  marginTop?: number;
  noSelectionLabel?: string;

  namespace?: string;
  isRequired?: boolean;
  errorMessages?: string | false | Array<string>;

  selectedValue?: string;
  onSelect?: (id: string) => void;
  items?: Array<Identifiable & Named>;
  isDisabled?: boolean;
};

const InputAnimatedSelect: FC<InputAnimatedSelectProps> = ({
  id,
  label,
  marginTop,
  selectedValue,
  width,
  items,
  noSelectionLabel,
  namespace,
  isRequired,
  errorMessages,
  onSelect,
  isDisabled,
}) => {
  const { t } = useTranslation(namespace);

  return (
    <Box marginTop={marginTop} width={width}>
      <FormControl
        id={id}
        variant="floating"
        borderColor="gray.400"
        marginTop={2}
        isInvalid={errorMessages && errorMessages?.length > 0}
        isRequired={isRequired}
        isDisabled={isDisabled}
      >
        <Select
          isDisabled={isDisabled}
          marginTop={8}
          value={selectedValue}
          onChange={(e) => onSelect(e?.target?.value)}
          placeholder={noSelectionLabel}
        >
          {items &&
            Array.isArray(items) &&
            items?.map((item) => (
              <option key={item?.id} value={item?.id}>
                {item?.name}
              </option>
            ))}
        </Select>
        <FormLabel backgroundColor="white" borderRadius={4} color="gray.500">
          {label}
        </FormLabel>
        {errorMessages &&
          (Array.isArray(errorMessages) ? (
            <FormErrorMessage
              display="flex"
              flexDirection="column"
              alignItems="start"
            >
              {errorMessages.map((message, key) => (
                <div key={key}>{t(message)}</div>
              ))}
            </FormErrorMessage>
          ) : (
            <FormErrorMessage>{t(errorMessages)}</FormErrorMessage>
          ))}
      </FormControl>
    </Box>
  );
};

export default InputAnimatedSelect;
