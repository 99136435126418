import MainNavBar from "../../components/Common/MainNavBar";
import CompanyTopBar from "../../components/Views/Company/CompanyTopBar";
import MainLayout from "../../layout/MainLayout";
import { useEffect } from "react";
import { useApp } from "../../../app";
import { useAuth } from "../../providers/Auth0JWTProvider";

const CompanyMainView = () => {
  const { updateContext } = useApp();
  const { updateSiteContext } = useAuth();

  useEffect(() => {
    updateContext({ topBarTitle: undefined, site: undefined });
    updateSiteContext(undefined);
  }, []);

  return <MainLayout header={<CompanyTopBar />} nav={<MainNavBar />} />;
};

export default CompanyMainView;
