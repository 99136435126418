import { BusinessSize } from './domain/interactors/auth/args';
import { LicenseType } from './domain/interactors/auth/args';

export const euVatCodes = (t) => [
	{ id: '-', name: t('vatCountryCode.-', { ns: 'companies' }) },
	{ id: 'AT', name: t(`vatCountryCode.AT`, { ns: 'companies' }) },
	{ id: 'BE', name: t(`vatCountryCode.BE`, { ns: 'companies' }) },
	{ id: 'BG', name: t(`vatCountryCode.BG`, { ns: 'companies' }) },
	{ id: 'CY', name: t(`vatCountryCode.CY`, { ns: 'companies' }) },
	{ id: 'CZ', name: t(`vatCountryCode.CZ`, { ns: 'companies' }) },
	{ id: 'DE', name: t(`vatCountryCode.DE`, { ns: 'companies' }) },
	{ id: 'DK', name: t(`vatCountryCode.DK`, { ns: 'companies' }) },
	{ id: 'EE', name: t(`vatCountryCode.EE`, { ns: 'companies' }) },
	{ id: 'EL', name: t(`vatCountryCode.EL`, { ns: 'companies' }) },
	{ id: 'ES', name: t(`vatCountryCode.ES`, { ns: 'companies' }) },
	{ id: 'FI', name: t(`vatCountryCode.FI`, { ns: 'companies' }) },
	{ id: 'FR', name: t(`vatCountryCode.FR`, { ns: 'companies' }) },
	{ id: 'HR', name: t(`vatCountryCode.HR`, { ns: 'companies' }) },
	{ id: 'HU', name: t(`vatCountryCode.HU`, { ns: 'companies' }) },
	{ id: 'IE', name: t(`vatCountryCode.IE`, { ns: 'companies' }) },
	{ id: 'IT', name: t(`vatCountryCode.IT`, { ns: 'companies' }) },
	{ id: 'LT', name: t(`vatCountryCode.LT`, { ns: 'companies' }) },
	{ id: 'LU', name: t(`vatCountryCode.LU`, { ns: 'companies' }) },
	{ id: 'LV', name: t(`vatCountryCode.LV`, { ns: 'companies' }) },
	{ id: 'MT', name: t(`vatCountryCode.MT`, { ns: 'companies' }) },
	{ id: 'NL', name: t(`vatCountryCode.NL`, { ns: 'companies' }) },
	{ id: 'PL', name: t(`vatCountryCode.PL`, { ns: 'companies' }) },
	{ id: 'PT', name: t(`vatCountryCode.PT`, { ns: 'companies' }) },
	{ id: 'RO', name: t(`vatCountryCode.RO`, { ns: 'companies' }) },
	{ id: 'SE', name: t(`vatCountryCode.SE`, { ns: 'companies' }) },
	{ id: 'SI', name: t(`vatCountryCode.SI`, { ns: 'companies' }) },
	{ id: 'SK', name: t(`vatCountryCode.SK`, { ns: 'companies' }) },
];

export const sizes = (t) => [
	{ id: BusinessSize.Micro, name: t('ui.company sizes.micro') },
	{ id: BusinessSize.Small, name: t('ui.company sizes.small') },
	{ id: BusinessSize.Medium, name: t('ui.company sizes.medium') },
	{ id: BusinessSize.MediumLarge, name: t('ui.company sizes.medium-large') },
	{ id: BusinessSize.Large, name: t('ui.company sizes.large') },
];

export const licenseTypes = (t) => [
	{ id: LicenseType.Smart, name: t('smart') },
	{ id: LicenseType.Standard, name: t('standard') },
	{ id: LicenseType.Master, name: t('master') },
];
