import UseCase from '../../../core/app/usecase';
import Requirement from '../../entities/requirement';
import RequirementRepository from '../../repositories/requirementRepository';
import RequirementGroupDetailParameters from '../requirementsGroup/requirementGroupDetailParameter';

class GetGroupRequirementsByResource implements UseCase<RequirementGroupDetailParameters, Requirement[]> {
	private requirementRepository: RequirementRepository;

	constructor(requirementRepository: RequirementRepository) {
		this.requirementRepository = requirementRepository;
	}

	async Call(parameter: RequirementGroupDetailParameters): Promise<Requirement[]> {
		return await this.requirementRepository.getGroupRequirementsByResource(
			parameter.companyId,
			parameter.requirementGroupId,
			parameter.requirementSubject,
		);
	}
}

export default GetGroupRequirementsByResource;
