import UseCase from '../../../core/app/usecase';
import Audit from '../../entities/audit';
import { PaginatedResults, SortMeta } from '../../entities/interfaces/paginatedResults';
import AuditRepository, { GetAuditFilter } from '../../repositories/auditRepository';

interface Args {
	companyId: string;
	siteId: string;
	page?: number;
	perPage?: number;
	filter?: GetAuditFilter;
	sort?: SortMeta;
}

class GetAudits implements UseCase<Args, PaginatedResults<Audit>> {
	constructor(private repository: AuditRepository) {}
	async Call({ companyId, siteId, page, perPage, filter, sort }: Args): Promise<PaginatedResults<Audit>> {
		return this.repository.getAudits(companyId, siteId, page, perPage, filter, sort);
	}
}

export default GetAudits;
