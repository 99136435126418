import Site, { SiteState } from '../../entities/site';
import UseCase from '../../../core/app/usecase';
import SiteRepository, { AddSiteUser } from "../../repositories/siteRepository";

interface Args {
  companyId: string;
  siteId: string;
  params: AddSiteUser;
}

class UpsertSiteUser implements UseCase<Args, void> {
  private siteRepository: SiteRepository;

  constructor(siteRepository: SiteRepository) {
    this.siteRepository = siteRepository;
  }

  async Call({ companyId, siteId, params }: Args): Promise<void> {
    await this.siteRepository.upsertSiteUser(companyId, siteId, params);
  }
}

export default UpsertSiteUser;
