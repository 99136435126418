import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Divider,
} from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ForcingDetails } from "../../../domain/entities/forcingDetails";
import { formatDateBasedOnLanguage } from "../../../utils";

type DetailsProps = {
  forcedDetails: ForcingDetails;
  showForcedDetails: boolean;
  setShowForcedDetails: (value: boolean) => void;
  title?: string;
};
export const ForcingDetailsModal = ({
  forcedDetails,
  showForcedDetails,
  setShowForcedDetails,
  title,
}: DetailsProps) => {
  const { t } = useTranslation("badgeReaders");
  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={showForcedDetails}
      leastDestructiveRef={cancelRef}
      onClose={() => {
        if (!showForcedDetails) return;
        setShowForcedDetails(false);
      }}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t(title, { ns: "common" })}
            <AlertDialogCloseButton />
          </AlertDialogHeader>

          <AlertDialogBody>
            <Trans
              i18nKey="forcedAccessInfoMsg"
              ns="badgeReaders"
              values={{
                username: forcedDetails?.userName,
                resource: forcedDetails?.resource,
                codeBadge: forcedDetails?.codeBadge || null,
                expireAt: forcedDetails?.expiresAt
                  ? formatDateBasedOnLanguage(forcedDetails?.expiresAt, true)
                  : "",
              }}
              components={{
                1: <strong />,
                3: <strong />,
                5: <strong />,
                7: <strong />,
                0: (
                  <p
                    style={{
                      marginTop: 2,
                    }}
                  />
                ),
              }}
            />
            {forcedDetails?.note && (
              <>
                <p style={{ fontWeight: "bold", marginTop: 6 }}>
                  {" "}
                  {t("noteTitle", { ns: "communication" })}
                </p>
                <Divider />
                {forcedDetails?.note}
              </>
            )}
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
