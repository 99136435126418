import UseCase from '../../../../core/app/usecase';
import SiteVehicleRepository from '../../../repositories/siteVehicleRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	vehicleId: string;
	variantId?: string;
};

class UpdateSiteVehicleVariant implements UseCase<Parameters, void | undefined> {
	constructor(private repository: SiteVehicleRepository) {}

	async Call({ companyId, siteId, vehicleId, variantId }: Parameters): Promise<void | undefined> {
		return this.repository.updateSiteVehicleVariant(companyId, siteId, vehicleId, variantId);
	}
}

export default UpdateSiteVehicleVariant;
