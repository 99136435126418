import { FC } from "react";
import { FiPlus } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import ActionBar from "../../../components/Common/ActionBar";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import { MdList } from "react-icons/md";

interface DocumentsPresetsActionsProps {
  onAddDocumentType: () => void;
  onShowOrderPreset: () => void
};

const DocumentsPresetsActions: FC<DocumentsPresetsActionsProps> = ({ onAddDocumentType, onShowOrderPreset}) => {
  const { t } = useTranslation();

  return (
    <ActionBar>
      <ActionBarItem
        icon={FiPlus}
        onClick={onAddDocumentType}
        description={t("actionBar.addDocumentType", { ns: "documentsPresets" })}
      />
      <ActionBarItem
        icon={MdList}
        description={t("orderPresets", { ns: "documentsPresets" })}
        onClick={onShowOrderPreset}
      />
    </ActionBar>
  );
};

export default DocumentsPresetsActions;
