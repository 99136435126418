import {
  makeCreateStaff,
  makeDeleteStaffElement,
  makeGetStaff,
  makeGetSystemStaff,
  makeUpdateStaffElement,
  makeUpdateSystemStaffElement,
} from "../../../core/app/factory/staff/usecases";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import Staff from "../../../domain/entities/staff";
import { GetStaffFilters } from "../../components/Views/Company/StaffTableView";

class StaffViewModel {
  private getStaffUC = makeGetStaff();
  private createStaffUC = makeCreateStaff();
  private updateStaffElementUC = makeUpdateStaffElement();
  private deleteStaffElementUC = makeDeleteStaffElement();
  private getSystemStaffUC = makeGetSystemStaff();
  private updateSystemStaffElementUC = makeUpdateSystemStaffElement();

  async getStaff(
    companyId: string,
    staffFilter?: GetStaffFilters,
    sort?: SortMeta,
    siteId?: string,
  ): Promise<Staff[]> {
    return await this.getStaffUC.Call({
      companyId,
      siteId,
      filter: staffFilter,
      sort,
    });
  }

  async createStaff(
    companyId: string,
    staffElement: Staff,
    siteId?: string,
  ): Promise<void> {
    return await this.createStaffUC.Call({ companyId, staffElement, siteId });
  }

  async updateStaffElement(
    companyId: string,
    staffElement: Staff,
    siteId?: string,
  ): Promise<void> {
    return await this.updateStaffElementUC.Call({
      companyId,
      staffElement,
      siteId,
    });
  }

  async deleteStaffElement(
    companyId: string,
    staffId: string,
    siteId?: string,
  ): Promise<void> {
    return await this.deleteStaffElementUC.Call({ companyId, staffId, siteId });
  }

  async getSystemStaff(
    companyId: string,
    staffFilter?: GetStaffFilters,
    sort?: SortMeta,
  ): Promise<Staff[]> {
    return await this.getSystemStaffUC.Call({
      companyId,
      filter: staffFilter,
      sort,
    });
  }

  async updateSystemStaffElement(
    companyId: string,
    staffElement: Staff,
  ): Promise<void> {
    return await this.updateSystemStaffElementUC.Call({
      companyId,
      staffElement,
    });
  }
}
export default StaffViewModel;
