import GetDashboardCompanyExpiringDocuments from '../../../../domain/interactors/dashboard/getDasboardCompanyExpiringDocuments';
import GetDashboardChartData from '../../../../domain/interactors/dashboard/getDashboardChartData';
import GetDashboardCount from '../../../../domain/interactors/dashboard/getDashboardCount';
import GetDashboardDocuments from '../../../../domain/interactors/dashboard/getDashboardDocuments';
import getDashboardExpiringCompanyDocument from '../../../../domain/interactors/dashboard/getDashboardExpiringCompanyDocuments';
import GetDashboardExpiringCompanyOrSiteEvaluations from '../../../../domain/interactors/dashboard/getDashboardExpiringCompanyOrSiteEvaluations';
import GetDashboardExpiringResourcesEvaluations from '../../../../domain/interactors/dashboard/getDashboardExpiringResourcesEvaluations';
import GetDashboardSupplierInfo from '../../../../domain/interactors/dashboard/getDashboardSupplierInfo';
import { makeDashboardRepository } from './repositories';

export const makeGetDashboardCount = () => {
	return new GetDashboardCount(makeDashboardRepository());
};

export const makeGetDashboardDocuments = () => {
	return new GetDashboardDocuments(makeDashboardRepository());
};
export const makeGetDashboardChartData = () => {
	return new GetDashboardChartData(makeDashboardRepository());
};
export const makeGetDashboardSupplierInfo = () => {
	return new GetDashboardSupplierInfo(makeDashboardRepository());
};
export const makeGetDashboardCompanyExpiringDocuments = () => {
	return new GetDashboardCompanyExpiringDocuments(makeDashboardRepository());
};
export const makeGetDashboardExpiringResourcesEvaluation = () => {
	return new GetDashboardExpiringResourcesEvaluations(makeDashboardRepository());
};
export const makeGetDashboardExpiringCompanyOrSiteEvaluation = () => {
	return new GetDashboardExpiringCompanyOrSiteEvaluations(makeDashboardRepository());
};
export const makeGetDashboardExpiringCompanyDocument = () => {
	return new getDashboardExpiringCompanyDocument(makeDashboardRepository());
};
