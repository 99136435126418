import {useTranslation} from "react-i18next";
import {Flex, Tbody, Text, Th, Thead, Tr, useMediaQuery,} from "@chakra-ui/react";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import ContentLayout from "../../../layout/ContentLayout";
import ActionBar from "../../../components/Common/ActionBar";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import {FiPlus} from "react-icons/fi";
import {BiReset} from "react-icons/bi";
import {FaRegSave} from "react-icons/fa";
import {COLORS} from "../../../assets/theme/colors";
import SimpleTable from "../../../components/Common/table/SimpleTable";
import {useEffect, useMemo} from "react";
import {UseCustomFieldsViewModel} from "../../../hooks/CustomFields/useCustomFieldsViewModel";
import {CustomField, FieldType} from "../../../../domain/entities/customFields";
import {RequirementSubject} from "../../../../domain/entities/requirementSubject.enum";
import CustomFieldRow from "./CustomFieldRow";
import {useLocation} from "react-router-dom";

export type FormValues = {
  customFields: CustomField[];
};

const CustomFieldsContainer = ({ hook }: { hook: UseCustomFieldsViewModel }) => {
  const { t } = useTranslation("settings");
  const [isTablet] = useMediaQuery("(max-width: 1300px)");

  const location = useLocation();
  const segments = location.pathname.split("/");
  const subject = segments.pop() as RequirementSubject;
  const { customFields, setCustomFields } = hook;

  const onValid = async ({ customFields }: FormValues) => {
    await setCustomFields.mutate(customFields);
  }

  const defaultValues = useMemo(
    () => ({ customFields: customFields.data }),
    [customFields.data],
  );

  const methods = useForm<FormValues>({ defaultValues });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "customFields",
  });

  const isSetCustomFieldsFetching =
    setCustomFields.status === "loading" || customFields.status === "error";

  const isSetCustomFieldsFetchingOrFormNotDirty = isSetCustomFieldsFetching || !isDirty;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset, subject]);

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            onClick={handleSubmit(onValid)}
            icon={FaRegSave}
            description={t("save", { ns: 'common' })}
            isDisabled={isSetCustomFieldsFetchingOrFormNotDirty}
            bgColor={
              isSetCustomFieldsFetchingOrFormNotDirty ? COLORS.lightGrey : undefined
            }
            color={
              isSetCustomFieldsFetchingOrFormNotDirty ? COLORS.gray : undefined
            }
          />
          <ActionBarItem
            onClick={() =>
              append({ key: "", label: "", type: FieldType.TEXT, subject })
            }
            isDisabled={isSetCustomFieldsFetching}
            icon={FiPlus}
            description={t("add")}
            bgColor={
              isSetCustomFieldsFetching ? COLORS.lightGrey : COLORS.sikuroBlue
            }
            color={isSetCustomFieldsFetching ? COLORS.gray : COLORS.white}
          />
          <ActionBarItem
            onClick={() => reset(defaultValues)}
            isDisabled={isSetCustomFieldsFetchingOrFormNotDirty}
            icon={BiReset}
            description={t("reset", { ns: "common" })}
            bgColor={
              isSetCustomFieldsFetchingOrFormNotDirty
                ? COLORS.lightGrey
                : COLORS.sikuroBlue
            }
            color={
              isSetCustomFieldsFetchingOrFormNotDirty ? COLORS.gray : COLORS.white
            }
          />
        </ActionBar>
      }
    >
      <FormProvider {...methods}>
        <Flex
          flex={1}
          h="100%"
          width={"calc(100vw - 190px)"}
          padding="40px 10px 40px 40px"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
        >
          <Text textColor={COLORS.sikuroBlue} fontSize={20} fontWeight={"bold"}>
            {t("customFields.title")}
          </Text>
          <Text>
            {t("customFields.subtitle")}
          </Text>
          <Flex
            flexDirection={"column"}
            alignItems={"start"}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            width={"calc(100vw - 240px)"}
            position="relative"
            overflow={"auto"}
            marginTop={3}
          >
            <SimpleTable
              isLoading={isSetCustomFieldsFetching}
              emptyText={t("customFields.noFieldFound")}
              showEmptyText={fields.filter(f => f.subject === subject).length === 0}
            >
              <Thead>
                <Tr>
                  <Th w={isTablet && fields.length > 0 ? "300px" : "calc(50% - 30px)"}>
                    {t("customFields.field")}
                  </Th>
                  <Th w={isTablet && fields.length > 0 ? "300px" : "calc(50% - 30px)"}>
                    {t("customFields.type")}
                  </Th>
                  <Th w="60px" />
                </Tr>
              </Thead>
              <Tbody>
                {fields.map((field, index) => (
                  <CustomFieldRow
                    key={field.id}
                    index={index}
                    remove={remove}
                    isHidden={field.subject !== subject}
                  />
                ))}
              </Tbody>
            </SimpleTable>
          </Flex>
        </Flex>
      </FormProvider>
    </ContentLayout>
  );
};

export default CustomFieldsContainer;
