import { Grid, GridItem } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

const SubNavLayout = ({ nav }: ContentLayoutProps) => {
  const templateAreas = `
    "nav main action"
  `;

  const columnsWidth = "64px 1fr";
  const rowsHeight = "1fr";

  return (
    <Grid
      templateAreas={templateAreas}
      gridTemplateRows={rowsHeight}
      gridTemplateColumns={columnsWidth}
      h="100%"
    >
      <GridItem bg="white" area={"nav"}>
        <div style={{ height: "100%", width: "64px" }}>{nav}</div>
      </GridItem>
      <GridItem bg="white" area={"main"}>
        <Outlet />
      </GridItem>
    </Grid>
  );
};

interface ContentLayoutProps {
  nav: React.ReactNode;
}

export default SubNavLayout;
