import UseCase from '../../../core/app/usecase';
import { DocumentFileLogEntry } from '../../entities/documentLogEntry';
import DocumentRepository, { GetDocumentLogFilter } from '../../repositories/documentRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';

interface Args {
	companyId: string;
	documentId: string;
	filter?: GetDocumentLogFilter;
	sort?: SortMeta;
	resourceId?: string;
	siteId?: string;
	target?: string;
	pageParam?: number;
}

class GetDocumentLog implements UseCase<Args, DocumentFileLogEntry[]> {
	constructor(private repository: DocumentRepository) {}
	async Call({ companyId, documentId, filter, sort, resourceId, siteId, target, pageParam }: Args): Promise<DocumentFileLogEntry[]> {
		return this.repository.getDocumentLog(companyId, documentId, filter, sort, resourceId, siteId, target, pageParam);
	}
}

export default GetDocumentLog;
