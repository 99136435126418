import {
  Box,
  Card,
  CardBody,
  ComponentWithAs,
  Heading,
  Icon,
  IconProps,
  Text,
} from "@chakra-ui/react";
import { DashboardCount } from "../../../domain/entities/dashboardCount";
import { IconType } from "react-icons";
import { COLORS } from "../../assets/theme/colors";
import { useTranslation } from "react-i18next";

type DashboardCountViewProps = {
  count: DashboardCount;
  icon: IconType | ComponentWithAs<"svg", IconProps>;
  title: string;
};

const DashboardCountView = ({
  count,
  icon,
  title,
}: DashboardCountViewProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={16}
      >
        <Box>
          <Heading color={COLORS.sikuroBlue}>{count?.count}</Heading>
          <Text>{title}</Text>
        </Box>
        <Icon as={icon} boxSize={16} />
      </CardBody>
    </Card>
  );
};

export default DashboardCountView;
