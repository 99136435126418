import UseCase from '../../../../core/app/usecase';
import WorkerRepository from '../../../repositories/workerRepository';
import DeleteWorkerParameters from './deleteWorkerParameter';

class DeleteWorker implements UseCase<DeleteWorkerParameters, void> {
	constructor(private repository: WorkerRepository) {}

	async Call({ companyId, workerId }: DeleteWorkerParameters): Promise<void> {
		return await this.repository.deleteWorker(companyId, workerId);
	}
}

export default DeleteWorker;
