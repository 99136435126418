export enum RequirementSubject {
	COMPANY = 'company',
	VEHICLE = 'vehicle',
	TOOL = 'tool',
	WORKER = 'worker',
	CHEMICAL = 'chemical',
	MACHINE = 'machine',
	SITE = 'site',
	SUPPLIER = 'supplier'
}
