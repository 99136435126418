import { Box, Flex, Tooltip, useMediaQuery } from "@chakra-ui/react";
import daygrid from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import ContentLayout from "../../layout/ContentLayout";
import "./index.css";
import { useTimeTableViewModel } from "../../hooks/TimeTable/useTimeTableViewModel";
import React, { useMemo, useRef, useState } from "react";
import { DatesSetArg, EventClickArg } from "@fullcalendar/core";
import TimeTableDayDetailModal from "../../components/Views/TimeTable/TimeTableDayDetailModal";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../assets/theme/colors";
import Legend from "../../components/Views/TimeTable/CalendarLegend";
import { FiInfo } from "react-icons/fi";
import i18n, { t } from "i18next";
import { format, parseISO } from "date-fns";

export type CalendarCategories = {
  chemical: string;
  machine: string;
  tool: string;
  vehicle: string;
  worker: string;
  company: string;
};

const TimeTableCalendarView = () => {
  const calendarRef = useRef(null);
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const { setStartDate, setEndDate, timeTableDocuments } =
    useTimeTableViewModel();

  const [showSelectedDayDocuments, setShowSelectedDayDocuments] =
    useState<boolean>(false);
  const [showLegend, setShowLegend] = useState<boolean>(false);

  const [selectedDate, setSelectedDate] = useState<Date>();

  const handleEventClick = (clickInfo: EventClickArg) => {
    setSelectedDate(clickInfo.event.start);
    setShowSelectedDayDocuments(true);
  };

  const handleLegend = () => {
    showLegend ? setShowLegend(false) : setShowLegend(true);
  };

  const handleMoreLinkClick = (arg) => {
    setSelectedDate(arg.date);
    setShowSelectedDayDocuments(true);
  };

  function handleDateChanged(arg: DatesSetArg): void {
    setStartDate(arg.start);
    setEndDate(arg.end);
  }

  function handleDocumentSelection(
    resourceId: string,
    resourceType: string,
  ): void {
    let resourceDetailPath = "";
    if (resourceType === "site") {
      resourceDetailPath = `/sites/${resourceId}/documents/general`;
    } else if (resourceType === "company") {
      resourceDetailPath = `/company/general-documents`;
    } else {
      resourceDetailPath = `/resources/${resourceType}s/${resourceId}/details`;
    }
    navigate(resourceDetailPath);
  }

  const getCategoryColor = (category) => {
    switch (category) {
      case "chemical":
        return COLORS.calendar.chemical;
      case "machine":
        return COLORS.calendar.machine;
      case "tool":
        return COLORS.calendar.tool;
      case "vehicle":
        return COLORS.calendar.vehicle;
      case "worker":
        return COLORS.calendar.worker;
      case "company":
        return COLORS.calendar.company;
      default:
        return COLORS.calendar.worker; // Set a default color if needed
    }
  };

  const flatDocumentList = useMemo(() => {
    if (timeTableDocuments && Object.values(timeTableDocuments)) {
      return Object.entries(timeTableDocuments)?.flatMap(
        ([category, resources]) =>
          Array.isArray(resources)
            ? resources?.flatMap((resource) =>
                resource.documents.map((document) => {
                  const date = parseISO(document.expiresAt.toString());
                  return {
                    title: document.name,
                    start: format(date, "yyyy-MM-dd"),
                    backgroundColor: getCategoryColor(category),
                    textColor: "black",
                  };
                }),
              )
            : [],
      );
    }
    return [];
  }, [timeTableDocuments]);

  const categories: CalendarCategories = {
    chemical: COLORS.calendar.chemical,
    machine: COLORS.calendar.machine,
    tool: COLORS.calendar.tool,
    vehicle: COLORS.calendar.vehicle,
    worker: COLORS.calendar.worker,
    company: COLORS.calendar.company,
  };

  return (
    <ContentLayout>
      <Flex
        h="100%"
        width={isMobile ? "900px" : "100%"}
        alignItems="top"
        flexDirection="initial"
        paddingRight={3}
        paddingLeft={0}
      >
        <div className="demo-app">
          <div className="demo-app-main">
            <FullCalendar
              ref={calendarRef}
              plugins={[daygrid, interactionPlugin]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "",
              }}
              dayHeaderFormat={{ weekday: "long" }}
              firstDay={1}
              buttonText={{ today: t("today", { ns: "common" }) }}
              locale={i18n.resolvedLanguage}
              initialView="dayGridMonth"
              selectable={true}
              dayMaxEvents={true}
              moreLinkContent={(args) => {
                return `+ ${args.num} ${t("showMore")}`;
              }}
              eventClick={handleEventClick}
              events={flatDocumentList}
              moreLinkClick={handleMoreLinkClick}
              datesSet={handleDateChanged}
            />
            <Box className="fc">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="start"
                height="4rem"
                gap="1rem"
              >
                <Tooltip label={t("showLegend", { ns: "documents" })}>
                  <span>
                    <FiInfo cursor="pointer" onClick={handleLegend} />
                  </span>
                </Tooltip>
                {showLegend ? <Legend categories={categories} /> : null}
              </Box>
            </Box>
          </div>
        </div>
        {showSelectedDayDocuments && (
          <TimeTableDayDetailModal
            timeTableDocumentsProp={timeTableDocuments}
            onClose={() => setShowSelectedDayDocuments(false)}
            selectedDate={selectedDate}
            onDocumentSelected={handleDocumentSelection}
          />
        )}
      </Flex>
    </ContentLayout>
  );
};

export default TimeTableCalendarView;
