import { makeWebHookRepository } from './repositories';
import GetWebHooks from '../../../../domain/interactors/webhook/getWebHooks';
import SetWebHooks from '../../../../domain/interactors/webhook/setWebHooks';
import GetWebHookEvents from '../../../../domain/interactors/webhook/getWebHookEvents';

export const makeGetWebHooks = () => {
	return new GetWebHooks(makeWebHookRepository());
};

export const makeSetWebHooks = () => {
	return new SetWebHooks(makeWebHookRepository());
};

export const makeGetWebHookEvents = () => {
	return new GetWebHookEvents(makeWebHookRepository());
};
