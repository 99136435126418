import { EditableFormFieldProps } from "./FormField";
import { FC, ReactElement, cloneElement } from "react";
import {Collapse, SimpleGrid, GridItem, IconButton, useDisclosure, Flex} from "@chakra-ui/react";
import {ChevronDownIcon, ChevronUpIcon} from "@chakra-ui/icons";

interface FormProps {
  isEditing: boolean;
  templateColumns?: string;
  fields: Array<ReactElement<Omit<EditableFormFieldProps, "isEditing">>>;
  withCollapse?: boolean;
  rows?: number
}

const Form: FC<FormProps> = ({
  isEditing = false,
  templateColumns = "1fr 1fr 1fr",
  fields,
  withCollapse = false,
  rows
}) => {
  const { isOpen, onToggle } = useDisclosure()
  const columns = templateColumns.split(" ").length;
  const visibleFields = fields.slice(0, rows ? columns * rows : columns * 4);

  const collapseFields = fields.slice(columns * 4);
  const Icon = isOpen ? ChevronUpIcon : ChevronDownIcon
  return (
    <>
      <SimpleGrid templateColumns={templateColumns} gap={4}>
        {visibleFields.map((f) => {
          const props = { ...f.props, isEditing };
          return (
            <GridItem key={f.key} colSpan={f.props.colSpan}>
              {cloneElement(f, { ...props })}
            </GridItem>
          );
        })}
      </SimpleGrid>
      {withCollapse && collapseFields.length > 0 && (
        <>
          <Collapse in={!withCollapse || isOpen} animateOpacity>
            <SimpleGrid templateColumns={templateColumns} gap={4} mt={4} mb={4}>
              {collapseFields.map((f) => {
                const props = { ...f.props, isEditing };
                return (
                  <GridItem key={f.key} colSpan={f.props.colSpan}>
                    {cloneElement(f, { ...props })}
                  </GridItem>
                );
              })}
            </SimpleGrid>
          </Collapse>
          {withCollapse && (
            <Flex width="100%" justifyContent="center">
              <IconButton
                onClick={onToggle}
                aria-label="show-custom-fields"
                icon={<Icon />}
                sx={{
                  bgColor: "transparent",
                  padding: "2px 0",
                  position: "absolute",
                  bottom: 0
                }}
                _hover={{ bgColor: "transparent" , transform: "scale(1.8)" }}
              />
            </Flex>
          )}
        </>
      )}
    </>
  );
};

export default Form;
