import UseCase from '../../../core/app/usecase';
import { WebHook } from '../../entities/webHook';
import WebHookRepository from '../../repositories/webHookRepository';

type ARGS = {
	companyId: string;
	webHooks: WebHook[];
};

class SetWebHooks implements UseCase<ARGS, void> {
	private webHookRepository: WebHookRepository;

	constructor(webHookRepository: WebHookRepository) {
		this.webHookRepository = webHookRepository;
	}

	async Call({ companyId, webHooks }: ARGS): Promise<void> {
		return await this.webHookRepository.set(companyId, webHooks);
	}
}

export default SetWebHooks;
