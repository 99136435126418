import UseCase from '../../../../core/app/usecase';
import repository from '../../../repositories/siteWorkerRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	workerId: string;
};

class RestoreSiteWorker implements UseCase<Parameters, void> {
	constructor(private repository: repository) {}

	async Call({ companyId, siteId, workerId }: Parameters): Promise<void> {
		return this.repository.restoreSiteWorker(companyId, siteId, workerId);
	}
}

export default RestoreSiteWorker;
