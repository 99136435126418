import Badge, {MachineMetas} from "../../../../domain/entities/badge";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "./badgeStyles";
import React from "react";
import machinePlaceholder from "../../../../assets/images/machine.png";
import FrontBadgeContainer from "./FrontBadgeContainer";

type FrontBadgeMachineProps = {
  badge: Badge;
};
const FrontBadgeMachine = ({ badge }: FrontBadgeMachineProps) => {
  const {
    photo
  } = badge.resource.meta as MachineMetas;
  return (
    <FrontBadgeContainer badge={badge} photo={photo || machinePlaceholder}>
      <View style={styles.badgeDetailsRow}>
        <Text style={{ fontWeight: 700 }}>Macchinario: </Text>
        <Text style={styles.text}>{badge.resource.name}</Text>
      </View>
    </FrontBadgeContainer>
  );
};
export default FrontBadgeMachine;
