import { SiteVehicleParams } from '../../../domain/entities/siteVehicle';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { SupplierResourcesViewModel } from '../../hooks/Suppliers/useSupplierResourcesViewModel';
import { GetSupplierVehiclesFilters } from '../../../domain/repositories/supplierRepository';
import {
	makeGetCompanySupplierVehicles,
	makeGetSpecializationsForCompanySupplierRequirements,
	makeGetVariantsForCompanySupplierRequirements,
} from '../../../core/app/factory/suppliers/useCases';
import Variant from '../../../domain/entities/variant';
import Specialization from '../../../domain/entities/specialization';
import { RequirementSubject } from '../../../domain/entities/requirementSubject.enum';
import { SupplierResource } from '../../../domain/entities/supplierResource';

class SupplierVehiclesViewModel implements SupplierResourcesViewModel<SiteVehicleParams> {
	async getSupplierResources(
		companyId: string,
		supplierId: string,
		page: number,
		filter?: GetSupplierVehiclesFilters,
		sort?: SortMeta,
	): Promise<PaginatedResults<SupplierResource<SiteVehicleParams>>> {
		return await makeGetCompanySupplierVehicles().Call({ companyId, supplierId, page, filter, sort });
	}

	async getVariants(companyId: string, supplierId: string): Promise<Variant[]> {
		return await makeGetVariantsForCompanySupplierRequirements().Call({ companyId, supplierId, subject: RequirementSubject.VEHICLE });
	}

	async getSpecializations(companyId: string, supplierId: string): Promise<Specialization[]> {
		return await makeGetSpecializationsForCompanySupplierRequirements().Call({ companyId, supplierId, subject: RequirementSubject.VEHICLE });
	}
}

export default SupplierVehiclesViewModel;
