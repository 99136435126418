import { COLORS } from "../../assets/theme/colors";
import { Button, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MdCloudDownload } from "react-icons/md";

const DownloadAllButton = ({ onClick, borderRadius = '10px' }: { onClick: () => void , borderRadius?: string}) => {
  const { t } = useTranslation("documents");

  return (
    <Tooltip label={t("downloadAllFiles")}>
      <Button
        bgColor={COLORS.sikuroBlue}
        color={"white"}
        fontSize="2xl"
        width="30px"
        borderRadius={borderRadius}
        border="3px solid transparent"
        _hover={{
          border: `3px solid ${COLORS.darkYellow}`,
        }} p={1}
        onClick={onClick}
        aria-label="document-download"
      >
        <MdCloudDownload />
      </Button>
    </Tooltip>
  );
};
export default DownloadAllButton;
