import UseCase from '../../../core/app/usecase';
import RequirementRepository from '../../repositories/requirementRepository';

interface Args {
	companyId: string;
	requirementId: string;
	specializationId: string;
	requirementGroupId: string;
}

class RemoveSpecializationFromRequirement implements UseCase<Args, void> {
	constructor(private repository: RequirementRepository) {}

	async Call(args: Args): Promise<void> {
		return await this.repository.removeSpecializationFromRequirement(
			args.companyId,
			args.requirementId,
			args.specializationId,
			args.requirementGroupId,
		);
	}
}

export default RemoveSpecializationFromRequirement;
