import UseCase from '../../../../core/app/usecase';
import SiteChemicalRepository from '../../../repositories/siteChemicalRepository';
import Evaluation from '../../../entities/evaluation';
import { ResourceEvaluationState } from '../../../entities/resourceEvaluationState.enum';

type Parameters = {
	companyId: string;
	siteId: string;
	chemicalId: string;
	value: ResourceEvaluationState;
};

class EvaluateSiteChemical implements UseCase<Parameters, Evaluation<ResourceEvaluationState> | undefined> {
	constructor(private repository: SiteChemicalRepository) {}

	async Call({ companyId, siteId, chemicalId, value }: Parameters): Promise<Evaluation<ResourceEvaluationState> | undefined> {
		return this.repository.evaluateSiteChemical(companyId, siteId, chemicalId, value);
	}
}

export default EvaluateSiteChemical;
