import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaCalendarCheck } from "react-icons/fa";
import { SiteResourceDocument } from "../../../../domain/entities/document";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import { COLORS } from "../../../assets/theme/colors";

interface ExpirationComponentProps {
  documentsFetching: boolean;
  readOnly: boolean;
  selectedState: ResourceDocumentEvaluationState;
  evaluationValidity: string;
  handleUpdateEvaluationDate: () => void;
  document: SiteResourceDocument;
}

export const RequirementExpirationComponent = ({
  readOnly,
  documentsFetching,
  evaluationValidity,
  handleUpdateEvaluationDate,
  selectedState,
  document,
}: ExpirationComponentProps) => {
  const { t } = useTranslation("documents");

  return (
    <Box fontSize={12} pt={6}>
      {!documentsFetching ? (
        <>
          <Text>{t("validity")}:</Text>
          <Flex gap={4} justifyContent={"space-between"}>
            <Text color={COLORS.sikuroBlue}>
              {evaluationValidity ? evaluationValidity : "-"}
            </Text>
            {selectedState === "available" && !readOnly && (
              <FaCalendarCheck
                cursor={"pointer"}
                fontSize={16}
                onClick={() => handleUpdateEvaluationDate()}
              />
            )}
          </Flex>
          {document.graceDays ? (
            <Text>{t("graysDays", { graysDays: document.graceDays })}</Text>
          ) : null}
        </>
      ) : (
        <Box pt={1}>
          <Skeleton
            height={"30px"}
            w={"8rem"}
            startColor={"gray.300"}
            endColor={"gray.300"}
          />
        </Box>
      )}
    </Box>
  );
};
