import UseCase from '../../../../core/app/usecase';
import VehicleRepository from '../../../repositories/vehicleRepository';
import DeleteVehicleParameters from './deleteVehicleParameter';

class DeleteVehicle implements UseCase<DeleteVehicleParameters, void> {
	constructor(private repository: VehicleRepository) {}

	async Call({ companyId, vehicleId }: DeleteVehicleParameters): Promise<void> {
		return await this.repository.deleteVehicle(companyId, vehicleId);
	}
}

export default DeleteVehicle;
