import { useMemo, useState } from "react";

import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import Company from "../../../domain/entities/company";
import { WorkingSitesSubcontractorsViewModel } from "../../viewmodels/workingSites/WorkingSitesSubcontractorsViewModel";
import Supplier from "../../../domain/entities/supplier";
import Document from "../../../domain/entities/document";
import {
  ActiveSubcontractorsFilter,
  AvailableSuppliersFilter,
  InvitedSuppliersFilter,
  UnlistedCompany,
} from "../../../domain/repositories/supplierRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetDocumentsFilter } from "../../../domain/repositories/documentRepository";
import Tag from "../../../domain/entities/tag";
import { InvitedSupplier } from "../../../domain/entities/invitedSupplier";
import { updateFilterWithDelete } from "../../../utils";

/**
 * @param site the site where we are managing subcontractors
 * @param subcontractor the subcontractor company
 */

const useWorkingSubcontractorViewModel = (siteId: string) => {
  const { companyId } = useAuth();
  const [filterInvited, setFilterInvited] = useState<InvitedSuppliersFilter>(
    {}
  );
  const [sortInvited, setSortInvited] = useState<SortMeta>();
  const [searchActive, setSearchActive] = useState<string>();
  const [searchInvited, setSearchInvited] = useState<string>();
  const [searchToInvite, setSearchToInvite] = useState<string>();

  const [filterActive, setFilterActive] = useState<ActiveSubcontractorsFilter>(
    {}
  );
  const [sortActive, setSortActive] = useState<SortMeta>();
  const [filterAvailable, setFilterAvailable] =
    useState<AvailableSuppliersFilter>({});
  const [sortAvailable, setSortAvailable] = useState<SortMeta>();
  const [filterDocuments, setFilterDocuments] = useState<GetDocumentsFilter>(
    {}
  );
  const [sortDocuments, setSortDocuments] = useState<SortMeta>();
  const [availableDocuments, setAvailableDocuments] = useState<boolean>(false);
  const [messageInvitation, setMessageInvitation] = useState<
    string | undefined
  >(undefined);

  const [availableCompaniesEnabled, setAvailableCompaniesEnabled] =
    useState(false);

  const [canAddSub, setCanAddSub] = useState<number>();

  const viewModel = useMemo(
    () => new WorkingSitesSubcontractorsViewModel(),
    []
  );

  const activeSubcontractorsQuery = useInfiniteQuery<Supplier[], Error>(
    [
      "activeContractors",
      companyId,
      siteId,
      filterActive,
      sortActive,
      searchActive,
    ],
    async ({ pageParam = 1 }) => {
      const filters = searchActive
        ? { ...filterActive, search: searchActive }
        : filterActive;
      const { results, canAddSuppliers } =
        await viewModel.listActiveSubcontractors(
          companyId,
          siteId,
          filters,
          sortActive,
          pageParam
        );
      setCanAddSub(canAddSuppliers);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const availableSubcontractorsQuery = useInfiniteQuery<Company[], Error>(
    [
      "availableSubcontractors",
      companyId,
      siteId,
      filterAvailable,
      sortAvailable,
      searchToInvite,
    ],
    async ({ pageParam = 1 }) => {
      const filters = searchToInvite
        ? { ...filterAvailable, search: searchToInvite }
        : filterAvailable;
      const result = await viewModel.listAvailableSubcontractors(
        companyId,
        siteId,
        filters,
        sortAvailable,
        pageParam
      );
      return result;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: availableCompaniesEnabled,
    }
  );

  const invitedSubcontractorsQuery = useInfiniteQuery<InvitedSupplier[], Error>(
    [
      "invited-suppliers",
      companyId,
      siteId,
      filterInvited,
      sortInvited,
      searchInvited,
    ],
    async ({ pageParam = 1 }) => {
      const filters = searchInvited
        ? { ...filterInvited, search: searchInvited }
        : filterInvited;
      const result = viewModel.getInvitedSuppliers(
        companyId,
        siteId,
        filters,
        sortInvited,
        pageParam
      );
      return result;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const supplierVariantsQuery = useQuery(
    ["supplier-variants", companyId],
    () => viewModel.getSupplierVariants(companyId, siteId),
    {
      initialData: [],
    }
  );

  const documentsQuery = useInfiniteQuery<Document[], Error>(
    ["availableDocuments", companyId, siteId, filterDocuments, sortDocuments],
    async ({ pageParam = 1 }) =>
      await viewModel.listAvailableDocumentsForSubcontractors(
        companyId,
        siteId,
        filterDocuments,
        sortDocuments,
        pageParam
      ),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 5) {
          return pages.length + 1;
        }
      },
      enabled: availableDocuments,
    }
  );

  const inviteMutation = useMutation(
    ({
      supplierIds,
      documentIds,
      companyVariant,
      unlistedCompany,
    }: {
      supplierIds: string[];
      documentIds: string[];
      companyVariant: string;
      siteVariant: string;
      unlistedCompany: UnlistedCompany;
    }) =>
      viewModel.inviteSubcontractors(
        companyId,
        siteId,
        supplierIds,
        companyVariant,
        documentIds,
        unlistedCompany
      ),
    {
      onError: (err) => console.error("cannot create supplier", err),
      onSuccess: () => (
        activeSubcontractorsQuery.refetch(),
        invitedSubcontractorsQuery.refetch()
      ),
    }
  );

  const renewInvitationMutation = useMutation(
    (invitationToken: string) => {
      setMessageInvitation(undefined);
      return viewModel.renewInvitation(companyId, invitationToken, siteId);
    },
    {
      onError: (err: Error) => {
        setMessageInvitation(err.message);
      },
      onSuccess: () => {
        setMessageInvitation("success"), invitedSubcontractorsQuery.refetch();
      },
    }
  );

  const getTagsQuery = useQuery<Tag[], Error>(
    ["tags"],
    async () => await viewModel.getTags(companyId),
    {
      initialData: [],
    }
  );

  const isUserEmailAvailable = async (email: string) =>
    await viewModel.userEmailAvailable(email);

  const renewInvitation = async (invitationToken: string) => {
    try {
      await renewInvitationMutation.mutateAsync(invitationToken);
    } catch (err) {
      return undefined;
    }
  };

  const activeSubcontractorsResult =
    activeSubcontractorsQuery?.data?.pages?.flat() ?? [];
  const invitedContractors =
    invitedSubcontractorsQuery.data?.pages?.flat() ?? [];
  const availableSubcontractors =
    availableSubcontractorsQuery.data?.pages?.flat() ?? [];

  const updateFilterActive = (
    field: keyof ActiveSubcontractorsFilter,
    value: string | string[]
  ) => {
    updateFilterWithDelete(setFilterActive, field, value);
  };

  const updateFilterInvited = (
    field: keyof InvitedSuppliersFilter,
    value: string | string[] | [Date, Date]
  ) => {
    updateFilterWithDelete(setFilterInvited, field, value);
  };

  const updateFilterAvailable = (
    field: keyof AvailableSuppliersFilter,
    value: string
  ) => {
    updateFilterWithDelete(setFilterAvailable, field, value);
  };

  return {
    activeSubcontractorsResult,
    canAddSub,
    activeContractorsHasNextPage: activeSubcontractorsQuery.hasNextPage,
    activeContractorsFetchNextPage: activeSubcontractorsQuery.fetchPreviousPage,
    availableSubcontractors,
    availableSubcontractorsHasNextPage:
      availableSubcontractorsQuery.hasNextPage,
    availableSubcontractorsFetchNextPage:
      availableSubcontractorsQuery.fetchNextPage,
    availableSubcontractorsIsLoading: availableSubcontractorsQuery.isLoading,
    setAvailableCompaniesEnabled,
    supplierVariants: supplierVariantsQuery.data,
    documents: documentsQuery.data?.pages?.flat() ?? [],
    filterActive,
    updateFilterActive,
    sortActive,
    setSortActive,
    filterAvailable,
    updateFilterAvailable,
    sortAvailable,
    setSortAvailable,
    filterDocuments,
    updateFilterDocuments: updateFilter(setFilterDocuments),
    sortDocuments,
    setSortDocuments,
    inviteSubcontractor: inviteMutation.mutateAsync,
    inviteSubcontractorIsLoading: inviteMutation.isLoading,
    tags: getTagsQuery.data,
    isUserEmailAvailable,
    setAvailableDocuments,
    activeContractorsFetching: activeSubcontractorsQuery.isLoading,
    invitedContractors,
    invitedContractorsHasNextPage: invitedSubcontractorsQuery.hasNextPage,
    invitedContractorsFetchNextPage:
      invitedSubcontractorsQuery.fetchPreviousPage,
    invitedIsFetching: invitedSubcontractorsQuery.isLoading,
    filterInvited,
    updateFilterInvited,
    sortInvited,
    setSortInvited,
    messageInvitation,
    setMessageInvitation,
    renewInvitation,
    setSearchActive,
    setSearchInvited,
    setSearchToInvite,
  };
};

const updateFilter = (setter) => (field: string, value: string | string[]) =>
  setter((filter) => ({ ...filter, [field]: value }));

export { useWorkingSubcontractorViewModel };
