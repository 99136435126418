import { Button, Tooltip, Text } from "@chakra-ui/react";
import { useRef } from "react";
import { TbFileImport } from "react-icons/tb";
import FileEntity from "../../../domain/entities/file";
import { processUploadedFiles } from "../../../utils";
import { COLORS } from "../../assets/theme/colors";
import CircleIcon from "./CircleIcon";
import ZStack from "./ZStack";

const ImportButton = ({
  uploadFile,
  description,
  type = "iconBtn",
  bgColor = COLORS.yellow,
  textColor = "black",
  isDisabled,
}: {
  uploadFile: (file: FileEntity) => void;
  description: string;
  type: string;
  bgColor?: string;
  textColor?: string;
  isDisabled?: boolean;
}) => {
  const fileInputRef = useRef(undefined);
  const handleButtonClick = () => {
    // Reset the value of the file input field
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    // Trigger the click event on the file input
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    // Handle the selected file here
    uploadFile(processUploadedFiles(e.target.files));
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple
      />
      {type === "iconBtn" && (
        <Tooltip label={description}>
          <ZStack spacing={3.5} onClick={handleButtonClick} cursor={"pointer"}>
            <CircleIcon color={COLORS.sikuroBlue} size={12} />
            <TbFileImport fontSize="1.3rem" color="white" />
          </ZStack>
        </Tooltip>
      )}
      {type === "btn" && (
        <Button
          isDisabled={isDisabled}
          borderRadius={10}
          bg={bgColor}
          color={textColor}
          onClick={handleButtonClick}
          cursor={"pointer"}
        >
          {description}
        </Button>
      )}
      {type === "text" && (
        <Text onClick={handleButtonClick} cursor={"pointer"} width={"full"}>
          {description}
        </Text>
      )}
    </>
  );
};
export default ImportButton;
