import UseCase from '../../../core/app/usecase';
import Requirement from '../../entities/requirement';
import RequirementRepository from '../../repositories/requirementRepository';

type ARGS = { companyId: string; requirements: Requirement[]; requirementGroupId: string };

class UpdateRequirementsOrder implements UseCase<ARGS, void> {
	private requirementRepository: RequirementRepository;

	constructor(requirementRepository: RequirementRepository) {
		this.requirementRepository = requirementRepository;
	}

	async Call({ companyId, requirements, requirementGroupId }: ARGS): Promise<void> {
		return await this.requirementRepository.updateRequirementsOrder(companyId, requirements, requirementGroupId);
	}
}

export default UpdateRequirementsOrder;
