import UseCase from '../../../../core/app/usecase';
import SiteMachineRepository from '../../../repositories/siteMachineRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	machineId: string;
	variantId?: string;
};

class UpdateSiteMachineVariant implements UseCase<Parameters, void | undefined> {
	constructor(private repository: SiteMachineRepository) {}

	async Call({ companyId, siteId, machineId, variantId }: Parameters): Promise<void | undefined> {
		return this.repository.updateSiteMachineVariant(companyId, siteId, machineId, variantId);
	}
}

export default UpdateSiteMachineVariant;
