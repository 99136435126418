import { Box, Flex, VStack, useColorModeValue } from "@chakra-ui/react";

const ActionBar = ({ children }: ActionBarProps) => {
  return (
    <Box
      transition="3s ease"
      h="100%"
      bg={useColorModeValue("white", "gray.900")}
      paddingTop={1}
    >
      <Flex as="nav" direction="column" h="100%">
        <VStack alignItems="start">
          <Flex direction="column" width="100%" alignItems="center">
            {children}
          </Flex>
        </VStack>
      </Flex>
    </Box>
  );
};

interface ActionBarProps {
  children: React.ReactNode;
}

export default ActionBar;
