import UseCase from '../../../core/app/usecase';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import Tag from '../../entities/tag';
import Variant from '../../entities/variant';
import RequirementRepository from '../../repositories/requirementRepository';

interface Args {
	companyId: string;
	variant: Tag;
	requirementType: RequirementSubject;
}

class CreateVariant implements UseCase<Args, Variant> {
	constructor(private repository: RequirementRepository) {}

	async Call(args: Args): Promise<Variant> {
		return await this.repository.createVariant(args.companyId, args.variant, args.requirementType);
	}
}

export default CreateVariant;
