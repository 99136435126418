import Badge, { badgeFromInterval } from "../../../domain/entities/badge";
import File from "../../../domain/entities/file";

import {
  makeGetAvailableBadgesSiteCount,
  makeGetBadges,
  makeGetBadgeSites,
  makeGetSiteBadgeFromInterval,
  makeGetSiteBadges,
  makeImportLinkResourceBadges,
  makeLinkBadgeToSite,
  makeLinkBadgeToSiteMachine,
  makeLinkBadgeToSiteVehicle,
  makeLinkBadgeToSiteWorker,
  makeUnlinkBadgeFromSite,
} from "../../../core/app/factory/badges/usecases";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import {
  GetAvailableBadgesFilters,
  GetBadgesFilters,
  GetBadgesQuery,
  GetSiteBadgesFilters,
} from "../../../domain/repositories/badgeRepository";
import {
  makeCreateSiteWorker,
  makeGetSiteWorkers,
} from "../../../core/app/factory/siteWorkers/usecases";
import { makeGetSiteVehicles } from "../../../core/app/factory/siteVehicles/usecases";
import { makeGetSiteMachines } from "../../../core/app/factory/siteMachines/usecases";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import { SiteWorker } from "../../../domain/entities/siteWorker";
import { SiteMachine } from "../../../domain/entities/siteMachine";
import { SiteVehicle } from "../../../domain/entities/siteVehicle";
import { GetSiteWorkersFilters } from "../../../domain/repositories/siteWorkerRepository";
import { GetSiteMachinesFilters } from "../../../domain/repositories/siteMachineRepository";
import { GetSiteVehiclesFilters } from "../../../domain/repositories/siteVehicleRepository";
import { BadgeStatusSite } from "../../hooks/Badge/useBadgeDetailViewModel";
import { GetSiteResourcesFilters } from "../../hooks/Badge/useSiteBadgesListViewModel";
import Tag from "../../../domain/entities/tag";
import { makeGetTags } from "../../../core/app/factory/tags/usecases";
import Site from "../../../domain/entities/site";
import { makeGetSite } from "../../../core/app/factory/sites/usecases";

class SiteBadgesListViewModel {
  private getBadgeAvailableUseCase = makeGetBadges();
  private getSiteBadgesUseCase = makeGetSiteBadges();
  private getSiteBadgeFromInterval = makeGetSiteBadgeFromInterval();
  private linkBadgesToSiteUseCase = makeLinkBadgeToSite();
  private linkBadgesToSiteWorkersUseCase = makeLinkBadgeToSiteWorker();
  private linkBadgesToSiteVehiclesUseCase = makeLinkBadgeToSiteVehicle();
  private linkBadgesToSiteMachinesUseCase = makeLinkBadgeToSiteMachine();
  private getSiteWorkersUseCase = makeGetSiteWorkers();
  private getSiteVehiclesUseCase = makeGetSiteVehicles();
  private getSiteMachinesUseCase = makeGetSiteMachines();
  private unlinkBadgesFromSiteUseCase = makeUnlinkBadgeFromSite();
  private getBadgeSitesUseCase = makeGetBadgeSites();
  private getAvailableBadgesSiteCountUseCase =
    makeGetAvailableBadgesSiteCount();
  private importLinkResourcesBadgesUseCase = makeImportLinkResourceBadges();
  private createSiteResourceUseCase = makeCreateSiteWorker();
  private getTagsUseCase = makeGetTags();


  async listAvailable(
    companyId: string,
    siteId: string,
    page: number,
    filter?: GetBadgesFilters,
    sort?: SortMeta,
  ): Promise<PaginatedResults<Badge>> {
    return await this.getBadgeAvailableUseCase.Call({
      companyId,
      siteId,
      page,
      perPage: 25,
      filter,
      sort,
      query: "availableForSite",
    });
  }

  async listBadgeInterval(
    companyId: string,
    siteId: string,
    fromCode: number,
    toCode: number,
  ): Promise<badgeFromInterval> {
    return await this.getSiteBadgeFromInterval.Call({
      companyId,
      siteId,
      fromCode,
      toCode,
    });
  }

  async list(
    companyId: string,
    siteId: string,
    page?: number,
    filter?: GetSiteBadgesFilters,
    sort?: SortMeta,
    perPage?: number,
  ): Promise<PaginatedResults<Badge>> {
    return await this.getSiteBadgesUseCase.Call({
      companyId,
      siteId,
      page,
      perPage,
      filter,
      sort,
    });
  }

  async listSiteAvailableBadges(
    companyId: string,
    siteId: string,
    page: number,
    query: GetBadgesQuery,
    filter?: GetSiteBadgesFilters,
    sort?: SortMeta,
  ): Promise<PaginatedResults<Badge>> {
    return await this.getSiteBadgesUseCase.Call({
      companyId,
      siteId,
      page,
      query,
      perPage: 25,
      filter,
      sort,
    });
  }

  async linkBadgesToSite(
    companyId: string,
    siteId: string,
    badgeIds: string[],
    selectAll?: boolean,
    selectedBadgesNumber?: number,
    availableBadgesFilters?: { [p: string]: any },
  ): Promise<void> {
    return await this.linkBadgesToSiteUseCase.Call({
      companyId,
      siteId,
      badgeIds,
      selectAll,
      selectedBadgesNumber,
      availableBadgesFilters,
    });
  }

  async unlinkBadgesFromSite(
    companyId: string,
    siteId: string,
    badgeIds: string[],
    selectAll: boolean,
  ): Promise<void> {
    return await this.unlinkBadgesFromSiteUseCase.Call({
      companyId,
      siteId,
      badgeIds,
      selectAll,
    });
  }

  async linkBadgesToSiteResources(
    companyId: string,
    siteId: string,
    badgeIds: string[],
    resourceIds: string[],
    resourceType: BadgeResourceType,
    selectAllResources: boolean,
    selectedResourcesNumber: number,
    siteResourcesFilters: GetSiteResourcesFilters,
    selectAllBadges: boolean,
    selectedBadgesNumber: number,
    availableBadgesFilters: GetAvailableBadgesFilters,
    automaticLinking: boolean,
  ): Promise<void> {
    switch (resourceType) {
      case BadgeResourceType.WORKER:
        return await this.linkBadgesToSiteWorkersUseCase.Call({
          companyId,
          siteId,
          badgeIds,
          workerIds: resourceIds,
          selectAllResources,
          selectedResourcesNumber,
          siteResourcesFilters,
          selectAllBadges,
          selectedBadgesNumber,
          availableBadgesFilters,
          automaticLinking,
        });
      case BadgeResourceType.VEHICLE:
        return await this.linkBadgesToSiteVehiclesUseCase.Call({
          companyId,
          siteId,
          badgeIds,
          vehicleIds: resourceIds,
          selectAllResources,
          selectedResourcesNumber,
          siteResourcesFilters,
          selectAllBadges,
          selectedBadgesNumber,
          availableBadgesFilters,
          automaticLinking,
        });
      case BadgeResourceType.MACHINE:
        return await this.linkBadgesToSiteMachinesUseCase.Call({
          companyId,
          siteId,
          badgeIds,
          machineIds: resourceIds,
          selectAllResources,
          selectedResourcesNumber,
          siteResourcesFilters,
          selectAllBadges,
          selectedBadgesNumber,
          availableBadgesFilters,
          automaticLinking,
        });
    }
  }

  async listSiteResources(
    companyId: string,
    siteId: string,
    resourceType: BadgeResourceType,
    page: number,
    filter?:
      | GetSiteWorkersFilters
      | GetSiteMachinesFilters
      | GetSiteVehiclesFilters,
    sort?: SortMeta,
  ): Promise<PaginatedResults<SiteWorker | SiteMachine | SiteVehicle>> {
    filter = { ...filter, badgeAvailable: true };
    switch (resourceType) {
      case BadgeResourceType.WORKER:
        return await this.getSiteWorkersUseCase.Call({
          companyId,
          siteId,
          page,
          perPage: 25,
          filter,
          sort,
        });
      case BadgeResourceType.VEHICLE:
        return await this.getSiteVehiclesUseCase.Call({
          companyId,
          siteId,
          page,
          perPage: 25,
          filter,
          sort,
        });
      case BadgeResourceType.MACHINE:
        return await this.getSiteMachinesUseCase.Call({
          companyId,
          siteId,
          page,
          perPage: 25,
          filter,
          sort,
        });
    }
  }

  async getBadgeSites(
    companyId: string,
    badgeId: string,
  ): Promise<BadgeStatusSite[]> {
    return await this.getBadgeSitesUseCase.Call({
      companyId,
      badgeId,
    });
  }

  async getAvailableBadgesSiteCount(
    companyId: string,
    siteId: string,
  ): Promise<number> {
    return await this.getAvailableBadgesSiteCountUseCase.Call({
      companyId,
      siteId,
    });
  }

  async importLinkResourcesBadges(
    companyId: string,
    siteId: string,
    resourceType: BadgeResourceType,
    file: File,
  ): Promise<void> {
    return await this.importLinkResourcesBadgesUseCase.Call({
      companyId,
      siteId,
      resourceType,
      file,
    });
  }
  async getTags(companyId: string): Promise<Tag[]> {
    return await this.getTagsUseCase.Call({ companyId });
  }
  async getSiteData(companyId: string, siteId: string): Promise<Site> {
    return await makeGetSite().Call({ companyId, siteId });
  }
}

export { SiteBadgesListViewModel };
