import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { GetDocumentEvaluationFilter } from '../../repositories/documentRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import Evaluation from '../../entities/evaluation';
import { ResourceDocumentEvaluationState } from '../../entities/resourceDocumentEvaluationState.enum';
import Document from '../../entities/document';

type ARGS = {
	companyId: string;
	documentId: string;
	supplierId: string;
	resourceType?: string;
	resourceId?: string;
	filter?: GetDocumentEvaluationFilter;
	sort?: SortMeta;
};

class GetSupplierDocumentEvaluation implements UseCase<ARGS, Evaluation<ResourceDocumentEvaluationState, Document>[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({
		companyId,
		supplierId,
		documentId,
		resourceType,
		resourceId,
		filter,
		sort,
	}: ARGS): Promise<Evaluation<ResourceDocumentEvaluationState, Document>[]> {
		return await this.supplierRepository.getSupplierDocumentEvaluations(companyId, supplierId, documentId, resourceType, resourceId, filter, sort);
	}
}

export default GetSupplierDocumentEvaluation;
