import { FC, useEffect } from "react";
import TopBar from "../Common/TopBar";
import SubNavBar from "../Common/SubNavBar";
import MainNavBar from "../Common/MainNavBar";
import { useTranslation } from "react-i18next";
import MainLayout from "../../layout/MainLayout";
import SubNavLayout from "../../layout/SubNavLayout";
import { useApp } from "../../../app";

interface Props {
  title?: string;
  layout: "main" | "sub";
}

const AccessDeniedMainView: FC<Props> = ({ title, layout }) => {
  const { t } = useTranslation("permissions");
  const { updateContext } = useApp();

  useEffect(() => {
    updateContext({ topBarTitle: undefined });
  }, []);

  if (layout === "main") {
    return (
      <MainLayout
        nav={<MainNavBar />}
        header={<TopBar title={title ?? t("accessDenied.title")} />}
      />
    );
  }

  return <SubNavLayout nav={<SubNavBar items={[]} />} />;
};

export default AccessDeniedMainView;
