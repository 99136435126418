import Supplier from '../../entities/supplier';
import UseCase from '../../../core/app/usecase';
import { SupplierStatus } from '../../entities/supplierStatus.enum';
import SupplierRepository from '../../repositories/supplierRepository';

interface Args {
	siteId: string;
	companyId: string;
	supplierId: string;
	status: SupplierStatus;
}

class SetSupplierStatus implements UseCase<Args, Supplier> {
	constructor(private repository: SupplierRepository) {}

	async Call({ companyId, siteId, supplierId, status }: Args): Promise<Supplier> {
		return await this.repository.setSupplierStatus(companyId, siteId, supplierId, status);
	}
}

export default SetSupplierStatus;
