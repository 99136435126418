import ExportDialog from "../Views/Export/ExportDialog";
import { useApp } from "../../../app";
import { CgMenuGridO } from "react-icons/cg";
import { useState } from "react";
import RenderIf from "../Permissions/RenderIf";
import { useTranslation } from "react-i18next";
import { AiFillCaretLeft } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import { Permission } from "../Permissions/Permissions";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { PermissionCheck } from "../../providers/Auth0JWTProvider";

const TopBar = ({ title, items, backPath }: TopBarProps) => {
  const {
    context: { topBarTitle },
  } = useApp();
  const [showExportDialog, setShowExportDialog] = useState(false);
  const { t } = useTranslation();

  const handleRenderTopBarItems = () => {
    return items
      ?.filter((i) => !i.hidden)
      ?.map((i) => {
        return (
          <RenderIf
            permissions={i.permissions}
            key={i.path}
            check={
              i?.check === PermissionCheck.All ? i.check : PermissionCheck.Some
            }
          >
            <Box w="115px" h="100%">
              <NavLink to={i.path}>
                {({ isActive }) => (
                  <Box
                    key={i.title}
                    w="100%"
                    h="100%"
                    display="flex"
                    px={3}
                    color="white"
                    className={isActive ? "active-link" : ""}
                    sx={{
                      "&.active-link": {
                        backgroundColor: "white",
                        color: "#32a2db",
                      },
                    }}
                    alignItems="center"
                    textAlign="center"
                    justifyContent="center"
                    fontWeight={500}
                    fontSize="16px"
                    lineHeight="19px"
                    _hover={{
                      shadow:
                        "4px 0px 8px rgba(0, 0, 0, 0.1), -4px 0px 8px rgba(0, 0, 0, 0.1)",
                    }}
                    style={
                      isActive
                        ? {
                            boxShadow:
                              "4px 0px 8px rgba(0, 0, 0, 0.1), -4px 0px 8px rgba(0, 0, 0, 0.1)", // Apply shadow if isActive is true
                          }
                        : {} // No styles applied if isActive is false
                    }
                  >
                    {i.title}
                  </Box>
                )}
              </NavLink>
            </Box>
          </RenderIf>
        );
      });
  };

  const handleRenderBackButton = () => {
    if (backPath) {
      return (
        <Link to={backPath}>
          <Box
            w="100%"
            h="100%"
            display="flex"
            alignItems="center"
            backgroundColor="tomato.500"
          >
            <Icon
              fontSize="15"
              _groupHover={{ color: "white" }}
              as={AiFillCaretLeft}
            />
          </Box>
        </Link>
      );
    }
  };

  return (
    <Flex
      width="100%"
      height="100%"
      textAlign="center"
      justifyContent={"space-between"}
    >
      <Flex>
        <Box
          backgroundColor="#eece23"
          height="100%"
          w="15%"
          minW="150px"
          display="flex"
          alignItems="center"
          px={3}
        >
          <HStack spacing={2} width="100%" height="">
            {handleRenderBackButton()}
            {topBarTitle ?? (
              <Heading fontSize="16px" size="xs">
                {title}
              </Heading>
            )}
          </HStack>
        </Box>
        <Box display={"flex"}>{handleRenderTopBarItems()}</Box>
      </Flex>
      <Box alignContent={"end"}>
        <Menu>
          <MenuButton
            bg={"transparent"}
            _hover={{ bg: "transparent" }}
            margin={"1rem"}
          >
            <CgMenuGridO size={20} color={"white"} />
          </MenuButton>
          <MenuList zIndex={2}>
            <MenuItem onClick={() => setShowExportDialog(true)}>
              {t("export", { ns: "common" })}
            </MenuItem>
          </MenuList>
        </Menu>
        {showExportDialog && (
          <ExportDialog
            onClose={() => setShowExportDialog(false)}
            onExport={() => {
                setShowExportDialog(false);
            }}
          />
        )}
      </Box>
    </Flex>
  );
};

interface TopBarProps {
  title: string;
  backPath?: string;
  items?: TopBarItem[];
}

export interface TopBarItem {
  path: string;
  title: string;
  hidden?: boolean;
  permissions?: Permission[];
  check?: PermissionCheck;
}

export default TopBar;
