import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Site from "../../../../domain/entities/site";
import Role, { RoleContext } from "../../../../domain/entities/role";
import { COLORS } from "../../../assets/theme/colors";
import InputAnimatedSelect from "../../../components/Common/InputAnimatedSelect";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { UserSiteRole } from "../../../../domain/entities/user";
import { useAuth } from "../../../providers/Auth0JWTProvider";

interface Props {
  sites: Site[];
  roles: Role[];
  worksites: Site[];
  onCancel: () => void;
  onConfirm: (siteId: string, roleId: string) => void;
  worksiteRoles: UserSiteRole[];
  siteRoles: UserSiteRole[];
  isLoading: boolean;
}

const AddUserSiteRoleView: FC<Props> = ({
  sites,
  roles = [],
  worksites,
  onCancel,
  onConfirm,
  worksiteRoles,
  siteRoles,
  isLoading,
}) => {
  const { t } = useTranslation("settings");
  const { company } = useAuth();
  const [selectedSiteId, setSelectedSiteId] = useState<string>();
  const [selectedRoleId, setSelectedRoleId] = useState<string>();

  const roleSiteIds = [...siteRoles, ...worksiteRoles].map((s) => s.site.id);

  const availableSites = sites.filter((s) => !roleSiteIds.includes(s.id));
  const availableWorksites = worksites.filter(
    (s) => !roleSiteIds.includes(s.id)
  );

  const [context, setContext] = useState<RoleContext>(
    availableSites.length > 0 ? RoleContext.SITE : RoleContext.WORKSITE
  );

  const getContextsByCompanyType = (companyType) => {
    const siteWorkingSiteContext = [
      ...(availableSites.length > 0
        ? [{ id: RoleContext.SITE, name: t("roles.siteContext") }]
        : []),
      ...(availableWorksites.length > 0
        ? [{ id: RoleContext.WORKSITE, name: t("roles.worksiteContext") }]
        : []),
    ];

    const worksiteContext = [
      ...(availableWorksites.length > 0
        ? [{ id: RoleContext.WORKSITE, name: t("roles.worksiteContext") }]
        : []),
    ];

    switch (companyType) {
      case "master":
        return siteWorkingSiteContext;
      case "standard":
        return worksiteContext;
      default:
        return [];
    }
  };
  const contextMap = getContextsByCompanyType(company?.type);

  const confirm = async () => {
    const selectedSites = context === RoleContext.SITE ? sites : worksites;
    const site = selectedSites.find((s) => s.id === selectedSiteId);
    const role = roles.find((r) => r.id === selectedRoleId);
    await onConfirm(site.id, role.id);
    onCancel();
  };

  const onSelectContext = (context: RoleContext) => {
    setContext(context);
    setSelectedSiteId(undefined);
    setSelectedRoleId(undefined);
  };

  return (
    <Modal isOpen onClose={onCancel} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("userSiteRoles.add")}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <InputAnimatedSelect
            id="context"
            items={contextMap}
            selectedValue={context}
            onSelect={onSelectContext}
            noSelectionLabel={t("userSiteRoles.noContextSelected")}
            label={t("userSiteRoles.selectContext")}
          />

          {context !== RoleContext.GENERAL && (
            <InputAnimatedSelect
              id="site"
              items={
                context === RoleContext.SITE
                  ? availableSites || []
                  : availableWorksites || []
              }
              selectedValue={selectedSiteId}
              onSelect={(id) => setSelectedSiteId(id)}
              noSelectionLabel={t("userSiteRoles.noSiteSelected")}
              label={t("userSiteRoles.selectSite")}
            />
          )}

          <InputAnimatedSelect
            id="role"
            items={roles.filter((r) => r.context === context)}
            selectedValue={selectedRoleId}
            onSelect={(id) => setSelectedRoleId(id)}
            noSelectionLabel={t("userSiteRoles.noRoleSelected")}
            label={t("userSiteRoles.selectRole")}
          />
        </ModalBody>

        <ModalFooter>
          {selectedSiteId && selectedRoleId && (
            <Button
              bg={COLORS.sikuroBlue}
              textColor="white"
              onClick={confirm}
              isLoading={isLoading}
            >
              {t("confirm", { ns: "common" })}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddUserSiteRoleView;
