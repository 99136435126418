import { Flex, IconButton } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { RepeatIcon } from "@chakra-ui/icons";
import React from "react";

type TimeRangePickerProps = {
  timeRange?: [Date, Date];
  setTimeRange: (dates: [Date, Date]) => void;
};

const TimeRangePicker = ({ timeRange, setTimeRange }: TimeRangePickerProps) => {
  const [defaultStartTime, defaultEndTime] = [new Date(), new Date()];
  defaultStartTime.setHours(0, 0, 0, 0);
  defaultEndTime.setHours(23, 59, 0, 0);
  const [startTime, endTime] = (timeRange as [Date, Date]) ?? [
    defaultStartTime,
    defaultEndTime,
  ];
  if (
    endTime?.getHours() == 0 &&
    endTime?.getMinutes() == 0 &&
    endTime?.getSeconds() == 0
  ) {
    endTime.setHours(23, 59, 0, 0);
  }
  return (
    <Flex
      gap="10px"
      alignItems="center"
      sx={{
        "& input": {
          width: "80px",
          border: "1px solid",
          borderColor: "gray.300",
          borderRadius: "5px",
          padding: "2px 5px",
        },
      }}
    >
      <DatePicker
        selected={startTime}
        onChange={(date) => setTimeRange([date, endTime])}
        showMonthDropdown
        showYearDropdown
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        minTime={defaultStartTime}
        maxTime={endTime}
      />
      <DatePicker
        selected={endTime}
        onChange={(date) => setTimeRange([startTime, date])}
        showMonthDropdown
        showYearDropdown
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        minTime={startTime}
        maxTime={defaultEndTime}
      />
      {timeRange && timeRange[0] && (
        <IconButton
          aria-label={"clear"}
          icon={<RepeatIcon />}
          onClick={() => setTimeRange(null)}
          size="xs"
        />
      )}
    </Flex>
  );
};

export default TimeRangePicker;
