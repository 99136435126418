import { HStack, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { BsDot } from "react-icons/bs";

interface Props {
  permission: string;
}

const PermissionBox: FC<Props> = ({ permission }) => {
  const { t } = useTranslation("permissions");
  permission = permission.replaceAll(":", "_");
  return (
    <VStack align="start">
      <HStack spacing={2}>
        <BsDot />
        <Text fontWeight="semibold">
          {t(`permissions.${permission}_title`)}
        </Text>
      </HStack>

      <Text px={6}>{t(`permissions.${permission}_description`)}</Text>
    </VStack>
  );
};

export default PermissionBox;
