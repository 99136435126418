import {
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
} from "@chakra-ui/react";
import { HTMLInputTypeAttribute } from "react";
import { AiOutlineClear } from "react-icons/ai";
import { useTranslation } from "react-i18next";

type TextInputProps = {
  value: string;
  // I pass the clearFilterValue parameter to control the filter reset on the column
  // (without this parameter, the filter makes a call even when the input is empty;
  // in the component where it is used, I verify that the filter can be updated with an empty input only if it is a reset).
  onChange?: (value: string, clearFilterValue?: boolean) => void;
  placeholder?: string;
  disableClear?: boolean;
  label?: string;
  isRequired?: boolean;
  error?: string;
  type?: HTMLInputTypeAttribute;
  readOnly?: boolean;
};

const TextInput = ({
  value,
  onChange,
  placeholder,
  label,
  disableClear,
  isRequired,
  type = "text",
  readOnly,
}: TextInputProps) => {
  const { t } = useTranslation();

  return (
    <FormControl variant="floating" isRequired={isRequired}>
      <InputGroup>
        <Input
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          placeholder={placeholder}
          type={type}
          isReadOnly={readOnly}
        />
        {!disableClear && (
          <Tooltip label={t("reset", { ns: "common" })}>
            <InputRightElement>
              <IconButton
                size="md"
                aria-label="clear"
                onClick={() => onChange("")}
                bg="transparent"
                _hover={{ bg: "transparent" }}
              >
                <AiOutlineClear />
              </IconButton>
            </InputRightElement>
          </Tooltip>
        )}
      </InputGroup>
      {label && <FormLabel>{label}</FormLabel>}
    </FormControl>
  );
};
export default TextInput;
