import UseCase from '../../../core/app/usecase';
import DocumentPreset from '../../entities/documentPreset';
import { DocumentPresetTarget } from '../../entities/documentPresetTarget.enum';
import DocumentsPresetsRepository from '../../repositories/documentsPresetsRepository';

interface Args {
	companyId: string;
	filter: DocumentPresetTarget;
	typologies: string[];
}

class GetDocumentPresets implements UseCase<Args, Array<DocumentPreset>> {
	constructor(private repository: DocumentsPresetsRepository) {}

	async Call(args: Args): Promise<Array<DocumentPreset>> {
		return await this.repository.getDocumentPresets(args.companyId, args.filter, args.typologies);
	}
}

export default GetDocumentPresets;