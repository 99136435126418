import Tool from '../../../entities/tool';
import UseCase from '../../../../core/app/usecase';
import ToolRepository from '../../../repositories/toolRepository';
import DeleteToolParameters from './deleteToolParameter';

class DeleteTool implements UseCase<DeleteToolParameters, void> {
	constructor(private repository: ToolRepository) {}

	async Call({ companyId, toolId }: DeleteToolParameters): Promise<void> {
		return await this.repository.deleteTool(companyId, toolId);
	}
}

export default DeleteTool;
