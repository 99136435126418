import { useTranslation } from "react-i18next";
import { Flex, Button, Text, Spacer } from "@chakra-ui/react";

const OnboardingFooter = () => {
  const { t } = useTranslation();

  return (
    <Flex width="100%">
      <Spacer />
      <Text fontSize="xs" marginRight={2}>
        {t("ui.need help?", { ns: "onboarding" })}
      </Text>
      <Button fontSize="xs" variant="link" color="#32A2DB">
        <a href="mailto:helpdesk@sikuro.it">
          {t("ui.contact us", { ns: "onboarding" })}
        </a>
      </Button>
      <Spacer />
    </Flex>
  );
};

export default OnboardingFooter;
