import {
  Box,
  Flex,
  HStack,
  Image,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { MdCheckCircle } from "react-icons/md";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { useAuth } from "../../../providers/Auth0JWTProvider";
import { CompaniesViewModel } from "../../../viewmodels/company/CompaniesList";
import BaseModal from "../../Common/alerts/BaseModal";
import ColumnFilterComponent, {
  ColumnFilterType,
} from "../../Common/table/ColumnFilterComponent";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import Company from "../../../../domain/entities/company";
import { LicenseType } from "../../../../domain/interactors/auth/args";
import { Alert } from "../../../screens/Common/Alert";
import { FaPlugCircleCheck, FaPlugCircleXmark } from "react-icons/fa6";
import { COLORS } from "../../../assets/theme/colors";
import SearchInput from "../../../screens/Common/SearchInput";

interface ChangeTenantModalProps {
  onClose: () => void;
  setSelectedTenant: (company: Company) => void;
  enableGetCompanies: boolean;
}
export type GetTenantsFilters = {
  name: string;
  image: any;
  licenseType: LicenseType | null;
  licenseState: string;
};

const ChangeTenantModal = ({
  onClose,
  setSelectedTenant,
  enableGetCompanies,
}: ChangeTenantModalProps) => {
  const { t } = useTranslation("common");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { companyId: defaultCompany, user, companyUser } = useAuth();
  const [sort, setSort] = useState<SortMeta>();
  const [search, setSearch] = useState<string>();
  const [filters, setFilters] = useState<GetTenantsFilters>();
  const [showDisabledCompany, setShowDisabledCompany] = useState(false);
  const viewModel = new CompaniesViewModel();

  const lastTenantSelected = localStorage.getItem("lastTenantSelected");
  const lastUserLogged = localStorage.getItem("lastUserLogged");

  const columns: {
    field: keyof GetTenantsFilters;
    type: "text" | "select" | "tags" | "licenseType";
    label: string;
    options?: Record<string, string>;
  }[] = [
    { field: "image", type: "text", label: "" },
    { field: "name", type: "text", label: t("tenant", { ns: "common" }) },
    {
      field: "licenseType",
      type: "licenseType",
      label: t("licenseType", { ns: "onboarding" }),
    },
    {
      field: "licenseState",
      type: "text",
      label: t("licenseState", { ns: "onboarding" }),
    },
  ];

  const selectLicenses = Object.values(LicenseType).map((type) => ({
    option: type,
    label: t(`${type}`, { ns: "onboarding" }),
  }));
  const selectLicenseStates = [
    {
      option: "enabled",
      label: t(`licenseStates.enabled`, { ns: "onboarding" }),
    },
    {
      option: "disabled",
      label: t(`licenseStates.disabled`, { ns: "onboarding" }),
    },
  ];
  const getCompanies = useInfiniteQuery(
    ["user-tenants", filters, sort, search],
    async ({ pageParam = 1 }) => {
      const companyFilters = search ? { ...filters, search } : filters;
      const tenants = viewModel.getCompanies(companyFilters, sort, pageParam);
      return await tenants;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetCompanies,
    },
  );

  const tenants = getCompanies.data?.pages?.flat() ?? [];
  const filterFields: GetTenantsFilters = filters || {
    name: "",
    image: null,
    licenseType: null,
    licenseState: "enabled",
  };
  const excludeSort = ["image"];

  const updateFilter = (field: keyof GetTenantsFilters, value: string) => {
    return setFilters({ ...filters, [field]: value });
  };

  const setTypeFilter = (column: string) => {
    let type: string;
    if (column === "licenseType") {
      type = "select";
    } else if (column === "licenseState") {
      type = "select";
    } else {
      type = "text";
    }
    return type;
  };
  const manageTenantSelection = (selectedTenant: Company) => {
    if (selectedTenant.state === "disabled") {
      setShowDisabledCompany(true);
    } else {
      setSelectedTenant(selectedTenant);
      localStorage.setItem(
        "lastTenantSelected",
        JSON.stringify(selectedTenant),
      );
      localStorage.setItem("lastUserLogged", JSON.stringify(user));
    }
  };
  //I need this effect to set the filter state to enabled by default.
  useEffect(() => {
    if (lastTenantSelected && lastUserLogged) {
      try {
        const parsedLastTenantSelected = JSON.parse(lastTenantSelected);
        const parsedLastUserLogged = JSON.parse(lastUserLogged);
        if (
          parsedLastTenantSelected.id !== defaultCompany &&
          parsedLastUserLogged.id === user.id
        ) {
          setSelectedTenant(parsedLastTenantSelected);
        }
      } catch (error) {
        console.error("Error parsing JSON from localStorage", error);
      }
    }
    updateFilter("licenseState", "enabled");
  }, [lastTenantSelected, defaultCompany]);

  return (
    <BaseModal
      title={t("changeTenant")}
      onClose={onClose}
      onCancel={false}
      size="5xl"
    >
      <Flex justifyContent={"end"} alignItems="center" w={"100%"} mb={4}>
        <SearchInput onSearch={setSearch} />
      </Flex>
      <InfiniteTable
        autosize
        bottomThreshold={250}
        infiniteScroll={{
          hasNextPage: getCompanies.hasNextPage,
          fetchNextPage: getCompanies.fetchNextPage,
          dataLength: tenants.length,
        }}
        showEmptyText={tenants.length === 0}
        emptyText={t("noTenants", { ns: "common" })}
        isLoading={getCompanies.isLoading}
        maxHeight="calc(100vh - 380px)"
        tableId="change-tenant-table"
      >
        <Thead>
          <Tr>
            {columns.map((column) => {
              return (
                <Th
                  width={column.field === "image" && "100px"}
                  key={column.field}
                >
                  <TableColumnHeader
                    text={column.label}
                    filter={
                      column.field !== "image" && {
                        component: (
                          <ColumnFilterComponent
                            type={
                              setTypeFilter(column.field) as ColumnFilterType
                            }
                            value={filterFields[column.field]}
                            updateFilter={(value) =>
                              updateFilter(column.field, value as string)
                            }
                            namespace="tenants"
                            selectOptions={
                              column.field === "licenseType"
                                ? selectLicenses
                                : selectLicenseStates
                            }
                          />
                        ),
                        isActive: !!(Array.isArray(filterFields[column.field])
                          ? filterFields[column.field][0]
                          : filterFields[column.field]),
                      }
                    }
                    sort={
                      excludeSort.includes(column.field)
                        ? undefined
                        : {
                            handler: (direction) =>
                              setSort({ field: column.field, direction }),
                            direction:
                              sort?.field === column.field
                                ? sort.direction
                                : null,
                          }
                    }
                  />
                </Th>
              );
            })}
            <Th width="95px"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {tenants &&
            tenants.map((company) => {
              return (
                <Tr
                  key={company.id}
                  onClick={() => manageTenantSelection(company)}
                  cursor={"pointer"}
                >
                  <Td width="100px">
                    {company?.logo ? (
                      <Image
                        src={company?.logo}
                        borderRadius="full"
                        w={"40px"}
                        h={"40px"}
                        border={company.id === defaultCompany && "2px solid"}
                        borderColor={
                          company.id === defaultCompany && "gray.600"
                        }
                      />
                    ) : (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        borderRadius="full"
                        boxSize="40px"
                        bg={"gray.400"}
                        border={company.id === defaultCompany && "2px solid"}
                        borderColor={
                          company.id === defaultCompany && "gray.600"
                        }
                      >
                        <Text textAlign={"center"} color={"#fff"}>
                          {Array.from(company.name)[0]}
                        </Text>
                      </Box>
                    )}
                  </Td>
                  <Td>{company.name}</Td>
                  <Td>
                    <Tag variant="outline">{company.type.toUpperCase()}</Tag>
                  </Td>
                  <Td>
                    <Tooltip
                      label={t("licenseStates." + company.state, {
                        ns: "onboarding",
                      })}
                    >
                      <HStack justifyContent={"center"}>
                        {company.state === "enabled" ? (
                          <IconContext.Provider
                            value={{ color: COLORS.sikuroBlue, size: "1.5rem" }}
                          >
                            <FaPlugCircleCheck />
                          </IconContext.Provider>
                        ) : (
                          <IconContext.Provider
                            value={{ color: COLORS.red, size: "1.5rem" }}
                          >
                            <FaPlugCircleXmark />
                          </IconContext.Provider>
                        )}
                      </HStack>
                    </Tooltip>
                  </Td>
                  <Td width="95px">
                    {company.id === defaultCompany &&
                      companyUser.state === "enabled" && (
                        <HStack justifyContent={"end"}>
                          <IconContext.Provider
                            value={{ color: "green", size: "1.5rem" }}
                          >
                            <MdCheckCircle />
                          </IconContext.Provider>
                        </HStack>
                      )}
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </InfiniteTable>

      {showDisabledCompany && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t("tenantMissingLicense", { ns: "common" })}
          variant="info"
          onClose={() => {
            setShowDisabledCompany(false);
          }}
        />
      )}
    </BaseModal>
  );
};

export default ChangeTenantModal;
