import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { RequirementSubject } from '../../entities/requirementSubject.enum';

type ARGS = {
	companyId: string;
	supplierId: string;
	resourceId: string;
	variantId?: string;
	type: RequirementSubject;
};

class UpdateCompanySupplierResourceVariant implements UseCase<ARGS, void> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, resourceId, variantId, type }: ARGS): Promise<void> {
		return await this.supplierRepository.updateCompanySupplierResourceVariant(companyId, supplierId, resourceId, type, variantId);
	}
}

export default UpdateCompanySupplierResourceVariant;
