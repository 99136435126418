import UseCase from '../../../../core/app/usecase';
import MachineRepository from '../../../repositories/machineRepository';

type CreateMachineDocumentParameters = {
	companyId: string;
	resourceId: string;
	documents: string[];
};

/**
 * Returns the list of all the existing Document.
 */
class CreateMachineDocument implements UseCase<CreateMachineDocumentParameters, void> {
	constructor(private repository: MachineRepository) {}

	async Call({ companyId, resourceId, documents }: CreateMachineDocumentParameters): Promise<void> {
		return await this.repository.createMachineDocument(companyId, resourceId, documents);
	}
}

export default CreateMachineDocument;
