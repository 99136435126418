import { makeSiteVehicleRepository } from './repositories';
import GetSiteVehicle from '../../../../domain/interactors/resources/siteVehicles/getSiteVehicle';
import GetSiteVehicles from '../../../../domain/interactors/resources/siteVehicles/getSiteVehicles';
import CreateSiteVehicle from '../../../../domain/interactors/resources/siteVehicles/createSiteVehicle';
import DeleteSiteVehicle from '../../../../domain/interactors/resources/siteVehicles/deleteSiteVehicle';
import RestoreSiteVehicle from '../../../../domain/interactors/resources/siteVehicles/restoreSiteVehicle';
import EvaluateSiteVehicle from '../../../../domain/interactors/resources/siteVehicles/evaluateSiteVehicle';
import GetWorkingSiteVehicles from '../../../../domain/interactors/resources/siteVehicles/getWorkingSiteVehicles';
import AutoEvaluateSiteVehicle from '../../../../domain/interactors/resources/siteVehicles/autoEvaluateSiteVehicle';
import GetSiteVehicleDocuments from '../../../../domain/interactors/resources/siteVehicles/getSiteVehicleDocuments';
import UpdateSiteVehicleVariant from '../../../../domain/interactors/resources/siteVehicles/updateSiteVehicleVariant';
import GetSiteVehicleEvaluations from '../../../../domain/interactors/resources/siteVehicles/getSiteVehicleEvaluations';
import AddRequirementToSiteVehicle from '../../../../domain/interactors/resources/siteVehicles/addRequirementToSiteVehicle';
import UpdateSiteVehicleGlobalStatus from '../../../../domain/interactors/resources/siteVehicles/updateSiteVehicleGlobalStatus';
import UpdateSiteVehicleSpecializations from '../../../../domain/interactors/resources/siteVehicles/updateSiteVehicleSpecializations';

export const makeGetSiteVehicles = () => {
	return new GetSiteVehicles(makeSiteVehicleRepository());
};

export const makeGetWorkingSiteVehicles = () => {
	return new GetWorkingSiteVehicles(makeSiteVehicleRepository());
};

export const makeGetSiteVehicle = () => {
	return new GetSiteVehicle(makeSiteVehicleRepository());
};

export const makeCreateSiteVehicle = () => {
	return new CreateSiteVehicle(makeSiteVehicleRepository());
};

export const makeDeleteSiteVehicle = () => {
	return new DeleteSiteVehicle(makeSiteVehicleRepository());
};

export const makeRestoreSiteVehicle = () => {
	return new RestoreSiteVehicle(makeSiteVehicleRepository());
};

export const makeGetSiteVehicleDocuments = () => {
	return new GetSiteVehicleDocuments(makeSiteVehicleRepository());
};

export const makeUpdateSiteVehicleVariant = () => {
	return new UpdateSiteVehicleVariant(makeSiteVehicleRepository());
};

export const makeUpdateSiteVehicleSpecializations = () => {
	return new UpdateSiteVehicleSpecializations(makeSiteVehicleRepository());
};

export const makeEvaluateSiteVehicle = () => {
	return new EvaluateSiteVehicle(makeSiteVehicleRepository());
};

export const makeAutoEvaluateSiteVehicle = () => {
	return new AutoEvaluateSiteVehicle(makeSiteVehicleRepository());
};
export const makeGetSiteVehicleEvaluations = () => {
	return new GetSiteVehicleEvaluations(makeSiteVehicleRepository());
};

export const makeAddRequirementToSiteVehicle = () => {
	return new AddRequirementToSiteVehicle(makeSiteVehicleRepository());
};

export const makeUpdateSiteVehicleGlobalStatus = () => {
	return new UpdateSiteVehicleGlobalStatus(makeSiteVehicleRepository());
};
