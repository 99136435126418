import Worker from '../../../entities/worker';
import UseCase from '../../../../core/app/usecase';
import WorkerRepository from '../../../repositories/workerRepository';
import GetWorkerParameters from './getWorkerParameter';

class GetWorker implements UseCase<GetWorkerParameters, Worker | undefined> {
	constructor(private repository: WorkerRepository) {}

	async Call({ companyId, workerId }: GetWorkerParameters): Promise<Worker | undefined> {
		return this.repository.getWorkerById(companyId, workerId);
	}
}

export default GetWorker;
