import { Box, Select, Skeleton, Text } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";

interface EvaluationComponentProps {
  documentsFetching: boolean;
  readOnly: boolean;
  selectedState: ResourceDocumentEvaluationState;
  handleEvaluation: (value: ResourceDocumentEvaluationState) => void;
  setSelectedState: Dispatch<SetStateAction<ResourceDocumentEvaluationState>>;
  action: "add" | "delete" | "evaluate" | "edit" | "editFile" | string | null;
}

export const RequirementEvaluationComponent = ({
  documentsFetching,
  readOnly,
  selectedState,
  handleEvaluation,
  setSelectedState,
  action,
}: EvaluationComponentProps) => {
  const { t } = useTranslation("documents");

  return (
    <Box>
      <Text fontWeight={600} pb={1}>
        {t("evaluationDetails")}
      </Text>
      {!documentsFetching ? (
        <Select
          placeholder={t("selectEvaluationState")}
          _placeholder={{ fontSize: "12px" }}
          size={"sm"}
          value={selectedState || ""}
          onChange={(e) => (
            handleEvaluation(e.target.value as ResourceDocumentEvaluationState),
            setSelectedState(e.target.value as ResourceDocumentEvaluationState)
          )}
          isDisabled={readOnly || action !== null}
        >
          <option value={ResourceDocumentEvaluationState.AVAILABLE}>
            {t(
              "combinedEvaluation." + ResourceDocumentEvaluationState.AVAILABLE,
              { ns: "enum" },
            )}
          </option>
          <option value={ResourceDocumentEvaluationState.NOT_AVAILABLE}>
            {t(
              "combinedEvaluation." +
                ResourceDocumentEvaluationState.NOT_AVAILABLE,
              { ns: "enum" },
            )}
          </option>
          <option value={ResourceDocumentEvaluationState.NOT_APPLICABLE}>
            {t(
              "combinedEvaluation." +
                ResourceDocumentEvaluationState.NOT_APPLICABLE,
              { ns: "enum" },
            )}
          </option>
        </Select>
      ) : (
        <Skeleton
          height={"30px"}
          w={"100%"}
          startColor={"gray.300"}
          endColor={"gray.300"}
        />
      )}
    </Box>
  );
};
