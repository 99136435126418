import { injectable } from 'tsyringe';
import { DashboardChartData, DashboardCount } from '../../domain/entities/dashboardCount';
import { DashboardUnevaluatedDocument } from '../../domain/entities/dashboardUnevaluatedDocuments';
import { SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import DashboardRepository, { GetSupplierEvaluationsFilter } from '../../domain/repositories/dashboardRepository';
import { ApiService } from '../utilities/apiService';
import { DashboardTopic } from '../../domain/entities/dashboardTopic';
import { DashboardSupplierInfo } from '../../domain/entities/dashboardSupplierInfo';
import { DashboardCompanyExpiringDocument } from '../../domain/entities/dashboardCompanyExpiringDocument';
import { DashboardExpiringSupplierEvaluation } from '../../domain/entities/dasboardExpiringSupplierEvaluation';

@injectable()
class ServerDashboardRepository implements DashboardRepository {
	constructor(private apiService: ApiService) {}

	async getDashboardCount(companyId: string, topic: DashboardTopic, days?: number): Promise<DashboardCount> {
		try {
			const params = topic === 'expiring-documents' || (topic === 'expiring-evaluations' && days) ? `?days=${days}` : '';
			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/dashboard/count/${topic}${params}`
			);
			if (!response.ok) {
				return Promise.reject(new Error('error.unauthorized'));
			}

			const payload = await response.json();
			return payload;
		} catch (e) {
			return Promise.reject(new Error('error.unauthorized'));
		}
	}

	async getDashboardDocuments(
		companyId: string,
		topic: DashboardTopic,
		pageParam: number,
		sort: SortMeta,
		days?: number
	): Promise<DashboardUnevaluatedDocument[]> {
		try {
			const params = new URLSearchParams({ page: pageParam.toString(), perPage: String(15), ...sort });

			if (topic === 'expiring-documents' || (topic === 'expiring-evaluations' && days)) {
				params.append('days', String(days));
			}

			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/dashboard/report/${topic}?${params.toString()}`
			);
			const payload = await response.json();
			return payload.results;
		} catch (e) {
			return Promise.reject(new Error('error.unauthorized'));
		}
	}

	async getDashboardChartData(companyId: string, topic: DashboardTopic, days?: number): Promise<DashboardChartData> {
		try {
			const params = topic === 'expiring-documents' || (topic === 'expiring-evaluations' && days) ? `?days=${days}` : '';
			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/dashboard/count/${topic}${params}`
			);
			const payload = await response.json();
			return { ...payload, count: payload.count, partialCount: payload.partial_count };
		} catch (e) {
			return Promise.reject(new Error('error.unauthorized'));
		}
	}

	async getDashboardSupplierInfo(companyId: string, topic: DashboardTopic, pageParam: number, sort: SortMeta): Promise<DashboardSupplierInfo[]> {
		try {
			const params = new URLSearchParams({ page: pageParam.toString(), perPage: String(15), ...sort });
			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/dashboard/report/${topic}?${params.toString()}`
			);
			const payload = await response.json();
			const remappedObjectResults: DashboardSupplierInfo[] = payload.results.map((item: DashboardSupplierInfo) => ({
				supplierId: item.supplierId,
				supplier: item.supplier,
				siteId: item.siteId,
				site: item.site,
				resource: item.resourceType,
				date: item.date,
				isOwnedSite: item.isOwnedSite,
			}));

			return remappedObjectResults;
		} catch (e) {
			return Promise.reject(new Error('error.unauthorized'));
		}
	}

	async getDashboardCompanyExpiringDocuments(
		companyId: string,
		topic: DashboardTopic,
		pageParam: number,
		sort: SortMeta,
		days?: number
	): Promise<DashboardCompanyExpiringDocument[]> {
		try {
			const params = new URLSearchParams({ page: pageParam.toString(), perPage: String(15), ...sort });
			if (days) {
				params.append('days', String(days));
			}

			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/dashboard/report/${topic}?${params.toString()}`
			);
			const payload = await response.json();
			const remappedObjectResults: DashboardCompanyExpiringDocument[] = payload.results.map(
				(item): DashboardCompanyExpiringDocument => ({
					id: item.id,
					name: item.name,
					resource: item.resource,
					resourceId: item.resource_id,
					resourceName: item.resource_name,
					expiresAt: item.expires_at,
					expiresIn: item.expires_in,
				})
			);

			return remappedObjectResults;
		} catch (e) {
			return Promise.reject(new Error('error.unauthorized'));
		}
	}
	async getDashboardExpiringResourcesEvaluations(
		companyId: string,
		pageParam: number,
		sort: SortMeta,
		filter: GetSupplierEvaluationsFilter,
		days?: number
	): Promise<DashboardExpiringSupplierEvaluation[]> {
		try {
			const params = new URLSearchParams({ page: pageParam.toString(), perPage: String(15), ...sort, ...filter });
			if (days) {
				params.append('days', String(days));
			}

			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/dashboard/report/expiring-resources-evaluations?${params.toString()}`
			);
			const payload = await response.json();

			const remappedObjectResults: DashboardExpiringSupplierEvaluation[] = payload.results.map(
				(item): DashboardExpiringSupplierEvaluation => ({
					id: item.id,
					name: item.name,
					expiresAt: item.expires_at,
					expiresIn: item.expires_in,
					resourceId: item.resource_id,
					resource: item.resource,
					resourceName: item.resource_name,
					siteId: item.site_id,
					siteName: item.site_name,
				})
			);

			return remappedObjectResults;
		} catch (e) {
			return Promise.reject(new Error('error.unauthorized'));
		}
	}

	async getDashboardExpiringCompanyOrSiteEvaluations(
		companyId: string,
		pageParam: number,
		sort: SortMeta,
		filter: GetSupplierEvaluationsFilter,
		days?: number
	): Promise<DashboardExpiringSupplierEvaluation[]> {
		try {
			const params = new URLSearchParams({ page: pageParam.toString(), perPage: String(15), ...sort, ...filter });
			if (days) {
				params.append('days', String(days));
			}

			const response = await this.apiService.fetchWithToken(
				`${
					process.env.REACT_APP_SERVER_API_ENDPOINT
				}/companies/${companyId}/dashboard/report/expiring-company-or-site-evaluations?${params.toString()}`
			);
			const payload = await response.json();

			const remappedObjectResults: DashboardExpiringSupplierEvaluation[] = payload.results.map(
				(item): DashboardExpiringSupplierEvaluation => ({
					id: item.id,
					name: item.name,
					expiresAt: item.expires_at,
					expiresIn: item.expires_in,
					resourceId: item.resource_id,
					resource: item.resource,
					resourceName: item.resource_name,
					siteId: item.site_id,
					siteName: item.site_name,
				})
			);

			return remappedObjectResults;
		} catch (e) {
			return Promise.reject(new Error('error.unauthorized'));
		}
	}

	async getDashboardExpiringCompanyDocuments(
		companyId: string,
		pageParam: number,
		sort: SortMeta,
		days?: number
	): Promise<DashboardCompanyExpiringDocument[]> {
		try {
			const params = new URLSearchParams({ page: pageParam.toString(), perPage: String(15), ...sort });
			if (days) {
				params.append('days', String(days));
			}
			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/dashboard/report/expiring-company-documents?${params.toString()}`
			);
			const payload = await response.json();

			const remappedObjectResults: DashboardCompanyExpiringDocument[] = payload.results.map(
				(item): DashboardCompanyExpiringDocument => ({
					id: item.id,
					name: item.name,
					expiresAt: item.expires_at,
					expiresIn: item.expires_in,
					resourceId: item.resource_id,
					resource: item.resource,
					resourceName: item.resource_name,
				})
			);

			return remappedObjectResults;
		} catch (e) {
			return Promise.reject(new Error('error.unauthorized'));
		}
	}
}

export default ServerDashboardRepository;
