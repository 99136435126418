import { makeToolRepository } from './repositories';
import GetTool from '../../../../domain/interactors/resources/tools/getTool';
import GetTools from '../../../../domain/interactors/resources/tools/getTools';
import CreateTool from '../../../../domain/interactors/resources/tools/createTool';
import UpdateTool from '../../../../domain/interactors/resources/tools/updateTool';
import DeleteTool from '../../../../domain/interactors/resources/tools/deleteTool';
import GetToolDocuments from '../../../../domain/interactors/resources/tools/getToolDocuments';
import CreateToolDocument from '../../../../domain/interactors/resources/tools/createToolDocument';
import AddTypologyToTool from '../../../../domain/interactors/resources/tools/addTypologyToTool';
import RemoveTypologyFromTool from '../../../../domain/interactors/resources/tools/removeTypologyFromTool';
import UploadTools from '../../../../domain/interactors/resources/tools/uploadTools';
import CreatePropagableToolDocument from '../../../../domain/interactors/resources/tools/createPropagableToolDocuments';
import EvaluateToolDocument from '../../../../domain/interactors/resources/tools/evaluateToolDocument';
import GetAvailableToolsCount from '../../../../domain/interactors/resources/tools/getAvailableToolsCount';
import RestoreTool from '../../../../domain/interactors/resources/tools/restoreTool';
import RepropagateEvaluationToolDocument from '../../../../domain/interactors/resources/tools/repropagateEvaluationToolDocument';

export const makeGetTools = () => {
	return new GetTools(makeToolRepository());
};

export const makeRestoreTool = () => {
	return new RestoreTool(makeToolRepository());
};

export const makeGetAvailableToolsCount = () => {
	return new GetAvailableToolsCount(makeToolRepository());
};

export const makeGetTool = () => {
	return new GetTool(makeToolRepository());
};

export const makeCreateTool = () => {
	return new CreateTool(makeToolRepository());
};
export const makeUploadTools = () => {
	return new UploadTools(makeToolRepository());
};
export const makeUpdateTool = () => {
	return new UpdateTool(makeToolRepository());
};

export const makeDeleteTool = () => {
	return new DeleteTool(makeToolRepository());
};

export const makeGetToolDocuments = () => {
	return new GetToolDocuments(makeToolRepository());
};

export const makeCreateToolDocument = () => {
	return new CreateToolDocument(makeToolRepository());
};

export const makeCreatePropagableToolDocument = () => {
	return new CreatePropagableToolDocument(makeToolRepository());
};

export const makeAddTypologyToTool = () => {
	return new AddTypologyToTool(makeToolRepository());
};

export const makeRemoveTypologyFromTool = () => {
	return new RemoveTypologyFromTool(makeToolRepository());
};

export const makeEvaluateToolDocument = () => {
	return new EvaluateToolDocument(makeToolRepository());
};

export const makeRepropagateEvaluationToolDocument = () => {
	return new RepropagateEvaluationToolDocument(makeToolRepository());
}
