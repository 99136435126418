import { injectable } from 'tsyringe';
import { ApiService } from '../utilities/apiService';
import WebHookRepository from '../../domain/repositories/webHookRepository';
import { WebHook } from '../../domain/entities/webHook';

@injectable()
class ServerWebHookRepository implements WebHookRepository {
	constructor(private apiService: ApiService) {}

	async get(companyId: string): Promise<WebHook[]> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/webhooks`);
		const { results } = await response.json();
		return results;
	}

	async getEvents(companyId: string): Promise<string[]> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/webhooks/events`);
		return response.json();
	}

	async set(companyId: string, webHooks: WebHook[]): Promise<void> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/webhooks`, {
			method: 'POST',
			body: JSON.stringify({ webhooks: webHooks }),
			headers: { 'Content-Type': 'application/json' },
		});
		if (!response.ok) {
			const data = await response.json();
			throw new Error(response.statusText);
		}
	}
}

export default ServerWebHookRepository;
