import { useTranslation } from "react-i18next";
import { CompanyResourceType } from "../../../domain/entities/companyResourceType.enum";
import { FC } from "react";
import { COLORS } from "../../assets/theme/colors";
import { Button, ButtonGroup } from "@chakra-ui/react";

interface Props {
  showArchived: boolean;
  type: CompanyResourceType;
  selectTab: (flag: boolean) => void;
}

const ArchivedResourceToggle: FC<Props> = ({
  showArchived,
  type,
  selectTab,
}) => {
  const { t } = useTranslation("siteResources");

  return (
    <ButtonGroup isAttached>
      <Button
        variant={showArchived ? "outline" : "solid"}
        sx={{
          borderRadius: 10,
          color: !showArchived ? "white" : COLORS.sikuroBlue,
          backgroundColor: showArchived ? "white" : COLORS.sikuroBlue,
        }}
        onClick={() => selectTab(false)}
      >
        {t("active", { context: type })}
      </Button>
      <Button
        variant={!showArchived ? "outline" : "solid"}
        sx={{
          borderRadius: 10,
          color: showArchived ? "white" : COLORS.sikuroBlue,
          backgroundColor: !showArchived ? "white" : COLORS.sikuroBlue,
        }}
        onClick={() => selectTab(true)}
      >
        {t("archived", { context: type })}
      </Button>
    </ButtonGroup>
  );
};

export default ArchivedResourceToggle;
