import UseCase from '../../../core/app/usecase';
import Document from '../../entities/document';
import DocumentRepository from '../../repositories/documentRepository';

type UpdateDocumentParameters = {
	companyId: string;
	document: Document;
	resourceId?: string;
	target?: string;
	siteId?: string;
	siteIds?: string[];
	selectAll?: boolean;
};
/**
 * Update the selected Docuemnt
 *
 * @param document the document that will be updated
 */
class UpdateDocument implements UseCase<UpdateDocumentParameters, void> {
	constructor(private repository: DocumentRepository) {}

	async Call({ companyId, document, resourceId, target, siteId, siteIds, selectAll }: UpdateDocumentParameters): Promise<void> {
		return await this.repository.updateDocument(companyId, document, resourceId, target, siteId, siteIds, selectAll);
	}
}

export default UpdateDocument;
