import { useInfiniteQuery } from "@tanstack/react-query";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { useAuth } from "../../providers/Auth0JWTProvider";
import MessageViewModel from "../../viewmodels/documents/MessageViewModel";

const useMessageViewModel = (
  groupId: string,
  queryEnabled: boolean,
  sort?: SortMeta,
) => {
  const { companyId } = useAuth();
  const viewModel = new MessageViewModel();

  const getDocumentCommentGroup = useInfiniteQuery(
    ["badges", companyId, groupId],
    async ({ pageParam = 1 }) => {
      const { results } = await viewModel.getDocumentCommentGroup(
        companyId,
        groupId,
        pageParam,
        sort,
      );

      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: queryEnabled,
    },
  );

  const groupComments = getDocumentCommentGroup.data?.pages?.flat() ?? [];

  return {
    resourceDocumentCommentGroup: groupComments,
    getDocumentCommentGroupRefetch: getDocumentCommentGroup.refetch,
    isFetchingGetDocumentCommentGroup: getDocumentCommentGroup.isFetching,
    groupHasNextPage: getDocumentCommentGroup.hasNextPage,
    groupFetchNextPage: getDocumentCommentGroup.fetchNextPage,
    resourceDocumentCommentGroupFetching:
      getDocumentCommentGroup.isFetchingNextPage,
  };
};

export default useMessageViewModel;
