import Chemical from '../../../entities/chemical';
import UseCase from '../../../../core/app/usecase';
import ChemicalRepository from '../../../repositories/chemicalRepository';

type ARGS = {
	companyId: string;
	chemical: Chemical;
	imageFile: File;
};

class UpdateChemical implements UseCase<ARGS, Chemical> {
	constructor(private repository: ChemicalRepository) {}

	async Call(parameters: ARGS): Promise<Chemical> {
		return await this.repository.updateChemical(parameters.companyId, parameters.chemical, parameters.imageFile);
	}
}

export default UpdateChemical;
