import UseCase from '../../../../core/app/usecase';
import SiteToolRepository from '../../../repositories/siteToolRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	toolId: string;
	variantId?: string;
};

class UpdateSiteToolVariant implements UseCase<Parameters, void | undefined> {
	constructor(private repository: SiteToolRepository) {}

	async Call({ companyId, siteId, toolId, variantId }: Parameters): Promise<void | undefined> {
		return this.repository.updateSiteToolVariant(companyId, siteId, toolId, variantId);
	}
}

export default UpdateSiteToolVariant;
