import { UserSiteRole } from '../../domain/entities/user';
import Role, { RoleContext } from '../../domain/entities/role';
import RolesRepository from '../../domain/repositories/rolesRepository';
import { GetSiteRolesFilter } from '../../domain/repositories/filters';

import {
	processArrayResponse,
	processBoolResponse,
	processResponse,
	ApiService,
	getRequest,
	postRequest,
	putRequest,
	patchRequest,
	deleteRequest,
} from '../utilities/apiService';
import { GetWorkingSiteFilter } from '../../domain/repositories/supplierRepository';
import { SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import Site from '../../domain/entities/site';

class ServerRolesRepository implements RolesRepository {
	constructor(private apiService: ApiService) {}

	private baseURL = (companyId?: string): string => {
		const root = `${process.env.REACT_APP_SERVER_API_ENDPOINT}`;
		return companyId ? `${root}/companies/${companyId}` : root;
	};

	async getRoles(companyId: string, nameDesc?: boolean, nameAsc?: boolean, contextFilter?: RoleContext, nameFilter?: string): Promise<Array<Role>> {
		let url = `${this.baseURL(companyId)}/roles`;
		const params: string[] = [];
		if (nameFilter) {
			params.push(`name=${nameFilter}`);
		}

		if (nameDesc === true) {
			params.push('field=name&direction=desc');
		}

		if (nameAsc === true) {
			params.push('field=name&direction=asc');
		}

		if (contextFilter === RoleContext.GENERAL) {
			params.push('context=general');
		} else if (contextFilter === RoleContext.SITE) {
			params.push('context=site');
		} else if (contextFilter === RoleContext.WORKSITE) {
			params.push('context=worksite');
		}

		if (params.length > 0) {
			url = `${url}?${params.join('&')}`;
		}

		const response = await this.apiService.fetchWithToken(url, getRequest());
		return processArrayResponse(response);
	}

	async getRole(companyId: string, roleId: string): Promise<Role> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/roles/${roleId}`);

		return processResponse(response);
	}

	async createRole(companyId: string, name: string, context: RoleContext): Promise<Role> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/roles`, postRequest(JSON.stringify({ name, context })));

		return processResponse(response);
	}

	async updateRole(companyId: string, roleId: string, permissions: Array<string>): Promise<Role> {
		const response = await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/roles/${roleId}`,
			putRequest(JSON.stringify({ permissions })),
		);

		return processResponse(response);
	}

	async updateRoleName(companyId: string, roleId: string, name: string): Promise<Role> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/roles/${roleId}`, patchRequest(JSON.stringify({ name })));

		return processResponse(response);
	}

	async roleLinkedUsers(companyId: string, roleId: string, context: string): Promise<number> {
		const response = await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/roles/${roleId}/count-users?context=${context}`,
			getRequest(),
		);

		return processResponse(response);
	}

	async deleteRole(companyId: string, roleId: string, contextRole: string, newRoleId?: string): Promise<boolean> {
		const params = {
			newRoleId: contextRole === 'general' ? newRoleId : null,
			context: contextRole,
		};
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/roles/${roleId}`, {
			method: 'DELETE',
			body: JSON.stringify(params),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return processBoolResponse(response);
	}

	async linkUserToRole(companyId: string, userId: string, roleId: string): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/users/${userId}/roles${roleId}`, putRequest());

		return processBoolResponse(response);
	}

	async unlinkUserFromRole(companyId: string, userId: string, roleId: string): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/users/${userId}/roles${roleId}`, deleteRequest());

		return processBoolResponse(response);
	}

	async getUserSiteRoles(companyId: string, userId: string, filters?: GetSiteRolesFilter): Promise<Array<UserSiteRole>> {
		const { sort = {}, ...queryfilters } = filters;
		const params = new URLSearchParams({ ...queryfilters, ...sort });
		const url = `${this.baseURL(companyId)}/users/${userId}/siteRoles?${params.toString()}`;
		const response = await this.apiService.fetchWithToken(url, getRequest());
		return processArrayResponse(response);
	}

	async linkUserSiteRole(companyId: string, userId: string, siteId: string, roleId: string): Promise<UserSiteRole> {
		const response = await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/users/${userId}/siteRoles`,
			putRequest(JSON.stringify({ siteId, roleId })),
		);

		return processResponse(response);
	}

	async unlinkUserSiteRole(companyId: string, userId: string, siteId: string, roleId: string): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/users/${userId}/sites/${siteId}/roles/${roleId}`,
			deleteRequest(),
		);

		return processBoolResponse(response);
	}

	async getAvailableSites(companyId: string): Promise<Site[]> {
		// FIXME: for now, this call is made whenever a user wants to assign
		//        a site-role to a user it controls for a site it owns.
		//        Future iterations should take into consideration suppliers and sub-suppliers authorization workflows.
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/owned-all`);
		const { results } = await response.json();
		return results;
	}

	async getAvailableWorkingSites(companyId: string, filter?: GetWorkingSiteFilter, sort?: SortMeta): Promise<Site[]> {
		const params = new URLSearchParams({
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/working-all?${params.toString()}`,
		);
		const { results } = await response.json();
		return results;
	}
}

export default ServerRolesRepository;
