import { useMemo, useState } from "react";
import Site from "../../../domain/entities/site";
import Typology from "../../../domain/entities/typology";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import RequirementsGroup from "../../../domain/entities/requirementsGroup";
import SitesListViewModel from "../../viewmodels/sites/SitesListViewModel";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import Variant from "../../../domain/entities/variant";
import { updateFilterWithDelete } from "../../../utils";
import {CustomField} from "../../../domain/entities/customFields";

export interface CreateArgs {
  site: Site;
  typology?: Typology;
  requirementsGroup?: RequirementsGroup;
  siteVariant: Variant;
}

const useSitesListViewModel = (withPermissions?: boolean) => {
  const { companyId } = useAuth();
  const viewModel = useMemo(
    () => new SitesListViewModel(companyId),
    [companyId]
  );
  const [sort, setSort] = useState<SortMeta>();
  const [siteCreationActive, setSiteCreationActive] = useState(false);
  const [customFieldsEnabled, setCustomFieldsEnabled] = useState(false);
  const [search, setSearch] = useState<string>();
  const [filterSites, setFilterSites] = useState<GetSitesFilter>({
    state: "active",
  });
  const [creationRequirementGroup, setCreationRequirementGroup] = useState("");

  const getSites = useInfiniteQuery<Site[]>(
    ["get-sites", filterSites, search, sort, companyId],
    async ({ pageParam = 1 }) => {
      const filters = search ? { ...filterSites, search } : filterSites;
      return await viewModel.get(filters, sort, pageParam, withPermissions);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const getCustomFieldsQuery = useQuery<CustomField[], Error>(
    ["site-custom-fields", companyId],
    async () => await viewModel.getCustomFields(companyId),
    {
      enabled: customFieldsEnabled,
    }
  );

  const getRequirementGroupsQuery = useQuery(
    ["get-requirements-groups", companyId],
    async () => viewModel.getRequirementsGroups(),
    {
      enabled: siteCreationActive,
    }
  );

  const getTypologiesQuery = useQuery(
    ["get-typologies", companyId],
    async () => viewModel.getTypologies(),
    {
      enabled: siteCreationActive,
    }
  );

  const createMut = useMutation(
    ({
      site,
      requirementsGroup,
      typology,
      siteVariant,
    }: CreateArgs) =>
      viewModel.create(
        site,
        requirementsGroup,
        typology,
        siteVariant
      ),
    { onError: (err) => console.error("cannot create site", err) }
  );

  const createSite = async (args: CreateArgs, onComplete?: () => void) => {
    try {
      await createMut.mutateAsync(args);
      onComplete?.();
      getSites.refetch();
    } catch {
      // Nothing to do: simple error logging already managed by upsertMut.
    }
  };

  const updateFilterSites = (
    field: string,
    value: string | string[] | [Date, Date]
  ) => {
    updateFilterWithDelete(setFilterSites, field, value);
  };

  const siteVariantsQuery = useQuery(
    ["site-variants", companyId, creationRequirementGroup],
    () => viewModel.getSiteVariants(companyId, creationRequirementGroup),
    {
      enabled: !!creationRequirementGroup,
    }
  );

  const sites = getSites.data?.pages?.flat() ?? [];

  return {
    sites,
    isFetching: getSites.isLoading,
    createSite,
    sort,
    setSort,
    getSites,
    createSiteIsLoading: createMut.isLoading,
    getSitesHasNextPage: getSites.hasNextPage,
    getSitesFetchNextPage: getSites.fetchNextPage,
    typologies: getTypologiesQuery.data ?? [],
    requirementsGroups: getRequirementGroupsQuery.data ?? [],
    siteVariants: siteVariantsQuery.data ?? [],
    setCreationRequirementGroup,
    setSiteCreationActive,
    filterSites,
    setFilterSites,
    updateFilterSites,
    setSearch,
    customFields: {
      data: getCustomFieldsQuery.data,
      isLoading: getCustomFieldsQuery.isLoading,
      error: getCustomFieldsQuery.error
    },
    setCustomFieldsEnabled,
    loading: getSites.isLoading
  };
};

export default useSitesListViewModel;
