import { FC } from "react";
import { Select } from "@chakra-ui/react";
import BaseFormField, { EditableFormFieldProps } from "./FormField";
import { Named } from "../../../../domain/entities/interfaces/named";
import { Identifiable } from "../../../../domain/entities/interfaces/identifiable";

interface FormSelectFieldProps extends EditableFormFieldProps {
  value?: string;
  options?: Array<Identifiable & Named>;
  applyConditionalStyles?: boolean;
  selectedBackgroundColor?: string;
  selectedTextColor?: string;
  placeHolder?: string;
}

const FormSelectField: FC<FormSelectFieldProps> = ({
  value,
  options,
  applyConditionalStyles,
  name,
  label,
  isEditing = false,
  displayValue,
  rules,
  onChange,
  selectedBackgroundColor = "white", // Default background color
  selectedTextColor = "black", // Default text color
  placeHolder,
}) => {
  const select = (
    <Select
      value={value}
      onChange={onChange}
      placeholder={placeHolder}
      sx={{
        bg: selectedBackgroundColor,
        color: selectedTextColor,
        option: {
          bg: selectedBackgroundColor,
          color: selectedTextColor,
        },
        _focus: {
          borderColor: "blue.500",
        },
      }}
    >
      {options?.map((c) => (
        <option
          key={c.id}
          value={c.id}
          style={{
            backgroundColor: selectedBackgroundColor,
            color: selectedTextColor,
          }}
        >
          {c.name}
        </option>
      ))}
    </Select>
  );

  return (
    <BaseFormField
      edit={select}
      name={name}
      label={label}
      isEditing={isEditing}
      displayValue={displayValue}
      rules={rules}
      onChange={onChange}
      applyConditionalStyles={applyConditionalStyles}
      selectedBackgroundColor={selectedBackgroundColor}
      selectedTextColor={selectedTextColor}
    />
  );
};

export default FormSelectField;
