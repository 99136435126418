import { useParams } from "react-router-dom";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SiteToolParams } from "../../../domain/entities/siteTool";
import { Permission } from "../../components/Permissions/Permissions";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";
import useWorkingSiteToolsViewModel from "../../hooks/WorkingSite/useWorkingSiteToolsViewModel";

const SiteToolsView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["name", "serial"];
  return (
    <SiteResourcesListView<SiteToolParams>
      type={DocumentTypeCategory.TOOL}
      useViewModel={useWorkingSiteToolsViewModel(
        siteId,
        resourceColumns,
        DocumentTypeCategory.TOOL,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[Permission.Worksite_AttachTool]}
      removeFromSitePermissions={[Permission.Worksite_DetachTool]}
    />
  );
};

export default SiteToolsView;
