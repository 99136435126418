import { injectable } from "tsyringe";
import Site, { ResourceSites, SiteState } from "../../domain/entities/site";
import Typology from "../../domain/entities/typology";
import DocumentType, { RenameModel } from "../../domain/entities/documentType";
import Document, { SiteResourceDocument } from "../../domain/entities/document";
import Requirement from "../../domain/entities/requirement";
import SiteRepository, {
	AddSiteUser,
	DocumentTypeAdd,
	GetResourceSitesFilter,
	GetSitesFilter,
	LinkResourcesToSiteWarning
} from "../../domain/repositories/siteRepository";
import RequirementsGroup from "../../domain/entities/requirementsGroup";
import { PaginatedResults, SortMeta } from "../../domain/entities/interfaces/paginatedResults";
import { GetDocumentsFilter } from "../../domain/repositories/documentRepository";
import { removeEmptyAttributes, removeEmptyProperties } from "../../utils";
import { RequirementSubject } from "../../domain/entities/requirementSubject.enum";
import SiteOptions, { ScheduleEmail } from "../../domain/entities/siteOptions";
import { Evaluable } from "../../domain/entities/evaluable.enum";
import PendingEvaluation from "../../domain/entities/pending-evaluation";
import { GetPendingEvaluationsResponse, mapPendingEvaluationsResponse } from "../responses/sites";
import { ApiService } from "../utilities/apiService";
import { dateIntervals } from "../utilities/filters";
import Variant from "../../domain/entities/variant";
import { PendingEvaluationsFilters } from "../../presentation/hooks/Site/useSiteApproveEvaluationsViewModel";
import { DocumentTypeCategory } from "../../domain/entities/documentTypeCategory.enum";
import { GetSiteChemicalsResponse } from "../responses/siteChemicals";
import { mapSiteChemical, SiteChemical } from "../../domain/entities/siteChemical";
import { GetSiteChemicalsFilters } from "../../domain/repositories/siteChemicalRepository";
import { GetSiteWorkersResponse } from "../responses/siteWorkers";
import { mapSiteWorker, SiteWorker } from "../../domain/entities/siteWorker";
import { GetSiteWorkersFilters } from "../../domain/repositories/siteWorkerRepository";
import { GetSiteMachinesResponse } from "../responses/siteMachines";
import { mapSiteMachine, SiteMachine } from "../../domain/entities/siteMachine";
import { GetSiteMachinesFilters } from "../../domain/repositories/siteMachineRepository";
import { GetSiteToolsFilters } from "../../domain/repositories/siteToolRepository";
import { mapSiteTool, SiteTool } from "../../domain/entities/siteTool";
import { GetSiteToolsResponse } from "../responses/siteTools";
import { GetSiteVehiclesResponse } from "../responses/siteVehicles";
import { mapSiteVehicle, SiteVehicle } from "../../domain/entities/siteVehicle";
import { GetSiteVehiclesFilters } from "../../domain/repositories/siteVehicleRepository";
import { ResourceType } from "../../presentation/screens/Site/ResourceSelectableTable";
import { mapApiResponseToDocument } from "../adapters/getResourceDocuments";
import User from "../../domain/entities/user";
import { GetUsersSiteFilter } from "../../domain/repositories/filters";
import { GetResourcesFilter } from "../../domain/repositories/workerRepository";
import FileEntity from "../../domain/entities/file";

@injectable()
class ServerSiteRepository implements SiteRepository {
	constructor(private apiService: ApiService) { }

	private baseURL = (companyId: string): string => {
		return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}`;
	};

	// Public.
	async getSites(companyId: string, filter?: GetSitesFilter, sort?: SortMeta, pageParam?: number, withPermissions?: boolean): Promise<Site[]> {
		const params = new URLSearchParams({
			withPermissions: withPermissions ? 'true' : 'false',
			...(pageParam ? { page: pageParam.toString(), perPage: String(25) } : {}),
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/owned?${params.toString()}`);
		const { results } = await response.json();
		return results;
	}

	async getSitesForRoleAssignment(companyId: string): Promise<Site[]> {
		// FIXME: for now, this call is made whenever a user wants to assign
		//        a site-role to a user it controls for a site it owns.
		//        Future iterations should take into consideration suppliers and sub-suppliers authorization workflows.
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/owned`);
		const { results } = await response.json();
		return results;
	}

	async getSite(companyId: string, siteId: string): Promise<Site> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}`);
		return response.json();
	}

	async getSiteUsers(companyId: string, siteId: string, pageParam?: number, filter?: GetUsersSiteFilter, sort?: SortMeta): Promise<User[]> {
		const params = new URLSearchParams({
			...(pageParam ? { page: pageParam.toString(), perPage: String(25) } : {}),
			...filter,
			...sort,
			ignoreTemporary: '1',
		});
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}/users${params ? `?${params.toString()}` : ''}`);
		const { results } = await response.json();
		return results
	}
	async getSiteAvailableUsers(companyId: string, siteId: string, pageParam?: number, filter?: GetUsersSiteFilter, sort?: SortMeta): Promise<User[]> {
		const params = new URLSearchParams({
			...(pageParam ? { page: pageParam.toString(), perPage: String(25) } : {}),
			...filter,
			...sort,
			ignoreTemporary: '1',
		});
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}/users/available${params ? `?${params.toString()}` : ''}`);
		const { results } = await response.json();
		return results
	}
	async updateSite(companyId: string, site: Site, image?: File): Promise<Site> {
		const data = new FormData();
		const params = new Set();
		if (image) {
			data.append('photo', image);
			params.add('photo');
		}

		Object.keys(removeEmptyProperties(site)).map((p) => {
			if (!params.has(p) && p !== 'photo') {
				data.append(p, site[p]);
				params.add(p);
			}
		});

		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${site.id}`, { method: 'POST', body: data });

		return response.json();
	}
	async upsertSiteUser(companyId: string, siteId: string, params: AddSiteUser): Promise<void> {
		await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}/users/upsert`,
			{ method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(params) });
	}

	async deleteSiteUser(companyId: string, siteId: string, userIds: string[]): Promise<void> {
		const params = new URLSearchParams({ userIds: userIds.join(',') });

		await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}/users/remove?${params.toString()}`,
			{
				method: 'DELETE',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ userIds })
			});
	}


	async updateState(companyId: string, siteId: string, state: SiteState): Promise<void> {
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}?state=${state}`, { method: 'PUT' });

		return response.json();
	}

	async createSite(
		companyId: string,
		site: Site,
		typology?: Typology,
		requirementsGroup?: RequirementsGroup,
		siteVariant?: Variant
	): Promise<Site> {
		const { state, ...siteWithoutState } = site;

		const payload = removeEmptyAttributes({
			...siteWithoutState,
			typologyId: typology?.id,
			requirementsGroupId: requirementsGroup?.id,
			variantId: siteVariant.id,
		});

		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload),
		});

		return response.json();
	}

	async addRequirementToSite(
		companyId: string,
		siteId: string,
		documents: DocumentTypeAdd[],
		requirementSubject: RequirementSubject
	): Promise<Requirement> {

		const urlAddRequirement = `${this.baseURL(companyId)}/sites/${siteId}/${requirementSubject}-requirements/`;

		const mappedRequirements = documents.map((document) => ({
			documentTypeId: document.documentTypeId,
			isOptional: document.isOptional,
			graceDays: document.graceDays,
			variantsIds: document.variants.map((obj) => obj.id),
			specializationsIds: document.specializations.map((obj) => obj.id),
			source: document.source
		}));

		const response = await this.apiService.fetchWithToken(urlAddRequirement, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ requirements: mappedRequirements }),
		});
		return response.json();
	}

	async addRequirementToWorkingSite(
		companyId: string,
		siteId: string,
		requirements: {
			documentTypeId: string;
			isOptional: boolean;
			graceDays: number;
		}[],
		source?: DocumentTypeCategory
	): Promise<Requirement> {

		const urlAddRequirement = `${this.baseURL(companyId)}/sites/${siteId}/suppliers/${companyId}/requirements`;

		const response = await this.apiService.fetchWithToken(urlAddRequirement, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ source: source, requirements }),
		});
		return response.json();
	}

	async removeRequirementFromSite(companyId: string, siteId: string, requirementId: string, subject: RequirementSubject): Promise<void> {
		await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}/${subject}-requirements/${requirementId}`, {
			method: 'DELETE',
		});
	}

	async getDocuments(companyId: string, siteId: string, filter?: GetDocumentsFilter, sort?: SortMeta, pageParam?: number): Promise<Document[]> {
		const { tags, expiresAt, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...dateIntervals(expiresAt),
			...sort,
		});
		tags?.length > 0 && tags.forEach((tag) => params.append('tagIds[]', tag));

		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}/documents?${params.toString()}`);
		const documents = await response.json();

		const mappedDocuments: Document[] = documents.results ? documents.results.map(mapApiResponseToDocument) : [];
		return mappedDocuments;
	}

	async createDocument(companyId: string, siteId: string, documents: string[]): Promise<void> {
		try {
			await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/sites/${siteId}/documents`, {
				method: 'POST',
				body: JSON.stringify({ documentTypesIds: documents }),
				headers: { 'Content-Type': 'application/json' },
			});
		} catch (e) {
			return Promise.reject(e);
		}
	}
	async getWorkerSites(
		companyId: string,
		workerId: string,
		filter: GetResourceSitesFilter,
		sort?: SortMeta,
		pageParam?: number,
		includeWorkingSites?: boolean,
		ignoreUserSiteRoleRestrictions?: boolean,
		documentTypeId?: string
	): Promise<ResourceSites> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});
		if (ignoreUserSiteRoleRestrictions) {
			params.append('ignoreUserSiteRoleRestrictions', '1');
			params.append('withResourceData', '1');
		}
		if (documentTypeId) {
			params.append('documentTypeId', documentTypeId);
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerId}/sites?${params.toString()}&includeWorkingSites=${includeWorkingSites ? 1 : 0
			}`
		);
		if (!response.ok) throw new Error('Error fetching worker sites');
		const { results, count } = await response.json();
		return { results, count };
	}

	async getMachineSites(
		companyId: string,
		machineId: string,
		filter?: GetResourceSitesFilter,
		sort?: SortMeta,
		pageParam?: number,
		includeWorkingSites?: boolean,
		ignoreUserSiteRoleRestrictions?: boolean,
		documentTypeId?: string,
	): Promise<ResourceSites> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});

		if (ignoreUserSiteRoleRestrictions) {
			params.append('ignoreUserSiteRoleRestrictions', '1');
			params.append('withResourceData', '1');
		}
		if (documentTypeId) {
			params.append('documentTypeId', documentTypeId);
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/machines/${machineId}/sites?${params.toString()}&includeWorkingSites=${includeWorkingSites ? 1 : 0
			}`
		);
		if (!response.ok) throw new Error('Error fetching machine sites');
		const { results, count } = await response.json();
		return { results, count };
	}

	async getVehicleSites(
		companyId: string,
		vehicleId: string,
		filter?: GetResourceSitesFilter,
		sort?: SortMeta,
		pageParam?: number,
		includeWorkingSites?: boolean,
		ignoreUserSiteRoleRestrictions?: boolean,
		documentTypeId?: string,
	): Promise<ResourceSites> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});

		if (ignoreUserSiteRoleRestrictions) {
			params.append('ignoreUserSiteRoleRestrictions', '1');
			params.append('withResourceData', '1');
		}
		if(documentTypeId){
			params.append('documentTypeId', documentTypeId);
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/vehicles/${vehicleId}/sites?${params.toString()}&includeWorkingSites=${includeWorkingSites ? 1 : 0
			}`
		);
		if (!response.ok) throw new Error('Error fetching vehicle sites');
		const { results, count } = await response.json();
		return { results, count };
	}

	async getToolSites(
		companyId: string,
		toolId: string,
		filter?: GetResourceSitesFilter,
		sort?: SortMeta,
		pageParam?: number,
		includeWorkingSites?: boolean,
		ignoreUserSiteRoleRestrictions?: boolean,
		documentTypeId?: string,
	): Promise<ResourceSites> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});

		if (ignoreUserSiteRoleRestrictions) {
			params.append('ignoreUserSiteRoleRestrictions', '1');
			params.append('withResourceData', '1');
		}
		if(documentTypeId){
			params.append('documentTypeId', documentTypeId);
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/${toolId}/sites?${params.toString()}&includeWorkingSites=${includeWorkingSites ? 1 : 0
			}`
		);
		if (!response.ok) throw new Error('Error fetching tool sites');
		const { results, count } = await response.json();
		return { results, count };
	}

	async getChemicalSites(
		companyId: string,
		chemicalId: string,
		filter?: GetResourceSitesFilter,
		sort?: SortMeta,
		pageParam?: number,
		includeWorkingSites?: boolean,
		ignoreUserSiteRoleRestrictions?: boolean,
		documentTypeId?: string,
	): Promise<ResourceSites> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});

		if (ignoreUserSiteRoleRestrictions) {
			params.append('ignoreUserSiteRoleRestrictions', '1');
			params.append('withResourceData', '1');
		}
		if (documentTypeId){
			params.append('documentTypeId', documentTypeId);
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT
			}/companies/${companyId}/chemicals/${chemicalId}/sites?${params.toString()}&includeWorkingSites=${includeWorkingSites ? 1 : 0}`
		);
		if (!response.ok) throw new Error('Error fetching chemical sites');
		const { results, count } = await response.json();
		return { results, count };
	}

	async getSiteOptions(companyId: string, siteId: string): Promise<SiteOptions> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/options`
		);
		return await response.json();
	}

	async getScheduleEmail(companyId: string, siteId: string): Promise<ScheduleEmail[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/scheduled-notifications`
		);
		const { results } = await response.json();
		return results;
	}

	async updateSiteOptions(companyId: string, siteId: string, options: SiteOptions): Promise<void> {
		try {
			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/options`,
				{
					method: 'PUT',
					body: JSON.stringify(options),
					headers: { 'Content-Type': 'application/json' },
				}
			);

			if (!response.ok) {
				if (response.status === 422) {
					const error = 'selectABadge';
					return Promise.reject(new Error(error));
				}
				const { message } = await response.json();
				return Promise.reject(new Error(message));
			}
			return await response.json();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	async updateScheduleEmail(companyId: string, siteId: string, options: ScheduleEmail): Promise<void> {
		try {
			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/scheduled-notifications`,
				{
					method: 'PUT',
					body: JSON.stringify(options),
					headers: { 'Content-Type': 'application/json' },
				}
			);

			if (!response.ok) {
				if (response.status === 422) {
					const error = 'emailIsRequired';
					return Promise.reject(new Error(error));
				}
				const { message } = await response.json();
				return Promise.reject(new Error(message));
			}
		} catch (e) {
			return Promise.reject(e);
		}
	}

	async approvePendingEvaluation(
		companyId: string,
		siteId: string,
		resource: string,
		evaluationIds: string[],
		type: Evaluable,
		isApproved: boolean,
		selectAllEvaluations?: boolean,
		filter?: PendingEvaluationsFilters,
	): Promise<void> {	

		const body = { isApproved, evaluationIds, selectAll:selectAllEvaluations }

		for (const key in filter) {
			if (filter[key] !== undefined) {
				body[key] = filter[key];
			}
		}
		try {
			const response = await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/sites/${siteId}/${resource}/pending-evaluations`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(body),
			}
		);
		if (!response.ok) {
			if (response.status === 403) {
				const error = 'noRejectEvaluation';
				return Promise.reject(new Error(error));
			}
		}
		} catch (error) {
			const message = error.message;
			return Promise.reject(new Error(message));
		}
	}

	async getPendingEvaluations(
		companyId: string,
		siteId: string,
		type: Evaluable,
		filters: PendingEvaluationsFilters,
		sort: SortMeta,
		pageParam: number
	): Promise<{results: PendingEvaluation[], count: number}> {
		const filtersWithResource: PendingEvaluationsFilters = { ...filters };

		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filtersWithResource,
			...sort,
		});

		const response = await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/sites/${siteId}/${evaluablePathMap[type]}/pending-evaluations?${params}`
		);
		const { results, count } = await response.json();
		return { results: mapPendingEvaluationsResponse(results, type), count};
	}
	

	async getPendingEvaluationFiles(companyId: string, documentId: string): Promise<{results: FileEntity[], count: number}> {
		const response = await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/documents/${documentId}/files?withMeta=1`
		);
		const { results, count } = await response.json();
		return { results, count };
	}

	async getRenameModel(companyId: string, documentTypeId: string): Promise<RenameModel> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-types/${documentTypeId}/rename-models`,
		);
		return response.json();
	}

	async getSiteSupplierDocuments(
		companyId: string,
		siteId: string,
		resourceType: DocumentTypeCategory,
		supplierId: string,
		pageParam: number
	): Promise<PaginatedResults<SiteResourceDocument>> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: '25',
		});
		const response = await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/sites/${siteId}/suppliers/${supplierId}/${resourceType}-requirements?${params}`
		);

		const requirements = await response.json();
		return requirements;
	}

	async getSiteSupplierResourcesDocuments(
		companyId: string,
		siteId: string,
		resourceType: DocumentTypeCategory,
		supplierId: string,
		pageParam: number
	): Promise<PaginatedResults<DocumentType>> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: '25',
		});

		const response = await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/sites/${siteId}/suppliers/${supplierId}/requirements/${resourceType}/document-types?${params}`
		);

		const requirements = await response.json();
		return requirements;
	}

	async getSiteResourcesDocuments(
		companyId: string,
		siteId: string,
		resourceType: DocumentTypeCategory,
		supplierId: string,
		pageParam: number
	): Promise<PaginatedResults<DocumentType>> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: '25',
		});

		const response = await this.apiService.fetchWithToken(
			`${this.baseURL(companyId)}/sites/${siteId}/requirements/${resourceType}/document-types?${params}`
		);

		const requirements = await response.json();
		return requirements;
	}

	async getSiteSupplierChemicals(
		companyId: string,
		siteId: string,
		pageParam: number,
		filterSiteResources: GetSiteChemicalsFilters,
		siteResourcesSort: SortMeta,
		supplierId: string
	): Promise<PaginatedResults<SiteChemical>> {
		const { variantIds, specializationIds, ...restFilter } = filterSiteResources || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: (25).toString(),
			...(supplierId !== undefined && supplierId !== null ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...siteResourcesSort,
		});

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/chemicals?${params.toString()}`
		);
		const data: GetSiteChemicalsResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteChemical(siteId)),
		};
	}

	async getSiteSupplierWorkers(
		companyId: string,
		siteId: string,
		pageParam: number,
		filterSiteResources: GetSiteWorkersFilters,
		siteResourcesSort: SortMeta,
		supplierId: string
	): Promise<PaginatedResults<SiteWorker>> {
		const { badgeAvailable, variantIds, specializationIds, ...restFilter } = filterSiteResources || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: (25).toString(),
			...(supplierId ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...(badgeAvailable ? { badgeAvailable: badgeAvailable ? '1' : '0' } : {}),
			...siteResourcesSort,
		});

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/workers?${params.toString()}`
		);
		const data: GetSiteWorkersResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteWorker(siteId)),
		};
	}

	async getSiteSupplierMachines(
		companyId: string,
		siteId: string,
		pageParam: number,
		filterSiteResources: GetSiteMachinesFilters,
		siteResourcesSort: SortMeta,
		supplierId: string
	): Promise<PaginatedResults<SiteMachine>> {
		const { badgeAvailable, variantIds, specializationIds, ...restFilter } = filterSiteResources || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: (25).toString(),
			...(supplierId !== undefined && supplierId !== null ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...(badgeAvailable ? { badgeAvailable: badgeAvailable ? '1' : '0' } : {}),
			...siteResourcesSort,
		});

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/machines?${params.toString()}`
		);
		const data: GetSiteMachinesResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteMachine(siteId)),
		};
	}

	async getSiteSupplierTools(
		companyId: string,
		siteId: string,
		pageParam: number,
		filterSiteResources: GetSiteToolsFilters,
		siteResourcesSort: SortMeta,
		supplierId: string
	): Promise<PaginatedResults<SiteTool>> {
		const { variantIds, specializationIds, ...restFilter } = filterSiteResources || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: (25).toString(),
			...(supplierId !== undefined && supplierId !== null ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...siteResourcesSort,
		});

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/tools?${params.toString()}`
		);
		const data: GetSiteToolsResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteTool(siteId)),
		};
	}

	async getSiteSupplierVehicles(
		companyId: string,
		siteId: string,
		pageParam: number,
		filterSiteResources: GetSiteVehiclesFilters,
		siteResourcesSort: SortMeta,
		supplierId: string
	): Promise<PaginatedResults<SiteVehicle>> {
		const { badgeAvailable, variantIds, specializationIds, ...restFilter } = filterSiteResources || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: (25).toString(),
			...(supplierId !== undefined && supplierId !== null ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...(badgeAvailable ? { badgeAvailable: badgeAvailable ? '1' : '0' } : {}),
			...siteResourcesSort,
		});

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/vehicles?${params.toString()}`
		);
		const data: GetSiteVehiclesResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteVehicle(siteId)),
		};
	}

	async getResourceLinkableSites(
		companyId: string,
		resourceType?: string,
		resourceId?: string,
		filters?: GetSitesFilter,
		sort?: SortMeta,
		pageParam?: number
	): Promise<{ results: Site[], count: number }> {
		const { ...restFilter } = filters || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: (25).toString(),
			...restFilter,
			...sort,
		});
		const url = resourceId ? `${resourceType}s/${resourceId}/assignableSites` : `sites`;

		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/${url}?${params.toString()}`);
		return await response.json()
	}

	async addResourceToSites(companyId: string, resourceType: string, resourceId: string, siteIds: string[], selectAll?:boolean, filters?: GetSitesFilter): Promise<void> {
		const body = { siteIds, selectAll };

		for (const key in filters) {
			if (filters[key] !== undefined) {
				body[key] = filters[key];
			}
		}
		await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/${resourceType}s/${resourceId}/sites`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: { 'Content-Type': 'application/json' },
		});
	}
	async addResourcesToSites(
		companyId: string,
		resourceType: ResourceType,
		resourceIds: string[],
		siteIds: string[],
		selectAllSites?: boolean,
		selectAllResources?: boolean,
		resourceFilters?: GetResourcesFilter,
		siteFilters?: GetSitesFilter
	): Promise<LinkResourcesToSiteWarning> {
		const body = {
			resourceIds,
			siteIds,
			selectAllSites,
			selectAllResources,
			resourceFilters,
			siteFilters
		};
		const response = await this.apiService.fetchWithToken(`${this.baseURL(companyId)}/${resourceType}s/sites`, {
			method: 'POST',
			body: JSON.stringify(removeEmptyProperties(body)),
			headers: { 'Content-Type': 'application/json' },
		});
		return response.json();
	}
}

const evaluablePathMap = {
	[Evaluable.COMPANY]: 'suppliers',
	[Evaluable.WORKER]: 'workers',
	[Evaluable.VEHICLE]: 'vehicles',
	[Evaluable.MACHINE]: 'machines',
	[Evaluable.TOOL]: 'tools',
	[Evaluable.CHEMICAL]: 'chemicals',
};

export default ServerSiteRepository;
