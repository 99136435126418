import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Tag, TagLabel } from "@chakra-ui/react";
import { UserState } from "../../../../../domain/entities/user";

const UserStateTag: FC<{ state: UserState }> = ({ state }) => {
  const { t } = useTranslation("settings");
  let color = "";
  let text = "";

  switch (state) {
    case UserState.BLOCKED:
      color = "red.200";
      text = t("users.blocked");
      break;
    case UserState.ENABLED:
      color = "green.200";
      text = t("users.enabled");
      break;
    case UserState.INVITED:
      color = "yellow.200";
      text = t("users.invited");
      break;
  }

  return (
    <Tag bgColor={color}>
      <TagLabel>{text}</TagLabel>
    </Tag>
  );
};

export default UserStateTag;
