import Permission from './permission';
import { Identifiable } from './interfaces/identifiable';

export enum RoleContext {
	GENERAL = 'general',
	SITE = 'site',
	WORKSITE = 'worksite',
}

type Role = Identifiable & {
	name: string;
	context: RoleContext;
	permissions: Array<Permission>;
	isSystem: boolean;
};

export default Role;
