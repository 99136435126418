import { SiteResourcesViewModel } from '../../hooks/Site/useSiteResourcesViewModel';
import { makeGetWorkingSiteVehicles } from '../../../core/app/factory/siteVehicles/usecases';
import { SiteVehicle, SiteVehicleParams } from '../../../domain/entities/siteVehicle';
import SiteVehiclesViewModel from '../sites/SiteVehiclesViewModel';
import { GetSiteVehiclesFilters } from '../../../domain/repositories/siteVehicleRepository';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';

class WorkingSiteVehiclesViewModel extends SiteVehiclesViewModel implements SiteResourcesViewModel<SiteVehicleParams> {
	async getSiteResources(
		companyId: string,
		siteId: string,
		page: number,
		archived?: boolean,
		filter?: GetSiteVehiclesFilters,
		sort?: SortMeta,
	): Promise<PaginatedResults<SiteVehicle>> {
		return await makeGetWorkingSiteVehicles().Call({ companyId, siteId, page, perPage: 25, filter, sort, archived });
	}
}

export default WorkingSiteVehiclesViewModel;
