import Site from '../../entities/site';
import UseCase from '../../../core/app/usecase';
import RolesRepository from '../../repositories/rolesRepository';

interface Args {
	companyId: string;
}

class GetAvailableSites implements UseCase<Args, Site[]> {
	private rolesRepository: RolesRepository;

	constructor(rolesRepository: RolesRepository) {
		this.rolesRepository = rolesRepository;
	}

	async Call({ companyId }: Args): Promise<Site[]> {
		return await this.rolesRepository.getAvailableSites(companyId);
	}
}

export default GetAvailableSites;
