import UseCase from '../../../../core/app/usecase';
import { SiteChemical } from '../../../entities/siteChemical';
import SiteChemicalRepository, { GetWorkingSiteChemicalsFilters } from '../../../repositories/siteChemicalRepository';
import { PaginatedMeta, PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';

type Parameters = {
	companyId: string;
	siteId: string;
	archived?: boolean;
	filter?: GetWorkingSiteChemicalsFilters;
	sort?: SortMeta;
} & PaginatedMeta;

class GetWorkingSiteChemicals implements UseCase<Parameters, PaginatedResults<SiteChemical> | undefined> {
	constructor(private repository: SiteChemicalRepository) {}

	async Call({ companyId, siteId, sort, archived, perPage, filter, page }: Parameters): Promise<PaginatedResults<SiteChemical> | undefined> {
		return this.repository.getWorkingSiteChemicals(companyId, siteId, page, perPage, archived, filter, sort);
	}
}

export default GetWorkingSiteChemicals;
