import { Flex } from "@chakra-ui/react";
import FileEntity from "../../../../domain/entities/file";
import FullScreenButton from "../../Common/FullScreenButton";

interface PreviewFileProps {
  activeFile: FileEntity;
}
export const PreviewFile = ({ activeFile }: PreviewFileProps) => {
  function openFilePreviewWindow(file: FileEntity) {
    if (file?.uri) {
      window.open(file?.uri);
    }
  }
  return (
    <Flex width={"100%"} height={"100%"}>
      <iframe
        src={activeFile?.uri}
        title={`PDF Preview ${activeFile?.name}`}
        width="100%"
        style={{
          border: "1px solid #E2E8F2",
          borderRadius: "4px",
          height: "100%",
        }}
      />
      <FullScreenButton
        onClick={() => {
          openFilePreviewWindow(activeFile);
        }}
      />
    </Flex>
  );
};
