import SiteOptions, { ScheduleEmail } from "../../../domain/entities/siteOptions";
import {
  makeDeleteSiteUser,
  makeGetScheduleEmail,
  makeGetSiteAvailableUsers,
  makeGetSiteOptions,
  makeGetSiteUsers,
  makeUpdateScheduledEmail,
  makeUpdateSiteOptions,
  makeUpsertSiteUser
} from "../../../core/app/factory/sites/usecases";
import User from "../../../domain/entities/user";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetUsersSiteFilter } from "../../../domain/repositories/filters";
import Role, { RoleContext } from "../../../domain/entities/role";
import { makeGetRoles } from "../../../core/app/factory/roles/usecases";
import { AddSiteUser } from "../../../domain/repositories/siteRepository";

class SiteOptionsViewModel {
  constructor(
    private readonly companyId: string,
    private readonly siteId: string,
  ) {}
  async getSiteOptions(): Promise<SiteOptions> {
    return await makeGetSiteOptions().Call({
      companyId: this.companyId,
      siteId: this.siteId,
    });
  }

  async getScheduleEmail(): Promise<ScheduleEmail[]> {
    return await makeGetScheduleEmail().Call({
      companyId: this.companyId,
      siteId: this.siteId,
    });
  }

  async getSiteUsers(pageParam?: number, filter?: GetUsersSiteFilter, sort?: SortMeta): Promise<User[]> {
    return await makeGetSiteUsers().Call({
      companyId: this.companyId,
      siteId: this.siteId,
      pageParam,
      filter,
      sort
    });
  }
  async getSiteAvailableUsers(pageParam?: number, filter?: GetUsersSiteFilter, sort?: SortMeta): Promise<User[]> {
    return await makeGetSiteAvailableUsers().Call({
      companyId: this.companyId,
      siteId: this.siteId,
      pageParam,
      filter,
      sort
    });
  }

  async getSiteRoles(isWorkingSite?: boolean): Promise<Role[]> {
    return await makeGetRoles().Call({
      companyId: this.companyId, filter: {contextFilter: (isWorkingSite ? RoleContext.WORKSITE : RoleContext.SITE)}
    });
  }

  async updateSiteOptions(options: SiteOptions): Promise<void> {
    return await makeUpdateSiteOptions().Call({
      companyId: this.companyId,
      siteId: this.siteId,
      options,
    });
  }

  async updateScheduledEmail(options: ScheduleEmail): Promise<void> {
    return await makeUpdateScheduledEmail().Call({
      companyId: this.companyId,
      siteId: this.siteId,
      options,
    });
  }
  async upsertSiteUser(params: AddSiteUser): Promise<void> {
    return await makeUpsertSiteUser().Call({
      companyId: this.companyId,
      siteId: this.siteId,
      params,
    });
  }
  async deleteSiteUser(userIds: string[]): Promise<void> {
    return await makeDeleteSiteUser().Call({
      companyId: this.companyId,
      siteId: this.siteId,
      userIds,
    });
  }
}

export default SiteOptionsViewModel;
