import "reflect-metadata";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { InitDependencies } from "./core/app/di";
import { ChakraProvider } from "@chakra-ui/react";
import { Auth0JWTProvider } from "./presentation/providers/Auth0JWTProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./i18n";
import { theme } from "./presentation/assets/theme/theme";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import { App } from "./app";
import { ApiService } from "./infrastructure/utilities/apiService";
import Auth0ProviderWithNavigate from "./presentation/providers/AuthProviderWithNavigate";
import * as Sentry from "@sentry/react";

// Initialize Sentry
Sentry.init({
  dsn: "https://c70aecd0678db2626d36cda0a0e12325@o4506320074113024.ingest.sentry.io/4506320654761984",
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        "https://dev.api.new.sikuro.it",
        "https://stage.api.new.sikuro.it",
        "https://api.new.sikuro.it",
      ],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});
/**
 * this service is created here to be given as a parameter to repositories registration
 */
const apiService = new ApiService();

registerLocale("it", it);
setDefaultLocale("it");
InitDependencies(apiService);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
      <ChakraProvider theme={theme}>

        <Auth0JWTProvider apiService={apiService}>
          
            <App />
         
        </Auth0JWTProvider>
        </ChakraProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
