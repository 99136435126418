import UseCase from '../../../core/app/usecase';
import Tag from '../../entities/tag';
import BadgeRepository from '../../repositories/badgeRepository';

type ARGS = {
	companyId: string;
	badgeId: string;
	tag: Tag;
	siteId?: string;
};

class LinkBadgeTag implements UseCase<ARGS, void> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, badgeId, tag, siteId }: ARGS): Promise<void> {
		return await this.badgeRepository.linkBadgeTag(companyId, badgeId, tag, siteId);
	}
}

export default LinkBadgeTag;
