import UseCase from '../../../core/app/usecase';
import Evaluation from '../../entities/evaluation';
import SupplierRepository from '../../repositories/supplierRepository';
import { SupplierEvaluationState } from '../../entities/supplierEvaluationState.enum';
import { ResourceDocumentEvaluationState } from '../../entities/resourceDocumentEvaluationState.enum';

type Parameters = {
	companyId: string;
	supplierId: string;
	requirementId: string;
	result: ResourceDocumentEvaluationState;
	expiresAt?: Date;
};

class EvaluateCompanySupplierRequirement implements UseCase<Parameters, void> {
	constructor(private repository: SupplierRepository) {}

	async Call({ companyId, supplierId, requirementId, result, expiresAt }: Parameters): Promise<void> {
		await this.repository.evaluateCompanySupplierRequirement(companyId, supplierId, requirementId, result, expiresAt);
	}
}

export default EvaluateCompanySupplierRequirement;
