import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from "@chakra-ui/react";

export interface BaseFieldProps {
  label?: string;
  error?: string|boolean;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  children?: React.ReactNode;
}

const BaseField = (props: BaseFieldProps) => (
  <FormControl variant="floating" isInvalid={!!props.error} { ...props.formControlProps }>
    {props.children}
    <FormLabel
      zIndex={1}
      {...props.formLabelProps}
      style={{
        backgroundColor: "white",
        borderRadius: 3,
        ...props.formLabelProps?.style
      }}
    >
      {props.label}
    </FormLabel>
    <FormErrorMessage>{props.error}</FormErrorMessage>
  </FormControl>
)

export default BaseField;