import UseCase from '../../../../core/app/usecase';
import SiteChemicalRepository from '../../../repositories/siteChemicalRepository';
import { SiteChemical } from '../../../entities/siteChemical';

type GetSiteChemicalParameters = {
	companyId: string;
	siteId: string;
	chemicalId: string;
};

class GetSiteChemical implements UseCase<GetSiteChemicalParameters, SiteChemical | undefined> {
	constructor(private repository: SiteChemicalRepository) {}

	async Call({ companyId, siteId, chemicalId }: GetSiteChemicalParameters): Promise<SiteChemical | undefined> {
		return this.repository.getSiteChemical(companyId, siteId, chemicalId);
	}
}

export default GetSiteChemical;
