import UseCase from '../../../../core/app/usecase';
import { AiTaskSectionCode } from '../../../entities/aiTaskSectionCode';
import DocumentRepository from '../../../repositories/documentRepository';
import AiTask from '../../../entities/aiTask';
import { DocumentTypeCategory } from '../../../entities/documentTypeCategory.enum';

type GetAiTasksParams = {
	companyId: string;
	sectionCode: AiTaskSectionCode;
	resourceType?: DocumentTypeCategory;
	resourceId?: string;
	siteId?: string;
	supplierId?: string;
	requirementSource?: string
};

/**
 * Returns the list of all the existing Document.
 */
class GetAiTasks implements UseCase<GetAiTasksParams, AiTask[]> {
	constructor(private repository: DocumentRepository) {}

	async Call({ companyId, sectionCode, resourceType, resourceId, siteId, supplierId, requirementSource }: GetAiTasksParams): Promise<AiTask[]> {
		return await this.repository.getAiTasks(companyId, sectionCode, resourceType, resourceId, siteId, supplierId, requirementSource);
	}
}

export default GetAiTasks;
