import CreateTypology from '../../../../domain/interactors/typology/createTypology';
import GetResourceTypologiesAssociated from '../../../../domain/interactors/typology/getResourceTypologiesAssociated';
import GetTypologies from '../../../../domain/interactors/typology/getTypologies';
import { makeTypologyRepository } from './repositories';

export const makeGetTypologies = () => {
	return new GetTypologies(makeTypologyRepository());
};

export const makeCreateTypology = () => {
	return new CreateTypology(makeTypologyRepository());
};

export const makeGetResourceTypologiesAssociated = () => {
	return new GetResourceTypologiesAssociated(makeTypologyRepository());
};
