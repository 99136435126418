import UseCase from '../../../../core/app/usecase';
import SiteMachineRepository from '../../../repositories/siteMachineRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	machineId: string;
};

class DeleteSiteMachine implements UseCase<Parameters, void> {
	constructor(private repository: SiteMachineRepository) {}

	async Call({ companyId, siteId, machineId }: Parameters): Promise<void> {
		return this.repository.deleteSiteMachine(companyId, siteId, machineId);
	}
}

export default DeleteSiteMachine;
