import { Named } from './interfaces/named';
import { Identifiable } from './interfaces/identifiable';
import Permission from './permission';
import { ResourceEvaluationState } from './resourceEvaluationState.enum';
import Evaluation, { EvaluationMode } from './evaluation';

export enum SiteState {
	ACTIVE = 'active',
	SUSPENDED = 'suspended',
	CLOSED = 'closed',
}

type Site = Identifiable &
	Named & {
		protocol: string;
		startDate: Date;
		photo?: string;
		lifespan: number;
		description: string;
		contract: string;
		stakeholder: string;
		address: string;
		city: string;
		zipCode: string;
		state: SiteState;
		permissions?: Permission[];

		//fields used when showing resource's sites
		userHasSiteRole?: boolean;
		evaluation?: Evaluation<ResourceEvaluationState>,
		badge?: {
			id: string,
			serial: string,
			code: string,
			status: "active",
			tags: [],
			type: "QR Code",
			onSite: boolean
		}
	};

export type ResourceSites = {
	results: Site[];
	count: number;
};

export type DocumentSites = {
	results: Site[];
	count: number;
};

export const makeEmptySite = (): Site => {
	return {
		id: '',
		name: '',
		photo: '',
		protocol: '',
		startDate: new Date(),
		lifespan: 0,
		description: '',
		contract: '',
		stakeholder: '',
		address: '',
		city: '',
		zipCode: '',
		state: SiteState.ACTIVE,
	};
};

export default Site;
