import UseCase from '../../../core/app/usecase';
import BadgeRepository from '../../repositories/badgeRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	badgeIds: string[];
	selectAll?: boolean;
	selectedBadgesNumber?: number;
	availableBadgesFilters?: { [p: string]: any };
};

class LinkBadgesToSite implements UseCase<ARGS, void> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, siteId, badgeIds, selectAll, selectedBadgesNumber, availableBadgesFilters }: ARGS): Promise<void> {
		return await this.badgeRepository.linkBadgesToSite(companyId, siteId, badgeIds, selectAll, selectedBadgesNumber, availableBadgesFilters);
	}
}

export default LinkBadgesToSite;
