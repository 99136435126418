import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteChemicalParams } from "../../../domain/entities/siteChemical";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";
import useWorkingSiteChemicalsViewModel from "../../hooks/WorkingSite/useWorkingSiteChemicalsViewModel";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const SiteChemicalsView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["name"];
  return (
    <SiteResourcesListView<SiteChemicalParams>
      type={DocumentTypeCategory.CHEMICAL}
      useViewModel={useWorkingSiteChemicalsViewModel(
        siteId,
        resourceColumns,
        DocumentTypeCategory.CHEMICAL,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[Permission.Worksite_AttachChemical]}
      removeFromSitePermissions={[Permission.Worksite_DetachChemical]}
    />
  );
};

export default SiteChemicalsView;
