import { SiteResourcesViewModel } from '../../hooks/Site/useSiteResourcesViewModel';
import { makeGetWorkingSiteWorkers } from '../../../core/app/factory/siteWorkers/usecases';
import { SiteWorker, SiteWorkerParams } from '../../../domain/entities/siteWorker';
import SiteWorkersViewModel from '../sites/SiteWorkersViewModel';
import { GetSiteWorkersFilters } from '../../../domain/repositories/siteWorkerRepository';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';

class WorkingSiteWorkersViewModel extends SiteWorkersViewModel implements SiteResourcesViewModel<SiteWorkerParams> {
	async getSiteResources(
		companyId: string,
		siteId: string,
		page: number,
		archived?: boolean,
		filter?: GetSiteWorkersFilters,
		sort?: SortMeta,
	): Promise<PaginatedResults<SiteWorker>> {
		return await makeGetWorkingSiteWorkers().Call({ companyId, siteId, page, perPage: 25, filter, sort, archived });
	}
}

export default WorkingSiteWorkersViewModel;
