import { SiteChemicalParams } from '../../../domain/entities/siteChemical';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { SupplierResourcesViewModel } from '../../hooks/Suppliers/useSupplierResourcesViewModel';
import { GetSupplierChemicalsFilters } from '../../../domain/repositories/supplierRepository';
import {
	makeGetCompanySupplierChemicals,
	makeGetSpecializationsForCompanySupplierRequirements,
	makeGetVariantsForCompanySupplierRequirements,
} from '../../../core/app/factory/suppliers/useCases';
import Variant from '../../../domain/entities/variant';
import Specialization from '../../../domain/entities/specialization';
import { RequirementSubject } from '../../../domain/entities/requirementSubject.enum';
import { SupplierResource } from '../../../domain/entities/supplierResource';

class SupplierChemicalsViewModel implements SupplierResourcesViewModel<SiteChemicalParams> {
	async getSupplierResources(
		companyId: string,
		supplierId: string,
		page: number,
		filter?: GetSupplierChemicalsFilters,
		sort?: SortMeta,
	): Promise<PaginatedResults<SupplierResource<SiteChemicalParams>>> {
		return await makeGetCompanySupplierChemicals().Call({ companyId, supplierId, page, filter, sort });
	}

	async getVariants(companyId: string, supplierId: string): Promise<Variant[]> {
		return await makeGetVariantsForCompanySupplierRequirements().Call({ companyId, supplierId, subject: RequirementSubject.CHEMICAL });
	}

	async getSpecializations(companyId: string, supplierId: string): Promise<Specialization[]> {
		return await makeGetSpecializationsForCompanySupplierRequirements().Call({ companyId, supplierId, subject: RequirementSubject.CHEMICAL });
	}
}

export default SupplierChemicalsViewModel;
