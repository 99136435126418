import Company from "../../../domain/entities/company";
import UpdateCompanyParameter from "../../hooks/Company/updateCompanyParameter";
import {
  makeGetCompany, makeGetCustomFields,
  makeUpdateCompany,
} from "../../../core/app/factory/companies/usecases";
import {CustomField} from "../../../domain/entities/customFields";
import {RequirementSubject} from "../../../domain/entities/requirementSubject.enum";

class CompanyDetailViewModel {
  private getUC = makeGetCompany();
  private updateUC = makeUpdateCompany();
  private getCustomFieldsUC = makeGetCustomFields();

  async getCompany(id: string): Promise<Company> {
    return await this.getUC.Call(id);
  }

  async updateCompany(
    updateCompanyParameter: UpdateCompanyParameter,
  ): Promise<Company> {
    return await this.updateUC.Call(updateCompanyParameter);
  }

  async getCustomFields(companyId: string): Promise<CustomField[]> {
    return await this.getCustomFieldsUC.Call({ companyId, subject: RequirementSubject.COMPANY });
  }
}

export default CompanyDetailViewModel;
