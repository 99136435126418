import { makeGetSiteRequirementsResource } from "../../../core/app/factory/documents/usecases";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { SiteRequirementsResource } from "../../../domain/entities/siteRequirementsResource";
import { GetSiteRequirementsResourceFilter } from "../../../domain/repositories/documentRepository";

class SiteRequirementsDocumentViewModel {
  private getSiteReqResource = makeGetSiteRequirementsResource();

  async getSiteRequirementsResource(
    companyId: string,
    siteId: string,
    availableResources: string[],
    pageParam: number,
    sort: SortMeta,
    filter: GetSiteRequirementsResourceFilter,
  ): Promise<SiteRequirementsResource[]> {
    return await this.getSiteReqResource.Call({
      companyId,
      siteId,
      availableResources,
      pageParam,
      sort,
      filter,
    });
  }
}

export { SiteRequirementsDocumentViewModel };
