import { injectable } from 'tsyringe';
import DocumentPreset from '../../domain/entities/documentPreset';
import { DocumentPresetTarget } from '../../domain/entities/documentPresetTarget.enum';
import DocumentType from '../../domain/entities/documentType';
import Tag from '../../domain/entities/tag';
import DocumentsPresetsRepository from '../../domain/repositories/documentsPresetsRepository';
import { ApiService } from '../utilities/apiService';

@injectable()
class ServerDocumentPresetRepository implements DocumentsPresetsRepository {
	constructor(private apiService: ApiService) {}

	async getDocumentPresets(companyId: string, filter: DocumentPresetTarget, typologies?: string[]): Promise<Array<DocumentPreset>> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-presets?type=${filter}${typologies.length > 0 ? `&typologies=${typologies.join(',')}` : ''}`,
		);
		const payload = await response.json();
		return payload.results;
	}

	async getDocumentPresetsByTypology(companyId: string, target: DocumentPresetTarget, typologyId: string ): Promise<Array<DocumentPreset>>  {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-presets/typologies/${typologyId}?type=${target}`,
		);
		const payload = await response.json();
		return payload.results;
	}

	async createDocumentPreset(companyId: string, documentType: DocumentType, target: DocumentPresetTarget): Promise<DocumentPreset> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-presets`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ documentTypeId: documentType.id, resourceType: target }),
		});

		return response.json();
	}

	async updateDocumentPreset(companyId: string, documentPresets: DocumentPreset[], target: DocumentPresetTarget): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-presets?type=${target}`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ documentPresets: documentPresets }),
			},
		);

		return response.json();
	}

	async deleteDocumentPreset(companyId: string, presetId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-presets/${presetId}`, {
			method: 'DELETE',
		});
	}

	async addTypologyToDocumentPreset(companyId: string, presetId: string, typology: Tag, target: DocumentPresetTarget): Promise<void> {
		let linkedTypology = typology;
		if (linkedTypology.id === linkedTypology.name) {
			const typologyCreationURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/typologies`;
			const createTypologyResponse = await this.apiService.fetchWithToken(typologyCreationURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ typologyName: linkedTypology.name, resourceType: target }),
			});
			linkedTypology = await createTypologyResponse.json();
		}

		const baseURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-presets/${presetId}/typologies/${linkedTypology.id}`;
		const linkResponse = await this.apiService.fetchWithToken(baseURL, {
			method: 'PUT',
		});

		if (!linkResponse.ok) {
			return Promise.reject(new Error('cannot link typology to document preset'));
		}

		return Promise.resolve();
	}

	async updateTypologyToDocumentPreset(companyId: string, tag: Tag): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/typologies/${tag.id}`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ name: tag.name }),
			},
		);
		if (response.status === 409) {
			return Promise.reject(new Error('cannot rename typology to document preset'));
		}
		return Promise.resolve();
	}
	async removeTypologyFromDocumentPreset(companyId: string, presetId: string, typologyId: string): Promise<void> {
		const baseURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-presets/${presetId}/typologies`;
		const unlinkResponse = await this.apiService.fetchWithToken(`${baseURL}/${typologyId}`, { method: 'DELETE' });

		if (!unlinkResponse.ok) {
			return Promise.reject(new Error('cannot unlink typology to document preset'));
		}

		return Promise.resolve();
	}

	async updatePresetsByTypologyOrder(companyId: string, typologyId: string, target: DocumentPresetTarget, presets: DocumentPreset[]): Promise<void> {
		const documentTypeIds = presets.map((item) => item.documentType.id)
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-presets/typologies/${typologyId}?type=${target}`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ documentTypeOrder: documentTypeIds }),
			},
		);
	}
}

export default ServerDocumentPresetRepository;
