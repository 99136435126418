import UseCase from '../../../core/app/usecase';
import FileEntity from '../../entities/file';
import File from '../../entities/file';
import DocumentRepository from '../../repositories/documentRepository';
import { GetSitesFilter } from "../../repositories/siteRepository";

type ARGS = {
	companyId: string;
	file: FileEntity;
	documentId: string;
	siteId?: string;
	siteIds?: string[];
	target?: string;
	resourceId?: string;
	selectAll?: boolean;
	filters?: GetSitesFilter
};
/**
 * Add a file to a document
 */
class AddFileToResourceDocument implements UseCase<ARGS, FileEntity[]> {
	constructor(private repository: DocumentRepository) {}

	async Call(parameter: ARGS): Promise<FileEntity[]> {
		return await this.repository.addFileToResourceDocument(
			parameter.companyId,
			parameter.documentId,
			parameter.file,
			parameter.siteIds,
			parameter.target,
			parameter.resourceId,
			parameter.selectAll,
			parameter.filters,
		);
	}
}

export default AddFileToResourceDocument;