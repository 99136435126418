import UseCase from '../../../../core/app/usecase';
import SiteToolRepository from '../../../repositories/siteToolRepository';
import Evaluation from '../../../entities/evaluation';
import { ResourceEvaluationState } from '../../../entities/resourceEvaluationState.enum';

type Parameters = {
	companyId: string;
	siteId: string;
	toolId: string;
};

class AutoEvaluateSiteTool implements UseCase<Parameters, void> {
	constructor(private repository: SiteToolRepository) {}

	async Call({ companyId, siteId, toolId }: Parameters): Promise<void> {
		return this.repository.autoEvaluateSiteTool(companyId, siteId, toolId);
	}
}

export default AutoEvaluateSiteTool;
