import { injectable } from 'tsyringe';
import Document from '../../domain/entities/document';
import { GetWorkersResponse } from '../responses/workers';
import Worker, { WorkerStatus } from '../../domain/entities/worker';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import { GetDocumentsFilter } from '../../domain/repositories/documentRepository';
import WorkerRepository, { GetWorkersFilter } from '../../domain/repositories/workerRepository';
import { ApiService } from '../utilities/apiService';
import { dateIntervals } from '../utilities/filters';
import ImportInfo from '../../domain/entities/importInfo';
import FileEntity from '../../domain/entities/file';
import DocumentTypeWithPublic from '../../domain/entities/documentTypeWithPublic';
import { ResourceDocumentEvaluationState } from '../../domain/entities/resourceDocumentEvaluationState.enum';
import { dateToRFC3339, removeEmptyAttributes } from "../../utils";
import { mapApiResponseToDocument } from '../adapters/getResourceDocuments';
import { GetAvailableBadgesFilters, GetBadgesFilters } from '../../domain/repositories/badgeRepository';
import Badge from '../../domain/entities/badge';
import { BadgeStatusSite } from '../../presentation/hooks/Badge/useBadgeDetailViewModel';
import { ResourceType } from '../../presentation/screens/Site/ResourceSelectableTable';
import { GetSitesFilter } from "../../domain/repositories/siteRepository";

@injectable()
class ServerWorkerRepository implements WorkerRepository {
	constructor(private apiService: ApiService) {}

	async getWorkerById(companyId: string, workerId: string): Promise<Worker | undefined> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerId}`,
		);
		if (!response.ok) {
			return undefined;
		}
		return await response.json();
	}

	async getWorkers(
		companyId: string,
		filter?: GetWorkersFilter,
		archived?: boolean,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<PaginatedResults<Worker>> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: '25',
			...filter,
			...sort,
		});

		if (archived) {
			params.append('archived', 'true');
		}
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers?${params.toString()}`,
		);
		const data: GetWorkersResponse = await response.json();

		return {
			...data,
			results: data.results.map((worker: any) => ({
				...worker,
				fullName: `${worker.firstName} ${worker.lastName}`,
			})),
		};
	}

	async getResourceBadge(
		companyId: string,
		resource: string,
		resourceId: string,
		sort?: SortMeta,
		filter?: GetBadgesFilters,
		pageParam?: number,
		supplierId?: string,
	): Promise<PaginatedResults<Badge>> {
		const { tagIds, ...restFilter } = filter || {};

		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: '25',
			...{
				...restFilter,
			},
			...sort,
		});

		tagIds?.length > 0 && tagIds.forEach((tag) => params.append('tagIds[]', tag));
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${resource}/${resourceId}/badges?${params.toString()}`,
		);
		const badges = await response.json();

		return badges;
	}

	async getBadgeSites(companyId: string, badgeId: string): Promise<BadgeStatusSite[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/badges/${badgeId}/sites`,
		);
		const badgeSites = await response.json();
		return badgeSites['results'] ?? [];
	}

	async restoreWorker(companyId: string, workerId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerId}/restore`, {
			method: 'PUT',
		});
	}

	async getAvailableWorkersCount(companyId: string, filter?: GetWorkersFilter, sort?: SortMeta): Promise<number> {
		const params = new URLSearchParams({
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers-count?${params.toString()}`,
		);
		const data: GetWorkersResponse = await response.json();
		return data.results.length;
	}

	async getAvailableBadges(
		companyId: string,
		resource: string,
		resourceId: string,
		sort?: SortMeta,
		filter?: GetAvailableBadgesFilters,
	): Promise<Badge[]> {
		const { tagIds, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			...{ ...restFilter },
			...sort,
		});
		tagIds?.length > 0 && tagIds.forEach((tag) => params.append('tagIds[]', tag));
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${resource}/${resourceId}/badges/linkable?${params.toString()}`,
		);
		const data = await response.json();
		return data.results;
	}

	async createWorker(companyId: string, worker: Worker, photo?: File): Promise<Worker> {
		const formData = new FormData();
		const appendedParameters = new Set();

		if (photo) {
			formData.append('photo', photo);
			appendedParameters.add('photo');
		}

		Object.keys(worker).map((parameter) => {
			if (!appendedParameters.has(parameter) && parameter !== 'photo') {
				formData.append(parameter, worker[parameter]);
				appendedParameters.add(parameter);
			}
		});

		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers`, {
			method: 'POST',
			body: formData,
		});
		return await response.json();
	}

	async uploadWorkers(companyId: string, file: FileEntity): Promise<ImportInfo> {
		const formData = new FormData();
		const appendedParameters = new Set();

		formData.append('file', file.binaries[0]);
		appendedParameters.add('file');
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/import`, {
			method: 'POST',
			body: formData,
		});
		return response.ok ? await response.json() : Promise.reject(new Error('error.conflict'));
	}

	async updateWorker(companyId: string, workerParameters: Worker, imageFile?: File): Promise<Worker> {
		const formData = new FormData();
		const appendedParameters = new Set();

		if (imageFile) {
			formData.append('photo', imageFile);
			appendedParameters.add('photo');
		}

		Object.keys(workerParameters).map((parameter) => {
			workerParameters[parameter] = workerParameters[parameter] ?? '';
			if (!appendedParameters.has(parameter) && parameter !== 'photo') {
				formData.append(parameter, workerParameters[parameter]);
				appendedParameters.add(parameter);
			}
		});

		if (!imageFile) {
			formData.delete('photo');
		}
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerParameters.id}`,
			{
				method: 'POST',
				body: formData,
			},
		);

		return await response.json();
	}

	async deleteWorker(companyId: string, workerId: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerId}`,
			{
				method: 'DELETE',
			},
		);
		if (!response.ok) {
			throw new Error('Failed to delete worker');
		}
	}

	async getWorkerDocuments(
		companyId: string,
		workerId: string,
		filter?: GetDocumentsFilter,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<Document[]> {
		const { tags, expiresAt, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...dateIntervals(expiresAt),
			...sort,
		});
		tags?.length > 0 && tags.forEach((tag) => params.append('tagIds[]', tag));
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerId}/documents?${params.toString()}`,
		);
		const documents = await response.json();
		return documents.results ? Object.values(documents.results).map(mapApiResponseToDocument) : [];
	}

	async createWorkerDocument(companyId: string, workerId: string, documents: string[]): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerId}/documents`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ documentTypesIds: documents }),
			},
		);
		return await response.json();
	}

	async createPropagableWorkerDocument(companyId: string, workerId: string, documents: DocumentTypeWithPublic[]): Promise<void> {
		const transformedDocuments = documents.map((doc) => ({
			documentTypeId: doc.id,
			isPublic: doc.isPublic,
		}));
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerId}/documents`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ documents: transformedDocuments }),
			},
		);
		return await response.json();
	}

	async evaluateWorkerDocument(
		companyId: string,
		workerId: string,
		documentId: string,
		evaluationState?: ResourceDocumentEvaluationState,
		expirationDate?: Date,
		noEvaluationExpiration?: boolean,
		siteIds?: string[],
		selectAll?: boolean,
		filters?: GetSitesFilter,
	): Promise<void> {
		const requestBody = {
			sitesIds: siteIds,
			selectAll,
			...(evaluationState !== null && { result: evaluationState }),
			...(expirationDate && (expirationDate !== null ? { expiresAt: dateToRFC3339(expirationDate, true) } : { expiresAt: '' })),
			noEvaluationExpiration,
		};
		for (const key in removeEmptyAttributes(filters)) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				requestBody[key] = filters[key];
			}
		}
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerId}/documents/${documentId}/evaluations`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(requestBody),
			},
		);
	}

	async repropagationEvaluationWorkerDocument(
		companyId: string,
		workerId: string,
		documentId: string,
		siteIds?: string[],
		selectAll?: boolean,
	): Promise<void> {
		const requestBody = {
			sitesIds: siteIds,
			selectAll,
		};

		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerId}/documents/${documentId}/evaluations`,
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(requestBody),
			},
		);
	}

	async addTypologyToWorker(companyId: string, workerId: string, typologyId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerId}/typologies/${typologyId}`,
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
			},
		);
	}

	async removeTypologyFromWorker(companyId: string, workerId: string, typologyId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerId}/typologies/${typologyId}`,
			{
				method: 'DELETE',
			},
		);
	}

	async updateWorkerStatus(companyId: string, workerId: string, status: WorkerStatus): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/workers/${workerId}/status`, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ status }),
		});
	}

	async linkBadgesResource(companyId: string, resourceType: ResourceType, resourceId: string, badgeIds: string[]): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${resourceType}s/${resourceId}/badges/link`,
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ badgeIds }),
			},
		);
	}
}

export default ServerWorkerRepository;
