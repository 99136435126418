import { useMemo, useState } from "react";
import User from "../../../domain/entities/user";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import UserSettingsViewModel from "../../viewmodels/users/UserSettingsViewModel";
import {
  LoginResponse,
  UpdatePasswordResponse,
} from "../../../infrastructure/responses/auth";
import { UserNotification } from "../../../domain/entities/interfaces/userNotification";
import Requirement from "../../../domain/entities/requirement";
import BadgeReader from "../../../domain/entities/badgeReader";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { updateFilterWithDelete } from "../../../utils";
import { UserEmail } from "../../../domain/entities/userEmail";
import { UserEmailsFilters } from "../../../domain/repositories/usersRepository";
import Supplier from "../../../domain/entities/supplier";

export interface UpdatePasswordArgs {
  value: string;
  confirm: string;
}

export interface UpdatePasswordParams {
  oldPassword: string;
  value: string;
  confirm: string;
}

export interface UpdateAlertParams {
  expiringDocumentsDaysBeforeAlert?: number[];
  expiringOwnedSitesDaysBeforeAlert?: number[];
  expiringWorkingSitesDaysBeforeAlert?: number[];
}

const useUserSettingsViewModel = (onPasswordUpdated?: () => void) => {
  const { companyId, user } = useAuth();

  const [passwordUpdateError, setPasswordUpdateError] =
    useState<Error>(undefined);
  const [oldPasswordError, setOldPasswordError] = useState<string>(undefined);
  const [updatePasswordData, setUpdatePasswordData] =
    useState<UpdatePasswordResponse>();
  const [errorMessage, setErrorMessage] = useState<string>(undefined);
  const [sort, setSort] = useState<SortMeta>();
  const [filterEmails, setFilterEmails] = useState<Record<string, string | string[]>>();
  const [emailError, setEmailError] =
    useState<Error>(undefined);
  const updateFilterEmails = ( field: string,  value: string | string[] | [Date, Date],
  ) => { updateFilterWithDelete(setFilterEmails, field, value); };
  const viewModel = useMemo(
    () => new UserSettingsViewModel(companyId, user.id),
    [user.id, companyId]
  );

  const {
    data: currentUser,
    isLoading,
    refetch,
    error,
  } = useQuery(["get-user", user.id], async () => await viewModel.get());

  const updateMutation = useMutation((user: User) => viewModel.update(user), {
    onSuccess: () => refetch(),
  });

  const updateImageMutation = useMutation(
    (image: File) => viewModel.updateUserImage(user.id, image),
    { onSuccess: () => refetch() }
  );

  const onPasswordUpdateSuccess = () => {
    onPasswordUpdated();
  };

  const onPasswordUpdateFail = (e: string | Error) => {
    setPasswordUpdateError(typeof e === "string" ? new Error(e) : e);
  };

  const updatePasswordMutation = useMutation(
    async (args: UpdatePasswordParams) => {
      setPasswordUpdateError(undefined);

      const updatePasswordData = await viewModel.updatePassword(
        args.oldPassword,
        args.value,
        args.confirm
      );
      setUpdatePasswordData(updatePasswordData);
    },
    {
      onSuccess: onPasswordUpdateSuccess,
      onError: onPasswordUpdateFail,
    }
  );

  const getUserNotifications = useQuery(
    ["badges", companyId, user.id],
    async () => {
      return await viewModel.getUserNotifications(companyId, user.id);
    }
  );
  const updateUserNotificationMutation = useMutation(
    async (notifications: UserNotification[]) => {
      await viewModel.updateUserNotification(companyId, user.id, notifications);
    },
    {
      onSuccess: () => getUserNotifications.refetch(),
      onError: (error) => console.error(error),
    }
  );

  const updateAlertNotificationMutation = useMutation(
    async (alert: UpdateAlertParams) => {
      await viewModel.updateAlertNotification(companyId, user.id, alert);
    },
    {
      onSuccess: () => getUserNotifications.refetch(),
      onError: (error) => {
        if (error instanceof Error) {
          setErrorMessage(error.message);
        }
      },
    }
  );

  const updateUserNotifications = (notifications: UserNotification[]) => {
    updateUserNotificationMutation.mutateAsync(notifications);
  };

  const updateAlertNotification = async (alert: UpdateAlertParams) => {
    try {
      return await updateAlertNotificationMutation.mutateAsync(alert);
    } catch (err) {
      console.error(err);
    }
  };

  const getUserEmails =  useInfiniteQuery<UserEmail[], Error>(
    ["emails", companyId, user.id, filterEmails, sort],
    async ({ pageParam = 1 }) => {
      return await viewModel.getUserEmails(companyId, user.id, filterEmails, sort, pageParam);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      retry: 1
    }
  );
  const upsertUserEmail = async (email: UserEmail) => {
    try {
      await upsertUserEmailMutation.mutateAsync(email);
    } catch (err) {
      console.error('cannot update email', err);
    }
  };

  const upsertUserEmailMutation = useMutation(
    ["update-email", companyId, user.id],
    async (email: UserEmail) => {
      if(email.id){
        return await viewModel.updateUserEmail(
          companyId,
          user.id,
          email
        );
      }else{
        return await viewModel.createUserEmail(
          companyId,
          user.id,
          email
        );
      }

    },
    {
      onSuccess: () => {
        getUserEmails.refetch();
      },
      onError: (error: Error) => {
        setEmailError(error)
      },
    }
  );
  const removeUserEmail = async (emailId: string) => {
    try {
      await removeUserEmailMutation.mutateAsync(emailId);
    } catch (err) {
      console.error('cannot remove email', err);
    }
  };

  const removeUserEmailMutation = useMutation(
    ["remove-email", companyId, user.id],
    async (emailId: string) => {
      return await viewModel.removeUserEmail(
        companyId,
        user.id,
        emailId
      );
    },
    {
      onSuccess: () => getUserEmails.refetch(),
      onError: (error: Error) => console.log(error.message),
    }
  );

  const userNotifications = getUserNotifications.data;
  const emails = getUserEmails.data?.pages?.flat() ?? [];

  return {
    currentUser,
    error,
    errorMessage,
    setErrorMessage,
    updateAlertNotification,
    updateUser: updateMutation.mutateAsync,
    updateUserImage: updateImageMutation.mutateAsync,
    isLoading:
      isLoading || updateMutation.isLoading || updateImageMutation.isLoading,
    passwordUpdateError,
    oldPasswordError,
    updatePassword: updatePasswordMutation.mutateAsync,
    updatePasswordIsLoading: updatePasswordMutation.isLoading,
    updatePasswordData,
    userNotifications,
    updateUserNotifications,
    isLoadingUpdateUserNotifications: updateUserNotificationMutation.isLoading,
    notificationsIsFetching: getUserNotifications.isFetching,
    emails,
    emailsIsLoading: getUserEmails.isLoading,
    upsertUserEmail,
    emailError,
    setEmailError,
    upsertUserEmailLoading: upsertUserEmailMutation.isLoading,
    removeUserEmail,
    filterEmails,
    updateFilterEmails,
    hasNextPage: getUserEmails.hasNextPage,
    fetchNextPage: getUserEmails.fetchNextPage,
    sort,
    setSort,
  };
};

export default useUserSettingsViewModel;
