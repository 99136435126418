import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SiteVehicleParams } from "../../../domain/entities/siteVehicle";
import SupplierResourcesListView from "../../components/Views/Suppliers/SupplierResourcesListView";
import useSupplierResourcesViewModel from "../../hooks/Suppliers/useSupplierResourcesViewModel";
import SupplierVehiclesViewModel from "../../viewmodels/suppliers/SupplierVehiclesViewModel";

const SupplierVehiclesView = () => {
  const resourceColumns = ["name", "plate", "model"];
  return (
    <SupplierResourcesListView<SiteVehicleParams>
      type={DocumentTypeCategory.VEHICLE}
      useViewModel={useSupplierResourcesViewModel(
        new SupplierVehiclesViewModel(),
        DocumentTypeCategory.VEHICLE,
      )}
      resourceColumns={resourceColumns}
      namespace={"vehicles"}
    />
  );
};

export default SupplierVehiclesView;
