import Vehicle from '../../../entities/vehicle';
import UseCase from '../../../../core/app/usecase';
import VehicleRepository from '../../../repositories/vehicleRepository';

type ARGS = {
	companyId: string;
	vehicle: Vehicle;
	imageFile: File;
};

class UpdateVehicle implements UseCase<ARGS, Vehicle> {
	constructor(private repository: VehicleRepository) {}

	async Call(parameters: ARGS): Promise<Vehicle> {
		return await this.repository.updateVehicle(parameters.companyId, parameters.vehicle, parameters.imageFile);
	}
}

export default UpdateVehicle;
