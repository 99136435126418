import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ResourceListView from "../ResourceListView";
import Chemical from "../../../../domain/entities/chemical";
import { Permission } from "../../../components/Permissions/Permissions";
import FormTextField from "../../../components/Common/forms/FormTextField";
import { EditableFormFieldProps } from "../../../components/Common/forms/FormField";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { ChemicalsListViewModel } from "../../../viewmodels/chemicals/ChemicalsListViewModel";
import { GetResourcesFilter } from "../../../../domain/repositories/workerRepository";
import FormImageField from "../../../components/Common/forms/FormImageField";

const ChemicalsView = () => {
  const { t } = useTranslation("chemicals");
  const formMethods = useForm<Chemical>({ mode: "all" });

  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const columns = [{ field: "name", type: "text", label: t("name") }] as {
    field: keyof GetResourcesFilter;
    type: "text" | "tags";
    label: string;
  }[];
  const fields: Array<ReactElement<Omit<EditableFormFieldProps, "isEditing">>> =
    [
      <FormTextField
        key="name"
        label={t("name")}
        name="name"
        rules={requiredRule}
      />,
      <FormImageField
        key="photo"
        label={t("photo")}
        name="photo"
        colSpan={2}
      />,
    ];

  return (
    <ResourceListView<Chemical>
      formMethods={formMethods}
      type={DocumentTypeCategory.CHEMICAL}
      viewModel={new ChemicalsListViewModel()}
      columns={columns}
      namespace="chemicals"
      createPermissions={[Permission.Resources_AddChemical]}
      deletePermissions={[Permission.Resources_AddChemical]}
      createFields={fields}
      viewDetailsPermissions={[Permission.Resources_ShowChemicalDetails]}
    />
  );
};

export default ChemicalsView;
