import UseCase from '../../../../core/app/usecase';
import repository from '../../../repositories/siteVehicleRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	vehicleId: string;
};

class RestoreSiteVehicle implements UseCase<Parameters, void> {
	constructor(private repository: repository) {}

	async Call({ companyId, siteId, vehicleId }: Parameters): Promise<void> {
		return this.repository.restoreSiteVehicle(companyId, siteId, vehicleId);
	}
}

export default RestoreSiteVehicle;
