import { RepeatClockIcon } from "@chakra-ui/icons";
import ActionButton, { ActionButtonProps } from "./ActionButton";
import { Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const RestoreButton = ({
  onClick,
  tooltipLabel = "restore",
}: ActionButtonProps & { tooltipLabel?: string }) => {
  const { t } = useTranslation("documents");

  return (
    <Tooltip
      label={t(tooltipLabel, { ns: "common" })}
      aria-label={tooltipLabel}
    >
      <span>
        <ActionButton
          aria-label="delete"
          onClick={onClick}
          icon={<RepeatClockIcon />}
        />
      </span>
    </Tooltip>
  );
};

export default RestoreButton;
