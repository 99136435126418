import UseCase from '../../../core/app/usecase';
import { BadgeReadersType } from '../../../presentation/hooks/Site/useSiteBadgeReadersViewModel';
import BadgeReader from '../../entities/badgeReader';
import BadgeReaderRepository from '../../repositories/badgeReaderRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	badgeReader: Partial<BadgeReader>;
	type: BadgeReadersType;
};

class CreateBadgeReader implements UseCase<ARGS, BadgeReader> {
	private badgeReaderRepository: BadgeReaderRepository;

	constructor(badgeReaderRepository: BadgeReaderRepository) {
		this.badgeReaderRepository = badgeReaderRepository;
	}

	async Call({ companyId, siteId, badgeReader, type }: ARGS): Promise<BadgeReader> {
		return await this.badgeReaderRepository.createBadgeReader(companyId, siteId, badgeReader, type);
	}
}

export default CreateBadgeReader;
