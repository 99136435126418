import { FiPlus } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import React, { FC, useState } from "react";
import { COLORS } from "../../assets/theme/colors";
import { getBadgeReaderPath } from "../../../utils";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../providers/Auth0JWTProvider";
import BadgeReader from "../../../domain/entities/badgeReader";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { Permission } from "../../components/Permissions/Permissions";
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import RenderIf, {
  useHasPermissions,
} from "../../components/Permissions/RenderIf";
import BadgeReadersTable from "../../components/Views/BadgeReader/BadgeReadersTable";
import CreateBadgeReaderModal from "../../components/Views/SiteDetails/CreateBadgeReaderModal";
import CreateVirtualBadgeReaderModal from "../../components/Views/SiteDetails/CreateVirtualBadgeReaderModal";
import useSitesBadgeReadersViewModel, {
  BadgeReadersType,
} from "../../hooks/Site/useSiteBadgeReadersViewModel";
import { useMediaQuery } from "@chakra-ui/react";
import { Alert } from "../Common/Alert";
import SearchInput from "../Common/SearchInput";

interface Props {
  managePermissions: Permission[];
  viewDetailsPermissions: Permission[];
  createBadgeReaderPermissions: Permission[];
  createVirtualBadgeReaderPermissions: Permission[];
}

const SiteBadgeReadersView: FC<Props> = ({
  managePermissions,
  viewDetailsPermissions,
  createBadgeReaderPermissions,
  createVirtualBadgeReaderPermissions,
}) => {
  const { siteId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("badgeReaders");
  const { companyId } = useAuth();

  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showCreateVirtual, setShowCreateVirtual] = useState<boolean>(false);

  const {
    filteredBadgeReaders,
    filteredVirtualBadgeReaders,
    badgeReadersFetching,
    virtualBadgeReadersFetching,
    hasNextPage,
    fetchNextPage,
    virtualHasNextPage,
    virtualFetchNextPage,
    createBadgeReader,
    deleteBadgeReader,
    filters,
    addFilter,
    sorting,
    setSorting,
    virtualFilters,
    addVirtualFilter,
    virtualSorting,
    setVirtualSorting,
    createVirtualBadgeReader,
    filteredVirtualBadgeReadersAvailableUsers,
    virtualUsersHasNextPage,
    virtualUsersFetchNextPage,
    error,
    setError,
    setSearchPhysical,
    setSearchVirtual,
    virtualUsersFilters,
    addVirtualUsersFilter,
    virtualUsersSorting,
    setVirtualUsersSorting,
    unlinkBadgeReader,
    badgeReaderType,
    setBadgeReaderType,
  } = useSitesBadgeReadersViewModel(siteId);

  const navigateToBadgeReaderDetail = (badgeReader: BadgeReader) => {
    navigate(`/sites/${siteId}/access/badge-readers/${badgeReader?.id}/`, {
      state: { badgeReaderType: badgeReaderType },
    });
  };

  const onTabSelect = (t) => {
    setBadgeReaderType(
      t === 0 ? BadgeReadersType.physical : BadgeReadersType.virtual
    );
  };

  const canViewDetails = useHasPermissions(viewDetailsPermissions);
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf
            permissions={
              badgeReaderType === BadgeReadersType.physical
                ? createBadgeReaderPermissions
                : createVirtualBadgeReaderPermissions
            }
          >
            <ActionBarItem
              icon={FiPlus}
              description={
                badgeReaderType == BadgeReadersType.physical
                  ? t("addPhysical", { ns: "badgeReaders" })
                  : t("addVirtual", { ns: "badgeReaders" })
              }
              onClick={() => {
                badgeReaderType == 0
                  ? setShowCreate(true)
                  : setShowCreateVirtual(true);
              }}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      <Text
        fontSize="xl"
        textColor={COLORS.sikuroBlue}
        fontWeight="bold"
        marginLeft={10}
        marginTop={10}
      >
        {t("badgeReaders", { ns: "badgeReaders" })}
      </Text>

      <Text textColor={"black"} fontSize={16} marginLeft={10} marginBottom={5}>
        {t("siteBadgeReaders", { ns: "badgeReaders" })}
      </Text>

      <Tabs
        height="100%"
        defaultIndex={0}
        marginTop={4}
        marginLeft={10}
        marginRight={10}
        onChange={onTabSelect}
        width={isMobile ? "1200px" : undefined}
      >
        <TabList>
          <Tab width="50%">{t("physical", { ns: "badgeReaders" })}</Tab>
          <Tab width="50%">{t("virtual", { ns: "badgeReaders" })}</Tab>
        </TabList>
        <TabPanels>
          {["physical", "virtual"].map((type: "physical" | "virtual") => (
            <TabPanel key={type} paddingLeft={0} paddingRight={0}>
              <Flex
                justifyContent={"end"}
                alignItems="center"
                w={"100%"}
                marginBottom={4}
              >
                <SearchInput
                  onSearch={(query) => {
                    if (badgeReaderType === BadgeReadersType.physical) {
                      setSearchPhysical(query);
                    } else {
                      setSearchVirtual(query);
                    }
                  }}
                />
              </Flex>
              <BadgeReadersTable
                badgeReaders={
                  badgeReaderType == BadgeReadersType.physical
                    ? filteredBadgeReaders
                    : filteredVirtualBadgeReaders
                }
                badgeReadersIsFetching={
                  badgeReaderType == BadgeReadersType.physical
                    ? badgeReadersFetching
                    : virtualBadgeReadersFetching
                }
                badgeReadersFilters={
                  badgeReaderType == BadgeReadersType.physical
                    ? filters
                    : virtualFilters
                }
                updateBadgeReadersFilters={
                  badgeReaderType == BadgeReadersType.physical
                    ? addFilter
                    : addVirtualFilter
                }
                badgeReadersSorting={
                  badgeReaderType == BadgeReadersType.physical
                    ? sorting
                    : virtualSorting
                }
                updateBadgeReadersSorting={
                  badgeReaderType == BadgeReadersType.physical
                    ? setSorting
                    : setVirtualSorting
                }
                hasNextPage={
                  badgeReaderType == BadgeReadersType.physical
                    ? hasNextPage
                    : virtualHasNextPage
                }
                fetchNextPage={
                  badgeReaderType == BadgeReadersType.physical
                    ? fetchNextPage
                    : virtualFetchNextPage
                }
                onBadgeReadersClick={navigateToBadgeReaderDetail}
                isPhysical={badgeReaderType == BadgeReadersType.physical}
                canViewDetails={canViewDetails}
                deleteBadgeReader={deleteBadgeReader}
                unlinkBadgeReader={unlinkBadgeReader}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      {showCreate && (
        <CreateBadgeReaderModal
          onClose={() => setShowCreate(false)}
          onConfirm={createBadgeReader}
          getPath={(badgeReaderId) =>
            getBadgeReaderPath(companyId, siteId, badgeReaderId)
          }
        />
      )}

      {showCreateVirtual && (
        <CreateVirtualBadgeReaderModal
          onClose={() => setShowCreateVirtual(false)}
          onConfirm={createVirtualBadgeReader}
          availableUsers={filteredVirtualBadgeReadersAvailableUsers}
          availableUsersFetching={false}
          virtualBadgeReaderUsersFilters={virtualUsersFilters}
          updateVirtualBadgeReadersUsersFilters={addVirtualUsersFilter}
          virtualBadgeReadersUsersSorting={virtualUsersSorting}
          updateVirtualBadgeReaderUsersSorting={setVirtualUsersSorting}
          hasNextPage={virtualUsersHasNextPage}
          fetchNextPage={virtualUsersFetchNextPage}
        />
      )}

      {error && (
        <Alert
          message={t(error, { ns: "errors" })}
          onClose={() => setError(undefined)}
          title={t("warning", { ns: "common" })}
          variant="warning"
        ></Alert>
      )}
    </ContentLayout>
  );
};

export default SiteBadgeReadersView;
