import ActionButton, { ActionButtonProps } from "./ActionButton";
import { MdModeEdit } from "react-icons/md";
import { Icon, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const EditButton = ({ onClick, buttonColor, buttonWidth }: ActionButtonProps) => {
  const { t } = useTranslation("documents");

  return (
    <Tooltip label={t("edit", { ns: "common" })} aria-label="Preview">
      <span>
        <ActionButton
          aria-label="edit"
          onClick={onClick}
          buttonWidth={buttonWidth}
          fontSize="22px"
          color={buttonColor}
          icon={<Icon as={MdModeEdit} fontSize="22px" />}
        />
      </span>
    </Tooltip>
  );
};

export default EditButton;
