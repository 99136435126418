import { SiteResource } from './siteResource';
import Badge from './badge';
import { SupplierResource } from './supplierResource';

export type SiteVehicleParams = {
	name: string;
	plate: string;
	model: string;
};

export type SiteVehicle = SiteResource<SiteVehicleParams & { badge?: Badge }>;

export type SupplierVehicle = SupplierResource<SiteVehicleParams>;

export const mapSiteVehicle =
	(siteId: string = null) =>
		(siteVehicle): SiteVehicle => ({
			name: siteVehicle.name,
			plate: siteVehicle.plate,
			model: siteVehicle.model,
			variant: siteVehicle.variant,
			specializations: siteVehicle.specializations,
			company: siteVehicle.companyName,
			id: siteVehicle.id,
			siteId: siteId,
			companyId: siteVehicle.companyId,
			state: siteVehicle.state,
			isGlobal: siteVehicle.isGlobal,
			isForcedEvaluation: siteVehicle.isForcedEvaluation,
		});
