import UseCase from '../../../../core/app/usecase';
import MachineRepository from '../../../repositories/machineRepository';

interface Args {
	companyId: string;
	resourceId: string;
	typologyId: string;
}

class AddTypologyToMachine implements UseCase<Args, void> {
	constructor(private repository: MachineRepository) {}

	async Call(args: Args): Promise<void> {
		return await this.repository.addTypologyToMachine(args.companyId, args.resourceId, args.typologyId);
	}
}

export default AddTypologyToMachine;
