import UseCase from '../../../../core/app/usecase';
import SiteToolRepository from '../../../repositories/siteToolRepository';
import { SiteTool } from '../../../entities/siteTool';

type GetSiteToolParameters = {
	companyId: string;
	siteId: string;
	toolId: string;
};

class GetSiteTool implements UseCase<GetSiteToolParameters, SiteTool | undefined> {
	constructor(private repository: SiteToolRepository) {}

	async Call({ companyId, siteId, toolId }: GetSiteToolParameters): Promise<SiteTool | undefined> {
		return this.repository.getSiteTool(companyId, siteId, toolId);
	}
}

export default GetSiteTool;
