import { makeWorkerRepository } from './repositories';
import GetWorker from '../../../../domain/interactors/resources/workers/getWorker';
import GetWorkers from '../../../../domain/interactors/resources/workers/getWorkers';
import CreateWorker from '../../../../domain/interactors/resources/workers/createWorker';
import DeleteWorker from '../../../../domain/interactors/resources/workers/deleteWorker';
import UpdateWorker from '../../../../domain/interactors/resources/workers/updateWorker';
import GetWorkerDocuments from '../../../../domain/interactors/resources/workers/getWorkerDocuments';
import UpdateWorkerStatus from '../../../../domain/interactors/resources/workers/updateWorkerStatus';
import AddTypologyToWorker from '../../../../domain/interactors/resources/workers/addTypologyToWorker';
import CreateWorkerDocument from '../../../../domain/interactors/resources/workers/createWorkerDocument';
import RemoveTypologyFromWorker from '../../../../domain/interactors/resources/workers/removeTypologyFromWorker';
import UploadWorkers from '../../../../domain/interactors/resources/workers/uploadWorkers';
import CreatePropagableWorkerDocument from '../../../../domain/interactors/resources/workers/createPropagableWorkerDocuments';
import EvaluateWorkerDocument from '../../../../domain/interactors/resources/workers/evaluateWorkerDocument';
import GetAvailbleWorkersCount from '../../../../domain/interactors/resources/workers/getAvailableWorkersCount';
import RestoreWorker from '../../../../domain/interactors/resources/workers/restoreWorker';
import AddResourceToSites from '../../../../domain/interactors/site/addResourceToSite';
import { makeSiteRepository } from '../sites/repositories';
import GetResourceBadge from '../../../../domain/interactors/resources/workers/getResourceBadge';
import GetAvailableBadges from '../../../../domain/interactors/resources/workers/getAvailableBadges';
import LinkBadgesResource from '../../../../domain/interactors/resources/vehicles/linkBadgesResource';
import RepropagateEvaluationWorkerDocument from '../../../../domain/interactors/resources/workers/repropagateEvaluationWorkerDocument';

export const makeGetWorkers = () => {
	return new GetWorkers(makeWorkerRepository());
};

export const makeRestoreWorker = () => {
	return new RestoreWorker(makeWorkerRepository());
};

export const makeGetAvailableWorkersCount = () => {
	return new GetAvailbleWorkersCount(makeWorkerRepository());
};

export const makeGetWorker = () => {
	return new GetWorker(makeWorkerRepository());
};

export const makeGetResourceBadge = () => {
	return new GetResourceBadge(makeWorkerRepository());
};

export const makeLinkBadgesResource = () => {
	return new LinkBadgesResource(makeWorkerRepository());
};

export const makeGetAvailableBadgesUseCase = () => {
	return new GetAvailableBadges(makeWorkerRepository());
};

export const makeCreateWorker = () => {
	return new CreateWorker(makeWorkerRepository());
};

export const makeUploadWorkers = () => {
	return new UploadWorkers(makeWorkerRepository());
};

export const makeUpdateWorker = () => {
	return new UpdateWorker(makeWorkerRepository());
};

export const makeUpdateWorkerStatus = () => {
	return new UpdateWorkerStatus(makeWorkerRepository());
};

export const makeDeleteWorker = () => {
	return new DeleteWorker(makeWorkerRepository());
};

export const makeGetWorkerDocuments = () => {
	return new GetWorkerDocuments(makeWorkerRepository());
};

export const makeCreateWorkerDocument = () => {
	return new CreateWorkerDocument(makeWorkerRepository());
};

export const makeCreatePropagableWorkerDocument = () => {
	return new CreatePropagableWorkerDocument(makeWorkerRepository());
};

export const makeEvaluateWorkerDocument = () => {
	return new EvaluateWorkerDocument(makeWorkerRepository());
};

export const makeRepropagateEvaluationWorkerDocument = () => {
	return new RepropagateEvaluationWorkerDocument(makeWorkerRepository());
};

export const makeAddTypologyToWorker = () => {
	return new AddTypologyToWorker(makeWorkerRepository());
};

export const makeRemoveTypologyFromWorker = () => {
	return new RemoveTypologyFromWorker(makeWorkerRepository());
};
export const makeAddResourceToSites = () => {
	return new AddResourceToSites(makeSiteRepository());
};
