import UseCase from '../../../../core/app/usecase';
import WorkerRepository from '../../../repositories/workerRepository';

type CreateWorkerDocumentParameters = {
	companyId: string;
	resourceId: string;
	documents: string[];
};

/**
 * Returns the list of all the existing Document.
 */
class CreateWorkerDocument implements UseCase<CreateWorkerDocumentParameters, void> {
	constructor(private repository: WorkerRepository) {}

	async Call({ companyId, resourceId, documents }: CreateWorkerDocumentParameters): Promise<void> {
		return await this.repository.createWorkerDocument(companyId, resourceId, documents);
	}
}

export default CreateWorkerDocument;
