import UseCase from '../../../core/app/usecase';
import RolesRepository from '../../repositories/rolesRepository';

interface Args {
	companyId: string;
	roleId: string;
	context: string;
}

class RoleLinkedUsers implements UseCase<Args, number> {
	constructor(private repository: RolesRepository) {}

	async Call({ companyId, roleId, context }: Args): Promise<number> {
		return await this.repository.roleLinkedUsers(companyId, roleId, context);
	}
}

export default RoleLinkedUsers;
