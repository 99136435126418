import CreateBadge from '../../../../domain/interactors/badge/createBadge';
import CreateBadgeLogEntry from '../../../../domain/interactors/badge/createBadgeLogEntry';
import CreateMultipleBadgeQR from '../../../../domain/interactors/badge/createMultipleBadgeQR';
import DeleteBadge from '../../../../domain/interactors/badge/deleteBadge';
import DeleteBadgeLogEntry from '../../../../domain/interactors/badge/deleteBadgeLogEntry';
import GetAvaialbleBadgesSiteCount from '../../../../domain/interactors/badge/getAvailableBadgesSiteCount';
import GetBadge from '../../../../domain/interactors/badge/getBadge';
import GetBadgeHistory from '../../../../domain/interactors/badge/getBadgeHistory';
import GetBadges from '../../../../domain/interactors/badge/getBadges';
import GetBadgeSites from '../../../../domain/interactors/badge/getBadgeSites';
import GetBadgesNoPaging from '../../../../domain/interactors/badge/getBadgesNoPaging';
import GetSiteBadges from '../../../../domain/interactors/badge/getSiteBadges';
import GetSiteBadgesFromInterval from '../../../../domain/interactors/badge/getSiteBadgesFromInterval';
import ImportBadges from '../../../../domain/interactors/badge/importBadges';
import ImportLinkResourcesBadges from '../../../../domain/interactors/badge/importLinkResourcesBadges';
import LinkBadgeResource from '../../../../domain/interactors/badge/linkBadgeResource';
import LinkBadgesToResources from '../../../../domain/interactors/badge/linkBadgesToResources';
import LinkBadgesToSite from '../../../../domain/interactors/badge/linkBadgesToSite';
import LinkBadgesToSiteMachines from '../../../../domain/interactors/badge/linkBadgesToSiteMachines';
import LinkBadgesToSiteVehicles from '../../../../domain/interactors/badge/linkBadgesToSiteVehicles';
import LinkBadgesToSiteWorkers from '../../../../domain/interactors/badge/linkBadgesToSiteWorkers';
import LinkBadgeTag from '../../../../domain/interactors/badge/linkBadgeTag';
import UnlinkBadgeResource from '../../../../domain/interactors/badge/unlinkBadgeFromResource';
import UnlinkBadgeFromSite from '../../../../domain/interactors/badge/unlinkBadgeFromSite';
import UnlinkBadgesFromResources from '../../../../domain/interactors/badge/unlinkBadgesFromResources.ts';
import UnlinkBadgeTag from '../../../../domain/interactors/badge/unlinkBadgeTag';
import UpdateBadge from '../../../../domain/interactors/badge/updateBadge';
import GetAvailableBadges from '../../../../domain/interactors/resources/workers/getAvailableBadges';
import { makeWorkerRepository } from '../workers/repositories';
import { makeBadgeRepository } from './repositories';

export const makeGetBadges = () => {
	return new GetBadges(makeBadgeRepository());
};

export const makeGetBadgesNotPaginated = () => {
	return new GetBadgesNoPaging(makeBadgeRepository());
};

export const makeDeleteBadge = () => {
	return new DeleteBadge(makeBadgeRepository());
};

export const makeImportBadges = () => {
	return new ImportBadges(makeBadgeRepository());
};

export const makeCreateBadge = () => {
	return new CreateBadge(makeBadgeRepository());
};

export const makeCreateMultipleBadgeQR = () => {
	return new CreateMultipleBadgeQR(makeBadgeRepository());
};

export const makeUpdateBadge = () => {
	return new UpdateBadge(makeBadgeRepository());
};

export const makeGetBadge = () => {
	return new GetBadge(makeBadgeRepository());
};

export const makeGetBadgeSites = () => {
	return new GetBadgeSites(makeBadgeRepository());
};

export const makeGetAvailableBadgesUseCase = () => {
	return new GetAvailableBadges(makeWorkerRepository());
};

export const makeGetAvailableBadgesSiteCount = () => {
	return new GetAvaialbleBadgesSiteCount(makeBadgeRepository());
};

export const makeImportLinkResourceBadges = () => {
	return new ImportLinkResourcesBadges(makeBadgeRepository());
};
export const makeUnlinkBadgesFromResources = () => {
	return new UnlinkBadgesFromResources(makeBadgeRepository());
};

export const makeGetSiteBadges = () => {
	return new GetSiteBadges(makeBadgeRepository());
};

export const makeGetSiteBadgeFromInterval = () => {
	return new GetSiteBadgesFromInterval(makeBadgeRepository());
};

export const makeLinkBadgeToSite = () => {
	return new LinkBadgesToSite(makeBadgeRepository());
};

export const makeUnlinkBadgeFromSite = () => {
	return new UnlinkBadgeFromSite(makeBadgeRepository());
};

export const makeLinkBadgeToSiteWorker = () => {
	return new LinkBadgesToSiteWorkers(makeBadgeRepository());
};

export const makeLinkBadgeToSiteVehicle = () => {
	return new LinkBadgesToSiteVehicles(makeBadgeRepository());
};

export const makeLinkBadgeToSiteMachine = () => {
	return new LinkBadgesToSiteMachines(makeBadgeRepository());
};

export const makeLinkBadgeToResources = () => {
	return new LinkBadgesToResources(makeBadgeRepository());
};
export const makeUnlinkBadgeResource = () => {
	return new UnlinkBadgeResource(makeBadgeRepository());
};
export const makeLinkBadgeResource = () => {
	return new LinkBadgeResource(makeBadgeRepository());
};

export const makeGetBadgeHistory = () => {
	return new GetBadgeHistory(makeBadgeRepository());
};

export const makeCreateBadgeLogEntry = () => {
	return new CreateBadgeLogEntry(makeBadgeRepository());
};

export const makeDeleteBadgeLogEntry = () => {
	return new DeleteBadgeLogEntry(makeBadgeRepository());
};

export const makeLinkBadgeTag = () => {
	return new LinkBadgeTag(makeBadgeRepository());
};

export const makeUnlinkBadgeTag = () => {
	return new UnlinkBadgeTag(makeBadgeRepository());
};
