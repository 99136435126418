import UseCase from '../../../core/app/usecase';
import { AuthRepository, makeAuthRepository } from '../../repositories/authRepository';
import { LoginResponse } from '../../../infrastructure/responses/auth';

export class PerformLoginWithToken implements UseCase<string, LoginResponse> {
	constructor(private repository: AuthRepository) {}

	async Call(param: string): Promise<LoginResponse> {
		const refreshToken = localStorage.getItem('refresh_token');
		const localTenant = localStorage.getItem('tenantId');
		if (!refreshToken) {
			return Promise.reject(new Error('error.wrong-credentials'));
		}

		const response = await this.repository.loginWithToken(refreshToken, localTenant);
		localStorage.setItem('access_token', response.token.access_token);
		localStorage.setItem('refresh_token', response.token.refresh_token);
		return response;
	}
}

export const makePerformLoginWithToken = (): PerformLoginWithToken => {
	return new PerformLoginWithToken(makeAuthRepository());
};
