import UseCase from '../../../core/app/usecase';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import Requirement from '../../entities/requirement';
import SupplierRepository from '../../repositories/supplierRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	supplierId: string;
	requirements: {
		documentTypeId: string;
		isOptional: boolean;
		graceDays: number;
	}[];
	source?: DocumentTypeCategory;
};

class AddRequirementToSupplier implements UseCase<ARGS, Requirement> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call(parameters: ARGS): Promise<Requirement> {
		return await this.supplierRepository.addRequirementToSupplier(
			parameters.companyId,
			parameters.siteId,
			parameters.supplierId,
			parameters.requirements,
			parameters.source
		);
	}
}

export default AddRequirementToSupplier;
