import {
  makeActivateSubcontractor,
  makeActivateSupplier,
  makeActivateUser,
  makeConfirmSubcontractor, makeConfirmUserEmail
} from "../../../core/app/factory/activation/usecases";

class ActivationViewModel {
  private activateUserUC = makeActivateUser();
  private activateSupplierUC = makeActivateSupplier();
  private activateSubcontractorUC = makeActivateSubcontractor();
  private confirmSubcontractorUC = makeConfirmSubcontractor();
  private confirmUserEmailUC = makeConfirmUserEmail();

  async activateUser(
    token: string,
    companyId: string,
    siteId?: string,
  ): Promise<void> {
    return await this.activateUserUC.Call({ token, companyId, siteId });
  }
  async activateSupplier(
    token: string,
    companyId: string,
    siteId?: string,
  ): Promise<void> {
    return await this.activateSupplierUC.Call({ token, companyId, siteId });
  }
  async activateSubcontractor(
    token: string,
    companyId: string,
    siteId?: string,
  ): Promise<void> {
    return await this.activateSubcontractorUC.Call({
      token,
      companyId,
      siteId,
    });
  }
  async confirmSubcontractor(
    token: string,
    companyId: string,
    siteId?: string,
  ): Promise<void> {
    return await this.confirmSubcontractorUC.Call({ token, companyId, siteId });
  }
  async confirmUserEmail(
    token: string,
  ): Promise<void> {
    return await this.confirmUserEmailUC.Call({ token });
  }
}

export { ActivationViewModel };
