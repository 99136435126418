import UseCase from '../../../core/app/usecase';
import { ActivationRepository } from '../../repositories/activationRepository';

type ARGS = {
	token: string;
	companyId: string;
	siteId: string;
};

class ActivateSubcontractor implements UseCase<ARGS, void> {
	private activationRepository: ActivationRepository;

	constructor(activationRepository: ActivationRepository) {
		this.activationRepository = activationRepository;
	}

	async Call({ token, companyId, siteId }: ARGS): Promise<void> {
		return await this.activationRepository.activateSubcontractor(token, companyId, siteId);
	}
}

export default ActivateSubcontractor;
