import {
  makeAddRequirementToSiteTool,
  makeAutoEvaluateSiteTool,
  makeEvaluateSiteTool,
  makeGetSiteTool,
  makeGetSiteToolDocuments,
  makeGetSiteToolEvaluations,
} from "../../../core/app/factory/siteTools/usecases";
import { makeGetSiteVariants } from "../../../core/app/factory/variants/usecases";
import { makeGetSiteSpecializations } from "../../../core/app/factory/specializations/usecases";
import { SiteTool, SiteToolParams } from "../../../domain/entities/siteTool";
import Evaluation from "../../../domain/entities/evaluation";
import { ResourceEvaluationState } from "../../../domain/entities/resourceEvaluationState.enum";
import DocumentType from "../../../domain/entities/documentType";
import { makeGetDocumentTypes, makeGetDocumentTypesForAi } from "../../../core/app/factory/documentTypes/usecases";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SiteResourceDetailViewModel } from "../../hooks/Site/useSiteResourceDetailViewModel";
import { SiteResourceDocument } from "../../../domain/entities/document";
import BaseSiteResourceDetailViewModel from "./BaseSiteResourceDetailViewModel";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import {
  makeUpdateSiteToolSpecializations,
  makeUpdateSiteToolVariant,
} from "../../../core/app/factory/siteTools/usecases";
import { GetResourceEvaluationFilter } from "../../../domain/repositories/siteRepository";
import { makeDeleteSiteSupplierRequirement } from "../../../core/app/factory/suppliers/useCases";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import { GetWorkingSiteDocumentsFilter } from "../../../domain/repositories/filters";
import { makeGetRequirementsTemplateUrl } from "../../../core/app/factory/requirements/useCases";

class SiteToolDetailViewModel
  extends BaseSiteResourceDetailViewModel
  implements SiteResourceDetailViewModel<SiteToolParams> {
  async getSiteResource(
    companyId: string,
    siteId: string,
    resourceId: string,
  ): Promise<SiteTool> {
    return await makeGetSiteTool().Call({
      companyId,
      siteId,
      toolId: resourceId,
    });
  }

  async getSiteResourceDocuments(
    companyId: string,
    siteId: string,
    resourceId: string,
    filter: GetWorkingSiteDocumentsFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<SiteResourceDocument[]> {
    return await makeGetSiteToolDocuments().Call({
      companyId,
      siteId,
      toolId: resourceId,
      filter,
      sort,
      pageParam,
    });
  }

  async evaluateSiteResource(
    companyId: string,
    siteId: string,
    resourceId: string,
    value: ResourceEvaluationState,
  ): Promise<void> {
    await makeEvaluateSiteTool().Call({
      companyId,
      siteId,
      toolId: resourceId,
      value,
    });
  }

  async autoEvaluateSiteResource(
    companyId: string,
    siteId: string,
    resourceId: string,
  ): Promise<void> {
    await makeAutoEvaluateSiteTool().Call({
      companyId,
      siteId,
      toolId: resourceId,
    });
  }

  async getSiteResourceEvaluations(
    companyId: string,
    siteId: string,
    resourceId: string,
    pageParam: number,
    sort?: SortMeta,
    filter?: GetResourceEvaluationFilter,
  ): Promise<Evaluation<ResourceEvaluationState>[]> {
    return (await makeGetSiteToolEvaluations().Call({
      companyId,
      siteId,
      toolId: resourceId,
      pageParam,
      sort,
      filter,
    })) as unknown as Promise<Evaluation<ResourceEvaluationState>[]>;
  }

  async getVariants(companyId: string, siteId: string): Promise<any[]> {
    return await makeGetSiteVariants().Call({
      companyId,
      siteId,
      type: DocumentTypeCategory.TOOL,
    });
  }

  async getSpecializations(companyId: string, siteId: string): Promise<any[]> {
    return await makeGetSiteSpecializations().Call({
      companyId,
      siteId,
      type: DocumentTypeCategory.TOOL,
    });
  }

  async getRequirementsTemplateUrl(
		companyId: string,
		siteId: string,
		requirementId: string,
		templateId: string
	): Promise<string> {
		return await makeGetRequirementsTemplateUrl().Call({ companyId, siteId, requirementId, templateId });
	}

  async updateSiteResourceVariant(
    companyId: string,
    toolId: string,
    siteId: string,
    variantId?: string,
  ): Promise<void> {
    await makeUpdateSiteToolVariant().Call({
      companyId,
      toolId,
      siteId,
      variantId,
    });
  }

  async updateSiteResourceSpecializations(
    companyId: string,
    toolId: string,
    siteId: string,
    specializationIds?: string[],
  ): Promise<void> {
    await makeUpdateSiteToolSpecializations().Call({
      companyId,
      toolId,
      siteId,
      specializationIds,
    });
  }

  async addRequirementToSiteResource(
    companyId: string,
    toolId: string,
    siteId: string,
    requirements: {
      documentTypeId: string;
      isOptional: boolean;
      graceDays: number;
    }[]
  ): Promise<void> {
    await makeAddRequirementToSiteTool().Call({
      companyId,
      toolId,
      siteId,
      requirements
    });
  }

  async removeRequirementFromSiteResource(
    companyId: string,
    toolId: string,
    siteId: string,
    requirementId: string,
    subject: RequirementSubject,
  ): Promise<void> {
    await makeDeleteSiteSupplierRequirement().Call({
      companyId,
      siteId,
      requirementId,
      subject,
      resourceId: toolId,
    });
  }

  async getDocumentTypes(
    companyId: string,
    search?: string,
    tags?: string[],
    sort?: SortMeta,
    pageParam?: number,
    resourceId?: string,
    systemOnly?: boolean,
  ): Promise<DocumentType[]> {
    return await makeGetDocumentTypes().Call({
      companyId,
      filter: DocumentTypeCategory.TOOL,
      //NM:I assigned the opposite of systemOnly because if the latter is true, then includePublic must be false and vice versa.
      includePublic: !systemOnly,
      search,
      tags,
      sort,
      pageParam,
      withFileCount: true,
      resourceId,
      systemOnly,
    });
  }

  async getDocumentTypesForAi(companyId: string, aiTaskId: string, resourceType: DocumentTypeCategory): Promise<DocumentType[]> {
    return await makeGetDocumentTypesForAi().Call({
      companyId,
      aiTaskId,
      resourceType
    });
  }
  async repropagateFileToDocument() {
    throw new Error("Method not implemented");
  }
}

export default SiteToolDetailViewModel;
