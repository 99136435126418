import { MdOutlineNavigateNext } from "react-icons/md";
import ActionButton, { ActionButtonProps } from "./ActionButton";

const NavigationButton = ({ onClick }: ActionButtonProps) => (
  <ActionButton
    aria-label="delete"
    onClick={onClick}
    icon={<MdOutlineNavigateNext />}
  />
);

export default NavigationButton;
