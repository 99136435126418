import UseCase from '../../../core/app/usecase';
import DocumentNote from '../../entities/documentNote';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import DocumentRepository from '../../repositories/documentRepository';

interface Args {
	companyId: string;
	siteId?: string;
	resourceId: string;
	resourceType: DocumentTypeCategory;
}

class GetResourceDocumentNote implements UseCase<Args, DocumentNote> {
	constructor(private repository: DocumentRepository) {}
	async Call({ companyId, siteId, resourceId, resourceType }: Args): Promise<DocumentNote> {
		return this.repository.getResourceDocumentNote(companyId, resourceId, resourceType, siteId);
	}
}

export default GetResourceDocumentNote;
