import UseCase from '../../../../core/app/usecase';
import ChemicalRepository, { GetChemicalsFilter } from '../../../repositories/chemicalRepository';
import { SortMeta } from '../../../entities/interfaces/paginatedResults';

type ARGS = {
	companyId: string;
	filter?: GetChemicalsFilter;
	sort?: SortMeta;
};

class GetAvailableChemicalsCount implements UseCase<ARGS, number> {
	constructor(private repository: ChemicalRepository) {}

	async Call({ companyId, filter, sort }: ARGS): Promise<number> {
		return await this.repository.getAvailableChemicalsCount(companyId, filter, sort);
	}
}

export default GetAvailableChemicalsCount;
