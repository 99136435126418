import UseCase from '../../../core/app/usecase';
import User from '../../entities/user';
import UsersRepository from '../../repositories/usersRepository';

interface Args {
	companyId: string;
	userId: string;
	image: File;
}

class UpdateUserImage implements UseCase<Args, User> {
	constructor(private repository: UsersRepository) {}

	async Call({ companyId, userId, image }: Args): Promise<User> {
		return await this.repository.updateUserImage(companyId, userId, image);
	}
}

export default UpdateUserImage;
