import UseCase from '../../../core/app/usecase';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { DashboardExpiringSupplierEvaluation } from '../../entities/dasboardExpiringSupplierEvaluation';
import DashboardRepository, { GetSupplierEvaluationsFilter } from '../../repositories/dashboardRepository';

interface Args {
	days: number;
	sort: SortMeta;
	companyId: string;
	pageParam: number;
	filter: GetSupplierEvaluationsFilter;
}

class GetDashboardExpiringResourcesEvaluations implements UseCase<Args, DashboardExpiringSupplierEvaluation[]> {
	private dashboardRepository: DashboardRepository;

	constructor(dashboardRepository: DashboardRepository) {
		this.dashboardRepository = dashboardRepository;
	}

	async Call({ companyId, pageParam, sort, filter, days }: Args): Promise<DashboardExpiringSupplierEvaluation[]> {
		return await this.dashboardRepository.getDashboardExpiringResourcesEvaluations(companyId, pageParam, sort, filter, days);
	}
}

export default GetDashboardExpiringResourcesEvaluations;
