import { Skeleton, Td, Tr } from "@chakra-ui/react";

interface SkeletonProps {
  columnNumber: number;
  rowNumber: number;
  hasAction?: boolean;
}

const SkeletonTableRow = ({
  columnNumber,
  rowNumber,
  hasAction,
}: SkeletonProps) => {
  const skeletonRows = Array.from({ length: rowNumber }, (_, rowIndex) => (
    <Tr key={rowIndex} backgroundColor={"gray.50"}>
      {Array.from({ length: columnNumber }, (_, columnIndex) => (
        <Td key={columnIndex}>
          <Skeleton
            height={8}
            width={"50%"}
            borderRadius={8}
            startColor="gray.200"
            endColor="gray.300"
          />
        </Td>
      ))}
      {hasAction && <Td key={""}></Td>}
    </Tr>
  ));

  return <>{skeletonRows}</>;
};

export default SkeletonTableRow;
