import { useTranslation } from "react-i18next";
import TopBar from "../../Common/TopBar";

const SuppliersTopBar = () => {
  const { t } = useTranslation();

  return <TopBar title={t("suppliers", { ns: "navbar" })} />;
};

export default SuppliersTopBar;
