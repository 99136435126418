import UseCase from '../../../../core/app/usecase';
import WorkerRepository from '../../../repositories/workerRepository';

type Args = {
	companyId: string;
	workerId: string;
};

class RestoreWorker implements UseCase<Args, void> {
	constructor(private repository: WorkerRepository) {}

	async Call({ companyId, workerId }: Args): Promise<void> {
		return await this.repository.restoreWorker(companyId, workerId);
	}
}

export default RestoreWorker;
