import { useTranslation } from "react-i18next";
import SubNavBar from "../../components/Common/SubNavBar";
import { FaRegBell, FaRegUser } from "react-icons/fa";

export const UserSettingsSubNav = () => {
  const { t } = useTranslation("sites");

  return (
    <SubNavBar
      items={[
        {
          icon: FaRegUser,
          path: `/userSettings/details`,
          name: t("sub.userDetails", {ns: "navbar"}),
        },
        {
          icon: FaRegBell,
          path: `/userSettings/notifications`,
          name: t("main.notifications", {ns: "navbar"}),
        },
      ]}
    />
  );
};
