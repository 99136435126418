import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Card,
  CardHeader,
  Flex,
  Icon,
  IconButton,
  Spacer,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import AiTask, { AiTaskStatus } from "../../../../domain/entities/aiTask";
import ProgressLoader from "../common/ProgressLoader";
import { COLORS } from "../../../assets/theme/colors";
import { FaRegClock } from "react-icons/fa";
import { formatDateTime } from "../../../../utils";
import StateTag from "../../Common/StateTag";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import ConfirmDeleteNotification from "../Notifications/ConfirmDeleteNotification";
import { Alert } from "../../../screens/Common/Alert";

interface Props {
  aiTask: AiTask;
  onDelete?: (taskId: string) => void;
}

const AiTaskRow: FC<Props> = ({ aiTask, onDelete }) => {
  const { t } = useTranslation("documents");
  const [confirmDelete, setConfirmDelete] = useState<string | null>(null);
  return (
    <>
      <Card mb={2} variant="outline">
        <CardHeader>
          <Flex>
            <Flex gap={4} alignItems={"center"}>
              <Text fontWeight="semibold" overflowWrap="anywhere" mx={2}>
                {t("taskCreateOn")}
                {formatDateTime(
                  aiTask?.createdAt as unknown as string,
                  "/",
                  true
                )}
              </Text>
              {aiTask.status === AiTaskStatus.RUNNING && (
                <Icon as={FaRegClock} boxSize={5} />
              )}
              <StateTag
                key={aiTask.status}
                value={aiTask.status}
                type="aiTaskStatus"
              />
            </Flex>
            <Spacer />

            {onDelete &&
              (aiTask.status === AiTaskStatus.TERMINATED ||
                aiTask.status === AiTaskStatus.ERROR) && (
                <Tooltip label={t("deleteAiTask")}>
                  <IconButton
                    aria-label="deleteAiTask"
                    onClick={(e) => {
                      e.stopPropagation();
                      setConfirmDelete(aiTask?.id);
                    }}
                    isRound
                    ml={2}
                    variant="ghost"
                    bgColor="red.500"
                    color="white"
                    size={"sm"}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
          </Flex>
          <Spacer height={5} />
          <ProgressLoader
            color={
              aiTask.status === AiTaskStatus.RUNNING
                ? "gray"
                : COLORS.sikuroBlue
            }
            animated={aiTask.status === AiTaskStatus.RUNNING}
          />
        </CardHeader>
      </Card>
      {confirmDelete && (
        <ConfirmAlert
          onConfirm={() => {
            onDelete(confirmDelete);
          }}
          message={t("deleteTaskConfirm")}
          onCancel={() => setConfirmDelete(null)}
          title={t("warning", { ns: "common" })}
          variant="warning"
        ></ConfirmAlert>
      )}
    </>
  );
};

export default AiTaskRow;
