import UseCase from '../../../../core/app/usecase';
import SiteWorkerRepository from '../../../repositories/siteWorkerRepository';
import { ResourceEvaluationState } from '../../../entities/resourceEvaluationState.enum';

type Parameters = {
	companyId: string;
	siteId: string;
	workerId: string;
};

class AutoEvaluateSiteWorker implements UseCase<Parameters, void> {
	constructor(private repository: SiteWorkerRepository) {}

	async Call({ companyId, siteId, workerId }: Parameters): Promise<void> {
		return this.repository.autoEvaluateSiteWorker(companyId, siteId, workerId);
	}
}

export default AutoEvaluateSiteWorker;
