export enum DocumentPresetTarget {
	WORKER = 'worker',
	VEHICLE = 'vehicle',
	MACHINE = 'machine',
	TOOL = 'tool',
	CHEMICAL = 'chemical',
	SITE = 'site',
}

export const documentPresetTargetFromString = (source: string): DocumentPresetTarget => {
	switch (source) {
		case 'workers':
			return DocumentPresetTarget.WORKER;
		case 'vehicles':
			return DocumentPresetTarget.VEHICLE;
		case 'machines':
			return DocumentPresetTarget.MACHINE;
		case 'tools':
			return DocumentPresetTarget.TOOL;
		case 'chemicals':
			return DocumentPresetTarget.CHEMICAL;
		case 'sites':
			return DocumentPresetTarget.SITE;
		default:
			return undefined;
	}
};
