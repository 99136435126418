import UseCase from '../../../../core/app/usecase';
import SiteWorkerRepository from '../../../repositories/siteWorkerRepository';
import { SiteWorker } from '../../../entities/siteWorker';

type GetSiteWorkerParameters = {
	companyId: string;
	siteId: string;
	workerId: string;
};

class GetSiteWorker implements UseCase<GetSiteWorkerParameters, SiteWorker | undefined> {
	constructor(private repository: SiteWorkerRepository) {}

	async Call({ companyId, siteId, workerId }: GetSiteWorkerParameters): Promise<SiteWorker | undefined> {
		return this.repository.getSiteWorker(companyId, siteId, workerId);
	}
}

export default GetSiteWorker;
