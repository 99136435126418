import { injectable } from 'tsyringe';
import SiteToolRepository, { GetSiteToolsFilters, GetWorkingSiteToolsFilters } from '../../domain/repositories/siteToolRepository';
import { mapSiteTool, SiteTool } from '../../domain/entities/siteTool';
import Evaluation from '../../domain/entities/evaluation';
import { SiteResourceDocument } from '../../domain/entities/document';
import { ResourceEvaluationState } from '../../domain/entities/resourceEvaluationState.enum';
import Requirement from '../../domain/entities/requirement';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import { GetSiteToolsResponse } from '../responses/siteTools';
import { GetEvaluationsResponse, mapEvaluation } from '../responses/evaluations';
import { ApiService } from '../utilities/apiService';
import { GetResourceEvaluationFilter } from '../../domain/repositories/siteRepository';
import { NewResource } from '../../presentation/hooks/Site/useSiteResourcesViewModel';
import { GetDocumentsFilter } from '../../domain/repositories/documentRepository';

@injectable()
class ServerSiteToolRepository implements SiteToolRepository {
	constructor(private apiService: ApiService) { }

	private getURL = (companyId: string, siteId: string, toolId?: string): string => {
		return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/tools${toolId ? `/${toolId}` : ''}`;
	};

	// Public.
	async getSiteTools(
		companyId: string,
		siteId: string,
		page: number,
		perPage: number,
		archived?: boolean,
		filter?: GetSiteToolsFilters,
		sort?: SortMeta,
		supplierId?: string
	): Promise<PaginatedResults<SiteTool>> {
		const { variantIds, specializationIds, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: perPage.toString(),
			...(supplierId !== undefined && supplierId !== null ? { companyId: supplierId } : {}),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...sort,
		});

		if (archived === true) {
			params.append('archived', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/tools?${params.toString()}`
		);
		const data: GetSiteToolsResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteTool(siteId)),
		};
	}

	// Public.
	async getWorkingSiteTools(
		companyId: string,
		siteId: string,
		page: number,
		perPage: number,
		archived?: boolean,
		filter?: GetWorkingSiteToolsFilters,
		sort?: SortMeta
	): Promise<PaginatedResults<SiteTool>> {
		const { variantIds, specializationIds, ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: page.toString(),
			perPage: perPage.toString(),
			...restFilter,
			...(variantIds ? { variantIds: variantIds.join(',') } : {}),
			...(specializationIds ? { specializationIds: specializationIds.join(',') } : {}),
			...sort,
		});

		if (archived === true) {
			params.append('archived', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${companyId}/tools?${params.toString()}`
		);
		if (!response.ok) {
			if (response.status === 403) {
				window.location.href = '/overview';
			}
			return;
		}

		const data: GetSiteToolsResponse = await response.json();
		return {
			...data,
			results: data.results.map(mapSiteTool(siteId)),
		};
	}

	// Public.
	async getSiteTool(companyId: string, siteId: string, toolId: string): Promise<SiteTool | undefined> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, toolId)}`);
		if (!response.ok) {
			return undefined;
		}
		return response.json();
	}

	async createSiteTool(
		companyId: string,
		siteId: string,
		resources: NewResource[],
		selectAll: boolean,
		selectedResourceNumber: number,
		selectAllVariants: string,
		selectAllSpecializations: string[],
		filterSiteResources?: Record<string, string | string[]>
	): Promise<boolean> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId)}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				resources,
				selectAll,
				selectedResourceNumber,
				selectAllVariant: selectAllVariants,
				selectAllSpecializations,
				filterSiteResources,
			}),
		});
		return Promise.resolve(true);
	}

	async deleteSiteTool(companyId: string, siteId: string, toolId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, toolId)}`, {
			method: 'DELETE',
		});
	}

	async restoreSiteTool(companyId: string, siteId: string, toolId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, toolId)}/restore`, {
			method: 'PUT',
		});
	}

	async updateSiteToolVariant(companyId: string, siteId: string, toolId: string, variantId?: string): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, toolId)}/variants`, {
			method: 'POST',
			body: variantId ? JSON.stringify({ variantId: variantId }) : null,
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	async updateSiteToolSpecializations(companyId: string, siteId: string, toolId: string, specializationIds: string[]): Promise<void> {
		await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, toolId)}/specializations`, {
			method: 'PUT',
			body: JSON.stringify({ specializationsIds: specializationIds }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	async getSiteToolEvaluations(
		companyId: string,
		siteId: string,
		toolId: string,
		pageParam: number,
		sort?: SortMeta,
		filter?: GetResourceEvaluationFilter
	): Promise<Evaluation<ResourceEvaluationState>[]> {
		const query = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...sort,
			...filter,
		}).toString();

		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, toolId)}/evaluations?${query}`);
		const { results }: GetEvaluationsResponse<ResourceEvaluationState> = await response.json();
		return results.map(mapEvaluation);
	}

	async evaluateSiteTool(
		companyId: string,
		siteId: string,
		toolId: string,
		value: ResourceEvaluationState
	): Promise<Evaluation<ResourceEvaluationState>> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, toolId)}/evaluations`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ value }),
		});
		return response.json();
	}

	async autoEvaluateSiteTool(companyId: string, siteId: string, toolId: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, toolId)}/evaluate`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw new Error(`Failed to auto evaluate site worker: ${response.status}`);
		}
	}

	async getSiteToolDocuments(
		companyId: string,
		siteId: string,
		toolId: string,
		filter: GetDocumentsFilter,
		sort: SortMeta,
		pageParam: number
	): Promise<SiteResourceDocument[]> {
		const { tags, expiresAt, ...restFilter } = filter ?? {};

		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...(tags ? { tags: tags.join(',') } : {}),
			...sort,
		});

		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, toolId)}/requirements?${params.toString()}`);
		const requirements = await response.json();
		return requirements['results'] ?? [];
	}

	async addRequirementToSiteTool(
		companyId: string,
		siteId: string,
		toolId: string,
		requirements: {
			documentTypeId: string;
			isOptional: boolean;
			graceDays: number;
		}[]
	): Promise<Requirement> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, toolId)}/requirements`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ requirements }),
		});
		return response.json();
	}

	async updateSiteToolGlobalStatus(companyId: string, siteId: string, toolId: string, isGlobal: boolean): Promise<boolean> {
		const response = await this.apiService.fetchWithToken(`${this.getURL(companyId, siteId, toolId)}/global`, {
			method: 'PUT',
			body: JSON.stringify({ isGlobal }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw new Error(`Failed to update site worker global status: ${response.status}`);
		}
		const { hasRequirements = true } = await response.json();
		return hasRequirements;
	}
}

export default ServerSiteToolRepository;
