import { VehicleDocumentsViewModel } from "../../../viewmodels/vehicles/VehicleDocumentsViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { useResourceDocumentsViewModel } from "../useResourceDocumentsViewModel";

const useVehicleDocumentsViewModel = (resourceId: string) =>
  useResourceDocumentsViewModel(
    new VehicleDocumentsViewModel(resourceId),
    DocumentTypeCategory.VEHICLE,
    resourceId,
  );

export default useVehicleDocumentsViewModel;
