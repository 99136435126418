import MainNavBar from "../../components/Common/MainNavBar";
import DashboardTopBar from "../../components/Views/Dashboard/DashboardTopBar";
import MainLayout from "../../layout/MainLayout";
import { useEffect } from "react";
import { useApp } from "../../../app";

const DashboardMainView = () => {
  const { updateContext } = useApp();

  useEffect(() => {
    updateContext({ topBarTitle: undefined });
  }, []);

  return <MainLayout header={<DashboardTopBar />} nav={<MainNavBar />} />;
};

export default DashboardMainView;
