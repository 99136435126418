import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import Specialization from '../../entities/specialization';
type ARGS = {
	companyId: string;
	supplierId: string;
	subject: RequirementSubject;
};
class GetSpecializationsForCompanySupplierRequirements implements UseCase<ARGS, Specialization[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, subject }: ARGS): Promise<Specialization[]> {
		return await this.supplierRepository.getSpecializationsForCompanySupplierRequirements(companyId, supplierId, subject);
	}
}

export default GetSpecializationsForCompanySupplierRequirements;
