import { useMemo } from "react";
import { useAuth } from "../../providers/Auth0JWTProvider";

import {
  DownloadFileScope,
  DownloadResourceFileScope,
} from "../../../domain/repositories/documentsDownloader";

import {
  RequestDocumentFilesViewModel,
  RequestDocumentsFilesViewModel,
  RequestResourceFilesViewModel,
  RequestSupplierOrSiteFilesViewModel,
} from "../../viewmodels/documents/RequestFilesDownloadViewModels";

export type UseDownloader = {
  initiateZipDownload: (
    documentId: string,
    renameModel: string,
    onComplete: (ok: boolean) => void,
  ) => void;
  getFileURL: (
    documentId: string,
    fileId: string,
    renameModel: string,
    onComplete: (url: string) => void,
    scope?: DownloadFileScope,
  ) => void;
};

export type UseDocumentsDownloader = {
  initiateDocumentsZipDownload: (onComplete: (ok: boolean) => void) => void;
};

export type UseExport = {
  initiateExport: (siteId: string, onComplete: (ok: boolean) => void) => void;
};

// FIXME: generalise this hook.
export const useDocumentFilesDownloader = () => {
  const { companyId } = useAuth();

  const vm = useMemo(
    () => new RequestDocumentFilesViewModel(companyId),
    [companyId],
  );

  const initiateZipDownload = async (
    documentId: string,
    renameModel: string,
    onComplete: (ok: boolean) => void,
  ) => {
    vm.initiateFilesArchiveDownload(documentId, renameModel)
      .then((ok) => onComplete(ok))
      .catch(() => onComplete(false));
  };

  const getFileURL = async (
    documentId: string,
    fileId: string,
    renameModel: string,
    onComplete: (url: string) => void,
  ) => {
    // For now, this call will never fail.
    vm.initiateFileDownload(documentId, fileId, renameModel).then(onComplete);
  };

  return { initiateZipDownload, getFileURL };
};

// FIXME: generalise this hook.
export const useSupplierOrSiteFilesDownloader = (
  targetId: string,
  scope: DownloadFileScope,
) => {
  const { companyId, siteId } = useAuth();

  const vm = useMemo(
    () => new RequestSupplierOrSiteFilesViewModel(companyId, siteId, scope),
    [companyId, siteId, scope],
  );

  const initiateZipDownload = async (
    requirementId: string,
    renameModel: string,
    onComplete: (ok: boolean) => void,
  ) => {
    vm.initiateFilesArchiveDownload(requirementId, renameModel)
      .then((ok) => onComplete(ok))
      .catch(() => onComplete(false));
  };

  const getFileURL = async (
    requirementId: string,
    fileId: string,
    renameModel: string,
    onComplete: (url: string) => void,
  ) => {
    // For now, this call will never fail.
    vm.initiateFileDownload(requirementId, fileId, renameModel).then(
      onComplete,
    );
  };

  return { initiateZipDownload, getFileURL };
};

// FIXME: generalise this hook.
export const useResourceFilesDownloader = (
  siteId: string,
  resourceId: string,
  scope: DownloadResourceFileScope,
) => {
  const { companyId } = useAuth();

  const vm = useMemo(
    () =>
      new RequestResourceFilesViewModel(companyId, siteId, resourceId, scope),
    [companyId, siteId, scope],
  );

  const initiateZipDownload = async (
    requirementId?: string,
    renameModel?: string,
    onComplete?: (ok: boolean) => void,
  ) => {
    vm.initiateFilesArchiveDownload(requirementId, renameModel)
      .then((ok) => onComplete(ok))
      .catch(() => onComplete(false));
  };

  const getFileURL = async (
    requirementId: string,
    fileId: string,
    renameModel: string,
    onComplete: (url: string) => void,
  ) => {
    // For now, this call will never fail.
    vm.initiateFileDownload(requirementId, fileId, renameModel).then(
      onComplete,
    );
  };

  return { initiateZipDownload, getFileURL };
};

export const useDocumentsFilesDownloader = (
  siteId: string,
  resourceId: string,
  scope: DownloadResourceFileScope | DownloadFileScope,
  renamModel?: string,
) => {
  const { companyId } = useAuth();

  const vm = useMemo(
    () =>
      new RequestDocumentsFilesViewModel(
        companyId,
        siteId,
        resourceId,
        scope,
        renamModel,
      ),
    [companyId, siteId, scope],
  );
  const initiateDocumentsZipDownload = async (
    onComplete?: (ok: boolean) => void,
  ) => {
    vm.initiateDocumentsFilesDownload()
      .then((ok) => onComplete(ok))
      .catch(() => onComplete(false));
  };

  return { initiateDocumentsZipDownload };
};
