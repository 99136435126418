import React, { useEffect, useState } from "react";
import {
  ChakraProvider,
  Box,
  Checkbox,
  CheckboxGroup,
  Stack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { transformToApiFormat } from "../../../../utils";

interface WeekdaysCheckboxGroupProps {
  handleChange: (value: { [key: string]: boolean }) => void;
  selectedDays: { [key: string]: boolean } | null;
}
export const WeekdaysCheckboxGroup = ({
  handleChange,
  selectedDays,
}: WeekdaysCheckboxGroupProps) => {
  const [selDays, setSelDays] = useState<string[]>([]);
  const { t } = useTranslation("sites");

  const daysOfWeek = [
    { label: t("options.monday"), value: "mon" },
    { label: t("options.tuesday"), value: "tue" },
    { label: t("options.wednesday"), value: "wed" },
    { label: t("options.thursday"), value: "thu" },
    { label: t("options.friday"), value: "fri" },
    { label: t("options.saturday"), value: "sat" },
    { label: t("options.sunday"), value: "sun" },
  ];

  useEffect(() => {
    if (selectedDays) {
      setSelDays(transformToStringArray(selectedDays));
    }
  }, [selectedDays]);

  const transformToStringArray = (
    selectedDays: { [key: string]: boolean } | null,
  ): string[] => {
    if (!selectedDays) {
      return [];
    }
    return Object.keys(selectedDays).filter((day) => selectedDays[day]);
  };

  const handleCheckboxChange = (selectedValues: string[]) => {
    setSelDays(selectedValues);
    const updatedSelectedDays = daysOfWeek.reduce(
      (acc, day) => {
        acc[day.value] = selectedValues.includes(day.value);
        return acc;
      },
      {} as { [key: string]: boolean },
    );

    handleChange(updatedSelectedDays);
  };

  return (
    <Box>
      <CheckboxGroup value={selDays} onChange={handleCheckboxChange}>
        <Stack direction="row">
          {daysOfWeek.map((day) => (
            <Checkbox mr={4} key={day.value} value={day.value}>
              {day.label}
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
    </Box>
  );
};
