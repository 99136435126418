import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./dragDropFile.css";
import { TbFileUpload } from "react-icons/tb";
import { Flex, Text } from "@chakra-ui/react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

// Props.
interface DragDropImageProps {
  onUploaded?: (uploadedFile: File) => void;
  externalDroppedImage?: File;
  setParentCropperReference?: (cropper: Cropper) => void;
}

/**
 * A functional component for handling drag and drop image uploads,
 * displaying a file input button, and integrating with a Cropper library.
 *
 * @param onUploaded - A callback function that is triggered when a file is uploaded.
 * @param externalDroppedImage - An optional file that is externally dropped in drop area.
 * @param setParentCropperReference - A callback function to set the parent cropper reference.
 * @returns A React functional component for drag and drop image uploads.
 */

const DragDropImageView: FC<DragDropImageProps> = ({
  onUploaded,
  externalDroppedImage,
  setParentCropperReference,
}) => {
  const { t } = useTranslation();
  const [dragActive, setDragActive] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [file, setFile] = useState<File>();
  const inputRef = useRef(null);
  // useEffect hook to handle externalDroppedImage
  useEffect(() => {
    if (externalDroppedImage) {
      setFile(externalDroppedImage);
      setFileLoaded(true);
      onUploaded?.(externalDroppedImage);
    }
    return () => {
      setFile(null);
      externalDroppedImage = null;
    };
  }, [externalDroppedImage]);

  // handle drag events
  const handleDrag = function (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
    type: string;
  }) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: React.DragEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e?.dataTransfer?.files[0]) {
      const formData = new FormData();
      formData.append("file", e.dataTransfer.files[0]);

      onUploaded(e.dataTransfer.files[0]);
      setFile(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e?.target?.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);

      setFile(e.target.files[0]);
      setFileLoaded(true);
      onUploaded(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={true}
        onChange={handleChange}
        accept="image/png, image/jpeg"
        disabled={file !== undefined}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        {!file && (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <TbFileUpload size="40" />
            {!file && (
              <button className="upload-button" onClick={onButtonClick}>
                {t("uploadFile", { ns: "common" })}
              </button>
            )}
            <Text>{file?.name}</Text>
            <Text paddingTop={4} fontSize={12}>
              {t("imageSizeInfo")}
            </Text>
            <Text paddingTop={4} fontSize={12}>
              {t("imageTypes")}
            </Text>
          </Flex>
        )}
        {file && (
          <Flex
            flexDirection="column"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Cropper
              style={{
                height: "100%",
                width: "80%",
                backgroundColor: "transparent",
                alignSelf: "center",
              }}
              color={"red"}
              preview=".img-preview"
              src={URL.createObjectURL(file)}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              modal={false}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              guides={false}
              onInitialized={(instance) => {
                setParentCropperReference(instance);
              }}
            />
          </Flex>
        )}
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
};

export default DragDropImageView;
