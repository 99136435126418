import UseCase from '../../../core/app/usecase';
import FileEntity from '../../entities/file';
import DocumentRepository from '../../repositories/documentRepository';
import { GetSitesFilter } from "../../repositories/siteRepository";

interface Args {
	companyId: string; // Maybe useless, kept for "consistency".

	documentId: string;
	updatedFiles: Partial<FileEntity>[];
	fileId: string;
	siteIds?: string[];
	target?: string;
	resourceId?: string;
	selectAll?: boolean;
	filters?: GetSitesFilter;
}

class UpdateFile implements UseCase<Args, void> {
	constructor(private repo: DocumentRepository) {}

	async Call({ companyId, documentId, updatedFiles, fileId, siteIds, target, resourceId, selectAll, filters }): Promise<void> {
		return this.repo.updateFile(companyId, documentId, fileId, updatedFiles, siteIds, target, resourceId, selectAll, filters);
	}
}

export default UpdateFile;
