import { IconType } from "react-icons";
import { NavLink } from "react-router-dom";
import RenderIf from "../Permissions/RenderIf";
import { Permission } from "../Permissions/Permissions";
import {
  Flex,
  FlexProps,
  Icon as ChakraIcon,
  Tooltip,
  forwardRef,
  IconProps,
  ComponentWithAs,
} from "@chakra-ui/react";
import { PermissionCheck } from "../../providers/Auth0JWTProvider";

const SubNavItem = forwardRef(
  ({ icon, Icon, path, name, permissions, ...rest }: SubNavItemProps, ref) => {
    const NavIcon = Icon ?? ChakraIcon;
    return (
      <RenderIf permissions={permissions ?? []} check={PermissionCheck.Some}>
        <NavLink to={path}>
          {({ isActive }) => (
            <Flex
              color={"black"}
              align="center"
              justifyContent="center"
              width="100%"
              cursor="pointer"
              className={isActive ? "active-link" : ""}
              sx={{
                "&.active-link": {
                  color: "black",
                  backgroundColor: "#32a2db",
                },
              }}
              {...rest}
            >
              {(icon || Icon) && (
                <Flex direction="column" align="center" justifyContent="center">
                  <Tooltip
                    label={name}
                    aria-label={name}
                    placement="auto-start"
                  >
                    <Flex ref={ref} align="center" height="64px">
                      <NavIcon
                        fontSize="32px"
                        _groupHover={{
                          color: "white",
                        }}
                        as={icon}
                      />
                    </Flex>
                  </Tooltip>
                </Flex>
              )}
            </Flex>
          )}
        </NavLink>
      </RenderIf>
    );
  },
);

export interface SubNavItemProps extends FlexProps {
  icon?: IconType;
  Icon?: ComponentWithAs<"svg", IconProps>;
  path: string;
  name?: string;
  permissions?: Permission[];
  hidden?: boolean;
}

export default SubNavItem;
