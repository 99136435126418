import User from '../../entities/user';
import UseCase from '../../../core/app/usecase';
import UsersRepository from '../../repositories/usersRepository';
import Role from '../../entities/role';

interface Args {
	companyId: string;
	name: string;
	email: string;
	role: Role;
}

class CreateUser implements UseCase<Args, User> {
	constructor(private repository: UsersRepository) {}

	async Call({ companyId, name, email, role }: Args): Promise<User> {
		return await this.repository.createUser(companyId, name, email, role.id);
	}
}

export default CreateUser;
