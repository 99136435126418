import UseCase from '../../../core/app/usecase';
import NotificationsRepository, { makeNotificationsRepository } from '../../repositories/notificationsRepository';

interface Args {
	companyId: string;
}

class NotificationCountUseCase implements UseCase<Args, number> {
	constructor(private repository: NotificationsRepository) {}

	async Call(args: Args): Promise<number> {
		const { companyId } = args;
		return await this.repository.getCount(companyId);
	}
}

export const makeNotificationCountUseCase = (): NotificationCountUseCase => {
	return new NotificationCountUseCase(makeNotificationsRepository());
};
