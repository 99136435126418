import { useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import Role, { RoleContext } from "../../../domain/entities/role";
import { RolesFilter } from "../../../domain/interactors/roles/getRoles";
import RolesListViewModel from "../../viewmodels/roles/RolesListViewModel";
import { SortDirection } from "../../../domain/entities/interfaces/paginatedResults";
import { RolesFilter as RolesNameFilter } from "../../screens/Settings/Roles/RolesView";

interface CreateArgs {
  name: string;
  context: RoleContext;
}
interface UpdateArgs {
  roleId: string;
  name: string;
}

const useRolesViewModel = (
  nameSort?: SortDirection,
  contextFilter?: RoleContext,
  filter?: RolesNameFilter,
) => {
  const { companyId, updateContext } = useAuth();
  const [updateError, setUpdateError] = useState(null);
  const [roleLinkedUsersParams, setRoleLinkedUsersParams] = useState<{
    roleId: string;
    context: string;
  }>();
  const viewModel = useMemo(() => new RolesListViewModel(companyId), []);

  const buildFilter = (): RolesFilter => {
    const out: RolesFilter = { contextFilter };
    if (filter) {
      out.nameFilter = filter.name;
    }
    if (nameSort === SortDirection.Descending) {
      out.nameDesc = true;
      out.nameAsc = false;
    } else if (nameSort === SortDirection.Ascending) {
      out.nameDesc = false;
      out.nameAsc = true;
    }
    return out;
  };

  const {
    data: roles,
    isFetching,
    error: fetchError,
    refetch,
  } = useQuery<Role[], Error>(
    ["get-roles", companyId, nameSort, contextFilter, filter],
    async () => (await viewModel.get(buildFilter())) ?? [],
    {
      initialData: [],
    },
  );

  const roleLinkedUsers = useQuery<number>(
    ["role-linked-users-count", roleLinkedUsersParams],
    async () => {
      return await viewModel.roleLinkedUsers(
        roleLinkedUsersParams?.roleId,
        roleLinkedUsersParams?.context,
      );
    },
    {
      enabled: !!roleLinkedUsersParams,
      retry: false,
      initialData: null,
    },
  );

  const reload = () => {
    refetch();
    setUpdateError(null);
  };

  const clearUpdateError = () => {
    setUpdateError(null);
  };

  const updateDidFail = (err: Error) => {
    setUpdateError(err.message);
  };

  const createMut = useMutation(
    ({ name, context }: CreateArgs) => viewModel.create(name, context),
    {
      onError: updateDidFail,
      onSuccess: reload,
    },
  );

  const updateMut = useMutation(
    ({ roleId, name }: UpdateArgs) => viewModel.update(roleId, name),
    {
      onError: updateDidFail,
      onSuccess: () => {
        reload();
        updateContext(companyId);
      },
    },
  );

  const deleteMut = useMutation(
    (params: { roleId: string; contextRole: string; newRoleId?: string }) =>
      viewModel.delete(params.roleId, params.contextRole, params.newRoleId),
    {
      onError: updateDidFail,
      onSuccess: () => {
        reload();
        updateContext(companyId);
      },
    },
  );

  const createRole = async (args: CreateArgs, onComplete?: () => void) => {
    createMut.mutateAsync(args).finally(onComplete);
  };

  const updateRole = async (args: UpdateArgs, onComplete?: () => void) => {
    updateMut.mutateAsync(args).finally(onComplete);
  };

  const deleteRole = async (
    params: { roleId: string; newRoleId: string; contextRole: string },
    onComplete?: () => void,
  ) => {
    deleteMut.mutateAsync(params).finally(onComplete);
  };
  const roleLinkedUsersResponse = roleLinkedUsers.data;

  return {
    roles,
    isLoading: isFetching,
    fetchError,
    updateError,
    clearUpdateError,
    createRole,
    updateRole,
    setRoleLinkedUsersParams,
    roleLinkedUsersResponse,
    roleLinkedUsersFetching: roleLinkedUsers.isFetching,
    deleteRole,
  };
};

export default useRolesViewModel;
