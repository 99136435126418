import Supplier from '../../entities/supplier';
import UseCase from '../../../core/app/usecase';
import SupplierRepository, { GetWorkingSiteFilter } from '../../repositories/supplierRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';

interface Args {
	companyId: string;
	filter?: GetWorkingSiteFilter;
	sort?: SortMeta;
}

class GetSupplierByCompany implements UseCase<Args, Supplier[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, filter, sort }: Args): Promise<Supplier[]> {
		return await this.supplierRepository.getSuppliersByCompany(companyId, filter, sort);
	}
}

export default GetSupplierByCompany;
