import { UserEmail } from "../../../domain/entities/userEmail";
import { useTranslation } from "react-i18next";
import BaseModal from "../../components/Common/alerts/BaseModal";
import { Box} from "@chakra-ui/react";
import InputAnimatedLabel from "../../components/Common/InputAnimatedLabel";
import { useState } from "react";
import { COLORS } from "../../assets/theme/colors";

type CreateUserEmailModalProps = {
  onClose: () => void;
  onConfirm: (email: UserEmail) => void;
  isLoading: boolean;
  errorMessage: string;
};

const CreateUserEmailModal = ({
                            onClose,
                            onConfirm,
                            isLoading,
                          }: CreateUserEmailModalProps) => {
  const { t } = useTranslation("userSettings");
  const [isValid, setIsValid] = useState<boolean>(true);
  const [email, setEmail] = useState<UserEmail>();
  const handleSave = () => {
    onConfirm(email);
    onClose();
  }
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(email.trim()))
  };

  return (
    <BaseModal
      title={t("addNewMail")}
      onClose={() => onClose()}
      size={"md"}
      isLoading={isLoading}
      closeOnOverlayClick={false}
      onConfirm={isValid && handleSave}
      onConfirmDisabled={!email}
    >
      <InputAnimatedLabel
        isRequired
        id="email"
        label={t("email")}
        handleOnChange={(fieldName,value)=> {
          validateEmail(value);
          setEmail({...email, email: value})
        }}
      />
      {!isValid && <Box color={COLORS.error} py={2}>{t('emailError')}</Box>}
    </BaseModal>
  );
};

export default CreateUserEmailModal;
