import UseCase from '../../../../core/app/usecase';
import DocumentTypeWithPublic from '../../../entities/documentTypeWithPublic';
import MachineRepository from '../../../repositories/machineRepository';

type CreatePropagableMachineDocumentParameters = {
	companyId: string;
	resourceId: string;
	documents: DocumentTypeWithPublic[];
};

/**
 * Returns the list of all the existing Document.
 */
class CreatePropagableMachineDocument implements UseCase<CreatePropagableMachineDocumentParameters, void> {
	constructor(private repository: MachineRepository) {}
	void;
	async Call({ companyId, resourceId, documents }: CreatePropagableMachineDocumentParameters): Promise<void> {
		return await this.repository.createPropagableMachineDocument(companyId, resourceId, documents);
	}
}

export default CreatePropagableMachineDocument;
