import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

interface ZStackProps extends BoxProps {
  children: React.ReactNode;
  spacing?: number;
}

const ZStack = ({ children, spacing = 0, ...rest }: ZStackProps) => {
  const childElements = React.Children.toArray(children);

  return (
    <Box position="relative" {...rest}>
      {childElements.map((child, index) => (
        <Box
          key={index}
          position={index === 0 ? "relative" : "absolute"}
          top={index * spacing}
          left={index * spacing}
        >
          {child}
        </Box>
      ))}
    </Box>
  );
};

export default ZStack;
