import Tool from '../../../entities/tool';
import UseCase from '../../../../core/app/usecase';
import ToolRepository, { GetToolsFilter } from '../../../repositories/toolRepository';
import { SortMeta } from '../../../entities/interfaces/paginatedResults';

type ARGS = {
	companyId: string;
	filter?: GetToolsFilter;
	sort?: SortMeta;
};

class GetAvailableToolsCount implements UseCase<ARGS, number> {
	constructor(private repository: ToolRepository) {}

	async Call({ companyId, filter, sort }: ARGS): Promise<number> {
		return await this.repository.getAvailableToolsCount(companyId, filter, sort);
	}
}

export default GetAvailableToolsCount;
