import { SiteResourcesViewModel } from '../../hooks/Site/useSiteResourcesViewModel';
import { makeGetWorkingSiteChemicals } from '../../../core/app/factory/siteChemicals/usecases';
import { SiteChemical, SiteChemicalParams } from '../../../domain/entities/siteChemical';
import SiteChemicalsViewModel from '../sites/SiteChemicalsViewModel';
import { GetWorkingSiteChemicalsFilters } from '../../../domain/repositories/siteChemicalRepository';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';

class WorkingSiteChemicalsViewModel extends SiteChemicalsViewModel implements SiteResourcesViewModel<SiteChemicalParams> {
	async getSiteResources(
		companyId: string,
		siteId: string,
		page: number,
		archived?: boolean,
		filter?: GetWorkingSiteChemicalsFilters,
		sort?: SortMeta,
	): Promise<PaginatedResults<SiteChemical>> {
		return await makeGetWorkingSiteChemicals().Call({ companyId, siteId, page, perPage: 25, filter, sort, archived });
	}
}

export default WorkingSiteChemicalsViewModel;
