import UseCase from '../../../../core/app/usecase';
import FileEntity from '../../../entities/file';
import ImportInfo from '../../../entities/importInfo';
import ChemicalRepository from '../../../repositories/chemicalRepository';

interface Args {
	companyId: string;
	file: FileEntity;
}

class UploadChemicals implements UseCase<Args, ImportInfo> {
	constructor(private repository: ChemicalRepository) {}

	async Call(args: Args): Promise<ImportInfo> {
		return await this.repository.uploadChemicals(args.companyId, args.file);
	}
}

export default UploadChemicals;
