import UseCase from '../../../core/app/usecase';
import Requirement from '../../entities/requirement';
import RequirementRepository from '../../repositories/requirementRepository';

type ARGS = { companyId: string; siteId: string; variantId: string; requirements: Requirement[] };

class UpdateRequirementsByVariantOrder implements UseCase<ARGS, void> {
	private requirementRepository: RequirementRepository;

	constructor(requirementRepository: RequirementRepository) {
		this.requirementRepository = requirementRepository;
	}

	async Call({ companyId, siteId, variantId, requirements }: ARGS): Promise<void> {
		return await this.requirementRepository.updateRequirementsByVariantOrder(companyId, siteId, variantId, requirements);
	}
}

export default UpdateRequirementsByVariantOrder;
