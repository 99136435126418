import UseCase from '../../../core/app/usecase';
import UsersRepository from '../../repositories/usersRepository';
import { makeUsersRepository } from "../../../core/app/factory/users/repositories";
import { UserEmail } from "../../entities/userEmail";

interface Args {
  companyId: string;
  userId: string;
  email: UserEmail;
}

class CreateUserEmail implements UseCase<Args, UserEmail> {
  constructor(private repository: UsersRepository) {}
  async Call({ companyId, userId, email}: Args): Promise<UserEmail> {
    return await this.repository.createUserEmail(companyId, userId, email);
  }
}
export const makeCreateUserEmail = (): CreateUserEmail => {
  return new CreateUserEmail(makeUsersRepository());
};