import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';
import { Evaluable } from '../../entities/evaluable.enum';
import PendingEvaluation from '../../entities/pending-evaluation';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { PendingEvaluationsFilters } from '../../../presentation/hooks/Site/useSiteApproveEvaluationsViewModel';
import FileEntity from '../../entities/file';

interface Args {
	companyId: string;
	documentId: string;
}

class GetPendingEvaluationFiles implements UseCase<Args,{results: FileEntity[], count: number}> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, documentId}: Args): Promise<{results: FileEntity[], count: number}> {
		return await this.siteRepository.getPendingEvaluationFiles(companyId, documentId);
	}
}

export default GetPendingEvaluationFiles;
