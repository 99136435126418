import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

const UsersIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 31 23" {...props}>
      <path d="M17.0603 10.181C17.9461 8.94326 18.4224 7.45933 18.4224 5.93725C18.4224 4.41517 17.9461 2.93124 17.0603 1.6935C17.8893 1.1276 18.871 0.827495 19.8748 0.833084C21.2285 0.833084 22.5268 1.37084 23.484 2.32806C24.4412 3.28528 24.979 4.58354 24.979 5.93725C24.979 7.29096 24.4412 8.58922 23.484 9.54644C22.5268 10.5037 21.2285 11.0414 19.8748 11.0414C18.871 11.047 17.8893 10.7469 17.0603 10.181ZM6.02067 5.93725C6.02067 4.92774 6.32002 3.9409 6.88088 3.10153C7.44173 2.26215 8.23889 1.60794 9.17156 1.22162C10.1042 0.835294 11.1305 0.734214 12.1206 0.93116C13.1107 1.12811 14.0202 1.61423 14.734 2.32806C15.4479 3.04189 15.934 3.95137 16.1309 4.94148C16.3279 5.93159 16.2268 6.95787 15.8405 7.89053C15.4542 8.8232 14.7999 9.62036 13.9606 10.1812C13.1212 10.7421 12.1343 11.0414 11.1248 11.0414C9.77113 11.0414 8.47286 10.5037 7.51565 9.54644C6.55843 8.58922 6.02067 7.29096 6.02067 5.93725ZM8.93734 5.93725C8.93734 6.3699 9.06563 6.79283 9.306 7.15256C9.54636 7.51229 9.888 7.79267 10.2877 7.95824C10.6874 8.1238 11.1273 8.16712 11.5516 8.08272C11.9759 7.99831 12.3657 7.78997 12.6716 7.48405C12.9776 7.17812 13.1859 6.78834 13.2703 6.36401C13.3547 5.93968 13.3114 5.49984 13.1458 5.10013C12.9803 4.70042 12.6999 4.35878 12.3401 4.11841C11.9804 3.87805 11.5575 3.74975 11.1248 3.74975C10.5447 3.74975 9.98828 3.98022 9.57804 4.39046C9.16781 4.80069 8.93734 5.35709 8.93734 5.93725ZM21.3332 19.7914V22.7081H0.916504V19.7914C0.916504 19.7914 0.916504 13.9581 11.1248 13.9581C21.3332 13.9581 21.3332 19.7914 21.3332 19.7914ZM18.4165 19.7914C18.2123 18.6539 16.4769 16.8748 11.1248 16.8748C5.77275 16.8748 3.93525 18.7852 3.83317 19.7914M21.2603 13.9581C22.1541 14.6533 22.8849 15.5358 23.4013 16.5435C23.9178 17.5513 24.2074 18.6598 24.2498 19.7914V22.7081H30.0832V19.7914C30.0832 19.7914 30.0832 14.4977 21.2457 13.9581H21.2603Z" />
    </Icon>
  );
};

export default UsersIcon;
