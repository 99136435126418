import UseCase from '../../../core/app/usecase';
import Permission from '../../entities/permission';
import UsersRepository from '../../repositories/usersRepository';

interface Args {
	companyId: string;
	userId: string;
}

class GetUserGeneralPermissions implements UseCase<Args, Permission[]> {
	constructor(private repository: UsersRepository) {}

	async Call({ companyId, userId }: Args): Promise<Permission[]> {
		return await this.repository.getGeneralPermissions(companyId, userId);
	}
}

export default GetUserGeneralPermissions;
