import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Center, Button, Text } from "@chakra-ui/react";

interface SignInHeaderProps {
  onClick?: () => void;
}

const SignInHeader: FC<SignInHeaderProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Center marginBottom={4}>
      <Text fontSize="xs" marginRight={2}>
        {t("ui.have an account?", { ns: "onboarding" })}
      </Text>
      <Button fontSize="xs" variant="link" color="#32A2DB" onClick={onClick}>
        {t("ui.sign in", { ns: "onboarding" })}
      </Button>
    </Center>
  );
};

export default SignInHeader;
