import { makeChemicalRepository } from './repositories';
import GetChemical from '../../../../domain/interactors/resources/chemicals/getChemical';
import GetChemicals from '../../../../domain/interactors/resources/chemicals/getChemicals';
import CreateChemical from '../../../../domain/interactors/resources/chemicals/createChemical';
import UpdateChemical from '../../../../domain/interactors/resources/chemicals/updateChemical';
import DeleteChemical from '../../../../domain/interactors/resources/chemicals/deleteChemical';
import GetChemicalDocuments from '../../../../domain/interactors/resources/chemicals/getChemicalDocuments';
import CreateChemicalDocument from '../../../../domain/interactors/resources/chemicals/createChemicalDocument';
import AddTypologyToChemical from '../../../../domain/interactors/resources/chemicals/addTypologyToChemical';
import RemoveTypologyFromChemical from '../../../../domain/interactors/resources/chemicals/removeTypologyFromChemical';
import UploadChemicals from '../../../../domain/interactors/resources/chemicals/uploadChemicals';
import CreatePropagableChemicalDocument from '../../../../domain/interactors/resources/chemicals/createPropagableChemicalDocument';
import EvaluateChemicalDocument from '../../../../domain/interactors/resources/chemicals/evaluateChemicalDocument';
import GetAvailableChemicalsCount from '../../../../domain/interactors/resources/chemicals/getAvailableChemicalsCount';
import RestoreChemical from '../../../../domain/interactors/resources/chemicals/restoreChemical';
import RepropagateEvaluationChemicalDocument from '../../../../domain/interactors/resources/chemicals/repropagateEvaluationChemicalDocument';

export const makeGetChemicals = () => {
	return new GetChemicals(makeChemicalRepository());
};

export const makeRestoreChemical = () => {
	return new RestoreChemical(makeChemicalRepository());
};

export const makeGetAvailableChemicalsCount = () => {
	return new GetAvailableChemicalsCount(makeChemicalRepository());
};

export const makeGetChemical = () => {
	return new GetChemical(makeChemicalRepository());
};

export const makeCreateChemical = () => {
	return new CreateChemical(makeChemicalRepository());
};
export const makeUpdateChemicals = () => {
	return new UploadChemicals(makeChemicalRepository());
};

export const makeUpdateChemical = () => {
	return new UpdateChemical(makeChemicalRepository());
};

export const makeDeleteChemical = () => {
	return new DeleteChemical(makeChemicalRepository());
};

export const makeGetChemicalDocuments = () => {
	return new GetChemicalDocuments(makeChemicalRepository());
};

export const makeCreateChemicalDocument = () => {
	return new CreateChemicalDocument(makeChemicalRepository());
};

export const makeCreatePropagableChemicalDocument = () => {
	return new CreatePropagableChemicalDocument(makeChemicalRepository());
};

export const makeAddTypologyToChemical = () => {
	return new AddTypologyToChemical(makeChemicalRepository());
};

export const makeRemoveTypologyFromChemical = () => {
	return new RemoveTypologyFromChemical(makeChemicalRepository());
};

export const makeEvaluateChemicalDocument = () => {
	return new EvaluateChemicalDocument(makeChemicalRepository());
};

export const makeRepropagateEvaluationChemicalDocument = () => {
	return new RepropagateEvaluationChemicalDocument(makeChemicalRepository());
}
