import SupplierRepository from '../../repositories/supplierRepository';
import UseCase from '../../../core/app/usecase';
import Evaluation from '../../entities/evaluation';
import { SupplierEvaluationState } from '../../entities/supplierEvaluationState.enum';
import { GetResourceEvaluationFilter } from '../../repositories/siteRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';

type Parameters = {
	companyId: string;
	siteId: string;
	supplierId: string;
	pageParam: number;
	sort?: SortMeta;
	filter?: GetResourceEvaluationFilter;
};

class GetSupplierEvaluations implements UseCase<Parameters, Evaluation<SupplierEvaluationState>[] | undefined> {
	constructor(private repository: SupplierRepository) {}

	async Call({ companyId, siteId, supplierId, pageParam, sort, filter }: Parameters): Promise<Evaluation<SupplierEvaluationState>[] | undefined> {
		return this.repository.getSupplierEvaluations(companyId, siteId, supplierId, pageParam, sort, filter);
	}
}

export default GetSupplierEvaluations;
