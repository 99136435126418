import UseCase from '../../../core/app/usecase';
import { BadgeReadersType } from '../../../presentation/hooks/Site/useSiteBadgeReadersViewModel';
import BadgeReader from '../../entities/badgeReader';
import { PaginatedMeta, PaginatedResults, SortMeta } from '../../entities/interfaces/paginatedResults';
import BadgeReaderRepository, { BadgeReadersFilters } from '../../repositories/badgeReaderRepository';

type Args = {
	siteId: string;
	companyId: string;
	type: BadgeReadersType;
} & PaginatedMeta & {
		filter?: BadgeReadersFilters;
		sort?: SortMeta;
	};

class GetBadgeReaders implements UseCase<Args, PaginatedResults<BadgeReader>> {
	constructor(private badgeReaderRepository: BadgeReaderRepository) {}

	async Call({ companyId, siteId, type, page, perPage, filter, sort }: Args): Promise<PaginatedResults<BadgeReader>> {
		return await this.badgeReaderRepository.getBadgeReaders(companyId, siteId, type, page, perPage, filter, sort);
	}
}

export default GetBadgeReaders;
