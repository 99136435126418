import { Flex, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PermissionBox from "./PermissionBox";

interface State {
  routeName?: string;
  permissions?: string[];
}

const LicenseNotActiveView = () => {
  const { t } = useTranslation("onboarding");

  return (
    <Flex
      flex={1}
      h="100%"
      w="100%"
      bg="white"
      padding={10}
      justifyContent="start"
      flexDirection="column"
    >
      <VStack spacing={2} borderRadius={10} padding={4} bg="red.100">
        <Text>{t("licenseDisabledMessage")}</Text>
      </VStack>
    </Flex>
  );
};

export default LicenseNotActiveView;
