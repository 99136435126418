import { Grid } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Staff, { StaffType } from "../../../../domain/entities/staff";
import BaseModal from "../../Common/alerts/BaseModal";
import InputControl from "../../Common/InputControl";
import TextInput from "../../Common/TextInput";

type CreateStaffModalProps = {
  onCancel: () => void;
  onConfirm: (staff: Staff) => void;
  staffToUpdate?: Staff;
  staffType?: StaffType;
  createUpdateStaffIsLoading: boolean;
  title?: string;
};
const CreateUpdateStaffModal = ({
  onCancel,
  onConfirm,
  staffToUpdate,
  staffType,
  createUpdateStaffIsLoading,
  title,
}: CreateStaffModalProps) => {
  const { t } = useTranslation("staff");
  const [name, setName] = useState<string>(
    staffToUpdate ? staffToUpdate.name : "",
  );
  const [surname, setSurname] = useState<string>(
    staffToUpdate ? staffToUpdate.surname : "",
  );
  const [phone, setPhone] = useState<string>(
    staffToUpdate ? staffToUpdate.phone : "",
  );
  const [email, setEmail] = useState<string>(
    staffToUpdate ? staffToUpdate.email : "",
  );
  const [role, setRole] = useState<string>(
    staffToUpdate ? staffToUpdate.role : "",
  );
  const [nameError, setNameError] = useState<string | null>(null);
  const [surnameError, setSurnameError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [roleError, setRoleError] = useState<string | null>(null);

  const handleChangeName = (value: string) => {
    setNameError(null);
    setName(value);
  };

  const handleChangeSurname = (value: string) => {
    setSurnameError(null);
    setSurname(value);
  };

  const handleChangePhone = (value: string) => {
    setPhoneError(null);
    setPhone(value);
  };

  const emailRegex = new RegExp(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  );

  const handleChangeEmail = (value: string) => {
    if (emailRegex.test(value)) {
      setEmailError(null);
    } else {
      setEmail(value);
      setEmailError(t("mailInvalid"));
      return;
    }
    setEmail(value);
  };

  const handleChangeStaffPosition = (value: string) => {
    setRoleError(null);
    setRole(value);
  };

  const handleCreate = async () => {
    let anyError = false;
    if (!name) {
      setNameError(t("errorName"));
      anyError = true;
    }
    if (!surname) {
      setSurnameError(t("errorSurname"));
      anyError = true;
    }

    if (!emailRegex.test(email)) {
      if (email) {
        setEmailError(t("mailInvalid"));
        anyError = true;
      }
    }
    if (!role) {
      setRoleError(t("errorStaffPosition"));
      anyError = true;
    }
    if (!anyError) {
      await onConfirm({
        id: staffToUpdate ? staffToUpdate.id : "",
        name,
        surname,
        phone,
        email,
        role,
        type: staffType,
      });
    }
  };
  return (
    <BaseModal
      title={title}
      onClose={onCancel}
      onConfirm={handleCreate}
      onCancel={onCancel}
      isLoading={createUpdateStaffIsLoading}
      disableOnClose={true}
    >
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <InputControl
          isRequired
          isInvalid={Boolean(nameError)}
          label={t("name")}
          error={nameError}
        >
          <TextInput
            value={name}
            onChange={handleChangeName}
            disableClear={true}
          />
        </InputControl>
        <InputControl
          isRequired
          isInvalid={Boolean(surnameError)}
          label={t("surname")}
          error={surnameError}
        >
          <TextInput
            value={surname}
            onChange={handleChangeSurname}
            disableClear={true}
          />
        </InputControl>
        <InputControl
          isInvalid={Boolean(phoneError)}
          label={t("phoneNumber")}
          error={phoneError}
        >
          <TextInput
            value={phone}
            onChange={handleChangePhone}
            disableClear={true}
          />
        </InputControl>
        <InputControl
          isInvalid={Boolean(emailError)}
          label={t("email")}
          error={emailError}
        >
          <TextInput
            value={email}
            onChange={handleChangeEmail}
            disableClear={true}
          />
        </InputControl>
        <InputControl
          isRequired
          isInvalid={Boolean(roleError)}
          label={t("staffPosition")}
          error={roleError}
        >
          <TextInput
            value={
              staffType === StaffType.SYSTEM
                ? t("systemStaffRole." + role, { ns: "enum" })
                : role
            }
            onChange={handleChangeStaffPosition}
            disableClear={true}
            readOnly={staffType === StaffType.SYSTEM}
          />
        </InputControl>
      </Grid>
    </BaseModal>
  );
};

export default CreateUpdateStaffModal;
