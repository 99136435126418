import { Image, Text, View } from "@react-pdf/renderer";
import { styles } from "./badgeStyles";
import React, {ReactNode} from "react";
import Badge from "../../../../domain/entities/badge";

type FrontBadgeContainerProps = {
  children: ReactNode | ReactNode[];
  badge: Badge;
  photo: string;
};
const FrontBadgeContainer = ({ children, badge, photo }: FrontBadgeContainerProps) => (
  <View style={styles.badgeContainer}>
    <View style={styles.siteName}>
      <Text>{badge.site.name}</Text>
    </View>
    <View style={styles.companyNameContainer}>
      <Text style={{ fontWeight: 700 }}>Datore di lavoro: </Text>
      <Text>{badge.resource.companyName}</Text>
    </View>
    <View style={styles.mainContainer}>
      <View style={styles.photoContainer}>
        <Image style={styles.photo} src={photo} />
      </View>
      <View style={styles.badgeDetailsContainer}>
        {children}
      </View>
    </View>
    <View style={styles.footerContainer}>
      <Text>{`Codice badge: ${badge.code}`}</Text>
    </View>
  </View>
);

export default FrontBadgeContainer;
