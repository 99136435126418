import UseCase from '../../../core/app/usecase';
import Evaluation from '../../entities/evaluation';
import SupplierRepository from '../../repositories/supplierRepository';
import { SupplierEvaluationState } from '../../entities/supplierEvaluationState.enum';

type Parameters = {
	companyId: string;
	siteId: string;
	supplierId: string;
	value: SupplierEvaluationState;
};

class EvaluateSupplier implements UseCase<Parameters, Evaluation<SupplierEvaluationState> | undefined> {
	constructor(private repository: SupplierRepository) {}

	async Call({ companyId, siteId, supplierId, value }: Parameters): Promise<Evaluation<SupplierEvaluationState> | undefined> {
		return this.repository.evaluateSupplier(companyId, siteId, supplierId, value);
	}
}

export default EvaluateSupplier;
