import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { SiteMachineParams } from '../../entities/siteMachine';
import { SupplierResource } from '../../entities/supplierResource';
type ARGS = {
	companyId: string;
	supplierId: string;
	resourceId: string;
};
class GetCompanySupplierMachine implements UseCase<ARGS, SupplierResource<SiteMachineParams>> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, resourceId }: ARGS): Promise<SupplierResource<SiteMachineParams>> {
		return await this.supplierRepository.getCompanySupplierMachine(companyId, supplierId, resourceId);
	}
}

export default GetCompanySupplierMachine;
