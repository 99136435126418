import { container } from 'tsyringe';

/**
 * Exposes all methods that can be used to download files or groups of files.
 */
export interface DocumentsDownloader {
	// Archives
	/**
	 * Initiates a download request for a all files of a document as a .zip archive.
	 * @param companyId the document owner identifier
	 * @param documentId the document identifier
	 */
	downloadDocumentZippedFiles(companyId: string, documentId: string, renameModel: string): Promise<boolean>;

	/**
	 * Initiates a download request for all files of a document,
	 * or "requirement", of a specific site and resource.
	 * @param companyId the document owner identifier
	 * @param siteId the site containing the document
	 * @param requirementId the document identifier
	 * @param resourceId the resource identifier (ex.: worker 1, vehicle 2, tool 3, etc)
	 * @param resourceScope the resource type (ex.: workers, vehicles, tools, etc.)
	 */
	downloadResourceDocumentZippedFiles(
		companyId: string,
		siteId: string,
		requirementId: string,
		resourceId: string,
		resourceScope: DownloadResourceFileScope,
		renameModel: string,
	): Promise<boolean>;

	/**
	 * Initiates a download request for all files of a document (or "requirement") linked to a site.
	 * @param companyId the document owner identifier
	 * @param siteId the site containing the document
	 * @param requirementId the document identifier
	 */
	downloadCompanyOrSiteDocumentZippedFiles(companyId: string, siteId: string, requirementId: string, renameModel: string): Promise<boolean>;

	// Single files
	/**
	 * Downloads a single file of a specific document.
	 * @param companyId the document owner identifier
	 * @param documentId the document identifier
	 * @param fileId the file identifier
	 */
	downloadDocumentFile(companyId: string, documentId: string, fileId: string, renameModel: string): Promise<string>;

	/**
	 * Downloads a single file of a specific document (or "requirement") linked to one site and resource.
	 * @param companyId the document owner identifier
	 * @param siteId the site containing the document
	 * @param requirementId the document identifier
	 * @param resourceId the resource identifier (ex.: worker 1, vehicle 2, tool 3, etc)
	 * @param resourceScope the resource type (ex.: workers, vehicles, tools, etc.)
	 * @param fileId the file identifier
	 */
	downloadResourceDocumentFile(
		companyId: string,
		siteId: string,
		requirementId: string,
		resourceId: string,
		resourceScope: DownloadResourceFileScope,
		fileId: string,
		renameModel: string,
	): Promise<string>;

	/**
	 * Downloads a single file of a document (or "requirement") linked to a site.
	 * @param companyId the document owner identifier
	 * @param siteId the document site owner identifier
	 * @param requirementId the document identifier
	 * @param fileId the file identifier
	 * @param renameModel
	 * @param scope
	 */
	downloadCompanyOrSiteDocumentFile(
		companyId: string,
		siteId: string,
		requirementId: string,
		fileId: string,
		renameModel: string,
		scope: DownloadFileScope,
	): Promise<string>;

	/**
	 * Downloads a single file of a specific document (or "requirement") linked to one site and resource.
	 * @param companyId the document owner identifier
	 * @param siteId the site containing the document
	 * @param resourceId the resource identifier (ex.: worker 1, vehicle 2, tool 3, etc)
	 * @param resourceScope the resource type (ex.: workers, vehicles, tools, etc.)
	 * @param renameModel
	 */
	downloadDocumentsFilesZipped(
		companyId: string,
		siteId: string,
		resourceId: string,
		resourceScope: DownloadResourceFileScope | DownloadFileScope,
		renameModel: string,
	): Promise<boolean>;
}

/**
 * Defines the resource for which the user wants to download single files or a zipped group of files.
 */
export type DownloadResourceFileScope = 'workers' | 'vehicles' | 'machines' | 'tools' | 'chemicals';

/**
 * Defines the main scope for which the user wants to download single files or a zipped group of files.
 */
export type DownloadFileScope = 'site' | 'company';

/**
 * Builds an actual instance of DocumentsDownloader.
 * @returns the concrete implementation of DocumentsDownloader
 */
export const makeDocumentsDownloaderRepository = (): DocumentsDownloader => {
	return container.resolve('DocumentsDownloader');
};
