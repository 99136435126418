import UseCase from '../../../../core/app/usecase';
import MachineRepository from '../../../repositories/machineRepository';

type MigrateToVehicleParameters = {
	companyId: string;
	machineId: string;
	typologyId: string;
};

class MigrateToVehicle implements UseCase<MigrateToVehicleParameters, string> {
	constructor(private repository: MachineRepository) {}

	async Call({ companyId, machineId, typologyId }: MigrateToVehicleParameters): Promise<string> {
		return await this.repository.migrateToVehicle(companyId, machineId, typologyId);
	}
}

export default MigrateToVehicle;
