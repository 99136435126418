import UseCase from '../../../core/app/usecase';
import DocumentType from '../../entities/documentType';
import DocumentsPresetsRepository from '../../repositories/documentsPresetsRepository';
import DocumentPreset from '../../entities/documentPreset';
import { DocumentPresetTarget } from '../../entities/documentPresetTarget.enum';

type ARGS = { companyId: string; documentType: DocumentType; target: DocumentPresetTarget };

class CreateDocumentPreset implements UseCase<ARGS, DocumentPreset> {
	constructor(private repository: DocumentsPresetsRepository) {}

	async Call(arg: ARGS): Promise<DocumentPreset> {
		return await this.repository.createDocumentPreset(arg.companyId, arg.documentType, arg.target);
	}
}

export default CreateDocumentPreset;
