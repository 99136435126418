import UseCase from '../../../../core/app/usecase';
import SiteMachineRepository from '../../../repositories/siteMachineRepository';
import { SiteResourceDocument } from '../../../entities/document';
import { SortMeta } from '../../../entities/interfaces/paginatedResults';
import { GetWorkingSiteDocumentsFilter } from '../../../repositories/filters';

type Parameters = {
	companyId: string;
	siteId: string;
	machineId: string;
	filter: GetWorkingSiteDocumentsFilter;
	sort?: SortMeta;
	pageParam: number;
};

class GetSiteMachineDocuments implements UseCase<Parameters, SiteResourceDocument[] | undefined> {
	constructor(private repository: SiteMachineRepository) {}

	async Call({ companyId, siteId, machineId, filter, sort, pageParam }: Parameters): Promise<SiteResourceDocument[] | undefined> {
		return this.repository.getSiteMachineDocuments(companyId, siteId, machineId, filter, sort, pageParam);
	}
}

export default GetSiteMachineDocuments;
