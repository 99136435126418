import {RegisterOptions, useFormContext} from "react-hook-form";
import Tag from "../../../../../domain/entities/tag";
import BaseTagSelect, {BaseTagSelectProps} from "../BaseTagSelect";

type HookTagFieldProps<IsMulti extends boolean> = Omit<BaseTagSelectProps<IsMulti>, "error"> & {
  name: string;
  rules?: RegisterOptions;
}

const HookTagField = ({name, rules, ...baseProps}: HookTagFieldProps<typeof baseProps.value extends Tag[] ? true : false>) => {
  const {
    setValue, watch, register,
    formState: { errors }
  } = useFormContext();
  register(name, rules);
  const selectedTag = watch(name) as Tag;
  return (
    <BaseTagSelect
      {...baseProps}
      error={errors[name]?.message as string}
      selectProps={{
        value: selectedTag,
        onChange: (tag: Tag) => setValue(name, tag),
        ...baseProps.selectProps
      }}
    />
  )
}

export default HookTagField;