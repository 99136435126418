import UseCase from '../../../core/app/usecase';
import Requirement from '../../entities/requirement';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import RequirementRepository from '../../repositories/requirementRepository';

type ARGS = { companyId: string; variantId: string; requirementGroupId: string; requirements: Requirement[] };

class UpdateRequirementsGroupByVariantOrder implements UseCase<ARGS, void> {
	private requirementRepository: RequirementRepository;

	constructor(requirementRepository: RequirementRepository) {
		this.requirementRepository = requirementRepository;
	}

	async Call({ companyId, variantId, requirementGroupId, requirements }: ARGS): Promise<void> {
		return await this.requirementRepository.updateRequirementsGroupByVariantOrder(companyId, variantId, requirementGroupId, requirements);
	}
}

export default UpdateRequirementsGroupByVariantOrder;
