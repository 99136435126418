import {
  makeCreateDocumentType,
  makeDeleteDocumentType,
  makeGetDocumentTypes,
  makeGetRenameModels,
  makeLinkTagToDocumentType,
  makeUnlinkTagFromDocumentType,
  makeUpdateDocumentType,
  makeUpsertRenameModel,
} from "../../../core/app/factory/documentTypes/usecases";
import {
  makeCreateTag,
  makeGetTags,
  makeUpdateTag,
} from "../../../core/app/factory/tags/usecases";
import DocumentType, {
  RenameModel,
} from "../../../domain/entities/documentType";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import Tag from "../../../domain/entities/tag";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetDocumentTypeFilters } from "../../../domain/repositories/documentTypeRepository";

export class DocumentTypesListViewModel {
  private getDocumentTypesUseCase = makeGetDocumentTypes();
  private deleteDocumentTypeUseCase = makeDeleteDocumentType();
  private createDocumentTypeUseCase = makeCreateDocumentType();
  private updateDocumentTypeUseCase = makeUpdateDocumentType();
  private getTagsUseCase = makeGetTags();
  private getRenameModelsUseCase = makeGetRenameModels();
  private linkTagToDocumentTypeUseCase = makeLinkTagToDocumentType();
  private unlinkTagFromDocumentTypeUseCase = makeUnlinkTagFromDocumentType();
  private createTagUseCase = makeCreateTag();
  private makeUpdateTagUseCase = makeUpdateTag();
  private upsertRenameModelUseCase = makeUpsertRenameModel();

  async list(
    companyId: string,
    docTypeFilters?: GetDocumentTypeFilters,
    sort?: SortMeta,
    systemOnly?: boolean,
    filter?: DocumentTypeCategory,
    pageParam?: number,
  ): Promise<DocumentType[]> {
    return await this.getDocumentTypesUseCase.Call({
      companyId,
      docTypeFilters,
      sort,
      systemOnly,
      filter,
      pageParam,
    });
  }
  async delete(companyId: string, typeId: string): Promise<void> {
    return await this.deleteDocumentTypeUseCase.Call({ companyId, typeId });
  }

  async create(
    companyId: string,
    resource: DocumentTypeCategory,
    type: DocumentType,
  ): Promise<DocumentType> {
    return await this.createDocumentTypeUseCase.Call({
      companyId,
      resource,
      type,
    });
  }
  z;
  async update(companyId: string, type: DocumentType): Promise<DocumentType> {
    return await this.updateDocumentTypeUseCase.Call({ companyId, type });
  }

  async getTags(companyId: string, search?: string): Promise<Tag[]> {
    return await this.getTagsUseCase.Call({ companyId, search });
  }

  async createTag(companyId: string, tag: Tag): Promise<Tag> {
    return await this.createTagUseCase.Call({ companyId, tag });
  }
  async updateTag(companyId: string, tag: Tag): Promise<void> {
    return await this.makeUpdateTagUseCase.Call({ companyId, tag });
  }

  async linkTagToDocumentType(
    companyId: string,
    documentTypeId: string,
    tagId: string,
  ): Promise<void> {
    return await this.linkTagToDocumentTypeUseCase.Call({
      companyId,
      documentTypeId,
      tagId,
    });
  }

  async unlinkTagFromDocumentType(
    companyId: string,
    documentTypeId: string,
    tagId: string,
  ): Promise<void> {
    return await this.unlinkTagFromDocumentTypeUseCase.Call({
      companyId,
      documentTypeId,
      tagId,
    });
  }
  async getRenameModel(
    companyId: string,
    documentTypeId?: string,
  ): Promise<RenameModel> {
    return await this.getRenameModelsUseCase.Call({
      companyId,
      documentTypeId,
    });
  }

  async upsertRenameModel(
    companyId: string,
    documentTypeId: string,
    renameModel: RenameModel,
  ): Promise<void> {
    return await this.upsertRenameModelUseCase.Call({
      companyId,
      documentTypeId,
      renameModel,
    });
  }
}
