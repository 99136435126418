import { useParams } from "react-router-dom";
import SupplierResourceView from "./SupplierResourceView";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SupplierToolViewModel from "../../viewmodels/suppliers/SupplierToolViewModel";
import { SupplierDocumentsProps } from "../../components/Views/common/SupplierDocumentsTable";

const SupplierToolDetailView = ({
  permissions,
}: {
  permissions: SupplierDocumentsProps["permissions"];
}) => {
  const { companyId } = useAuth();
  const { supplierId, resourceId } = useParams();

  const resourceColumns = ["name", "serial"];

  return (
    <SupplierResourceView
      viewModel={new SupplierToolViewModel(companyId, supplierId, resourceId)}
      columns={resourceColumns}
      permissions={permissions}
    />
  );
};
export default SupplierToolDetailView;
