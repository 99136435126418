import { Icon } from "@chakra-ui/react";

const CircleIcon = ({ size, color }: CircleIconProps) => {
  return (
    <Icon viewBox="0 0 200 200" boxSize={size} color={color}>
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </Icon>
  );
};

interface CircleIconProps {
  size: number;
  color: string;
}

export default CircleIcon;
