import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import Supplier from '../../entities/supplier';

type ARGS = {
	companyId: string;
	supplierId: string;
};
class GetCompanySupplier implements UseCase<ARGS, Supplier> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId }: ARGS): Promise<Supplier> {
		return await this.supplierRepository.getCompanySupplier(companyId, supplierId);
	}
}

export default GetCompanySupplier;
