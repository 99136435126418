import UseCase from '../../../core/app/usecase';
import { Notification } from '../../entities/notification';
import { PaginatedResults } from '../../entities/interfaces/paginatedResults';
import NotificationsRepository, { makeNotificationsRepository } from '../../repositories/notificationsRepository';

export type ListNotificationsStatusFilter = 'archived' | 'new';

interface Args {
	companyId: string;
	status: ListNotificationsStatusFilter;

	// Pagination.
	page: number;
	perPage: number;
}

/**
 * Obtains all unread (new) or archived notifications for the given context identifier.
 *
 * Notifications owner (that is, the calling user) is obtained
 * from the authentication token sent as part of request's header.
 */
class ListNotificationsUseCase implements UseCase<Args, PaginatedResults<Notification>> {
	constructor(private repository: NotificationsRepository) {}

	async Call(args: Args): Promise<PaginatedResults<Notification>> {
		const { companyId, status, page, perPage } = args;

		switch (status) {
			case 'archived':
				return await this.repository.listArchived(companyId, page, perPage);
			case 'new':
				return await this.repository.listNew(companyId, page, perPage);
		}
	}
}

export const makeListNotificationsUseCase = (): ListNotificationsUseCase => {
	return new ListNotificationsUseCase(makeNotificationsRepository());
};
