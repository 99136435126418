import UseCase from '../../../core/app/usecase';
import DocumentNote from '../../entities/documentNote';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import DocumentRepository from '../../repositories/documentRepository';

interface Args {
	companyId: string;
	siteId?: string;
	resourceId: string;
	resourceType: DocumentTypeCategory;
	note: string;
}

class CreateResourceDocumentNote implements UseCase<Args, void> {
	constructor(private repository: DocumentRepository) {}

	async Call({ companyId, siteId, resourceId, resourceType, note }: Args): Promise<void> {
		return await this.repository.createResourceDocumentNote(companyId, resourceId, resourceType, note, siteId);
	}
}

export default CreateResourceDocumentNote;
