import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SiteMachineParams } from "../../../domain/entities/siteMachine";
import SupplierResourcesListView from "../../components/Views/Suppliers/SupplierResourcesListView";
import useSupplierResourcesViewModel from "../../hooks/Suppliers/useSupplierResourcesViewModel";
import SupplierMachinesViewModel from "../../viewmodels/suppliers/SupplierMachinesViewModel";

const SupplierMachinesView = () => {
  const resourceColumns = ["name", "plate", "model"];
  return (
    <SupplierResourcesListView<SiteMachineParams>
      type={DocumentTypeCategory.MACHINE}
      useViewModel={useSupplierResourcesViewModel(
        new SupplierMachinesViewModel(),
        DocumentTypeCategory.MACHINE,
      )}
      resourceColumns={resourceColumns}
      namespace={"machines"}
    />
  );
};

export default SupplierMachinesView;
