import UseCase from '../../../../core/app/usecase';
import { ResourceDocumentEvaluationState } from '../../../entities/resourceDocumentEvaluationState.enum';
import ToolRepository from '../../../repositories/toolRepository';
import { GetSitesFilter } from "../../../repositories/siteRepository";

type EvaluateToolDocumentParameters = {
	companyId: string;
	resourceId: string;
	documentId: string;
	evaluationState?: ResourceDocumentEvaluationState;
	expirationDate?: Date;
	noEvaluationExpiration?: boolean;
	siteIds: string[];
	selectAll?: boolean;
	filters?: GetSitesFilter
};

/**
 * Returns the list of all the existing Document.
 */
class EvaluateToolDocument implements UseCase<EvaluateToolDocumentParameters, void> {
	constructor(private repository: ToolRepository) {}

	async Call({
		companyId,
		resourceId,
		documentId,
		evaluationState,
		expirationDate,
		noEvaluationExpiration,
		siteIds,
		selectAll,
		filters
	}: EvaluateToolDocumentParameters): Promise<void> {
		return await this.repository.evaluateToolDocument(
			companyId,
			resourceId,
			documentId,
			evaluationState,
			expirationDate,
			noEvaluationExpiration,
			siteIds,
			selectAll,
			filters
		);
	}
}

export default EvaluateToolDocument;
