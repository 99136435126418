import UseCase from "../../../core/app/usecase";
import BadgeReaderRepository, { VirtualBadgeReaderUsersFilters } from "../../repositories/badgeReaderRepository";

type Args = {
    siteId: string;
    companyId: string;
    badgeReaderId: string
    siteIds: string[]
  }
  
  class LinkBadgeReaderToSites implements UseCase<Args, void> {
    constructor(private badgeReaderRepository: BadgeReaderRepository) { }
  
    async Call({ companyId, siteId, badgeReaderId, siteIds}: Args): Promise<void> {
      return await this.badgeReaderRepository.linkBadgeReaderToSites(companyId, siteId, badgeReaderId, siteIds);
    };
  }
  
  export default LinkBadgeReaderToSites;