import Machine from '../../../entities/machine';
import UseCase from '../../../../core/app/usecase';
import MachineRepository from '../../../repositories/machineRepository';
import UpdateMachineParameter from '../../../../presentation/hooks/Resources/Machines/updateMachineParameter';

type ARGS = {
	companyId: string;
	machine: Machine;
	imageFile: File;
};

class UpdateMachine implements UseCase<ARGS, Machine> {
	constructor(private repository: MachineRepository) {}

	async Call(parameters: ARGS): Promise<Machine> {
		return await this.repository.updateMachine(parameters.companyId, parameters.machine, parameters.imageFile);
	}
}

export default UpdateMachine;
