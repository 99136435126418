import { Flex, Text } from "@chakra-ui/react";

const OnboardingBackgroundText = () => {
  return (
    <Flex direction="column" height="100vh" marginLeft={8}>
      <Flex flexGrow={1} />
      <Text fontSize="xl">Con noi puoi stare</Text>
      <Text fontSize="xl" textColor="#32A2DB" fontWeight="bold">
        Sikuro
      </Text>
      <Flex flexGrow={1} />
    </Flex>
  );
};

export default OnboardingBackgroundText;
