import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';
import SiteOptions from '../../entities/siteOptions';

interface Args {
	companyId: string;
	siteId: string;
}

class GetSiteOptions implements UseCase<Args, SiteOptions> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, siteId }: Args): Promise<SiteOptions> {
		return await this.siteRepository.getSiteOptions(companyId, siteId);
	}
}

export default GetSiteOptions;
