import UseCase from '../../../../core/app/usecase';
import VehicleRepository from '../../../repositories/vehicleRepository';

type CreateVehicleDocumentParameters = {
	companyId: string;
	resourceId: string;
	documents: string[];
};

/**
 * Returns the list of all the existing Document.
 */
class CreateVehicleDocument implements UseCase<CreateVehicleDocumentParameters, void> {
	constructor(private repository: VehicleRepository) {}

	async Call({ companyId, resourceId, documents }: CreateVehicleDocumentParameters): Promise<void> {
		return await this.repository.createVehicleDocument(companyId, resourceId, documents);
	}
}

export default CreateVehicleDocument;
