import { makeActivationRepository } from './repositories';
import ActivateSupplier from '../../../../domain/interactors/activation/activateSupplier';
import ActivateUser from '../../../../domain/interactors/activation/activateUser';
import ActivateSubcontractor from '../../../../domain/interactors/activation/activateSubcontractor';
import ConfirmSubcontractor from '../../../../domain/interactors/activation/confirmSubcontractor';
import ConfirmUserEmail from "../../../../domain/interactors/activation/confirmUserEmail";

export const makeActivateUser = () => {
	return new ActivateUser(makeActivationRepository());
};
export const makeActivateSupplier = () => {
	return new ActivateSupplier(makeActivationRepository());
};
export const makeActivateSubcontractor = () => {
	return new ActivateSubcontractor(makeActivationRepository());
};
export const makeConfirmSubcontractor = () => {
	return new ConfirmSubcontractor(makeActivationRepository());
};
export const makeConfirmUserEmail = () => {
	return new ConfirmUserEmail(makeActivationRepository());
};