import UseCase from '../../../../core/app/usecase';
import WorkerRepository from '../../../repositories/workerRepository';
import MachineRepository from '../../../repositories/machineRepository';
import VehicleRepository from '../../../repositories/vehicleRepository';
import { BadgeResourceType } from '../../../entities/badgeResourceType.enum';
import SupplierRepository from '../../../repositories/supplierRepository';
import { RequirementSubject } from '../../../entities/requirementSubject.enum';

type ARGS = {
	companyId: string;
	resourceType: BadgeResourceType | RequirementSubject;
	resourceId: string;
	badgeIds: string[];
	supplierId?: string;
};

class LinkBadgesResource implements UseCase<ARGS, void> {
	constructor(private repository: WorkerRepository | MachineRepository | VehicleRepository | SupplierRepository) {}

	async Call({ companyId, resourceType, resourceId, badgeIds, supplierId }: ARGS): Promise<void> {
		return this.repository.linkBadgesResource(companyId, resourceType, resourceId, badgeIds, supplierId);
	}
}

export default LinkBadgesResource;
