import Chemical from '../../../entities/chemical';
import UseCase from '../../../../core/app/usecase';
import ChemicalRepository from '../../../repositories/chemicalRepository';
import CreateChemicalParameters from './createChemicalParameter';

class CreateChemical implements UseCase<CreateChemicalParameters, Chemical> {
	constructor(private repository: ChemicalRepository) {}

	async Call({ companyId, chemical, photo }: CreateChemicalParameters): Promise<Chemical> {
		return await this.repository.createChemical(companyId, chemical, photo);
	}
}

export default CreateChemical;
