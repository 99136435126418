import UseCase from '../../../core/app/usecase';
import StaffRepository from '../../repositories/staffRepository';

interface Args {
	companyId: string;
	staffId: string;
	siteId?: string;
}

class DeleteStaffElement implements UseCase<Args, void> {
	private staffRepository: StaffRepository;

	constructor(staffRepository: StaffRepository) {
		this.staffRepository = staffRepository;
	}

	async Call({ companyId, staffId, siteId }: Args): Promise<void> {
		return await this.staffRepository.deleteStaffElement(companyId, staffId, siteId);
	}
}

export default DeleteStaffElement;
