import UseCase from '../../../core/app/usecase';
import Requirement from '../../entities/requirement';
import RequirementRepository from '../../repositories/requirementRepository';
import { RequirementSubject } from '../../entities/requirementSubject.enum';

interface ARGS {
	companyId: string;
	siteId: string;
	requirementSubject: RequirementSubject;
}
class GetSiteRequirementsByResource implements UseCase<ARGS, Requirement[]> {
	private requirementRepository: RequirementRepository;

	constructor(requirementRepository: RequirementRepository) {
		this.requirementRepository = requirementRepository;
	}

	async Call(parameter: ARGS): Promise<Requirement[]> {
		return await this.requirementRepository.getSiteRequirementsByResource(parameter.companyId, parameter.siteId, parameter.requirementSubject);
	}
}

export default GetSiteRequirementsByResource;
