import UseCase from '../../../core/app/usecase';
import BadgeRepository from '../../repositories/badgeRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	badgeIds: string[];
	selectAll: boolean;
};

class UnlinkBadgeFromSite implements UseCase<ARGS, void> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, siteId, badgeIds, selectAll }: ARGS): Promise<void> {
		return await this.badgeRepository.unlinkBadgeFromSite(companyId, siteId, badgeIds, selectAll);
	}
}

export default UnlinkBadgeFromSite;
