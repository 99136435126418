import { MetaObject } from './interfaces/metaObject';
import { Dictionary } from 'tsyringe/dist/typings/types';
import { BadgeResource } from './interfaces/badgeResource';
import { BadgeResourceType } from './badgeResourceType.enum';
import Typology from './typology';

export enum WorkerStatus {
	ACTIVE = 'active',
	BLOCKED = 'blocked',
}

class Worker implements BadgeResource, MetaObject {
	id: string;
	propertyNames: string[];
	meta: Dictionary<string>;
	badgeResource: BadgeResourceType;

	fiscalCodeCountryCode?: string;
	fiscalCode: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;

	dateOfBirth: Date;
	placeOfBirth: string;

	dateOfEmployment: Date;
	jobTitle: string;

	typology: Typology;

	fullName: string;
	photo?: string;

	status: WorkerStatus;
	updatedAt?: string;

	constructor(
		firstName: string,
		lastName: string,
		fiscalCode: string,
		email: string,
		phone: string,
		photo: string,
		dateOfBirth: Date,
		placeOfBirth: string,
		dateOfEmployment: Date,
		status: WorkerStatus,
		meta?: Dictionary<string>,
		propertyNames?: Array<string>,
	) {
		this.lastName = lastName;
		this.firstName = firstName;
		this.fiscalCode = fiscalCode;
		this.fullName = `${firstName} ${lastName}`;

		this.email = email;
		this.phone = phone;
		this.photo = photo;
		this.dateOfBirth = dateOfBirth;
		this.placeOfBirth = placeOfBirth;
		this.dateOfEmployment = dateOfEmployment;
		this.status = status;

		this.badgeResource = BadgeResourceType.WORKER;

		this.meta = meta ?? {};
		this.propertyNames = propertyNames ?? [];
	}
}

export default Worker;
