import Chemical from '../../../entities/chemical';
import UseCase from '../../../../core/app/usecase';
import ChemicalRepository from '../../../repositories/chemicalRepository';
import GetChemicalParameters from './getChemicalParameter';

class GetChemical implements UseCase<GetChemicalParameters, Chemical | undefined> {
	constructor(private repository: ChemicalRepository) {}

	async Call({ companyId, chemicalId }: GetChemicalParameters): Promise<Chemical | undefined> {
		return this.repository.getChemicalById(companyId, chemicalId);
	}
}

export default GetChemical;
