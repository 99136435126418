import { FormErrorMessage } from "@chakra-ui/react";
import { FC } from "react";

interface Props {
  messages?: string | Array<string>;
}

const ErrorMessages: FC<Props> = ({ messages }) => {
  if (!messages) {
    return <></>;
  }

  if (Array.isArray(messages)) {
    return (
      <FormErrorMessage>
        <ul>
          {messages.map((message, key) => (
            <li key={key}>{message}</li>
          ))}
        </ul>
      </FormErrorMessage>
    );
  }

  return <FormErrorMessage>{messages}</FormErrorMessage>;
};

export default ErrorMessages;
