import UseCase from '../../../core/app/usecase';
import DocumentType from '../../entities/documentType';
import DocumentTypeRepository from '../../repositories/documentTypeRepository';
import UpdateDocumentTypeParameters from './updateDocumentTypeParameter';

/**
 * Update the given DocumentType
 *
 * @param documentType the object to update
 */
class UpdateDocumentType implements UseCase<UpdateDocumentTypeParameters, DocumentType> {
	constructor(private repository: DocumentTypeRepository) {}

	async Call({ companyId, type }: UpdateDocumentTypeParameters): Promise<DocumentType> {
		return await this.repository.updateDocumentType(companyId, type);
	}
}

export default UpdateDocumentType;
