import UseCase from '../../../core/app/usecase';
import Badge from '../../entities/badge';
import BadgeRepository, { GetBadgesFilters, GetBadgesQuery } from '../../repositories/badgeRepository';
import { PaginatedMeta, PaginatedResults, SortMeta } from '../../entities/interfaces/paginatedResults';

type ARGS = {
	companyId: string;
	siteId?: string;
	query?: GetBadgesQuery;
	filter?: GetBadgesFilters;
	sort?: SortMeta;
} & PaginatedMeta;

class GetBadges implements UseCase<ARGS, PaginatedResults<Badge>> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, siteId, page, perPage, filter, sort, query }: ARGS): Promise<PaginatedResults<Badge>> {
		return await this.badgeRepository.getBadges(companyId, siteId, page, perPage, filter, sort, query);
	}
}

export default GetBadges;
