import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { FormValues } from "./WebHookContainer";
import { useTranslation } from "react-i18next";
import Select, { MultiValue, GroupBase, components } from "react-select";
import React from "react";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import { SelectComponentsConfig } from "react-select/dist/declarations/src/components";

interface Option {
  value: string;
  label: string;
}

interface GroupedOption {
  label: string;
  options: Option[];
}

interface SelectFieldProps {
  index: number;
  name: "events" | "siteIds";
  options: GroupedOption[] | Option[];
  handleChange: (
    onChange: (options: string[]) => void,
  ) => (options: MultiValue<Option>) => void;
  isDisabled?: boolean;
  rules: UseControllerProps<FormValues>["rules"];
  components?: SelectComponentsConfig<Option, true, GroupBase<Option>>;
  getValue: (value: string[]) => MultiValue<Option>;
}

const SelectField = ({
  index,
  name,
  options,
  handleChange,
  rules,
  isDisabled,
  components,
  getValue,
}: SelectFieldProps) => {
  const { t } = useTranslation("webhooks");
  const {
    control,
    formState: { errors },
  } = useFormContext<FormValues>();
  const error = errors["webHooks"]?.[index]?.[name]?.message;
  const fieldName = `webHooks.${index}.${name}` as const;

  return (
    <FormControl isInvalid={!!error}>
      <Controller
        control={control}
        name={fieldName}
        rules={rules}
        render={({
          field: { onChange, onBlur, value, name: fieldName, ref },
        }) => (
          <Select
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isDisabled={isDisabled}
            options={options}
            onChange={handleChange(onChange)}
            value={getValue(value as string[])}
            isMulti={true}
            onBlur={onBlur}
            name={fieldName}
            ref={ref}
            menuPortalTarget={document.getElementById("root")}
            menuPosition="fixed"
            menuShouldScrollIntoView={false}
            menuPlacement={"auto"}
            placeholder={t(`${name}Placeholder`)}
            aria-errormessage={`webhook-error-message-${name}`}
            isClearable={false}
            components={{ ValueContainer, ...components }}
            styles={{
              control: (styles) => ({
                ...styles,
                fontSize: "14px",
                borderRadius: 2,
                ...(error
                  ? {
                      borderColor: "red",
                      boxShadow: "0 0 0 1px red",
                    }
                  : {}),
              }),
              noOptionsMessage: (styles) => ({
                ...styles,
                fontSize: "14px",
              }),
              option: (styles) => ({
                ...styles,
                fontSize: "14px",
                ":hover": {
                  backgroundColor: "rgba(0,95,250,0.25)",
                },
              }),
              multiValue: (styles) => ({
                ...styles,
                width: "100%",
              }),
              multiValueLabel: (styles) => ({
                ...styles,
                width: "100%",
              }),
              groupHeading: (styles) => ({
                ...styles,
                padding: 0,
                cursor: "pointer",
                fontSize: "14px",
              }),
            }}
          />
        )}
      />
      <FormErrorMessage id={`webhook-error-message-${name}`}>
        {error}
      </FormErrorMessage>
    </FormControl>
  );
};

const ValueContainer = (props: any) => {
  const { t } = useTranslation("webhooks");
  const selectedLabelKey = `select.${props.selectProps.name.endsWith(".events") ? "events" : "sites"}`;
  const selectedLabel = props.selectProps.menuIsOpen
    ? null
    : t(selectedLabelKey, { count: props.children[0].length });
  return (
    <components.ValueContainer {...props}>
      {props.children[0] &&
        (props.children[0].key === "placeholder"
          ? props.children[0]
          : selectedLabel)}
      {props.children[1]}
    </components.ValueContainer>
  );
};

export default SelectField;
