import UseCase from '../../../core/app/usecase';
import Badge from '../../entities/badge';
import BadgeRepository from '../../repositories/badgeRepository';

type ARGS = {
	companyId: string;
	badgeId: string;
	siteId?: string;
};

class GetBadge implements UseCase<ARGS, Badge> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, badgeId, siteId }: ARGS): Promise<Badge> {
		return await this.badgeRepository.getBadge(companyId, badgeId, siteId);
	}
}

export default GetBadge;
