import React from "react";
import { Tag as ChakraTag } from "@chakra-ui/react";
import { components, SingleValueProps } from "react-select";
import Tag from "../../../../domain/entities/tag";
import { darken } from "polished";
import BaseTagSelect, { BaseTagSelectProps } from "./BaseTagSelect";

type SingleTagSelectProps = Omit<
  BaseTagSelectProps<false>,
  "value" | "onChange"
> & {
  selectedTag: Tag;
  setSelectedTag: (tag: Tag) => void;
  noSelectionLabel?: string;
};

const SingleTagSelect = ({
  selectedTag,
  setSelectedTag,
  noSelectionLabel,
  ...props
}: SingleTagSelectProps) => (
  <BaseTagSelect
    {...props}
    value={selectedTag}
    onChange={setSelectedTag}
    noSelectionLabel={noSelectionLabel}
    components={{
      SingleValue,
    }}
  />
);

const SingleValue = ({ children, ...props }: SingleValueProps<Tag>) => (
  <components.SingleValue {...props}>
    <ChakraTag
      size="md"
      color={darken(0.6, props.data.color)}
      bg={props.data.color}
      sx={{ fontSize: "12px" }}
    >
      {props.data.name}
    </ChakraTag>
  </components.SingleValue>
);

export default SingleTagSelect;
