import { SignUpCompany } from './args';
import UseCase from '../../../core/app/usecase';
import { AuthRepository, makeAuthRepository } from '../../repositories/authRepository';

export class UserAlreadyRegistered {
	constructor(private repository: AuthRepository) {}

	async Call(token: string, company: string): Promise<{ already_registered: string }> {
		return await this.repository.userAlreadyRegistered(token, company);
	}
}
export class CreateBusinessAccount implements UseCase<SignUpCompany, boolean> {
	constructor(private repository: AuthRepository) {}

	async Call(payload: SignUpCompany): Promise<boolean> {
		return await this.repository.createBusinessAccount(payload);
	}
}

class GetBusinessTypes implements UseCase<null, Array<string>> {
	constructor(private repository: AuthRepository) {}

	async Call(): Promise<Array<string>> {
		return await this.repository.getBusinessTypes();
	}
}

export default GetBusinessTypes;

export const makeCreateBusinessAccount = (): CreateBusinessAccount => {
	return new CreateBusinessAccount(makeAuthRepository());
};
export const makeGetBusinessTypes = () => {
	return new GetBusinessTypes(makeAuthRepository());
};
export const makeUserAlreadyRegistered = (): UserAlreadyRegistered => {
	return new UserAlreadyRegistered(makeAuthRepository());
};
