import UseCase from '../../../core/app/usecase';
import { GetStaffFilters } from '../../../presentation/components/Views/Company/StaffTableView';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import Staff from '../../entities/staff';
import StaffRepository from '../../repositories/staffRepository';

export type GetSystemStaffArgs = {
	companyId: string;
	filter?: GetStaffFilters;
	sort?: SortMeta;
};

class GetSystemStaff implements UseCase<GetSystemStaffArgs, Staff[]> {
	private staffRepository: StaffRepository;

	constructor(staffRepository: StaffRepository) {
		this.staffRepository = staffRepository;
	}

	async Call({ companyId, filter, sort }: GetSystemStaffArgs): Promise<Staff[]> {
		return await this.staffRepository.getSystemStaff(companyId, filter, sort);
	}
}

export default GetSystemStaff;
