import { useApp } from "../../../app";
import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import MainNavBar from "../../components/Common/MainNavBar";
import SiteDetailsTopBar from "../../components/Views/SiteDetails/SiteDetailsTopBar";
import { useParams } from "react-router-dom";
import useSiteMainViewModel from "../../hooks/Site/useSiteMainViewModel";
import SiteTopBarName from "./SiteTopBarName";
import { useAuth } from "../../providers/Auth0JWTProvider";

const SiteDetailsMainView = () => {
  const { updateContext } = useApp();
  const { updateSiteContext, siteId: siteIdContext } = useAuth();
  const { siteId } = useParams();
  const { details, options } = useSiteMainViewModel(siteId);
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!init && details && options) {
      updateContext({
        topBarTitle: <SiteTopBarName site={details} stateVisible={true} />,
        site: { details, options },
      });
      updateSiteContext(siteId);
      setInit(true);
    }
  }, [details, init, options, siteId, updateContext, updateSiteContext]);

  return (
    <MainLayout
      isLoading={!init || siteId !== siteIdContext}
      header={<SiteDetailsTopBar />}
      nav={<MainNavBar />}
    />
  );
};

export default SiteDetailsMainView;
