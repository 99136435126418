import UseCase from '../../../core/app/usecase';
import BadgeReader from '../../entities/badgeReader';
import BadgeReaderRepository from '../../repositories/badgeReaderRepository';

type Args = {
	siteId: string;
	companyId: string;
};

class GetBadgeReadersNotPaginated implements UseCase<Args, BadgeReader[]> {
	constructor(private badgeReaderRepository: BadgeReaderRepository) {}

	async Call({ companyId, siteId }: Args): Promise<BadgeReader[]> {
		return await this.badgeReaderRepository.getBadgeReadersWithoutPaging(companyId, siteId);
	}
}

export default GetBadgeReadersNotPaginated;
