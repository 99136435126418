import AiTaskDocument from "./aiTaskDocument";
import { AiTaskSectionCode } from "./aiTaskSectionCode";
import { Identifiable } from "./interfaces/identifiable";

export enum AiTaskStatus {
    PENDING = "pending",
    RUNNING = "running",
    TERMINATED = "terminated",
    ERROR = "error",
  }

type AiTask = Identifiable & {
    sectionCode?: AiTaskSectionCode;
    result?: AiTaskDocument[];
    status : AiTaskStatus;

    error: string;
    documentCount?: number;
    createdAt?: Date;
    startedAt?: Date;
    endedAt?: Date;
};

export default AiTask;