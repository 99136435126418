import UseCase from '../../../core/app/usecase';
import DocumentRepository from '../../repositories/documentRepository';
import CreatePropagableDocumentsParams from './parameterObjects/createPropagableDocumentsParams';

/**
 * Create document
 *
 * @param document the document that will be created
 */
class CreatePropagableDocument implements UseCase<CreatePropagableDocumentsParams, void> {
	constructor(private repository: DocumentRepository) {}

	async Call({ companyId, documents }: CreatePropagableDocumentsParams): Promise<void> {
		return await this.repository.createPropagableDocuments(companyId, documents);
	}
}

export default CreatePropagableDocument;
