import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  Grid,
  GridItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Text,
  Tooltip,
  VStack
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UserNotification } from "../../../../domain/entities/interfaces/userNotification";
import React, { Fragment, useEffect, useState } from "react";
import { COLORS } from "../../../assets/theme/colors";
import { IoIosMailUnread, IoMdNotifications } from "react-icons/io";
import useUserSettingsViewModel, { UpdateAlertParams } from "../../../hooks/Users/useUserSettingsViewModel";
import ContentLayout from "../../../layout/ContentLayout";
import DeleteButton from "../../../components/Common/table/DeleteButton";
import LoadingView from "../../Common/LoadingView";

type NotificationsGroup = {
  notification?: string;
  channels: { id: string; channel: string; isActive: boolean }[];
};
const UserNotificationsView = () => {
  const { t } = useTranslation("notifications");
  const {
    userNotifications,
    updateUserNotifications,
    notificationsIsFetching,
    isLoadingUpdateUserNotifications,
    updateAlertNotification
  } = useUserSettingsViewModel();
  const notifications = [
    {
      notification: "expiring_documents",
      label: t("alertGeneralDocuments", {ns: 'settings'}),
      isActive: userNotifications?.find(
        (el) => el.notification === "expiring_documents"
      )?.isActive
    },
    {
      notification: "expiring_owned_sites_evaluations",
      label: t("alertEvaluationsMade", {ns: 'settings'}),
      isActive: userNotifications?.find(
        (el) => el.notification === "expiring_owned_sites_evaluations"
      )?.isActive
    },
    {
      notification: "expiring_working_sites_evaluations",
      label: t("alertEvaluationsReceived", {ns: 'settings'}),
      isActive: userNotifications?.find(
        (el) => el.notification === "expiring_working_sites_evaluations"
      )?.isActive
    }
  ];
  const { register, handleSubmit, formState } = useForm<UserNotification[]>();
  const [checkboxState, setCheckboxState] = useState<{
    [key: string]: boolean;
  }>({});
  const dirtyFields = formState.dirtyFields;
  const [saveUpdatedNotifications, setSaveUpdatedNotifications] = useState<boolean>(false);
  const [saveUpdatedConfigurations, setSaveUpdatedConfigurations] = useState<boolean>(false);
  const [inputsData, setInputsData] = useState(
    notifications.map(() => [{ id: 1, value: 1 }])
  );
  const remapNotifications = (
    notifications: UserNotification[]
  ): NotificationsGroup[] => {
    const remappedNotifications: { [key: string]: NotificationsGroup } = {};
    notifications?.forEach((notification) => {
      const {
        id,
        notification: notificationType,
        channel,
        isActive
      } = notification;

      if (!remappedNotifications[notificationType]) {
        remappedNotifications[notificationType] = {
          notification: notificationType,
          channels: []
        };
      }
      remappedNotifications[notificationType].channels.push({
        id,
        channel,
        isActive
      });
    });

    return Object.values(remappedNotifications);
  };

  const remappedNotifications: NotificationsGroup[] =
    remapNotifications(userNotifications);

  const handleCheckboxChange = (id: string) => {
    setCheckboxState((prevState) => {
      return {
        ...prevState,
        [id]: !prevState[id]
      };
    });
    setSaveUpdatedNotifications(true);
  };
  useEffect(() => {
    if (userNotifications) {
      const initialState: { [key: string]: boolean } = {};
      remappedNotifications.forEach((notification) => {
        notification.channels.forEach((channel) => {
          initialState[channel.id] = channel.isActive;
        });
      });
      setCheckboxState(initialState);
    }
  }, [userNotifications]);

  const onSubmitForm = (data: UserNotification[]) => {
    const updatedData = [];
    const updatedNotifications = userNotifications.reduce(
      (acc: UserNotification[], notification, index) => {
        if (
          notification.isActive !==
          (data[notification.id] as unknown as boolean)
        ) {
          return [...acc, { ...notification, isActive: data[notification.id] }];
        }
        return acc;
      },
      []
    ) as unknown as UserNotification[];

    for (const key in dirtyFields) {
      const foundItem = updatedNotifications?.find((item) => item.id === key);
      if (foundItem) {
        updatedData.push(foundItem);
      }
    }
    updateUserNotifications(updatedData);
    setSaveUpdatedNotifications(false);
  };

  useEffect(() => {
    if (userNotifications?.length > 0) {
      const updatedInputsData = userNotifications
        .map((item) => {
          switch (item.notification) {
            case "expiring_documents":
              return item.daysBeforeAlert.map((day, index) => ({
                id: index,
                value: day,
              }));
            case "expiring_owned_sites_evaluations":
              return item.daysBeforeAlert.map((day, index) => ({
                id: index,
                value: day,
              }));
            case "expiring_working_sites_evaluations":
              return item.daysBeforeAlert.map((day, index) => ({
                id: index,
                value: day,
              }));
            default:
              return [];
          }
        })
        .filter((data) => {
          return data.length > 0;
        });
      setInputsData(updatedInputsData);
    } else {
      setInputsData(notifications.map(() => [{ id: 1, value: 1 }]));
    }
  }, [userNotifications]);
  const handleChange = (notificationIndex, inputIndex, valueString) => {
    setSaveUpdatedConfigurations(true);
    const updatedInputs = [...inputsData];
    updatedInputs[notificationIndex][inputIndex].value = Number(valueString);
    setInputsData(updatedInputs);
  };

  const addInput = (notificationIndex) => {
    setSaveUpdatedConfigurations(true);
    const updatedInputs = [...inputsData];
    updatedInputs[notificationIndex].push({ id: Date.now(), value: 1 });
    setInputsData(updatedInputs);
  };

  const removeInput = (notificationIndex, id) => {
    setSaveUpdatedConfigurations(true);
    const updatedInputs = inputsData[notificationIndex].filter(
      (input) => input.id !== id
    );
    setInputsData((prev) =>
      prev.map((inputs, idx) =>
        idx === notificationIndex ? updatedInputs : inputs
      )
    );
  };

  const hasNotifications = notifications.some((n) =>
    notifications?.some((el) => el.notification === n.notification)
  );
  const removeDuplicates = (array) => {
    return array.filter((value, index, self) => self.indexOf(value) === index);
  };
  const getAlertParams = (notifications, userNotifications) => {
    const alertParams: UpdateAlertParams = {
      expiringDocumentsDaysBeforeAlert: [],
      expiringOwnedSitesDaysBeforeAlert: [],
      expiringWorkingSitesDaysBeforeAlert: []
    };

    notifications.forEach((notification, notificationIndex) => {
      const userNotificationGroup = userNotifications[notificationIndex];

      if (userNotificationGroup) {
        switch (notification.notification) {
          case "expiring_documents":
            alertParams.expiringDocumentsDaysBeforeAlert = removeDuplicates(
              userNotificationGroup.map((item) => item.value)
            );
            break;
          case "expiring_owned_sites_evaluations":
            alertParams.expiringOwnedSitesDaysBeforeAlert = removeDuplicates(
              userNotificationGroup.map((item) => item.value)
            );
            break;
          case "expiring_working_sites_evaluations":
            alertParams.expiringWorkingSitesDaysBeforeAlert = removeDuplicates(
              userNotificationGroup.map((item) => item.value)
            );
            break;
          default:
            break;
        }
      }
    });

    return alertParams;
  };


  const handleUpdateNotification = async() => {
    const alertParams = getAlertParams(notifications, inputsData);
    await updateAlertNotification(alertParams);
    setSaveUpdatedConfigurations(false);
  };

  return (
    <ContentLayout>
      <form
        onSubmit={handleSubmit(onSubmitForm)}
        id="notificationForm"
      >
        {(saveUpdatedNotifications || saveUpdatedConfigurations) && (
          <Flex justifyContent={'space-between'}
                alignItems={'end'}
                width={"calc(100vw - 205px)"}
                position={"sticky"}
                zIndex={2}
                top={4}
                mx={10}
                gap={4}>
              <Box
                py={2}
                borderRadius={6}
                bg={COLORS.lightRed}
                width={'100%'}
              >
                <Text px={4}>{t("unsavedChanges", { ns: "sites" })}</Text>
              </Box>
              {hasNotifications && (
                <Box>
                  {saveUpdatedConfigurations && (
                    <Button
                      mt={4}
                      borderRadius={6}
                      isLoading={isLoadingUpdateUserNotifications}
                      onClick={() => handleUpdateNotification()}
                      colorScheme={"blue"}
                    >
                      {t("save", { ns: "common" })}
                    </Button>)}
                  {saveUpdatedNotifications && (
                    <Button
                      isLoading={isLoadingUpdateUserNotifications}
                      borderRadius={6}
                      type="submit"
                      form={"notificationForm"}
                      colorScheme={"blue"}
                      position={"sticky"}
                      top={4}
                    >
                      {t("save", { ns: "common" })}
                    </Button>
                  )}
                </Box>
              )}
          </Flex>
        )}
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(6, 1fr)"
          gap={4}
          m={10}
        >
          <GridItem colSpan={4}
                    p={6}
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="10px">
            {notificationsIsFetching ? <LoadingView /> : <Box
              h={"100%"}
              width={"100%"}
              paddingBottom={6}
            >
              {!notificationsIsFetching && userNotifications?.length > 0 ? (
                <Flex flexDirection={"column"}>
                    <Flex justifyContent={"space-between"} alignItems={"center"} pb={4}>
                      <Box>
                        <Text textStyle="h2">
                          {t("notificationSettings", { ns: "userSettings" })}
                        </Text>
                      </Box>
                    </Flex>
                    <Divider my={4} colorScheme={"blackAlpha"} />
                    <VStack align="start">
                      {Object.keys(remappedNotifications).map(
                        (notificationKey, index) => {
                          const notification =
                            remappedNotifications[notificationKey];
                          return (
                            <Fragment key={notification.id}>
                              <Text fontWeight={600}>
                                {t(
                                  "user." + notification.notification + ".name"
                                )}
                              </Text>
                              <FormControl
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Text
                                  fontStyle={"italic"}
                                  color={"gray.500"}
                                >
                                  {t(
                                    "user." +
                                    notification.notification +
                                    ".description"
                                  )}
                                </Text>
                                <Flex gap={6} justifyContent={"flex-start"}>
                                  {notification.channels.map(
                                    (channel, channelIndex: number) => {
                                      const isChecked =
                                        checkboxState[channel.id] ??
                                        channel.isActive;
                                      return (
                                        <Box
                                          position="relative"
                                          key={`${channel.id}_${channelIndex}`}
                                        >
                                          <Checkbox
                                            id={`${channel.id}`}
                                            style={{
                                              position: "absolute",
                                              opacity: 0,
                                              zIndex: -1,
                                              cursor: "pointer",
                                              width: "1px",
                                              height: "1px",
                                              overflow: "hidden"
                                            }}
                                            defaultChecked={isChecked}
                                            {...register(
                                              `${channel.id}` as const
                                            )}
                                          />
                                          <label
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              !saveUpdatedConfigurations && handleCheckboxChange(channel.id);
                                            }}
                                            htmlFor={`${channel.id}`}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <Tooltip
                                              label={
                                                isChecked
                                                  ? channel.channel === "mail"
                                                    ? t("disableMail")
                                                    : t("disableNotification")
                                                  : channel.channel === "push"
                                                    ? t("enableNotification")
                                                    : t("enableMail")
                                              }
                                            >
                                              <Box
                                                bgColor={saveUpdatedConfigurations ? COLORS.gray : (isChecked ? COLORS.sikuroBlue : COLORS.error)}
                                                borderRadius={6}
                                                color="white"
                                                fontWeight={600}
                                                p={2}
                                                border={`3.5px solid ${saveUpdatedConfigurations ? COLORS.gray : (isChecked ? COLORS.sikuroBlue : COLORS.error) }`}
                                                _hover={
                                                  isChecked
                                                    ? {
                                                      boxSizing: "border-box",
                                                      boxShadow:
                                                        "10px 11px 21px -10px rgba(0,0,0,0.72)",
                                                      transition:
                                                        "all 0.1s ease-out"
                                                    }
                                                    : {
                                                      boxSizing: "border-box",
                                                      boxShadow:
                                                        "10px 11px 21px -10px rgba(0,0,0,0.72)",
                                                      transition:
                                                        "all 0.1s ease-out"
                                                    }
                                                }
                                              >
                                                {!notificationsIsFetching &&
                                                  (channel.channel === "push" ? (
                                                    <IoMdNotifications
                                                      size={"1rem"}
                                                    />
                                                  ) : (
                                                    <IoIosMailUnread
                                                      size={"1rem"}
                                                    />
                                                  ))}
                                                {notificationsIsFetching && (
                                                  <Spinner size="md" />
                                                )}
                                              </Box>
                                            </Tooltip>
                                          </label>
                                        </Box>
                                      );
                                    }
                                  )}
                                </Flex>

                              </FormControl>
                              <Divider />
                            </Fragment>
                          );
                        }
                      )}
                    </VStack>
                </Flex>
              ) : (
                <Text>{t("noItems", { ns: "notifications" })}</Text>
              )}

            </Box>}
          </GridItem>
          <GridItem colSpan={2}
                    p={6}
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="10px">
            <Box pb={8}>
              <Text textStyle="h2">
                {t("notificationNoticePeriod", { ns: 'userSettings' })}
              </Text>
              <Box>{t("", { ns: "settings" })}</Box>
            </Box>
            <Divider />
            <Flex
              width={"100%"}
              flexDirection={"column"}
            >
              <Flex
                flexDirection={"row"}
                justifyContent="space-between"
                flexWrap={"wrap"}
              >
                {hasNotifications ? (
                  notifications?.map((n, notificationIndex) =>
                    notifications?.some(
                      (el) => el.notification === n.notification
                    ) ? (
                      <Flex flexDirection="column" key={n.notification} width={'100%'}>
                        <Flex key={n.notification} flexDirection="column" gap={2}>
                          <Text my={3}>{n.label}</Text>
                          {inputsData[notificationIndex]?.map(
                            (input, inputIndex) => {
                              return (
                                <Flex
                                  key={input.id}
                                  alignItems="center"
                                  justifyContent="space-between"
                                  width={"100%"}
                                  pb={2}
                                >
                                  <NumberInput
                                    isDisabled={!n.isActive || saveUpdatedNotifications}
                                    value={input.value}
                                    min={1}
                                    max={365}
                                    width={'100%'}
                                    onChange={(valueString) =>
                                      handleChange(
                                        notificationIndex,
                                        inputIndex,
                                        valueString
                                      )
                                    }
                                  >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                      <Tooltip label={t('daysBeforePlus', { ns: 'userSettings' })}>
                                        <NumberIncrementStepper />
                                      </Tooltip>
                                      <Tooltip label={t('daysBeforeMinus', { ns: 'userSettings' })}>
                                        <NumberDecrementStepper />
                                      </Tooltip>
                                    </NumberInputStepper>
                                  </NumberInput>
                                  {inputIndex !== 0 && (
                                    <Box marginLeft={2}>
                                      <DeleteButton
                                        isDisabled={!n.isActive || saveUpdatedNotifications}
                                        tooltipLabel={t("delete", { ns: 'common' })}
                                        onClick={() =>
                                          removeInput(
                                            notificationIndex,
                                            input.id
                                          )
                                        }
                                      />
                                    </Box>
                                  )}
                                  {inputIndex ===
                                    inputsData[notificationIndex]?.length - 1 &&
                                    inputIndex <= 1 && (
                                      <Tooltip label={t("add", { ns: "settings" })}>
                                        <Button
                                          isDisabled={!n.isActive || saveUpdatedNotifications}
                                          aria-label="add"
                                          marginLeft={2}
                                          onClick={() =>
                                            addInput(notificationIndex)
                                          }
                                          bgColor={'transparent'}
                                        >
                                          <FaPlus />
                                        </Button>
                                      </Tooltip>
                                    )}
                                </Flex>
                              );
                            }
                          )}
                        </Flex>
                      </Flex>
                    ) : null
                  )
                ) : (
                  <Text>{t("noNotificationsToConfigure")}</Text>
                )}
              </Flex>
            </Flex>
          </GridItem>
        </Grid>
      </form>
    </ContentLayout>
);
};

export default UserNotificationsView;
