import { injectable } from 'tsyringe';
import ExportRepository from '../../domain/repositories/exportRepository';
import { ApiService } from '../utilities/apiService';
import { formatISODate } from '../utilities/filters';

@injectable()
class ServerExportRepository implements ExportRepository {
	constructor(private apiService: ApiService) {}

	async export(
		companyId: string,
		topic: string,
		siteId?: string,
		siteIds?: string[],
		startDate?: Date,
		endDate?: Date,
		permissionSites?: { [key: string]: string },
		resourcesIds?: string[],
		resourceDocumentsToExclude?: string[]
	): Promise<boolean> {
		const path = siteId ? `sites/${siteId}/${topic}` : `${topic}`;
		//NM: I perform a check on the string to modify the URLs of the sites where I work.
		const workingSitesPath = `sites/${siteId}/suppliers/${companyId}/${topic.replace('ws-', '')}`;

		let response = undefined;

		if (topic === "workers-requirements" || 
			topic === "vehicles-requirements" || 
			topic === "machines-requirements" || 
			topic === "tools-requirements" || 
			topic === "chemicals-requirements" ||
			topic === "ws-workers-requirements" || 
			topic === "ws-vehicles-requirements" || 
			topic === "ws-machines-requirements" || 
			topic === "ws-tools-requirements" || 
			topic === "ws-chemicals-requirements" ||
			topic === "requirements"
		){

			const requestBody: any = {
				resourceIds: {},  // Initialize resourceIds to prevent undefined issues
				excludedResourceDocumentTypes: {}
			  };

			if (topic != "requirements"){
				requestBody.resourceIds = resourcesIds;
			}

			requestBody.excludedResourceDocumentTypes = resourceDocumentsToExclude;

			response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/exports/` + (topic.includes('ws-') ? workingSitesPath : path),
				{
					method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(
							requestBody
						),
				}
			);
		}

		if (topic === 'badges-records' || topic === 'badges-records-denied') {
			const value = Object.entries(permissionSites).map(([key, value]) => {
				const parts = value.split('-');
				return { siteId: key, permission: parts.pop() };
			});
			if (topic === 'badges-records'){
				response = await this.apiService.fetchWithToken(
					`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/exports/` + (topic.includes('ws-') ? workingSitesPath : path),
					{
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({
							siteIds: Object.keys(permissionSites).length > 0 ? value : siteIds,
							fromDate: formatISODate(startDate),
							toDate: formatISODate(endDate),
						}),
					}
				);
			}else{
				response = await this.apiService.fetchWithToken(
					`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/exports/badges-failed-records`,
					{
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({
							siteIds: Object.keys(permissionSites).length > 0 ? value : siteIds,
							fromDate: formatISODate(startDate),
							toDate: formatISODate(endDate),
						}),
					}
				);
			}
		} 
		if (!response) {

			response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/exports/` + (topic.includes('ws-') ? workingSitesPath : path),
				{
					method: 'GET',
					headers: { 'Content-Type': 'application/json' },
				}
			);
		}

		if (!response.ok) {
			console.error('cannot download file', response.statusText);
		}

		return Promise.resolve(response.ok);
	}
}

export default ServerExportRepository;
