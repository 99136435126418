import { useMemo } from "react";
import User from "../../../domain/entities/user";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import UpdateUserViewModel from "../../viewmodels/users/UpdateUserViewModel";
import useUserSiteRolesViewModel from "../Roles/useUserSiteRolesViewModel";
import Role, { RoleContext } from "../../../domain/entities/role";

interface AddRelArgs {
  siteId: string;
  roleId: string;
}
interface UpdRelArgs {
  siteId: string;
  oldRoleId: string;
  newRoleId: string;
}

export interface useUpdateUserViewModelProps {
  user: User;
  roles: Role[];
  error: unknown;
  updateUser: (user: User) => void;
  updateUserImage: (image: File) => void;
  isLoading: boolean;
  siteRolesProps: any;
  worksiteRolesProps: any;
  addUserSiteRole: (args: AddRelArgs) => void;
}
const useUpdateUserViewModel = (
  userId?: string,
): useUpdateUserViewModelProps => {
  const { companyId, company, updateContext } = useAuth();
  const viewModel = useMemo(
    () => new UpdateUserViewModel(companyId, userId),
    [userId, companyId],
  );
  const siteHookProps = useUserSiteRolesViewModel(userId, RoleContext.SITE);
  const worksiteHookProps = useUserSiteRolesViewModel(
    userId,
    RoleContext.WORKSITE,
  );

  const {
    data: user,
    isLoading,
    refetch,
    isRefetching,
    error,
  } = useQuery(["get-user", userId], async () => await viewModel.get());

  const { data: roles, isLoading: isLoadingRoles } = useQuery(
    ["get-roles", userId],
    async () => await viewModel.getRoles(),
  );

  const updateMutation = useMutation(
    async (user: User) => await viewModel.update(user),
    {
      onSuccess: () => {
        refetch();
        updateContext(companyId, company);
      },
    },
  );

  const updateImageMutation = useMutation(
    (image: File) => viewModel.updateUserImage(userId, image),
    { onSuccess: () => refetch() },
  );

  const addUserSiteRoleMut = useMutation(
    ({ siteId, roleId }: { siteId: string; roleId: string }) =>
      viewModel.linkUserSiteRole(siteId, roleId),
    {
      onError: (err) =>
        console.error("cannot link user site role to user, site, role", err),
      onSuccess: () => {
        siteHookProps.refetchSiteRoles();
        worksiteHookProps.refetchSiteRoles();
      },
    },
  );

  const delUserSiteRoleMut = useMutation(
    ({ siteId, roleId }: AddRelArgs) =>
      viewModel.unlinkUserSiteRole(siteId, roleId),
    {
      onError: (err) =>
        console.error("cannot link user site role to user, site, role", err),
      onSuccess: () => {
        siteHookProps.refetchSiteRoles();
        worksiteHookProps.refetchSiteRoles();
      },
    },
  );

  const updUserSiteRoleMut = useMutation(
    ({ siteId, oldRoleId, newRoleId }: UpdRelArgs) =>
      viewModel.updateUserSiteRole(siteId, oldRoleId, newRoleId),
    {
      onError: (err) =>
        console.error("cannot link user site role to user, site, role", err),
      onSuccess: () => {
        siteHookProps.refetchSiteRoles();
        worksiteHookProps.refetchSiteRoles();
      },
    },
  );

  const siteRoleProps = {
    deleteUserSiteRole: delUserSiteRoleMut.mutateAsync,
    updateUserSiteRole: updUserSiteRoleMut.mutateAsync,
    roles,
    addUserSiteRole: addUserSiteRoleMut.mutateAsync,
    updateUserSiteRoleIsLoading: updUserSiteRoleMut.isLoading,
    addUserSiteRoleIsLoading: addUserSiteRoleMut.isLoading,
    deleteUserSiteRoleIsLoading: delUserSiteRoleMut.isLoading,
  };

  return {
    user,
    roles,
    error,
    updateUser: updateMutation.mutateAsync,
    addUserSiteRole: addUserSiteRoleMut.mutateAsync,
    updateUserImage: updateImageMutation.mutateAsync,
    isLoading:
      isLoading ||
      updateMutation.isLoading ||
      updateImageMutation.isLoading ||
      isRefetching,
    siteRolesProps: {
      ...siteHookProps,
      ...siteRoleProps,
      isLoading: isLoadingRoles && siteHookProps.isLoading,
    },
    worksiteRolesProps: {
      ...worksiteHookProps,
      ...siteRoleProps,
      isLoading: isLoadingRoles && worksiteHookProps.isLoading,
    },
  };
};

export default useUpdateUserViewModel;
