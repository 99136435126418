import {
  Button,
  Flex,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FiAlertTriangle, FiSettings, FiUpload } from "react-icons/fi";
import File from "../../../../domain/entities/file";
import ImportInfo from "../../../../domain/entities/importInfo";
import { COLORS } from "../../../assets/theme/colors";
import LoadingView from "../../../screens/Common/LoadingView";
import DragDropFileView from "../../Common/DragDropFileView";
import BaseModal from "../../Common/alerts/BaseModal";

type ResourceImportModalProps = {
  resourceTypeTranslation: string;
  resourceType: string;
  title: string;
  onClose: () => void;
  onConfirm: (file: File) => Promise<ImportInfo>;
  error: string;
};
const ResourceImportModal = ({
  onClose,
  onConfirm,
  resourceType,
  title,
  resourceTypeTranslation,
  error,
}: ResourceImportModalProps) => {
  const { t } = useTranslation("common");
  const [file, setFile] = useState<File | null>(null);
  const [result, setResult] = useState<ImportInfo>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onUpload = async () => {
    if (file) {
      setIsLoading(true);
      const result = await onConfirm(file);
      setIsLoading(false);
      setResult(result);
    }
  };
  const templateMap = {
    worker: "/templates/lavoratori_template.xlsx",
    vehicle: "/templates/veicoli_template.xlsx",
    machine: "/templates/macchinari_template.xlsx",
    tool: "/templates/attrezzature_template.xlsx",
    chemical: "/templates/prodotti_chimici_template.xlsx",
  };

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  const handleConfirm = () => {
    if (!result && !error) {
      return onUpload;
    } else {
      return undefined;
    }
  };

  return (
    <BaseModal
      size={"3xl"}
      title={title}
      onClose={onClose}
      onCancelLabel={result ? t("close") : undefined}
      onConfirm={handleConfirm}
      footer={
        <>
          {!error && (
            <Button
              colorScheme="blue"
              disabled={!file}
              onClick={result ? onClose : onUpload}
              isLoading={isLoading}
            >
              {t(result ? "close" : "confirm", { ns: "common" })}
            </Button>
          )}
          {!result && (
            <Button colorScheme="red" onClick={onClose}>
              {t("cancel", { ns: "common" })}
            </Button>
          )}
        </>
      }
    >
      <Flex flexDirection={"column"}>
        {isLoading && (
          <Flex padding={5} justifyContent={"center"} alignItems={"center"}>
            <LoadingView width="100" height="100" />
          </Flex>
        )}

        {result && !isLoading && (
          <>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={FiUpload} color={COLORS.sikuroBlue} />
                {`${t("uploadedResources", { ns: "common" })} : ${result?.rows}`}
              </ListItem>
              <ListItem>
                <ListIcon as={FiSettings} color={COLORS.sikuroBlue} />
                {`${t("processedResources", { ns: "common" })} : ${result?.resources}`}
              </ListItem>
              {result.errors.length && (
                <ListItem>
                  <ListIcon as={FiAlertTriangle} color={COLORS.red} />
                  {t("errorsProcessing", { ns: "common" })} :
                  {result &&
                    result.errors.map((error, idx) => (
                      <Text px={2} key={idx}>
                        {t("errorsInfo", {
                          ns: "common",
                          error: error.col,
                          row: error.row,
                        })}
                      </Text>
                    ))}
                </ListItem>
              )}
            </List>
          </>
        )}
        {!result && !isLoading && !error && (
          <>
            <Text py={4}>
              <Trans
                i18nKey="importMessage"
                target={resourceTypeTranslation}
                components={{
                  a: (
                    <Link
                      sx={{ color: COLORS.sikuroBlue }}
                      py={2}
                      href={templateMap[resourceType]}
                    />
                  ),
                }}
              />
            </Text>
            <DragDropFileView
              onUploaded={setFile}
              acceptedExtensions=".xlsx,.xls"
            />
          </>
        )}
        {error && !isLoading && !result && (
          <Text>
            <Trans
              i18nKey={error}
              ns={"common"}
              components={{
                a: (
                  <Link
                    sx={{ color: COLORS.sikuroBlue }}
                    py={2}
                    href={templateMap[resourceType]}
                  />
                ),
              }}
            />
          </Text>
        )}
      </Flex>
    </BaseModal>
  );
};

export default ResourceImportModal;
