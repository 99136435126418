import { makeGetDocumentCommentGroup } from "../../../core/app/factory/documents/usecases";
import DocumentComment from "../../../domain/entities/documentComment";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";

class MessageViewModel {
  async getDocumentCommentGroup(
    companyId: string,
    groupId: string,
    pageParam: number,
    sort: SortMeta,
  ): Promise<PaginatedResults<DocumentComment>> {
    return await makeGetDocumentCommentGroup().Call({
      companyId,
      groupId,
      pageParam,
      sort,
    });
  }
}

export default MessageViewModel;
