import UseCase from '../../../../core/app/usecase';
import SiteVehicleRepository from '../../../repositories/siteVehicleRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	vehicleId: string;
};

class AutoEvaluateSiteVehicle implements UseCase<Parameters, void> {
	constructor(private repository: SiteVehicleRepository) {}

	async Call({ companyId, siteId, vehicleId }: Parameters): Promise<void> {
		return this.repository.autoEvaluateSiteVehicle(companyId, siteId, vehicleId);
	}
}

export default AutoEvaluateSiteVehicle;
