import UseCase from '../../../core/app/usecase';
import Document from '../../entities/document';
import DocumentRepository from '../../repositories/documentRepository';
import CreateDocumentParams from './parameterObjects/createDocumentParameter';

/**
 * Create document
 *
 * @param document the document that will be created
 */
class CreateDocument implements UseCase<CreateDocumentParams, void> {
	constructor(private repository: DocumentRepository) {}

	async Call({ companyId, documents }: CreateDocumentParams): Promise<void> {
		return await this.repository.createDocument(companyId, documents);
	}
}

export default CreateDocument;
