import Supplier from '../../entities/supplier';
import UseCase from '../../../core/app/usecase';
import { GetWorkingSiteFilter } from '../../repositories/supplierRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import RolesRepository from '../../repositories/rolesRepository';
import Site from '../../entities/site';

interface Args {
	companyId: string;
	filter?: GetWorkingSiteFilter;
	sort?: SortMeta;
}

class GetAvailableWorkingSites implements UseCase<Args, Site[]> {
	private rolesRepository: RolesRepository;

	constructor(supplierRepository: RolesRepository) {
		this.rolesRepository = supplierRepository;
	}

	async Call({ companyId, filter, sort }: Args): Promise<Site[]> {
		return await this.rolesRepository.getAvailableWorkingSites(companyId, filter, sort);
	}
}

export default GetAvailableWorkingSites;
