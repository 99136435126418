import UseCase from '../../../core/app/usecase';
import { GetStaffFilters } from '../../../presentation/components/Views/Company/StaffTableView';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import Staff from '../../entities/staff';
import StaffRepository from '../../repositories/staffRepository';

export type GetStaffArgs = {
	companyId: string;
	siteId: string;
	filter?: GetStaffFilters;
	sort?: SortMeta;
};

class GetStaff implements UseCase<GetStaffArgs, Staff[]> {
	private staffRepository: StaffRepository;

	constructor(staffRepository: StaffRepository) {
		this.staffRepository = staffRepository;
	}

	async Call({ companyId, siteId, filter, sort }: GetStaffArgs): Promise<Staff[]> {
		return await this.staffRepository.getStaff(companyId, siteId, filter, sort);
	}
}

export default GetStaff;
