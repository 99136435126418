import { Identifiable } from './interfaces/identifiable';

export enum CommunicationAction {
	PENDING_EVALUATION_APPROVED = 'evaluation.pending.approved',
	REQUIREMENT_EVALUATION_CREATED = 'evaluation.requirement.created',
	DOCUMENT_EVALUATION_CREATED = 'evaluation.document.created',
	RESOURCE_EVALUATION_CREATED = 'evaluation.resource.created',
	DOCUMENT_UPDATED = 'document.updated',
	FILE_ADDED = 'file.added',
	FILE_REMOVED = 'file.removed',
}

export enum MessageType {
	ACTION = 'action',
	COMMENT = 'comment',
	GROUP = 'action_group',
}

export type Author = Identifiable & {
	name: string;
	email: string;
	photo: string;
	lastLogin?: Date;
};

export type ActionParameter = {
	document_id?: string;
	requirement_id?: string;
	mode?: string;
	result?: string;
	file_name?: string;
	document_type_name?: string;
};

type DocumentComment = Identifiable & {
	comment: string;
	createdAt: Date;
	parameters?: ActionParameter;
	author?: Author;
	taggedUsers: string[];
	taggedDocuments: string[];
	commentUrl: string;
	type: MessageType;
};

export default DocumentComment;
