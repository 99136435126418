import UseCase from '../../../core/app/usecase';
import BadgeRepository from '../../repositories/badgeRepository';
import { BadgeStatusSite } from '../../../presentation/hooks/Badge/useBadgeDetailViewModel';

type ARGS = {
	companyId: string;
	badgeId: string;
};

class GetBadgeSites implements UseCase<ARGS, BadgeStatusSite[]> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, badgeId }: ARGS): Promise<BadgeStatusSite[]> {
		return await this.badgeRepository.getBadgeSites(companyId, badgeId);
	}
}

export default GetBadgeSites;
