import UseCase from '../../../core/app/usecase';
import DocumentTypeRepository from '../../repositories/documentTypeRepository';
import DeleteDocumentTypeParameters from './deleteDocumentTypeParameter';

/**
 * Delete the selected DocuemntType
 *
 * @param type the DocumentType to delete
 */
class DeleteDocumentType implements UseCase<DeleteDocumentTypeParameters, void> {
	constructor(private repository: DocumentTypeRepository) {}

	async Call({ companyId, typeId }: DeleteDocumentTypeParameters): Promise<void> {
		return await this.repository.deleteDocumentType(companyId, typeId);
	}
}

export default DeleteDocumentType;
