import { useParams } from "react-router-dom";
import { SiteToolParams } from "../../../domain/entities/siteTool";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import useWorkingSiteToolDetailViewModel from "../../hooks/WorkingSite/useWorkingSiteToolDetailViewModel";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { Permission } from "../../components/Permissions/Permissions";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const WorkingSiteToolDetailView = () => {
  const { siteId, toolId } = useParams();
  const resourceColumns = ["name", "serial"];

  const downloader = useResourceFilesDownloader(siteId, toolId, "tools");

  return (
    <SiteResourceDetailView<SiteToolParams>
      downloader={downloader}
      resourceId={toolId}
      type={DocumentTypeCategory.TOOL}
      useViewModel={useWorkingSiteToolDetailViewModel(
        siteId,
        toolId,
        resourceColumns,
        DocumentTypeCategory.TOOL,
        AiTaskSectionCode.WORKING_SITE_RESOURCES_TOOLS
      )}
      resourceColumns={resourceColumns}
      isWorkingSite={true}
      isReadOnly={true}
      permissions={{
        downloadDocuments: Permission.Worksite_DownloadToolDoc,
        showGlobalEvaluation: Permission.Worksite_ShowToolGlobalEval,
        setGlobalEvaluation: Permission.Worksite_Show,
        addRequirement: Permission.Worksite_NewToolReq,
        viewRequirements: Permission.Worksite_EditToolReq,
        editTags: Permission.Worksite_EditToolReq,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        documents: {
          delete: Permission.Worksite_Tool_DeleteFile,
          edit: Permission.Worksite_Tool_EditFile,
          evaluate: Permission.Worksite_EvalToolReq,
          viewEvaluations: Permission.Worksite_EvalToolReq,
          downloadAll: Permission.Worksite_DownloadAllFile,
          addFile: Permission.Worksite_EditToolReq,
          addNewFile: Permission.Worksite_Tool_AddFile,

          chat: Permission.Worksite_ToolChat,
          report: Permission.Worksite_Reports,
          setExpiration: Permission.Worksite_Tool_SetExpiration,
        },
      }}
    />
  );
};

export default WorkingSiteToolDetailView;
