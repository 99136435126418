import Machine from '../../../entities/machine';
import UseCase from '../../../../core/app/usecase';
import MachineRepository from '../../../repositories/machineRepository';
import DeleteMachineParameters from './deleteMachineParameter';

class DeleteMachine implements UseCase<DeleteMachineParameters, void> {
	constructor(private repository: MachineRepository) {}

	async Call({ companyId, machineId }: DeleteMachineParameters): Promise<void> {
		return await this.repository.deleteMachine(companyId, machineId);
	}
}

export default DeleteMachine;
