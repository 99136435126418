import { Flex, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BiMessageX } from "react-icons/bi";
import DOMPurify from "dompurify";

interface PreviewFileProps {
  activeFileName: string;
}

export const PreviewFileNotReadable = ({
  activeFileName,
}: PreviewFileProps) => {
  const { t } = useTranslation();
  const fileNotReadable = DOMPurify.sanitize(
    t("fileNotReadable", { ns: "documents" }),
  );
  return (
    <Flex
      title={`PDF Preview ${activeFileName}`}
      backgroundColor={"gray.300"}
      width="100%"
      height="100%"
      style={{
        border: "1px solid black",
        borderRadius: "4px",
        height: "100%",
      }}
    >
      <Flex
        justifySelf="center"
        alignSelf="center"
        width="100%"
        paddingLeft={5}
        paddingRight={5}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Icon fontSize="40px" color={"gray.500"} as={BiMessageX} />
        <Text
          mt={4}
          textAlign={"center"}
          textColor={"gray.600"}
          dangerouslySetInnerHTML={{ __html: fileNotReadable }}
        />
      </Flex>
    </Flex>
  );
};
