import UseCase from '../../../core/app/usecase';
import DocumentType from '../../entities/documentType';
import DocumentTypeRepository from '../../repositories/documentTypeRepository';

type Args = {
	companyId: string;
};

export type GetMachineVehicleDocumentTypeMapRecordsResult = { machineDocumentType: DocumentType; vehicleDocumentType: DocumentType }[];
/**
 * Returns the rename model object.
 */

class GetMachineVehicleDocumentTypeMapRecords implements UseCase<Args, GetMachineVehicleDocumentTypeMapRecordsResult> {
	constructor(private repository: DocumentTypeRepository) {}

	async Call({ companyId }: Args): Promise<GetMachineVehicleDocumentTypeMapRecordsResult> {
		return await this.repository.getMachineVehicleDocumentTypeMapRecords(companyId);
	}
}

export default GetMachineVehicleDocumentTypeMapRecords;
