import CreateStaff from '../../../../domain/interactors/staff/createStaff';
import DeleteStaffElement from '../../../../domain/interactors/staff/deleteStaffElement';
import GetStaff from '../../../../domain/interactors/staff/getStaff';
import UpdateStaffElement from '../../../../domain/interactors/staff/updateStaffElement';
import { makeStaffRepository } from './repositories';
import GetSystemStaff from '../../../../domain/interactors/staff/getSystemStaff';
import UpdateSystemStaffElement from '../../../../domain/interactors/staff/updateSystemStaffElement';

export const makeGetStaff = () => {
	return new GetStaff(makeStaffRepository());
};

export const makeCreateStaff = () => {
	return new CreateStaff(makeStaffRepository());
};

export const makeUpdateStaffElement = () => {
	return new UpdateStaffElement(makeStaffRepository());
};

export const makeDeleteStaffElement = () => {
	return new DeleteStaffElement(makeStaffRepository());
};

export const makeGetSystemStaff = () => {
	return new GetSystemStaff(makeStaffRepository());
};

export const makeUpdateSystemStaffElement = () => {
	return new UpdateSystemStaffElement(makeStaffRepository());
};
