import Tool from '../../../entities/tool';
import UseCase from '../../../../core/app/usecase';
import ToolRepository, { GetToolsFilter } from '../../../repositories/toolRepository';
import { PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';

type ARGS = {
	companyId: string;
	filter?: GetToolsFilter;
	sort?: SortMeta;
	pageParam?: number;
	archived?: boolean;
};

class GetTools implements UseCase<ARGS, PaginatedResults<Tool>> {
	constructor(private repository: ToolRepository) {}

	async Call({ companyId, filter, archived, sort, pageParam }: ARGS): Promise<PaginatedResults<Tool>> {
		return await this.repository.getTools(companyId, filter, archived, sort, pageParam);
	}
}

export default GetTools;
