import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApp } from "../../../app";
import MainNavBar from "../../components/Common/MainNavBar";
import SupplierTopBar from "../../components/Views/Suppliers/SupplierTopBar";
import useSupplierMainViewModel from "../../hooks/Suppliers/useSupplierMainViewModel";
import MainLayout from "../../layout/MainLayout";
import SupplierTopBarName from "./SupplierTopBarName";

const SupplierMainView = () => {
  const { updateContext } = useApp();
  const { supplierId } = useParams();
  const { details } = useSupplierMainViewModel(supplierId);
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!init && details) {
      updateContext({
        topBarTitle: <SupplierTopBarName supplier={details} />,
        supplier: { details },
      });
      setInit(true);
    }
  }, [details, init, supplierId, updateContext]);

  return (
    <MainLayout header={<SupplierTopBar />} nav={<MainNavBar />}></MainLayout>
  );
};

export default SupplierMainView;
