import UseCase from '../../../core/app/usecase';
import { DashboardTopic } from '../../entities/dashboardTopic';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import DashboardRepository from '../../repositories/dashboardRepository';
import { DashboardSupplierInfo } from '../../entities/dashboardSupplierInfo';

interface Args {
	sort: SortMeta;
	companyId: string;
	pageParam: number;
	topic: DashboardTopic;
}

class GetDashboardSupplierInfo implements UseCase<Args, DashboardSupplierInfo[]> {
	private dashboardRepository: DashboardRepository;

	constructor(dashboardRepository: DashboardRepository) {
		this.dashboardRepository = dashboardRepository;
	}

	async Call({ companyId, topic, pageParam, sort }: Args): Promise<DashboardSupplierInfo[]> {
		return await this.dashboardRepository.getDashboardSupplierInfo(companyId, topic, pageParam, sort);
	}
}

export default GetDashboardSupplierInfo;
