import CreateRole from '../../../../domain/interactors/roles/createRole';
import DeleteRole from '../../../../domain/interactors/roles/deleteRole';
import GetAvailableSites from '../../../../domain/interactors/roles/getAvailableSites';
import GetAvailableWorkingSites from '../../../../domain/interactors/roles/getAvailableWorkingSites';
import GetRole from '../../../../domain/interactors/roles/getRole';
import GetRoles from '../../../../domain/interactors/roles/getRoles';
import RoleLinkedUsers from '../../../../domain/interactors/roles/roleLinkedUsers';
import UpdateRole from '../../../../domain/interactors/roles/updateRole';
import UpdateRoleName from '../../../../domain/interactors/roles/updateRoleName';
import GetUserSiteRoles from '../../../../domain/interactors/users/getUserSiteRoles';
import LinkUserSiteRole from '../../../../domain/interactors/users/linkUserSiteRole';
import UnlinkUserSiteRole from '../../../../domain/interactors/users/unlinkUserSiteRole';
import { makeRolesRepository } from './repositories';

export const makeGetRoles = () => {
	return new GetRoles(makeRolesRepository());
};

export const makeGetRole = () => {
	return new GetRole(makeRolesRepository());
};

export const makeCreateRole = () => {
	return new CreateRole(makeRolesRepository());
};

export const makeUpdateRoleName = () => {
	return new UpdateRoleName(makeRolesRepository());
};
export const makeUpdateRole = () => {
	return new UpdateRole(makeRolesRepository());
};

export const makeRoleLinkedUsers = () => {
	return new RoleLinkedUsers(makeRolesRepository());
};
export const makeDeleteRole = () => {
	return new DeleteRole(makeRolesRepository());
};

export const makeGetUserSiteRoles = () => {
	return new GetUserSiteRoles(makeRolesRepository());
};

export const makeLinkUserSiteRole = () => {
	return new LinkUserSiteRole(makeRolesRepository());
};

export const makeUnlinkUserSiteRole = () => {
	return new UnlinkUserSiteRole(makeRolesRepository());
};

export const makeGetWorkingSiteAvailable = () => {
	return new GetAvailableWorkingSites(makeRolesRepository());
};

export const makeGetAvailableSites = () => {
	return new GetAvailableSites(makeRolesRepository());
};
