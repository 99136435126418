import UseCase from '../../../core/app/usecase';
import { UserSiteRole } from '../../entities/user';
import RolesRepository from '../../repositories/rolesRepository';

interface Args {
	userId: string;
	siteId: string;
	roleId: string;
	companyId: string;
}

class LinkUserSiteRole implements UseCase<Args, UserSiteRole> {
	constructor(private repository: RolesRepository) {}

	async Call({ companyId, userId, siteId, roleId }: Args): Promise<UserSiteRole> {
		return await this.repository.linkUserSiteRole(companyId, userId, siteId, roleId);
	}
}

export default LinkUserSiteRole;
