import ActionButton, { ActionButtonProps } from "./ActionButton";
import { Badge, Box, Icon, Tooltip } from "@chakra-ui/react";
import { FaRegEdit } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const AddFileButton = ({
  onClick,
  filesCount = 0,
  justFile = true,
  tooltipLabal
}: ActionButtonProps & { filesCount?: number; justFile?: boolean, tooltipLabal?: string }) => {
  const { t } = useTranslation("documents");

  return (
    <Tooltip
      label={tooltipLabal ? tooltipLabal : t("editDocument", { ns: "documents" })}
      aria-label="Preview"
    >
      <span>
        <ActionButton
          aria-label="add file"
          onClick={onClick}
          icon={
            <Box position={"relative"} pl={!filesCount && 2}>
              {filesCount > 0 && (
                <Badge
                  bg="rgba(50, 162, 219, .8)"
                  color="white"
                  position="relative"
                  top="4px"
                  right="-5px"
                >
                  {filesCount}
                </Badge>
              )}
              <Icon as={FaRegEdit} fontSize="22px" marginTop={1.5} />
            </Box>
          }
        />
      </span>
    </Tooltip>
  );
};

export default AddFileButton;
