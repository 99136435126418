import UseCase from '../../../core/app/usecase';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import Specialization from '../../entities/specialization';
import RequirementRepository from '../../repositories/requirementRepository';

interface Args {
	companyId: string;
	specialization: Specialization;
	requirementType: RequirementSubject;
}

class CreateSpecialization implements UseCase<Args, Specialization> {
	constructor(private repository: RequirementRepository) {}

	async Call(args: Args): Promise<Specialization> {
		return await this.repository.createSpecialization(args.companyId, args.specialization, args.requirementType);
	}
}

export default CreateSpecialization;
