import UseCase from '../../../core/app/usecase';
import { AuthRepository, makeAuthRepository } from '../../repositories/authRepository';
import { LoginResponse } from '../../../infrastructure/responses/auth';

interface Args {
	email: string;
	password: string;
}

export class PerformLogin implements UseCase<Args, LoginResponse> {
	constructor(private repository: AuthRepository) {}

	async Call({ email, password }: Args): Promise<LoginResponse> {
		const response = await this.repository.login(email, password);
		localStorage.setItem('access_token', response.token.access_token);
		localStorage.setItem('refresh_token', response.token.refresh_token);
		return response;
	}
}

export const makePerformLogin = (): PerformLogin => {
	return new PerformLogin(makeAuthRepository());
};
