import Document from '../../entities/document';
import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';

interface Args {
	siteId: string;
	companyId: string;
	documents: string[];
}

class CreateSiteDocument implements UseCase<Args, void> {
	constructor(private repository: SiteRepository) {} // FIXME: move to DocumentsRepository?

	async Call({ companyId, siteId, documents }: Args): Promise<void> {
		return await this.repository.createDocument(companyId, siteId, documents);
	}
}

export default CreateSiteDocument;
