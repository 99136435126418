import { Flex, Text, TextProps } from "@chakra-ui/react";
import {
  ActionParameter,
  Author,
  CommunicationAction,
} from "../../../domain/entities/documentComment";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import DOMPurify from "dompurify";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";

type Props = {
  action?: CommunicationAction;
  date?: string;
  author?: Author;
  messageParameter?: ActionParameter;
} & TextProps;

const CommentAction = ({ action, messageParameter, date, author }: Props) => {
  const { t } = useTranslation("documents");

  let actionLabel = "";
  let dictionaryKey = "";
  let statusBackgroundColor = "";
  let sanitizedHtmlContent = "";

  switch (messageParameter.result) {
    case ResourceDocumentEvaluationState.AVAILABLE:
      statusBackgroundColor = COLORS.sikuroBlue;
      break;
    case ResourceDocumentEvaluationState.NOT_AVAILABLE:
      statusBackgroundColor = COLORS.red;
      break;
    default:
      statusBackgroundColor = COLORS.darkYellow;
  }
  switch (action) {
    case CommunicationAction.PENDING_EVALUATION_APPROVED: {
      dictionaryKey = "logs." + CommunicationAction.PENDING_EVALUATION_APPROVED;
      actionLabel = t(dictionaryKey, { user: author?.name ?? t("system") });

      sanitizedHtmlContent = DOMPurify.sanitize(
        t(dictionaryKey, {
          user: author?.name ?? t("system"),
          color: COLORS.sikuroBlue,
        }),
      );

      break;
    }
    case CommunicationAction.REQUIREMENT_EVALUATION_CREATED: {
      dictionaryKey =
        "logs." + CommunicationAction.REQUIREMENT_EVALUATION_CREATED;
      actionLabel = t(dictionaryKey, {
        user: author?.name ?? t("system"),
        documentName: messageParameter.document_id,
      });

      sanitizedHtmlContent = DOMPurify.sanitize(
        t(dictionaryKey, {
          user: author?.name ?? t("system"),
          documentName: messageParameter.document_type_name,
          color: statusBackgroundColor,
          status: t(`evaluation.document.${messageParameter.result}`, {
            ns: "common",
          }),
        }),
      );
      break;
    }
    case CommunicationAction.DOCUMENT_EVALUATION_CREATED: {
      dictionaryKey = "logs." + CommunicationAction.DOCUMENT_EVALUATION_CREATED;
      sanitizedHtmlContent = DOMPurify.sanitize(
        t(dictionaryKey, {
          user: author?.name ?? t("system"),
          documentName: messageParameter.document_type_name,
          color: statusBackgroundColor,
          status: t(`evaluation.document.${messageParameter.result}`, {
            ns: "common",
          }),
        }),
      );
      break;
    }
    case CommunicationAction.RESOURCE_EVALUATION_CREATED: {
      dictionaryKey = "logs." + CommunicationAction.RESOURCE_EVALUATION_CREATED;
      sanitizedHtmlContent = DOMPurify.sanitize(
        t(dictionaryKey, {
          user: author?.name ?? t("system"),
          resourceName: messageParameter.document_type_name,
          color: statusBackgroundColor,
          status: t(`evaluation.resource.${messageParameter.result}`, {
            ns: "common",
          }),
        }),
      );
      break;
    }
    case CommunicationAction.DOCUMENT_UPDATED: {
      dictionaryKey = "logs." + CommunicationAction.DOCUMENT_UPDATED;
      sanitizedHtmlContent = DOMPurify.sanitize(
        t(dictionaryKey, {
          user: author?.name ?? t("system"),
          documentName: messageParameter.document_type_name,
        }),
      );
      break;
    }
    case CommunicationAction.FILE_ADDED: {
      dictionaryKey = "logs." + CommunicationAction.FILE_ADDED;
      sanitizedHtmlContent = DOMPurify.sanitize(
        t(dictionaryKey, {
          user: author?.name ?? t("system"),
          documentName: messageParameter.document_type_name,
          fileName: messageParameter.file_name,
        }),
      );
      break;
    }
    case CommunicationAction.FILE_REMOVED: {
      dictionaryKey = "logs." + CommunicationAction.FILE_REMOVED;
      sanitizedHtmlContent = DOMPurify.sanitize(
        t(dictionaryKey, {
          user: author?.name ?? t("system"),
          documentName: messageParameter.document_type_name,
          fileName: messageParameter.file_name,
        }),
      );
      break;
    }
    default: {
      sanitizedHtmlContent = "";
      break;
    }
  }

  return (
    <>
      <Flex flexDirection={"row"} justifyContent={"space-between"}>
        <Flex flexDirection={"row"}>
          <Text
            fontSize={"xs"}
            dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
          ></Text>
        </Flex>
        {date && (
          <Text fontSize={"xs"} flexShrink={0} marginLeft={5}>
            {date}
          </Text>
        )}
      </Flex>
    </>
  );
};

export default CommentAction;
