import { injectable } from 'tsyringe';
import { LoginResponse, UpdatePasswordResponse } from '../responses/auth';
import { ApiService, postRequest } from '../utilities/apiService';
import { AuthRepository } from '../../domain/repositories/authRepository';
import { SignUpCompany, SignUpUser } from '../../domain/interactors/auth/args';
import { removeEmptyProperties } from '../../utils';

@injectable()
export class ServerAuthRepository implements AuthRepository {
	constructor(private apiService: ApiService) {}

	async createPersonalAccount(payload: SignUpUser): Promise<boolean> {
		return this.createAccount(payload, 'personal');
	}

	async userAlreadyRegistered(token: string, company: string): Promise<{ already_registered: string }> {
		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${company}/users/token/${token}`;
		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (!response.ok) {
				if (response.status === 417) {
					const error = 'invalidToken';
					return Promise.reject(new Error(error));
				} else if (response.status === 403 || response.status === 401) {
					const error = 'unauthorized';
					return Promise.reject(new Error(error));
				} else if (response.status === 416) {
					const error = 'tokenExpired';
					return Promise.reject(new Error(error));
				} else if (response.status === 404) {
					const error = 'notFound';
					return Promise.reject(new Error(error));
				} else if (response.status === 500) {
					const error = 'serverError';
					return Promise.reject(new Error(error));
				} else {
					const { message } = await response.json();
					return Promise.reject(new Error(message));
				}
			}

			return await response.json();
		} catch (e) {
			return Promise.reject(e);
		}
	}

	async createBusinessAccount(payload: SignUpCompany): Promise<boolean> {
		const fieldsToClear = ['employer', 'rls', 'rspp', 'doctor', 'contact'];
		const shouldRemoveKey = (key: string) => fieldsToClear.includes(key);
		const filteredEntries = Object.entries(payload).filter(([key, value]) => {
			return !shouldRemoveKey(key);
		});
		const filteredObject = Object.fromEntries(filteredEntries);
		const body = payload.businessType === 'self_employed' ? filteredObject : payload;
		return this.createAccount(body as SignUpCompany, 'business');
	}

	async login(email: string, password: string): Promise<LoginResponse> {
		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/auth/login`;

		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
				body: JSON.stringify({ email, password }),
			});

			if (!response.ok) {
				if (response.status === 404) {
					const error = 'error.invalid-payload';
					return Promise.reject(new Error(error));
				} else {
					const { message } = await response.json();
					return Promise.reject(new Error(message));
				}
			}

			return await response.json();
		} catch (e) {
			return Promise.reject(new Error('error.wrong-credentials'));
		}
	}

	async requestPasswordReset(email: string): Promise<boolean> {
		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/auth/request-password-reset`;

		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
				body: JSON.stringify({ email }),
			});

			if (!response.ok) {
				const { message } = await response.json();
				return Promise.reject(new Error(message));
			}

			return Promise.resolve(true);
		} catch (e) {
			return Promise.reject(new Error('error.cannot-request-password-reset'));
		}
	}

	async loginWithToken(refresh_token: string, tenant: string = null): Promise<LoginResponse> {
		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/auth/login-with-token`;
		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ refresh_token, ...(tenant ? { tenant } : {}) }),
			});

			if (!response.ok) {
				const { message } = await response.json();
				return Promise.reject(new Error(message));
			}

			return await response.json();
		} catch (e) {
			return Promise.reject(new Error('error.wrong-credentials'));
		}
	}

	// Private.
	private createAccountPayload(payload: SignUpUser | SignUpCompany): string {
		const input = {
			email: payload.email,
			password: payload.password,
			passwordConfirm: payload.confirmPassword,
			privacyPolicy: payload.privacyPolicy,
			newsletter: payload.newsletter,
		};
		if (payload['token']) {
			input['token'] = payload['token'];
		}

		if (payload['firstName']) {
			input['firstName'] = payload['firstName'];
		}

		if (payload['lastName']) {
			input['lastName'] = payload['lastName'];
		}

		if (payload['businessName']) {
			input['businessName'] = payload['businessName'];
		}

		if (payload['businessSize']) {
			input['businessSize'] = payload['businessSize'];
		}
		if (payload['businessType']) {
			input['businessType'] = payload['businessType'];
		}
		if (payload['vat']) {
			input['vat'] = payload['vat'];
			input['fiscalCode'] = payload['vat'];
		}

		if (payload['vatCountry']) {
			input['vatCountryCode'] = payload['vatCountry'];
		}

		if (payload['employer']) {
			input['employer'] = payload['employer'];
		}

		if (payload['rls']) {
			input['rls'] = payload['rls'];
		}

		if (payload['rspp']) {
			input['rspp'] = payload['rspp'];
		}

		if (payload['doctor']) {
			input['doctor'] = payload['doctor'];
		}

		if (payload['contact']) {
			input['contact'] = payload['contact'];
		}

		if (payload['pec']) {
			input['pec'] = payload['pec'];
		}
		if (payload['ccnl']) {
			input['ccnl'] = payload['ccnl'];
		}
		if (payload['licenseType']) {
			input['licenseType'] = payload['licenseType'];
		}
		return JSON.stringify(input);
	}

	async updatePassword(oldPassword: string, password: string, confirmPassword: string): Promise<UpdatePasswordResponse> {
		try {
			const response = await this.apiService.fetchWithToken(
				`${process.env.REACT_APP_SERVER_API_ENDPOINT}/auth/update-password`,
				postRequest(JSON.stringify({ currentPassword: oldPassword, password, confirmPassword })),
			);

			if (!response.ok) {
				const { message } = await response.json();
				return Promise.reject(new Error(message));
			}

			return Promise.resolve({ message: 'Success' });
		} catch (error) {
			return Promise.reject(new Error(error));
		}
	}

	private async createAccount(payload: SignUpUser | SignUpCompany, target: 'personal' | 'business'): Promise<boolean> {
		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/auth/signup/${target}`;
		const cleanPayload = (payload as SignUpCompany).businessType === 'self_employed' ? removeEmptyProperties(payload) : payload;
		const request = this.createAccountPayload(cleanPayload as SignUpCompany);
		try {
			const response = await fetch(url, {
				body: request,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (!response.ok) {
				if (response.status === 417) {
					const error = 'invalidToken';
					return Promise.reject(new Error(error));
				} else if (response.status === 416) {
					const error = 'tokenExpired';
					return Promise.reject(new Error(error));
				} else if (response.status === 422) {
					const error = 'invalidAccount';
					return Promise.reject(new Error(error));
				}
				const { message } = await response.json();
				return Promise.reject(new Error(message));
			}
		} catch (e) {
			return Promise.reject(new Error('error.create-account-failure'));
		}

		return Promise.resolve(true);
	}

	async getBusinessTypes(): Promise<string[]> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/auth/available-business-types`);
		const result = await response.json();
		return result;
	}
}
