import UseCase from '../../../../core/app/usecase';
import { ResourceDocumentEvaluationState } from '../../../entities/resourceDocumentEvaluationState.enum';
import VehicleRepository from '../../../repositories/vehicleRepository';
import { GetSitesFilter } from "../../../repositories/siteRepository";

type RepropagateEvaluationVehicleDocumentParameters = {
	companyId: string;
	resourceId: string;
	documentId: string;
	siteIds?: string[];
	selectAll?: boolean;
};

/**
 * Returns the list of all the existing Document.
 */
class RepropagateEvaluationVehicleDocument implements UseCase<RepropagateEvaluationVehicleDocumentParameters, void> {
	constructor(private repository: VehicleRepository) {}

	async Call({
		companyId,
		resourceId,
		documentId,
		siteIds,
		selectAll
	}: RepropagateEvaluationVehicleDocumentParameters): Promise<void> {
		return await this.repository.repropagateEvaluationVehicleDocument(
			companyId,
			resourceId,
			documentId,
			siteIds,
			selectAll,
		);
	}
}

export default RepropagateEvaluationVehicleDocument;
