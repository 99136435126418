import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Tag, TagLabel, TagLeftIcon, Tooltip } from "@chakra-ui/react";
import {
  BsArrowLeftRight,
  BsBoxArrowInRight,
  BsBoxArrowLeft,
} from "react-icons/bs";
import { BadgeReaderDirection as Direction } from "../../../domain/entities/badgeReaderDirection.enum";
import { BadgeReaderGate } from "../../../domain/entities/badgeReaderGate.enum";

interface Props {
  direction: Direction;
  releConfiguration?: BadgeReaderGate;
}

const BadgeReaderDirection: FC<Props> = ({ direction, releConfiguration }) => {
  const { t } = useTranslation("badgeReaders");
  const icon = (): ReactNode => {
    switch (direction) {
      case Direction.ENTRY:
        return <TagLeftIcon as={BsBoxArrowInRight} />;
      case Direction.EXIT:
        return <TagLeftIcon as={BsBoxArrowLeft} />;
      case Direction.BIDIRECTIONAL:
        return <TagLeftIcon as={BsArrowLeftRight} />;
    }
  };

  return (
    <Tooltip
      label={
        direction === Direction.BIDIRECTIONAL && releConfiguration
          ? t(
              releConfiguration === BadgeReaderGate.R1ER2U
                ? "R1E-R2U"
                : "R1U-R2E",
            )
          : t(direction)
      }
    >
      <Tag>
        {icon()}
        <TagLabel>
          {direction === Direction.BIDIRECTIONAL && releConfiguration
            ? t(
                releConfiguration === BadgeReaderGate.R1ER2U
                  ? "R1E-R2U"
                  : "R1U-R2E",
              )
            : t(direction)}
        </TagLabel>
      </Tag>
    </Tooltip>
  );
};

export default BadgeReaderDirection;
