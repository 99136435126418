import UseCase from '../../../core/app/usecase';
import DocumentComment from '../../entities/documentComment';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import AuditRepository from '../../repositories/auditRepository';

interface Args {
	companyId: string;
	siteId?: string;
	requirementId: string;
	requirementSubject: DocumentTypeCategory;
	resourceId: string;
}

class GetAuditNote implements UseCase<Args, DocumentComment> {
	constructor(private repository: AuditRepository) {}
	async Call({ companyId, siteId, requirementId, requirementSubject, resourceId }: Args): Promise<DocumentComment> {
		return this.repository.getAuditNote(companyId, requirementId, siteId, requirementSubject, resourceId);
	}
}

export default GetAuditNote;
