import { FC, useState } from "react";
import StateTag from "../../Common/StateTag";
import { useTranslation } from "react-i18next";
import Supplier from "../../../../domain/entities/supplier";
import { Thead, Tbody, Td, Tr, Th } from "@chakra-ui/react";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { SupplierEvaluationState } from "../../../../domain/entities/supplierEvaluationState.enum";
import { GetWorkingSiteFilter } from "../../../../domain/repositories/supplierRepository";
import { useNavigate } from "react-router-dom";
import { SupplierStatus } from "../../../../domain/entities/supplierStatus.enum";
import { Alert } from "../../../screens/Common/Alert";
import SimpleTable from "../../Common/table/SimpleTable";
import { SiteState } from "../../../../domain/entities/site";

export type Props = {
  sites: Array<Supplier>;
  isFetching: boolean;
  filter?: GetWorkingSiteFilter;
  updateFilter?: (field: keyof GetWorkingSiteFilter, value: string) => void;
  sort?: SortMeta;
  setSort?: (sort: SortMeta) => void;
  autosize?: boolean;
};

const WorkingSitesTable: FC<Props> = ({
  sites,
  isFetching,
  filter,
  updateFilter,
  sort,
  setSort,
  autosize,
}) => {
  const { t } = useTranslation("sites");
  const navigate = useNavigate();
  const [showNavigationAlert, setShowNavigationAlert] =
    useState<boolean>(false);

  const columns: {
    field: keyof GetWorkingSiteFilter;
    type: "text" | "select";
    options?: Record<string, string>;
  }[] = [
    { field: "name", type: "text" },
    { field: "company", type: "text" },
    { field: "address", type: "text" },
    { field: "status", type: "select", options: SiteState },
    {
      field: "evaluationResult",
      type: "select",
      options: SupplierEvaluationState,
    },
    { field: "invitationStatus", type: "select", options: SupplierStatus },
  ];

  const getTranslationContext = (value: keyof GetWorkingSiteFilter) => {
    if (value === "evaluationResult") return "combinedEvaluation";
    if (value === "status") return "siteStatus";
    return "supplierStatus";
  };

  return (
    <div>
      <SimpleTable
        autosize={autosize}
        tableId="working-sites-table"
        isLoading={isFetching}
        showEmptyText={sites?.length === 0}
        emptyText={t("noSiteEnabled", { ns: "sites" })}
      >
        <Thead>
          <Tr bg={"gray.300"}>
            {columns.map((c) => (
              <Th key={c.field}>
                <TableColumnHeader
                  text={t(`columns.${c.field}`)}
                  filter={{
                    isActive: !!filter[c.field],
                    component: (
                      <ColumnFilterComponent
                        type={c.type}
                        value={filter[c.field]}
                        namespace="enum"
                        optionsTranslationContext={getTranslationContext(
                          c.field
                        )}
                        updateFilter={(v) => updateFilter(c.field, v as string)}
                        selectOptions={c.options}
                      />
                    ),
                  }}
                  sort={{
                    handler: (direction) =>
                      setSort({ field: c.field, direction }),
                    direction:
                      sort && sort.field === c.field ? sort.direction : null,
                  }}
                />
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {sites?.map((site) => (
            <Tr
              key={site.site?.id}
              onClick={() => {
                site.status !== SupplierStatus.PENDING
                  ? navigate(`/workingsites/${site?.site?.id}/details/`)
                  : setShowNavigationAlert(true);
              }}
              cursor="pointer"
            >
              <Td>{site.site?.name}</Td>
              <Td>{site.company?.name}</Td>
              <Td>{site.site?.address}</Td>
              <Td>
                <StateTag value={site?.site?.state} type="siteStatus" />
              </Td>
              <Td>
                <StateTag
                  value={
                    site.evaluationResult ?? SupplierEvaluationState.UNEVALUATED
                  }
                  type="supplierEvaluation"
                />
              </Td>
              <Td>
                <StateTag value={site?.status} type="supplierStatus" />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </SimpleTable>
      {showNavigationAlert && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t("navigationBlocked", { ns: "sites" })}
          variant="info"
          onClose={() => {
            setShowNavigationAlert(false);
          }}
        />
      )}
    </div>
  );
};

export default WorkingSitesTable;
