import Machine from '../../../entities/machine';
import UseCase from '../../../../core/app/usecase';
import MachineRepository from '../../../repositories/machineRepository';
import CreateMachineParameters from './createMachineParameter';

class CreateMachine implements UseCase<CreateMachineParameters, Machine> {
	constructor(private repository: MachineRepository) {}

	async Call({ companyId, machine, photo }: CreateMachineParameters): Promise<Machine> {
		return await this.repository.createMachine(companyId, machine, photo);
	}
}

export default CreateMachine;
