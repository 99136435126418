import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import CustomInputDate from "./CustomDateInput/CustomDateInput";
import { parse, startOfDay } from "date-fns";
import { getDateFormat } from "../../../../utils";

type DocumentExpirationDateProps = {
  documentDuration?: number;
  expiresAt?: Date;
  updateExpirationDate?: (date: Date) => void;
  openPopover?: (close: boolean) => void;
  setAction?: (value: null) => void;
  isDisabled: boolean;
  showNoExpiration?: boolean;
  noExpiration?: boolean;
  setNoExpiration?: (value: boolean) => void;
  // this flag establish if we are showing the popover or the entire modal,
  // when we show the popover we will show even the buttons to save and cancel the action
  // when this is false these actions will be managed from the parent component
  showButtons?: boolean;
};

export enum DateCalculationType {
  ISSUANCE = "Calculate from issuance",
  MANUAL = "Insert date manually",
  NOEXPIRATION = "No expiration",
}

const DocumentExpirationDate = ({
  documentDuration,
  expiresAt,
  updateExpirationDate,
  openPopover,
  setAction,
  isDisabled,
  showNoExpiration,
  noExpiration,
  setNoExpiration,
  showButtons,
}: DocumentExpirationDateProps) => {
  const { t } = useTranslation("documents");
  const [newDate, setNewDate] = useState<string>();
  const [showErrorDate, setShowErrorDate] = useState(false);
  const [invalidDate, setInvalidDate] = useState<string>();
  const { register, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      dateCalculationType: DateCalculationType.MANUAL,
      expirationDate: expiresAt ? new Date(expiresAt) : null,
    },
  });
  const expirationDateType = watch("dateCalculationType");

  const handleExpirationDate = (data: {
    dateCalculationType: string;
    expirationDate: Date | string;
  }) => {
    let expiresDate = undefined;

    if (!isDisabled) {
      const fullInfo = {
        ...data,
        expirationDate: parse(newDate, getDateFormat(), new Date()),
      };
      if (isNaN(fullInfo.expirationDate.getDay())) {
        // check for valid date
        if (!showButtons) {
          updateExpirationDate(undefined);
        }
        return;
      }
      expiresDate = dateTypeCalculation(fullInfo.expirationDate as Date);
    }

    updateExpirationDate(expiresDate);
    openPopover?.(false);
    if (showButtons) {
      reset();
      setAction(null);
    }
    setInvalidDate(null);
    setShowErrorDate(false);
  };

  const resetExpirationDate = () => {
    setAction(null);
    openPopover?.(false);
    setInvalidDate(null);
    setShowErrorDate(false);
    reset();
  };

  const dateTypeCalculation = (val: Date) => {
    const date = new Date(val);
    if (
      expirationDateType === DateCalculationType.ISSUANCE &&
      documentDuration
    ) {
      date.setMonth(date.getMonth() + documentDuration);
    }
    return date;
  };

  useEffect(() => {
    if (!showButtons) {
      handleExpirationDate({
        dateCalculationType: expirationDateType,
        expirationDate: newDate,
      });
    }
  }, [newDate]);

  return (
    <Flex flexDirection={"column"} width={"100%"} overflow={"unset"}>
      {showNoExpiration && (
        <Flex flexDirection={"row"}>
          <Text fontSize={"14px"} marginTop={2}>
            {t("disableExpiration")}:
          </Text>
          <Checkbox
            marginLeft={2}
            marginTop={2}
            borderColor={"gray"}
            isChecked={noExpiration}
            onChange={() => {
              setNoExpiration(!noExpiration);
            }}
          />
        </Flex>
      )}
      <form onSubmit={handleSubmit(handleExpirationDate)}>
        <FormControl isRequired marginTop={2}>
          <FormLabel fontSize={14} fontWeight={400}>
            {t("dateCalculationType")}
          </FormLabel>
          <Select {...register("dateCalculationType")} isDisabled={isDisabled}>
            {documentDuration && (
              <option value={DateCalculationType.ISSUANCE}>
                {t("calculateExpiresAt")}{" "}
                {t("addingDocumentTypeDuration", {
                  duration: documentDuration,
                })}
              </option>
            )}
            <option value={DateCalculationType.MANUAL}>
              {t("insertManually")}
            </option>
          </Select>
        </FormControl>
        <FormControl isRequired marginTop={2}>
          <FormLabel fontSize={14} fontWeight={400}>
            {t(
              expirationDateType === DateCalculationType.ISSUANCE
                ? "emissionDate"
                : "expiresAt",
            )}
          </FormLabel>
          <CustomInputDate
            value={expiresAt && expiresAt.toString()}
            onChange={setNewDate}
            minDate={
              expirationDateType === DateCalculationType.ISSUANCE
                ? null
                : startOfDay(new Date())
            }
            maxDate={new Date("9999-12-31")}
            name={"otp"}
            hasError={setShowErrorDate}
            setValue={setInvalidDate}
            isDisabled={isDisabled}
          />
        </FormControl>
        {showErrorDate && (
          <Text
            color={"#E53E3E"}
            fontSize="0.875rem"
            py="2"
            lineHeight={"normal"}
          >
            {t("invalidDateError", { ns: "common", date: invalidDate })}
          </Text>
        )}
        {showButtons && (
          <Flex justifyContent={"end"} gap={2} padding={2}>
            <Button
              colorScheme="blue"
              type="submit"
              isDisabled={showErrorDate || (!newDate && !isDisabled)}
            >
              {t("save", { ns: "common" })} {showErrorDate}
            </Button>
            <Button colorScheme="red" onClick={() => resetExpirationDate()}>
              {t("cancel", { ns: "common" })}
            </Button>
          </Flex>
        )}
      </form>
    </Flex>
  );
};

export default DocumentExpirationDate;
