import { FC, useState } from "react";
import ErrorMessages from "./ErrorMessages";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react";

export type Props = {
  id: string;
  label: string;
  width?: string;
  marginTop?: number;
  defaultValue?: string;
  isRequired?: boolean;
  errorMessages?: string | Array<string>;

  onChange: (fieldName: string, value: string) => void;
};

const InputAnimatedDatePicker: FC<Props> = ({
  id,
  label,
  marginTop,
  defaultValue,
  width,
  isRequired,
  errorMessages,
  onChange,
}) => {
  const [_, setEdited] = useState(defaultValue);

  const handleOnChange = (e) => {
    setEdited(e.target.value);
    onChange(id, e.target.value);
  };

  return (
    <Box marginTop={marginTop} width={width}>
      <FormControl
        id={id}
        variant="floating"
        borderColor="gray.400"
        marginTop={2}
        isInvalid={errorMessages?.length > 0}
        isRequired={isRequired}
      >
        <InputGroup>
          <Input
            type="date"
            color="black"
            backgroundColor="white"
            defaultValue={defaultValue}
            onChange={handleOnChange}
            max="9999-12-31"
          />
        </InputGroup>
        <FormLabel backgroundColor="white" borderRadius={4} color="gray.500">
          {label}
        </FormLabel>
        <ErrorMessages messages={errorMessages} />
      </FormControl>
    </Box>
  );
};

export default InputAnimatedDatePicker;
