import UseCase from '../../../../core/app/usecase';
import ToolRepository from '../../../repositories/toolRepository';

type Args = {
	companyId: string;
	toolId: string;
};

class RestoreTool implements UseCase<Args, void> {
	constructor(private repository: ToolRepository) {}

	async Call({ companyId, toolId }: Args): Promise<void> {
		return await this.repository.restoreTool(companyId, toolId);
	}
}

export default RestoreTool;
