import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { ResourceDocumentEvaluationState } from "../../entities/resourceDocumentEvaluationState.enum";
import { GetSitesFilter } from "../../repositories/siteRepository";

type Parameters = {
  companyId: string;
  supplierId: string;
  documentId: string;
  result: ResourceDocumentEvaluationState,
  expiresAt?: Date,
  noEvaluationExpiration?: boolean,
  siteIds?: string[],
  selectAll?: boolean,
  filters?: GetSitesFilter
};

class EvaluateSupplierDocument implements UseCase<Parameters, void> {
  constructor(private repository: SupplierRepository) {}

  async Call({ companyId, supplierId, documentId, result, expiresAt, noEvaluationExpiration, siteIds,selectAll, filters }: Parameters): Promise<void> {
    await this.repository.evaluateSupplierDocument(companyId, supplierId, documentId,result, expiresAt, noEvaluationExpiration, siteIds,selectAll, filters);
  }
}

export default EvaluateSupplierDocument;
