import UseCase from '../../../core/app/usecase';
import SupplierRepository, { GetSupplierWorkersFilters } from '../../repositories/supplierRepository';
import { PaginatedResults, SortMeta } from '../../entities/interfaces/paginatedResults';
import { SupplierWorker } from '../../entities/siteWorker';
type ARGS = {
	companyId: string;
	supplierId: string;
	page: number;
	perPage?: number;
	filter?: GetSupplierWorkersFilters;
	sort?: SortMeta;
};
class GetCompanySupplierWorkers implements UseCase<ARGS, PaginatedResults<SupplierWorker>> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, page, filter, sort }: ARGS): Promise<PaginatedResults<SupplierWorker>> {
		return await this.supplierRepository.getCompanySupplierWorkers(companyId, supplierId, page, 25, filter, sort);
	}
}

export default GetCompanySupplierWorkers;
