import UseCase from '../../../core/app/usecase';
import DocumentsPresetsRepository from '../../repositories/documentsPresetsRepository';
import DocumentPreset from '../../entities/documentPreset';
import { DocumentPresetTarget } from '../../entities/documentPresetTarget.enum';

type ARGS = { companyId: string; documentPresets: DocumentPreset[]; target: DocumentPresetTarget };

class UpdateDocumentPreset implements UseCase<ARGS, void> {
	constructor(private repository: DocumentsPresetsRepository) {}

	async Call(arg: ARGS): Promise<void> {
		return await this.repository.updateDocumentPreset(arg.companyId, arg.documentPresets, arg.target);
	}
}

export default UpdateDocumentPreset;
