import UseCase from '../../../core/app/usecase';
import { ResourceDocumentEvaluationState } from '../../entities/resourceDocumentEvaluationState.enum';
import DocumentRepository from '../../repositories/documentRepository';
import { GetSitesFilter } from "../../repositories/siteRepository";

type ARGS = {
	companyId: string;
	documentId: string;
	result: ResourceDocumentEvaluationState;
	expiresAt?: Date;
	noEvaluationExpiration?: boolean;
	target?: string;
	resourceId?: string;
	siteIds?: string[];
	selectAll?: boolean;
	filters?: GetSitesFilter;
};

class EvaluateGeneralDocument implements UseCase<ARGS, void> {
	private documentRepository: DocumentRepository;

	constructor(evaluationRepository: DocumentRepository) {
		this.documentRepository = evaluationRepository;
	}

	async Call({ companyId, documentId, result, expiresAt, noEvaluationExpiration, target, resourceId, siteIds, selectAll, filters }: ARGS): Promise<void> {
		return await this.documentRepository.evaluateGeneralDocument(
			companyId,
			documentId,
			result,
			expiresAt,
			noEvaluationExpiration,
			target,
			resourceId,
			siteIds,
			selectAll,
			filters
		);
	}
}

export default EvaluateGeneralDocument;
