import { COLORS } from "../../assets/theme/colors";
import { BsArrowsFullscreen } from "react-icons/bs";
import { Button, Tooltip } from "@chakra-ui/react";

const FullScreenButton = ({
  onClick,
  tooltipMessage,
}: {
  onClick: () => void;
  tooltipMessage?: string;
}) => (
  <Tooltip label={tooltipMessage} aria-label={tooltipMessage}>
    <span>
      <Button
        bgColor={COLORS.yellow}
        fontSize="2xl"
        width="30px"
        borderRadius="10px"
        p={1}
        onClick={onClick}
        aria-label="fullscreen"
        style={{
          position: "absolute",
          bottom: "30px",
          right: "30px",
          zIndex: 1,
          padding: "10px",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        <BsArrowsFullscreen />
      </Button>
    </span>
  </Tooltip>
);

export default FullScreenButton;
