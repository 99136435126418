import { injectable } from 'tsyringe';
import { DocumentTypeCategory } from '../../domain/entities/documentTypeCategory.enum';
import Specialization from '../../domain/entities/specialization';
import Variant from '../../domain/entities/variant';
import SpecializationRepository from '../../domain/repositories/specializationRepository';
import { ApiService } from '../utilities/apiService';

@injectable()
class ServerSpecializationRepository implements SpecializationRepository {
	constructor(private apiService: ApiService) {}

	async getSpecializations(companyId: string, type: DocumentTypeCategory, search?: string): Promise<Specialization[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/specializations?type=${type}${search ? `&search=${search}` : ''}`,
		);
		const specializations = await response.json();
		return specializations.results;
	}

	async createSpecialization(companyId: string, specialization: Specialization): Promise<Specialization> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/specializations`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(specialization),
		});
		return response.json();
	}

	async getSiteSpecializations(companyId: string, siteId: string, type: DocumentTypeCategory, search?: string): Promise<Variant[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirements/specializations?type=${type}${search ? `&search=${search}` : ''}`,
		);
		const data = await response.json();
		return data.results;
	}
}

export default ServerSpecializationRepository;