import React, { memo } from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import Staff, { StaffType } from "../../../domain/entities/staff";
import StaffTableView, { GetStaffFilters } from "../../components/Views/Company/StaffTableView";
import { useTranslation } from "react-i18next";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";

const CompanyStaffTabs = memo(
  ({
    staffType,
    onTabSelect,
    systemStaff,
    staff,
    systemStaffIsFetching,
    staffIsFetching,
    updateStaffIsLoading,
    deleteStaffIsLoading,
    systemStaffSort,
    staffSort,
    systemStaffFilter,
    staffFilter,
    setSystemStaffSort,
    setStaffSort,
    updateSystemFilter,
    updateFilter,
    updateSystemStaff,
    updateStaff,
    deleteStaff,
    memoizedEditRolesPermission,
  }: {
    staffType: StaffType;
    onTabSelect: (tabIndex: number) => void;
    systemStaff: Staff[];
    staff: Staff[];
    systemStaffIsFetching: boolean;
    staffIsFetching: boolean;
    updateStaffIsLoading: boolean;
    deleteStaffIsLoading: boolean;
    systemStaffSort: SortMeta;
    staffSort: SortMeta;
    systemStaffFilter: GetStaffFilters;
    staffFilter: GetStaffFilters;
    setSystemStaffSort: (sort: SortMeta) => void;
    setStaffSort: (sort: SortMeta) => void;
    updateSystemFilter: (
        field: keyof GetStaffFilters,
        value: string | string[],
      ) => void;
    updateFilter: (
        field: keyof GetStaffFilters,
        value: string | string[],
      ) => void;
    updateSystemStaff: (staffElement: Staff) => void;
    updateStaff: (staffElement: Staff) => void;
    deleteStaff: (staffId: string) => void;
    memoizedEditRolesPermission: any;
  }) => {

    const { t } = useTranslation("companies");

    return (
      <Tabs height="100%" defaultIndex={0} onChange={onTabSelect}>
        <TabList>
          <Tab width="50%">{t("staff.system")}</Tab>
          <Tab width="50%">{t("staff.custom")}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel key={StaffType.SYSTEM} paddingLeft={0} paddingRight={0}>
            <StaffTableView
              staffIsLoading={updateStaffIsLoading}
              deleteStaffIsLoading={deleteStaffIsLoading}
              staffType={staffType}
              staffs={systemStaff}
              isFetching={systemStaffIsFetching}
              sorting={systemStaffSort}
              filters={systemStaffFilter}
              updateStaffsSorting={setSystemStaffSort}
              updateStaffsFilter={updateSystemFilter}
              updateStaffElement={updateSystemStaff}
              canEditPermissions={memoizedEditRolesPermission}
              isTablet={true}
            />
          </TabPanel>
          <TabPanel key={StaffType.CUSTOM} paddingLeft={0} paddingRight={0}>
            <StaffTableView
              staffIsLoading={updateStaffIsLoading}
              deleteStaffIsLoading={deleteStaffIsLoading}
              staffType={staffType}
              staffs={staff}
              isFetching={staffIsFetching}
              sorting={staffSort}
              filters={staffFilter}
              updateStaffsSorting={setStaffSort}
              updateStaffsFilter={updateFilter}
              updateStaffElement={updateStaff}
              deleteStaffElement={deleteStaff}
              canEditPermissions={memoizedEditRolesPermission}
              from={"company"}
              isTablet={true}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }
);

CompanyStaffTabs.displayName = "CompanyStaffTabs"

export default CompanyStaffTabs;