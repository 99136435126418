import UseCase from '../../../../core/app/usecase';
import { SiteWorker } from '../../../entities/siteWorker';
import SiteWorkerRepository, { GetWorkingSiteWorkersFilters } from '../../../repositories/siteWorkerRepository';
import { PaginatedMeta, PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';

type Parameters = {
	companyId: string;
	siteId: string;
	archived?: boolean;
	filter?: GetWorkingSiteWorkersFilters;
	sort?: SortMeta;
} & PaginatedMeta;

class GetWorkingSiteWorkers implements UseCase<Parameters, PaginatedResults<SiteWorker> | undefined> {
	constructor(private repository: SiteWorkerRepository) {}

	async Call({ companyId, siteId, sort, perPage, archived, filter, page }: Parameters): Promise<PaginatedResults<SiteWorker> | undefined> {
		return this.repository.getWorkingSiteWorkers(companyId, siteId, page, perPage, archived, filter, sort);
	}
}

export default GetWorkingSiteWorkers;
