import React from "react";
import {Controller, FieldValues, RegisterOptions, useFormContext} from "react-hook-form";
import {CreatableProps} from "react-select/creatable";
import BaseSelectField, {BaseSelectFieldProps} from "../BaseSelectField";

type HookSelectFieldProps<Option, IsMulti extends boolean> = Omit<BaseSelectFieldProps<Option, IsMulti>, "error"> & {
  rules?: Omit<RegisterOptions<FieldValues, string>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">;
  name: string
  selectProps: CreatableProps<any, IsMulti, undefined>
}
const HookSelectField = <Option extends { [key: string]: any }, IsMulti extends boolean>({
  name, rules, selectProps, ...baseProps
}: HookSelectFieldProps<Option, IsMulti>) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <BaseSelectField
          error={errors[name]?.message as string}
          {...field}
          {...baseProps}
          selectProps={{
            ...selectProps,
            ...field,
            onChange: (option) => {
              field.onChange(option)
              selectProps.onChange?.(option, undefined)
            }
          }}
        />
      )}

    />
  )
}

export default HookSelectField;