import { makeVehicleRepository } from './repositories';
import GetVehicles from '../../../../domain/interactors/resources/vehicles/getVehicles';
import CreateVehicle from '../../../../domain/interactors/resources/vehicles/createVehicle';
import UpdateVehicle from '../../../../domain/interactors/resources/vehicles/updateVehicle';
import DeleteVehicle from '../../../../domain/interactors/resources/vehicles/deleteVehicle';
import GetVehicle from '../../../../domain/interactors/resources/vehicles/getVehicle';
import GetVehicleDocuments from '../../../../domain/interactors/resources/vehicles/getVehicleDocuments';
import CreateVehicleDocument from '../../../../domain/interactors/resources/vehicles/createVehicleDocument';
import AddTypologyToVehicle from '../../../../domain/interactors/resources/vehicles/addTypologyToVehicle';
import RemoveTypologyFromVehicle from '../../../../domain/interactors/resources/vehicles/removeTypologyFromVehicle';
import UploadVehicles from '../../../../domain/interactors/resources/vehicles/uploadVehicles';
import CreatePropagableVehicleDocument from '../../../../domain/interactors/resources/vehicles/createPropagableVehicleDocument';
import EvaluateVehicleDocument from '../../../../domain/interactors/resources/vehicles/evaluateVehicleDocument';
import GetAvailableVehiclesCount from '../../../../domain/interactors/resources/vehicles/getAvailableVehiclesCount';
import RestoreVehicle from '../../../../domain/interactors/resources/vehicles/restoreVehicle';
import MigrateToMachine from '../../../../domain/interactors/resources/vehicles/migrateToMachine';
import RepropagateEvaluationVehicleDocument from '../../../../domain/interactors/resources/vehicles/repropagateEvaluationVehicleDocument';

export const makeGetVehicles = () => {
	return new GetVehicles(makeVehicleRepository());
};

export const makeRestoreVehicle = () => {
	return new RestoreVehicle(makeVehicleRepository());
};

export const makeGetAvailableVehiclesCount = () => {
	return new GetAvailableVehiclesCount(makeVehicleRepository());
};

export const makeGetVehicle = () => {
	return new GetVehicle(makeVehicleRepository());
};

export const makeCreateVehicle = () => {
	return new CreateVehicle(makeVehicleRepository());
};
export const makeUploadVehicles = () => {
	return new UploadVehicles(makeVehicleRepository());
};
export const makeUpdateVehicle = () => {
	return new UpdateVehicle(makeVehicleRepository());
};

export const makeDeleteVehicle = () => {
	return new DeleteVehicle(makeVehicleRepository());
};

export const makeGetVehicleDocuments = () => {
	return new GetVehicleDocuments(makeVehicleRepository());
};

export const makeCreateVehicleDocument = () => {
	return new CreateVehicleDocument(makeVehicleRepository());
};

export const makeCreatePropagableVehicleDocument = () => {
	return new CreatePropagableVehicleDocument(makeVehicleRepository());
};

export const makeAddTypologyToVehicle = () => {
	return new AddTypologyToVehicle(makeVehicleRepository());
};

export const makeRemoveTypologyFromVehicle = () => {
	return new RemoveTypologyFromVehicle(makeVehicleRepository());
};

export const makeEvaluateVehicleDocument = () => {
	return new EvaluateVehicleDocument(makeVehicleRepository());
};

export const makeRepropagateEvaluationVehicleDocument = () => {
	return new RepropagateEvaluationVehicleDocument(makeVehicleRepository());
};

export const makeMigrateToMachine = () => {
	return new MigrateToMachine(makeVehicleRepository());
};
