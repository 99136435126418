import File from './file';

export enum DocumentFileLogEntryAction {
	DELETED = 'deleted',
	CREATED = 'created',
	UPDATED = 'updated',
}

export type DocumentFileLogEntry = {
	file: File;
	author?: string;
	oldExpiresAt?: Date;
	expiresAt?: Date;
	fileName: string;
	name?: string;
	actionDate: Date;
	actionType: DocumentFileLogEntryAction;
	description: string;
	uri?: string;
};

export type DocumentActionLogEntry = {
	actionDate: Date;
	actionType: string;
	author: string;
	newDate: Date;
	oldDate?: Date;
	newIsPublic?: boolean;
	oldIsPublic?: boolean;
};
