import UseCase from '../../../core/app/usecase';
import Staff from '../../entities/staff';
import StaffRepository from '../../repositories/staffRepository';

interface Args {
	companyId: string;
	staffElement: Staff;
}

class UpdateSystemStaffElement implements UseCase<Args, void> {
	private staffRepository: StaffRepository;

	constructor(staffRepository: StaffRepository) {
		this.staffRepository = staffRepository;
	}

	async Call({ companyId, staffElement }: Args): Promise<void> {
		return await this.staffRepository.updateSystemStaffElement(companyId, staffElement);
	}
}

export default UpdateSystemStaffElement;
