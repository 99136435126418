import FormInput from "../../components/Common/FormInput";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  register: any;
  watch: any;
  errors: any;
}
const PasswordFields = ({ register, watch, errors }: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation("onboarding");
  return (
    <>
      <FormInput
        inputProps={register("password", {
          required: t("errors.password.required"),
          minLength: {
            value: 8,
            message: t("errors.password.too short"),
          },
          validate: {
            hasUppercase: (value) =>
              /[A-Z]+/.test(value) ||
              t("errors.password.must contain uppercase char"),
            hasLowercase: (value) =>
              /[a-z]+/.test(value) ||
              t("errors.password.must contain lowercase char"),
            hasNumber: (value) =>
              /\d+/.test(value) || t("errors.password.must contain number"),
            hasSpecialChar: (value) =>
              /[$%&#+\-_!?@]+/.test(value) ||
              t("errors.password.must contain special char"),
            notEmail: (value) => {
              const emailValue = watch("email");
              return (
                value !== emailValue ||
                t("errors.password.must not be your email")
              );
            },
          },
        })}
        label={t("ui.password")}
        isRequired
        error={errors.password?.message ?? false}
        type={showPassword ? "text" : "password"}
        actions={[
          {
            icon: showPassword ? <FaEyeSlash /> : <FaEye />,
            hint: t(showPassword ? "hide" : "slash", { ns: "common" }),
            handler: () => setShowPassword(!showPassword),
          },
        ]}
      />
      <FormInput
        inputProps={register("confirmPassword", {
          required: t("errors.password.not confirmed"),
          validate: {
            matchesPassword: (value) => {
              const passwordValue = watch("password");
              return (
                value === passwordValue ||
                t("errors.password.passwordDoesntMatch")
              );
            },
          },
        })}
        label={t("ui.repeat password")}
        isRequired
        error={errors.confirmPassword?.message ?? false}
        type={showPassword ? "text" : "password"}
      />
    </>
  );
};

export default PasswordFields;
