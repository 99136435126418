import { SupplierMachine } from '../../../domain/entities/siteMachine';
import { makeGetCompanySupplierMachine } from '../../../core/app/factory/suppliers/useCases';
import { RequirementSubject } from '../../../domain/entities/requirementSubject.enum';
import SupplierResourceViewModel from './SupplierResourceViewModel';
import { SiteMachineParams } from '../../../domain/entities/siteMachine';

class SupplierMachineViewModel extends SupplierResourceViewModel<SiteMachineParams> {
	constructor(
		public companyId: string,
		public supplierId: string,
		public resourceId: string,
	) {
		super(companyId, supplierId, resourceId, RequirementSubject.MACHINE);
	}
	async getSupplierResource(): Promise<SupplierMachine> {
		return await makeGetCompanySupplierMachine().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			resourceId: this.resourceId,
		});
	}
}

export default SupplierMachineViewModel;
