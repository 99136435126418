import { useForm, FormProvider } from "react-hook-form";
import { Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import AiTaskDocument from "../../../../domain/entities/aiTaskDocument";
import DocumentType from "../../../../domain/entities/documentType";
import Form from "../../Common/forms/Form";
import FormSelectField from "../../Common/forms/FormSelectField";
import { EditableFormFieldProps } from "../../Common/forms/FormField";
import { isValidDate } from "../../../../utils";
import { ReactElement, useEffect, useState } from "react";
import { COLORS } from "../../../assets/theme/colors";
import EditButton from "../../Common/table/EditButton";
import SaveButton from "../../Common/table/SaveButton";
import DiscardButton from "../../Common/table/DiscardButton";
import FormDateField from "../../Common/forms/FormDateField";

interface DocumentFormProps {
  file: AiTaskDocument;
  documentTypesForAi?: DocumentType[];
  selectedTaskFile?: AiTaskDocument;
  setSelectedTaskFile: (file: AiTaskDocument) => void;
  selectedTaskFileToEdit?: AiTaskDocument;
  setSelectedTaskFileToEdit: (file: AiTaskDocument) => void;
  updateFile: (updatedFile: AiTaskDocument) => void; // New prop to update the file
}

const DocumentFormCell = ({
  file,
  documentTypesForAi,
  selectedTaskFile,
  setSelectedTaskFile,
  selectedTaskFileToEdit,
  setSelectedTaskFileToEdit,
  updateFile,
}: DocumentFormProps) => {
  const { t } = useTranslation("documents");

  const [updatedFile, setUpdatedFile] = useState<AiTaskDocument>(file);

  const formMethods = useForm<AiTaskDocument>({
    defaultValues: file,
    mode: "all",
  });

  useEffect(() => {
    formMethods.reset(file);
  }, [file, formMethods]);

  const validateExpiresAtDate = (expiresAtDate?: Date) => {
    let message = undefined;
    if (expiresAtDate && !isValidDate(expiresAtDate)) {
      message = t("errors.invalidDate", { ns: "common" });
    }
    if (message) {
      formMethods.setError("expiresAt", { message });
      return message;
    }

    if (expiresAtDate) {
      //const updatedFile = { ...updatedFile, expiresAt: expiresAtDate };
      setUpdatedFile({ ...updatedFile, expiresAt: expiresAtDate });
    }

    formMethods.clearErrors("expiresAt");
    return true;
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    const selectedType = documentTypesForAi?.find(
      (type) => type.id === selectedId
    );

    if (selectedType) {
      //const updatedFile = { ...file, documentTypeId: selectedType.id };
      setUpdatedFile({ ...file, documentTypeId: selectedType.id });
    }
  };

  const handleFileSave = () => {
    updateFile(updatedFile);
  };

  const fields: Array<ReactElement<Omit<EditableFormFieldProps, "isEditing">>> =
    [
      <FormSelectField
        key={`documentType`}
        name="documentType"
        label={t("documentType", { ns: "documents" })}
        options={documentTypesForAi?.map((c) => ({ id: c.id, name: c.name }))}
        displayValue={
          documentTypesForAi.find((c) => c.id === updatedFile?.documentTypeId)
            ?.name ?? "-"
        }
        value={updatedFile?.documentTypeId}
        onChange={handleSelectChange}
        selectedBackgroundColor={COLORS.sikuroBlue}
        selectedTextColor="white"
        placeHolder={t("selectDocumentType", { ns: "documents" })}
      />,
      <FormDateField
        key={`expiresAt`}
        name="expiresAt"
        displayValue={updatedFile?.expiresAt?.toString()}
        label={t("expiresAt")}
        rules={{ validate: validateExpiresAtDate }}
        selectedBackgroundColor={COLORS.sikuroBlue}
        selectedTextColor="white"
      />,
    ];

  return (
    <FormProvider {...formMethods}>
      <Flex
        width={"100%"}
        paddingLeft={2}
        paddingRight={2}
        paddingTop={2}
        key={file.id}
        justifyContent={"space-between"}
        alignItems={"center"}
        color={selectedTaskFile?.id === file.id ? "white" : "unset"}
        backgroundColor={
          selectedTaskFile?.id === file.id ? COLORS.sikuroBlue : "unset"
        }
        onClick={() => setSelectedTaskFile(file)}
        cursor={"pointer"}
      >
        <Flex flexDirection={"column"} width={"100%"}>
          <Flex
            flexDirection={"row"}
            width={"100%"}
            alignItems={"center"}
            marginBottom={4}
          >
            <Text
              paddingLeft={4}
              fontSize={17}
              textColor={
                selectedTaskFileToEdit?.id === file.id ||
                selectedTaskFile?.id === file.id
                  ? "white"
                  : COLORS.sikuroBlue
              }
            >
              {file?.name}
            </Text>
            <Spacer />
            {selectedTaskFileToEdit?.id !== file.id && (
              <EditButton
                buttonColor={
                  selectedTaskFile?.id === file.id ? "white" : "black"
                }
                buttonWidth="34px"
                onClick={() => {
                  setSelectedTaskFile(file);
                  setSelectedTaskFileToEdit(file);
                }}
              />
            )}
            {selectedTaskFileToEdit?.id === file.id && (
              <Flex flexDirection={"row"}>
                <SaveButton
                  buttonColor={
                    selectedTaskFile?.id === file.id ? "white" : "black"
                  }
                  buttonWidth="34px"
                  iconSize={"20px"}
                  onClick={() => {
                    handleFileSave();
                    setSelectedTaskFileToEdit(undefined);
                  }}
                />
                <DiscardButton
                  buttonColor={
                    selectedTaskFile?.id === file.id ? "white" : "black"
                  }
                  buttonWidth="34px"
                  iconSize={"20px"}
                  onClick={() => {
                    setSelectedTaskFileToEdit(undefined);
                    setUpdatedFile(file);
                  }}
                />
              </Flex>
            )}
          </Flex>
          <Form
            fields={fields}
            isEditing={selectedTaskFileToEdit?.id === file.id}
            templateColumns="1fr"
          />
          <Divider width="100%" marginTop={4} />
        </Flex>
      </Flex>
    </FormProvider>
  );
};

export default DocumentFormCell;
