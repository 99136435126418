import { SiteResourcesViewModel } from '../../hooks/Site/useSiteResourcesViewModel';
import { makeGetWorkingSiteTools } from '../../../core/app/factory/siteTools/usecases';
import { SiteTool, SiteToolParams } from '../../../domain/entities/siteTool';
import SiteToolsViewModel from '../sites/SiteToolsViewModel';
import { GetWorkingSiteToolsFilters } from '../../../domain/repositories/siteToolRepository';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';

class WorkingSiteToolsViewModel extends SiteToolsViewModel implements SiteResourcesViewModel<SiteToolParams> {
	async getSiteResources(
		companyId: string,
		siteId: string,
		page: number,
		archived?: boolean,
		filter?: GetWorkingSiteToolsFilters,
		sort?: SortMeta,
	): Promise<PaginatedResults<SiteTool>> {
		return await makeGetWorkingSiteTools().Call({ companyId, siteId, page, perPage: 25, filter, sort, archived });
	}
}

export default WorkingSiteToolsViewModel;
