import { SiteChemicalParams, SupplierChemical } from '../../../domain/entities/siteChemical';
import { makeGetCompanySupplierChemical } from '../../../core/app/factory/suppliers/useCases';
import { RequirementSubject } from '../../../domain/entities/requirementSubject.enum';
import SupplierResourceViewModel from './SupplierResourceViewModel';

class SupplierChemicalViewModel extends SupplierResourceViewModel<SiteChemicalParams> {
	constructor(
		public companyId: string,
		public supplierId: string,
		public resourceId: string,
	) {
		super(companyId, supplierId, resourceId, RequirementSubject.CHEMICAL);
	}
	async getSupplierResource(): Promise<SupplierChemical> {
		return await makeGetCompanySupplierChemical().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			resourceId: this.resourceId,
		});
	}
}

export default SupplierChemicalViewModel;
