import UseCase from '../../../core/app/usecase';
import { ResourceDocumentEvaluationState } from '../../entities/resourceDocumentEvaluationState.enum';
import DocumentRepository from '../../repositories/documentRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	documentId: string;
	result: ResourceDocumentEvaluationState;
	expiresAt?: Date;
	noEvaluationExpiration?: boolean;
	target?: string;
	resourceId?: string;
};
class EvaluateSiteSupplierDocument implements UseCase<ARGS, void> {
	private documentRepository: DocumentRepository;

	constructor(evaluationRepository: DocumentRepository) {
		this.documentRepository = evaluationRepository;
	}

	async Call({ companyId, siteId, documentId, result, expiresAt, noEvaluationExpiration, target, resourceId }: ARGS): Promise<void> {
		return await this.documentRepository.evaluateSiteSupplierDocument(
			companyId,
			siteId,
			documentId,
			result,
			expiresAt,
			noEvaluationExpiration,
			target,
			resourceId,
		);
	}
}

export default EvaluateSiteSupplierDocument;
