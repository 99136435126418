import UseCase from '../../../core/app/usecase';
import RequirementsGroupRepository from '../../repositories/requirementsGroupRepository';

interface ARGS {
	companyId: string;
	requirementsGroupId: string;
	requirementsGroupName: string;
}

class CloneRequirementsGroup implements UseCase<ARGS, void> {
	private requirementsGroupRepository: RequirementsGroupRepository;

	constructor(requirementsGroupRepository: RequirementsGroupRepository) {
		this.requirementsGroupRepository = requirementsGroupRepository;
	}

	async Call(parameters: ARGS) {
		return await this.requirementsGroupRepository.cloneRequirementsGroup(
			parameters.companyId,
			parameters.requirementsGroupId,
			parameters.requirementsGroupName,
		);
	}
}

export default CloneRequirementsGroup;
