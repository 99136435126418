import React, {
  createContext,
  useReducer,
  useEffect,
  useState,
  ReactNode,
  useContext,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import User from "../../domain/entities/user";
import Company from "../../domain/entities/company";
import LoadingView from "../screens/Common/LoadingView";
import Permission from "../../domain/entities/permission";
import { ApiService } from "../../infrastructure/utilities/apiService";
import i18n from "../../i18n";
import AuthViewModel from "../viewmodels/users/AuithViewModel";

export enum PermissionCheck {
  All,
  None,
  Some,
}

interface AuthUser {
  user?: User;
  companyUser?: User;
  token?: Token;
  company: Company;
}

interface AuthContext {
  companyId?: string;
  generalPermissions: Permission[];
}

interface AuthSiteContext {
  siteId?: string;
  sitePermissions: Permission[];
}

interface AuthMethods {
  logout: () => void;
  updateContext: (companyId?: string, company?: Company) => Promise<void>;
  changeTenant: (company: Company) => Promise<void>;
  updateSiteContext: (siteId?: string) => Promise<void>;
  setLoginData: (data: AuthUser & AuthContext & AuthSiteContext) => void;
  hasPermissions: (list: string[], check?: PermissionCheck) => boolean;
  updateTenantPermissions: (userId?: string) => Promise<void>;
}
interface Token {
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

type AuthState = AuthUser & AuthContext & AuthSiteContext;

const AuthContext = createContext<AuthState & AuthMethods>(undefined);

interface Auth0JWTProviderProps {
  children: ReactNode;
  apiService: ApiService;
}

const initialState: AuthState = {
  user: undefined,
  token: undefined,
  company: null,
  companyId: undefined,
  generalPermissions: [],
  siteId: undefined,
  sitePermissions: [],
};

const reducer = (state: AuthState, action: any): AuthState => {
  switch (action.type) {
    case "SET_LOGIN_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_AUTH":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_CONTEXT":
      return {
        ...state,
        generalPermissions: action.payload.generalPermissions,
        companyId: action.payload.companyId,
        company: action.payload.company || state.company,
      };
    case "SET_SITE_CONTEXT":
      return {
        ...state,
        sitePermissions: action.payload.sitePermissions,
        siteId: action.payload.siteId,
      };
    case "LOGOUT":
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const Auth0JWTProvider = ({
  children,
  apiService,
}: Auth0JWTProviderProps) => {
  const navigate = useNavigate();
  const viewModel = new AuthViewModel();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [init, setInit] = useState(false);
  const [query] = useSearchParams();
  const token = query.get("token");
  const companyId = query.get("company");
  const [currentCompanyId, setCurrentCompanyId] = useState("");

  const setLoginData = (authData: AuthUser & AuthContext) => {
    const { token, user, companyUser, company, generalPermissions, companyId } =
      authData;
    dispatch({
      type: "SET_LOGIN_DATA",
      payload: {
        token,
        user,
        company,
        generalPermissions,
        companyId,
        companyUser,
      },
    });
    const lastUserLogged = localStorage.getItem("lastUserLogged");
    const parsedLastUserLogged = JSON.parse(lastUserLogged);
    if (parsedLastUserLogged && parsedLastUserLogged.id !== user.id) {
      localStorage.removeItem("lastTenantSelected");
    } else {
      const tenant = localStorage.getItem("lastTenantSelected");
      const parsedTenant = JSON.parse(tenant);
      if (parsedTenant) {
        updateContext(parsedTenant.id, parsedTenant, user?.id);
      } else {
        updateContext(companyId, company, user?.id);
      }
    }
    i18n.changeLanguage(user?.language);
    apiService.setToken(token?.access_token);
  };

  const changeTenant = async (company: Company) => {
    await updateContext(company.id, company);
    setCurrentCompanyId(company.id);
    localStorage.setItem("tenantId", company.id);
    localStorage.setItem("tenantLogo", company.logo);
    localStorage.setItem("tenantName", company.name);
    localStorage.setItem("lastTenantSelected", JSON.stringify(company));
    navigate("/overview");
  };

  const updateContext = async (
    companyId: string,
    company: Company,
    userId?: string,
  ) => {
    const generalPermissions = await viewModel.getUserGeneralPermissions(
      companyId,
      userId ?? state.user.id,
    );
    dispatch({
      type: "SET_CONTEXT",
      payload: { generalPermissions, companyId, company },
    });
    dispatch({ type: "SET_AUTH", payload: { company } });
  };

  const updateSiteContext = async (siteId?: string) => {
    const sitePermissions = siteId
      ? await viewModel.getUserSitePermissions(
          state.companyId,
          state.user.id,
          siteId,
        )
      : [];
    dispatch({
      type: "SET_SITE_CONTEXT",
      payload: { sitePermissions, siteId },
    });
  };

  const logout = () => {
    localStorage.removeItem("tenantId");
    localStorage.removeItem("tenantLogo");
    localStorage.removeItem("tenantName");
    localStorage.removeItem("ssupp_5515c1ee6ac350948fff3f9af35bce124e35c269");
    apiService.setToken(null);
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    dispatch({ type: "LOGOUT" });
    navigate("/?screen=sign-in");
  };

  const hasPermissions = (
    permissionTested: string[],
    check?: PermissionCheck
  ): boolean => {
    const checkPermission = (permission: string) => {
      const is_general_permission =
        ["sites:manage", "worksite:show"].includes(permission) ||
        !(
          permission.startsWith("sites:") || permission.startsWith("worksite:")
        );
      const permissions = is_general_permission
        ? state.generalPermissions
        : state.sitePermissions;
      return permissions.some((p) => p.name === permission);
    };

    switch (check) {
      case PermissionCheck.None:
        return permissionTested.every((p) => !checkPermission(p));
      case PermissionCheck.All:
        return permissionTested.every((p) => checkPermission(p));
      default:
        return permissionTested.some(checkPermission);
    }
  };

  const updateTenantPermissions = async (userId: string = null) => {
    if (!userId || userId === state.user.id) {
      const generalPermissions = await viewModel.getUserGeneralPermissions(
        state.companyId,
        state.user.id,
      );
      dispatch({
        type: "SET_CONTEXT",
        payload: { generalPermissions, companyId: state.companyId },
      });
    }
  };

  useEffect(() => {
    const getLoginDataSilently = async () => {
      try {
        const loginData = await viewModel.loginSilently();
        setLoginData({
          ...loginData,
          generalPermissions: loginData.permissions,
        });
        apiService.setToken(loginData?.token?.access_token);
        if (window.location.pathname === "/") {
          navigate("/overview");
        }
      } catch (e) {
        const currentPath = window.location.pathname;
        const email = query.get("email") ? `&email=${query.get("email")}` : '';
        const site = query.get("site") ? `&site=${query.get("site")}` : '';
        const company = query.get("company") ? `&company=${query.get("company")}` : '';
        const owner = query.get("owner") ? `&owner=${query.get("owner")}` : '';
        const usernameParam = query.get("userName") ? `&userName=${query.get("userName")}` : '';

        const navigateWithParams = (activateKey: string) => {
          navigate(`/?screen=selector&token=${token}${company}${email}${site}${owner}${usernameParam}&${activateKey}`);
        };
        switch (currentPath) {
          case "/activate-user":
            navigateWithParams("activateUser");
            break;
          case "/activate-supplier":
            navigateWithParams("activateSupplier");
            break;
          case "/activate-subcontractor":
            navigateWithParams("activateSubcontractor");
            break;
          case "/confirm-subcontractor":
            navigateWithParams("confirmSubcontractor");
            break;
          case "/email-certification":
            navigateWithParams("emailCertification");
            break;
          default:
            navigate("/?screen=sign-in");
            break;
        }
      } finally {
        setInit(true);
      }
    };
    getLoginDataSilently();
  }, [currentCompanyId]);

  const actions: AuthMethods = {
    setLoginData,
    updateContext,
    changeTenant,
    updateSiteContext,
    logout,
    hasPermissions,
    updateTenantPermissions,
  };

  return (
    <AuthContext.Provider value={{ ...state, ...actions }}>
      {init ? children : <LoadingView />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
