import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteVehicleParams } from "../../../domain/entities/siteVehicle";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";
import useWorkingSiteVehiclesViewModel from "../../hooks/WorkingSite/useWorkingSiteVehiclesViewModel";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const SiteVehiclesView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["name", "plate", "model"];
  return (
    <SiteResourcesListView<SiteVehicleParams>
      type={DocumentTypeCategory.VEHICLE}
      useViewModel={useWorkingSiteVehiclesViewModel(
        siteId,
        resourceColumns,
        DocumentTypeCategory.VEHICLE,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[Permission.Worksite_AttachVehicle]}
      removeFromSitePermissions={[Permission.Worksite_DetachVehicle]}
    />
  );
};

export default SiteVehiclesView;
