import { useAuth } from "../../../providers/Auth0JWTProvider";
import Machine from "../../../../domain/entities/machine";
import { MachineViewModel } from "../../../viewmodels/machines/MachineViewModel";
import React, { useMemo, useState } from "react";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { GetSitesFilter, GetSitesWithBadgesFilter } from "../../../../domain/repositories/siteRepository";
import { updateFilterWithDelete } from "../../../../utils";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";
import { GetAvailableBadgesFilters } from "../../../../domain/repositories/badgeRepository";
import { BadgeResourceType } from "../../../../domain/entities/badgeResourceType.enum";
import {CustomField} from "../../../../domain/entities/customFields";

export interface MapRecordInput {
  vehicleDocumentTypeId: string;
  machineDocumentTypeId: string;
}

const useMachineViewModel = (id: string) => {
  const { companyId } = useAuth();

  const [sortResourceDocumentSites, setSortResourceDocumentSites] = useState<SortMeta>();
  const [filterResourceDocumentSites, setFilterResourceDocumentSites] =
    useState<GetSitesFilter>();

  const [sortResourceSites, setSortResourceSites] = useState<SortMeta>();
  const [filterResourceSites, setFilterResourceSites] =
  useState<GetSitesWithBadgesFilter>();

  const [sortSiteCollection, setSortSiteCollection] = useState<SortMeta>();
  const [filterSiteCollection, setFilterSiteCollection] =
    useState<GetSitesFilter>();
  const [resourceLinkableSitesCount, setResourceLinkableSitesCount] = useState<number>();
  const [sortMachineBadge, setSortMachineBadge] = useState<SortMeta>();
  const [filterMachineBadge, setFilterMachineBadge] =
    useState<GetAvailableBadgesFilters>({});
  const [badgeIds, setBadgeIds] = useState<string[]>([]);
  const [badgeId, setBadgeId] = useState<string>();
  const [selectAll, setSelectAll] = useState(false);
  const [act, setAct] = useState<string>();
  const [availableBadgesFilters, setAvailableBadgesFilters] =
    useState<GetAvailableBadgesFilters>({});
  const [availableBadgesSort, setAvailableBadgesSort] =
    useState<SortMeta>(null);
  const [showInfoSuccess, setShowInfoSuccess] = useState<string>(null);
  const viewModel = useMemo(() => new MachineViewModel(), []);
  const queryClient = useQueryClient();
  const [openMigrationModal, setOpenMigrationModal] = useState(false);
  const [records, setRecords] = React.useState<MapRecordInput[]>([]);
  const [documentTypeId, setDocumentTypeId] = React.useState<string>();
  const [enableGetMigrationMachineSites, setGetMigrationMachineSites] = useState<boolean>(false);
  const [enableGetMachineSites, setEnableGetMachineSites] = useState<boolean>(false);
  const [enableGetMachineSiteCollection, setEnableGetMachineSiteCollection] = useState<boolean>(false);
  const {
    data: machine,
    isFetching,
    refetch: getMachine,
    isLoading
  } = useQuery<Machine, Error>(
    ["machine", companyId, id],
    async () => await viewModel.get(companyId, id),
    {
      retry: false,
      initialData: undefined,
    },
  );

  const getAvailableBadgesQuery = useQuery(
    [
      "available-badges",
      companyId,
      availableBadgesFilters,
      availableBadgesSort,
    ],
    async () => {
      return await viewModel.getAvailableBadges(
        companyId,
        "machines",
        id,
        availableBadgesSort,
        availableBadgesFilters,
      );
    },
  );

  const linkBadgesToResourceMutation = useMutation(
    async () => {
      return await viewModel.linkBadgesToResource(
        companyId,
        badgeIds,
        BadgeResourceType.MACHINE,
        id,
      );
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => {
        getMachineSites.refetch();
        setBadgeIds([]);
        setShowInfoSuccess('badgeAssociatedToResource');
      },
    },
  );

  const unlinkBadgeResourceMutation = useMutation(
    async () => {
      return await viewModel.unlinkBadgeResource(companyId, badgeId);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => {
        getMachineSites.refetch();
      },
    },
  );

  const updateMutation = useMutation(
    ({ machine, imageFile }: { machine: Machine; imageFile?: File }) =>
      viewModel.update(companyId, machine, imageFile),
    {
      onError: (err) => {
        console.error(err);
      },
      onSuccess: () => getMachine(),
    },
  );

  const updateMachine = (machine: Machine) => {
    return updateMutation.mutateAsync({ machine });
  };

  const updateImage = (machine: Machine, imageFile?: File) => {
    return updateMutation.mutateAsync({
      machine,
      imageFile,
    });
  };

  const getMachineDocumentSites = useInfiniteQuery(
    ["resource-document-sites", companyId, id, sortResourceDocumentSites, filterResourceDocumentSites, documentTypeId],
    async ({ pageParam = 1 }) => {
      const includeWorkingSites = act === "add";
      return await viewModel.getMachineSites(
        companyId,
        id,
        sortResourceSites,
        filterResourceSites,
        pageParam,
        includeWorkingSites,
        false,
        documentTypeId
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: !!sortResourceDocumentSites || !!filterResourceDocumentSites,
    },
  );

  const getMachineSites = useInfiniteQuery(
    ["resource-sites", companyId, id, sortResourceSites, filterResourceSites],
    async ({ pageParam = 1 }) => {
      return await viewModel.getMachineSites(
        companyId,
        id,
        sortResourceSites,
        filterResourceSites,
        pageParam,
        false,
        true,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetMachineSites
    },
  );

  /**
   * This method differs from the one above because here we want to know if the vehicle is used on any site for the purpose of migration
   * while in the other method (getMachineSites), we want to know the sites where the document can be propagated
   * (this means only those visible to the current user according to their permissions)
   */
  const getMachineMigrationSites = useInfiniteQuery(
    ["resource-migration-sites", companyId, id],
    async ({ pageParam = 1 }) => {
      const includeWorkingSites = act === "add";
      return await viewModel.getMachineSites(
        companyId,
        id,
        undefined,
        undefined,
        pageParam,
        includeWorkingSites,
        true,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetMigrationMachineSites,
    },
  );

  const hasSites = async (action, documentTypeId) => {
    setAct(action);
    setDocumentTypeId(documentTypeId)
    await queryClient.invalidateQueries([
      "resource-document-sites",
      companyId,
      id,
      sortResourceSites,
      filterResourceSites,
      action,
    ]);
    const data = await getMachineDocumentSites.refetch();
    const sites = data.data?.pages.flatMap((page) => page.results) ?? [];
    return sites.length > 0;
  };

  const getResourceLinkableSites = useInfiniteQuery(
    [
      "site-collection",
      companyId,
      id,
      sortSiteCollection,
      filterSiteCollection,
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } = await viewModel.getResourceLinkableSites(
        companyId,
        "machine" as ResourceType,
        id,
        sortSiteCollection,
        filterSiteCollection,
        pageParam,
      );
      setResourceLinkableSitesCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetMachineSiteCollection,
    },
  );
  const linkMachineToSites = (siteIds: string[], selectAll?: boolean) => {
    const params = {siteIds, selectAll, filters: filterSiteCollection}
    linkMachineToSitesMutation.mutateAsync(params);
  };
  const linkMachineToSitesMutation = useMutation(
    async (params: { siteIds: string[], selectAll?: boolean, filters?: GetSitesFilter }) => {
      return await viewModel.linkResourceToSites(companyId, id, params.siteIds, params.selectAll, params.filters);
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => {
        getMachineSites.refetch();
        setShowInfoSuccess('resourceAssociatedToSite')
      }
    },
  );

  const unlinkBadgeResource = () => {
    return unlinkBadgeResourceMutation.mutateAsync();
  };

  const linkBadgesToResource = () => {
    linkBadgesToResourceMutation.mutateAsync();
  };

  const getMapRecordsQuery = useQuery(
    ["machine-vehicle-document-type-map-records", companyId],
    async () => {
      const documentTypesMap =
        await viewModel.getMachineVehicleDocumentTypeMapRecords(companyId);
      await getMachineDocuments.refetch();
      return documentTypesMap;
    },
    {
      enabled: openMigrationModal,
    },
  );

  const getVehicleTypologiesQuery = useQuery(
    ["vehicle-typologies", companyId],
    async () => await viewModel.getVehicleTypologies(companyId),
    {
      enabled: openMigrationModal,
    },
  );

  const migrateToVehicleMutation = useMutation(
    async ({ typologyId }: { typologyId: string }) => {
      return await viewModel.migrateToVehicle(companyId, id, typologyId);
    },
    {
      onError: (e) => console.error(e),
    },
  );

  const upsertMachineVehicleDocumentTypeMapRecordsMutation = useMutation(
    async (
      records: {
        machineDocumentTypeId: string;
        vehicleDocumentTypeId: string;
      }[],
    ) => {
      return await viewModel.upsertMachineVehicleDocumentTypeMapRecords(
        companyId,
        records,
      );
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => getMapRecordsQuery.refetch(),
    },
  );

  const getMachineDocuments = useQuery(
    ["machine-documents-unpaginated", companyId, id],
    async () => {
      const response = await viewModel.getDocuments(companyId, id);
      setRecords(
        response.map((d) => ({
          vehicleDocumentTypeId: getMapRecordsQuery.data.find(
            (r) => r.machineDocumentType.id === d.type.id,
          )?.vehicleDocumentType.id,
          machineDocumentTypeId: d.type.id,
        })),
      );
      return response;
    },
    {
      enabled: !!getMapRecordsQuery.data,
    },
  );

  const getVehicleDocumentTypes = useQuery(
    ["vehicle-document-types", companyId],
    async () => await viewModel.getVehicleDocumentTypes(companyId),
    {
      enabled: openMigrationModal,
    },
  );
  const availableBadgesResource = getAvailableBadgesQuery.data;
  const resourceSites =
    getMachineSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const resourceMigrationSites =
    getMachineMigrationSites.data?.pages?.flatMap((page) => page.results) ?? [];

  const totalSiteCount = getMachineSites.data?.pages?.[0]?.count ?? 0;

  const resourceDocumentSites =
    getMachineDocumentSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const totalResourceDocumentSiteCount = getMachineDocumentSites.data?.pages?.[0]?.count ?? 0;


  const getCustomFieldsQuery = useQuery<CustomField[], Error>(
    ["machine-custom-fields", companyId],
    async () => await viewModel.getCustomFields(companyId),
  );

  return {
    machine,
    getMachine,
    isFetching,
    updateImage,
    updateMachine,
    resourceMigrationSites,

    resourceDocumentSites,
    isFetchingResourceDocumentSites: getMachineDocumentSites.isFetching,
    resourceDocumentSitesHasNextPage: getMachineDocumentSites.hasNextPage,
    resourceDocumentSitesFetchNextPage: getMachineDocumentSites.fetchNextPage,
    updateFilterResourceDocumentSites: (field, value) => {
      field !== null && updateFilterWithDelete(setFilterResourceDocumentSites, field, value);
    },
    filterResourceDocumentSites,
    setSortResourceDocumentSites,
    sortResourceDocumentSites,
    totalResourceDocumentSiteCount,
    setGetMigrationMachineSites,
    setEnableGetMachineSites,
    resourceSites,
    isFetchingSites: getMachineSites.isFetching,
    hasNextPage: getMachineSites.hasNextPage,
    fetchNextPage: getMachineSites.fetchNextPage,
    enableGetMigrationMachineSites,
    updateFilterResourceSites: (field, value) => {
      field !== null && updateFilterWithDelete(setFilterResourceSites, field, value);
    },
    setFilterResourceSites,
    filterResourceSites,
    setSortResourceSites,
    sortResourceSites,
    hasSites,
    siteCollection: getResourceLinkableSites.data?.pages?.flat() ?? [],
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    setFilterSiteCollection,
    updateFilterSiteCollection: (field, value) => {
      field !== null &&  updateFilterWithDelete(setFilterSiteCollection, field, value);
    },
    setEnableGetMachineSiteCollection,
    siteCollectionIsLoading: getResourceLinkableSites.isLoading,
    siteCollectionHasNextPage: getResourceLinkableSites.hasNextPage,
    siteCollectionFetchNextPage: getResourceLinkableSites.fetchNextPage,
    refetchSiteCollection: getResourceLinkableSites.refetch,
    linkMachineToSites,
    isLinking: linkMachineToSitesMutation.isLoading,
    sortMachineBadge,
    filterMachineBadge,
    setFilterMachineBadge,
    setSortMachineBadge,
    setBadgeIds,
    badgeIds,
    badgeId,
    setBadgeId,
    updateFilterMachine: (field, value) => {
      field !== null && updateFilterWithDelete(setFilterMachineBadge, field, value);
    },
    availableBadgesResource,
    availableBadgesFilters,
    availableBadgesSort,
    setAvailableBadgesSort,
    availableBadgesIsLoading: getAvailableBadgesQuery.isLoading,
    availableBadgesRefetch: getAvailableBadgesQuery.refetch,
    updateFilterAvailableBadge: (field, value) => {
      field !== null && updateFilterWithDelete(setAvailableBadgesFilters, field, value);
    },
    linkBadgesToResource,
    linkBadgesToResourceIsLoading: linkBadgesToResourceMutation.isLoading,
    showInfoSuccess,
    setShowInfoSuccess,
    unlinkBadgeResource,
    unlinkBadgeResourceIsLoading: unlinkBadgeResourceMutation.isLoading,
    selectAll,
    setSelectAll,
    totalSiteCount,
    machineVehicleMigrationProps: {
      targetTypologies: getVehicleTypologiesQuery.data ?? [],
      migrate: migrateToVehicleMutation.mutateAsync,
      loading:
        migrateToVehicleMutation.isLoading ||
        getVehicleTypologiesQuery.isLoading ||
        getMapRecordsQuery.isFetching ||
        upsertMachineVehicleDocumentTypeMapRecordsMutation.isLoading ||
        getMachineDocuments.isFetching ||
        getVehicleDocumentTypes.isLoading,
      upsertMapRecords:
        upsertMachineVehicleDocumentTypeMapRecordsMutation.mutateAsync,
      documentTypeMapRecords: getMapRecordsQuery.data ?? [],
      documents: getMachineDocuments.data ?? [],
      openMigrationModal,
      setOpenMigrationModal,
      targetDocumentTypes: getVehicleDocumentTypes.data ?? [],
      records,
      setRecords,
    },
    customFields: {
      data: getCustomFieldsQuery.data,
      isLoading: getCustomFieldsQuery.isLoading,
      error: getCustomFieldsQuery.error
    },
    loading: getCustomFieldsQuery.isLoading || isLoading,
  };
};

export default useMachineViewModel;
