import { DocumentsListViewModel } from "../../viewmodels/documents/DocumentsListViewModel";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import useDocumentInfo, { UseDocumentInfo } from "./useDocumentInfo";
import useAddDocumentType, { UseAddDocumentType } from "./useAddDocumentType";
import useDocuments, { UseDocuments } from "./useDocuments";
import Site from "../../../domain/entities/site";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import { useAuth } from "../../providers/Auth0JWTProvider";
import useDocumentCommunicationViewModel, {
  DocumentCommunicationHook,
} from "./useDocumentCommunicationVIewModel";
import useDocumentAI, { UseDocumentAI } from "./useDocumentAI";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";

export type DocumentsListViewModelProps = {
  documentsProps: UseDocuments;
  documentAIProps: UseDocumentAI;
  documentProps: UseDocumentInfo;
  documentTypesProps: UseAddDocumentType;
  documentSites: Site[];
  communicationProps?: DocumentCommunicationHook;
  hookSectionCode?: AiTaskSectionCode;
};

const useDocumentsListViewModel = (
  viewModel: DocumentsListViewModel,
  category: DocumentTypeCategory,
  identifier: string,
  siteId?: string,
  hookSectionCode?: AiTaskSectionCode
): DocumentsListViewModelProps => {
  const { companyId } = useAuth();
  const documentsProps = useDocuments(viewModel, identifier, siteId, undefined, undefined);
  const documentAIProps = useDocumentAI(viewModel, siteId, "", undefined, hookSectionCode);

  const [sortResourceSites, setSortResourceSites] = useState<SortMeta>();
  const [filterResourceSites, setFilterResourceSites] =
    useState<GetSitesFilter>();
  const [enableGetResourceSites, setEnableGetResourceSites] = useState(false);

  const getDocumentSites = useInfiniteQuery(
    ["document-sites", companyId, sortResourceSites, filterResourceSites],
    async ({ pageParam = 1 }) => {
      //TODO insert call to correct viewmodel method
      return [];
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetResourceSites,
    },
  );

  const documentSites = getDocumentSites.data?.pages?.flat() ?? [];

  return {
    documentsProps,
    documentTypesProps: useAddDocumentType(viewModel, category),
    documentProps: useDocumentInfo(viewModel, siteId, category, null),
    documentSites,
    documentAIProps
  };
};

const useDocumentWithCommunicationViewModel = (
  viewModel: DocumentsListViewModel,
  category: DocumentTypeCategory,
  identifier: string,
  siteId?: string,
  hookSectionCode?: AiTaskSectionCode
): DocumentsListViewModelProps => {
  return {
    ...useDocumentsListViewModel(viewModel, category, identifier, siteId, hookSectionCode),
    communicationProps: useDocumentCommunicationViewModel(
      identifier,
      category,
      siteId,
    ),
  };
};

export { useDocumentWithCommunicationViewModel };

export default useDocumentsListViewModel;
