import UseCase from '../../../../core/app/usecase';
import SiteChemicalRepository from '../../../repositories/siteChemicalRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	chemicalId: string;
	specializationIds?: string[];
};

class UpdateSiteChemicalSpecializations implements UseCase<Parameters, void | undefined> {
	constructor(private repository: SiteChemicalRepository) {}

	async Call({ companyId, siteId, chemicalId, specializationIds }: Parameters): Promise<void | undefined> {
		return this.repository.updateSiteChemicalSpecializations(companyId, siteId, chemicalId, specializationIds);
	}
}

export default UpdateSiteChemicalSpecializations;
