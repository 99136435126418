import UseCase from '../../../core/app/usecase';
import SubcontractorRepository, { UnlistedCompany } from '../../repositories/supplierRepository';

export type InviteSubcontractorsArgs = {
	companyId: string;
	siteId: string;
	companyVariant: string;
	documentIds?: string[];
	subcontractorIds?: string[];
	unlistedCompany?: UnlistedCompany;
};

class InviteSubcontractors implements UseCase<InviteSubcontractorsArgs, void> {
	private supplierRepository: SubcontractorRepository;

	constructor(supplierRepository: SubcontractorRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({
		companyId,
		siteId,
		subcontractorIds,
		companyVariant,
		documentIds,
		unlistedCompany,
	}: InviteSubcontractorsArgs): Promise<void> {
		return await this.supplierRepository.inviteSubcontractors(
			companyId,
			siteId,
			subcontractorIds,
			companyVariant,
			documentIds,
			unlistedCompany,
		);
	}
}

export default InviteSubcontractors;
