import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import Evaluation from '../../entities/evaluation';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import { ResourceEvaluationState } from '../../entities/resourceEvaluationState.enum';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { GetResourceEvaluationFilter } from '../../repositories/siteRepository';

type ARGS = {
	companyId: string;
	supplierId: string;
	resourceId: string;
	type: RequirementSubject;
	pageParam: number;
	sort?: SortMeta;
	filter?: GetResourceEvaluationFilter;
};

class GetCompanySupplierCompanyRequirements implements UseCase<ARGS, Evaluation<ResourceEvaluationState>[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, resourceId, type, pageParam, sort, filter }: ARGS): Promise<Evaluation<ResourceEvaluationState>[]> {
		return await this.supplierRepository.getCompanySupplierResourceEvaluations(companyId, supplierId, resourceId, type, pageParam, sort, filter);
	}
}

export default GetCompanySupplierCompanyRequirements;
