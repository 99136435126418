import { FC } from "react";
import { Select } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const defaultDays = 30;
const daysOptions = [15, 30, 45, 60, 90];

interface Props {
  days?: number;
  onSelectDays?: (days: number) => void;
}

const DashboardDaysSelector: FC<Props> = ({ days, onSelectDays }) => {
  const { t } = useTranslation();

  return (
    <Select
      maxWidth={130}
      value={days ?? defaultDays}
      onChange={(e) => onSelectDays(parseInt(e.target.value) ?? defaultDays)}
    >
      {daysOptions.map((d) => (
        <option value={d} key={d}>
          {t("days", { ns: "common", count: d })}
        </option>
      ))}
    </Select>
  );
};

export default DashboardDaysSelector;
