import Chemical from '../../../entities/chemical';
import UseCase from '../../../../core/app/usecase';
import ChemicalRepository, { GetChemicalsFilter } from '../../../repositories/chemicalRepository';
import { PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';

type ARGS = {
	companyId: string;
	filter?: GetChemicalsFilter;
	sort?: SortMeta;
	pageParam?: number;
	archived?: boolean;
};

class GetChemicals implements UseCase<ARGS, PaginatedResults<Chemical>> {
	constructor(private repository: ChemicalRepository) {}

	async Call({ companyId, filter, archived, sort, pageParam }: ARGS): Promise<PaginatedResults<Chemical>> {
		return await this.repository.getChemicals(companyId, filter, archived, sort, pageParam);
	}
}

export default GetChemicals;
