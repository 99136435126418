import TopBar, { TopBarItem } from "../../Common/TopBar";

const WorkingSitesTopBar = () => {
  const items: TopBarItem[] = [
    {
      title: "Lista",
      path: "/workingsites",
    },
  ];

  return <TopBar title="Siti dove lavoro" items={items} />;
};

export default WorkingSitesTopBar;
