import UseCase from '../../../core/app/usecase';
import DocumentType from '../../entities/documentType';
import DocumentTypeRepository from '../../repositories/documentTypeRepository';
import CreateDocumentTypeParameters from './createDocumentTypeParameter';

/**
 * Create the DocumentType given as parameter.
 *
 * @param type the DocumentType that will be created
 */
class CreateDocumentType implements UseCase<CreateDocumentTypeParameters, DocumentType> {
	constructor(private repository: DocumentTypeRepository) {}

	async Call({ companyId, resource, type }: CreateDocumentTypeParameters): Promise<DocumentType> {
		return await this.repository.createDocumentType(companyId, resource, type);
	}
}

export default CreateDocumentType;
