import UseCase from '../../../../core/app/usecase';
import Document from '../../../entities/document';
import MachineRepository from '../../../repositories/machineRepository';
import { GetDocumentsFilter } from '../../../repositories/documentRepository';
import { SortMeta } from '../../../entities/interfaces/paginatedResults';

type GetMachineDocumentsParameters = {
	companyId: string;
	resourceId: string;
	filter?: GetDocumentsFilter;
	sort?: SortMeta;
	pageParam?: number;
};

/**
 * Returns the list of all the existing Document.
 */
class GetMachineDocuments implements UseCase<GetMachineDocumentsParameters, Document[]> {
	constructor(private repository: MachineRepository) {}

	async Call({ companyId, resourceId, sort, filter, pageParam }: GetMachineDocumentsParameters): Promise<Document[]> {
		return await this.repository.getMachineDocuments(companyId, resourceId, filter, sort, pageParam);
	}
}

export default GetMachineDocuments;
