import UseCase from '../../../core/app/usecase';
import TimeTableDocuments from '../../entities/timeTableDocuments';
import DocumentRepository from '../../repositories/documentRepository';

interface Args {
	companyId: string;
	startDate: Date;
	endDate: Date;
}

class GetTimeTableDocuments implements UseCase<Args, TimeTableDocuments> {
	constructor(private repository: DocumentRepository) {}

	async Call({ companyId, startDate, endDate }: Args): Promise<TimeTableDocuments> {
		return this.repository.getTimeTableDocuments(companyId, startDate, endDate);
	}
}

export default GetTimeTableDocuments;
