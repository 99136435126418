import Badge from "../../../domain/entities/badge";
import {
  makeCreateBadge,
  makeCreateMultipleBadgeQR,
  makeDeleteBadge,
  makeGetBadges,
  makeImportBadges,
  makeImportLinkResourceBadges,
  makeLinkBadgeTag,
  makeLinkBadgeToResources,
  makeUnlinkBadgesFromResources,
} from "../../../core/app/factory/badges/usecases";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import {
  GetAvailableBadgesFilters,
  GetBadgesFilters,
  GetBadgesQuery,
  LinkResourcesToBadgesResponse,
} from "../../../domain/repositories/badgeRepository";
import File from "../../../domain/entities/file";
import Tag from "../../../domain/entities/tag";
import {
  makeCreateTag,
  makeGetTags,
} from "../../../core/app/factory/tags/usecases";
import ImportInfo from "../../../domain/entities/importInfo";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import { GetSiteResourcesFilters } from "../../hooks/Badge/useSiteBadgesListViewModel";

class BadgesListViewModel {
  private getBadgesUseCase = makeGetBadges();
  private deleteBadgeUseCase = makeDeleteBadge();
  private importBadgesUseCase = makeImportBadges();
  private getTagsUseCase = makeGetTags();
  private createBadgeUseCase = makeCreateBadge();
  private createMultipleBadgeQRUseCase = makeCreateMultipleBadgeQR();
  private createTagUseCase = makeCreateTag();
  private linkBadgeTag = makeLinkBadgeTag();
  private linkBadgesToWorkersUseCase = makeLinkBadgeToResources();
  private importLinkResourcesBadgesUseCase = makeImportLinkResourceBadges();
  private unlinkBadgesFromResourcesUseCase = makeUnlinkBadgesFromResources();

  async list(
    companyId: string,
    page: number,
    filter?: GetBadgesFilters,
    sort?: SortMeta,
    query?: GetBadgesQuery,
  ): Promise<PaginatedResults<Badge>> {
    return await this.getBadgesUseCase.Call({
      companyId,
      siteId: "",
      page,
      perPage: 25,
      filter,
      sort,
      query,
    });
  }

  async delete(companyId: string, badgeId: string): Promise<void> {
    await this.deleteBadgeUseCase.Call({ companyId, badgeId });
  }

  async import(companyId: string, file: File): Promise<ImportInfo> {
    return await this.importBadgesUseCase.Call({ companyId, file });
  }
  async createTag(companyId: string, tag: Tag): Promise<Tag> {
    return await this.createTagUseCase.Call({ companyId, tag });
  }

  async getTags(companyId: string): Promise<Tag[]> {
    return await this.getTagsUseCase.Call({ companyId });
  }

  async createBadge(companyId: string, badge: Badge): Promise<Badge> {
    return await this.createBadgeUseCase.Call({ companyId, badge });
  }

  async createMultipleBadgeQR(
    companyId: string,
    count: number,
    tags: Tag[],
  ): Promise<Badge> {
    return await this.createMultipleBadgeQRUseCase.Call({
      companyId,
      count,
      tags,
    });
  }

  async linkTagToBadge(
    companyId: string,
    badgeId: string,
    tag: Tag,
  ): Promise<void> {
    return await this.linkBadgeTag.Call({ companyId, badgeId, tag });
  }
  async linkBadgesToResources(
    companyId: string,
    badgeIds: string[],
    resourceIds: string[],
    resourceType: BadgeResourceType,
    selectAllResources: boolean,
    selectedResourcesNumber: number,
    siteResourcesFilters: GetSiteResourcesFilters,
    selectAllBadges: boolean,
    selectedBadgesNumber: number,
    availableBadgesFilters: GetAvailableBadgesFilters,
    automaticLinking: boolean,
  ): Promise<LinkResourcesToBadgesResponse> {
    return await this.linkBadgesToWorkersUseCase.Call({
      companyId,
      badgeIds,
      workerIds: resourceIds,
      selectAllResources,
      selectedResourcesNumber,
      siteResourcesFilters,
      selectAllBadges,
      selectedBadgesNumber,
      availableBadgesFilters,
      automaticLinking,
      resourceType,
    });
  }
  async importLinkResourcesBadges(
    companyId: string,
    resourceType: BadgeResourceType,
    file: File,
  ): Promise<void> {
    return await this.importLinkResourcesBadgesUseCase.Call({
      companyId,
      resourceType,
      file,
    });
  }

  async unlinkBadgesFromResources(
    companyId: string,
    badgeIds: string[],
    selectAll: boolean,
  ): Promise<void> {
    return await this.unlinkBadgesFromResourcesUseCase.Call({
      companyId,
      badgeIds,
      selectAll,
    });
  }
}

export { BadgesListViewModel };
