import {
  makeAddTypologyToMachine,
  makeCreateMachineDocument,
  makeCreatePropagableMachineDocument,
  makeEvaluateMachineDocument,
  makeGetMachine,
  makeGetMachineDocuments,
  makeRemoveTypologyFromMachine,
  makeRepropagateEvaluationMachineDocument,
} from "../../../core/app/factory/machines/usecases";
import { ResourceDocumentsViewModel } from "../resources/ResourceDocumentsViewModel";
import { makeGetTypologies } from "../../../core/app/factory/typologies/usecases";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { GetDocumentsFilter } from "../../../domain/repositories/documentRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import Document from "../../../domain/entities/document";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";
import DocumentTypeWithPublic from "../../../domain/entities/documentTypeWithPublic";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";

class MachineDocumentsViewModel extends ResourceDocumentsViewModel {
  private getResourceUseCase = makeGetMachine();
  private getTypologiesUseCase = makeGetTypologies();

  constructor(private machineId: string) {
    super(machineId, DocumentTypeCategory.MACHINE);
  }

  //#region Extends class: ResourceDocumentsViewModel

  async getDocuments(
    companyId: string,
    filter?: GetDocumentsFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<Document[]> {
    return await makeGetMachineDocuments().Call({
      companyId,
      resourceId: this.machineId,
      sort,
      filter,
      pageParam,
    });
  }

  async createDocument(companyId: string, documents: string[]): Promise<void> {
    return await makeCreateMachineDocument().Call({
      companyId,
      resourceId: this.machineId,
      documents,
    });
  }

  async createPropagableDocuments(
    companyId: string,
    documents: DocumentTypeWithPublic[],
  ): Promise<void> {
    return await makeCreatePropagableMachineDocument().Call({
      companyId,
      resourceId: this.machineId,
      documents,
    });
  }

  async evaluateDocument(
    companyId: string,
    documentId: string,
    evaluationState?: ResourceDocumentEvaluationState,
    expirationDate?: Date,
    noEvaluationExpiration?: boolean,
    siteIds?: string[],
    selectAll?: boolean,
    filters?: GetSitesFilter
  ): Promise<void> {
    return await makeEvaluateMachineDocument().Call({
      companyId,
      resourceId: this.machineId,
      documentId,
      evaluationState,
      expirationDate,
      noEvaluationExpiration,
      siteIds,
      selectAll,
      filters,
    });
  }

  async repropagateEvaluationDocument(
    companyId: string,
    documentId: string,
    siteIds?: string[],
    selectAll?: boolean,
  ): Promise<void> {
    return await makeRepropagateEvaluationMachineDocument().Call({
      companyId,
      resourceId: this.machineId,
      documentId,
      siteIds,
      selectAll,
    });
  }

  async addTypologyToResource(
    companyId: string,
    resourceId: string,
    typologyId: string,
  ) {
    return await makeAddTypologyToMachine().Call({
      companyId,
      resourceId,
      typologyId,
    });
  }

  async removeTypologyFromResource(
    companyId: string,
    resourceId: string,
    typologyId: string,
  ) {
    return await makeRemoveTypologyFromMachine().Call({
      companyId,
      resourceId,
      typologyId,
    });
  }

  //#endregion

  async getResource(companyId: string, resourceId: string) {
    return await this.getResourceUseCase.Call({
      companyId,
      machineId: resourceId,
    });
  }

  async getTypologies(companyId: string) {
    return await this.getTypologiesUseCase.Call({
      companyId,
      type: DocumentTypeCategory.MACHINE,
    });
  }
}

export { MachineDocumentsViewModel };
