import UseCase from '../../../core/app/usecase';
import Tag from '../../entities/tag';
import RequirementRepository from '../../repositories/requirementRepository';

interface Args {
	companyId: string;
	variant: Tag;
}

class UpdateSpecializationToSiteRequirement implements UseCase<Args, void> {
	constructor(private repository: RequirementRepository) {}

	async Call(args: Args): Promise<void> {
		return await this.repository.updateSpecializationToSiteRequirement(args.companyId, args.variant);
	}
}

export default UpdateSpecializationToSiteRequirement;
