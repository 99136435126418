import { ResourceDocumentEvaluationState } from '../../domain/entities/resourceDocumentEvaluationState.enum';
import { EvaluationMode } from '../../domain/entities/evaluation';
import DocumentType from '../../domain/entities/document';
import { Evaluable } from '../../domain/entities/evaluable.enum';
import Document from '../../domain/entities/document';

interface Site {
	id: string;
	name: string;
}

interface Type {
	id: string;
	name: string;
	description: string;
	duration: number;
}

interface Evaluation {
	result: ResourceDocumentEvaluationState;
	createdAt: string;
	expiresAt: string;
	site: Site;
	evaluator: null;
	mode: EvaluationMode;
}

interface ResultItem {
	id: string;
	evaluation: Evaluation;
	document: Document;
	companyId: string;
	companyName: string;
	resourceId: string;
	resourceName?: string;
	firstName?: string;
	lastName?: string;
	requirementId: string;
	isOptional: boolean;
	resourceType: string;
}

export interface GetPendingEvaluationsResponse {
	results: ResultItem[];
}

export function mapPendingEvaluationsResponse(evaluations: ResultItem[], type: Evaluable) {
	return evaluations.map((item) => ({
		id: item.id,
		evaluation: {
			result: item.evaluation.result,
			createdAt: new Date(item.evaluation.createdAt),
			expiresAt: new Date(item.evaluation.expiresAt),
			resource: {
				id: item.document.id,
				expiresAt: new Date(item.document.expiresAt),
				type: {
					id: item.document.type.id,
					name: item.document.type.name,
					description: item.document.type.description,
				},
			} as unknown as DocumentType,
			evaluator: item.evaluation.evaluator,
			mode: item.evaluation.mode,
		},
		site: {
			id: item.evaluation.site.id,
			name: item.evaluation.site.name,
		},
		company: {
			id: item.companyId,
			name: item.companyName,
		},
		resource:
			type === 'worker'
				? {
						id: item.resourceId,
						firstName: item.firstName,
						lastName: item.lastName,
					}
				: {
						id: item.resourceId,
						name: item.resourceName,
					},
		requirementId: item.requirementId,
		isOptional: item.isOptional,
		document: item.document
	}));
}
