import { makeUsersRepository } from './repositories';
import { makeRolesRepository } from '../roles/repositories';
import GetUser from '../../../../domain/interactors/users/getUser';
import GetUsers from '../../../../domain/interactors/users/getUsers';
import GetRoles from '../../../../domain/interactors/roles/getRoles';
import CreateUser from '../../../../domain/interactors/users/createUser';
import DeleteUser from '../../../../domain/interactors/users/deleteUser';
import UpdateUser from '../../../../domain/interactors/users/updateUser';
import LinkUserToRole from '../../../../domain/interactors/users/linkUserToRole';
import UnlinkUserFromRole from '../../../../domain/interactors/users/unlinkUserFromRole';
import UpdateUserImage from '../../../../domain/interactors/users/updateUserImage';
import GetUserGeneralPermissions from '../../../../domain/interactors/users/GetUserGeneralPermissions';
import GetUserSitePermissions from '../../../../domain/interactors/users/GetUserSitePermissions';
import UserEmailAvailable from '../../../../domain/interactors/users/userEmailAvailable';
import RenewInvitation from '../../../../domain/interactors/users/renewInvitation';
import CompanyEmailAvailable from "../../../../domain/interactors/users/companyEmailAvailable";

export const makeGetUsers = () => {
	return new GetUsers(makeUsersRepository());
};

export const makeGetUser = () => {
	return new GetUser(makeUsersRepository());
};

export const makeGetRoles = () => {
	return new GetRoles(makeRolesRepository());
};

export const makeCreateUser = () => {
	return new CreateUser(makeUsersRepository());
};

export const makeUpdateUser = () => {
	return new UpdateUser(makeUsersRepository());
};

export const makeUpdateUserImage = () => {
	return new UpdateUserImage(makeUsersRepository());
};

export const makeDeleteUser = () => {
	return new DeleteUser(makeUsersRepository());
};

export const makeLinkUserToRole = () => {
	return new LinkUserToRole(makeRolesRepository());
};

export const makeUnlinkUserFromRole = () => {
	return new UnlinkUserFromRole(makeRolesRepository());
};

export const makeGetGeneralPermissions = () => {
	return new GetUserGeneralPermissions(makeUsersRepository());
};

export const makeGetSitePermissions = () => {
	return new GetUserSitePermissions(makeUsersRepository());
};

export const makeUserEmailAvailable = () => {
	return new UserEmailAvailable(makeUsersRepository());
};
export const makeCompanyEmailAvailable = () => {
	return new CompanyEmailAvailable(makeUsersRepository());
};

export const makeRenewInvitation = () => {
	return new RenewInvitation(makeUsersRepository());
};
