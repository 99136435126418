import CreateTag from '../../../../domain/interactors/tag/createTag';
import GetTags from '../../../../domain/interactors/tag/getTags';
import UpdateTag from '../../../../domain/interactors/tag/updateTag';
import { makeTagRepository } from './repositories';

export const makeGetTags = () => {
	return new GetTags(makeTagRepository());
};

export const makeCreateTag = () => {
	return new CreateTag(makeTagRepository());
};

export const makeUpdateTag = () => {
	return new UpdateTag(makeTagRepository());
};
