import UseCase from '../../../core/app/usecase';
import Tag from '../../entities/tag';
import TagRepository from '../../repositories/tagRepository';

type ARGS = {
	companyId: string;
	supplierId?: string;
	search?: string;
};

class GetTags implements UseCase<ARGS, Tag[]> {
	private tagRepository: TagRepository;

	constructor(tagRepository: TagRepository) {
		this.tagRepository = tagRepository;
	}

	async Call({ companyId, supplierId, search }: ARGS): Promise<Tag[]> {
		return await this.tagRepository.getTags(companyId, supplierId, search);
	}
}

export default GetTags;
