import TopBar, { TopBarItem } from "../../Common/TopBar";

const SitesTopBar = () => {
  const items: TopBarItem[] = [
    {
      title: "Lista",
      path: "/sites",
    },
  ];

  return <TopBar title="Siti" items={items} />;
};

export default SitesTopBar;
