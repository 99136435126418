import LoadingView from "../../Common/LoadingView";
import CustomFieldsContainer from "./CustomFieldsContainer";
import ErrorModals from "./ErrorModals";
import useCustomFieldsViewModel from "../../../hooks/CustomFields/useCustomFieldsViewModel";

const CustomFieldsView = () => {
  const hook = useCustomFieldsViewModel();
  return (
    <>
      <ErrorModals {...hook} />
      {hook.customFields.status === 'loading' ? <LoadingView /> : <CustomFieldsContainer hook={hook} />}
    </>
  );
};

export default CustomFieldsView;
