import UseCase from '../../../core/app/usecase';
import Document from '../../entities/document';
import SupplierRepository from '../../repositories/supplierRepository';

type ARGS = {
	companyId: string;
	supplierId: string;
	document: Document;
	siteIds: string[];
	expiresAt?: Date;
};

class UpdateSupplierDocument implements UseCase<ARGS, Document[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, document, siteIds, expiresAt }: ARGS): Promise<Document[]> {
		return await this.supplierRepository.updateSupplierDocument(companyId, supplierId, document, siteIds);
	}
}

export default UpdateSupplierDocument;
