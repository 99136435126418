import { FC, ReactNode, useRef, useState } from "react";
import { COLORS } from "../../../assets/theme/colors";
import { HiFilter, HiOutlineFilter } from "react-icons/hi";
import { BsArrowDown, BsArrowDownUp, BsArrowUp } from "react-icons/bs";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Text,
  useOutsideClick
} from "@chakra-ui/react";
import { SortDirection } from "../../../../domain/entities/interfaces/paginatedResults";

interface Props {
  overflowHidden?: boolean;
  text: string;
  filter?: { component: ReactNode; isActive: boolean };
  sort?: {
    direction: SortDirection;
    handler: (direction: SortDirection) => void;
  };
}

const TableColumnHeader: FC<Props> = ({
  text,
  filter,
  sort,
  overflowHidden,
}) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const updateSorting = () => {
    const nextState =
      sort.direction === SortDirection.Ascending
        ? SortDirection.Descending
        : SortDirection.Ascending;
    sort?.handler(nextState);
  };

  const handleEdit = (e) => {
    e.stopPropagation(); // Prevent closing the filter when clicking inside
    setShowFilter((prev) => !prev);
  };

  useOutsideClick({
    ref,
    handler: () => {
      setShowFilter(false); // Only close if clicking outside of the header area
    },
  });

  const directionIcon = {
    [SortDirection.Ascending]: BsArrowUp,
    [SortDirection.Descending]: BsArrowDown,
  };

  return (
    <Box position="relative" ref={ref}>
      <Flex direction="row" alignItems="center">
        {filter && (
          <>
            <IconButton
              variant="ghost"
              aria-label="toggle-filter"
              size="sm"
              onClick={handleEdit}
              sx={{
                height: "16px",
                minWidth: "16px",
                padding: 0,
                marginRight: "2px",
              }}
            >
              <Icon
                color={filter.isActive ? COLORS.sikuroBlue : undefined}
                as={filter.isActive ? HiFilter : HiOutlineFilter}
              />
            </IconButton>

            {showFilter && (
              <Box
                padding={1}
                position="absolute"
                top="40%" // Position below the header
                left={0}
                zIndex={1400}
                backgroundColor="white"
                height="fit-content"
                borderRadius="10px"
                border="1px solid"
                borderColor="lightGray"
                mt="2" // Add space between header and filter box
                {...(overflowHidden && { overflow: "hidden" })}
              >
                {filter.component}
              </Box>
            )}
          </>
        )}
        <Text>{text}</Text>
        <div style={{ flexGrow: 1 }} />
        {sort && (
          <IconButton
            variant="ghost"
            aria-label="toggle-sort"
            size="sm"
            onClick={updateSorting}
            sx={{ height: "16px" }}
          >
            <Icon as={directionIcon[sort.direction] ?? BsArrowDownUp} />
          </IconButton>
        )}
      </Flex>
    </Box>
  );
};

export default TableColumnHeader;
