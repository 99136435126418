import UseCase from '../../../../core/app/usecase';
import DocumentTypeWithPublic from '../../../entities/documentTypeWithPublic';
import ChemicalRepository from '../../../repositories/chemicalRepository';

type CreatePropagableChemicalDocumentParameters = {
	companyId: string;
	resourceId: string;
	documents: DocumentTypeWithPublic[];
};

/**
 * Returns the list of all the existing Document.
 */
class CreatePropagableChemicalDocument implements UseCase<CreatePropagableChemicalDocumentParameters, void> {
	constructor(private repository: ChemicalRepository) {}

	async Call({ companyId, resourceId, documents }: CreatePropagableChemicalDocumentParameters): Promise<void> {
		return await this.repository.createPropagableChemicalDocument(companyId, resourceId, documents);
	}
}

export default CreatePropagableChemicalDocument;
