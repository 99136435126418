import { makeGetCompanySupplier } from "../../../core/app/factory/suppliers/useCases";
import Supplier from "../../../domain/entities/supplier";

class SupplierMainViewModel {
  constructor(
    private readonly companyId: string,
    private readonly siteId: string,
  ) {}
  async get(): Promise<Supplier> {
    return await makeGetCompanySupplier().Call({
      companyId: this.companyId,
      supplierId: this.siteId,
    });
  }
}

export default SupplierMainViewModel;
