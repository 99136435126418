import UseCase from '../../../core/app/usecase';
import Company from '../../entities/company';
import CompanyRepository from '../../repositories/companyRepository';

class GetCompany implements UseCase<string, Company | undefined> {
	private companyRepository: CompanyRepository;

	constructor(companyRepository: CompanyRepository) {
		this.companyRepository = companyRepository;
	}

	async Call(id: string): Promise<Company | undefined> {
		const company = await this.companyRepository.getCompanyById(id); // fetch company here
		return company;
	}
}

export default GetCompany;
