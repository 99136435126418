import { UseWebHookViewModel } from "../../../hooks/WebHook/useWebHookViewModel";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import ContentLayout from "../../../layout/ContentLayout";
import ActionBar from "../../../components/Common/ActionBar";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import { FiPlus } from "react-icons/fi";
import { BiReset } from "react-icons/bi";
import { FaRegSave } from "react-icons/fa";
import { COLORS } from "../../../assets/theme/colors";
import WebHookRow from "./WebHookRow";
import { WebHook } from "../../../../domain/entities/webHook";
import SimpleTable from "../../../components/Common/table/SimpleTable";
import { useEffect, useMemo } from "react";
import WebHookInfoButton from "./WebHookInfoButton";

export type FormValues = {
  webHooks: WebHookFormElement[];
};

const WebHookContainer = ({ hook }: { hook: UseWebHookViewModel }) => {
  const { t } = useTranslation("webhooks");
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const { webHooks, setWebHooks, events, ownedSites } = hook;

  const onValid = ({ webHooks }: FormValues) =>
    setWebHooks.mutate(formToWebHooks(webHooks));

  const defaultValues = useMemo(
    () => ({ webHooks: webHooksToForm(webHooks.data) }),
    [webHooks.data],
  );

  const methods = useForm<FormValues>({ defaultValues });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "webHooks",
  });

  const isSetWebHooksFetching =
    setWebHooks.status === "loading" ||
    [webHooks.status, events.status, ownedSites.status].includes("error");

  const isSetWebHooksFetchingOrFormNotDirty = isSetWebHooksFetching || !isDirty;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const eventOptions = (events.data ?? []).map((event) => ({
    value: event,
    label: t(`events.${event.replace(/:/g, "_")}.title`),
  }));

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            onClick={handleSubmit(onValid)}
            icon={FaRegSave}
            description={t("save")}
            isDisabled={isSetWebHooksFetchingOrFormNotDirty}
            bgColor={
              isSetWebHooksFetchingOrFormNotDirty ? COLORS.lightGrey : undefined
            }
            color={
              isSetWebHooksFetchingOrFormNotDirty ? COLORS.gray : undefined
            }
          />
          <ActionBarItem
            onClick={() =>
              append({ url: "", apiSecret: "", siteIds: [], events: [] })
            }
            isDisabled={isSetWebHooksFetching}
            icon={FiPlus}
            description={t("add")}
            bgColor={
              isSetWebHooksFetching ? COLORS.lightGrey : COLORS.sikuroBlue
            }
            color={isSetWebHooksFetching ? COLORS.gray : COLORS.white}
          />
          <ActionBarItem
            onClick={() => reset(defaultValues)}
            isDisabled={isSetWebHooksFetchingOrFormNotDirty}
            icon={BiReset}
            description={t("reset", { ns: "common" })}
            bgColor={
              isSetWebHooksFetchingOrFormNotDirty
                ? COLORS.lightGrey
                : COLORS.sikuroBlue
            }
            color={
              isSetWebHooksFetchingOrFormNotDirty ? COLORS.gray : COLORS.white
            }
          />
          <WebHookInfoButton events={eventOptions} />
        </ActionBar>
      }
    >
      <FormProvider {...methods}>
        <Flex
          flex={1}
          h="100%"
          width={"calc(100vw - 190px)"}
          padding={10}
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
        >
          <Text textColor={COLORS.sikuroBlue} fontSize={20} fontWeight={"bold"}>
            {t("title")}
          </Text>
          <Flex
            flexDirection={"column"}
            alignItems={"start"}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            width={"calc(100vw - 240px)"}
            position="relative"
            overflow={"auto"}
            marginTop={3}
          >
            <SimpleTable
              isLoading={isSetWebHooksFetching}
              emptyText={t("noWebhooks")}
              showEmptyText={fields.length === 0}
            >
              <Thead>
                <Tr>
                  <Th w={isTablet && fields.length > 0 ? "300px" : "35%"}>
                    {t("endpoint")}
                  </Th>
                  <Th w={isTablet && fields.length > 0 ? "300px" : "35%"}>
                    {t("events.title")}
                  </Th>
                  <Th
                    w={
                      isTablet && fields.length > 0
                        ? "300px"
                        : "calc(30% - 60px)"
                    }
                  >
                    {t("sites")}
                  </Th>
                  <Th w="60px" />
                </Tr>
              </Thead>
              <Tbody>
                {fields.map((field, index) => (
                  <WebHookRow
                    key={field.id}
                    index={index}
                    events={eventOptions}
                    ownedSites={ownedSites.data ?? []}
                    remove={remove}
                  />
                ))}
              </Tbody>
            </SimpleTable>
          </Flex>
        </Flex>
      </FormProvider>
    </ContentLayout>
  );
};

interface WebHookFormElement {
  url: string;
  apiSecret?: string;
  siteIds: string[];
  events: string[];
}

const webHooksToForm = (webhooks: WebHook[]): WebHookFormElement[] => {
  const endpointMap = webhooks.reduce((map, webhook) => {
    webhook.endpoints.forEach((endpoint) => {
      if (!map[endpoint.url]) {
        map[endpoint.url] = {
          url: endpoint.url,
          apiSecret: endpoint.apiSecret,
          siteIds: [],
          events: [],
        };
      }
      if (
        webhook.siteId &&
        !map[endpoint.url].siteIds.includes(webhook.siteId)
      ) {
        map[endpoint.url].siteIds.push(webhook.siteId);
      }
      map[endpoint.url].events.push(webhook.event);
    });
    return map;
  }, {});

  // Step 2: Transform the map into the desired array format
  return Object.values(endpointMap);
};

const formToWebHooks = (table: WebHookFormElement[]): WebHook[] => {
  const eventSiteMap = new Map();
  table.forEach((endpoint) => {
    endpoint.events.forEach((event) => {
      const groups = event.startsWith("site:") ? endpoint.siteIds : [""];
      groups.forEach((siteId) => {
        const key = `${event}:${siteId}`;
        if (!eventSiteMap.has(key)) {
          eventSiteMap.set(key, {
            event,
            siteId,
            endpoints: [],
          });
        }
        if (
          !eventSiteMap
            .get(key)
            .endpoints.some(
              (e) =>
                e.url === endpoint.url && e.apiSecret === endpoint.apiSecret,
            )
        ) {
          eventSiteMap.get(key).endpoints.push({
            url: endpoint.url,
            apiSecret: endpoint.apiSecret,
          });
        }
      });
    });
  });

  // Step 2: Transform the map into the original array format
  return Array.from(eventSiteMap.values());
};

export default WebHookContainer;
