import { useMemo, useState } from "react";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { ListNotificationsStatusFilter } from "../../../domain/interactors/notifications/list";
import NotificationsListViewModel from "../../viewmodels/notifications/NotificationsListViewModel";

const useNotificationsList = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [status, setStatus] = useState<ListNotificationsStatusFilter>();

  const { companyId } = useAuth();
  const vm = useMemo(
    () => new NotificationsListViewModel(companyId),
    [companyId]
  );

  const [error, setError] = useState<string>("");
  const authenticated = !!companyId;

  // Read.
  const query = useInfiniteQuery(
    ["get-notifications", companyId, status],
    async ({ pageParam = 1 }) => {
      const { results } = await vm.list(status, pageParam, 25);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage?.length >= 25 ? pages.length + 1 : undefined,
      enabled: authenticated,
    }
  );

  const notifications = query.data?.pages?.flat() ?? [];

  // Update.
  const readMutation = useMutation(
    (data: { id: string; status: string }) => vm.read(data.id, data.status),
    {
      onSuccess: () => {
        query.refetch();

        notificationsCount.refetch();
      },
      onError: (e: string) => setError(e),
    }
  );

  const deleteMutation = useMutation((id: string) => vm.delete(id), {
    onSuccess: () => query.refetch(),
    onError: (e: string) => setError(e),
  });

  const notificationsCount = useQuery(
    ["get-count", companyId],
    async () => {
      const res = await vm.notificationCount();
      return res;
    },
    {
      enabled: authenticated,
    }
  );
  const readNotification = (data: { id: string; status: string }) =>
    readMutation.mutate(data);
  const deleteNotification = (id: string) => deleteMutation.mutate(id);

  return {
    readNotification,
    deleteNotification,
    refetchNotifications: query.refetch,
    notifications,
    error,
    isFetching: query.isLoading,
    hasNextPage: query.hasNextPage,
    fetchNextPage: query.fetchNextPage,
    isFetchingNextPage: query.isFetchingNextPage,
    setStatus,
    notificationCount: notificationsCount.data,
    refreshCount: notificationsCount.refetch,
    setAuthenticated,
  };
};

export default useNotificationsList;
