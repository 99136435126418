import UseCase from '../../../core/app/usecase';
import Supplier from '../../entities/supplier';
import SupplierRepository from '../../repositories/supplierRepository';
import SupplierParameters from './supplierParameters';

class GetSupplierDetail implements UseCase<SupplierParameters, Supplier> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, siteId, supplierId }: SupplierParameters): Promise<Supplier> {
		return await this.supplierRepository.getSupplierById(companyId, siteId, supplierId);
	}
}

export default GetSupplierDetail;
