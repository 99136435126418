import UseCase from '../../../core/app/usecase';
import { GetSiteResourcesFilters } from '../../../presentation/hooks/Badge/useSiteBadgesListViewModel';
import BadgeRepository, { GetAvailableBadgesFilters } from '../../repositories/badgeRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	badgeIds: string[];
	workerIds: string[];
	selectAllResources: boolean;
	selectedResourcesNumber: number;
	siteResourcesFilters: GetSiteResourcesFilters;
	selectAllBadges: boolean;
	selectedBadgesNumber: number;
	availableBadgesFilters: GetAvailableBadgesFilters;
	automaticLinking: boolean;
};

class LinkBadgesToSiteWorkers implements UseCase<ARGS, void> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({
		companyId,
		siteId,
		badgeIds,
		workerIds,
		selectAllResources,
		selectedResourcesNumber,
		siteResourcesFilters,
		selectAllBadges,
		selectedBadgesNumber,
		availableBadgesFilters,
		automaticLinking,
	}: ARGS): Promise<void> {
		return await this.badgeRepository.linkBadgesToSiteWorkers(
			companyId,
			siteId,
			badgeIds,
			workerIds,
			selectAllResources,
			selectedResourcesNumber,
			siteResourcesFilters,
			selectAllBadges,
			selectedBadgesNumber,
			availableBadgesFilters,
			automaticLinking,
		);
	}
}

export default LinkBadgesToSiteWorkers;
