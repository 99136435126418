import SiteRepository, { GetSitesFilter, LinkResourcesToSiteWarning } from "../../repositories/siteRepository";
import UseCase from '../../../core/app/usecase';
import { ResourceType } from '../../../presentation/screens/Site/ResourceSelectableTable';
import { GetResourcesFilter } from "../../repositories/workerRepository";

interface Args {
	companyId: string;
	resourceType: ResourceType;
	resourceIds: string[];
	siteIds: string[];
	selectAllSites?: boolean;
	selectAllResources?: boolean;
	resourceFilters?: GetResourcesFilter,
	siteFilters?: GetSitesFilter
}

class AddResourcesToSites implements UseCase<Args, LinkResourcesToSiteWarning> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, resourceType, resourceIds, siteIds, selectAllSites, selectAllResources, resourceFilters, siteFilters }: Args): Promise<LinkResourcesToSiteWarning> {
		return await this.siteRepository.addResourcesToSites(companyId, resourceType, resourceIds, siteIds, selectAllSites, selectAllResources, resourceFilters, siteFilters);
	}
}

export default AddResourcesToSites;
