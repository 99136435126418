import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';
import { Evaluable } from '../../entities/evaluable.enum';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { PendingEvaluationsFilters } from '../../../presentation/hooks/Site/useSiteApproveEvaluationsViewModel';

interface Args {
	companyId: string;
	siteId: string;
	resource: string;
	evaluationIds: string[];
	isApproved: boolean;
	type: Evaluable;
	selectAllEvaluations: boolean,
	filter?: PendingEvaluationsFilters,
}

class ApprovePendingEvaluation implements UseCase<Args, void> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, siteId, resource, evaluationIds, isApproved, type, selectAllEvaluations, filter }: Args): Promise<void> {
		return await this.siteRepository.approvePendingEvaluation(companyId, siteId, resource, evaluationIds, type, isApproved, selectAllEvaluations, filter);
	}
}

export default ApprovePendingEvaluation;
