import { SiteResourcesViewModel } from '../../hooks/Site/useSiteResourcesViewModel';
import { makeGetWorkingSiteMachines } from '../../../core/app/factory/siteMachines/usecases';
import { SiteMachine, SiteMachineParams } from '../../../domain/entities/siteMachine';
import SiteMachinesViewModel from '../sites/SiteMachinesViewModel';
import { GetWorkingSiteMachinesFilters } from '../../../domain/repositories/siteMachineRepository';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';

class WorkingSiteMachinesViewModel extends SiteMachinesViewModel implements SiteResourcesViewModel<SiteMachineParams> {
	async getSiteResources(
		companyId: string,
		siteId: string,
		page: number,
		archived?: boolean,
		filter?: GetWorkingSiteMachinesFilters,
		sort?: SortMeta,
	): Promise<PaginatedResults<SiteMachine>> {
		return await makeGetWorkingSiteMachines().Call({ companyId, siteId, page, perPage: 25, filter, sort, archived });
	}
}

export default WorkingSiteMachinesViewModel;
