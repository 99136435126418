import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';

type Parameters = {
	companyId: string;
	supplierId: string;
	variantId?: string;
};

class UpdateCompanySupplierVariant implements UseCase<Parameters, void> {
	constructor(private repository: SupplierRepository) {}

	async Call({ companyId, supplierId, variantId }: Parameters): Promise<void> {
		return this.repository.updateCompanySupplierVariant(companyId, supplierId, variantId);
	}
}

export default UpdateCompanySupplierVariant;
