import UseCase from '../../../core/app/usecase';
import { DashboardTopic } from '../../entities/dashboardTopic';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import DashboardRepository from '../../repositories/dashboardRepository';
import { DashboardUnevaluatedDocument } from '../../entities/dashboardUnevaluatedDocuments';

interface Args {
	sort: SortMeta;
	companyId: string;
	pageParam: number;
	topic: DashboardTopic;
	days?: number;
}

class GetDashboardDocuments implements UseCase<Args, DashboardUnevaluatedDocument[]> {
	private dashboardRepository: DashboardRepository;

	constructor(dashboardRepository: DashboardRepository) {
		this.dashboardRepository = dashboardRepository;
	}

	async Call({ companyId, topic, pageParam, sort, days }: Args): Promise<DashboardUnevaluatedDocument[]> {
		return await this.dashboardRepository.getDashboardDocuments(companyId, topic, pageParam, sort, days);
	}
}

export default GetDashboardDocuments;
