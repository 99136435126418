import UseCase from '../../../../core/app/usecase';
import SiteChemicalRepository from '../../../repositories/siteChemicalRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	chemicalId: string;
	variantId?: string;
};

class UpdateSiteChemicalVariant implements UseCase<Parameters, void | undefined> {
	constructor(private repository: SiteChemicalRepository) {}

	async Call({ companyId, siteId, chemicalId, variantId }: Parameters): Promise<void | undefined> {
		return this.repository.updateSiteChemicalVariant(companyId, siteId, chemicalId, variantId);
	}
}

export default UpdateSiteChemicalVariant;
