import { useState } from "react";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { GetBadgeHistoryFilters } from "../../../domain/repositories/badgeRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { BadgeDetailViewModel } from "../../viewmodels/badges/BadgeDetailViewModel";
import { BadgeStatus } from "../../../domain/entities/badgeStatus.enum";
import { GetResourceSitesFilter } from "../../../domain/repositories/siteRepository";
import Site from "../../../domain/entities/site";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import { BadgeLogEntry } from "../../../domain/entities/badgeLogEntry";
import Tag from "../../../domain/entities/tag";
import { updateFilterWithDelete } from "../../../utils";
import { GetResourcesFilter } from "../../../domain/repositories/workerRepository";
import Worker from "../../../domain/entities/worker";
import Vehicle from "../../../domain/entities/vehicle";
import Machine from "../../../domain/entities/machine";

export type BadgeStatusSite = {
  badgeStatus: BadgeStatus;
  site: Site;
};

const useBadgeDetailViewModel = (badgeId: string, siteId?: string) => {
  const { companyId } = useAuth();
  const viewModel = new BadgeDetailViewModel();
  const [resourceType, setResourceType] = useState<BadgeResourceType>();
  const [filters, setFilters] = useState<GetBadgeHistoryFilters>({});
  const [search, setSearch] = useState<string>();
  const [sort, setSort] = useState<SortMeta>(null);
  const [resourceSitesFilters, setResourceSitesFilters] =
    useState<GetResourceSitesFilter>({ companyId });
  const [resourceSiteSort, setResourceSitesSort] = useState<SortMeta>(null);
  const [resourceFilters, setResourceFilters] =
    useState<GetResourceSitesFilter>({ companyId });
  const [resourceSort, setResourceSort] = useState<SortMeta>(null);
  const [stampingMgmtActive, setStampingMgmtActive] = useState(false);
  const [tagRenameError, setTagRenameError] = useState<string>();
  const [error, setError] = useState<string>();
  const [linkResourceError, setLinkResourceError] = useState<string | null>();

  const getBadgeQuery = useQuery(["badges", companyId, badgeId], async () => {
    return await viewModel.getBadge(companyId, badgeId, siteId);
  });

  const resource = getBadgeQuery.data?.resource;

  const getResourceSites = useInfiniteQuery(
    [
      "resource-sites",
      companyId,
      resource?.type,
      resource?.id,
      resourceSiteSort,
      resourceSitesFilters,
    ],
    async ({ pageParam = 1 }) => {
      if (resource) {
        return await viewModel.getResourceSites(
          companyId,
          resource.type,
          resource.id,
          resourceSitesFilters,
          resourceSiteSort,
          pageParam
        );
      } else {
        return { results: [], count: 0 };
      }
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const updateBadgeStatusMutation = useMutation(
    (params: { status: BadgeStatus; siteId?: string }) =>
      viewModel.updateBadge(
        companyId,
        { id: badgeId, status: params.status },
        params.siteId
      ),
    {
      onSuccess: () => getBadgeQuery.refetch(),
    }
  );

  const createBadgeMutation = useMutation(
    async (siteId: string) =>
      await viewModel.createBadge(companyId, siteId, badgeId),
    {
      onSuccess: () => getBadgeSites.refetch(),
    }
  );

  const getBadgeSites = useQuery(
    ["badge-sites", companyId, badgeId],
    async () => {
      return await viewModel.getBadgeSites(companyId, badgeId);
    }
  );

  const unlinkBadgeResourceMutation = useMutation(
    (resourceType: BadgeResourceType) =>
      viewModel.unlinkBadgeResource(companyId, badgeId),
    {
      onSuccess: () => {
        getBadgeQuery.refetch();
      },
    }
  );

  const linkBadgeResourceMutation = useMutation(
    (params: Worker | Vehicle | Machine) => {
      return viewModel.linkBadgeResource(
        companyId,
        badgeId,
        resourceType,
        params.id
      );
    },
    {
      onSuccess: () => getBadgeQuery.refetch(),
      onError: (error: Error) => {
        setLinkResourceError(error.message);
        getBadgeQuery.refetch();
      },
    }
  );

  const getBadgeHistoryQuery = useInfiniteQuery(
    ["badges", companyId, badgeId, sort, filters],
    async ({ pageParam = 1 }) => {
      return await viewModel.getBadgeHistory(
        companyId,
        badgeId,
        sort,
        filters,
        pageParam
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const createBadgeLogEntry = useMutation(
    (badgeLogEntry: BadgeLogEntry) =>
      viewModel.createBadgeLogEntry(companyId, siteId, badgeLogEntry, badgeId),
    {
      onSuccess: () => getBadgeHistoryQuery.refetch(),
      onError: (error: Error) => {
        setError(error.message), getBadgeHistoryQuery.refetch();
      },
    }
  );

  const deleteBadgeLogEntryMutation = useMutation(
    (badgeLogEntry: BadgeLogEntry) =>
      viewModel.deleteBadgeLogEntry(companyId, siteId, badgeLogEntry),
    {
      onSuccess: () => getBadgeHistoryQuery.refetch(),
    }
  );

  const getSiteBadgesReadersQuery = useQuery(
    ["getBadgeReaders", companyId],
    async () => {
      return await viewModel.getSiteBadgeReaders(companyId, siteId);
    },
    {
      initialData: [],
      enabled: stampingMgmtActive,
    }
  );

  const getTagsQuery = useQuery(
    ["tags", companyId],
    async () => {
      return await viewModel.getTags(companyId);
    },
    {
      initialData: [],
    }
  );

  const unlinkBadgeTag = useMutation(
    (badgeTag: Tag) =>
      viewModel.unlinkBadgeTag(companyId, siteId, badgeId, badgeTag),
    {
      onSuccess: () => getBadgeQuery.refetch(),
    }
  );
  const linkBadgeTag = useMutation(
    (badgeTag: Tag) => {
      return viewModel.linkBadgeTag(companyId, siteId, badgeId, badgeTag);
    },
    {
      onSuccess: () => getBadgeQuery.refetch(),
    }
  );
  const getResources = useInfiniteQuery(
    [
      "resources",
      companyId,
      search,
      resourceType,
      resourceSort,
      resourceFilters,
    ],
    async ({ pageParam = 1 }) => {
      const filters = search ? { ...resourceFilters, search } : resourceFilters;
      const { results } = await viewModel.getCompanyResources(
        companyId,
        resourceType,
        filters,
        resourceSort,
        pageParam
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );
  const updateTagBadgeMutation = useMutation(
    async (tag: Tag) => {
      return await viewModel.updateTagBadge(companyId, tag);
    },
    {
      onSuccess: async () => {
        await getTagsQuery.refetch();
        await getBadgeQuery.refetch();
      },
      onError: (e) => {
        console.error(e);
        getTagsQuery.refetch();
        getBadgeQuery.refetch();
      },
    }
  );

  const updateTagBadge = async (tag: Tag) => {
    try {
      await updateTagBadgeMutation.mutateAsync(tag);
    } catch (err) {
      setTagRenameError("existingTag");
    }
  };

  const updateFilter = (
    field: keyof GetBadgeHistoryFilters,
    value: string | string[] | [Date, Date]
  ) => {
    updateFilterWithDelete(setFilters, field, value);
  };

  const updateResourceSitesFilter = (
    field: keyof GetResourceSitesFilter,
    value: string
  ) => {
    updateFilterWithDelete(setResourceSitesFilters, field, value);
  };

  const updateResourcesFilter = (
    field: keyof GetResourcesFilter,
    value: string
  ) => {
    updateFilterWithDelete(setResourceFilters, field, value);
  };
  const badgeSiteIds = getBadgeSites.data?.map(
    (badgeSite) => badgeSite.site.id
  );
  const availableResources = getResources.data?.pages?.flat() ?? [];

  const resourceSites =
    getResourceSites.data?.pages
      ?.flatMap((page) => page.results)
      ?.filter((badgeSite) => !badgeSiteIds?.includes(badgeSite.id)) ?? [];
  const totalSiteCount = getResourceSites.data?.pages?.[0]?.count ?? 0;
  return {
    badge: getBadgeQuery.data,
    badgeFetching: getBadgeQuery.isLoading,
    isTagLoading:
      getBadgeQuery.isFetching ||
      getTagsQuery.isFetching ||
      unlinkBadgeTag.isLoading ||
      linkBadgeTag.isLoading ||
      updateTagBadgeMutation.isLoading,
    badgeReaders: getSiteBadgesReadersQuery.data,
    setStampingMgmtActive,
    resourceSites,
    resourceSitesHasNextPage: getResourceSites.hasNextPage,
    resourceSitesFetchNextPage: getResourceSites.fetchNextPage,
    resourceSitesFilters,
    updateResourceSitesFilter,
    resourceSiteSort,
    setResourceSitesSort,
    updateBadgeStatus: updateBadgeStatusMutation.mutate,
    filters,
    updateFilter,
    sort,
    setSort,
    cloneBadge: createBadgeMutation.mutateAsync,
    badgeStatusSites: getBadgeSites.data,
    badgeStatusSitesIsLoading: getBadgeSites.isLoading,
    unlinkBadgeResource: unlinkBadgeResourceMutation.mutateAsync,
    history: getBadgeHistoryQuery?.data?.pages?.flat() ?? [],
    historyHasNextPage: getBadgeHistoryQuery.hasNextPage,
    historyFetchNextPage: getBadgeHistoryQuery.fetchNextPage,
    historyIsLoading: getBadgeHistoryQuery.isLoading,
    createBadgeLogEntry: createBadgeLogEntry.mutate,
    deleteBadgeLogEntry: deleteBadgeLogEntryMutation.mutateAsync,
    deleteBadgeLogEntryIsLoading: deleteBadgeLogEntryMutation.isLoading,
    allTags: getTagsQuery.data,
    linkBadgeTag: linkBadgeTag.mutate,
    unlinkBadgeTag: unlinkBadgeTag.mutate,
    updateTagBadge,
    tagRenameError,
    error,
    setError,
    linkResource: linkBadgeResourceMutation.mutateAsync,
    linkBadgeResourceMutationLoading: linkBadgeResourceMutation.isLoading,
    linkResourceError,
    setLinkResourceError,
    search,
    setSearch,
    availableResources,
    totalSiteCount,
    availableResourcesLoading: getResources.isLoading,
    availableResourcesHasNextPage: getResources.hasNextPage,
    availableResourcesFetchNextPage: getResources.fetchNextPage,
    resourceFilters,
    setResourceFilters,
    resourceSort,
    setResourceSort,
    setResourceType,
    updateResourcesFilter,
    resourceType,
  };
};

export { useBadgeDetailViewModel };
