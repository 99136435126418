import UseCase from '../../../../core/app/usecase';
import Document from '../../../entities/document';
import VehicleRepository from '../../../repositories/vehicleRepository';
import { GetDocumentsFilter } from '../../../repositories/documentRepository';
import { SortMeta } from '../../../entities/interfaces/paginatedResults';

type GetVehicleDocumentsParameters = {
	companyId: string;
	resourceId: string;
	filter?: GetDocumentsFilter;
	sort?: SortMeta;
	pageParam?: number;
};

/**
 * Returns the list of all the existing Document.
 */
class GetVehicleDocuments implements UseCase<GetVehicleDocumentsParameters, Document[]> {
	constructor(private repository: VehicleRepository) {}

	async Call({ companyId, resourceId, sort, filter, pageParam }: GetVehicleDocumentsParameters): Promise<Document[]> {
		return await this.repository.getVehicleDocuments(companyId, resourceId, filter, sort, pageParam);
	}
}

export default GetVehicleDocuments;
