import UseCase from '../../../core/app/usecase';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import Variant from '../../entities/variant';
import VariantRepository from '../../repositories/variantRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	type: DocumentTypeCategory;
	search?: string;
};

class GetSiteVariants implements UseCase<ARGS, Variant[]> {
	private variantRepository: VariantRepository;

	constructor(variantRepository: VariantRepository) {
		this.variantRepository = variantRepository;
	}

	async Call(args: ARGS): Promise<Variant[]> {
		return await this.variantRepository.getSiteVariants(args.companyId, args.siteId, args.type, args.search);
	}
}

export default GetSiteVariants;
