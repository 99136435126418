import { makeGetSitesForRoleAssignment } from "../../../core/app/factory/sites/usecases";
import Role, { RoleContext } from "../../../domain/entities/role";
import Site from "../../../domain/entities/site";
import { UserSiteRole } from "../../../domain/entities/user";
import { GetSiteRolesFilter } from "../../../domain/repositories/filters";

import {
  makeGetAvailableSites,
  makeGetRoles,
  makeGetUserSiteRoles,
  makeGetWorkingSiteAvailable,
} from "../../../core/app/factory/roles/usecases";
import Supplier from "../../../domain/entities/supplier";

class UserSiteRolesViewModel {
  private getRolesUC = makeGetRoles();
  private getUserSiteRolesUC = makeGetUserSiteRoles();
  private getWorkingSitesUC = makeGetWorkingSiteAvailable();
  private getSitesUC = makeGetAvailableSites();

  constructor(
    private companyId: string,
    private userId: string,
  ) {}

  async getWorkingSites(): Promise<Site[]> {
    return this.getWorkingSitesUC.Call({ companyId: this.companyId });
  }

  async getSites(): Promise<Site[]> {
    return this.getSitesUC.Call({ companyId: this.companyId });
  }

  async getRoles(context?: RoleContext): Promise<Role[]> {
    return this.getRolesUC.Call({
      companyId: this.companyId,
      filter: { contextFilter: context },
    });
  }

  async getUserSiteRoles(
    filters?: GetSiteRolesFilter,
  ): Promise<UserSiteRole[]> {
    const results =
      (await this.getUserSiteRolesUC.Call({
        companyId: this.companyId,
        userId: this.userId,
        filters,
      })) ?? [];

    return results;
  }
}

export default UserSiteRolesViewModel;
