import Worker, { WorkerStatus } from "../../../domain/entities/worker";
import {
  makeAddResourceToSites,
  makeGetAvailableBadgesUseCase,
  makeGetWorker,
  makeLinkBadgesResource,
  makeUpdateWorker,
  makeUpdateWorkerStatus,
} from "../../../core/app/factory/workers/usecases";
import {
  makeGetResourceLinkableSites,
  makeGetWorkerSites,
} from "../../../core/app/factory/sites/usecases";
import Site, { ResourceSites } from "../../../domain/entities/site";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import {
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import Badge from "../../../domain/entities/badge";
import {
  GetAvailableBadgesFilters,
} from "../../../domain/repositories/badgeRepository";
import {
  makeUnlinkBadgeResource,
} from "../../../core/app/factory/badges/usecases";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import {makeGetCustomFields} from "../../../core/app/factory/companies/usecases";
import {CustomField} from "../../../domain/entities/customFields";
import {RequirementSubject} from "../../../domain/entities/requirementSubject.enum";

class WorkerViewModel {
  private getWorkerUseCase = makeGetWorker();
  private updateWorkerUseCase = makeUpdateWorker();
  private updateWorkerStatusUseCase = makeUpdateWorkerStatus();
  private getWorkerSitesUseCase = makeGetWorkerSites();
  private getResourceLinkableSitesUseCase = makeGetResourceLinkableSites();
  private linkResourceToSitesUseCase = makeAddResourceToSites();
  private getAvailableBadgesUseCase = makeGetAvailableBadgesUseCase();
  private linkBadgesToResourceUseCase = makeLinkBadgesResource();
  private unlinkBadgeResourceUseCase = makeUnlinkBadgeResource();
  private getCustomFieldsUseCase = makeGetCustomFields();

  async get(companyId: string, workerId: string): Promise<Worker> {
    return await this.getWorkerUseCase.Call({ companyId, workerId });
  }

  async getWorkerSites(
    companyId: string,
    workerId: string,
    sort: SortMeta,
    filter: GetSitesFilter,
    pageParam: number,
    includeWorkingSites?: boolean,
    ignoreUserSiteRoleRestrictions?: boolean,
    documentTypeId?: string,
  ): Promise<ResourceSites> {
    return await this.getWorkerSitesUseCase.Call({
      companyId,
      workerId,
      sort,
      filter,
      pageParam,
      includeWorkingSites,
      ignoreUserSiteRoleRestrictions,
      documentTypeId
    });
  }

  async getAvailableBadges(
    companyId: string,
    resource: string,
    resourceId: string,
    sort: SortMeta,
    filter: GetAvailableBadgesFilters,
  ): Promise<Badge[]> {
    return await this.getAvailableBadgesUseCase.Call({
      companyId,
      resource,
      resourceId,
      sort,
      filter,
    });
  }

  async getResourceLinkableSites(
    companyId: string,
    resourceType: string,
    resourceId: string,
    sort: SortMeta,
    filters: GetSitesFilter,
    pageParam: number,
  ): Promise<{ results:Site[], count: number }> {
    return await this.getResourceLinkableSitesUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      sort,
      filters,
      pageParam,
    });
  }

  async linkBadgesToResource(
    companyId: string,
    badgeIds: string[],
    resourceType: BadgeResourceType,
    resourceId: string,
  ): Promise<void> {
    return await this.linkBadgesToResourceUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      badgeIds,
    });
  }

  async unlinkBadgeResource(companyId: string, badgeId: string): Promise<void> {
    return await this.unlinkBadgeResourceUseCase.Call({ companyId, badgeId });
  }

  async update(
    companyId: string,
    worker: Worker,
    imageFile?: File,
  ): Promise<Worker> {
    return await this.updateWorkerUseCase.Call({
      companyId,
      worker,
      imageFile,
    });
  }

  async setStatus(
    companyId: string,
    workerId: string,
    status: WorkerStatus,
  ): Promise<void> {
    return await this.updateWorkerStatusUseCase.Call({
      companyId,
      workerId,
      status,
    });
  }
  async linkResourceToSites(
    companyId: string,
    workerId: string,
    siteIds: string[],
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ): Promise<void> {
    return await this.linkResourceToSitesUseCase.Call({
      companyId,
      resourceType: "worker",
      resourceId: workerId,
      siteIds,
      selectAll,
      filters,
    });
  }

  async getCustomFields(companyId: string): Promise<CustomField[]> {
    return await this.getCustomFieldsUseCase.Call({ companyId, subject: RequirementSubject.WORKER });
  }
}

export { WorkerViewModel };
