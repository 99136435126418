import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import DangerIcon from "../DangerIcon";

type BaseModalProps = {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  type?: "warning";
  onClose: () => void;
  onConfirm?: () => void;
  onConfirmLabel?: string;
  onCancel?: boolean | (() => void);
  onCancelLabel?: string;
  footer?: React.ReactNode | React.ReactNode[];
  size?: string;
  onConfirmDisabled?: boolean;
  onCancelDisabled?: boolean;
  disableOnClose?: boolean;
  isLoading?: boolean;
  bodyOverflow?: "hidden" | "unset" | "auto" | "scroll" | "visible";
  avoidCloseOnOnConfirm?: boolean;
  includeFooterButton?: boolean;
  closeOnOverlayClick?: boolean;
};

const BaseModal = ({
  children,
  footer,
  title,
  type,
  onClose,
  onCancel = true,
  onConfirm,
  onCancelLabel,
  onConfirmLabel,
  onCancelDisabled,
  onConfirmDisabled,
  disableOnClose,
  size = "4xl",
  isLoading = false,
  avoidCloseOnOnConfirm,
  includeFooterButton = true,
  bodyOverflow,
  closeOnOverlayClick,
}: BaseModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size={size}
      closeOnOverlayClick={closeOnOverlayClick}
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        maxHeight="85vh"
        style={{ background: "white", padding: "2rem" }}
      >
        {title && <ModalHeader>{title}</ModalHeader>}
        {!title && type === "warning" && (
          <ModalHeader
            sx={{
              display: "flex",
              textTransform: "uppercase",
              alignItems: "center",
            }}
          >
            <DangerIcon fill="red" mr={5} />
            {t(type)}
          </ModalHeader>
        )}

        <ModalCloseButton />

        {children && (
          <ModalBody
            zIndex={bodyOverflow === "unset" ? "10" : "1"}
            overflowY={bodyOverflow}
          >
            {children}
          </ModalBody>
        )}
        {includeFooterButton && (
          <ModalFooter gap={5}>
            {footer}

            {!footer && onConfirm && (
              <Button
                isLoading={isLoading}
                isDisabled={onConfirmDisabled}
                colorScheme="blue"
                onClick={async () => {
                  try {
                    await onConfirm();
                    if (
                      !disableOnClose &&
                      !avoidCloseOnOnConfirm &&
                      !isLoading
                    ) {
                      onClose();
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                {onConfirmLabel ?? t("confirm")}
              </Button>
            )}
            {!footer && onCancel && (
              <Button
                isDisabled={onCancelDisabled}
                colorScheme="red"
                onClick={() => {
                  if (typeof onCancel === "function") onCancel();
                  !disableOnClose && onClose();
                }}
              >
                {onCancelLabel ?? t("cancel")}
              </Button>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default BaseModal;
