import useWebHookViewModel from "../../../hooks/WebHook/useWebHookViewModel";
import LoadingView from "../../Common/LoadingView";
import WebHookContainer from "./WebHookContainer";
import ErrorModals from "./ErrorModals";

const WebHookView = () => {
  const hook = useWebHookViewModel();

  const loadingQuery = Object.keys(hook).find(
    (query) => query !== "setWebHooks" && hook[query].status === "loading",
  );

  return (
    <>
      <ErrorModals {...hook} />
      {loadingQuery ? <LoadingView /> : <WebHookContainer hook={hook} />}
    </>
  );
};

export default WebHookView;
