import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../components/Common/TopBar";
import { useAuth } from "../../providers/Auth0JWTProvider";

const UserSettingsTopBar = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const items: TopBarItem[] = [
    {
      title: t("userSettingsTopBar", { ns: "userSettings" }),
      path: "/userSettings",
    },
  ];

  return <TopBar title={user.name} items={items} />;
};

export default UserSettingsTopBar;
