import { FC } from "react";
import { Th, Tr } from "@chakra-ui/react";
import LabelFilter from "../select/LabelFilter";
import Tag from "../../../../domain/entities/tag";

export interface LabelFilterColumn {
  id: string;
  title: string;
  labels?: Array<Tag>;
  onFilter?: (selected: Array<Tag>) => void;
  items?: Array<Tag>;
  width?: string;
}

interface TableViewLabelFiltersHeaderProps {
  columns: Array<LabelFilterColumn>;
}

const TableViewLabelFiltersHeader: FC<TableViewLabelFiltersHeaderProps> = ({
  columns,
}) => {
  return (
    <Tr bg="gray.300">
      {columns.map((c) => (
        <Th key={c.id} verticalAlign="top" textColor="gray.700" width={c.width}>
          {c.labels && c.onFilter && c.items && (
            <LabelFilter
              options={c.labels}
              items={c.items}
              id={`filter-${c.id}`}
              onChange={c.onFilter}
              margin="0px 8px 0px 0px"
            />
          )}
          {c.title}
        </Th>
      ))}
    </Tr>
  );
};

export default TableViewLabelFiltersHeader;
