import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	supplierId: string;
};

class AutoEvaluateSupplier implements UseCase<Parameters, void> {
	constructor(private repository: SupplierRepository) {}

	async Call({ companyId, siteId, supplierId }: Parameters): Promise<void> {
		return this.repository.autoEvaluateSupplier(companyId, siteId, supplierId);
	}
}

export default AutoEvaluateSupplier;
