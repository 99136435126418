import { createUseRequirementsViewModel } from "../Requirements/useRequirementsViewModel";
import { CompanyReqGroupDetailViewModel } from "../../viewmodels/company/CompanyReqGroupDetailViewModel";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";

const useCompanyReqGroupDetailViewModel = (target: RequirementSubject) =>
  createUseRequirementsViewModel(
    () => new CompanyReqGroupDetailViewModel(target),
  );

export default useCompanyReqGroupDetailViewModel;
