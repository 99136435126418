import { FC } from "react";
import { useTranslation } from "react-i18next";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { RxDot, RxDotFilled } from "react-icons/rx";
import { COLORS } from "../../../assets/theme/colors";
import { SiteResourceDocument } from "../../../../domain/entities/document";
import { ResourceEvaluationState } from "../../../../domain/entities/resourceEvaluationState.enum";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

export interface RejectionReason {
  id: string;
  name: string;
  isRequired: boolean;
  evaluation?: ResourceDocumentEvaluationState | ResourceEvaluationState;
}

export interface RejectionReasonGroup {
  subTitle?: string;
  reasons: RejectionReason[];
  resource:
    | "company"
    | "site"
    | "chemical"
    | "machine"
    | "tool"
    | "vehicle"
    | "worker";
}

interface RejectionRowProps {
  reason: RejectionReason;
}

/**
 * Assumes that item *actually represents* a rejection cause.
 */
const RejectionRow: FC<RejectionRowProps> = ({ reason }) => {
  const { t } = useTranslation("common"); // Uses common dictionary.

  return (
    <HStack>
      {reason.isRequired && !reason.evaluation && (
        <>
          <RxDot color={COLORS.red} />
          <Text>
            {t("resourceRejected.reason_required", { document: reason.name })}
          </Text>
        </>
      )}

      {reason.evaluation && (
        <>
          <RxDotFilled color={COLORS.red} />
          <Text>
            {t("resourceRejected.reason_rejected", { document: reason.name })}
          </Text>
        </>
      )}
    </HStack>
  );
};

interface RejectionSummaryProps {
  groups: RejectionReasonGroup[];
}

/**
 * Renders the summarised list of rejection causes;
 * groups are assumed to be non-empty sets of things that *actually represent* rejection causes.
 */
const RejectionSummary: FC<RejectionSummaryProps> = ({ groups }) => {
  const { t } = useTranslation("common"); // Uses common dictionary.

  const nonEmptyGroups = groups.filter((g) => g.reasons.length > 0);

  if (nonEmptyGroups.length <= 0) {
    return <></>;
  }

  return (
    <Accordion
      allowToggle
      borderRadius={8}
      backgroundColor={COLORS.lightRed}
      borderColor={"#F"}
    >
      {nonEmptyGroups.map((g) => (
        <AccordionItem border="none" key={g?.reasons[0]?.id}>
          <AccordionButton>
            <HStack spacing={2} flex={1}>
              <WarningTwoIcon color={COLORS.red} fontSize="larger" />
              <Text>
                {t("resourceRejected.title", {
                  context: g.resource,
                  count: g.reasons.length,
                })}
              </Text>
              {g.subTitle && <Text>({g.subTitle})</Text>}
            </HStack>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <VStack spacing={2} alignItems="start">
              {g.reasons.map((r) => (
                <RejectionRow reason={r} key={r.id} />
              ))}
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

RejectionSummary.defaultProps = {
  groups: [],
};

export default RejectionSummary;

// Support functions.
interface RejectionGroupsMapItem {
  subTitle?: string;
  documents: SiteResourceDocument[];
  resource:
    | "company"
    | "site"
    | "chemical"
    | "machine"
    | "tool"
    | "vehicle"
    | "worker";
}

export const mapRejectionGroups = (
  source: RejectionGroupsMapItem[],
): RejectionReasonGroup[] => {
  const out: RejectionReasonGroup[] = [];

  for (const item of source) {
    const reasons = item.documents
      .filter(
        (d) =>
          // Get required but unevaluated documents.
          (d?.isOptional !== true && !d?.evaluationResult) ||
          // Get rejected documents.
          d?.evaluationResult ===
            ResourceDocumentEvaluationState.NOT_AVAILABLE ||
          d?.evaluationResult === ResourceDocumentEvaluationState.UNDEFINED,
      )
      .map((d) => ({
        id: d?.documentId,
        name: d?.documentTypeName,
        evaluation: d?.evaluationResult,
        isRequired: d?.isOptional !== true,
      }));

    out.push({ reasons, subTitle: item.subTitle, resource: item.resource });
  }

  return out;
};
