import { makeSiteWorkerRepository } from './repositories';
import GetSiteWorker from '../../../../domain/interactors/resources/siteWorkers/getSiteWorker';
import GetSiteWorkers from '../../../../domain/interactors/resources/siteWorkers/getSiteWorkers';
import CreateSiteWorker from '../../../../domain/interactors/resources/siteWorkers/createSiteWorker';
import DeleteSiteWorker from '../../../../domain/interactors/resources/siteWorkers/deleteSiteWorker';
import RestoreSiteWorker from '../../../../domain/interactors/resources/siteWorkers/restoreSiteWorker';
import EvaluateSiteWorker from '../../../../domain/interactors/resources/siteWorkers/evaluateSiteWorker';
import UpdateSiteWorkerMeta from '../../../../domain/interactors/resources/workers/updateSiteWorkerMeta';
import GetWorkingSiteWorkers from '../../../../domain/interactors/resources/siteWorkers/getWorkingSiteWorkers';
import AutoEvaluateSiteWorker from '../../../../domain/interactors/resources/siteWorkers/autoEvaluateSiteWorker';
import GetSiteWorkerDocuments from '../../../../domain/interactors/resources/siteWorkers/getSiteWorkerDocuments';
import UpdateSiteWorkerVariant from '../../../../domain/interactors/resources/siteWorkers/updateSiteWorkerVariant';
import GetSiteWorkerEvaluations from '../../../../domain/interactors/resources/siteWorkers/getSiteWorkerEvaluations';
import AddRequirementToSiteWorker from '../../../../domain/interactors/resources/siteWorkers/addRequirementToSiteWorker';
import UpdateSiteWorkerGlobalStatus from '../../../../domain/interactors/resources/siteWorkers/updateSiteWorkerGlobalStatus';
import UpdateSiteWorkerSpecializations from '../../../../domain/interactors/resources/siteWorkers/updateSiteWorkerSpecializations';

export const makeGetSiteWorkers = () => {
	return new GetSiteWorkers(makeSiteWorkerRepository());
};

export const makeGetWorkingSiteWorkers = () => {
	return new GetWorkingSiteWorkers(makeSiteWorkerRepository());
};

export const makeGetSiteWorker = () => {
	return new GetSiteWorker(makeSiteWorkerRepository());
};

export const makeCreateSiteWorker = () => {
	return new CreateSiteWorker(makeSiteWorkerRepository());
};

export const makeDeleteSiteWorker = () => {
	return new DeleteSiteWorker(makeSiteWorkerRepository());
};

export const makeRestoreSiteWorker = () => {
	return new RestoreSiteWorker(makeSiteWorkerRepository());
};

//TODO rename this method in getSiteWorkerRequirement
export const makeGetSiteWorkerDocuments = () => {
	return new GetSiteWorkerDocuments(makeSiteWorkerRepository());
};

export const makeUpdateSiteWorkerVariant = () => {
	return new UpdateSiteWorkerVariant(makeSiteWorkerRepository());
};

export const makeUpdateSiteWorkerSpecializations = () => {
	return new UpdateSiteWorkerSpecializations(makeSiteWorkerRepository());
};

export const makeEvaluateSiteWorker = () => {
	return new EvaluateSiteWorker(makeSiteWorkerRepository());
};

export const makeAutoEvaluateSiteWorker = () => {
	return new AutoEvaluateSiteWorker(makeSiteWorkerRepository());
};

export const makeGetSiteWorkerEvaluations = () => {
	return new GetSiteWorkerEvaluations(makeSiteWorkerRepository());
};

export const makeAddRequirementToSiteWorker = () => {
	return new AddRequirementToSiteWorker(makeSiteWorkerRepository());
};

export const makeUpdateSiteWorkerMeta = () => {
	return new UpdateSiteWorkerMeta(makeSiteWorkerRepository());
};

export const makeUpdateSiteWorkerGlobalStatus = () => {
	return new UpdateSiteWorkerGlobalStatus(makeSiteWorkerRepository());
};
