import { FC, useState } from "react";
import { MdClose } from "react-icons/md";
import Tag from "../../../domain/entities/tag";
import { useTranslation } from "react-i18next";
import InputAnimatedLabel from "../../components/Common/InputAnimatedLabel";
import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  Text,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Divider,
  Spacer,
} from "@chakra-ui/react";
import SelectSitesView from "../../components/Views/common/SelectSitesView";
import Requirement from "../../../domain/entities/requirement";
import Variant from "../../../domain/entities/variant";
import Specialization from "../../../domain/entities/specialization";
import Site from "../../../domain/entities/site";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import { InfoBanner } from "../../components/Common/alerts/InfoBanner";
import MultiTagSelect from "../../components/Common/TagSelect/MultiTagSelect";
import { UpdateFilter } from "../../hooks/Requirements/useRequirementsViewModel";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

// Props.
interface EditRequirementProps {
  handleRequirementUpdate: (
    requirement: Requirement,
    sitesIds: string[],
    selectAllSites?: boolean,
  ) => void;
  requirement: Requirement;
  onClose: () => void;
  updateTag?: (tag: Tag) => void;
  variants: Variant[];
  specializations: Specialization[];
  createVariantApi?: (variant: Variant) => Promise<Variant>;
  createVariantIsBusy: boolean;
  createSpecializationApi?: (
    specialization: Specialization,
  ) => Promise<Specialization>;
  createSpecializationIsBusy: boolean;
  showSiteSelection?: boolean;
  namespace?: string;
  propagableSites?: Site[];
  propagableSitesHasNextPage?: boolean,
  propagableSitesFetchNextPage?: () => void,
  propagableSitesCount?: number,
  propagableSitesIsFetching?: boolean,
  updatePropagableSites?: UpdateFilter,
  propagableFilterSites?: GetSitesFilter,
  setSortSites?: (value: SortMeta) => void,
  sortSites?: SortMeta,
}

const RequirementUpdateModal: FC<EditRequirementProps> = ({
  requirement,
  handleRequirementUpdate,
  showSiteSelection,
  onClose,
  updateTag,
  variants,
  specializations,
  createVariantApi,
  createVariantIsBusy,
  createSpecializationApi,
  createSpecializationIsBusy,
  namespace,
  propagableSites,
  propagableSitesHasNextPage,
  propagableSitesFetchNextPage,
  propagableSitesCount,
  propagableSitesIsFetching,
  propagableFilterSites,
  updatePropagableSites,
  setSortSites,
  sortSites,
}) => {
  const { t } = useTranslation();
  const [localRequirement, setLocalRequirement] = useState<
    Requirement | undefined
  >(requirement);

  const [selectedVariants, setSelectedVariants] = useState<Tag[]>(
    requirement?.variants ?? [],
  );
  const [selectedSpecializations, setSelectedSpecializations] = useState<Tag[]>(
    requirement?.specializations ?? [],
  );
  const [selectAllSites, setSelectAllSites] = useState<boolean>(false);
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>([]);
  const [showSelectSites, setShowSelectSites] = useState(false);
  const [showInfoBanner, setShowInfoBanner] = useState<boolean>(true);
  const [requirementIsLoading, setRequirementIsLoading] =
    useState<boolean>(false);

  const isSpecializationRequired =
    requirement.subject !== RequirementSubject.SUPPLIER;

  const handleChangeInput = (key: string, value: string | number) => {
    setLocalRequirement({
      ...localRequirement,
      [key]: value,
    });
  };

  const onConfirm = async () => {
    const updatedSelectedVariants = selectedVariants.map((variant) => {
      const createdVariant = variants.find(
        (created) => variant.name === created.name,
      );
      if (createdVariant) {
        return createdVariant.id;
      } else {
        return variant.name;
      }
    });

    let updatedSelectedSpecializations = [];
    if (isSpecializationRequired) {
      updatedSelectedSpecializations = selectedSpecializations.map(
        (specialization) => {
          const createdSpecialization = specializations.find(
            (created) => specialization.name === created.name,
          );
          if (createdSpecialization) {
            return createdSpecialization.id;
          } else {
            return specialization;
          }
        },
      );
    }

    if (showSelectSites || !showSiteSelection) {
      setRequirementIsLoading(true);

      await handleRequirementUpdate(
        {
          ...localRequirement,
          variants: [...variants].filter((t) =>
            updatedSelectedVariants.includes(t.id),
          ),
          specializations: isSpecializationRequired
            ? [...specializations].filter((t) =>
              updatedSelectedSpecializations.includes(t.id),
            )
            : [],
        },
        selectAllSites ? [] : selectedSiteIds,
        selectAllSites,
      );

      setRequirementIsLoading(false);
      onClose();
      return;
    }
    if (showSiteSelection) {
      setShowSelectSites(true);
    }
  };

  const onCancel = () => {
    if (showSelectSites) {
      setShowSelectSites(false);
      return;
    }
    onClose();
  };

  const createVariant = async (variantName: string) => {
    const variantCreated = await createVariantApi({
      id: variantName,
      name: variantName,
      color: "#CCC",
      type: requirement.subject as unknown as DocumentTypeCategory,
    });
    setSelectedVariants([...selectedVariants, variantCreated]);
  };

  const createSpecialization = async (specializationName: string) => {
    const specializationCreated = await createSpecializationApi({
      id: specializationName,
      name: specializationName,
      color: "#CCC",
      type: requirement.subject as unknown as DocumentTypeCategory,
    });
    setSelectedSpecializations([
      ...selectedSpecializations,
      specializationCreated,
    ]);
  };

  function isOptionalChange(checked: boolean): void {
    setLocalRequirement({
      ...localRequirement,
      isOptional: checked,
    });
  }

  return (
    <Modal isOpen={true} onClose={onClose} size={"3xl"} trapFocus={false}>
      <ModalOverlay />

      <ModalContent backgroundColor="white" padding={5}>
        <ModalHeader>
          <Flex
            flex={1}
            h="100%"
            w="100%"
            alignItems="end"
            justifyContent={"end"}
            flexDirection="row"
          >
            <IconButton
              marginTop={-2}
              marginRight={-5}
              fontSize="3xl"
              size="2xl"
              backgroundColor="transparent"
              onClick={onClose}
              aria-label="Edit"
            >
              <MdClose />
            </IconButton>
          </Flex>
          <Flex
            flex={1}
            h="100%"
            w="100%"
            alignItems="center"
            textAlign="center"
            justifyContent={"center"}
            flexDirection="row"
          >
            <Text textColor={"black"} fontSize={24}>
              {t("editRequirementSetting", { ns: "requirements" })}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody overflowY={"unset"} zIndex={10}>
          {!showSelectSites && (
            <Flex
              flex={1}
              h="100%"
              w="100%"
              rounded="md"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              {namespace === "sites" && showInfoBanner && (
                <InfoBanner
                  text={t("propagateRequirementInfo", { ns: "documents" })}
                  onClose={() => setShowInfoBanner(false)}
                />
              )}

              <Flex width={"100%"} justifyContent={"space-between"}>
                <Text>
                  {t("requirementName", { ns: "requirements" })}
                </Text>
                <Spacer></Spacer>
                <Text id="name" defaultValue={requirement?.documentType?.name} fontWeight={"bold"}>
                  {requirement?.documentType?.name}
                </Text>
              </Flex>
              <Divider />

              {requirement["source"] && <Flex width={"100%"} justifyContent={"space-between"} marginTop={5}>
                <Text>
                  {t("requirementSource", { ns: "requirements" })}
                </Text>
                <Spacer></Spacer>
                <Text id="name" fontWeight={"bold"}>
                  {t(requirement["source"], { ns: "common" })}
                </Text>
              </Flex>}
              <Divider />

              <Flex
                marginTop={5}
                width={"100%"}
                justifyContent={"space-between"}
              >
                <Text id="isOptional" width="100%">
                  {t("editOptional", { ns: "requirements" })}
                </Text>
                <Switch
                  isChecked={!localRequirement?.isOptional}
                  onChange={(e) => isOptionalChange(!e.target.checked)}
                />
              </Flex>
              <Divider />
              <Flex
                marginTop={5}
                width={"100%"}
                justifyContent={"space-between"}
                flexDirection={"column"}
              >
                <Text id="isOptional" width="100%">
                  {t("insertGraceDays", { ns: "requirements" })}
                </Text>
                <InputAnimatedLabel
                  id="graceDays"
                  label={t("graceDays", { ns: "requirements" })}
                  type="number"
                  defaultValue={requirement?.graceDays.toString() ?? "0"}
                  handleOnChange={handleChangeInput}
                  showButton={false}
                  width="100%"
                  marginTop={2}
                />
              </Flex>
              <Text width="100%" marginTop={5}>
                {t("selectVariant", { ns: "requirements" })}
              </Text>
              <MultiTagSelect
                tags={variants}
                selectedTags={selectedVariants}
                setSelectedTags={setSelectedVariants}
                createTag={createVariant}
                fullWidth={true}
                placeholder={t("selectVariantPlaceholder", {
                  ns: "requirements",
                })}
              />
              {isSpecializationRequired && (
                <Flex
                  flexDirection={"column"}
                  width={"100%"}
                  overflow={"visible"}
                >
                  <Text width="100%">
                    {t("selectSpecialization", { ns: "requirements" })}
                  </Text>

                  <MultiTagSelect
                    tags={specializations}
                    selectedTags={selectedSpecializations}
                    setSelectedTags={setSelectedSpecializations}
                    defaultMenuIsOpen={false}
                    createTag={createSpecialization}
                    placeholder={t("selectSpecializationPlaceholder", {
                      ns: "requirements",
                    })}
                  />
                </Flex>
              )}
            </Flex>
          )}
          {showSelectSites && (
            <SelectSitesView
              autosize
              title={t("selectSitesUpdateRequirement", { ns: "requirements" })}
              reminderText={t("noSiteSelected", { ns: "common" })}
              alertText={t("siteSelectionAlert", { ns: "common" })}
              siteSelectedAction={setSelectedSiteIds}
              siteList={propagableSites}
              showSelectAll={true}
              includeFooterButton={false}
              siteCount={propagableSitesCount}
              hasNextPage={propagableSitesHasNextPage}
              fetchNextPage={propagableSitesFetchNextPage}
              isFetchingSites={propagableSitesIsFetching}
              filterResourceSites={propagableFilterSites}
              updateFilterResourceSites={updatePropagableSites}
              setSortResourceSites={setSortSites}
              sortResourceSites={sortSites}
              setSelectAllSites={setSelectAllSites}
              selectAllSites={selectAllSites}
              />
          )}
        </ModalBody>
        <ModalFooter>
          <Flex grow={1} />
          <Button
            colorScheme="blue"
            onClick={onConfirm}
            marginRight={5}
            isLoading={
              createVariantIsBusy ||
              createSpecializationIsBusy ||
              requirementIsLoading
            }
          >
            {t("save", { ns: "common" })}
          </Button>
          <Button colorScheme="red" onClick={onCancel} marginRight={5}>
            {t("cancel", { ns: "common" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { RequirementUpdateModal };
