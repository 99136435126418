import {
	makeCreateSiteWorker,
	makeDeleteSiteWorker,
	makeGetSiteWorkers,
	makeRestoreSiteWorker,
} from '../../../core/app/factory/siteWorkers/usecases';
import { makeGetSiteSpecializations } from '../../../core/app/factory/specializations/usecases';
import { makeGetSiteVariants } from '../../../core/app/factory/variants/usecases';
import { makeGetAvailableWorkersCount, makeGetWorkers } from '../../../core/app/factory/workers/usecases';
import { DocumentTypeCategory } from '../../../domain/entities/documentTypeCategory.enum';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { SiteWorker, SiteWorkerParams } from '../../../domain/entities/siteWorker';
import { GetSiteWorkersFilters } from '../../../domain/repositories/siteWorkerRepository';
import { GetWorkersFilter } from '../../../domain/repositories/workerRepository';
import { NewResource, SiteResourcesViewModel } from '../../hooks/Site/useSiteResourcesViewModel';

class SiteWorkersViewModel implements SiteResourcesViewModel<SiteWorkerParams> {
	async getSiteResources(
		companyId: string,
		siteId: string,
		page: number,
		archived?: boolean,
		filter?: GetSiteWorkersFilters,
		sort?: SortMeta,
		supplierId?: string,
	): Promise<PaginatedResults<SiteWorker>> {
		return await makeGetSiteWorkers().Call({ companyId, siteId, page, perPage: 25, filter, sort, supplierId, archived });
	}

	async getOwnedResources(
		companyId: string,
		siteId: string,
		filter?: GetWorkersFilter,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<PaginatedResults<SiteWorkerParams>> {
		return (await makeGetWorkers().Call({ companyId, filter: { siteAvailable: siteId, ...filter }, sort, pageParam })) as unknown as Promise<
			PaginatedResults<SiteWorkerParams>
		>;
	}

	async getAvailableResourcesTotalCount(companyId: string, siteId: string, filter?: GetWorkersFilter, sort?: SortMeta): Promise<number> {
		return (await makeGetAvailableWorkersCount().Call({
			companyId,
			filter: { siteAvailable: siteId, ...filter },
			sort,
		})) as unknown as Promise<number>;
	}

	async getVariants(companyId: string, siteId: string): Promise<any[]> {
		return await makeGetSiteVariants().Call({ companyId, siteId, type: DocumentTypeCategory.WORKER });
	}

	async getSpecializations(companyId: string, siteId: string): Promise<any[]> {
		return await makeGetSiteSpecializations().Call({ companyId, siteId, type: DocumentTypeCategory.WORKER });
	}

	async createSiteResource(
		companyId: string,
		siteId: string,
		resources: NewResource[],
		selectAll: boolean,
		selectedResourceNumber: number,
		selectAllVariants: string,
		selectAllSpecializations: string[],
		filterSiteResources?: Record<string, string | string[]>,
	): Promise<boolean> {
		return await makeCreateSiteWorker().Call({
			companyId,
			siteId,
			resources,
			selectAll,
			selectedResourceNumber,
			selectAllVariants,
			selectAllSpecializations,
			filterSiteResources,
		});
	}

	async deleteSiteResource(companyId: string, workerId: string, siteId: string): Promise<void> {
		await makeDeleteSiteWorker().Call({ companyId, workerId, siteId });
	}

	async restoreSiteResource(companyId: string, workerId: string, siteId: string): Promise<void> {
		await makeRestoreSiteWorker().Call({ companyId, workerId, siteId });
	}
}

export default SiteWorkersViewModel;
