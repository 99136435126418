import UseCase from '../../../core/app/usecase';
import SiteRepository, { DocumentTypeAdd } from '../../repositories/siteRepository';
import Requirement from '../../entities/requirement';
import { RequirementSubject } from '../../entities/requirementSubject.enum';

type ARGS = {
	companyId: string;
	siteId: string;
	documents: DocumentTypeAdd[];
	requirementSubject: RequirementSubject;
};

class AddRequirementToSite implements UseCase<ARGS, Requirement> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call(parameters: ARGS): Promise<Requirement> {
		return await this.siteRepository.addRequirementToSite(
			parameters.companyId,
			parameters.siteId,
			parameters.documents,
			parameters.requirementSubject,
		);
	}
}

export default AddRequirementToSite;
