import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteChemicalParams } from "../../../domain/entities/siteChemical";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import useSiteChemicalDetailViewModel from "../../hooks/Site/useSiteChemicalDetailViewModel";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const SiteChemicalDetailView = () => {
  const { siteId, chemicalId } = useParams();
  const resourceColumns = ["name", "companyName"];

  const downloader = useResourceFilesDownloader(
    siteId,
    chemicalId,
    "chemicals"
  );

  return (
    <SiteResourceDetailView<SiteChemicalParams>
      downloader={downloader}
      resourceId={chemicalId}
      type={DocumentTypeCategory.CHEMICAL}
      useViewModel={useSiteChemicalDetailViewModel(
        siteId,
        chemicalId,
        resourceColumns,
        DocumentTypeCategory.CHEMICAL,
        AiTaskSectionCode.SITE_RESOURCES_CHEMICALS
      )}
      resourceColumns={resourceColumns}
      isReadOnly={false}
      permissions={{
        downloadDocuments: Permission.Sites_DownloadChemicalDocuments,
        showGlobalEvaluation: Permission.Sites_Manage,
        setGlobalEvaluation: Permission.Sites_SetGlobalChemicalEval,
        addRequirement: Permission.Sites_NewChemicalRequirement,
        viewRequirements: Permission.Sites_Manage,
        editTags: Permission.Sites_EditChemicalRequirements,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        documents: {
          delete: Permission.Sites_DeleteChemicalRequirement,
          edit: Permission.Sites_EditChemicalRequirements,
          evaluate: Permission.Sites_EditChemicalRequirements,
          viewEvaluations: Permission.Sites_EditChemicalRequirements,
          downloadAll: Permission.Sites_EditChemicalRequirements,
          addFile: Permission.Sites_EditChemicalRequirements,
          chat: Permission.Sites_AccessChemicalChat,
          setExpiration: Permission.Sites_EditChemicalRequirements,
        },
      }}
    />
  );
};

export default SiteChemicalDetailView;
