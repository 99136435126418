import UseCase from '../../../core/app/usecase';
import UsersRepository, { UserEmailsFilters } from "../../repositories/usersRepository";
import { makeUsersRepository } from "../../../core/app/factory/users/repositories";
import { SortMeta } from "../../entities/interfaces/paginatedResults";
import { UserEmail } from "../../entities/userEmail";

interface Args {
  companyId: string;
  userId: string;
  filterEmails?: UserEmailsFilters,
  sort?: SortMeta,
  pageParam?: number
}

class GetUserEmails implements UseCase<Args, UserEmail[]> {
  constructor(private repository: UsersRepository) {}
  async Call({ companyId, userId, filterEmails, sort, pageParam }: Args): Promise<UserEmail[]> {
    return await this.repository.getUserEmails(companyId, userId, filterEmails, sort, pageParam  );
  }
}
export const makeGetUserEmails = (): GetUserEmails => {
  return new GetUserEmails(makeUsersRepository());
};