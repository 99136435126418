import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Skeleton,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { HiPencil } from "react-icons/hi";
import { COLORS } from "../../../assets/theme/colors";
import { FieldAttributes } from "../DetailsHeader";

export const DetailField = ({
  label,
  value,
  edit,
  isLoaded,
  isTag,
}: FieldAttributes) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState<any>(
    edit?.value && edit?.value !== "" ? edit?.value : "-",
  );
  const InputComponent = edit?.type === "multiline" ? Textarea : Input;
  const [error, setError] = useState("");

  return (
    <Box fontSize="12px" height={"100%"}>
      <Box sx={{ opacity: isEditing ? 0 : 1 }}>
        {label}{" "}
        {edit && (
          <IconButton
            aria-label="edit"
            icon={<HiPencil />}
            marginLeft={2}
            marginBottom={1}
            onClick={() => setIsEditing(true)}
            size="5"
            variant="ghost"
            cursor="pointer"
            _hover={{ backgroundColor: undefined }}
          />
        )}
      </Box>
      <Skeleton
        height="auto"
        isLoaded={isLoaded}
        fadeDuration={1}
        width={"90%"}
        startColor="gray.200"
        endColor="gray.300"
        borderRadius={8}
      >
        <Box fontWeight={600} mt={1}>
          {isEditing && edit ? (
            <FormControl
              variant="floating"
              borderColor="gray.400"
              marginTop="-20px"
            >
              <InputGroup>
                {edit.type === "select" && (
                  <Select
                    onChange={(v) => {
                      edit.onChange(v.target.value);
                      setIsEditing(false);
                    }}
                    placeholder={edit.noSelection}
                    value={edit.value}
                  >
                    {edit.options?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                )}

                {edit.type === "number" && (
                  <Flex flexDirection={"column"} w="100%">
                    <Input
                      type={edit.type}
                      value={editedValue}
                      w="100%"
                      onChange={(e) => {
                        if (!edit.validate) {
                          setEditedValue(e.target.value);
                          setError("");
                          return;
                        }

                        const regex = new RegExp(
                          `^\\d{${edit.numericSizeConstraint}}$`,
                        );
                        const isValid = regex.test(e.target.value);
                        if (isValid) {
                          setEditedValue(e.target.value);
                          setError("");
                        } else {
                          setEditedValue(e.target.value);
                          setError(t("needToInsertDigits", { ns: "common" }));
                        }
                      }}
                      paddingRight={14}
                      marginBottom={2}
                    />
                    <Text color={"red"} noOfLines={1} fontSize={12}>
                      {error}
                    </Text>
                  </Flex>
                )}

                {(edit.type === "text" || edit.type === "multiline") && (
                  <Flex flexDirection={"column"} w="100%">
                    <InputComponent
                      value={editedValue}
                      type={edit.type}
                      w="100%"
                      rows={1}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setEditedValue(newValue);

                        if (!edit.validationRegex) {
                          return;
                        }

                        const isValid = edit.validationRegex.test(newValue);
                        setError(
                          isValid ? "" : (edit.validationErrorMessage ?? ""),
                        );
                      }}
                      paddingRight={14}
                      marginBottom={2}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          edit.onChange(editedValue);
                          setIsEditing(false);
                        }
                      }}
                    />
                    <Text color={"red"} noOfLines={1} fontSize={12}>
                      {error ? error : edit.validationErrorMessage}
                    </Text>
                  </Flex>
                )}

                {edit.type === "date" && (
                  <Flex flexDirection={"column"} w="100%">
                    <InputComponent
                      value={editedValue}
                      type={edit.type}
                      max="9999-12-31"
                      w="100%"
                      rows={1}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setEditedValue(newValue);

                        if (!edit.validationRegex) {
                          return;
                        }

                        const isValid = edit.validationRegex.test(newValue);
                        setError(
                          isValid ? "" : (edit.validationErrorMessage ?? ""),
                        );
                      }}
                      paddingRight={14}
                      marginBottom={2}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          edit.onChange(editedValue);
                          setIsEditing(false);
                        }
                      }}
                    />
                    <Text color={"red"} noOfLines={1} fontSize={12}>
                      {error ? error : edit.validationErrorMessage}
                    </Text>
                  </Flex>
                )}

                {edit.type !== "select" && (
                  <InputRightElement marginRight={2}>
                    <IconButton
                      aria-label="confirm"
                      onClick={() => {
                        if (!error) {
                          edit.onChange(editedValue);
                          setIsEditing(false);
                        }
                      }}
                      as={AiFillCheckCircle}
                      cursor="pointer"
                      _hover={{ backgroundColor: undefined }}
                      size="xs"
                      variant="ghost"
                      color={error ? COLORS.lightBlue : COLORS.sikuroBlue}
                    />
                    <IconButton
                      aria-label="cancel"
                      onClick={() => setIsEditing(false)}
                      as={AiFillCloseCircle}
                      cursor="pointer"
                      _hover={{ backgroundColor: undefined }}
                      size="xs"
                      variant="ghost"
                      color="red"
                    />
                  </InputRightElement>
                )}

                {edit.type !== "customTimezoneSelect" && (
                  <InputRightElement marginRight={2}>
                    <IconButton
                      aria-label="confirm"
                      onClick={() => {
                        if (!error) {
                          edit.onChange(editedValue);
                          setIsEditing(false);
                        }
                      }}
                      as={AiFillCheckCircle}
                      cursor="pointer"
                      _hover={{ backgroundColor: undefined }}
                      size="xs"
                      variant="ghost"
                      color={error ? COLORS.lightBlue : COLORS.sikuroBlue}
                    />
                    <IconButton
                      aria-label="cancel"
                      onClick={() => setIsEditing(false)}
                      as={AiFillCloseCircle}
                      cursor="pointer"
                      _hover={{ backgroundColor: undefined }}
                      size="xs"
                      variant="ghost"
                      color="red"
                    />
                  </InputRightElement>
                )}
              </InputGroup>
              <FormLabel
                backgroundColor="white"
                borderRadius={4}
                color="gray.500"
              >
                {label}
              </FormLabel>
            </FormControl>
          ) : (
            (value ?? "-")
          )}
        </Box>
      </Skeleton>
    </Box>
  );
};

export default DetailField;
