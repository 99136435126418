import Company from '../../../domain/entities/company';
import { makeGetCompanySuppliers } from '../../../core/app/factory/suppliers/useCases';
import { GetGlobalSupplierFilters } from '../../../domain/repositories/supplierRepository';
import { SortMeta } from '../../../domain/entities/interfaces/paginatedResults';

class SupplierListViewModel {
	async getSuppliers(companyId: string, filter?: GetGlobalSupplierFilters, sort?: SortMeta, pageParam?: number): Promise<Company[]> {
		return await makeGetCompanySuppliers().Call({ companyId, filter, sort, pageParam });
	}
}

export default SupplierListViewModel;
