import Vehicle from "../../../domain/entities/vehicle";
import {
  makeGetVehicle,
  makeMigrateToMachine,
  makeUpdateVehicle,
} from "../../../core/app/factory/vehicles/usecases";
import {
  makeGetResourceLinkableSites,
  makeGetVehicleSites,
} from "../../../core/app/factory/sites/usecases";
import {
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import {
  makeGetAvailableBadgesUseCase,
  makeLinkBadgesResource,
} from "../../../core/app/factory/workers/usecases";
import {
  makeUnlinkBadgeResource,
} from "../../../core/app/factory/badges/usecases";
import {
  GetAvailableBadgesFilters,
} from "../../../domain/repositories/badgeRepository";
import Badge from "../../../domain/entities/badge";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import Site, { ResourceSites } from "../../../domain/entities/site";
import { makeAddResourceToSites } from "../../../core/app/factory/workers/usecases";
import {
  makeGetMachineVehicleDocumentTypeMapRecords,
  makeUpsertMachineVehicleDocumentTypeMapRecords,
} from "../../../core/app/factory/documentTypes/usecases";
import { GetMachineVehicleDocumentTypeMapRecordsResult } from "../../../domain/interactors/documentTypes/getMachineVehicleDocumentTypeMapRecords";
import { makeGetTypologies } from "../../../core/app/factory/typologies/usecases";
import Typology from "../../../domain/entities/typology";
import Document from "../../../domain/entities/document";
import { makeGetVehicleDocuments } from "../../../core/app/factory/vehicles/usecases";
import DocumentType from "../../../domain/entities/documentType";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { makeGetDocumentTypes } from "../../../core/app/factory/requirements/useCases";
import {makeGetCustomFields} from "../../../core/app/factory/companies/usecases";
import {CustomField} from "../../../domain/entities/customFields";
import {RequirementSubject} from "../../../domain/entities/requirementSubject.enum";

class VehicleViewModel {
  private getVehicleUseCase = makeGetVehicle();
  private updateVehicleUseCase = makeUpdateVehicle();
  private getVehicleSitesUseCase = makeGetVehicleSites();
  private getResourceLinkableSitesUseCase = makeGetResourceLinkableSites();
  private linkResourceToSitesUseCase = makeAddResourceToSites();
  private getAvailableBadgesUseCase = makeGetAvailableBadgesUseCase();
  private linkBadgesToResourceUseCase = makeLinkBadgesResource();
  private unlinkBadgeResourceUseCase = makeUnlinkBadgeResource();

  private migrateToMachineUseCase = makeMigrateToMachine();
  private getMachineVehicleDocumentTypeMapRecordsUseCase =
    makeGetMachineVehicleDocumentTypeMapRecords();
  private upsertMachineVehicleDocumentTypeMapRecordsUseCase =
    makeUpsertMachineVehicleDocumentTypeMapRecords();
  private getTypologiesUseCase = makeGetTypologies();
  private getVehicleDocumentsUseCase = makeGetVehicleDocuments();
  private getDocumentTypesUseCase = makeGetDocumentTypes();
  private getCustomFieldsUseCase = makeGetCustomFields();

  async get(companyId: string, vehicleId: string): Promise<Vehicle> {
    return await this.getVehicleUseCase.Call({ companyId, vehicleId });
  }
  async update(
    companyId: string,
    vehicle: Vehicle,
    imageFile?: File,
  ): Promise<Vehicle> {
    return await this.updateVehicleUseCase.Call({
      companyId,
      vehicle,
      imageFile,
    });
  }

  async getVehicleSites(
    companyId: string,
    vehicleId: string,
    sort?: SortMeta,
    filter?: GetSitesFilter,
    pageParam?: number,
    includeWorkingSites?: boolean,
    ignoreUserSiteRoleRestrictions?: boolean,
    documentTypeId?: string,
  ): Promise<ResourceSites> {
    return await this.getVehicleSitesUseCase.Call({
      companyId,
      vehicleId,
      sort,
      filter,
      pageParam,
      includeWorkingSites,
      ignoreUserSiteRoleRestrictions,
      documentTypeId
    });
  }

  async getAvailableBadges(
    companyId: string,
    resource: string,
    resourceId: string,
    sort: SortMeta,
    filter: GetAvailableBadgesFilters,
  ): Promise<Badge[]> {
    return await this.getAvailableBadgesUseCase.Call({
      companyId,
      resource,
      resourceId,
      sort,
      filter,
    });
  }

  async linkBadgesToResource(
    companyId: string,
    badgeIds: string[],
    resourceType: BadgeResourceType,
    resourceId: string,
  ): Promise<void> {
    return await this.linkBadgesToResourceUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      badgeIds,
    });
  }

  async unlinkBadgeResource(companyId: string, badgeId: string): Promise<void> {
    return await this.unlinkBadgeResourceUseCase.Call({ companyId, badgeId });
  }

  async getResourceLinkableSites(
    companyId: string,
    resourceType: string,
    resourceId: string,
    sort: SortMeta,
    filters: GetSitesFilter,
    pageParam: number,
  ): Promise<{results: Site[] , count: number }> {
    return await this.getResourceLinkableSitesUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      sort,
      filters,
      pageParam,
    });
  }
  async linkResourceToSites(
    companyId: string,
    vehicleId: string,
    siteIds: string[],
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ): Promise<void> {
    return await this.linkResourceToSitesUseCase.Call({
      companyId,
      resourceType: "vehicle",
      resourceId: vehicleId,
      siteIds,
      selectAll,
      filters
    });
  }
  async migrateToMachine(
    companyId: string,
    vehicleId: string,
    typologyId: string,
  ): Promise<string> {
    return await this.migrateToMachineUseCase.Call({
      companyId,
      vehicleId,
      typologyId,
    });
  }
  async getMachineVehicleDocumentTypeMapRecords(
    companyId: string,
  ): Promise<GetMachineVehicleDocumentTypeMapRecordsResult> {
    return await this.getMachineVehicleDocumentTypeMapRecordsUseCase.Call({
      companyId,
    });
  }
  async upsertMachineVehicleDocumentTypeMapRecords(
    companyId: string,
    records: { machineDocumentTypeId: string; vehicleDocumentTypeId: string }[],
  ): Promise<void> {
    return await this.upsertMachineVehicleDocumentTypeMapRecordsUseCase.Call({
      companyId,
      records,
    });
  }
  async getMachineTypologies(companyId: string): Promise<Typology[]> {
    return await this.getTypologiesUseCase.Call({
      companyId,
      type: DocumentTypeCategory.MACHINE,
    });
  }
  async getDocuments(
    companyId: string,
    vehicleId: string,
  ): Promise<Document[]> {
    return await this.getVehicleDocumentsUseCase.Call({
      companyId,
      resourceId: vehicleId,
    });
  }
  async getMachineDocumentTypes(companyId: string): Promise<DocumentType[]> {
    return await this.getDocumentTypesUseCase.Call({
      companyId,
      filter: DocumentTypeCategory.MACHINE,
      includePublic: true,
    });
  }
  async getCustomFields(companyId: string): Promise<CustomField[]> {
    return await this.getCustomFieldsUseCase.Call({ companyId, subject: RequirementSubject.VEHICLE });
  }
}

export { VehicleViewModel };
