import UseCase from '../../../core/app/usecase';
import DocumentsPresetsRepository from '../../repositories/documentsPresetsRepository';

class DeleteDocumentPreset implements UseCase<{ companyId: string; presetId: string }, void> {
	constructor(private repository: DocumentsPresetsRepository) {}

	async Call(arg: { companyId: string; presetId: string }): Promise<void> {
		return await this.repository.deleteDocumentPreset(arg.companyId, arg.presetId);
	}
}

export default DeleteDocumentPreset;
