import { ToolDocumentsViewModel } from "../../../viewmodels/tools/ToolDocumentsViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { useResourceDocumentsViewModel } from "../useResourceDocumentsViewModel";

const useToolDocumentsViewModel = (resourceId: string) =>
  useResourceDocumentsViewModel(
    new ToolDocumentsViewModel(resourceId),
    DocumentTypeCategory.TOOL,
    resourceId,
  );

export default useToolDocumentsViewModel;
