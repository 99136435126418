import Worker from '../../../entities/worker';
import UseCase from '../../../../core/app/usecase';
import WorkerRepository from '../../../repositories/workerRepository';
import GetWorkerParameters from './getWorkerParameter';
import Badge from '../../../entities/badge';
import { PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';
import { GetBadgesFilters } from '../../../repositories/badgeRepository';
import MachineRepository from '../../../repositories/machineRepository';
import VehicleRepository from '../../../repositories/vehicleRepository';

type ARGS = {
	companyId: string;
	resource: string;
	resourceId: string;
	sort?: SortMeta;
	filter?: GetBadgesFilters;
	pageParam?: number;
	supplierId?: string;
};

class GetResourceBadge implements UseCase<ARGS, PaginatedResults<Badge>> {
	constructor(private repository: WorkerRepository | MachineRepository | VehicleRepository) {}

	async Call({ companyId, resource, resourceId, sort, filter, pageParam, supplierId }: ARGS): Promise<PaginatedResults<Badge>> {
		return this.repository.getResourceBadge(companyId, resource, resourceId, sort, filter, pageParam, supplierId);
	}
}

export default GetResourceBadge;
