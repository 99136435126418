import UseCase from '../../../core/app/usecase';
import Requirement from '../../entities/requirement';
import RequirementRepository from '../../repositories/requirementRepository';

type CreateSiteRequirementParameter = {
	companyId: string;
	requirement: Requirement;
	siteId?: string;
};
class UpdateSiteRequirement implements UseCase<CreateSiteRequirementParameter, void> {
	private requirementRepository: RequirementRepository;

	constructor(requirementRepository: RequirementRepository) {
		this.requirementRepository = requirementRepository;
	}

	async Call({ companyId, requirement, siteId }: CreateSiteRequirementParameter): Promise<void> {
		return await this.requirementRepository.updateSiteRequirement(companyId, requirement, siteId);
	}
}

export default UpdateSiteRequirement;
