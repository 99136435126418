import { Text, View } from "@react-pdf/renderer";
import Badge, {
  WorkerMetas,
} from "../../../../domain/entities/badge";
import { formatStringToDate } from "../../../../utils";
import { styles } from "./badgeStyles";
import FrontBadgeContainer from "./FrontBadgeContainer";
import workerPlaceholder from "../../../../assets/images/worker.png";

type FrontBadgeWorkerProps = {
  badge: Badge;
};

const FrontBadgeWorker = ({ badge }: FrontBadgeWorkerProps) => {
  const {
    firstName,
    lastName,
    fiscalCode,
    jobTitle,
    dateOfBirth,
    dateOfEmployment,
    photo
  } = badge.resource.meta as WorkerMetas;

  return (
    <FrontBadgeContainer badge={badge} photo={photo || workerPlaceholder}>
      <View style={styles.badgeDetailsRow}>
        <Text style={{ fontWeight: 700 }}>Nome: </Text>
        <Text style={styles.text}>{firstName}</Text>
      </View>
      <View style={styles.badgeDetailsRow}>
        <Text style={{ fontWeight: 700 }}>Cognome: </Text>
        <Text style={styles.text}>{lastName}</Text>
      </View>
      {dateOfBirth && (
        <View style={styles.badgeDetailsRow}>
          <Text style={{ fontWeight: 700 }}>Nato il: </Text>
          <Text>{formatStringToDate(dateOfBirth)}</Text>
        </View>
      )}
      <View style={styles.badgeDetailsRow}>
        <Text style={{ fontWeight: 700 }}>C.F.: </Text>
        <Text>{fiscalCode}</Text>
      </View>
      {jobTitle && (
        <View style={styles.badgeDetailsRow}>
          <Text style={{ fontWeight: 700 }}>Ruolo: </Text>
          <Text style={styles.text}>{jobTitle}</Text>
        </View>
      )}
      {dateOfEmployment && (
        <View style={styles.badgeDetailsRow}>
          <Text style={{ fontWeight: 700 }}>Assunto il: </Text>
          <Text>{formatStringToDate(dateOfEmployment)}</Text>
        </View>
      )}
    </FrontBadgeContainer>
  );
};
export default FrontBadgeWorker;
