import UseCase from '../../../core/app/usecase';
import BadgeRepository from '../../repositories/badgeRepository';

type ARGS = {
	companyId: string;
	badgeId: string;
};

class UnlinkBadgeResource implements UseCase<ARGS, void> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, badgeId }: ARGS): Promise<void> {
		return await this.badgeRepository.unlinkBadgeResource(companyId, badgeId);
	}
}

export default UnlinkBadgeResource;
