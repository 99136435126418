import InputAnimatedDatePicker from "../InputAnimatedDatePicker";
import InputAnimatedLabel from "../InputAnimatedLabel";

export type InputFieldProps = {
  field: string;
  type: "text" | "date";
  required?: boolean;
  value: string;
  label: string;
  onChange: (fieldName: string, value: string) => void;
};
const InputField = ({
  field,
  required,
  value,
  type,
  label,
  onChange,
}: InputFieldProps) =>
  type === "text" ? (
    <InputAnimatedLabel
      isRequired={required}
      id={field}
      label={label}
      handleOnChange={onChange}
      showButton={false}
      defaultValue={value}
    />
  ) : (
    <InputAnimatedDatePicker
      onChange={onChange}
      id={field}
      label={label}
      defaultValue={value}
    />
  );

export default InputField;
