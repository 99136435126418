import UseCase from '../../../core/app/usecase';
import DocumentsPresetsRepository from '../../repositories/documentsPresetsRepository';

interface Args {
	companyId: string;
	presetId: string;
	typologyId: string;
}

class RemoveTypologyFromDocumentPreset implements UseCase<Args, void> {
	constructor(private repository: DocumentsPresetsRepository) {}

	async Call(args: Args): Promise<void> {
		return await this.repository.removeTypologyFromDocumentPreset(args.companyId, args.presetId, args.typologyId);
	}
}

export default RemoveTypologyFromDocumentPreset;
