import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';
import { RequirementSubject } from '../../entities/requirementSubject.enum';

type ARGS = {
	companyId: string;
	siteId: string;
	requirementId: string;
	subject: RequirementSubject;
};

class RemoveRequirementFromSite implements UseCase<ARGS, void> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call(parameters: ARGS) {
		return await this.siteRepository.removeRequirementFromSite(
			parameters.companyId,
			parameters.siteId,
			parameters.requirementId,
			parameters.subject,
		);
	}
}

export default RemoveRequirementFromSite;
