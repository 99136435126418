import UseCase from '../../../core/app/usecase';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import AuditRepository from '../../repositories/auditRepository';

interface Args {
	companyId: string;
	siteId?: string;
	requirementId: string;
	note: string;
	requirementSubject: DocumentTypeCategory;
	resourceId: string;
}

class CreateAuditNote implements UseCase<Args, void> {
	constructor(private repository: AuditRepository) {}

	async Call({ companyId, siteId, requirementId, note, requirementSubject, resourceId }: Args): Promise<void> {
		return await this.repository.createAuditNote(companyId, siteId, requirementId, note, requirementSubject, resourceId);
	}
}

export default CreateAuditNote;
