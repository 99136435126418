import UseCase from '../../../core/app/usecase';
import { DocumentActionLogEntry } from '../../entities/documentLogEntry';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import DocumentRepository, { GetDocumentLogFilter } from '../../repositories/documentRepository';

interface Args {
	companyId: string;
	documentId: string;
	filter?: GetDocumentLogFilter;
	sort?: SortMeta;
	pageParam?: number;
}

class GetDocumentFilesLog implements UseCase<Args, DocumentActionLogEntry[]> {
	constructor(private repository: DocumentRepository) {}
	async Call({ companyId, documentId, filter, sort, pageParam }: Args): Promise<DocumentActionLogEntry[]> {
		return this.repository.getDocumentActionsLog(companyId, documentId, filter, sort, pageParam);
	}
}

export default GetDocumentFilesLog;
