import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDateBasedOnLanguage, formatDateTime } from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import { CalendarIcon, CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import { Notification } from "../../../../domain/entities/notification";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  IconButton,
  Spacer,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import DOMPurify from "dompurify";
import CommentMessage from "../../Common/CommentMessage";
interface Props {
  notification: Notification;
  onRead?: (data: { id: string; status: string }) => void;
  onUnread?: (data: { id: string; status: string }) => void;
  onDelete?: (notification: Notification) => void;
}

const NotificationRow: FC<Props> = ({
  notification,
  onRead,
  onUnread,
  onDelete,
}) => {
  const { t } = useTranslation("notifications");
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const sanitizedHtmlTitle = DOMPurify.sanitize(t(notification.title));
  const sanitizedHtmlContent = DOMPurify.sanitize(t(notification.content));

  return (
    <Card mb={2} variant="outline">
      <CardHeader>
        <Flex>
          <Flex gap={4} alignItems={"center"}>
            <Tag width="fit-content" p={3}>
              <TagLeftIcon as={CalendarIcon} />
              <TagLabel>
                {formatDateBasedOnLanguage(notification.createdAt, true)}
              </TagLabel>
            </Tag>
            <Text
              fontWeight="semibold"
              overflowWrap="anywhere"
              mx={2}
              dangerouslySetInnerHTML={{ __html: sanitizedHtmlTitle }}
            />
          </Flex>
          <Spacer />
          <Flex direction="row-reverse" p={2}>
            <Button
              mt={2}
              variant="link"
              color={COLORS.sikuroBlue}
              fontSize="sm"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {t(!isExpanded ? "showMore" : "showLess", { ns: "common" })}
            </Button>
          </Flex>
          {onRead && (
            <Tooltip label={t("markAsRead")}>
              <IconButton
                aria-label="read"
                onClick={() =>
                  onRead({ id: notification.id, status: "archived" })
                }
                isRound
                ml={2}
                variant="ghost"
                bgColor={COLORS.sikuroBlue}
                color="white"
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          )}
          {onUnread && (
            <Tooltip label={t("markAsUnread")}>
              <IconButton
                aria-label="read"
                onClick={() =>
                  onUnread({ id: notification.id, status: "unread" })
                }
                isRound
                ml={2}
                variant="ghost"
                bgColor={COLORS.sikuroBlue}
                color="white"
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          )}

          {onDelete && (
            <Tooltip label={t("delete")}>
              <IconButton
                aria-label="delete"
                onClick={() => onDelete(notification)}
                isRound
                ml={2}
                variant="ghost"
                bgColor="red.500"
                color="white"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Flex>
      </CardHeader>

      {isExpanded && (
        <CardBody
          borderTopColor="#e5e5e5"
          borderTopStyle="solid"
          borderTopWidth={1}
        >
          <CommentMessage fontSize={16} message={sanitizedHtmlContent} />
        </CardBody>
      )}
    </Card>
  );
};

export default NotificationRow;
