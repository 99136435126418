import { Grid, GridItem } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import LoadingView from "../screens/Common/LoadingView";
import React from "react";

const MainLayout = ({ header, nav, children, isLoading }: MainLayoutProps) => {
  const templateAreas = `
    "nav header"
    "nav main"
  `;

  const columnsWidth = "64px 1fr";
  const rowsHeight = "56px 1fr";

  return (
    <Grid
      templateAreas={templateAreas}
      gridTemplateRows={rowsHeight}
      gridTemplateColumns={columnsWidth}
      height="100vh"
    >
      <GridItem bg="#32A2DB" area={"header"} overflowX={"auto"}>
        {header}
      </GridItem>
      <GridItem bg="#eece23" area={"nav"}>
        {nav}
      </GridItem>
      <GridItem bg="white" area={"main"} h="calc(100vh - 56px)">
        <div id="mainScrollArea" style={{overflowY: 'auto', height: '100%'}}>
          {isLoading ? <LoadingView /> : (children ?? <Outlet />)}
        </div>
      </GridItem>
    </Grid>
  );
};

interface MainLayoutProps {
  header: React.ReactNode;
  nav: React.ReactNode;
  children?: React.ReactNode;
  isLoading?: boolean;
}

export default MainLayout;
