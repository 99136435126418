import CreateVariant from '../../../../domain/interactors/variant/createVariant';
import GetVariants from '../../../../domain/interactors/variant/getVariants';
import { makeVariantRepository } from './repositories';
import GetSiteVariants from '../../../../domain/interactors/variant/getSiteVariants';
import GetRequirementVariants from '../../../../domain/interactors/variant/getRequirementVariant';

export const makeGetVariants = () => {
	return new GetVariants(makeVariantRepository());
};

export const makeGetRequirementVariants = () => {
	return new GetRequirementVariants(makeVariantRepository());
};

export const makeCreateVariant = () => {
	return new CreateVariant(makeVariantRepository());
};

export const makeGetSiteVariants = () => {
	return new GetSiteVariants(makeVariantRepository());
};
