import UseCase from '../../../../core/app/usecase';
import { ResourceDocumentEvaluationState } from '../../../entities/resourceDocumentEvaluationState.enum';
import MachineRepository from '../../../repositories/machineRepository';
import { GetSitesFilter } from "../../../repositories/siteRepository";

type RepropagateEvaluationMachineDocumentParameters = {
	companyId: string;
	resourceId: string;
	documentId: string;
	siteIds: string[];
	selectAll?: boolean;
};

/**
 * Returns the list of all the existing Document.
 */
class RepropagateEvaluationMachineDocument implements UseCase<RepropagateEvaluationMachineDocumentParameters, void> {
	constructor(private repository: MachineRepository) {}

	async Call({
		companyId,
		resourceId,
		documentId,
		siteIds,
		selectAll,
	}: RepropagateEvaluationMachineDocumentParameters): Promise<void> {
		return await this.repository.repropagateEvaluationMachineDocument(
			companyId,
			resourceId,
			documentId,
			siteIds,
			selectAll,
		);
	}
}

export default RepropagateEvaluationMachineDocument;
