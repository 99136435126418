import UseCase from '../../../core/app/usecase';
import CompanyRepository from '../../repositories/companyRepository';
import {CustomField} from "../../entities/customFields";
import { RequirementSubject } from "../../entities/requirementSubject.enum";

type GetCustomFieldsParams = {
  companyId: string;
  subject?: RequirementSubject;
};

class GetCustomFields implements UseCase<null, CustomField[]> {
	private companyRepository: CompanyRepository;

	constructor(companyRepository: CompanyRepository) {
		this.companyRepository = companyRepository;
	}

	async Call({ companyId, subject = null }: GetCustomFieldsParams): Promise<CustomField[]> {
		return await this.companyRepository.getCustomFields(companyId, subject);
	}
}

export default GetCustomFields;
