import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';
import { ScheduleEmail } from '../../entities/siteOptions';

interface Args {
	companyId: string;
	siteId: string;
}

class GetScheduleEmail implements UseCase<Args, ScheduleEmail[]> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, siteId }: Args): Promise<ScheduleEmail[]> {
		return await this.siteRepository.getScheduleEmail(companyId, siteId);
	}
}

export default GetScheduleEmail;
