import Chemical from '../../../entities/chemical';
import UseCase from '../../../../core/app/usecase';
import ChemicalRepository from '../../../repositories/chemicalRepository';
import DeleteChemicalParameters from './deleteChemicalParameter';

class DeleteChemical implements UseCase<DeleteChemicalParameters, void> {
	constructor(private repository: ChemicalRepository) {}

	async Call({ companyId, chemicalId }: DeleteChemicalParameters): Promise<void> {
		return await this.repository.deleteChemical(companyId, chemicalId);
	}
}

export default DeleteChemical;
