import { Flex, Skeleton } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TbCrane, TbJacket } from "react-icons/tb";
import { DashboardCount } from "../../../domain/entities/dashboardCount";
import UserIcon from "../../components/Common/icons/UserIcon";
import DashboardCountView from "./DashboardCountView";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { useNavigate } from "react-router-dom";

type DashboardCountersViewProps = {
  ownedSitesCount: DashboardCount;
  workingSitesCount: DashboardCount;
  suppliersCount: DashboardCount;
  ownedSitesCountFetching: boolean;
  workingSitesCountFetching: boolean;
  suppliersCountFetching: boolean;
  ownedSitesPermissions: string[];
  workingSitesPermissions: string[];
  suppliersPermissions: string[];
};

const DashboardCountersView = ({
  ownedSitesCount,
  workingSitesCount,
  suppliersCount,
  ownedSitesCountFetching,
  workingSitesCountFetching,
  suppliersCountFetching,
  ownedSitesPermissions,
  workingSitesPermissions,
  suppliersPermissions,
}: DashboardCountersViewProps) => {
  const { t } = useTranslation("dashboard");
  const { hasPermissions } = useAuth();
  const navigate = useNavigate();

  return (
    <Flex gap={3} flexDirection={"row"}>
      {hasPermissions(ownedSitesPermissions) && (
        <Flex grow={1} cursor="pointer" onClick={() => navigate("/sites")}>
          <Skeleton
            isLoaded={!ownedSitesCountFetching}
            borderRadius={"md"}
            width={"100%"}
          >
            <DashboardCountView
              count={ownedSitesCount}
              icon={TbCrane}
              title={t("ownedSitesCount")}
            />
          </Skeleton>
        </Flex>
      )}
      {hasPermissions(workingSitesPermissions) && (
        <Flex
          grow={1}
          cursor="pointer"
          onClick={() => navigate("/workingsites")}
        >
          <Skeleton
            isLoaded={!workingSitesCountFetching}
            borderRadius={"md"}
            width={"100%"}
          >
            <DashboardCountView
              count={workingSitesCount}
              icon={TbJacket}
              title={t("workingSitesCount")}
            />
          </Skeleton>
        </Flex>
      )}
      {hasPermissions(suppliersPermissions) && (
        <Flex grow={1} cursor="pointer" onClick={() => navigate("/suppliers")}>
          <Skeleton
            isLoaded={!suppliersCountFetching}
            borderRadius={"md"}
            width={"100%"}
          >
            <DashboardCountView
              count={suppliersCount}
              icon={UserIcon}
              title={t("suppliersCount")}
            />
          </Skeleton>
        </Flex>
      )}
    </Flex>
  );
};
export default DashboardCountersView;
