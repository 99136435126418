import { makeExport } from "../../../core/app/factory/export/useCases";
import { makeGetSiteResourcesDocuments } from "../../../core/app/factory/sites/usecases";
import DocumentType from "../../../domain/entities/documentType";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { PaginatedResults } from "../../../domain/entities/interfaces/paginatedResults";

class ExportViewModel {

  async getSiteResourceDocuments(
    companyId: string,
    siteId: string,
    resourceType: DocumentTypeCategory,
    supplierId: string,
    pageParam?: number,
  ): Promise<PaginatedResults<DocumentType>> {
    return await makeGetSiteResourcesDocuments().Call({
      companyId,
      siteId,
      resourceType,
      supplierId,
      pageParam,
    });
  }

  async export(
    companyId: string,
    topic: string,
    siteId?: string,
    siteIds?: string[],
    startDate?: Date,
    endDate?: Date,
    permissionSites?: { [key: string]: string },
    resourcesIds?: string[],
    resourceDocumentsToExclude?: string[]
  ): Promise<boolean> {
    return await makeExport().Call({
      companyId,
      topic,
      siteId,
      siteIds,
      startDate,
      endDate,
      permissionSites,
      resourcesIds,
      resourceDocumentsToExclude
    });
  }
}

export default ExportViewModel;
