import UseCase from '../../../core/app/usecase';
import { GetSiteResourcesAuditFilters } from '../../../presentation/hooks/Site/useSiteAuditsViewModel';
import { PaginatedResults, SortMeta } from '../../entities/interfaces/paginatedResults';
import { SiteTool } from '../../entities/siteTool';
import SiteRepository from '../../repositories/siteRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	pageParam: number;
	filterSiteResources: GetSiteResourcesAuditFilters;
	siteResourcesSort: SortMeta;
	supplierId: string;
};

class GetSiteSupplierTools implements UseCase<Parameters, PaginatedResults<SiteTool>> {
	constructor(private repository: SiteRepository) {}

	async Call({ companyId, siteId, pageParam, filterSiteResources, siteResourcesSort, supplierId }: Parameters): Promise<PaginatedResults<SiteTool>> {
		return this.repository.getSiteSupplierTools(companyId, siteId, pageParam, filterSiteResources, siteResourcesSort, supplierId);
	}
}

export default GetSiteSupplierTools;
