import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Specialization from "../../../../domain/entities/specialization";
import Variant from "../../../../domain/entities/variant";
import TextInput from "../../Common/TextInput";
import Steps from "../../Common/stepper/Steps";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useMediaQuery,
  useSteps,
} from "@chakra-ui/react";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import Tag from "../../../../domain/entities/tag";
import { NewResource } from "../../../hooks/Site/useSiteResourcesViewModel";
import CellTagList from "../../Common/table/CellTagList";
import InfiniteTable from "../../Common/table/InfiniteTable";
import MultiTagSelect from "../../Common/TagSelect/MultiTagSelect";
import SingleTagSelect from "../../Common/TagSelect/SingleTagSelect";
import SimpleTable from "../../Common/table/SimpleTable";
import { COLORS } from "../../../assets/theme/colors";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import { BadgeType } from "../../../../domain/entities/badgeType.enum";
import { BlobProvider } from "@react-pdf/renderer";
import { useAuth } from "../../../providers/Auth0JWTProvider";
import useSiteRequirementsViewModel from "../../../hooks/Site/useSiteRequirementsViewModel";
import Requirement from "../../../../domain/entities/requirement";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { ImSpinner6 } from "react-icons/im";
import RequirementsPdf from "../../Common/RequirementsPdf";
import { RequirementSubject } from "../../../../domain/entities/requirementSubject.enum";

export type VariantRequirements = Record<
  string,
  { requirements: Requirement[] }
>;

type CreateSiteResourceModalProps<Resource> = {
  resources: Resource[];
  ownedResourcesFetching: boolean;
  columns: string[];
  variants: Variant[];
  specializationsFromList: Specialization[];
  type: "worker" | "machine" | "vehicle" | "tool" | "chemical";
  search: string;
  setSearch: (search: string) => void;
  sort?: SortMeta;
  setSort: (sort: SortMeta) => void;
  onConfirm: (
    resources: NewResource[],
    selectAll: boolean,
    selectedAvailableResourceCount: number,
    selectAllVariants: string,
    selectAllSpecializations: string[],
    filterSiteResources: Record<string, string | string[]>
  ) => void;
  onClose: () => void;
  resourcesHasNextPage;
  resourcesFetchNextPage;
  selectAllAvailable?: boolean;
  availableResourcesTotalCount?: number;
  filterSiteResources?: Record<string, string | string[]>;
  badgesAvailable: number;
  badgesAvailableType: BadgeType;
};

const CreateSiteResourceModal = <Resource extends { [key: string]: any }>({
  resources,
  ownedResourcesFetching,
  resourcesHasNextPage,
  resourcesFetchNextPage,
  variants,
  specializationsFromList,
  type,
  search,
  setSearch,
  sort,
  setSort,
  onClose,
  columns,
  onConfirm,
  selectAllAvailable,
  availableResourcesTotalCount,
  filterSiteResources,
  badgesAvailable,
  badgesAvailableType,
}: CreateSiteResourceModalProps<Resource>) => {
  const portalRef = useRef();
  const { siteId } = useAuth();
  const reqs = useSiteRequirementsViewModel(type as RequirementSubject);
  const { requirements, requirementsLoading, siteVariants } = reqs(
    siteId,
    type as RequirementSubject
  );

  const [ids, setIds] = useState<string[]>([]);
  const [finalSelections, setFinalSelections] = useState<Resource[]>([]);
  const summaryColumns = ["name", "variants", "specializations"];
  const { t } = useTranslation("siteResources");

  const [selectedResources, setSelectedResources] = useState<Resource[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<
    { [resourceId: string]: Tag }[]
  >([]);
  const [selectedSpecialization, setSelectedSpecialization] = useState<
    { [resourceId: string]: Tag[] }[]
  >([]);

  const [selectAll, setSelectAll] = useState(false);
  const [selectAllMatching, setSelectAllMatching] = useState(false);
  const [insertedResourcesNumber, setInsertedResourcesNumber] = useState(0);
  const [selectedResourcesNumber, setSelectedResourcesNumber] = useState(0);
  const [manuallySelectedNumber, setManuallySelectedNumber] = useState(false);

  const [selectedAllVariants, setSelectedAllVariants] =
    useState<Tag>(undefined);
  const [selectedAllSpecializations, setSelectedAllSpecializations] = useState<
    Tag[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState(false);

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: 2,
  });

  const handleConfirm = async () => {
    setIsLoading(true);
    const resources = selectedResources.map((resource, index) => {
      const variantId = selectedVariant?.find((x) => x[resource.id])[
        resource.id
      ].id;
      const specializationsIds = selectedSpecialization[index][
        resource.id
      ]?.map((x) => x.id);
      return { resourceId: resource.id, variantId, specializationsIds };
    });
    await onConfirm(
      resources,
      selectAllMatching,
      selectedResourcesNumber,
      selectedAllVariants?.id,
      selectedAllSpecializations?.map((x) => x.id),
      filterSiteResources
    );
    setIsLoading(false);
    onClose();
  };

  const handleCreate = async () => {
    if (ids.length > badgesAvailable && badgesAvailableType === BadgeType.NFC) {
      setShowMessage(true);
    } else {
      handleConfirm();
    }
  };

  const onSelectStep = (index: number) => {
    if (activeStep === 0 && ids.length === 0) {
      return;
    }
    setActiveStep(index);
  };

  const handleNext = async () => {
    switch (activeStep) {
      case 0:
        // from step 0 we go to step 1 if selectAll is false otherwise to step 2 where we will select
        // the same variants and specializations for every resource
        onSelectStep(selectAllMatching ? 2 : 1);
        break;
      case 1:
        // in this case we will select variants and specializations ad hoc for every resource
        await handleCreate();
        break;
      case 2:
        // in this case we will select variants and specializations just for one times and apply those to every resource
        await handleCreate();
        break;
    }
  };

  const handleCancel = () => {
    switch (activeStep) {
      case 0:
        onClose();
        break;
      case 1:
        setActiveStep(0);
        break;
      case 2:
        setActiveStep(0);
        break;
    }
  };

  const toggleItem = (resourceId: string) => {
    if (!ids.includes(resourceId)) {
      setIds([...ids, resourceId]);
      setFinalSelections([
        ...finalSelections,
        resources.find((resource) => resource.id === resourceId),
      ]);
    } else {
      setSelectAll(false);
      setSelectedResourcesNumber(0);
      setSelectAllMatching(false);
      setIds(ids.filter((i) => i !== resourceId));
      setFinalSelections(finalSelections.filter((r) => r.id !== resourceId));
    }

    const selResources = resources.filter((resource) =>
      ids.includes(resource.id)
    );
    setSelectedResources(selResources);
    const variant = siteVariants?.find(
      (variant) => variant.is_default
    );
    const mappedVariant = selResources.map((r) => ({
      [r.id]: variant ? variant : null,
    }));
    setSelectedVariant(mappedVariant);
    const specializations = selResources.map((r) => ({ [r.id]: [] }));
    setSelectedSpecialization(specializations);
  };

  const toggleSelectAll = (value: boolean) => {
    setSelectAll(value);
    if (!value) {
      setIds([]);
      setFinalSelections([]);
      setSelectAllMatching(false);
    } else {
      setIds(resources.map((resource) => resource.id));
      setFinalSelections(resources.map((resource) => resource));
    }
  };

  const handleSelectionButton = () => {
    if (selectAllMatching) {
      setSelectAll(false);
      setSelectAllMatching(false);
      setIds([]);
      setFinalSelections([]);
      return;
    }
    setSelectAllMatching(true);
  };

  const selectVariant = (variantId: Tag, resourceId: string, index: number) => {
    const updatedVariant = [...selectedVariant];
    const selVariant = {
      [resourceId]: siteVariants?.find((v) => v.id === variantId.id),
    };
    if (updatedVariant.length > 0) {
      updatedVariant[index] = selVariant;
    }
    setSelectedVariant(updatedVariant);
  };

  const selectSpecialization = (
    spec: Tag,
    resourceId: string,
    index: number
  ) => {
    if (!resources.find((r) => r.id === resourceId)) {
      return; // Resource id does not exist.
    }
    if (!spec) {
      return;
    }
    const rowSpecs = selectedSpecialization
      ? selectedSpecialization[index][resourceId]
      : [];
    rowSpecs.push(spec);
    setSelectedSpecialization([
      ...selectedSpecialization,
      { [resourceId]: rowSpecs },
    ]);
  };

  const deleteSpecialization = (
    spec: Tag,
    resourceId: string,
    index: number
  ) => {
    const updatedSpecs = { ...selectedSpecialization };
    const rowSpecs = selectedSpecialization
      ? selectedSpecialization[index][resourceId]
      : [];
    const filteredData = rowSpecs.filter((rs) => rs.id !== spec.id);
    updatedSpecs[index][resourceId] = filteredData;

    setSelectedSpecialization([
      ...selectedSpecialization,
      { [resourceId]: filteredData },
    ]);
  };

  const isButtonDisabled = (() => {
    switch (activeStep) {
      case 0:
        return ids.length === 0 && !selectAllMatching;
      case 1:
        return false;
      case 2:
        return selectedAllVariants === undefined;
      default:
        return true; // Assuming default behavior is not disabled
    }
  })();

  useEffect(() => {
    const selResources = finalSelections?.filter((resource) =>
      ids.includes(resource.id)
    );
    setSelectedResources(selResources);
    let variant = siteVariants?.find(
      (variant) => variant.is_default
    );
    if (!variant && siteVariants) {
      variant = siteVariants[0];
    }
    const mappedVariant = selResources?.map((r) => ({
      [r.id]: variant ? variant : null,
    }));
    setSelectedVariant(mappedVariant);
    const specializations = selResources?.map((r) => ({ [r.id]: [] }));
    setSelectedSpecialization(specializations);
  }, [ids]);

  const [isMobile] = useMediaQuery("(max-width: 767px)");
  useEffect(() => {
    if (resources.length > 0 && selectAllMatching && !insertedResourcesNumber) {
      const resourceIds = resources.map((resource) => resource.id);
      const allIds = [...resourceIds, ...ids];
      const uniqueBadgeIds = Array.from(new Set(allIds));
      setIds(uniqueBadgeIds);
    }
  }, [resources.length]);

  return (
    <Modal isOpen={true} onClose={onClose} size="4xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent
        style={{ background: "white", padding: "2rem" }}
        overflowY={"unset"}
        ref={portalRef}
      >
        <ModalHeader>
          <Box
            sx={{
              padding: "1rem",
              border: "1px solid",
              borderColor: "gray.300",
              borderRadius: "5px",
              marginBottom: "20px",
            }}
          >
            <Steps
              selected={activeStep}
              steps={[
                { title: t(`${type}.resources`) },
                { title: t("summary") },
              ]}
              onSelect={onSelectStep}
            />
          </Box>
        </ModalHeader>
        <ModalBody>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Box>
              <Text fontSize="xl" fontWeight={500}>
                {t(`${type}.titleStep${activeStep + 1}`)}
              </Text>
              <Text fontSize="sm" mb={5}>
                {t(`${type}.descriptionStep${activeStep + 1}`)}
              </Text>
            </Box>
            {activeStep === 1 && (
              <Tooltip
                shouldWrapChildren
                label={t("pdf.tooltipExportPdf", { ns: "common" })}
              >
                <BlobProvider
                  document={
                    <RequirementsPdf
                      data={requirements}
                      columnsVariantTable={[
                        t("pdf.variants", { ns: "common" }),
                        t("pdf.documents", { ns: "common" }),
                      ]}
                      columnsSpecializationTable={[
                        t("pdf.specializations", { ns: "common" }),
                        t("pdf.documents", { ns: "common" }),
                      ]}
                      title={t("pdf.tooltipExportPdf", {
                        ns: "common",
                      })}
                      subTitle={t("pdf.subTitle", {
                        ns: "common",
                      })}
                    />
                  }
                >
                  {({ url, loading }) => (
                    <Box
                      backgroundColor={COLORS.sikuroBlue}
                      color={COLORS.white}
                      padding={2}
                      borderRadius={"5px"}
                      cursor={"pointer"}
                    >
                      <Link
                        href={url}
                        _hover={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Flex
                          gap={4}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          {loading ? <ImSpinner6 /> : <FaCloudDownloadAlt />}
                        </Flex>
                      </Link>
                    </Box>
                  )}
                </BlobProvider>
              </Tooltip>
            )}
          </Flex>

          {(() => {
            switch (activeStep) {
              case 0:
                return (
                  <>
                    <TextInput value={search} onChange={setSearch} />
                    <Flex
                      flexDirection={"column"}
                      alignItems={"start"}
                      border="1px solid"
                      borderColor="gray.300"
                      borderRadius="10px"
                      width={isMobile ? "700px" : "100%"}
                      marginTop={5}
                      position="relative"
                      overflow={"hidden"}
                    >
                      <InfiniteTable
                        autosize
                        tableId="create-resource-modal-resource-table"
                        infiniteScroll={{
                          dataLength: resources?.length,
                          hasNextPage: resourcesHasNextPage,
                          fetchNextPage: resourcesFetchNextPage,
                        }}
                        emptyText={t("noResourcesAvailable", {
                          ns: "siteResources",
                        })}
                        showEmptyText={resources?.length === 0}
                        isLoading={ownedResourcesFetching}
                        bottomThreshold={200}
                        isCheckboxTable={true}
                      >
                        <Thead>
                          <Tr>
                            {selectAllAvailable && (
                              <Th key={"selectAllCheckbox"} width={10}>
                                <Checkbox
                                  borderColor={"gray.500"}
                                  isChecked={selectAll}
                                  onChange={() => toggleSelectAll(!selectAll)}
                                ></Checkbox>
                              </Th>
                            )}
                            {columns.map((column) => (
                              <Th key={column}>
                                <TableColumnHeader
                                  text={t(
                                    (type === "vehicle" ||
                                      type === "machine") &&
                                      column === "name"
                                      ? "brand"
                                      : column
                                  )} // FIXME: find a better way to manage this thing.
                                  sort={{
                                    handler: (direction) =>
                                      setSort({ field: column, direction }),
                                    direction:
                                      sort && sort.field === column
                                        ? sort.direction
                                        : null,
                                  }}
                                />
                              </Th>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody borderRadius="xl">
                          <Tr width={"100%"}>
                            {selectAll && (
                              <Th
                                colSpan={columns.length + 1}
                                backgroundColor={"gray.100"}
                              >
                                <Text color={COLORS.red}>
                                  {insertedResourcesNumber >
                                    availableResourcesTotalCount &&
                                    t("selectionTooltip", {
                                      ns: "siteResources",
                                      count: availableResourcesTotalCount,
                                    })}
                                </Text>
                                <Text textAlign="center" mx="auto">
                                  {insertedResourcesNumber <
                                    availableResourcesTotalCount &&
                                    !selectAllMatching &&
                                    t("resourcesSelectedVisible")}
                                  {insertedResourcesNumber <
                                    availableResourcesTotalCount &&
                                    selectAllMatching &&
                                    !manuallySelectedNumber &&
                                    t("resourcesSelectedNotVisible", {
                                      count: availableResourcesTotalCount,
                                    })}
                                  {insertedResourcesNumber <
                                    availableResourcesTotalCount &&
                                    selectAllMatching &&
                                    manuallySelectedNumber &&
                                    t("resourcesSelectedManuallyNotVisible", {
                                      countSelected: selectedResourcesNumber,
                                      total: availableResourcesTotalCount,
                                    })}
                                  {insertedResourcesNumber <
                                    availableResourcesTotalCount &&
                                    resourcesHasNextPage && (
                                      <Button
                                        mt="10px"
                                        ml="4px"
                                        colorScheme="blue"
                                        variant="link"
                                        onClick={() => handleSelectionButton()}
                                      >
                                        {t(
                                          selectAllMatching
                                            ? "clearSelection"
                                            : "resourcesSelectAll",
                                          { ns: "siteResources" }
                                        )}
                                      </Button>
                                    )}
                                </Text>
                              </Th>
                            )}
                          </Tr>

                          {resources?.map((resource) => (
                            <Tr key={resource.id} width={"100%"}>
                              <Td width={10}>
                                <Checkbox
                                  borderColor={"gray.500"}
                                  isChecked={ids?.includes(resource["id"])}
                                  onChange={() => toggleItem(resource["id"])}
                                />
                              </Td>
                              {columns.map((column) => (
                                <Td key={column}>{resource[column]}</Td>
                              ))}
                            </Tr>
                          ))}
                        </Tbody>
                      </InfiniteTable>
                    </Flex>
                  </>
                );
              case 1:
                return (
                  <Flex
                    flexDirection={"column"}
                    alignItems={"start"}
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="10px"
                    width={isMobile ? "700px" : "100%"}
                    marginTop={5}
                    position="relative"
                    overflow={"hidden"}
                  >
                    <SimpleTable
                      autosize
                      tableId="resources-table"
                      emptyText={t("noResourcesAvailable", {
                        ns: "siteResources",
                      })}
                      showEmptyText={selectedResources?.length === 0}
                      bottomThreshold={200}
                    >
                      <Thead>
                        <Tr>
                          {summaryColumns.map((column) => (
                            <Th key={column}>
                              <TableColumnHeader
                                text={t(
                                  (type === "vehicle" || type === "machine") &&
                                    column === "name"
                                    ? "brand"
                                    : column
                                )} // FIXME: find a better way to manage this thing.
                              />
                            </Th>
                          ))}
                        </Tr>
                      </Thead>
                      <Tbody borderRadius="xl">
                        {selectedResources.map((resource, index) => {
                          const resourceVariants = selectedVariant?.filter(
                            (v) => v[resource.id]
                          );
                          return (
                            <Tr key={resource.id}>
                              <Td>
                                {" "}
                                {Object.prototype.hasOwnProperty.call(
                                  resource,
                                  "firstName"
                                )
                                  ? `${resource.firstName} ${resource.lastName}  \n  ${resource.fiscalCode}`
                                  : ` ${resource.name}`}{" "}
                              </Td>
                              <Td textColor={"gray.700"}>
                                <CellTagList
                                  tags={siteVariants}
                                  selectedTags={
                                    selectedVariant
                                      ? [
                                        resourceVariants.length > 0 &&
                                        resourceVariants[0][resource.id],
                                      ]
                                      : []
                                  }
                                  onAdd={(variantObject) =>
                                    selectVariant(
                                      variantObject,
                                      resource.id,
                                      index
                                    )
                                  }
                                  isTagLoading={false}
                                  required
                                  isCreatable={false}
                                />
                              </Td>
                              <Td textColor={"gray.700"}>
                                <CellTagList
                                  tags={specializationsFromList}
                                  selectedTags={
                                    specializationsFromList
                                      ? selectedSpecialization[index][
                                      resource.id
                                      ]
                                      : []
                                  }
                                  onAdd={(specObject) =>
                                    selectSpecialization(
                                      specObject,
                                      resource.id,
                                      index
                                    )
                                  }
                                  onDelete={(specObject) =>
                                    deleteSpecialization(
                                      specObject,
                                      resource.id,
                                      index
                                    )
                                  }
                                  isTagLoading={false}
                                  required
                                  isCreatable={false}
                                />
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </SimpleTable>
                    {resources?.length === 0 && !ownedResourcesFetching && (
                      <Text
                        height={16}
                        width={"100%"}
                        backgroundColor={"gray.100"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        display="flex"
                        textAlign={"center"}
                        color={"black"}
                      >
                        {t("noResourcesAvailable", { ns: "siteResources" })}
                      </Text>
                    )}
                  </Flex>
                );

              case 2:
                return (
                  <Flex
                    flexDirection={"column"}
                    alignItems={"start"}
                    width="100%"
                    position="relative"
                    overflow={"hidden"}
                    paddingTop={3}
                    gap={5}
                    marginBottom={3}
                  >
                    <SingleTagSelect
                      fullWidth
                      label={t("variants") + "*"}
                      tags={siteVariants}
                      selectedTag={selectedAllVariants}
                      setSelectedTag={setSelectedAllVariants}
                      defaultMenuIsOpen={false}
                      menuPosition={"fixed"}
                      required
                    />
                    <MultiTagSelect
                      fullWidth
                      label={t("specializations")}
                      tags={specializationsFromList}
                      selectedTags={selectedAllSpecializations}
                      setSelectedTags={setSelectedAllSpecializations}
                      defaultMenuIsOpen={false}
                      menuPosition={"fixed"}
                    />
                  </Flex>
                );
              default:
                return null;
            }
          })()}

          {showMessage && (
            <ConfirmAlert
              message={t("notEnoughBadges")}
              onCancel={() => setShowMessage(false)}
              title={t("warning", { ns: "common" })}
              variant="warning"
              onConfirm={() => handleConfirm()}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="flex-end" gap={2} mt={5}>
            <Button
              isLoading={isLoading}
              isDisabled={isButtonDisabled}
              colorScheme="blue"
              onClick={handleNext}
              mr="20px"
            >
              {t("confirm")}
            </Button>
            <Button colorScheme="red" onClick={handleCancel}>
              {t("cancel")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateSiteResourceModal;
