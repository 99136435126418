import {
  makeGetRoles,
  makeGetUserSiteRoles,
  makeLinkUserSiteRole,
  makeUnlinkUserSiteRole,
} from "../../../core/app/factory/roles/usecases";
import { makeGetSitesForRoleAssignment } from "../../../core/app/factory/sites/usecases";
import { makeGetWorkingSites } from "../../../core/app/factory/suppliers/useCases";
import {
  makeGetUser,
  makeUpdateUser,
  makeUpdateUserImage,
} from "../../../core/app/factory/users/usecases";
import Role, { RoleContext } from "../../../domain/entities/role";
import Site from "../../../domain/entities/site";
import Supplier from "../../../domain/entities/supplier";
import User, { UserSiteRole } from "../../../domain/entities/user";
import { GetSiteRolesFilter } from "../../../domain/repositories/filters";

class UpdateUserViewModel {
  private updateUC = makeUpdateUser();
  private getUC = makeGetUser();
  private getRolesUC = makeGetRoles();
  private updateImageUC = makeUpdateUserImage();
  private linkUserSiteRoleUC = makeLinkUserSiteRole();
  private unlinkUserSiteRoleUC = makeUnlinkUserSiteRole();
  private getUserSiteRolesUC = makeGetUserSiteRoles();

  constructor(
    private companyId: string,
    private userId?: string,
  ) {}

  async update(user: User): Promise<User> {
    return await this.updateUC.Call({
      companyId: this.companyId,
      userId: this.userId,
      user,
    });
  }

  async getUserSiteRoles(
    filters?: GetSiteRolesFilter,
  ): Promise<UserSiteRole[]> {
    const results =
      (await this.getUserSiteRolesUC.Call({
        companyId: this.companyId,
        userId: this.userId,
        filters,
      })) ?? [];

    return results;
  }

  async get(): Promise<User> {
    return await this.getUC.Call({
      companyId: this.companyId,
      userId: this.userId,
    });
  }

  async getRoles(): Promise<Role[]> {
    const roles =
      (await this.getRolesUC.Call({
        companyId: this.companyId,
      })) ?? [];

    return roles;
  }

  async linkUserSiteRole(
    siteId: string,
    roleId: string,
  ): Promise<UserSiteRole> {
    return this.linkUserSiteRoleUC.Call({
      companyId: this.companyId,
      userId: this.userId,
      siteId,
      roleId,
    });
  }

  async unlinkUserSiteRole(siteId: string, roleId: string): Promise<boolean> {
    return this.unlinkUserSiteRoleUC.Call({
      companyId: this.companyId,
      userId: this.userId,
      siteId,
      roleId,
    });
  }

  async updateUserSiteRole(
    siteId: string,
    oldRoleId: string,
    newRoleId: string,
  ): Promise<UserSiteRole> {
    try {
      return await this.linkUserSiteRole(siteId, newRoleId);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateUserImage(userId: string, image: File): Promise<User> {
    return await this.updateImageUC.Call({
      companyId: this.companyId,
      userId,
      image,
    });
  }
}

export default UpdateUserViewModel;
