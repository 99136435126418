import UseCase from '../../../core/app/usecase';
import SupplierRepository, { UnlistedCompany } from '../../repositories/supplierRepository';

type InviteSuppliersArgs = {
	companyId: string;
	siteId: string;
	documentIds?: string[];
	supplierIds?: string[];
	supplierVariant?: string;
	unlistedCompany?: UnlistedCompany;
};

class InviteSuppliers implements UseCase<InviteSuppliersArgs, void> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, siteId, supplierIds, documentIds, supplierVariant, unlistedCompany }: InviteSuppliersArgs): Promise<void> {
		return await this.supplierRepository.inviteSuppliers(companyId, siteId, supplierIds, documentIds, supplierVariant, unlistedCompany);
	}
}

export default InviteSuppliers;
