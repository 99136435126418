import {
  Button,
  Text,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  IconButton,
  Spacer,
} from "@chakra-ui/react";
import { t } from "i18next";
import { FC, ReactNode } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { MdClose } from "react-icons/md";

// Props.
interface DeleteActionProps {
  mainTitle?: string;
  title: string | ReactNode;
  isOpen: boolean;
  leftButtonText?: string;
  rightButtonText?: string;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  children?: ReactNode;
}

/**
 * Renders a modal view that asks user to confirm an action.
 *
 * @param title the modal view title
 * @param isOpen the flag indicating if the view is visible
 * @param onCancel the handler that cancels the user action, usually closing the view
 * @param onConfirm the handler that confirms the user action, usually closing the view
 * @returns
 */
const DeleteActionAlert: FC<DeleteActionProps> = ({
  mainTitle,
  title,
  isOpen,
  leftButtonText,
  rightButtonText,
  onCancel,
  onConfirm,
  children,
  isLoading,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader flexDirection="column" paddingLeft={8} paddingRight={8}>
          <Flex>
            <Spacer />
            <IconButton
              fontSize="3xl"
              size="2xl"
              backgroundColor="transparent"
              onClick={onCancel}
              aria-label="Edit"
            >
              <MdClose />
            </IconButton>
          </Flex>
          <Flex alignItems="center" justifyContent={"center"}>
            <FiAlertTriangle color="red" />
            <Text
              textColor={"black"}
              fontSize={22}
              fontWeight={"bold"}
              marginLeft={3}
            >
              {mainTitle}
            </Text>
          </Flex>
          <Text
            textColor={"black"}
            fontSize={16}
            marginTop={3}
            fontWeight="normal"
            textAlign={"center"}
          >
            {title}
          </Text>
        </ModalHeader>
        <ModalFooter>
          <Flex justifyContent="center" alignItems="center" width={"100%"}>
            <Button
              bg="red.600"
              textColor="white"
              onClick={onConfirm}
              borderRadius={15}
              marginRight={5}
              isLoading={isLoading}
            >
              {leftButtonText ?? t("yes")}
            </Button>
            <Button
              bg="blue.500"
              textColor="white"
              onClick={onCancel}
              borderRadius={15}
            >
              {rightButtonText ?? t("no")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { DeleteActionAlert };
