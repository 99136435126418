import { Outlet } from "react-router-dom";
import { FC, ReactNode } from "react";
import { useAuth, PermissionCheck } from "../../providers/Auth0JWTProvider";
import LicenseNotActiveMainView from "./LicenseNotActiveMainView";

interface Props {
  routeName?: string;
  element?: ReactNode;
  check?: PermissionCheck;
}

const RequireLicense: FC<Props> = () => {
  const { company } = useAuth();
  return company?.state !== "disabled" ? (
    <>
      <Outlet />
    </>
  ) : (
    <LicenseNotActiveMainView />
  );
};

export default RequireLicense;
