import { NavItemProps } from "../../Common/NavItem";
import SubNavBar from "../../Common/SubNavBar";
import { useParams } from "react-router-dom";
import WorkerIcon from "../../Common/icons/WorkerIcon";
import VehicleIcon from "../../Common/icons/VehicleIcon";
import MachineIcon from "../../Common/icons/MachineIcon";
import ToolIcon from "../../Common/icons/ToolIcon";
import ChemicalIcon from "../../Common/icons/ChemicalIcon";
import { useTranslation } from "react-i18next";
import { getSectionPermissions } from "../../Permissions/Permissions";

const SupplierResourcesSubNav = () => {
  const { supplierId } = useParams();
  const { t } = useTranslation("navbar");

  const items: NavItemProps[] = [
    {
      Icon: WorkerIcon,
      path: `/suppliers/${supplierId}/resources/workers`,
      name: t("sub.workers"),
      permissions: getSectionPermissions("supplier", "workers"),
    },
    {
      Icon: VehicleIcon,
      path: `/suppliers/${supplierId}/resources/vehicles`,
      name: t("sub.vehicles"),
      permissions: getSectionPermissions("supplier", "vehicles"),
    },
    {
      Icon: MachineIcon,
      path: `/suppliers/${supplierId}/resources/machines`,
      name: t("sub.machines"),
      permissions: getSectionPermissions("supplier", "machines"),
    },
    {
      Icon: ToolIcon,
      path: `/suppliers/${supplierId}/resources/tools`,
      name: t("sub.tools"),
      permissions: getSectionPermissions("supplier", "tools"),
    },
    {
      Icon: ChemicalIcon,
      path: `/suppliers/${supplierId}/resources/chemicals`,
      name: t("sub.chemicals"),
      permissions: getSectionPermissions("supplier", "chemicals"),
    },
  ];

  return <SubNavBar items={items} />;
};

export default SupplierResourcesSubNav;
