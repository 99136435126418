import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../Common/TopBar";

const DashboardTopBar = () => {
  const { t } = useTranslation();

  const items: TopBarItem[] = [
    {
      title: t("overview", { ns: "navbar" }),
      path: "/overview",
    },
  ];

  return <TopBar title="Dashboard" items={items} />;
};

export default DashboardTopBar;
