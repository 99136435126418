// This view is redundant, but is useful as it will not render the dashboard if the user has
// no dashboard related permission.
// Dashboard widgets should be made atomic, so that DashboardOverviewView does not issue all
// requests to all endpoints, especially when the user has not all dashboard permissions.

import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ContentLayout from "../../layout/ContentLayout";
import DashboardOverviewView from "./DashboardOverviewView";
import { Permission } from "../../components/Permissions/Permissions";
import { ErrorBanner } from "../../components/Common/alerts/ErrorBanner";
import { useAuth, PermissionCheck } from "../../providers/Auth0JWTProvider";
import permission from "../../../domain/entities/permission";

const DashboardFallbackView = () => {
  const { t } = useTranslation("dashboard");
  const { hasPermissions, companyUser } = useAuth();

  const hasNoPermissions = hasPermissions(
    [
      Permission.Dashboard_ShowOwnedSitesCount,
      Permission.Dashboard_ShowWorkingSitesCount,
      Permission.Dashboard_ShowSuppliersCount,
      Permission.Dashboard_ShowUnevaluatedDocuments,
      Permission.Dashboard_ShowExpiringEvaluations,
      Permission.Dashboard_ShowInvitedSuppliers,
      Permission.Dashboard_ShowExpiringDocuments,
      Permission.Dashboard_ShowPendingEvaluations,
      Permission.Dashboard_ShowExpiringResourcesEvaluations,
      Permission.Dashboard_ShowExpiringCompanyOrSiteEvaluations,
    ],
    PermissionCheck.None,
  );
  return (
    <ContentLayout>
      {hasNoPermissions || companyUser?.state === "blocked" ? (
        <Box p={10}>
          <Text
            borderRadius={10}
            padding={4}
            bg="red.100"
            fontWeight="semibold"
          >
            {companyUser?.state === "blocked"
              ? t("accessDenied.blockedUserOnTenant", { ns: "permissions" })
              : t("noPermissions")}{" "}
          </Text>
        </Box>
      ) : (
        <DashboardOverviewView />
      )}
    </ContentLayout>
  );
};

export default DashboardFallbackView;
