import UseCase from '../../../core/app/usecase';
import DocumentRepository from '../../repositories/documentRepository';
import { UserMini } from '../../entities/user';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';

interface Args {
	siteId?: string;
	companyId: string;
	resourceId?: string;
	resourceType?: DocumentTypeCategory;
}

class GetTaggableUsers implements UseCase<Args, UserMini[]> {
	constructor(private repository: DocumentRepository) {}

	async Call({ siteId, companyId, resourceId, resourceType }: Args): Promise<UserMini[]> {
		return await this.repository.getTaggableUsers(companyId, siteId, resourceId, resourceType);
	}
}

export default GetTaggableUsers;
