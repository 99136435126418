import UseCase from '../../../core/app/usecase';
import Requirement from '../../entities/requirement';
import SupplierRepository from '../../repositories/supplierRepository';
import { RequirementSubject } from '../../entities/requirementSubject.enum';

type ARGS = {
	companyId: string;
	supplierId: string;
	resourceId: string;
	documentTypeId: string;
	isOptional: boolean;
	graceDays: number;
	subject: RequirementSubject;
};

class AddRequirementToSupplier implements UseCase<ARGS, Requirement> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call(parameters: ARGS): Promise<Requirement> {
		return await this.supplierRepository.addRequirementToSupplierCompanyResource(
			parameters.companyId,
			parameters.resourceId,
			parameters.supplierId,
			parameters.documentTypeId,
			parameters.isOptional,
			parameters.graceDays,
			parameters.subject,
		);
	}
}

export default AddRequirementToSupplier;
