import UseCase from '../../../core/app/usecase';
import AuditRepository from '../../repositories/auditRepository';

type Args = {
	companyId: string;
	auditId: string;
	siteId?: string;
};

class DeleteAudit implements UseCase<Args, void> {
	constructor(private repository: AuditRepository) {}

	async Call({ companyId, auditId, siteId }: Args): Promise<void> {
		return await this.repository.deleteAudit(companyId, auditId, siteId);
	}
}

export default DeleteAudit;
