import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { CalendarCategories } from "../../../screens/TimeTable/TimeTableCalendarView";
import { t } from "i18next";

export type LegendItemProps = {
  color: string;
  label: string;
};
const LegendItem = ({ color, label }: LegendItemProps) => (
  <Box display="flex" alignItems="center" mb={2}>
    <Box w={4} h={4} borderRadius="full" mr={0.5} backgroundColor={color} />
    <Text mr={2}>{t(label, { ns: "common" })}</Text>
  </Box>
);

export type LegendProps = {
  categories: CalendarCategories;
};
const Legend = ({ categories }: LegendProps) => (
  <Box mb={4} marginTop={5} display="flex" justifyContent="center" gap="0.2rem">
    {Object.entries(categories).map(([category, color]) => (
      <LegendItem key={category} color={color} label={category} />
    ))}
  </Box>
);

export default Legend;
