import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SiteToolParams } from "../../../domain/entities/siteTool";
import SupplierResourcesListView from "../../components/Views/Suppliers/SupplierResourcesListView";
import useSupplierResourcesViewModel from "../../hooks/Suppliers/useSupplierResourcesViewModel";
import SupplierToolsViewModel from "../../viewmodels/suppliers/SupplierToolsViewModel";

const SupplierToolsView = () => {
  const resourceColumns = ["name", "serial"];
  return (
    <SupplierResourcesListView<SiteToolParams>
      type={DocumentTypeCategory.TOOL}
      useViewModel={useSupplierResourcesViewModel(
        new SupplierToolsViewModel(),
        DocumentTypeCategory.TOOL,
      )}
      resourceColumns={resourceColumns}
      namespace={"tools"}
    />
  );
};

export default SupplierToolsView;
