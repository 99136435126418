import {
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { FiInfo } from "react-icons/fi";

type InputWrapperProps = {
  children: React.ReactNode;
  error?: string;
  label?: string;
  tooltip?: string;
} & FormControlProps;

const InputControl = ({
  children,
  error,
  label,
  tooltip,
  ...formControlProps
}: InputWrapperProps) => (
  <FormControl {...formControlProps} position="relative">
    {children}
    <FormLabel
      sx={{
        position: "absolute",
        top: "-7px",
        left: "8px",
        padding: "0 10px",
        fontSize: "10px",
        display: "flex",
        alignItems: "center",
      }}
      backgroundColor="white"
      borderRadius={4}
      color="gray.500"
    >
      <Box display="flex" alignItems="center">
        {label}
        {tooltip && (
          <Tooltip label={tooltip} placement="right">
            <Box as="span" ml={1}>
              <FiInfo />
            </Box>
          </Tooltip>
        )}
      </Box>
    </FormLabel>
    <FormErrorMessage>{error}</FormErrorMessage>
  </FormControl>
);

export default InputControl;
