import {
  makeGetDashboardCount,
  makeGetDashboardChartData,
  makeGetDashboardDocuments,
  makeGetDashboardSupplierInfo,
  makeGetDashboardCompanyExpiringDocuments,
  makeGetDashboardExpiringResourcesEvaluation,
  makeGetDashboardExpiringCompanyOrSiteEvaluation,
  makeGetDashboardExpiringCompanyDocument,
} from "../../../core/app/factory/dashboard/usecases";

import { DashboardTopic } from "../../../domain/entities/dashboardTopic";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { DashboardSupplierInfo } from "../../../domain/entities/dashboardSupplierInfo";
import {
  DashboardCount,
  DashboardChartData,
} from "../../../domain/entities/dashboardCount";
import { GetSupplierEvaluationsFilter } from "../../../domain/repositories/dashboardRepository";
import { DashboardUnevaluatedDocument } from "../../../domain/entities/dashboardUnevaluatedDocuments";
import { DashboardCompanyExpiringDocument } from "../../../domain/entities/dashboardCompanyExpiringDocument";
import { DashboardExpiringSupplierEvaluation } from "../../../domain/entities/dasboardExpiringSupplierEvaluation";

class DashboardViewModel {
  private getDashboardCountUC = makeGetDashboardCount();
  private getDashboardDocumentsUC = makeGetDashboardDocuments();
  private getDashboardChartDataUC = makeGetDashboardChartData();
  private getDashboardSupplierInfoUC = makeGetDashboardSupplierInfo();
  private getExpiringResourcesEvaluationsUC =
    makeGetDashboardExpiringResourcesEvaluation();
  private getDashboardCompanyExpiringDocumentsUC =
    makeGetDashboardCompanyExpiringDocuments();
  private getExpiringCompanyOrSiteEvaluationsUC =
    makeGetDashboardExpiringCompanyOrSiteEvaluation();
  private getDashboardExpiringCompanyDocumentUC =
    makeGetDashboardExpiringCompanyDocument();

  async getDashboardCount(
    companyId: string,
    topic: DashboardTopic,
    days?: number,
  ): Promise<DashboardCount> {
    return await this.getDashboardCountUC.Call({ companyId, topic, days });
  }

  async getDashboardDocuments(
    companyId: string,
    topic: DashboardTopic,
    pageParam: number,
    sort: SortMeta,
    days?: number,
  ): Promise<DashboardUnevaluatedDocument[]> {
    return await this.getDashboardDocumentsUC.Call({
      companyId,
      topic,
      pageParam,
      sort,
      days,
    });
  }

  async getDashboardChartData(
    companyId: string,
    topic: DashboardTopic,
    days?: number,
  ): Promise<DashboardChartData> {
    return await this.getDashboardChartDataUC.Call({ companyId, topic, days });
  }

  async getDashboardSiteDocuments(
    companyId: string,
    pageParam: number,
    sort: SortMeta,
    days?: number,
  ): Promise<DashboardCompanyExpiringDocument[]> {
    return await this.getDashboardExpiringCompanyDocumentUC.Call({
      companyId,
      pageParam,
      sort,
      days,
    });
  }

  async getDashboardSupplierInfo(
    companyId: string,
    topic: DashboardTopic,
    pageParam: number,
    sort: SortMeta,
  ): Promise<DashboardSupplierInfo[]> {
    return await this.getDashboardSupplierInfoUC.Call({
      companyId,
      topic,
      pageParam,
      sort,
    });
  }

  async getDashboardCompanyExpiringDocuments(
    companyId: string,
    topic: DashboardTopic,
    pageParam: number,
    sort: SortMeta,
    days?: number,
  ): Promise<DashboardCompanyExpiringDocument[]> {
    return await this.getDashboardCompanyExpiringDocumentsUC.Call({
      companyId,
      topic,
      pageParam,
      sort,
      days,
    });
  }

  async getExpiringResourcesEvaluations(
    companyId: string,
    pageParam: number,
    sort: SortMeta,
    filter: GetSupplierEvaluationsFilter,
    days?: number,
  ): Promise<DashboardExpiringSupplierEvaluation[]> {
    return await this.getExpiringResourcesEvaluationsUC.Call({
      companyId,
      pageParam,
      sort,
      filter,
      days,
    });
  }

  async getExpiringCompanyOrSiteEvaluations(
    companyId: string,
    pageParam: number,
    sort: SortMeta,
    filter: GetSupplierEvaluationsFilter,
    days?: number,
  ): Promise<DashboardExpiringSupplierEvaluation[]> {
    return await this.getExpiringCompanyOrSiteEvaluationsUC.Call({
      companyId,
      pageParam,
      sort,
      filter,
      days,
    });
  }
}

export default DashboardViewModel;
