import UseCase from '../../../../core/app/usecase';
import SiteChemicalRepository from '../../../repositories/siteChemicalRepository';
type Parameters = {
	companyId: string;
	siteId: string;
	chemicalId: string;
};

class EvaluateSiteChemical implements UseCase<Parameters, void> {
	constructor(private repository: SiteChemicalRepository) {}

	async Call({ companyId, siteId, chemicalId }: Parameters): Promise<void> {
		return this.repository.autoEvaluateSiteChemical(companyId, siteId, chemicalId);
	}
}

export default EvaluateSiteChemical;
