import { Button, Flex, Text } from "@chakra-ui/react";
import { COLORS } from "../../assets/theme/colors";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import LoadingView from "../Common/LoadingView";
import { useNavigate } from "react-router-dom";

const ActivationComponent = ({
  activationFunction,
  isLoading,
  redirectUrl,
  showExploreBtn,
  msg,
  email,
  username
}: {
  activationFunction: () => void;
  isLoading: boolean;
  redirectUrl: string;
  showExploreBtn: boolean;
  msg: string;
  email?: string;
  username?: string
}) => {
  const { t } = useTranslation("errors");
  const navigate = useNavigate();

  useEffect(() => {
    activationFunction();
  }, []);

  if (isLoading || !msg) {
    return <LoadingView />;
  }
  return (
    <Flex flexDirection="column" alignItems="center" gap={6} padding={28}>
      {username &&
        <Text>{t('emailConfirmationSuccessMessage', {ns: 'userSettings', username: username})}</Text>
      }
        <Text>{t(msg, { ns: "errors" })}</Text>
      <Button
        bg={COLORS.sikuroBlue}
        color="white"
        size="lg"
        _hover={{ backgroundColor: COLORS.yellow, color: "black" }}
        onClick={() => navigate(showExploreBtn && redirectUrl ? redirectUrl : "/overview")}
      >
        {t(`${showExploreBtn ? "explore" : "goToDashboard"}`)}
      </Button>
    </Flex>
  );
};

export default ActivationComponent;
