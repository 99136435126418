import UseCase from '../../../../core/app/usecase';
import SiteWorkerRepository from '../../../repositories/siteWorkerRepository';
import { ResourceEvaluationState } from '../../../entities/resourceEvaluationState.enum';

type Parameters = {
	companyId: string;
	siteId: string;
	workerId: string;
	value: ResourceEvaluationState;
};

class EvaluateSiteWorker implements UseCase<Parameters, void> {
	constructor(private repository: SiteWorkerRepository) {}

	async Call({ companyId, siteId, workerId, value }: Parameters): Promise<void> {
		return this.repository.evaluateSiteWorker(companyId, siteId, workerId, value);
	}
}

export default EvaluateSiteWorker;
