import Tool from '../../../entities/tool';
import UseCase from '../../../../core/app/usecase';
import ToolRepository from '../../../repositories/toolRepository';

type ARGS = {
	companyId: string;
	tool: Tool;
	imageFile: File;
};

class UpdateTool implements UseCase<ARGS, Tool> {
	constructor(private repository: ToolRepository) {}

	async Call(parameters: ARGS): Promise<Tool> {
		return await this.repository.updateTool(parameters.companyId, parameters.tool, parameters.imageFile);
	}
}

export default UpdateTool;
