import User from '../../entities/user';
import UseCase from '../../../core/app/usecase';
import UsersRepository from '../../repositories/usersRepository';

interface Args {
	companyId: string;
	userId: string;
}

class GetUser implements UseCase<Args, User> {
	constructor(private repository: UsersRepository) {}

	async Call({ companyId, userId }: Args): Promise<User> {
		return await this.repository.getUser(companyId, userId);
	}
}

export default GetUser;
