import Site from "../../../domain/entities/site";
import Typology from "../../../domain/entities/typology";
import RequirementsGroup from "../../../domain/entities/requirementsGroup";
import {
  makeCreateSite,
  makeGetSites,
} from "../../../core/app/factory/sites/usecases";
import { makeGetRequirementsGroups } from "../../../core/app/factory/requirementsGroup/useCases";
import { makeGetTypologies } from "../../../core/app/factory/typologies/usecases";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { makeGetRequirementVariants } from "../../../core/app/factory/variants/usecases";
import Variant from "../../../domain/entities/variant";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import {makeGetCustomFields} from "../../../core/app/factory/companies/usecases";
import {CustomField} from "../../../domain/entities/customFields";
import {RequirementSubject} from "../../../domain/entities/requirementSubject.enum";

class SitesListViewModel {
  private getUC = makeGetSites();
  private createUC = makeCreateSite();
  private getRequirementsGroupsUC = makeGetRequirementsGroups();
  private getTypologiesUC = makeGetTypologies();
  private getCustomFieldsUC = makeGetCustomFields();

  constructor(private companyId: string) {}

  // Public.
  async get(
    filter?: GetSitesFilter,
    sort?: SortMeta,
    pageParam?: number,
    withPermissions?: boolean
  ): Promise<Site[]> {
    return await this.getUC.Call({
      companyId: this.companyId,
      filter,
      sort,
      pageParam,
      withPermissions,
    });
  }

  async create(
    site: Site,
    requirementsGroup?: RequirementsGroup,
    typology?: Typology,
    siteVariant?: Variant
  ): Promise<Site> {
    return await this.createUC.Call({
      companyId: this.companyId,
      site,
      requirementsGroup,
      typology,
      siteVariant,
    });
  }

  async getRequirementsGroups(): Promise<RequirementsGroup[]> {
    return await this.getRequirementsGroupsUC.Call(this.companyId);
  }

  async getTypologies(search?: string): Promise<Typology[]> {
    return await this.getTypologiesUC.Call({
      companyId: this.companyId,
      type: DocumentTypeCategory.SITE,
      search,
    });
  }

  async getCompanyVariants(
    companyId: string,
    requirementGroupId: string
  ): Promise<any[]> {
    return await makeGetRequirementVariants().Call({
      companyId,
      requirementGroupId,
      type: DocumentTypeCategory.COMPANY,
    });
  }

  async getSiteVariants(companyId: string, requirementGroupId): Promise<any[]> {
    return await makeGetRequirementVariants().Call({
      companyId,
      requirementGroupId,
      type: DocumentTypeCategory.SUPPLIER,
    });
  }

  async getCustomFields(companyId: string): Promise<CustomField[]> {
    return await this.getCustomFieldsUC.Call({ companyId, subject: RequirementSubject.SITE });
  }
}

export default SitesListViewModel;
