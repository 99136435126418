import UseCase from '../../../core/app/usecase';
import RolesRepository from '../../repositories/rolesRepository';

interface Args {
	userId: string;
	siteId: string;
	roleId: string;
	companyId: string;
}

class UnlinkUserSiteRole implements UseCase<Args, boolean> {
	constructor(private repository: RolesRepository) {}

	async Call({ companyId, userId, siteId, roleId }: Args): Promise<boolean> {
		return await this.repository.unlinkUserSiteRole(companyId, userId, siteId, roleId);
	}
}

export default UnlinkUserSiteRole;
