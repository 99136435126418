import { useState } from "react";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { BadgesListViewModel } from "../../viewmodels/badges/BadgesListViewModel";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import {
  GetAvailableBadgesFilters,
  GetBadgesFilters,
  LinkResourcesToBadgesResponse,
} from "../../../domain/repositories/badgeRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import File from "../../../domain/entities/file";
import Badge from "../../../domain/entities/badge";
import { updateFilterWithDelete } from "../../../utils";
import FileEntity from "../../../domain/entities/file";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import { GetResourceSitesFilter } from "../../../domain/repositories/siteRepository";
import { BadgeDetailViewModel } from "../../viewmodels/badges/BadgeDetailViewModel";
import { GetResourcesFilter } from "../../../domain/repositories/workerRepository";
import { GetSiteResourcesFilters } from "./useSiteBadgesListViewModel";
import Tag from "../../../domain/entities/tag";

const useBadgesListViewModel = () => {
  const { companyId } = useAuth();
  const viewModel = new BadgesListViewModel();
  const vm = new BadgeDetailViewModel();
  const [filters, setFilters] = useState<GetBadgesFilters>({});
  const [sort, setSort] = useState<SortMeta>(null);
  const [errorImport, setErrorImport] = useState<string>(null);
  const [errorCreate, setErrorCreate] = useState<string>();

  const [linkBadgesToResourcesError, setLinkBadgesToResourcesError] =
    useState<LinkResourcesToBadgesResponse>(null);
  const [resourceType, setResourceType] = useState<BadgeResourceType>(null);
  const [search, setSearch] = useState<string>();
  const [searchBadges, setSearchBadges] = useState<string>();
  const [resourceFilters, setResourceFilters] =
    useState<GetResourceSitesFilter>({ companyId });
  const [resourceSort, setResourceSort] = useState<SortMeta>(null);
  const [importLinkError, setImportLinkError] = useState<string | null>();
  // Define a state to store the total count
  const [totalBadgeAvailableCount, setTotalBadgeAvailableCount] =
    useState<number>(0);
  const [totalBadgeRegisteredCount, setTotalBadgeRegisteredCount] =
    useState<number>(0);

  const [badgesCount, setBadgesCount] = useState<number>(0);
  const [resourcesCount, setResourcesCount] = useState<number>(0);
  const [availableBadges, setAvailableBadges] = useState<boolean>(false);
  const [availableResources, setAvailableResources] = useState<boolean>(false);
  const [availableBadgesFilters, setAvailableBadgesFilters] =
    useState<GetAvailableBadgesFilters>({});
  const [availableBadgesSort, setAvailableBadgesSort] =
    useState<SortMeta>(null);

  const isCompanyBadge = location.pathname.includes("company/badges");

  const getBadgesQuery = useInfiniteQuery(
    ["badges", companyId, filters, sort, searchBadges],
    async ({ pageParam = 1 }) => {
      const badgeFilters = searchBadges
        ? { ...filters, search: searchBadges }
        : filters;
      const { results = [], count = 0 } =
        resourceType || isCompanyBadge
          ? await viewModel.list(companyId, pageParam, badgeFilters, sort)
          : { results: [], count: 0 };
      setBadgesCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );
  const getAvailableBadgesQuery = useInfiniteQuery(
    [
      "available-badges",
      companyId,
      availableBadgesFilters,
      sort,
      availableBadges,
      resourceType,
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } =
        resourceType &&
        (await viewModel.list(
          companyId,
          pageParam,
          availableBadgesFilters,
          sort,
          "available"
        ));
      setTotalBadgeAvailableCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const getTagsQuery = useQuery(
    ["tags", companyId],
    async () => {
      return await viewModel.getTags(companyId);
    },
    {
      initialData: [],
    }
  );

  const deleteBadgeMutation = useMutation(
    (badgeId: string) => viewModel.delete(companyId, badgeId),
    {
      onSuccess: () => getBadgesQuery.refetch(),
    }
  );

  const createBadgeMutation = useMutation(
    (badge: Badge) => viewModel.createBadge(companyId, badge),
    {
      onSuccess: () => getBadgesQuery.refetch(),
    }
  );

  const createMultipleBadgeQRMutation = useMutation(
    (params: { count: number; tags: Tag[] }) =>
      viewModel.createMultipleBadgeQR(companyId, params.count, params.tags),
    {
      onSuccess: () => getBadgesQuery.refetch(),
    }
  );

  const importBadgesMutation = useMutation(
    (file: File) => viewModel.import(companyId, file),
    {
      onSuccess: () => getBadgesQuery.refetch(),
    }
  );

  const importBadges = async (file: FileEntity) => {
    try {
      return await importBadgesMutation.mutateAsync(file);
    } catch (err) {
      setErrorImport(err.message);
    }
  };

  const createBadges = async (badge: Badge) => {
    try {
      return await createBadgeMutation.mutateAsync(badge);
    } catch (err) {
      if (err.toString().includes("error.badge_serial_conflict")) {
        setErrorCreate("badge_serial_conflict");
      } else {
        if (err.toString().includes("error.badge_code_conflict")) {
          setErrorCreate("badge_code_conflict");
        }
      }
    }
  };

  const createMultipleBadgeQR = async (count: number, tags: Tag[]) => {
    return await createMultipleBadgeQRMutation.mutateAsync({ count, tags });
  };

  const updateFilter = (
    field: keyof GetBadgesFilters,
    value: string | string[]
  ) => {
    updateFilterWithDelete(setFilters, field, value);
  };

  const clearErrors = () => {
    getBadgesQuery.refetch();
    deleteBadgeMutation.reset();
  };

  const getResources = useInfiniteQuery(
    [
      "resources",
      companyId,
      search,
      resourceType,
      resourceSort,
      resourceFilters,
      availableResources,
    ],
    async ({ pageParam = 1 }) => {
      const filters = search ? { ...resourceFilters, search } : resourceFilters;
      const { results, count } = await vm.getCompanyResources(
        companyId,
        resourceType,
        filters,
        resourceSort,
        pageParam
      );
      setResourcesCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const linkBadgesToResourcesMutation = useMutation(
    async ({
      badgeIds,
      resourceIds,
      selectAllResources,
      selectedResourcesNumber,
      siteResourcesFilters,
      selectAllBadges,
      selectedBadgesNumber,
      availableBadgesFilters,
      automaticLinking,
    }: {
      badgeIds: string[];
      resourceIds: string[];
      selectAllResources: boolean;
      selectedResourcesNumber: number;
      siteResourcesFilters: GetSiteResourcesFilters;
      selectAllBadges: boolean;
      selectedBadgesNumber: number;
      availableBadgesFilters: GetAvailableBadgesFilters;
      automaticLinking: boolean;
    }) => {
      try {
        return await viewModel.linkBadgesToResources(
          companyId,
          badgeIds,
          resourceIds,
          resourceType,
          selectAllResources,
          selectedResourcesNumber,
          siteResourcesFilters,
          selectAllBadges,
          selectedBadgesNumber,
          availableBadgesFilters,
          automaticLinking
        );
      } catch (err) {
        setLinkBadgesToResourcesError(err.message);
      }
    },
    {
      onSuccess: () => {
        getBadgesQuery.refetch();
      },
    }
  );
  const unlinkBadgesFromResourcesMutation = useMutation(
    ({ badgeIds, selectAll }: { badgeIds: string[]; selectAll: boolean }) =>
      viewModel.unlinkBadgesFromResources(companyId, badgeIds, selectAll),
    {
      onSuccess: () => {
        getBadgesQuery.refetch();
      },
    }
  );

  const importLinkResourceBadgesMutation = useMutation(
    (file: FileEntity) =>
      viewModel.importLinkResourcesBadges(companyId, resourceType, file),
    {
      onSuccess: () => {
        getResources.refetch();
        getBadgesQuery.refetch();
      },
      onError: (error: string) => {
        setImportLinkError(error);
      },
    }
  );

  const updateResourcesFilter = (
    field: keyof GetResourcesFilter,
    value: string
  ) => {
    updateFilterWithDelete(setResourceFilters, field, value);
  };
  const updateAvailableBadgesFilter = (
    field: keyof GetAvailableBadgesFilters,
    value: string | string[]
  ) => {
    updateFilterWithDelete(setAvailableBadgesFilters, field, value);
  };

  const badges = getBadgesQuery.data?.pages?.flat() ?? [];
  const availableBadgesQuery =
    getAvailableBadgesQuery.data?.pages?.flat() ?? [];
  const availableResourcesQuery = getResources.data?.pages?.flat() ?? [];

  return {
    badges,
    getBadgesIsLoading: getBadgesQuery.isLoading,
    hasNextPage: getBadgesQuery.hasNextPage,
    fetchNextPage: getBadgesQuery.fetchNextPage,
    deleteBadge: deleteBadgeMutation.mutateAsync,
    deleteBadgeIsLoading: deleteBadgeMutation.isLoading,
    error:
      getBadgesQuery.error?.["message"] ??
      deleteBadgeMutation.error?.["message"] ??
      null,
    clearErrors,
    allTags: getTagsQuery.data,
    createBadge: createBadges,
    createMultipleBadgeQR: createMultipleBadgeQR,
    createBadgeIsLoading: createBadgeMutation.isLoading,
    importBadges: importBadges,
    importBadgesIsLoading: importBadgesMutation.isLoading,
    errorImport,
    setErrorImport,
    tags: getTagsQuery.data,
    filters,
    updateFilter,
    sort,
    setSort,
    errorCreate,
    setErrorCreate,
    ////Multiple Linking
    setAvailableBadges,
    badgesCount,
    resourcesCount,
    availableBadgesFilters,
    updateAvailableBadgesFilter,
    availableBadgesSort,
    setAvailableBadgesSort,
    availableBadgesQuery,
    availableBadgesIsLoading: getAvailableBadgesQuery.isLoading,
    availableBadgesQueryHasNextPage: getAvailableBadgesQuery.hasNextPage,
    availableBadgesQueryFetchNextPage: getAvailableBadgesQuery.fetchNextPage,
    ////
    totalBadgeAvailableCount,
    totalBadgeRegisteredCount,
    resourceType,
    setResourceType,
    search,
    setSearch,
    availableResources,
    setAvailableResources,
    availableResourcesQuery,
    availableResourcesLoading: getResources.isLoading,
    availableResourcesHasNextPage: getResources.hasNextPage,
    availableResourcesFetchNextPage: getResources.fetchNextPage,
    resourceFilters,
    setResourceFilters,
    resourceSort,
    setResourceSort,
    updateResourcesFilter,
    linkBadgesToResources: linkBadgesToResourcesMutation.mutateAsync,
    importLinkResourcesBadges: importLinkResourceBadgesMutation.mutateAsync,
    importLinkError,
    setImportLinkError,
    unlinkBadgesFromResources: unlinkBadgesFromResourcesMutation.mutateAsync,
    linkBadgesToResourcesError,
    setLinkBadgesToResourcesError,
    setSearchBadges,
  };
};

export { useBadgesListViewModel };
