export enum Evaluable {
	COMPANY = 'company',
	WORKER = 'worker',
	VEHICLE = 'vehicle',
	MACHINE = 'machine',
	TOOL = 'tool',
	CHEMICAL = 'chemical',
}

export enum ResourceOptions {
	WORKER = 'worker',
	VEHICLE = 'vehicle',
	MACHINE = 'machine',
	TOOL = 'tool',
	CHEMICAL = 'chemical',
}
