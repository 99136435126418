import {
  Flex,
  FlexProps,
  Icon as ChakraIcon,
  Tooltip,
  forwardRef,
  ComponentWithAs,
  IconProps,
  Badge,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { NavLink } from "react-router-dom";
import { Permission } from "../Permissions/Permissions";
import { MdOutlineNotifications } from "react-icons/md";

const NavItem = forwardRef(
  ({ icon, Icon, path, name, details, ...rest }: NavItemProps, ref) => {
    const NavIcon = Icon ?? ChakraIcon;
    return (
      <NavLink to={path}>
        {({ isActive }) => (
          <Flex
            align="center"
            justifyContent="center"
            width="100%"
            cursor="pointer"
            className={isActive ? "active-link" : ""}
            sx={{
              "&.active-link": {
                color: "#32a2db",
                backgroundColor: "white",
                //backgroundColor: "#32a2db",
              },
            }}
            {...rest}
          >
            {(icon || Icon) && (
              <Flex direction="column" align="center" justifyContent="center">
                <Tooltip label={name} aria-label={name} placement="auto-start">
                  <Flex
                    ref={ref}
                    height="64px"
                    width="64px"
                    align="center"
                    justify={"center"}
                    position={"relative"}
                    _hover={{
                      shadow:
                        "0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                    style={
                      isActive
                        ? {
                            boxShadow:
                              "0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1)", // Apply shadow if isActive is true
                          }
                        : {} // No styles applied if isActive is false
                    }
                  >
                    <NavIcon fontSize="32" as={icon} />
                    {icon === MdOutlineNotifications && details > 0 && (
                      <Badge
                        ml="1"
                        bg={"red"}
                        color={"white"}
                        position={"absolute"}
                        top={4}
                        left={3}
                        borderRadius={13}
                      >
                        {details}
                      </Badge>
                    )}
                  </Flex>
                </Tooltip>
              </Flex>
            )}
          </Flex>
        )}
      </NavLink>
    );
  },
);

export interface NavItemProps extends FlexProps {
  icon?: IconType;
  Icon?: ComponentWithAs<"svg", IconProps>;
  path: string;
  name?: string;
  permissions?: Permission[];
  details?: number;
}

export default NavItem;
