import UseCase from '../../../core/app/usecase';
import Role, { RoleContext } from '../../entities/role';
import RolesRepository from '../../repositories/rolesRepository';

export interface RolesFilter {
	nameDesc?: boolean;
	nameAsc?: boolean;
	contextFilter?: RoleContext;
	nameFilter?: string;
}

interface Args {
	companyId: string;
	filter?: RolesFilter;
}

class GetRoles implements UseCase<null, Array<Role>> {
	constructor(private repository: RolesRepository) {}

	async Call({ companyId, filter }: Args): Promise<Array<Role>> {
		return await this.repository.getRoles(companyId, filter?.nameDesc, filter?.nameAsc, filter?.contextFilter, filter?.nameFilter);
	}
}

export default GetRoles;
