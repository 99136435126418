import { MachineDocumentsViewModel } from "../../../viewmodels/machines/MachineDocumentsViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { useResourceDocumentsViewModel } from "../useResourceDocumentsViewModel";

const useMachineDocumentsViewModel = (resourceId: string) =>
  useResourceDocumentsViewModel(
    new MachineDocumentsViewModel(resourceId),
    DocumentTypeCategory.MACHINE,
    resourceId,
  );

export default useMachineDocumentsViewModel;
