import { useState } from "react";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { DashboardTopic } from "../../../domain/entities/dashboardTopic";
import DashboardViewModel from "../../viewmodels/dashboard/DashboardViewModel";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetSupplierEvaluationsFilter } from "../../../domain/repositories/dashboardRepository";
import { updateFilterWithDelete } from "../../../utils";
import { useHasPermissions } from "../../components/Permissions/RenderIf";
import { Permission } from "../../components/Permissions/Permissions";

const useDashboardViewModel = () => {
  const { companyId } = useAuth();
  const dashboardViewModel = new DashboardViewModel();
  const [unevaluatedDocsSort, setUnevaluatedDocsSort] = useState<SortMeta>();
  const [expiringDocumentsSort, setExpiringDocumentsSort] =
    useState<SortMeta>();
  const [cseAuthorizationsSort, setCseAuthorizationsSort] =
    useState<SortMeta>();
  const [invitedSuppliersSort, setInvitedSupplierSort] = useState<SortMeta>();
  useState<SortMeta>();
  const [companyExpiringDocsSort, setCompanyExpiringDocSort] =
    useState<SortMeta>();
  const [expiringSupplierResourcesSort, setExpiringSupplierResourcesSort] =
    useState<SortMeta>();
  const [expiringSupplierDocumentsSort, setExpiringSupplierDocumentsSort] =
    useState<SortMeta>();
  const [expiringCompanyDocumentsSort, setExpiringCompanyDocumentsSort] =
    useState<SortMeta>();

  const [expiringSupplierResourcesFilter, setExpiringSupplierResourcesFilter] =
    useState<GetSupplierEvaluationsFilter>();
  const [expiringSupplierDocumentsFilter, setExpiringSupplierDocumentsFilter] =
    useState<GetSupplierEvaluationsFilter>();

  const updateSupplierResourcesFilter = (
    field: string,
    value: string | string[] | [Date, Date],
  ) => {
    updateFilterWithDelete(setExpiringSupplierResourcesFilter, field, value);
  };

  const [expiringDocumentsDays, setExpiringDocumentsDays] =
    useState<number>(30);
  const [expiringCompanyDocumentsDays, setExpiringCompanyDocumentsDays] =
    useState<number>(30);
  const [expiringEvaluationsDays, setExpiringEvaluationsDays] =
    useState<number>(30);
  const [
    expiringResourcesEvaluationsDays,
    setExpiringResourcesEvaluationsDays,
  ] = useState<number>(30);
  const [
    expiringCompanyOrSiteEvaluationsDays,
    setExpiringCompanyOrSiteEvaluationsDays,
  ] = useState<number>(30);

  const [enableUnevaluatedDocuments, setEnableUnevaluatedDocuments] = useState<boolean>(false);
  const [enableExpiringDocumentsEvaluations, setEnableExpiringDocumentsEvaluations] = useState<boolean>(false);
  const [enableExpiringDocuments, setEnableExpiringDocuments] = useState<boolean>(false);
  const [enableGetExpiringSiteDocuments, setEnableGetExpiringSiteDocuments] = useState<boolean>(false);
  const [enableExpiringResourceEvaluations, setEnableExpiringResourceEvaluations] = useState<boolean>(false);
  const [enableGetExpiringCompanyOrSiteEvaluations, setEnableGetExpiringCompanyOrSiteEvaluations] = useState<boolean>(false);
  const [enableCseAuthorization, setEnableCseAuthorization] = useState<boolean>(false);
  const [enableInvitedSupplier, setEnableInvitedSupplier] = useState<boolean>(false);

  const updateSupplierDocumentsFilter = (
    field: string,
    value: string | string[] | [Date, Date],
  ) => {
    updateFilterWithDelete(setExpiringSupplierDocumentsFilter, field, value);
  };

  //Counts
  const getOwnedSitesCountQuery = useQuery(
    ["dashboard-count-owned-sites", companyId, "owned-sites"],
    async () => {
      return dashboardViewModel.getDashboardCount(
        companyId,
        "owned-sites" as DashboardTopic,
      );
    },
    {
      enabled: useHasPermissions([Permission.Dashboard_ShowOwnedSitesCount]),
    },
  );
  const getWorkingSitesCountQuery = useQuery(
    ["dashboard-count-working-sites", companyId, "working-sites"],
    async () => {
      return dashboardViewModel.getDashboardCount(
        companyId,
        "working-sites" as DashboardTopic,
      );
    },
    {
      enabled: useHasPermissions([Permission.Dashboard_ShowWorkingSitesCount]),
    },
  );
  
  const getSuppliersCountQuery = useQuery(
    ["dashboard-count-suppliers", companyId, "suppliers"],
    async () => {
      return dashboardViewModel.getDashboardCount(
        companyId,
        "suppliers" as DashboardTopic,
      );
    },
    {
      enabled: useHasPermissions([Permission.Dashboard_ShowSuppliersCount]),
    },
  );

  const getUnevaluatedCountQuery = useQuery(
    ["unevaluated-count", companyId, "unevaluated-documents"],
    async () => {
      return dashboardViewModel.getDashboardChartData(
        companyId,
        "unevaluated-documents" as DashboardTopic,
      );
    },
    {
      enabled: false,
    },
  );
  
  const getExpiringCountQuery = useQuery(
    [
      "expiring-count",
      companyId,
      expiringEvaluationsDays,
      "expiring-evaluations",
    ],
    async () => {
      return dashboardViewModel.getDashboardChartData(
        companyId,
        "expiring-evaluations" as DashboardTopic,
        expiringEvaluationsDays,
      );
    },
    {
      enabled: false
    },
  );

  const getExpiringCompanyDocuments = useInfiniteQuery(
    [
      "expiring-company-documents",
      companyId,
      expiringCompanyDocumentsSort,
      expiringCompanyDocumentsDays,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getDashboardSiteDocuments(
        companyId,
        pageParam,
        expiringCompanyDocumentsSort,
        expiringCompanyDocumentsDays,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 15) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([
        Permission.Dashboard_ShowExpiringCompanyDocuments,
      ]) && enableGetExpiringSiteDocuments,
    },
  );

  // Unevaluated documents
  const getUnevaluatedDocumentsQuery = useInfiniteQuery(
    [
      "unevaluated-documents",
      companyId,
      "unevaluated-documents",
      unevaluatedDocsSort,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getDashboardDocuments(
        companyId,
        "unevaluated-documents" as DashboardTopic,
        pageParam,
        unevaluatedDocsSort,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 15) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([
        Permission.Dashboard_ShowUnevaluatedDocuments,
      ]) && enableUnevaluatedDocuments,

    },
  );

  //Expiring Documents
  const getExpiringDocuments = useInfiniteQuery(
    [
      "expiring-evaluations",
      companyId,
      "expiring-evaluations",
      expiringEvaluationsDays,
      expiringDocumentsSort,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getDashboardDocuments(
        companyId,
        "expiring-evaluations" as DashboardTopic,
        pageParam,
        expiringDocumentsSort,
        expiringEvaluationsDays,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 15) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([
        Permission.Dashboard_ShowExpiringCompanyDocuments,
      ]) && enableExpiringDocumentsEvaluations
    },
  );
  //Authorizations CSE
  const getCseAuthorizations = useInfiniteQuery(
    [
      "pending-evaluations",
      companyId,
      "pending-evaluations",
      cseAuthorizationsSort,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getDashboardSupplierInfo(
        companyId,
        "pending-evaluations" as DashboardTopic,
        pageParam,
        cseAuthorizationsSort,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 15) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([Permission.Dashboard_ShowPendingEvaluations]) && enableCseAuthorization,
    },
  );

  //Invited suppliers
  const getInvitedSuppliers = useInfiniteQuery(
    ["invited-suppliers", companyId, "invited-suppliers", invitedSuppliersSort],
    async ({ pageParam = 1 }) => {
      return await dashboardViewModel.getDashboardSupplierInfo(
        companyId,
        "invited-suppliers" as DashboardTopic,
        pageParam,
        invitedSuppliersSort,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 5) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([Permission.Dashboard_ShowInvitedSuppliers]) && enableInvitedSupplier,
    },
  );

  //CompanyExpiringDocuments
  const getDashboardCompanyExpiringDocuments = useInfiniteQuery(
    [
      "expiring-documents",
      companyId,
      "expiring-documents",
      companyExpiringDocsSort,
      expiringDocumentsDays,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getDashboardCompanyExpiringDocuments(
        companyId,
        "expiring-documents" as DashboardTopic,
        pageParam,
        companyExpiringDocsSort,
        expiringDocumentsDays,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 15) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([Permission.Dashboard_ShowExpiringDocuments]) && enableExpiringDocuments,
    },
  );

  //ExpiringSupplierResources
  const getDasboardExpiringSupplierResources = useInfiniteQuery(
    [
      "expiring-supplier-resources",
      companyId,
      expiringSupplierResourcesSort,
      expiringSupplierResourcesFilter,
      expiringResourcesEvaluationsDays,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getExpiringResourcesEvaluations(
        companyId,
        pageParam,
        expiringSupplierResourcesSort,
        expiringSupplierResourcesFilter,
        expiringResourcesEvaluationsDays,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 5) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([
        Permission.Dashboard_ShowExpiringResourcesEvaluations,
      ]) && enableExpiringResourceEvaluations,
    },
  );

  //ExpiringSupplierDocuments
  const getDasboardExpiringSupplierDocuments = useInfiniteQuery(
    [
      "expiring-supplier-documents",
      companyId,
      expiringSupplierDocumentsSort,
      expiringSupplierDocumentsFilter,
      expiringCompanyOrSiteEvaluationsDays,
    ],
    async ({ pageParam = 1 }) => {

      return await dashboardViewModel.getExpiringCompanyOrSiteEvaluations(
        companyId,
        pageParam,
        expiringSupplierDocumentsSort,
        expiringSupplierDocumentsFilter,
        expiringCompanyOrSiteEvaluationsDays,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 5) {
          return pages.length + 1;
        }
      },
      enabled: useHasPermissions([
        Permission.Dashboard_ShowExpiringCompanyOrSiteEvaluations,
      ]) && enableGetExpiringCompanyOrSiteEvaluations,
    },
  );

  const ownedSitesCount = getOwnedSitesCountQuery.data;
  const workingSitesCount = getWorkingSitesCountQuery.data;
  const suppliersCount = getSuppliersCountQuery.data;
  const unevaluatedDocsCount = getUnevaluatedCountQuery.data;
  const expiringDocsCount = getExpiringCountQuery.data;
  const unevaluatedDocs =
    getUnevaluatedDocumentsQuery.data?.pages?.flat() ?? [];
  const expiringDocuments = getExpiringDocuments.data?.pages?.flat() ?? [];
  const expiringCompanyDocuments =
    getExpiringCompanyDocuments.data?.pages?.flat() ?? [];
  const cseAuthorizations = getCseAuthorizations.data?.pages?.flat() ?? [];
  const invitedSuppliers = getInvitedSuppliers.data?.pages?.flat() ?? [];
  const companyExpiringDocs =
    getDashboardCompanyExpiringDocuments.data?.pages?.flat() ?? [];
  const expiringSupplierResources =
    getDasboardExpiringSupplierResources.data?.pages?.flat() ?? [];
  const expiringSupplierDocuments =
    getDasboardExpiringSupplierDocuments.data?.pages?.flat() ?? [];

  return {
    ownedSitesCount,
    ownedSitesCountFetching: getOwnedSitesCountQuery.isFetching,
    workingSitesCount,
    workingSitesCountFetching: getWorkingSitesCountQuery.isFetching,
    suppliersCount,
    suppliersCountFetching: getSuppliersCountQuery.isFetching,
    unevaluatedDocsCount,
    unevaluatedDocsCountFetching: getUnevaluatedCountQuery.isFetching,
    expiringDocsCount,
    expiringDocsCountFetching: getExpiringCountQuery.isFetching,
    unevaluatedDocs,
    unevaluatedDocumentsFetching: getUnevaluatedDocumentsQuery.isLoading,
    unevaluatedDocsSort,
    setUnevaluatedDocsSort,
    unevaluatedDocsHasNextPage: getUnevaluatedDocumentsQuery.hasNextPage,
    unevaluatedDocsFetchNextPage: getUnevaluatedDocumentsQuery.fetchNextPage,
    expiringDocuments,
    expiringDocumentsFetching: getExpiringDocuments.isLoading,
    expiringDocumentsSort,
    setExpiringDocumentsSort,
    expiringDocumentsHasNextPage: getExpiringDocuments.hasNextPage,
    expiringDocumentsFetchNextPage: getExpiringDocuments.fetchNextPage,
    expiringCompanyDocuments,
    expiringCompanyDocumentsFetching: getExpiringCompanyDocuments.isLoading,
    expiringCompanyDocumentsSort,
    setExpiringCompanyDocumentsSort,
    expiringCompanyDocumentsHasNextPage:
      getExpiringCompanyDocuments.hasNextPage,
    expiringCompanyDocumentsFetchNextPage:
      getExpiringCompanyDocuments.fetchNextPage,
    cseAuthorizations,
    cseAuthorizationsSort,
    setCseAuthorizationsSort,
    cseAuthorizationsFetching: getCseAuthorizations.isLoading,
    cseAuthorizationsHasNextPage: getCseAuthorizations.hasNextPage,
    cseAuthorizationsFetchNextPage: getCseAuthorizations.fetchNextPage,
    invitedSuppliers,
    invitedSuppliersSort,
    setInvitedSupplierSort,
    invitedSuppliersFetching: getInvitedSuppliers.isLoading,
    invitedSuppliersHasNextPage: getInvitedSuppliers.hasNextPage,
    invitedSuppliersFetchNextPage: getInvitedSuppliers.fetchNextPage,

    companyExpiringDocs,
    companyExpiringDocsSort,
    setCompanyExpiringDocSort,
    companyExpiringDocsFetching: getDashboardCompanyExpiringDocuments.isLoading,
    companyExpiringDocsHasNextPage:
      getDashboardCompanyExpiringDocuments.hasNextPage,
    companyExpiringDocsFetchNextPage:
      getDashboardCompanyExpiringDocuments.fetchNextPage,

    expiringSupplierResources,
    expiringSupplierResourcesSort,
    setExpiringSupplierResourcesSort,
    expiringSupplierResourcesFilter,
    updateSupplierResourcesFilter,
    expiringSupplierResourcesFetching:
      getDasboardExpiringSupplierResources.isLoading,
    expiringSupplierResourcesHasNextPage:
      getDasboardExpiringSupplierResources.hasNextPage,
    expiringSupplierResourcesFetchNextPage:
      getDasboardExpiringSupplierResources.fetchNextPage,

    expiringSupplierDocuments,
    expiringSupplierDocumentsSort,
    setExpiringSupplierDocumentsSort,
    expiringSupplierDocumentsFilter,
    updateSupplierDocumentsFilter,
    expiringSupplierDocumentsFetching:
      getDasboardExpiringSupplierDocuments.isLoading,
    expiringSupplierDocumentsHasNextPage:
      getDasboardExpiringSupplierDocuments.hasNextPage,
    expiringSupplierDocumentsFetchNextPage:
      getDasboardExpiringSupplierDocuments.fetchNextPage,

    expiringDocumentsDays,
    setExpiringDocumentsDays,
    expiringCompanyDocumentsDays,
    setExpiringCompanyDocumentsDays,
    expiringEvaluationsDays,
    setExpiringEvaluationsDays,
    expiringResourcesEvaluationsDays,
    setExpiringResourcesEvaluationsDays,
    expiringCompanyOrSiteEvaluationsDays,
    setExpiringCompanyOrSiteEvaluationsDays,

    setEnableUnevaluatedDocuments,
    setEnableExpiringDocumentsEvaluations,
    setEnableExpiringDocuments,
    setEnableGetExpiringSiteDocuments,
    setEnableExpiringResourceEvaluations,
    setEnableGetExpiringCompanyOrSiteEvaluations,
    setEnableCseAuthorization,
    setEnableInvitedSupplier
  };
};

export { useDashboardViewModel };
