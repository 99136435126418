import { SupplierWorker, WorkerParams } from '../../../domain/entities/siteWorker';
import { makeGetCompanySupplierWorker } from '../../../core/app/factory/suppliers/useCases';
import { RequirementSubject } from '../../../domain/entities/requirementSubject.enum';
import SupplierResourceViewModel from './SupplierResourceViewModel';

class SupplierWorkerViewModel extends SupplierResourceViewModel<WorkerParams> {
	constructor(
		public companyId: string,
		public supplierId: string,
		public resourceId: string,
	) {
		super(companyId, supplierId, resourceId, RequirementSubject.WORKER);
	}
	async getSupplierResource(): Promise<SupplierWorker> {
		return await makeGetCompanySupplierWorker().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			resourceId: this.resourceId,
		});
	}
}

export default SupplierWorkerViewModel;
