import UseCase from '../../../core/app/usecase';
import UsersRepository from '../../repositories/usersRepository';

interface Args {
	email: string;
}

class UserEmailAvailable implements UseCase<Args, boolean> {
	constructor(private repository: UsersRepository) {}

	async Call({ email }: Args): Promise<boolean> {
		return await this.repository.userEmailAvailable(email);
	}
}

export default UserEmailAvailable;
