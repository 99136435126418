import { useState } from "react";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { useInfiniteQuery } from "@tanstack/react-query";
import SupplierListViewModel from "../../viewmodels/suppliers/SupplierListViewModel";
import { GetGlobalSupplierFilters } from "../../../domain/repositories/supplierRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";

const useSupplierListModel = () => {
  const { companyId } = useAuth();
  const vm = new SupplierListViewModel();
  const [sort, setSort] = useState<SortMeta>();
  const [search, setSearch] = useState<string>();
  const [filterSuppliers, setFilterSuppliers] =
    useState<GetGlobalSupplierFilters>();

  const updateFilterSuppliers = (
    field: string,
    value: string | string[] | [Date, Date],
  ) => {
    setFilterSuppliers((prev) => ({ ...prev, [field]: value }));
  };

  const getSuppliersQuery = useInfiniteQuery(
    ["company-suppliers", companyId, filterSuppliers, search, sort],
    async ({ pageParam = 1 }) => {
      const filters = search ? { ...filterSuppliers, search } : filterSuppliers;
      return await vm.getSuppliers(companyId, filters, sort, pageParam);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );

  const suppliers = getSuppliersQuery.data?.pages?.flat() ?? [];

  return {
    suppliers,
    isFetching: getSuppliersQuery.isLoading,
    suppliersHasNextPage: getSuppliersQuery.hasNextPage,
    suppliersFetchNextPage: getSuppliersQuery.fetchNextPage,
    sort,
    setSort,
    updateFilterSuppliers,
    filterSuppliers,
    setFilterSuppliers,
    setSearch,
  };
};

export { useSupplierListModel };
