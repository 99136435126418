import UseCase from '../../../core/app/usecase';
import BadgeRepository from '../../repositories/badgeRepository';
import Badge from '../../entities/badge';

type ARGS = {
	companyId: string;
	badge: Partial<Badge>;
	siteId?: string;
};

class UpdateBadge implements UseCase<ARGS, void> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, badge, siteId }: ARGS): Promise<void> {
		return await this.badgeRepository.updateBadge(companyId, badge, siteId);
	}
}

export default UpdateBadge;
