import UseCase from '../../../../core/app/usecase';
import FileEntity from '../../../entities/file';
import ImportInfo from '../../../entities/importInfo';
import WorkerRepository from '../../../repositories/workerRepository';

interface Args {
	companyId: string;
	file: FileEntity;
}

class UploadWorkers implements UseCase<Args, ImportInfo> {
	constructor(private repository: WorkerRepository) {}

	async Call(args: Args): Promise<ImportInfo> {
		return await this.repository.uploadWorkers(args.companyId, args.file);
	}
}

export default UploadWorkers;
