import { Identifiable } from './interfaces/identifiable';

export enum EvaluationMode {
	MANUAL = 'manual',
	AUTO = 'automatic',
}

type Evaluation<T, R extends Identifiable = Identifiable> = {
	result: T;
	createdAt: Date;
	expiresAt?: Date;
	resource?: R;
	evaluator?: string;
	mode: EvaluationMode;
};

export default Evaluation;
