import UseCase from '../../../core/app/usecase';
import { DashboardTopic } from '../../entities/dashboardTopic';
import { DashboardChartData } from '../../entities/dashboardCount';
import DashboardRepository from '../../repositories/dashboardRepository';

interface Args {
	days?: number;
	companyId: string;
	topic: DashboardTopic;
}

class GetDashboardChartData implements UseCase<Args, DashboardChartData> {
	private dashboardRepository: DashboardRepository;

	constructor(dashboardRepository: DashboardRepository) {
		this.dashboardRepository = dashboardRepository;
	}

	async Call({ companyId, topic, days }: Args): Promise<DashboardChartData> {
		return await this.dashboardRepository.getDashboardChartData(companyId, topic, days);
	}
}

export default GetDashboardChartData;
