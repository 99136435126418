import UseCase from '../../../core/app/usecase';
import BadgeRepository from '../../repositories/badgeRepository';

type ARGS = {
	companyId: string;
	siteId: string;
};

class GetAvaialbleBadgesSiteCount implements UseCase<ARGS, number> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, siteId }: ARGS): Promise<number> {
		return await this.badgeRepository.getAvailableBadgesSiteCount(companyId, siteId);
	}
}

export default GetAvaialbleBadgesSiteCount;
