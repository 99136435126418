import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import RequirementsGroup from "../../../domain/entities/requirementsGroup";
import CompanyRequirementsGroupViewModel from "../../viewmodels/company/CompanyRequirementsGroupViewModel";
import { useAuth } from "../../providers/Auth0JWTProvider";
import {
  SortDirection,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";

const useCompanyRequirementsGroupsViewModel = () => {
  const { companyId } = useAuth();
  const { t } = useTranslation();
  const viewModel = useMemo(() => new CompanyRequirementsGroupViewModel(), []);
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<SortMeta>();

  const {
    data: requirementsGroupsRaw,
    error,
    isLoading: requirementGroupIsFetching,
    refetch: refetchRequirementsGroups,
  } = useQuery<RequirementsGroup[], Error>(
    ["requirements-groups", companyId],
    () => viewModel.getRequirementsGroups(companyId),
    {
      retry: false,
    },
  );

  const createMutation = useMutation(
    (requirementsGroup: RequirementsGroup) =>
      viewModel.createRequirementsGroups(companyId, requirementsGroup),
    {
      onError: (err) => {
        console.error(err);
      },
      onSuccess: () => refetchRequirementsGroups(),
    },
  );

  const updateMutation = useMutation(
    (requirementsGroup: RequirementsGroup) =>
      viewModel.updateRequirementsGroup(companyId, requirementsGroup),
    {
      onError: (err) => {
        console.error(err);
      },
      onSuccess: () => refetchRequirementsGroups(),
    },
  );

  const deleteMutation = useMutation(
    (requirementGroupId: string) =>
      viewModel.deleteRequirementGroup(companyId, requirementGroupId),
    {
      onError: (err) => {
        console.error(err);
      },
      onSuccess: () => refetchRequirementsGroups(),
    },
  );

  const createRequirementsGroups = async (
    requirementsGroup: RequirementsGroup,
  ) => {
    try {
      await createMutation.mutateAsync(requirementsGroup);
    } catch (err) {
      console.error(t("cannotUpdateRequirementGroup", { ns: "requirement" }));
    }
  };

  const updateRequirementsGroup = async (
    requirementsGroupToUpdate: RequirementsGroup,
  ) => {
    try {
      await updateMutation.mutateAsync(requirementsGroupToUpdate);
    } catch (err) {
      console.error(t("cannotUpdateRequirementGroup", { ns: "requirement" }));
    }
  };

  const deleteRequirementsGroup = async (requirementGroupId: string) => {
    try {
      await deleteMutation.mutateAsync(requirementGroupId);
    } catch (err) {
      console.error(t("cannotDeleteRequirementGroup", { ns: "requirement" }));
    }
  };

  const requirementsGroups = useMemo(
    () =>
      Array.isArray(requirementsGroupsRaw)
        ? requirementsGroupsRaw
            ?.filter((requirementGroup) =>
              requirementGroup.name
                .toLowerCase()
                .includes(search.toLowerCase()),
            )
            .sort((a, b) => {
              if (sort?.field === "name") {
                return sort.direction === SortDirection.Ascending
                  ? a.name.localeCompare(b.name)
                  : b.name.localeCompare(a.name);
              }
              return 0;
            })
        : [],
    [requirementsGroupsRaw, search, sort],
  );

  const cloneRequirementGroupMutation = useMutation(
    (requirementGroup: RequirementsGroup) =>
      viewModel.cloneRequirementGroup(
        companyId,
        requirementGroup.id,
        requirementGroup.name,
      ),
    {
      onError: (err) => {
        console.error(err);
      },
      onSuccess: () => refetchRequirementsGroups(),
    },
  );

  const cloneRequirementGroup = async (requirementGroup: RequirementsGroup) => {
    await cloneRequirementGroupMutation.mutateAsync(requirementGroup),
      {
        onError: (err) => {
          console.error(err);
        },
        onSuccess: () => refetchRequirementsGroups(),
      };
  };

  return {
    error,
    requirementsGroups: requirementsGroups,
    requirementGroupIsFetching,
    refetchRequirementsGroups,
    createRequirementsGroups,
    updateRequirementsGroup,
    deleteRequirementsGroup,
    search,
    setSearch,
    sort,
    setSort,
    cloneRequirementGroup,
  };
};

export { useCompanyRequirementsGroupsViewModel };
