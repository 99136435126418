import { Flex } from "@chakra-ui/react";
import Tag from "../../../../domain/entities/tag";
import TagBadge from "../TagBadge";
import TagMoreButton from "../TagMoreButton";

type TagListFieldProps = {
  tags: Tag[];
  editable?: boolean;
  onUpdate?: (tag: Tag) => void;
};

const TagListField = ({ tags, editable, onUpdate }: TagListFieldProps) => (
  <Flex wrap="wrap" gap={2}>
    {tags
      ?.slice(0, 2)
      .map((tag: Tag) => (
        <TagBadge
          tag={tag}
          key={tag.id}
          editable={editable}
          onUpdate={onUpdate}
        />
      ))}
    {Array.isArray(tags) && tags.length > 2 && (
      <TagMoreButton tags={tags} editable={editable} onUpdate={onUpdate} />
    )}
  </Flex>
);

export default TagListField;
