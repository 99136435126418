import React, { useEffect, useState } from "react";
import {
  Box,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
  Input,
} from "@chakra-ui/react";
import "./tagInput.module.css";
import { useTranslation } from "react-i18next";

interface TagEmailInputProps {
  scheduleEmails: string[];
  onChange: (value: string[]) => void;
}

const TagEmailInput = ({ scheduleEmails, onChange }: TagEmailInputProps) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [emails, setEmails] = useState<string[]>([]);

  useEffect(() => {
    if (scheduleEmails) {
      setEmails(scheduleEmails);
    }
  }, [scheduleEmails]);

  const addEmails = () => {
    const newEmails = inputValue
      .split(/[\s,;\n]+/)
      .filter((email) => email && validateEmail(email));
    if (newEmails.length > 0) {
      const updatedEmails = [...emails, ...newEmails];
      setEmails(updatedEmails);
      onChange(updatedEmails);
      setInputValue("");
    }
  };

  const removeEmail = (emailToRemove: string) => {
    const updatedEmails = emails.filter((email) => email !== emailToRemove);
    setEmails(updatedEmails);
    onChange(updatedEmails);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addEmails();
    }
  };

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <Box width="100%">
      <Wrap mb={2} mt={2}>
        {emails.map((email) => (
          <WrapItem key={email}>
            <Tag colorScheme="blue">
              <TagLabel>{email}</TagLabel>
              <TagCloseButton onClick={() => removeEmail(email)} />
            </Tag>
          </WrapItem>
        ))}
      </Wrap>
      <Input
        placeholder={t("enterEmail", { ns: "common" })}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={addEmails}
      />
    </Box>
  );
};

export default TagEmailInput;
