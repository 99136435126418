import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';
import SiteOptions, { ScheduleEmail } from '../../entities/siteOptions';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import User from '../../entities/user';
import { GetUsersSiteFilter } from '../../repositories/filters';

interface Args {
  companyId: string;
  siteId: string;
  pageParam: number;
  filter: GetUsersSiteFilter
  sort: SortMeta
}

class GetSiteAvailableUsers implements UseCase<Args, User[]> {
  private siteRepository: SiteRepository;

  constructor(siteRepository: SiteRepository) {
    this.siteRepository = siteRepository;
  }

  async Call({ companyId, siteId, pageParam, filter, sort }: Args): Promise<User[]> {
    return await this.siteRepository.getSiteAvailableUsers(companyId, siteId, pageParam, filter, sort);
  }
}

export default GetSiteAvailableUsers;
