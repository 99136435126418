import { makeInitiateFileDownload } from "../../../domain/interactors/documentDownloader/initiateFileDownload";
import { makeInitiateFilesArchiveDownload } from "../../../domain/interactors/documentDownloader/initiateFilesArchiveDownload";

import {
  RequestDocumentFilesArchive,
  RequestDocumentFile,
  RequestResourceFilesArchive,
  RequestResourceFile,
  RequestSupplierOrSiteFilesArchive,
  RequestSupplierOrSiteFile,
  RequestDocumentsFiles,
} from "../../../domain/interactors/documentDownloader/common";

import {
  DownloadFileScope,
  DownloadResourceFileScope,
} from "../../../domain/repositories/documentsDownloader";

class RequestFilesDownloadViewModel {
  protected getFileUC = makeInitiateFileDownload();
  protected getZipUC = makeInitiateFilesArchiveDownload();

  constructor(protected companyId: string) {}
}

export class RequestDocumentFilesViewModel extends RequestFilesDownloadViewModel {
  async initiateFilesArchiveDownload(
    documentId: string,
    renameModel: string,
  ): Promise<boolean> {
    return this.getZipUC.Call(
      new RequestDocumentFilesArchive(this.companyId, documentId, renameModel),
    );
  }

  async initiateFileDownload(
    documentId: string,
    fileId: string,
    renameModel: string,
  ): Promise<string> {
    return this.getFileUC.Call(
      new RequestDocumentFile(this.companyId, documentId, fileId, renameModel),
    );
  }
}

export class RequestSupplierOrSiteFilesViewModel extends RequestFilesDownloadViewModel {
  constructor(
    companyId: string,
    private siteId: string,
    private scope: DownloadFileScope,
  ) {
    super(companyId);
  }

  async initiateFilesArchiveDownload(
    requirementId: string,
    renameModel: string,
  ): Promise<boolean> {
    return this.getZipUC.Call(
      new RequestSupplierOrSiteFilesArchive(
        this.siteId,
        this.companyId,
        requirementId,
        this.scope,
        renameModel,
      ),
    );
  }

  async initiateFileDownload(
    requirementId: string,
    fileId: string,
    renameModel: string,
  ): Promise<string> {
    return this.getFileUC.Call(
      new RequestSupplierOrSiteFile(
        fileId,
        this.siteId,
        this.companyId,
        requirementId,
        this.scope,
        renameModel,
      ),
    );
  }
}

export class RequestResourceFilesViewModel extends RequestFilesDownloadViewModel {
  constructor(
    companyId: string,
    private siteId: string,
    private resourceId: string,
    private scope: DownloadResourceFileScope,
  ) {
    super(companyId);
  }

  async initiateFilesArchiveDownload(
    requirementId: string,
    renameModel: string,
  ): Promise<boolean> {
    return this.getZipUC.Call(
      new RequestResourceFilesArchive(
        this.siteId,
        this.companyId,
        this.resourceId,
        requirementId,
        this.scope,
        renameModel,
      ),
    );
  }

  async initiateFileDownload(
    requirementId: string,
    fileId: string,
    renameModel: string,
  ): Promise<string> {
    return this.getFileUC.Call(
      new RequestResourceFile(
        fileId,
        this.siteId,
        this.companyId,
        this.resourceId,
        requirementId,
        this.scope,
        renameModel,
      ),
    );
  }
}

export class RequestDocumentsFilesViewModel extends RequestFilesDownloadViewModel {
  constructor(
    companyId: string,
    private siteId: string,
    private resourceId: string,
    private scope: DownloadResourceFileScope | DownloadFileScope,
    private renameModel: string,
  ) {
    super(companyId);
  }

  async initiateDocumentsFilesDownload(): Promise<boolean> {
    return this.getZipUC.Call(
      new RequestDocumentsFiles(
        this.companyId,
        this.siteId,
        this.resourceId,
        this.scope,
        this.renameModel,
      ),
    );
  }
}
