import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { ResourceDocumentEvaluationState } from '../../entities/resourceDocumentEvaluationState.enum';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import { GetSitesFilter } from "../../repositories/siteRepository";

type Parameters = {
	companyId: string;
	supplierId: string;
	resourceId: string;
	type: RequirementSubject;
	requirementId: string;
	result: ResourceDocumentEvaluationState;
	expiresAt?: Date;
	siteIds: string[];
	selectAll?: boolean;
	filters?: GetSitesFilter
};

class EvaluateCompanySupplierRequirement implements UseCase<Parameters, void> {
	constructor(private repository: SupplierRepository) {}

	async Call({ companyId, supplierId, resourceId, type, requirementId, result, expiresAt, siteIds, selectAll, filters }: Parameters): Promise<void> {
		await this.repository.evaluateCompanySupplierResourceRequirement(
			companyId,
			supplierId,
			resourceId,
			type,
			requirementId,
			result,
			expiresAt,
			siteIds,
			selectAll,
			filters
		);
	}
}

export default EvaluateCompanySupplierRequirement;