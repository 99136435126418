import Tool from '../../../entities/tool';
import UseCase from '../../../../core/app/usecase';
import ToolRepository from '../../../repositories/toolRepository';
import CreateToolParameters from './createToolParameter';

class CreateTool implements UseCase<CreateToolParameters, Tool> {
	constructor(private repository: ToolRepository) {}

	async Call({ companyId, tool, photo }: CreateToolParameters): Promise<Tool> {
		return await this.repository.createTool(companyId, tool, photo);
	}
}

export default CreateTool;
