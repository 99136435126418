import { FC } from "react";
import { BiBullseye } from "react-icons/bi";
import { Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import Site, { SiteState } from "../../../domain/entities/site";

interface Props {
  site: Site;
  stateVisible: boolean;
}

const siteStateColor = (state?: SiteState): string => {
  switch (state) {
    case SiteState.ACTIVE:
      return "#00DD00";
    case SiteState.CLOSED:
      return "#FF0000";
    default:
      return "black";
  }
};

const SiteTopBarName: FC<Props> = ({ site, stateVisible }) => {
  return (
    <Flex cursor="pointer" align="center">
      <Tooltip label={site?.name}>
        <Flex direction="column" alignItems="start">
          <Text
            align="start"
            fontSize="small"
            fontWeight="semibold"
            noOfLines={1}
          >
            {site?.name}
          </Text>
          <Text fontSize="smaller">{site?.protocol}</Text>
        </Flex>
      </Tooltip>

      {stateVisible && (
        <Flex marginLeft={2}>
          <Icon
            as={BiBullseye}
            fontSize="xl"
            color={siteStateColor(site?.state)}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default SiteTopBarName;
