import UseCase from '../../../core/app/usecase';
import Document from '../../entities/document';
import SiteRepository from '../../repositories/siteRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { GetDocumentsFilter } from '../../repositories/documentRepository';

type Args = {
	siteId: string;
	companyId: string;
	filter?: GetDocumentsFilter;
	sort?: SortMeta;
	pageParam?: number;
};

class GetSiteDocuments implements UseCase<Args, Document[]> {
	constructor(private repository: SiteRepository) {}

	async Call({ companyId, siteId, filter, sort, pageParam }: Args): Promise<Document[]> {
		return await this.repository.getDocuments(companyId, siteId, filter, sort, pageParam);
	}
}

export default GetSiteDocuments;
