import { useParams } from "react-router-dom";
import SupplierResourceView from "./SupplierResourceView";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SupplierChemicalViewModel from "../../viewmodels/suppliers/SupplierChemicalViewModel";
import { SupplierDocumentsProps } from "../../components/Views/common/SupplierDocumentsTable";

const SupplierChemicalDetailView = ({
  permissions,
}: {
  permissions: SupplierDocumentsProps["permissions"];
}) => {
  const { companyId } = useAuth();
  const { supplierId, resourceId } = useParams();

  const resourceColumns = ["name"];

  return (
    <SupplierResourceView
      viewModel={
        new SupplierChemicalViewModel(companyId, supplierId, resourceId)
      }
      columns={resourceColumns}
      permissions={permissions}
    />
  );
};

export default SupplierChemicalDetailView;
