import UseCase from '../../../core/app/usecase';
import { WebHookFilters } from '../../../presentation/hooks/WebHook/useWebHookViewModel';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { WebHook } from '../../entities/webHook';
import WebHookRepository from '../../repositories/webHookRepository';

type ARGS = {
	companyId: string;
};

class GetWebHooks implements UseCase<ARGS, WebHook[]> {
	private webHookRepository: WebHookRepository;

	constructor(webHookRepository: WebHookRepository) {
		this.webHookRepository = webHookRepository;
	}

	async Call({ companyId }: ARGS): Promise<WebHook[]> {
		return await this.webHookRepository.get(companyId);
	}
}

export default GetWebHooks;
