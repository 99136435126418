import SiteOptions from "../../../domain/entities/siteOptions";
import {
  makeGetSite,
  makeGetSiteOptions,
} from "../../../core/app/factory/sites/usecases";
import Site from "../../../domain/entities/site";

class SiteMainViewModel {
  constructor(
    private readonly companyId: string,
    private readonly siteId: string,
  ) {}
  async get(): Promise<Site> {
    return await makeGetSite().Call({
      companyId: this.companyId,
      siteId: this.siteId,
    });
  }
  async getSiteOptions(): Promise<SiteOptions> {
    return await makeGetSiteOptions().Call({
      companyId: this.companyId,
      siteId: this.siteId,
    });
  }
}

export default SiteMainViewModel;
