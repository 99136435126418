import { Evaluable } from "../../../domain/entities/evaluable.enum";
import {
  makeApprovePendingEvaluation,
  makeGetPendingEvaluationFiles,
  makeGetPendingEvaluations,
} from "../../../core/app/factory/sites/usecases";
import { PendingEvaluationsFilters } from "../../hooks/Site/useSiteApproveEvaluationsViewModel";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { RenameModel } from "../../../domain/entities/documentType";
import { makeGetRenameModels } from "../../../core/app/factory/documentTypes/usecases";
import { DocumentInfoViewModel } from "../../hooks/Document/useDocumentInfo";

class SiteApproveEvaluationsViewModel {
  constructor(
    private readonly companyId: string,
    private readonly siteId: string,
    private readonly type: Evaluable,
  ) {}

  async get(
    filters: PendingEvaluationsFilters,
    sort: SortMeta,
    pageParam: number,
  ) {
    return await makeGetPendingEvaluations().Call({
      companyId: this.companyId,
      siteId: this.siteId,
      type: this.type,
      filters,
      sort,
      pageParam,
    });
  }

  async getPendingEvaluationFiles(documentId: string) {
  return await makeGetPendingEvaluationFiles().Call({
    companyId: this.companyId,
    documentId
  })}

  async getRenameModel(
    companyId: string,
    documentTypeId?: string,
  ): Promise<RenameModel> {
    return await makeGetRenameModels().Call({
      companyId,
      documentTypeId,
    });
  }

  async approve(resource: string, evaluationIds: string[], isApproved: boolean, selectAllEvaluations?: boolean, filter?: PendingEvaluationsFilters) {
    await makeApprovePendingEvaluation().Call({
      companyId: this.companyId,
      siteId: this.siteId,
      resource,
      evaluationIds,
      isApproved,
      type: this.type,
      selectAllEvaluations,
      filter
    });
  }
}

export default SiteApproveEvaluationsViewModel;
