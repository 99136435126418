import UseCase from '../../../core/app/usecase';
import { BadgeResourceType } from '../../entities/badgeResourceType.enum';
import BadgeRepository from '../../repositories/badgeRepository';

type ARGS = {
	companyId: string;
	badgeId: string;
	resourceType: BadgeResourceType;
	resourceId: string;
};

class LinkBadgeResource implements UseCase<ARGS, void> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, badgeId, resourceType, resourceId }: ARGS): Promise<void> {
		return await this.badgeRepository.linkBadgeResource(companyId, badgeId, resourceType, resourceId);
	}
}

export default LinkBadgeResource;
