import UseCase from '../../../../core/app/usecase';
import SiteVehicleRepository from '../../../repositories/siteVehicleRepository';
import { SiteResourceDocument } from '../../../entities/document';
import { SortMeta } from '../../../entities/interfaces/paginatedResults';
import { GetWorkingSiteDocumentsFilter } from '../../../repositories/filters';

type Parameters = {
	companyId: string;
	siteId: string;
	vehicleId: string;
	filter: GetWorkingSiteDocumentsFilter;
	sort: SortMeta;
	pageParam: number;
};

class GetSiteVehicleDocuments implements UseCase<Parameters, SiteResourceDocument[] | undefined> {
	constructor(private repository: SiteVehicleRepository) {}

	async Call({ companyId, siteId, vehicleId, filter, sort, pageParam }: Parameters): Promise<SiteResourceDocument[] | undefined> {
		return this.repository.getSiteVehicleDocuments(companyId, siteId, vehicleId, filter, sort, pageParam);
	}
}

export default GetSiteVehicleDocuments;
