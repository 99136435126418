import { GiCrane } from "react-icons/gi";
import { useParams } from "react-router-dom";
import { FaRegFileAlt } from "react-icons/fa";
import SubNavBar from "../../Common/SubNavBar";
import { useTranslation } from "react-i18next";
import { GrDocumentUser } from "react-icons/gr";
import { NavItemProps } from "../../Common/NavItem";
import { getSectionPermissions, Permission } from "../../Permissions/Permissions";
import { PermissionCheck, useAuth } from "../../../providers/Auth0JWTProvider";
import { useHasPermissions } from "../../Permissions/RenderIf";

const SiteDocumentsSubNav = () => {
  const { siteId, topic } = useParams();
  const { t } = useTranslation();
  const { companyId } = useAuth();
  const getPath = (type: string) =>
    `/sites/${siteId}/documents/siteRequirements/${type}`;

  const hasCsePermission = useHasPermissions(Permission.Sites_CseShowSites, PermissionCheck.All);

  let items: NavItemProps[];

  if (hasCsePermission) {
    items = [
      {
        icon: GiCrane,
        path: `/sites/${siteId}/documents/general`,
        name: t("generalDocuments")
      },
      {
        icon: GrDocumentUser,
        path: `/sites/${siteId}/documents/supplier/${companyId}`,
        name: t("siteDocuments")
      }
    ];
  }else{
    items = [
      {
        icon: GiCrane,
        path: `/sites/${siteId}/documents/general`,
        name: t("generalDocuments"),
        permissions: getSectionPermissions("sites", "site-documents"),
      },
      {
        icon: GrDocumentUser,
        path: `/sites/${siteId}/documents/supplier/${companyId}`,
        name: t("siteDocuments"),
        permissions: getSectionPermissions("sites", "eval-owner"),
      },
      {
        icon: FaRegFileAlt,
        path: `${getPath(topic ?? "resource")}`,
        name: t("evaluationOverview", { ns: "documents" }),
        permissions: getSectionPermissions("sites", "overview"),
      },
    ];
  }

  return <SubNavBar items={items} />;
};

export default SiteDocumentsSubNav;
