import UseCase from "../../../core/app/usecase";
import { BadgeReadersType } from "../../../presentation/hooks/Site/useSiteBadgeReadersViewModel";
import BadgeReader from "../../entities/badgeReader";
import { PaginatedMeta, PaginatedResults, SortMeta } from "../../entities/interfaces/paginatedResults";
import Site from "../../entities/site";
import BadgeReaderRepository, { BadgeReadersFilters } from "../../repositories/badgeReaderRepository";
import { GetSitesFilter } from "../../repositories/siteRepository";

type Args = {
    companyId: string;
    badgeReaderId: string;
  } & PaginatedMeta  & {
    filter?: GetSitesFilter;
    sort?: SortMeta;
  }
  
  class GetBadgeReadersSites implements UseCase<Args, PaginatedResults<Site>> {
    constructor(private badgeReaderRepository: BadgeReaderRepository) { }
    async Call({ companyId, badgeReaderId, page, perPage, filter, sort }: Args): Promise<PaginatedResults<Site>> {
      return await this.badgeReaderRepository.getBadgeReadersSites(companyId, badgeReaderId, page, perPage, filter, sort);
    };
  }
  
  export default GetBadgeReadersSites;