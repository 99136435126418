import UseCase from '../../../core/app/usecase';
import { UserNotification } from '../../entities/interfaces/userNotification';
import NotificationsRepository, { makeNotificationsRepository } from '../../repositories/notificationsRepository';

interface Args {
	companyId: string;
	userId: string;
	notifications: UserNotification[];
}

/**
 * Update the notification.
 */
class UpdateUserNotificationUseCase implements UseCase<Args, void> {
	constructor(private repository: NotificationsRepository) {}

	async Call(args: Args): Promise<void> {
		const { companyId, userId, notifications } = args;
		return await this.repository.updateUserNotification(companyId, userId, notifications);
	}
}

export const makeUpdateUserNotificationUseCase = (): UpdateUserNotificationUseCase => {
	return new UpdateUserNotificationUseCase(makeNotificationsRepository());
};
