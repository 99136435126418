import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteVehicleParams } from "../../../domain/entities/siteVehicle";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import useWorkingSiteVehicleDetailViewModel from "../../hooks/WorkingSite/useWorkingSiteVehicleDetailViewModel";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const WorkingSiteVehicleDetailView = () => {
  const { siteId, vehicleId } = useParams();
  const resourceColumns = ["name", "plate", "model"];

  const downloader = useResourceFilesDownloader(siteId, vehicleId, "vehicles");

  return (
    <SiteResourceDetailView<SiteVehicleParams>
      downloader={downloader}
      resourceId={vehicleId}
      type={DocumentTypeCategory.VEHICLE}
      useViewModel={useWorkingSiteVehicleDetailViewModel(
        siteId,
        vehicleId,
        resourceColumns,
        DocumentTypeCategory.VEHICLE,
        AiTaskSectionCode.WORKING_SITE_RESOURCES_VEHICLES
      )}
      resourceColumns={resourceColumns}
      isWorkingSite={true}
      isReadOnly={true}
      permissions={{
        downloadDocuments: Permission.Worksite_DownloadVehicleDoc,
        showGlobalEvaluation: Permission.Worksite_ShowVehicleGlobalEval,
        setGlobalEvaluation: Permission.Worksite_Show,
        addRequirement: Permission.Worksite_NewVehicleReq,
        viewRequirements: Permission.Worksite_EditChemicalReq,
        editTags: Permission.Worksite_EditVehicleReq,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        documents: {
          delete: Permission.Worksite_Vehicle_DeleteFile,
          edit: Permission.Worksite_Vehicle_EditFile,
          evaluate: Permission.Worksite_EvalVehicleReq,
          viewEvaluations: Permission.Worksite_EvalVehicleReq,
          downloadAll: Permission.Worksite_DownloadAllFile,
          addFile: Permission.Worksite_EditVehicleReq,
          addNewFile: Permission.Worksite_Vehicle_AddFile,
          chat: Permission.Worksite_VehicleChat,
          report: Permission.Worksite_Reports,
          setExpiration: Permission.Worksite_SetExpiration,
        },
      }}
    />
  );
};

export default WorkingSiteVehicleDetailView;
