import UseCase from '../../../../core/app/usecase';
import DocumentTypeWithPublic from '../../../entities/documentTypeWithPublic';
import ToolRepository from '../../../repositories/toolRepository';

type CreatePropagableToolDocumentParameters = {
	companyId: string;
	resourceId: string;
	documents: DocumentTypeWithPublic[];
};

/**
 * Returns the list of all the existing Document.
 */
class CreatePropagableToolDocument implements UseCase<CreatePropagableToolDocumentParameters, void> {
	constructor(private repository: ToolRepository) {}

	async Call({ companyId, resourceId, documents }: CreatePropagableToolDocumentParameters): Promise<void> {
		return await this.repository.createPropagableToolDocument(companyId, resourceId, documents);
	}
}

export default CreatePropagableToolDocument;
