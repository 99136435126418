import UseCase from '../../../core/app/usecase';
import DocumentPreset from '../../entities/documentPreset';
import { DocumentPresetTarget } from '../../entities/documentPresetTarget.enum';
import DocumentsPresetsRepository from '../../repositories/documentsPresetsRepository';

interface Args {
	companyId: string;
	target: DocumentPresetTarget;
	typologyId: string;
}

class GetDocumentPresetsByTypology implements UseCase<Args, Array<DocumentPreset>> {
	constructor(private repository: DocumentsPresetsRepository) {}

	async Call(args: Args): Promise<Array<DocumentPreset>> {
		return await this.repository.getDocumentPresetsByTypology(args.companyId, args.target, args.typologyId);
	}
}

export default GetDocumentPresetsByTypology;
