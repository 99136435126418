import UseCase from '../../../core/app/usecase';
import { PaginatedMeta, PaginatedResults, SortMeta } from '../../entities/interfaces/paginatedResults';
import { UserMini } from '../../entities/user';
import BadgeReaderRepository, { VirtualBadgeReaderUsersFilters } from '../../repositories/badgeReaderRepository';

type Args = {
	siteId: string;
	companyId: string;
} & PaginatedMeta & {
		filter?: VirtualBadgeReaderUsersFilters;
		sort?: SortMeta;
	};

class GetVirtualBadgeReaderAvailableUsers implements UseCase<Args, PaginatedResults<UserMini>> {
	constructor(private badgeReaderRepository: BadgeReaderRepository) {}

	async Call({ companyId, siteId, page, perPage, filter, sort }: Args): Promise<PaginatedResults<UserMini>> {
		return await this.badgeReaderRepository.getVirtualBadgeReaderAvailableUsers(companyId, siteId, page, perPage, filter, sort);
	}
}

export default GetVirtualBadgeReaderAvailableUsers;
