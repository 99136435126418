import { useFormContext } from "react-hook-form";
import { FormValues } from "./WebHookContainer";
import { useTranslation } from "react-i18next";
import { MultiValue, components, OptionProps } from "react-select";
import React from "react";
import SelectField from "./SelectField";
import { Checkbox, Flex } from "@chakra-ui/react";
import { TFunction } from "i18next";

interface Option {
  value: string;
  label: string;
}

interface GroupedOption {
  label: string;
  options: Option[];
}

interface SelectFieldProps {
  index: number;
  options: Option[];
}

const EventSelectField = ({ index, options }: SelectFieldProps) => {
  const name = "events";
  const { t } = useTranslation("webhooks");
  const { clearErrors, setValue, watch } = useFormContext<FormValues>();
  const fieldName = `webHooks.${index}.${name}` as const;

  const handleChange =
    (onChange: (options: string[]) => void) =>
    (options: MultiValue<Option>) => {
      clearErrors(fieldName);
      const values = options.map((option) => option.value);
      onChange(values);
      if (!values.some((value) => value.startsWith("site."))) {
        setValue(`webHooks.${index}.siteIds`, []);
      }
    };

  const groupedOptions = groupOptions(options, t);

  const getValue = (value: string[]) =>
    value.map((value) => ({ value, label: value }));

  const selectedValues = watch(`webHooks.${index}.events`) as string[];

  const GroupHeading = (props: any) => {
    const { label, options } = props.data;
    const allSelected = options.every((option: Option) =>
      selectedValues.includes(option.value),
    );

    const handleSelectAll = () => {
      const newValues = allSelected
        ? selectedValues.filter(
            (value) =>
              !options.map((option: Option) => option.value).includes(value),
          )
        : Array.from(
            new Set([
              ...selectedValues,
              ...options.map((option: Option) => option.value),
            ]),
          );
      setValue(`webHooks.${index}.events`, newValues);
      props.selectProps.onChange(
        newValues.map((value) => ({ value, label: value })),
      );
    };

    return (
      <components.GroupHeading {...props} onClick={handleSelectAll}>
        <Flex
          sx={{
            padding: "10px 12px",
            zIndex: "-1",
            backgroundColor: "rgba(0,95,250,0.1)",
            color: "rgb(26, 32, 44)",
            fontWeight: 500,
            textTransform: "none",
            ":hover": {
              backgroundColor: "rgba(0,95,250,0.25)",
            },
          }}
        >
          <Checkbox
            isChecked={allSelected}
            isIndeterminate={
              !allSelected &&
              selectedValues.some((value) =>
                options.map((option: Option) => option.value).includes(value),
              )
            }
            onChange={() => null}
            style={{ marginRight: "10px" }}
          />
          {label}
        </Flex>
      </components.GroupHeading>
    );
  };

  return (
    <SelectField
      name={name}
      options={groupedOptions}
      index={index}
      handleChange={handleChange}
      rules={{
        required: {
          value: true,
          message: t(`errors.${name}Required`),
        },
      }}
      components={{ Option, GroupHeading }}
      getValue={getValue}
    />
  );
};

const Option = (props: OptionProps<Option>) => (
  <components.Option {...props} isSelected={false} isFocused={false}>
    <Flex sx={{ marginLeft: "12px" }}>
      <Checkbox
        isChecked={props.isSelected}
        onChange={() => null}
        style={{ display: "block", marginRight: "10px", zIndex: "-1" }}
      />
      <label style={{ fontSize: "12px" }}>{props.label}</label>
    </Flex>
  </components.Option>
);

export const groupOptions = (
  options: Option[],
  t: TFunction,
): GroupedOption[] => {
  const grouped: { [key: string]: { [key: string]: Option[] } } = {};

  options.forEach((option) => {
    const [section, resource] = option.value.split(":");
    const resourceSingular = resource.endsWith("s")
      ? resource.slice(0, -1)
      : resource;
    if (!grouped[section]) grouped[section] = {};
    if (!grouped[section][resourceSingular])
      grouped[section][resourceSingular] = [];
    grouped[section][resourceSingular].push(option);
  });

  return Object.keys(grouped).flatMap((section) =>
    Object.keys(grouped[section]).map((resource) => ({
      label: t(`eventGroups.${section}.${resource}`),
      options: grouped[section][resource],
    })),
  );
};

export default EventSelectField;
