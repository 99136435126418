import { RequirementsViewModel } from "../requirements/RequirementsViewModel";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import {
  makeAddSpecializationToSiteRequirement,
  makeAddVariantToSiteRequirement,
  makeGetDocumentTypes,
  makeGetRequirementsByVariant,
  makeGetSiteRequirementsByResource,
  makeRemoveSpecializationFromSiteRequirement,
  makeRemoveVariantFromSiteRequirement,
  makeUpdateSiteRequirement,
  makeUpdateSiteRequirementsOrder,
} from "../../../core/app/factory/requirements/useCases";
import Requirement from "../../../domain/entities/requirement";
import {
  makeAddRequirementToSite,
  makeRemoveRequirementFromSite,
} from "../../../core/app/factory/sites/usecases";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import DocumentType from "../../../domain/entities/documentType";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import Tag from "../../../domain/entities/tag";
import { DocumentTypeAdd } from "../../../domain/repositories/siteRepository";
import { makeGetDocumentTypesForAi } from "../../../core/app/factory/documentTypes/usecases";
class SiteRequirementsViewModel extends RequirementsViewModel {
  constructor(private target: RequirementSubject) {
    super();
  }
  async updateRequirement(
    companyId: string,
    requirement: Requirement,
    siteId?: string,
  ): Promise<void> {
    return await makeUpdateSiteRequirement().Call({
      companyId,
      requirement,
      siteId,
    });
  }

  async getGroupRequirementsByResource(companyId: string, siteId: string) {

    if (!this.target) {
      return [];
    }

    return await makeGetSiteRequirementsByResource().Call({
      companyId,
      siteId,
      requirementSubject: this.target,
    });
  }

  addRequirementToGroup = undefined;

  async getRequirementsByVariant(
    companyId: string,
    siteId: string,
    variantId: string,
  ) {
    return await makeGetRequirementsByVariant().Call({
      companyId,
      siteId,
      variantId,
      requirementSubject: this.target,
    });
  }

  async addRequirementToSite(
    companyId: string,
    siteId: string,
    documents: DocumentTypeAdd[],
    requirementSubject: RequirementSubject,
  ): Promise<Requirement> {
    return await makeAddRequirementToSite().Call({
      companyId,
      siteId,
      requirementSubject,
      documents,
    });
  }

  removeRequirementFromGroup = undefined;

  async removeRequirementFromSite(
    companyId: string,
    siteId: string,
    requirementId: string,
  ): Promise<void> {
    return await makeRemoveRequirementFromSite().Call({
      companyId,
      siteId,
      requirementId,
      subject: this.target,
    });
  }

  async getDocumentTypes(
    companyId: string,
    search?: string,
    tags?: string[],
    sort?: SortMeta,
    pageParam?: number,
    resourceId?: string,
    systemOnly?: boolean,
    category?: DocumentTypeCategory
  ): Promise<DocumentType[]> {
    // this guard is to prevent a useless call with SUPPLIER category
    if (category === DocumentTypeCategory.SUPPLIER || !category) {
      return;
    }
    return await makeGetDocumentTypes().Call({
      companyId,
      filter: category,
      includePublic: true,
      search,
      tags,
      sort,
      pageParam,
    });
  }

  async getDocumentTypesForAi(companyId: string, aiTaskId: string, resourceType: DocumentTypeCategory): Promise<DocumentType[]> {
    return await makeGetDocumentTypesForAi().Call({
      companyId,
      aiTaskId,
      resourceType
    });
  }

  async updateRequirementsOrder(
    companyId: string,
    requirements: Requirement[],
    siteId?: string,
  ): Promise<void> {
    return await makeUpdateSiteRequirementsOrder().Call({
      companyId,
      requirements,
      siteId,
    });
  }

  async addVariantToRequirement(
    companyId: string,
    requirementId: string,
    variant: Tag,
    siteId: string,
    resourceType: DocumentTypeCategory,
  ): Promise<void> {
    return await makeAddVariantToSiteRequirement().Call({
      companyId,
      requirementId,
      variant,
      siteId,
      resourceType,
    });
  }

  async removeVariantFromRequirement(
    companyId: string,
    requirementId: string,
    variantId: string,
    siteId: string,
  ): Promise<void> {
    return await makeRemoveVariantFromSiteRequirement().Call({
      companyId,
      requirementId,
      variantId,
      siteId,
    });
  }

  async addSpecializationToRequirement(
    companyId: string,
    requirementId: string,
    specialization: Tag,
    siteId: string,
    resourceType: DocumentTypeCategory,
  ): Promise<void> {
    return await makeAddSpecializationToSiteRequirement().Call({
      companyId,
      requirementId,
      specialization,
      siteId,
      resourceType,
    });
  }

  async removeSpecializationFromRequirement(
    companyId: string,
    requirementId: string,
    specializationId: string,
    siteId: string,
  ): Promise<void> {
    return await makeRemoveSpecializationFromSiteRequirement().Call({
      companyId,
      requirementId,
      specializationId,
      siteId,
    });
  }
}

export { SiteRequirementsViewModel };
