import UseCase from '../../../core/app/usecase';
import { GetSiteResourcesFilters } from '../../../presentation/hooks/Badge/useSiteBadgesListViewModel';
import BadgeRepository, { GetAvailableBadgesFilters, LinkResourcesToBadgesResponse } from '../../repositories/badgeRepository';
import { BadgeResourceType } from '../../entities/badgeResourceType.enum';

type ARGS = {
	companyId: string;
	badgeIds: string[];
	workerIds: string[];
	selectAllResources: boolean;
	selectedResourcesNumber: number;
	siteResourcesFilters: GetSiteResourcesFilters;
	selectAllBadges: boolean;
	selectedBadgesNumber: number;
	availableBadgesFilters: GetAvailableBadgesFilters;
	automaticLinking: boolean;
	resourceType: BadgeResourceType;
};

class LinkBadgesToResources implements UseCase<ARGS, LinkResourcesToBadgesResponse> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({
		companyId,
		badgeIds,
		workerIds,
		selectAllResources,
		selectedResourcesNumber,
		siteResourcesFilters,
		selectAllBadges,
		selectedBadgesNumber,
		availableBadgesFilters,
		automaticLinking,
		resourceType,
	}: ARGS): Promise<LinkResourcesToBadgesResponse> {
		return await this.badgeRepository.linkBadgesToResources(
			companyId,
			badgeIds,
			workerIds,
			selectAllResources,
			selectedResourcesNumber,
			siteResourcesFilters,
			selectAllBadges,
			selectedBadgesNumber,
			availableBadgesFilters,
			automaticLinking,
			resourceType,
		);
	}
}

export default LinkBadgesToResources;
