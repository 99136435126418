import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Supplier from "../../../domain/entities/supplier";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { WorkingSitesListViewModel } from "../../viewmodels/workingSites/WorkingSitesListViewModel";
import { GetWorkingSiteFilter } from "../../../domain/repositories/supplierRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";

const useWorkingSitesViewModel = () => {
  const { companyId } = useAuth();
  const viewModel = new WorkingSitesListViewModel();
  const [filter, setFilter] = useState<GetWorkingSiteFilter>({
    name: null,
    company: null,
    address: null,
  });
  const [sort, setSort] = useState<SortMeta>();
  const [search, setSearch] = useState<string>();

  const { data: sites, isLoading: isFetching } = useQuery<Supplier[], Error>(
    ["working-sites", companyId, filter, sort, search],
    async () => {
      const filters = search ? { ...filter, search } : filter;
      return await viewModel.list(companyId, filters, sort);
    },
  );

  const updateFilter = (field: keyof GetWorkingSiteFilter, value: string) =>
    setFilter({ ...filter, [field]: value });

  return { sites, isFetching, filter, updateFilter, sort, setSort, setSearch };
};

export { useWorkingSitesViewModel };
