import {RequirementSubject} from "./requirementSubject.enum";

export type CustomField = {
  key: string;
  label: string;
  type: FieldType;
  subject: RequirementSubject;
}

export enum FieldType {
  TEXT = 'text',
  DATE = 'date',
  NUMBER = 'number',
}