import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingView from "../Common/LoadingView";
import ContentLayout from "../../layout/ContentLayout";
import Company from "../../../domain/entities/company";
import CompanyDetailActionBar from "./CompanyDetailActionBar";
import Staff, { StaffType } from "../../../domain/entities/staff";
import { Permission } from "../../components/Permissions/Permissions";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import CreateUpdateStaffModal from "../../components/Views/Staff/CreateStaffModal";
import { useCompanyDetailViewModel } from "../../hooks/Company/useCompanyDetailViewModel";
import {
  Flex,
  Grid,
  GridItem
} from "@chakra-ui/react";
import RecordsPanel from "./RecordsPanel";
import React from "react";
import CompanyStaffTabs from "./CompanyStaffTabs";

const CompanyDetailView = () => {
  const hook = useCompanyDetailViewModel();
  return hook.loading ?
    <LoadingView /> :
    <CompanyDetailViewMain hook={hook} />;
}

const memoizedEditRolesPermission = [Permission.Company_EditRoles];
const memoizedEditRecordsPermission = [Permission.Company_EditRecords];

const CompanyDetailViewMain = ({hook}: {hook: ReturnType<typeof useCompanyDetailViewModel>}) => {
  const { t } = useTranslation("companies");
  const [isEditing, setIsEditing] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [staffType, setStaffType] = useState(StaffType.SYSTEM);
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();

  const {
    company,
    isFetching,
    updateCompanyLogo,
    updateCompanyFields,
    staff,
    staffIsFetching,
    staffFilter,
    staffSort,
    createStaff,
    updateFilter,
    setStaffSort,
    deleteStaff,
    updateStaff,
    deleteStaffIsLoading,
    createStaffIsLoading,
    updateStaffIsLoading,
    systemStaff,
    systemStaffIsFetching,
    systemStaffFilter,
    systemStaffSort,
    setSystemStaffSort,
    updateSystemFilter,
    updateSystemStaff,
    coordinates,
    customFields
  } = hook;

  const onTabSelect = useCallback((t) => {
    setStaffType(t === 0 ? StaffType.SYSTEM : StaffType.CUSTOM);
  }, [setStaffType]);
  
  const onSubmit: SubmitHandler<Company> = (data) => {
    if (uploadedImageFile) {
      updateCompanyLogo(
        {
          ...data,
          id: company.id,
          fiscalCode: data.fiscalCode.toUpperCase(),
        },
        uploadedImageFile,
      );
    } else {
      updateCompanyFields({
        ...data,
        id: company.id,
        fiscalCode: data.fiscalCode.toUpperCase()
      });
    }
    setIsEditing(false);
  };

  const methods = useForm<Company>({ mode: "all", defaultValues: company });
  const {
    handleSubmit,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { isValid },
  } = methods;

  const toggleIsEditing = (isEditing: boolean) => {
    if (!isEditing) {
      setUploadedImageFile(undefined);
      reset({
        ccnl: company.ccnl,
        vatCountryCode: company.vatCountryCode,
        address: company.address,
        name: company.name,
        fiscalCode: company.fiscalCode,
        phoneNumber: company.phoneNumber,
        pec: company.pec,
        vat: company.vat,
        email: company.email,
        businessSize: company.businessSize,
      });
    }
    setIsEditing(isEditing);
  };

  const createStaffAction = async (staffElement: Staff) => {
    await createStaff(staffElement);
    setShowCreate(false);
  };

  const staffTypeMemoized = useMemo(()=>{
    return staffType === StaffType.CUSTOM
    ? () => setShowCreate(true)
    : undefined;
  }, [staffType]);

  if (!company) {
    // Company data not yet available.
    return <LoadingView />;
  }

  return (
    <>
      <ContentLayout
        action={
          <CompanyDetailActionBar
            isEditing={isEditing}
            canSave={isEditing && isValid}
            onSave={handleSubmit(onSubmit)}
            onAdd={staffTypeMemoized}
            onToggleEdit={isFetching ? undefined : toggleIsEditing}
            editDetailsPermissions={memoizedEditRecordsPermission}
            editStaffPermissions={memoizedEditRolesPermission}
          />
        }
      >
        
          <Grid
            templateColumns={"repeat(2, 1fr)"}
            gap={4}
            paddingLeft={10}
            paddingRight={3}
            py={10}
            h="100%"
            width={"calc(100vw - 120px)"}
          >
            <GridItem colSpan={2}>
              <Flex
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
                width={"calc(100vw - 180px)"}
                position={"relative"}
                overflow="hidden"
                flexDirection="column"
              >
                <FormProvider {...methods}>
                  <RecordsPanel
                    watch={watch}
                    setError={setError}
                    clearErrors={clearErrors}
                    coordinates={coordinates}
                    isEditing={isEditing}
                    isFetching={isFetching}
                    uploadedImageFile={uploadedImageFile}
                    setUploadedImageFile={setUploadedImageFile}
                    company={company}
                    customFields={customFields.data}
                  />
                </FormProvider>
              </Flex>
            </GridItem>
            <GridItem colSpan={2}>
            <CompanyStaffTabs
              staffType={staffType}
              onTabSelect={onTabSelect}
              systemStaff={systemStaff}
              staff={staff}
              systemStaffIsFetching={systemStaffIsFetching}
              staffIsFetching={staffIsFetching}
              updateStaffIsLoading={updateStaffIsLoading}
              deleteStaffIsLoading={deleteStaffIsLoading}
              systemStaffSort={systemStaffSort}
              staffSort={staffSort}
              systemStaffFilter={systemStaffFilter}
              staffFilter={staffFilter}
              setSystemStaffSort={setSystemStaffSort}
              setStaffSort={setStaffSort}
              updateSystemFilter={updateSystemFilter}
              updateFilter={updateFilter}
              updateSystemStaff={updateSystemStaff}
              updateStaff={updateStaff}
              deleteStaff={deleteStaff}
              memoizedEditRolesPermission={memoizedEditRolesPermission}
            />
            </GridItem>
          </Grid>
        {showCreate && (
          <CreateUpdateStaffModal
            createUpdateStaffIsLoading={createStaffIsLoading}
            staffType={staffType}
            onCancel={() => {
              setShowCreate(false);
            }}
            onConfirm={createStaffAction}
            title={t("createStaff", { ns: "companies" })}
          />
        )}
      </ContentLayout>
    </>
  );
};

export default CompanyDetailView;
