import UseCase from '../../../core/app/usecase';
import { ActivationRepository } from '../../repositories/activationRepository';

type ARGS = {
  token: string;
};

class ConfirmUserEmail implements UseCase<ARGS, void> {
  private activationRepository: ActivationRepository;

  constructor(activationRepository: ActivationRepository) {
    this.activationRepository = activationRepository;
  }

  async Call({ token}: ARGS): Promise<void> {
    return await this.activationRepository.confirmUserEmail(token);
  }
}

export default ConfirmUserEmail;
