import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import Document from '../../entities/document';
import { GetDocumentsFilter } from '../../repositories/documentRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';

type ARGS = {
	companyId: string;
	supplierId: string;
	filter?: GetDocumentsFilter;
	sort?: SortMeta;
	pageParam?: number;
};

class GetSupplierDocuments implements UseCase<ARGS, Document[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, filter, sort, pageParam }: ARGS): Promise<Document[]> {
		return await this.supplierRepository.getSupplierDocuments(companyId, supplierId, filter, sort, pageParam);
	}
}

export default GetSupplierDocuments;
