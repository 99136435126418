import UseCase from '../../../core/app/usecase';
import UsersRepository from '../../repositories/usersRepository';
import { makeUsersRepository } from "../../../core/app/factory/users/repositories";

interface Args {
  companyId: string;
  userId: string;
  emailId: string;
}

class RemoveUserEmail implements UseCase<Args, void> {
  constructor(private repository: UsersRepository) {}
  async Call({ companyId, userId, emailId }: Args): Promise<void> {
    return await this.repository.removeUserEmail(companyId, userId, emailId);
  }
}
export const makeRemoveEmail = (): RemoveUserEmail => {
  return new RemoveUserEmail(makeUsersRepository());
};