import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IoMdCloseCircle } from "react-icons/io";
import { COLORS } from "../../../assets/theme/colors";
import { Box, Tooltip, forwardRef } from "@chakra-ui/react";
import { MdCheckCircle, MdInfo, MdTimer } from "react-icons/md";

const kDefaultDaysCountDown = 30; // Keep it here or move it somewhere else?

interface Props {
  days?: number;
  daysCountDown?: number;
  noExpiration?: boolean;
}

/**
 * Renders an icon that indicates if something is valid, expired or about to expire in a given
 * number of days (daysCountDown, defaults to kDefaultDaysCountDown).
 *
 * Paramter days must be provided as-is, as component ExpirationIcon will use its absolute value
 * when relevant.
 *
 * The icon also displays a tooltip which uses localisation dictionary "common" to render
 * the appropriate hint (help) text.
 *
 * @param days number of days before the element will expire,
 *             may be a negative number (indicates "expired" scenario).
 * @param daysCountDown defines the "about to expire" time window;
 *                      if undefined, defaults to kDefaultDaysCountDown.
 */
const ExpirationIcon: FC<Props> = forwardRef(
  ({ days, daysCountDown, noExpiration }, ref) => {
    const { t } = useTranslation(); // Uses "common" dictionary.

    let text = "";
    let icon = undefined;
    let color = undefined;
    const expirationDaysTolerance = daysCountDown ?? kDefaultDaysCountDown;

    switch (true) {
      case days === 0:
        text = t("expiresHint.expiresToday");
        icon = <MdTimer />;
        color = COLORS.orange;
        break;
      case days > 0 && days <= expirationDaysTolerance:
        text = t("expiresHint.expiresIn", { count: days });
        icon = <MdTimer />;
        color = COLORS.orange;
        break;
      case days < 0:
        text = t("expiresHint.expiredSince", { count: -days });
        icon = <IoMdCloseCircle />;
        color = COLORS.red;
        break;
      case days > expirationDaysTolerance:
        text = noExpiration
          ? t("expiresHint.doesNotExpire", { ns: "common" })
          : t("expiresHint.safe");
        icon = <MdCheckCircle />;
        color = COLORS.green;
        break;
      default:
        text = t("expiresHint.doesNotExpire");
        icon = <MdInfo />;
        color = COLORS.sikuroBlue;
        break;
    }

    return (
      <Tooltip label={text} fontSize="md">
        <Box ref={ref} bgColor={color} color="white" p={2} borderRadius={20}>
          {icon}
        </Box>
      </Tooltip>
    );
  },
);

export default ExpirationIcon;
