import { BusinessSize } from '../../domain/interactors/auth/args';
import { ZodError, z } from 'zod';

export const fiscalCodeRegex = /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/i;

export const validateIdentityCode = (code?: string): boolean => {
	if (!code) {
		return false;
	}

	const it = fiscalCodeRegex;
	if (it.test(code)) {
		if (code.charAt(code.length - 1) === calculateControlDigit(code.slice(0, -1))) {
			return true;
		}
	}

	return false;
};

function calculateControlDigit(codice) {
	const code = codice.split('');
	let sum = 0;

	for (let i = 1; i <= code.length; i++) {
		const cifra = code[i - 1];
		sum += i % 2 ? dispari[cifra] : pari[cifra];
	}

	sum %= 26;

	return controllo[sum];
}

export const passwordIsValid = (password: string, errorMessagePrefix = ''): Array<string> => {
	const input = password.trim();

	try {
		z.string()
			.min(8, `${errorMessagePrefix}too short`)
			.regex(/[A-Z]+/, `${errorMessagePrefix}must contain uppercase char`)
			.regex(/[a-z]+/, `${errorMessagePrefix}must contain lowercase char`)
			.regex(/\d+/, `${errorMessagePrefix}must contain number`)
			.regex(/[$%&#+\-_!?@]+/, `${errorMessagePrefix}must contain special char`)
			.parse(input);

		return undefined;
	} catch (out) {
		return (out as ZodError).errors.map((e) => e.message);
	}
};

export const passwordIsConfirmed = (password: string, confirm: string, errorMessagePrefix = ''): Array<string> => {
	return password.trim() !== confirm.trim() ? [`${errorMessagePrefix}not confirmed`] : undefined;
};

// Source: https://euipo.europa.eu/tunnel-web/secure/webdav/guest/document_library/Documents/COSME/VAT%20numbers%20EU.pdf
export const vatCodeValidators = new Map<string, RegExp>([
	['-', /^(?!\s*$).+/], // Non-EU         -> non-empty string
	['AT', /^U\d{9}$/i], // Austria        -> U999999999
	['BE', /^[0-1]\d{9}$/i], // Belgium        -> 0999999999, 1999999999
	['BG', /^\d{9,10}$/i], // Bulgaria       -> 999999999, 9999999999
	['CY', /^\d{8}[A-Z]$/i], // Cyprus         -> 99999999L
	['CZ', /^\d{8,10}$/i], // Czech Republic -> 99999999, ..., 9999999999
	['DE', /^\d{9}$/i], // Germany        -> 999999999
	['DK', /^\d{8}$/i], // Denmark        -> 99999999
	['EE', /^\d{9}$/i], // Estonia        -> 999999999
	['EL', /^\d{9}$/i], // Greece         -> 999999999
	['ES', /^([A-Z]\d{8}|\d{8}[A-Z])$/i], // Spain          -> 99999999X, X99999999
	['FI', /^\d{8}$/i], // Finland        -> 99999999
	['FR', /^[A-Z]{2}\d{9}$/i], // France         -> XX999999999
	['HR', /^\d{11}$/i], // Croatia        -> 99999999999
	['HU', /^\d{8}$/i], // Hungary        -> 99999999
	['IE', /^(\d[A-Z]\d{5}[A-Z]|\d{7}[A-Z]{2})$/i], // Ireland        -> IE9S99999L, IE9999999WI
	['IT', /^\d{11}$/i], // Italy          -> 99999999999
	['LT', /^(\d{9}|\d{12})$/i], // Lithuania      -> 999999999, 999999999999
	['LU', /^\d{8}$/i], // Luxembourg     -> 99999999
	['LV', /^\d{11}$/i], // Latvia         -> 99999999999
	['MT', /^\d{8}$/i], // Malta          -> 99999999
	['NL', /^\d{9}B\d{2}$/i], // Netherlands    -> 999999999B99
	['PL', /^\d{10}$/i], // Poland         -> 9999999999
	['PT', /^\d{9}$/i], // Portugal       -> 999999999
	['RO', /^\d{2,10}$/i], // Romania        -> 99, ..., 9999999999
	['SE', /^\d{12}$/i], // Sweden         -> 999999999999
	['SI', /^\d{8}$/i], // Slovenia       -> 99999999
	['SK', /^\d{10}$/i], // Slovakia       -> 9999999999
]);

export const businessSizes = [
	{ id: BusinessSize.Micro, name: 'micro' },
	{ id: BusinessSize.Small, name: 'small' },
	{ id: BusinessSize.Medium, name: 'medium' },
	{ id: BusinessSize.MediumLarge, name: 'medium-large' },
	{ id: BusinessSize.Large, name: 'large' },
];

export const euVatCodes = [
	{ id: '-', name: '-' },
	{ id: 'AT', name: 'AT' },
	{ id: 'BE', name: 'BE' },
	{ id: 'BG', name: 'BG' },
	{ id: 'CY', name: 'CY' },
	{ id: 'CZ', name: 'CZ' },
	{ id: 'DE', name: 'DE' },
	{ id: 'DK', name: 'DK' },
	{ id: 'EE', name: 'EE' },
	{ id: 'EL', name: 'EL' },
	{ id: 'ES', name: 'ES' },
	{ id: 'FI', name: 'FI' },
	{ id: 'FR', name: 'FR' },
	{ id: 'HR', name: 'HR' },
	{ id: 'HU', name: 'HU' },
	{ id: 'IE', name: 'IE' },
	{ id: 'IT', name: 'IT' },
	{ id: 'LT', name: 'LT' },
	{ id: 'LU', name: 'LU' },
	{ id: 'LV', name: 'LV' },
	{ id: 'MT', name: 'MT' },
	{ id: 'NL', name: 'NL' },
	{ id: 'PL', name: 'PL' },
	{ id: 'PT', name: 'PT' },
	{ id: 'RO', name: 'RO' },
	{ id: 'SE', name: 'SE' },
	{ id: 'SI', name: 'SI' },
	{ id: 'SK', name: 'SK' },
];

export const fiscalCodeCountries = [
	{ id: 'IT', name: 'IT' },
	{ id: '-', name: '-' },
];

export const languageCodes = [
	{ id: 'it', name: 'it' },
	{ id: 'en', name: 'en' },
	{ id: 'fr', name: 'fr' },
	{ id: 'es', name: 'es' },
	{ id: 'de', name: 'de' },
];

const pari = {
	'0': 0,
	'1': 1,
	'2': 2,
	'3': 3,
	'4': 4,
	'5': 5,
	'6': 6,
	'7': 7,
	'8': 8,
	'9': 9,
	A: 0,
	B: 1,
	C: 2,
	D: 3,
	E: 4,
	F: 5,
	G: 6,
	H: 7,
	I: 8,
	J: 9,
	K: 10,
	L: 11,
	M: 12,
	N: 13,
	O: 14,
	P: 15,
	Q: 16,
	R: 17,
	S: 18,
	T: 19,
	U: 20,
	V: 21,
	W: 22,
	X: 23,
	Y: 24,
	Z: 25,
};

const dispari = {
	'0': 1,
	'1': 0,
	'2': 5,
	'3': 7,
	'4': 9,
	'5': 13,
	'6': 15,
	'7': 17,
	'8': 19,
	'9': 21,
	A: 1,
	B: 0,
	C: 5,
	D: 7,
	E: 9,
	F: 13,
	G: 15,
	H: 17,
	I: 19,
	J: 21,
	K: 2,
	L: 4,
	M: 18,
	N: 20,
	O: 11,
	P: 3,
	Q: 6,
	R: 8,
	S: 12,
	T: 14,
	U: 16,
	V: 10,
	W: 22,
	X: 25,
	Y: 24,
	Z: 23,
};

const controllo = {
	'0': 'A',
	'1': 'B',
	'2': 'C',
	'3': 'D',
	'4': 'E',
	'5': 'F',
	'6': 'G',
	'7': 'H',
	'8': 'I',
	'9': 'J',
	'10': 'K',
	'11': 'L',
	'12': 'M',
	'13': 'N',
	'14': 'O',
	'15': 'P',
	'16': 'Q',
	'17': 'R',
	'18': 'S',
	'19': 'T',
	'20': 'U',
	'21': 'V',
	'22': 'W',
	'23': 'X',
	'24': 'Y',
	'25': 'Z',
};
