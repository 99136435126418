import { SignUpUser } from './args';
import UseCase from '../../../core/app/usecase';
import { AuthRepository, makeAuthRepository } from '../../repositories/authRepository';

export class CreatePersonalAccount implements UseCase<SignUpUser, boolean> {
	constructor(private repository: AuthRepository) {}

	async Call(payload: SignUpUser): Promise<boolean> {
		return await this.repository.createPersonalAccount(payload);
	}
}

export const makeCreatePersonalAccount = (): CreatePersonalAccount => {
	return new CreatePersonalAccount(makeAuthRepository());
};
