import { useParams } from "react-router-dom";
import { FaRegIdBadge } from "react-icons/fa";
import SubNavBar from "../../Common/SubNavBar";
import { TbRubberStamp } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { SubNavItemProps } from "../../Common/SubNavItem";
import { Permission } from "../../Permissions/Permissions";

const SiteAccessSubNav = () => {
  const { siteId } = useParams();
  const { t } = useTranslation("badgeReaders");

  const items: SubNavItemProps[] = [
    {
      icon: FaRegIdBadge,
      path: `/sites/${siteId}/access/badges`,
      name: t("title", { ns: "badges" }),
    },
    {
      icon: TbRubberStamp,
      path: `/sites/${siteId}/access/badge-readers`,
      name: t("badgeReaders", { ns: "badgeReaders" }),
      permissions: [Permission.Sites_ShowBadgeReaders],
    },
  ];

  return <SubNavBar items={items} />;
};

export default SiteAccessSubNav;
