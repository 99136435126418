import { Flex, Spinner, Image } from "@chakra-ui/react";
import { COLORS } from "../../assets/theme/colors";
import sikuroLogo from "../../../assets/images/logoSikuro.png";

type loadingViewProps = {
  width?: string;
  height?: string;
};

const LoadingView = ({ width = "100%", height = "100%" }: loadingViewProps) => {
  return (
    <Flex h={height} w={width} justify="center" align="center">
      <Flex
        width={"100px"}
        height={"100px"}
        position={"relative"}
        justifyContent="center"
        alignItems="center"
      >
        <Spinner
          width={"100px"}
          height={"100px"}
          color={COLORS.sikuroBlue}
          speed={"1s"}
        />
        <Image
          src={sikuroLogo}
          position="absolute"
          zIndex={1}
          width={"60%"}
          height={"60%"}
        />
      </Flex>
    </Flex>
  );
};

export default LoadingView;
