import {
  ChangeEvent,
  FC,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
} from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export type TextFieldAction = {
  icon: any;
  hint: string;
  handler: () => void;
};

export type TextFieldProps = {
  id: string;
  defaultValue: string;

  label: string;
  width?: string;
  marginTop?: number;

  isRequired?: boolean;
  isProtected?: boolean;
  errorMessages?: string | Array<string> | false;

  actions?: Array<TextFieldAction>;
  onChange: (field: string, value: string) => void;
  onEnter?: () => void;
  namespace?: string;
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
};

// TODO: Merge this component with InputAnimatedLabel.
const TextField: FC<TextFieldProps> = ({
  id,
  defaultValue,
  label,
  width,
  marginTop,
  isRequired,
  isProtected,
  errorMessages,
  onChange,
  actions,
  namespace,
  type = "text",
  onEnter,
  disabled = false,
}) => {
  const { t } = useTranslation(namespace);
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange(id, newValue);
  };

  return (
    <Box marginTop={marginTop} width={width}>
      <FormControl
        id={id}
        variant="floating"
        marginTop={2}
        borderColor="gray.400"
        isInvalid={errorMessages && errorMessages?.length > 0}
        isRequired={isRequired}
      >
        <InputGroup>
          <Input
            type={isProtected ? "password" : type}
            color="black"
            backgroundColor="white"
            onChange={(e) => handleOnChange(e)}
            value={defaultValue}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
                onEnter && onEnter();
              }
            }}
            disabled={disabled}
          />

          {actions &&
            actions.map((action, key) => (
              <InputRightElement key={key} width="20">
                <IconButton
                  fontSize="2xl"
                  outlineColor="transparent"
                  backgroundColor="transparent"
                  style={{ backgroundColor: "transparent" }}
                  aria-label={action.hint}
                  onClick={action.handler}
                >
                  {action.icon}
                </IconButton>
              </InputRightElement>
            ))}
        </InputGroup>

        <FormLabel backgroundColor="white" borderRadius={4} color="gray.500">
          {label}
        </FormLabel>
        {errorMessages &&
          (Array.isArray(errorMessages) ? (
            <FormErrorMessage
              display="flex"
              flexDirection="column"
              alignItems="start"
            >
              {errorMessages.map((message, key) => (
                <div key={key}>{t(message)}</div>
              ))}
            </FormErrorMessage>
          ) : (
            <FormErrorMessage>{t(errorMessages)}</FormErrorMessage>
          ))}
      </FormControl>
    </Box>
  );
};

export default TextField;
