import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { SiteVehicleParams } from '../../entities/siteVehicle';
import { SupplierResource } from '../../entities/supplierResource';
type ARGS = {
	companyId: string;
	supplierId: string;
	resourceId: string;
};
class GetCompanySupplierVehicle implements UseCase<ARGS, SupplierResource<SiteVehicleParams>> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, resourceId }: ARGS): Promise<SupplierResource<SiteVehicleParams>> {
		return await this.supplierRepository.getCompanySupplierVehicle(companyId, supplierId, resourceId);
	}
}

export default GetCompanySupplierVehicle;
