import { DownloadFileScope, DownloadResourceFileScope } from '../../repositories/documentsDownloader';

/**
 * Defines the download context of all files of a document.
 */
export class RequestDocumentFilesArchive {
	constructor(
		private _companyId: string,
		private _documentId: string,
		private _renameModel: string,
	) {}

	get companyId(): string {
		return this._companyId;
	}
	get documentId(): string {
		return this._documentId;
	}
	get renameModel(): string {
		return this._renameModel;
	}
}

/**
 * Defines the download context of a single file of a document.
 */
export class RequestDocumentFile extends RequestDocumentFilesArchive {
	constructor(
		companyId: string,
		documentId: string,
		private _fileId: string,
		renameModel: string,
	) {
		super(companyId, documentId, renameModel);
	}

	get fileId(): string {
		return this._fileId;
	}
}

/**
 * Defines the download context for all files of companies or sites.
 */
export class RequestSupplierOrSiteFilesArchive {
	constructor(
		private _siteId: string,
		private _companyId: string,
		private _requirementId: string,
		private _scope: DownloadFileScope,
		private _renameModel: string,
	) {}

	get siteId(): string {
		return this._siteId;
	}
	get companyId(): string {
		return this._companyId;
	}
	get scope(): DownloadFileScope {
		return this._scope;
	}
	get requirementId(): string {
		return this._requirementId;
	}
	get renameModel(): string {
		return this._renameModel;
	}
}

/**
 * Defines the download context for a single file of companies or sites.
 */
export class RequestSupplierOrSiteFile extends RequestSupplierOrSiteFilesArchive {
	constructor(
		private _fileId: string,

		siteId: string,
		companyId: string,
		requirementId: string,
		scope: DownloadFileScope,
		renameModel: string,
	) {
		super(siteId, companyId, requirementId, scope, renameModel);
	}

	get fileId(): string {
		return this._fileId;
	}
}

/**
 * Defines the download context for all files of a resource (workers, tools, etc.).
 */
export class RequestResourceFilesArchive {
	constructor(
		private _siteId: string,
		private _companyId: string,
		private _resourceId: string,
		private _requirementId: string,
		private _scope: DownloadResourceFileScope,
		private _renameModel: string,
	) {}

	get siteId(): string {
		return this._siteId;
	}
	get companyId(): string {
		return this._companyId;
	}
	get resourceId(): string {
		return this._resourceId;
	}
	get requirementId(): string {
		return this._requirementId;
	}
	get scope(): DownloadResourceFileScope {
		return this._scope;
	}
	get renameModel(): string {
		return this._renameModel;
	}
}

/**
 * Defines the download context for a single file of a resource (workers, tools, etc.).
 */
export class RequestResourceFile extends RequestResourceFilesArchive {
	constructor(
		private _fileId: string,

		siteId: string,
		companyId: string,
		resourceId: string,
		requirementId: string,
		scope: DownloadResourceFileScope,
		renameModel: string,
	) {
		super(siteId, companyId, resourceId, requirementId, scope, renameModel);
	}

	get fileId(): string {
		return this._fileId;
	}
}

/**
 * Defines the download context for all documents of resource.
 */
export class RequestDocumentsFiles {
	constructor(
		private _companyId: string,
		private _siteId: string,
		private _resourceId: string,
		private _resourceScope: DownloadResourceFileScope | DownloadFileScope,
		private _renameModel: string,
	) {}
	get companyId(): string {
		return this._companyId;
	}
	get siteId(): string {
		return this._siteId;
	}
	get resourceId(): string {
		return this._resourceId;
	}
	get resourceScope(): DownloadResourceFileScope | DownloadFileScope {
		return this._resourceScope;
	}
	get renameModel(): string {
		return this._renameModel;
	}
}

export type RequestFilesArchiveContext =
	| RequestDocumentFilesArchive
	| RequestSupplierOrSiteFilesArchive
	| RequestResourceFilesArchive
	| RequestDocumentsFiles;

export type RequestFileContext = RequestDocumentFile | RequestSupplierOrSiteFile | RequestResourceFile;
