import UseCase from "../../../core/app/usecase";
import BadgeReader from "../../entities/badgeReader";
import BadgeReaderRepository from "../../repositories/badgeReaderRepository";

type ARGS = {
  companyId: string;
  siteId: string
  badgeReaderId: string;
}

class UnlinkBadgeReader implements UseCase<ARGS, void> {
  private badgeReaderRepository: BadgeReaderRepository;

  constructor(badgeReaderRepository: BadgeReaderRepository) {
    this.badgeReaderRepository = badgeReaderRepository;
  }

  async Call({companyId, siteId, badgeReaderId}: ARGS): Promise<void> {
    return await this.badgeReaderRepository.unlinkBadgeReader(companyId, siteId, badgeReaderId);
  }
}

export default UnlinkBadgeReader;