import { useTranslation } from "react-i18next";
import TopBar from "../../Common/TopBar";
import { useParams } from "react-router-dom";

const SupplierTopBar = () => {
  const { t } = useTranslation("sites");
  const { supplierId } = useParams();

  return (
    <TopBar
      title={""}
      items={[
        {
          path: `/suppliers/${supplierId}/details`,
          title: t("summary"),
        },
        {
          path: `/suppliers/${supplierId}/resources`,
          title: t("resources"),
        },
      ]}
    />
  );
};

export default SupplierTopBar;
