import UseCase from '../../../../core/app/usecase';
import MachineRepository from '../../../repositories/machineRepository';

type Args = {
	companyId: string;
	machineId: string;
};

class RestoreMachine implements UseCase<Args, void> {
	constructor(private repository: MachineRepository) {}

	async Call({ companyId, machineId }: Args): Promise<void> {
		return await this.repository.restoreMachine(companyId, machineId);
	}
}

export default RestoreMachine;
