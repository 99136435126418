import UseCase from '../../../core/app/usecase';
import { BadgeLogEntry } from '../../entities/badgeLogEntry';
import BadgeReaderRepository from '../../repositories/badgeReaderRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	badgeLogEntry: BadgeLogEntry;
	badgeReaderId: string;
};

class CreateBadgeReaderLogEntry implements UseCase<ARGS, void> {
	private badgeReaderRepository: BadgeReaderRepository;

	constructor(badgeRepository: BadgeReaderRepository) {
		this.badgeReaderRepository = badgeRepository;
	}

	async Call({ companyId, siteId, badgeLogEntry, badgeReaderId }: ARGS): Promise<void> {
		return await this.badgeReaderRepository.createBadgeReaderLogEntry(companyId, siteId, badgeLogEntry, badgeReaderId);
	}
}

export default CreateBadgeReaderLogEntry;
