import UseCase from "../../../core/app/usecase";
import DocumentRepository from "../../repositories/documentRepository";
import Document from "../../entities/document";

interface Args {
  companyId: string;
  documents: Document[];
}

class ReorderDocuments implements UseCase<Args, void> {
  constructor(private repository: DocumentRepository) {}

  async Call({ companyId, documents }: Args): Promise<void> {
    return this.repository.reorderDocuments(companyId, documents);
  }
}

export default ReorderDocuments;
