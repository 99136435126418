import { WarningTwoIcon } from "@chakra-ui/icons";
import { Alert, Box, Flex, Text } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { COLORS } from "../../../assets/theme/colors";

interface WarningBannerProps {
  description: string | ReactNode;
  onClose?: () => void;
  fontSize?: string;
}

const WarningBanner: FC<WarningBannerProps> = ({ description, onClose, fontSize }) => {
  const { t } = useTranslation("siteResources");
  return (
    <Alert status="warning" borderRadius={8} my={2} display={"flex"}>
      <Box width="full">
        <Flex justifyContent="space-between">
          <Flex gap={2} mb={2}>
            <WarningTwoIcon color={COLORS.darkYellow} fontSize="larger" />
            <Text fontWeight={"bold"}>{t("warning", { ns: "common" })}</Text>
          </Flex>
          {onClose && <AiOutlineClose onClick={onClose} cursor={"pointer"} />}
        </Flex>
        <Text ml={7} flexGrow={1} fontSize={fontSize}>
          {description}
        </Text>
      </Box>
    </Alert>
  );
};

export { WarningBanner };
