import { makeGetTimeTableDocuments } from "../../../core/app/factory/documents/usecases";
import TimeTableDocuments from "../../../domain/entities/timeTableDocuments";

class TimeTableViewModel {
  private getTimeTableDocumentsUseCase = makeGetTimeTableDocuments();

  async getTimeTableDocuments(
    companyId: string,
    startDate: Date,
    endDate: Date,
  ): Promise<TimeTableDocuments> {
    return await this.getTimeTableDocumentsUseCase.Call({
      companyId,
      startDate,
      endDate,
    });
  }
}

export default TimeTableViewModel;
