import UseCase from '../../../core/app/usecase';
import Tag from '../../entities/tag';
import TagRepository from '../../repositories/tagRepository';
type UpdateTagParams = {
	companyId: string;
	tag: Tag;
};
class UpdateTag implements UseCase<UpdateTagParams, void> {
	private tagRepository: TagRepository;

	constructor(tagRepository: TagRepository) {
		this.tagRepository = tagRepository;
	}

	async Call({ companyId, tag }: UpdateTagParams): Promise<void> {
		return await this.tagRepository.renameTag(companyId, tag);
	}
}

export default UpdateTag;
