import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteMachineParams } from "../../../domain/entities/siteMachine";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";
import useWorkingSiteMachinesViewModel from "../../hooks/WorkingSite/useWorkingSiteMachinesViewModel";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const SiteMachinesView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["name", "plate", "model"];
  return (
    <SiteResourcesListView<SiteMachineParams>
      type={DocumentTypeCategory.MACHINE}
      useViewModel={useWorkingSiteMachinesViewModel(
        siteId,
        resourceColumns,
        DocumentTypeCategory.MACHINE,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[Permission.Worksite_AttachMachine]}
      removeFromSitePermissions={[Permission.Worksite_DetachMachine]}
    />
  );
};

export default SiteMachinesView;
