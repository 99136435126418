import { injectable } from 'tsyringe';
import Requirement from '../../domain/entities/requirement';
import { RequirementSubject } from '../../domain/entities/requirementSubject.enum';
import Specialization from '../../domain/entities/specialization';
import Tag from '../../domain/entities/tag';
import Variant from '../../domain/entities/variant';
import RequirementRepository from '../../domain/repositories/requirementRepository';
import { ApiService } from '../utilities/apiService';
import FileEntity from '../../domain/entities/file';
import { GetSitesFilter } from "../../domain/repositories/siteRepository";
import { removeEmptyAttributes } from "../../utils";
import { DocumentTypeCategory } from '../../domain/entities/documentTypeCategory.enum';

@injectable()
class ServerRequirementRepository implements RequirementRepository {
	constructor(private apiService: ApiService) { }

	async getRequirementById(companyId: string, id: string): Promise<Requirement | undefined> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements/${id}`);
		return response.json();
	}

	async getRequirements(companyId: string): Promise<Requirement[]> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements`);
		return response.json();
	}

	async getRequirementsByVariant(
		companyId: string,
		siteId: string,
		variantId: string,
		requirementSubject: RequirementSubject,
	): Promise<Requirement[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/variants/${variantId}/requirements?subject=${requirementSubject}`,
		);
		const { results } = await response.json();
		return results;
	}

	async getRequirementsGroupByVariant(
		companyId: string,
		variantId: string,
		requirementGroupId: string,
		requirementSubject: RequirementSubject,
	): Promise<Requirement[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${requirementGroupId}/variants/${variantId}/requirements?type=${requirementSubject}`,
		);
		const { results } = await response.json();
		return results;
	}

	async getGroupRequirementsByResource(
		companyId: string,
		requirementGroupId: string,
		requirementSubject: RequirementSubject,
	): Promise<Requirement[]> {

		const requestPath = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${requirementGroupId}/requirements?type=${requirementSubject}`;

		const response = await this.apiService.fetchWithToken(requestPath);
		const { results } = await response.json();
		return results;
	}

	async getSiteRequirementsByResource(companyId: string, siteId: string, requirementSubject: RequirementSubject): Promise<Requirement[]> {
		let path = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirements?subject=${requirementSubject}`;
		if (requirementSubject === RequirementSubject.COMPANY || requirementSubject === RequirementSubject.SITE) {
			path = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirements`;
		}
		const response = await this.apiService.fetchWithToken(path);
		const { results } = await response.json();
		return results;
	}

	async getRequirementsTemplateUrl(companyId: string, siteId: string, requirementId: string, templateId: string ): Promise<string> {
		return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirement/${requirementId}/template-file/${templateId}/download`;
	}

	async createRequirement(companyId: string, requirement: Requirement): Promise<Requirement> {
		return await this.apiService
			.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(requirement),
			})
			.then((response) => response.json());
	}

	async updateRequirement(companyId: string, requirement: Requirement, requirementGroupId: string, siteIds?: string[], selectAllSites?: boolean, filters?: GetSitesFilter): Promise<void> {
		const body = {
			isOptional: requirement.isOptional,
			graceDays: parseInt(requirement.graceDays.toString()),
			variantsIds: requirement.variants.map((variant) => variant.id),
			specializationsIds: requirement.specializations.map((specialization) => specialization.id),
			sitesIds: siteIds,
			selectAll: selectAllSites,
		}

		for (const key in removeEmptyAttributes(filters)) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				body[key] = filters[key];
			}
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${requirementGroupId}/requirements/${requirement.id}`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(body),
			},
		);
		if (response.status === 409) {
			return Promise.reject(new Error('requirementWithVariantAlreadyExists'));
		}
		return Promise.resolve();
	}

	async updateSiteRequirement(companyId: string, requirement: Requirement, siteId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirements/${requirement.id}`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					isOptional: requirement.isOptional,
					graceDays: parseInt(requirement.graceDays.toString()),
					variantsIds: requirement.variants.map((variant) => variant.id),
					specializationsIds: requirement.specializations.map((specialization) => specialization.id),
				}),
			},
		);
	}

	async addTemplateToSiteRequirement(companyId: string, siteId: string, requirementId: string, file: FileEntity): Promise<void> {
		const formData = new FormData();
		formData.append('file', file.binaries[0]);
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirement/${requirementId}/template`,
			{
				method: 'POST',
				body: formData,
			},
		);
	}

	async deleteTemplateFromSiteRequirement(companyId: string, siteId: string, requirementId: string, templateId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirement/${requirementId}/template`,
			{
				method: 'DELETE',
			},
		);
	}

	async updateRequirementsOrder(companyId: string, requirements: Requirement[], requirementGroupId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${requirementGroupId}/requirements`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ requirements: requirements }),
			},
		);
	}

	async updateRequirementsByVariantOrder(companyId: string, siteId: string, variantId: string, requirements: Requirement[]): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/variants/${variantId}/requirements`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ requirements: requirements }),
			},
		);
	}

	async updateRequirementsGroupByVariantOrder(
		companyId: string,
		variantId: string,
		requirementGroupId: string,
		requirements: Requirement[],
	): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${requirementGroupId}/variants/${variantId}/requirements`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ requirements: requirements }),
			},
		);
	}

	async updateSiteRequirementsOrder(companyId: string, requirements: Requirement[], siteId: string): Promise<void> {
		const payload = {
			requirements: [],
		};

		payload.requirements = requirements.map((r) => {
			return {
				id: r.id,
				subject: r.subject,
				isOptional: r.isOptional,
				documentType: r.documentType,
				variants: r.variants,
				specializations: r.specializations,
				graceDays: r.graceDays,
				source: r.source,
				parentId: r.parentId
			};
		});

		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirements`, {
			method: 'PATCH',
			body: JSON.stringify(payload),
			headers: { 'Content-Type': 'application/json' },
		});
	}

	async deleteRequirement(companyId: string, id: string): Promise<Requirement[]> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements/${id}`, {
			method: 'DELETE',
		});

		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements`);
		return response.json();
	}

	async deleteSiteRequirement(companyId: string, siteId: string, requirementId: string, subject: RequirementSubject): Promise<void> {
		let path;
		if (subject === RequirementSubject.COMPANY || subject === RequirementSubject.SITE || subject === RequirementSubject.SUPPLIER) {
			path = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${companyId}/requirements/${requirementId}`;
		} else {
			path = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${companyId}/${subject}-requirements/${requirementId}`
		}
		await this.apiService.fetchWithToken(
			path,
			{
				method: 'DELETE',
			},
		);
	}

	async createVariant(companyId: string, variant: Tag, requirementSubject: RequirementSubject): Promise<Variant> {
		if (variant.id === variant.name) {
			const variantCreationURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/variants`;
			const createdVariantResponse = await this.apiService.fetchWithToken(variantCreationURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ name: variant.name, resourceType: requirementSubject }),
			});

			const createdVariant = await createdVariantResponse.json();
			return createdVariant;
		}
	}

	async createSpecialization(companyId: string, specialization: Specialization, requirementSubject: RequirementSubject): Promise<Specialization> {
		if (specialization.id === specialization.name) {
			const specializationCreationURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/specializations`;
			const createdSpecializationResponse = await this.apiService.fetchWithToken(specializationCreationURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ name: specialization.name, resourceType: requirementSubject }),
			});

			const createdSpecialization = await createdSpecializationResponse.json();
			return createdSpecialization;
		}
	}

	async addVariantToRequirement(
		companyId: string,
		requirementId: string,
		variant: Tag,
		requirementGroupId: string,
		resType: DocumentTypeCategory,
	): Promise<void> {
		let linkedVariant = variant;
		if (linkedVariant.id === linkedVariant.name) {
			const variantCreationURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/variants`;
			const createVariantResponse = await this.apiService.fetchWithToken(variantCreationURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ name: linkedVariant.name, resourceType: resType }),
			});
			linkedVariant = await createVariantResponse.json();
		}

		const linkResponse = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${requirementGroupId}/requirements/${requirementId}/variants/${linkedVariant.id}`,
			{
				method: 'PUT',
			},
		);

		if (!linkResponse.ok) {
			return Promise.reject(new Error('cannot link variant to requirement'));
		}
	}

	async removeVariantFromRequirement(companyId: string, requirementId: string, variantId: string, requirementGroupId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${requirementGroupId}/requirements/${requirementId}/variants/${variantId}`,
			{
				method: 'DELETE',
			},
		);
	}

	async addSpecializationToRequirement(
		companyId: string,
		requirementId: string,
		specialization: Tag,
		requirementGroupId: string,
		resType: DocumentTypeCategory,
	): Promise<void> {
		let linkedSpecialization = specialization;
		if (linkedSpecialization.id === linkedSpecialization.name) {
			const specializationCreationURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/specializations`;
			const createSpecializationResponse = await this.apiService.fetchWithToken(specializationCreationURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ name: linkedSpecialization.name, resourceType: resType }),
			});
			linkedSpecialization = await createSpecializationResponse.json();
		}

		const linkResponse = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${requirementGroupId}/requirements/${requirementId}/specializations/${linkedSpecialization.id}`,
			{
				method: 'PUT',
			},
		);

		if (!linkResponse.ok) {
			return Promise.reject(new Error('cannot link specialization to requirement'));
		}
	}

	async removeSpecializationFromRequirement(
		companyId: string,
		requirementId: string,
		specializationId: string,
		requirementGroupId: string,
	): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/requirements-groups/${requirementGroupId}/requirements/${requirementId}/specializations/${specializationId}`,
			{
				method: 'DELETE',
			},
		);
	}

	async addVariantToSiteRequirement(
		companyId: string,
		requirementId: string,
		variant: Tag,
		siteId: string,
		resType: DocumentTypeCategory,
	): Promise<void> {
		let linkedVariant = variant;
		if (linkedVariant.id === linkedVariant.name) {
			const variantCreationURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/variants`;
			const createVariantResponse = await this.apiService.fetchWithToken(variantCreationURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ name: linkedVariant.name, resourceType: resType }),
			});
			linkedVariant = await createVariantResponse.json();
		}

		const linkResponse = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirements/${requirementId}/variants/${linkedVariant.id}`,
			{
				method: 'PUT',
			},
		);

		if (!linkResponse.ok) {
			return Promise.reject(new Error('cannot link variant to requirement'));
		}
	}

	async updateVariantToSiteRequirement(companyId: string, tag: Tag): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/variants/${tag.id}`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ name: tag.name }),
			},
		);
		if (response.status === 409) {
			return Promise.reject(new Error('cannot rename variant'));
		}
		return Promise.resolve();
	}

	async removeVariantFromSiteRequirement(companyId: string, requirementId: string, variantId: string, siteId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirements/${requirementId}/variants/${variantId}`,
			{
				method: 'DELETE',
			},
		);
	}

	async addSpecializationToSiteRequirement(
		companyId: string,
		requirementId: string,
		specialization: Tag,
		siteId: string,
		resType: DocumentTypeCategory,
	): Promise<void> {
		let linkedSpecialization = specialization;
		if (linkedSpecialization.id === linkedSpecialization.name) {
			const specializationCreationURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/specializations`;
			const createSpecializationResponse = await this.apiService.fetchWithToken(specializationCreationURL, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ name: linkedSpecialization.name, resourceType: resType }),
			});
			linkedSpecialization = await createSpecializationResponse.json();
		}

		const linkResponse = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirements/${requirementId}/specializations/${linkedSpecialization.id}`,
			{
				method: 'PUT',
			},
		);

		if (!linkResponse.ok) {
			return Promise.reject(new Error('cannot link specialization to requirement'));
		}
	}

	async updateSpecializationToSiteRequirement(companyId: string, tag: Tag): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/specializations/${tag.id}`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ name: tag.name }),
			},
		);
		if (response.status === 409) {
			return Promise.reject(new Error('cannot rename specialization'));
		}
		return Promise.resolve();
	}

	async removeSpecializationFromSiteRequirement(companyId: string, requirementId: string, specializationId: string, siteId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirements/${requirementId}/specializations/${specializationId}`,
			{
				method: 'DELETE',
			},
		);
	}

	async deleteSiteSupplierRequirement(
		companyId: string,
		siteId: string,
		requirementId: string,
		subject: RequirementSubject,
		resourceId: string,
	): Promise<void> {
		let requirementsPath = '';
		switch (subject) {
			case RequirementSubject.COMPANY:
			case RequirementSubject.SITE:
				requirementsPath = `suppliers/${resourceId}/requirements/${requirementId}`;
				break;
			default:
				requirementsPath = `${subject}s/${resourceId}/requirements/${requirementId}`;
				break;
		}

		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/${requirementsPath}`;
		await this.apiService.fetchWithToken(url, { method: 'DELETE', headers: { 'Content-Type': 'application/json' } });
	}
}

export default ServerRequirementRepository;
