import { TbBuildingFactory } from "react-icons/tb";
import { GiCrane } from "react-icons/gi";
import { NavItemProps } from "../../Common/NavItem";
import SubNavBar from "../../Common/SubNavBar";
import { useParams } from "react-router-dom";
import WorkerIcon from "../../Common/icons/WorkerIcon";
import { useTranslation } from "react-i18next";
import VehicleIcon from "../../Common/icons/VehicleIcon";
import MachineIcon from "../../Common/icons/MachineIcon";
import ToolIcon from "../../Common/icons/ToolIcon";
import ChemicalIcon from "../../Common/icons/ChemicalIcon";

const SiteRequirementsSubNav = () => {
  const { siteId } = useParams();
  const { t } = useTranslation("navbar");

  const items: NavItemProps[] = [
    {
      icon: TbBuildingFactory,
      path: `/sites/${siteId}/requirements/supplier`,
      name: t("sub.company"),
    },
    {
      Icon: WorkerIcon,
      path: `/sites/${siteId}/requirements/worker`,
      name: t("sub.workers"),
    },
    {
      Icon: VehicleIcon,
      path: `/sites/${siteId}/requirements/vehicle`,
      name: t("sub.vehicles"),
    },
    {
      Icon: MachineIcon,
      path: `/sites/${siteId}/requirements/machine`,
      name: t("sub.machines"),
    },
    {
      Icon: ToolIcon,
      path: `/sites/${siteId}/requirements/tool`,
      name: t("sub.tools"),
    },
    {
      Icon: ChemicalIcon,
      path: `/sites/${siteId}/requirements/chemical`,
      name: t("sub.chemicals"),
    }
  ];

  return <SubNavBar items={items} />;
};

export default SiteRequirementsSubNav;
