import UseCase from '../../../core/app/usecase';
import DocumentType from '../../entities/documentType';
import DocumentTypeRepository from '../../repositories/documentTypeRepository';
import GetDocumentTypesParameters from './getDocumentTypesParameter';

/**
 * Returns the entire list of DocumentType.
 */

class GetDocumentTypes implements UseCase<GetDocumentTypesParameters, DocumentType[]> {
	constructor(private repository: DocumentTypeRepository) {}

	async Call({
		companyId,
		filter,
		docTypeFilters,
		includePublic,
		search,
		tags,
		sort,
		pageParam,
		withFileCount,
		resourceId,
		systemOnly,
	}: GetDocumentTypesParameters): Promise<DocumentType[]> {
		return await this.repository.getDocumentTypes(
			companyId,
			filter,
			docTypeFilters,
			includePublic,
			search,
			tags,
			sort,
			pageParam,
			withFileCount,
			resourceId,
			systemOnly,
		);
	}
}

export default GetDocumentTypes;
