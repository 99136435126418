import UseCase from '../../../../core/app/usecase';
import SiteMachineRepository from '../../../repositories/siteMachineRepository';
import { SiteMachine } from '../../../entities/siteMachine';

type GetSiteMachineParameters = {
	companyId: string;
	siteId: string;
	machineId: string;
};

class GetSiteMachine implements UseCase<GetSiteMachineParameters, SiteMachine | undefined> {
	constructor(private repository: SiteMachineRepository) {}

	async Call({ companyId, siteId, machineId }: GetSiteMachineParameters): Promise<SiteMachine | undefined> {
		return this.repository.getSiteMachine(companyId, siteId, machineId);
	}
}

export default GetSiteMachine;
