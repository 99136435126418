import {
  makeCreateBadgeReaderLogEntry,
  makeDeleteBadgeReaderLogEntry,
  makeGetBadgeReaderDetail,
  makeGetBadgeReaderHistory,
  makeGetBadgeReadersSites,
  makeLinkBadgeReaderToSites,
  makeUpdateBadgeReader,
  makeUpdateBadgeReaderStatus
} from "../../../core/app/factory/badgeReaders/usecases";
import { makeGetBadgesNotPaginated } from "../../../core/app/factory/badges/usecases";
import Badge from "../../../domain/entities/badge";
import { BadgeLogEntry } from "../../../domain/entities/badgeLogEntry";
import BadgeReader from "../../../domain/entities/badgeReader";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import Site from "../../../domain/entities/site";
import { GetBadgeReaderHistoryFilters } from "../../../domain/repositories/badgeReaderRepository";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";

class SiteBadgeReaderDetailViewModel {
  private getSiteBadgeReaderDetailUseCase = makeGetBadgeReaderDetail();
  private getBadgeReaderHistoryUseCase = makeGetBadgeReaderHistory();
  private getSiteBadgeReadersSitesUseCase = makeGetBadgeReadersSites();
  private updateBadgeReaderStatusUseCase = makeUpdateBadgeReaderStatus();
  private deleteBadgeReaderLogEntryUseCase = makeDeleteBadgeReaderLogEntry();
  private getSiteBadgesNoPagingUseCase = makeGetBadgesNotPaginated();
  private createBadgeLogEntryUseCase = makeCreateBadgeReaderLogEntry();
  private updateBadgeReaderUseCase = makeUpdateBadgeReader();
  private linkBadgeReaderToSitesUseCase = makeLinkBadgeReaderToSites();

  async getSiteBadgeReaderDetail(
    companyId: string,
    siteId: string,
    badgeReaderId: string
  ): Promise<BadgeReader> {
    const badgeReaders = await this.getSiteBadgeReaderDetailUseCase.Call({
      companyId,
      siteId,
      badgeReaderId,
    });
    return badgeReaders;
  }

  async getSiteBadgeReadersSites(
    companyId: string,
    badgeReaderId: string,
    page: number,
    filter?: GetSitesFilter,
    sort?: SortMeta
  ): Promise<PaginatedResults<Site>> {
    return await this.getSiteBadgeReadersSitesUseCase.Call({
      companyId,
      badgeReaderId,
      page,
      perPage: 25,
      filter,
      sort,
    });
  }

  async getBadgeReaderHistory(
    companyId: string,
    siteId: string,
    badgeReaderId: string,
    sort: SortMeta,
    filter: GetBadgeReaderHistoryFilters,
    pageParam: number
  ): Promise<BadgeLogEntry[]> {
    return await this.getBadgeReaderHistoryUseCase.Call({
      companyId,
      siteId,
      badgeReaderId,
      filter,
      sort,
      pageParam,
    });
  }

  async updateBadgeReaderStatus(
    companyId: string,
    siteId: string,
    badgeReader: Partial<BadgeReader>
  ): Promise<void> {
    return await this.updateBadgeReaderStatusUseCase.Call({
      companyId,
      siteId,
      badgeReader,
    });
  }
  async updateBadgeReader(
    companyId: string,
    siteId: string,
    badgeReader: Partial<BadgeReader>
  ): Promise<void> {
    return await this.updateBadgeReaderUseCase.Call({
      companyId,
      siteId,
      badgeReader,
    });
  }

  async createBadgeLogEntry(
    companyId: string,
    siteId: string,
    badgeLogEntry: BadgeLogEntry,
    badgeReaderId: string
  ): Promise<void> {
    return await this.createBadgeLogEntryUseCase.Call({
      companyId,
      siteId,
      badgeLogEntry,
      badgeReaderId,
    });
  }

  async deleteBadgeReaderLogEntry(
    companyId: string,
    siteId: string,
    badgeLogEntry: BadgeLogEntry,
  ): Promise<void> {
    return await this.deleteBadgeReaderLogEntryUseCase.Call({
      companyId,
      siteId,
      badgeLogEntry,
    });
  }

  async linkBadgeReaderToSites(
    companyId: string,
    siteId: string,
    badgeReaderId: string,
    siteIds: string[]
  ): Promise<void> {
    return await this.linkBadgeReaderToSitesUseCase.Call({
      companyId,
      siteId,
      badgeReaderId,
      siteIds,
    });
  }

  async getSiteBadgesNoPaging(
    companyId: string,
    siteId: string
  ): Promise<Badge[]> {
    return await this.getSiteBadgesNoPagingUseCase.Call({ companyId, siteId });
  }
}

export { SiteBadgeReaderDetailViewModel };
