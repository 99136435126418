import UseCase from '../../../../core/app/usecase';
import Requirement from '../../../entities/requirement';
import SiteChemicalRepository from '../../../repositories/siteChemicalRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	chemicalId: string;
	requirements: {
		documentTypeId: string;
		isOptional: boolean;
		graceDays: number;
	}[],
};

class AddRequirementToSiteChemical implements UseCase<ARGS, Requirement> {
	private siteRepository: SiteChemicalRepository;

	constructor(siteRepository: SiteChemicalRepository) {
		this.siteRepository = siteRepository;
	}

	async Call(parameters: ARGS): Promise<Requirement> {
		return await this.siteRepository.addRequirementToSiteChemical(
			parameters.companyId,
			parameters.siteId,
			parameters.chemicalId,
			parameters.requirements
		);
	}
}

export default AddRequirementToSiteChemical;
