import { Alert, AlertDescription, Flex, CloseButton } from "@chakra-ui/react";
import { FC } from "react";

interface ErrorBannerProps {
  text: string;
  onClose?: () => void;
  borderRadius?: string;
}

const ErrorBanner: FC<ErrorBannerProps> = ({ text, onClose, borderRadius }) => {
  return (
    <Alert borderRadius={borderRadius} marginBottom={5} status="error">
      <AlertDescription>{text}</AlertDescription>
      {onClose && (
        <>
          <Flex grow={1} />
          <CloseButton onClick={onClose} />
        </>
      )}
    </Alert>
  );
};

export { ErrorBanner };
