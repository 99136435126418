import {
  makeGetSite,
  makeUpdateSite,
  makeUpdateSiteState,
} from "../../../core/app/factory/sites/usecases";
import Site, { SiteState } from "../../../domain/entities/site";
import {makeGetCustomFields} from "../../../core/app/factory/companies/usecases";
import {CustomField} from "../../../domain/entities/customFields";
import {RequirementSubject} from "../../../domain/entities/requirementSubject.enum";

class SiteDetailsViewModel {
  private getUC = makeGetSite();
  private updateUC = makeUpdateSite();
  private updateStateUC = makeUpdateSiteState();
  private getCustomFieldsUC = makeGetCustomFields();

  constructor(private companyId: string) {}

  // Public.
  async get(siteId: string): Promise<Site> {
    return await this.getUC.Call({ companyId: this.companyId, siteId });
  }

  async update(site: Site, image?: File): Promise<Site> {
    return await this.updateUC.Call({ companyId: this.companyId, site, image });
  }

  async updateState(siteId: string, state: SiteState): Promise<void> {
    await this.updateStateUC.Call({ companyId: this.companyId, siteId, state });
  }

  async getCustomFields(companyId: string): Promise<CustomField[]> {
    return await this.getCustomFieldsUC.Call({ companyId, subject: RequirementSubject.SITE });
  }
}

export default SiteDetailsViewModel;
