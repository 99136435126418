import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "../../Common/alerts/BaseModal";
import BadgeReader from "../../../../domain/entities/badgeReader";
import { BadgeReaderDirection } from "../../../../domain/entities/badgeReaderDirection.enum";
import {
  SimpleGrid,
  useClipboard,
  Button,
  Text,
  ModalFooter,
  InputGroup,
  Input,
  InputRightElement,
  Tooltip,
  Flex,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import FormInput from "../../Common/FormInput";
import { AllowedTimezones } from "../../../../domain/entities/AllowedTimezones.enum";
import { BadgeReaderGate } from "../../../../domain/entities/badgeReaderGate.enum";
import { FaInfoCircle } from "react-icons/fa";
import { COLORS } from "../../../assets/theme/colors";

interface CreateBadgeReaderModalProps {
  onClose: () => void;
  onConfirm: (badge: Partial<BadgeReader>) => Promise<BadgeReader>;
  getPath: (readerId: string) => string;
}

const CreateBadgeReaderModal = ({
  onClose,
  onConfirm,
  getPath,
}: CreateBadgeReaderModalProps) => {
  const { t } = useTranslation("badgeReaders");

  const DIRECTION_OPTIONS = Object.values(BadgeReaderDirection).map(
    (direction) => ({ id: direction, name: t(direction) }),
  );
  const TIMEZONE_OPTIONS = Object.keys(AllowedTimezones).map((timezone) => ({
    id: timezone,
    name: AllowedTimezones[timezone],
  }));
  const GATE_OPTIONS = Object.values(BadgeReaderGate).map((gate) => ({
    id: gate,
    name: t(gate),
  }));

  const {
    handleSubmit,
    register,
    formState,
    watch,
    setValue: setFormValue,
  } = useForm({
    defaultValues: {
      name: "",
      serial: "",
      delay: 0,
      direction: undefined,
      releConfiguration: undefined,
      timeZone:
        TIMEZONE_OPTIONS.find(
          (t) => t.id === Intl.DateTimeFormat().resolvedOptions().timeZone,
        )?.id ?? undefined,
    },
    mode: "onBlur",
    reValidateMode: "onChange",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { onCopy, value, setValue, hasCopied } = useClipboard(undefined);

  const onVerify = async (data: Partial<BadgeReader>) => {
    setIsLoading(true);
    const badgeReader = await onConfirm(data);
    setIsLoading(false);
    setValue(getPath(badgeReader.id));
  };

  const direction = watch("direction");

  return (
    <BaseModal
      title={t("createBadgeReader")}
      onClose={onClose}
      footer={
        <ModalFooter zIndex={"1"}>
          {!value && (
            <Button
              colorScheme="blue"
              onClick={handleSubmit(onVerify)}
              isLoading={isLoading}
            >

              {t("confirm", { ns: "common" })}
            </Button>
          )}
          <Button colorScheme="red" onClick={onClose} marginLeft={5}>
            {t(value ? "close" : "cancel", { ns: "common" })}
          </Button>
        </ModalFooter>
      }
    >
      <Text mb={5}>
        {t(
          value
            ? "badgeReaderCreatedDescription"
            : "createBadgeReaderDescription",
        )}
      </Text>

      {value ? (
        <InputGroup>
          <Input pr="5rem" value={value} readOnly />
          <InputRightElement width="5rem">
            <Button
              h="1.75rem"
              size="sm"
              onClick={onCopy}
              sx={{ textTransform: "uppercase", fontSize: "12px" }}
            >
              {t(hasCopied ? "copied" : "copy", { ns: "common" })}
            </Button>
          </InputRightElement>
        </InputGroup>
      ) : (
        <>
          <SimpleGrid columns={2} spacing={4}>
            <FormInput
              inputProps={register("name", {
                required: t("errors.nameRequired"),
              })}
              label={t("name")}
              isRequired
              error={formState.errors.name?.message ?? false}
              type="text"
            />
            <FormInput
              inputProps={register("serial", {
                required: t("errors.serialRequired"),
              })}
              label={t("badgeReadersSerial")}
              isRequired
              error={formState.errors.serial?.message ?? false}
              type="text"
            />
          </SimpleGrid>

          <FormInput
            selectProps={{
              ...register("direction", {
                required: t("errors.directionRequired"),
              }),
              placeholder: t("selectDirection"),
            }}
            items={DIRECTION_OPTIONS}
            label={t("columns.readerDirection")}
            isRequired
            error={(formState.errors.direction?.message as string) ?? false}
            type="select"
          />

          {direction === BadgeReaderDirection.BIDIRECTIONAL && (
            <Flex alignItems="center">
              <FormInput
                selectProps={{
                  ...register("releConfiguration", {}),
                  placeholder: t("selectEntranceGate"),
                }}
                items={GATE_OPTIONS}
                label={t("columns.entranceGate")}
                error={
                  (formState.errors.releConfiguration?.message as string) ??
                  false
                }
                type="select"
              />
              <Tooltip
                shouldWrapChildren
                label={
                  <ul>
                    <li>{t("infoReleConfigurations.noSelection")}</li>
                    <li>{t("infoReleConfigurations.R1ER2U")}</li>
                    <li>{t("infoReleConfigurations.R1UR2E")}</li>
                  </ul>
                }
              >
                <FaInfoCircle
                  color={COLORS.sikuroBlue}
                  style={{ marginLeft: "0.5rem", marginTop: "2rem" }}
                />
              </Tooltip>
            </Flex>
          )}

          <FormInput
            selectProps={{
              ...register("timeZone", {
                required: t("errors.timeZoneRequired"),
              }),
              placeholder: t("selectTimezone"),
            }}
            items={TIMEZONE_OPTIONS}
            label={t("columns.timezone")}
            isRequired
            error={formState.errors.timeZone?.message ?? false}
            type="select"
          />
          <FormInput
            inputProps={register("delay")}
            label={t("columns.delay")}
            type="number"
          />
        </>
      )}
    </BaseModal>
  );
};

export default CreateBadgeReaderModal;
