import { FC, ReactElement } from "react";
import { Input } from "@chakra-ui/react";
import BaseFormField, { EditableFormFieldProps } from "./FormField";

interface FormTextFieldProps extends EditableFormFieldProps {
  type?: "text" | "number";
  link?: string;
}

const FormTextField: FC<FormTextFieldProps> = ({
  type = "text", name, label, readOnly = false, isEditing = false,
  displayValue, rules, onChange, link, hideRequiredMark, applyConditionalStyles
}) => {
  const renderRawValue = (): string => {
    return displayValue;
  };
  const handleInputNumber = (event) => {
    // List of special keys that are allowed (such as backspace, delete, left/right arrow)
    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];
    const char = event.key;
    // Check if the character is not numeric or is not in the list of allowed special keys & block the event
    if (!isNumericChar(char) && !allowedKeys.includes(char)) {
      event.preventDefault();
    }
  };

  const isNumericChar = (char) => {
    // Check if the character is numeric (0-9)
    return /\d/.test(char);
  };

  const getType = (type: string): ReactElement => {
    switch (type) {
      case "number":
        return (
          <Input
            type="number"
            max="9999-12-31"
            onKeyDown={(e) => (type === "number" ? handleInputNumber(e) : null)}
          />
        );
      default:
        return <Input type="text" />;
    }
  };

  return (
    <BaseFormField
      renderRawValue={renderRawValue}
      edit={getType(type)}
      displayValue={displayValue}
      link={link}
      readOnly={readOnly}
      hideRequiredMark={hideRequiredMark}
      name={name}
      label={label}
      isEditing={isEditing}
      rules={rules}
      onChange={onChange}
      applyConditionalStyles={applyConditionalStyles}
    />
  );
};

export default FormTextField;
