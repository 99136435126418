import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { ResourceEvaluationState } from '../../entities/resourceEvaluationState.enum';
import { RequirementSubject } from '../../entities/requirementSubject.enum';

type Parameters = {
	companyId: string;
	supplierId: string;
	resourceId: string;
	value: ResourceEvaluationState;
	type: RequirementSubject;
};

class EvaluateCompanySupplierResource implements UseCase<Parameters, void> {
	constructor(private repository: SupplierRepository) {}

	async Call({ companyId, supplierId, resourceId, value, type }: Parameters): Promise<void> {
		await this.repository.evaluateCompanySupplierResource(companyId, supplierId, resourceId, value, type);
	}
}

export default EvaluateCompanySupplierResource;
