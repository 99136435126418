import { ChemicalDocumentsViewModel } from "../../../viewmodels/chemicals/ChemicalDocumentsViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { useResourceDocumentsViewModel } from "../useResourceDocumentsViewModel";

const useChemicalDocumentsViewModel = (resourceId: string) =>
  useResourceDocumentsViewModel(
    new ChemicalDocumentsViewModel(resourceId),
    DocumentTypeCategory.CHEMICAL,
    resourceId,
  );

export default useChemicalDocumentsViewModel;
