import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import Document from '../../entities/document';
import { ResourceDocumentEvaluationState } from '../../entities/resourceDocumentEvaluationState.enum';
import FileEntity from '../../entities/file';

type ARGS = {
	companyId: string;
	supplierId: string;
	isPublic: boolean;
	documentTypeId: string;
	siteIds: string[];
	result?: ResourceDocumentEvaluationState;
	expiresAt?: Date;
	files: FileEntity;
};

class CreateSupplierDocument implements UseCase<ARGS, Document[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, isPublic, documentTypeId, siteIds, result, expiresAt, files }: ARGS): Promise<Document[]> {
		return await this.supplierRepository.createSupplierDocument(companyId, supplierId, isPublic, documentTypeId, siteIds, result, expiresAt, files);
	}
}

export default CreateSupplierDocument;
