import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SiteOptions from "../../../domain/entities/siteOptions";
import SiteMainViewModel from "../../viewmodels/sites/SiteMainViewModel";

const useSiteOptionsViewModel = (siteId: string, isWorkingSite?: boolean) => {
  const { companyId } = useAuth();
  const viewModel = new SiteMainViewModel(companyId, siteId);

  const getSiteQuery = useQuery(
    ["get-site-details", companyId, siteId],
    async () => await viewModel.get()
  );

  const getOptionsQuery = useQuery<SiteOptions>(
    ["get-site-options", companyId, siteId],
    async () => await viewModel.getSiteOptions(),
    {
      enabled: !isWorkingSite,
    }
  );

  return {
    details: getSiteQuery.data,
    options: getOptionsQuery.data,
  };
};

export default useSiteOptionsViewModel;
