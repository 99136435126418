import { SupplierWorker, WorkerParams } from '../../../domain/entities/siteWorker';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { SupplierResourcesViewModel } from '../../hooks/Suppliers/useSupplierResourcesViewModel';
import { GetSupplierWorkersFilters } from '../../../domain/repositories/supplierRepository';
import {
	makeGetCompanySupplierWorkers,
	makeGetSpecializationsForCompanySupplierRequirements,
	makeGetVariantsForCompanySupplierRequirements,
} from '../../../core/app/factory/suppliers/useCases';
import Variant from '../../../domain/entities/variant';
import Specialization from '../../../domain/entities/specialization';
import { RequirementSubject } from '../../../domain/entities/requirementSubject.enum';

class SupplierWorkersViewModel implements SupplierResourcesViewModel<WorkerParams> {
	async getSupplierResources(
		companyId: string,
		supplierId: string,
		page: number,
		filter?: GetSupplierWorkersFilters,
		sort?: SortMeta,
	): Promise<PaginatedResults<SupplierWorker>> {
		return await makeGetCompanySupplierWorkers().Call({ companyId, supplierId, page, filter, sort });
	}

	async getVariants(companyId: string, supplierId: string): Promise<Variant[]> {
		return await makeGetVariantsForCompanySupplierRequirements().Call({ companyId, supplierId, subject: RequirementSubject.WORKER });
	}

	async getSpecializations(companyId: string, supplierId: string): Promise<Specialization[]> {
		return await makeGetSpecializationsForCompanySupplierRequirements().Call({ companyId, supplierId, subject: RequirementSubject.WORKER });
	}
}

export default SupplierWorkersViewModel;
