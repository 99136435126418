import UseCase from '../../../core/app/usecase';
import SupplierRepository, { GetGlobalSupplierFilters } from '../../repositories/supplierRepository';
import Company from '../../entities/company';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
type ARGS = {
	companyId: string;
	filter?: GetGlobalSupplierFilters;
	sort?: SortMeta;
	pageParam?: number;
};
class GetCompanySuppliers implements UseCase<ARGS, Company[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, filter, sort, pageParam }: ARGS): Promise<Company[]> {
		return await this.supplierRepository.getCompanySuppliers(companyId, filter, sort, pageParam);
	}
}

export default GetCompanySuppliers;
