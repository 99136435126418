import {
  makeCloneRequirementsGroup,
  makeCreateRequirementsGroup,
  makeDeleteRequirementsGroup,
  makeGetRequirementsGroups,
  makeUpdateRequirementsGroup,
} from "../../../core/app/factory/requirementsGroup/useCases";
import RequirementsGroup from "../../../domain/entities/requirementsGroup";

class CompanyRequirementsGroupViewModel {
  async createRequirementsGroups(
    companyId: string,
    requirementsGroup: RequirementsGroup,
  ): Promise<RequirementsGroup> {
    return await makeCreateRequirementsGroup().Call({
      companyId,
      requirementsGroup,
    });
  }

  async getRequirementsGroups(companyId: string): Promise<RequirementsGroup[]> {
    return await makeGetRequirementsGroups().Call(companyId);
  }

  async updateRequirementsGroup(
    companyId: string,
    requirementsGroup: RequirementsGroup,
  ): Promise<RequirementsGroup> {
    return await makeUpdateRequirementsGroup().Call({
      companyId,
      requirementsGroup,
    });
  }

  async deleteRequirementGroup(
    companyId: string,
    requirementsGroupId: string,
  ): Promise<void> {
    return await makeDeleteRequirementsGroup().Call({
      companyId,
      requirementsGroupId,
    });
  }
  async cloneRequirementGroup(
    companyId: string,
    requirementsGroupId: string,
    requirementsGroupName: string,
  ): Promise<void> {
    return await makeCloneRequirementsGroup().Call({
      companyId,
      requirementsGroupId,
      requirementsGroupName,
    });
  }
}

export default CompanyRequirementsGroupViewModel;
