import { UpdatePasswordPayload } from './args';
import UseCase from '../../../core/app/usecase';
import { AuthRepository, makeAuthRepository } from '../../repositories/authRepository';
import { UpdatePasswordResponse } from '../../../infrastructure/responses/auth';

export class UpdatePassword implements UseCase<UpdatePasswordPayload, UpdatePasswordResponse> {
	constructor(private repository: AuthRepository) {}

	async Call(input: UpdatePasswordPayload): Promise<UpdatePasswordResponse> {
		return this.repository.updatePassword(input.oldPassword, input.password, input.confirmPassword);
	}
}

export const makeUpdatePassword = (): UpdatePassword => {
	return new UpdatePassword(makeAuthRepository());
};
