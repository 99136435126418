import { FC, useState } from "react";
import { Alert } from "../Common/Alert";
import { useTranslation } from "react-i18next";
import OnboardingHeader from "./OnboardingHeader";
import OnboardingFooter from "./OnboardingFooter";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import TextField from "../../components/Common/TextField";
import useOnboardingViewModel from "../../hooks/Onboarding/useOnboardingViewModel";
import {
  CardHeader,
  CardBody,
  Button,
  CardFooter,
  VStack,
  HStack,
  Spacer,
} from "@chakra-ui/react";

interface SignInProps {
  onCancel: () => void;
  onSelectRequestPasswordReset?: () => void;
}

const SignIn: FC<SignInProps> = ({
  onCancel,
  onSelectRequestPasswordReset,
}) => {
  const { t } = useTranslation("onboarding");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { error, setError, login, isLoading } = useOnboardingViewModel();

  return (
    <>
      <CardHeader>
        <OnboardingHeader
          title={t("ui.sign in")}
          onBack={() => {
            onCancel();
            setEmail("");
            setPassword("");
            setShowPassword(false);
          }}
        />
      </CardHeader>

      <CardBody>
        <TextField
          id="email"
          defaultValue={email}
          label={t("ui.email")}
          onChange={(_, value) => setEmail(value)}
        />

        <TextField
          id="password"
          isProtected={!showPassword}
          marginTop={8}
          label={t("ui.password")}
          defaultValue={password}
          onChange={(_, value) => setPassword(value)}
          onEnter={() => login({ email, password })}
          actions={[
            {
              icon: showPassword ? <FaEyeSlash /> : <FaEye />,
              hint: t(showPassword ? "hide" : "slash"),
              handler: () => setShowPassword(!showPassword),
            },
          ]}
        />
      </CardBody>

      <CardFooter>
        <VStack width="100%" spacing={8}>
          <Button
            isLoading={isLoading}
            isDisabled={!password || !email}
            variant="ghost"
            color="white"
            _hover={{ bg: "#32A2DB", color: "#FFF" }}
            textColor="#32A2DB"
            onClick={() => login({ email, password })}
          >
            {t("ui.sign in", { ns: "onboarding" })}
          </Button>

          <HStack>
            <Spacer />
            <Button
              fontSize="xs"
              variant="link"
              color="#32A2DB"
              onClick={onSelectRequestPasswordReset}
            >
              {t("ui.requestPasswordReset")}
            </Button>
            <Spacer />
          </HStack>

          <OnboardingFooter />
        </VStack>
      </CardFooter>

      {error && (
        <Alert
          title={t("apiErrors.cannotLogin")}
          message={t(`apiErrors.${error}`)}
          onClose={() => setError("")}
          variant="warning"
        />
      )}
    </>
  );
};

export default SignIn;
