import UseCase from '../../../../core/app/usecase';
import { SiteChemical } from '../../../entities/siteChemical';
import SiteChemicalRepository from '../../../repositories/siteChemicalRepository';
import { GetSiteChemicalsFilters } from '../../../repositories/siteChemicalRepository';
import { PaginatedMeta, PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';

type Parameters = {
	companyId: string;
	siteId: string;
	archived?: boolean;
	filter?: GetSiteChemicalsFilters;
	sort?: SortMeta;
	supplierId?: string;
} & PaginatedMeta;

class GetSiteChemicals implements UseCase<Parameters, PaginatedResults<SiteChemical> | undefined> {
	constructor(private repository: SiteChemicalRepository) {}

	async Call({
		companyId,
		siteId,
		sort,
		perPage,
		archived,
		filter,
		page,
		supplierId,
	}: Parameters): Promise<PaginatedResults<SiteChemical> | undefined> {
		return this.repository.getSiteChemicals(companyId, siteId, page, perPage, archived, filter, sort, supplierId);
	}
}

export default GetSiteChemicals;
