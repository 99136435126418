import UseCase from '../../../../core/app/usecase';
import ChemicalRepository from '../../../repositories/chemicalRepository';

type Args = {
	companyId: string;
	chemicalId: string;
};

class RestoreChemical implements UseCase<Args, void> {
	constructor(private repository: ChemicalRepository) {}

	async Call({ companyId, chemicalId }: Args): Promise<void> {
		return await this.repository.restoreChemical(companyId, chemicalId);
	}
}

export default RestoreChemical;
