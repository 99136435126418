import Site from '../../entities/site';
import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';

interface Args {
	companyId: string;
	siteId: string;
}

class GetSite implements UseCase<Args, Site> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, siteId }: Args): Promise<Site> {
		return await this.siteRepository.getSite(companyId, siteId);
	}
}

export default GetSite;
