import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import Document, { SiteResourceDocument } from '../../entities/document';
import { ResourceDocumentEvaluationState } from '../../entities/resourceDocumentEvaluationState.enum';
import FileEntity from '../../entities/file';

type ARGS = {
	companyId: string;
	supplierId: string;
	document: Document;
	siteIds: string[];
	target: string;
	resourceId: string;
};

class UpdateSupplierResourceDocument implements UseCase<ARGS, Document[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, document, siteIds, target, resourceId }: ARGS): Promise<Document[]> {
		return await this.supplierRepository.updateSupplierResourceDocument(companyId, supplierId, document, target, resourceId, siteIds);
	}
}

export default UpdateSupplierResourceDocument;
