import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { SupplierWorker } from '../../entities/siteWorker';
type ARGS = {
	companyId: string;
	supplierId: string;
	resourceId: string;
};
class GetCompanySupplierWorker implements UseCase<ARGS, SupplierWorker> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, resourceId }: ARGS): Promise<SupplierWorker> {
		return await this.supplierRepository.getCompanySupplierWorker(companyId, supplierId, resourceId);
	}
}

export default GetCompanySupplierWorker;
