import UseCase from '../../../../core/app/usecase';
import SiteVehicleRepository from '../../../repositories/siteVehicleRepository';
import Evaluation from '../../../entities/evaluation';
import { ResourceEvaluationState } from '../../../entities/resourceEvaluationState.enum';

type Parameters = {
	companyId: string;
	siteId: string;
	vehicleId: string;
	value: ResourceEvaluationState;
};

class EvaluateSiteVehicle implements UseCase<Parameters, Evaluation<ResourceEvaluationState> | undefined> {
	constructor(private repository: SiteVehicleRepository) {}

	async Call({ companyId, siteId, vehicleId, value }: Parameters): Promise<Evaluation<ResourceEvaluationState> | undefined> {
		return this.repository.evaluateSiteVehicle(companyId, siteId, vehicleId, value);
	}
}

export default EvaluateSiteVehicle;
