import { injectable } from 'tsyringe';
import TypologyRepository from '../../domain/repositories/typologyRepository';
import Typology from '../../domain/entities/typology';
import { DocumentTypeCategory } from '../../domain/entities/documentTypeCategory.enum';
import { ApiService } from '../utilities/apiService';

@injectable()
class ServerTypologyRepository implements TypologyRepository {
	constructor(private apiService: ApiService) {}

	async getTypologies(companyId: string, type: DocumentTypeCategory, search?: string): Promise<Typology[]> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/typologies?type=${type}${search ? `&search=${search}` : ''}`,
		);
		const payload = await response.json();
		return payload.results ?? [];
	}

	async createTypology(companyId: string, typology: Typology): Promise<Typology> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/typologies`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(typology),
		});
		return response.json();
	}

	async getResourceTypologiesAssociated(companyId: string, resourceId: string, type: DocumentTypeCategory): Promise<Typology> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${type}s/${resourceId}/typologies`,
		);
		const payload = await response.json();
		return payload;
	}
}

export default ServerTypologyRepository;
