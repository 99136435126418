import UseCase from '../../../../core/app/usecase';
import Document from '../../../entities/document';
import ChemicalRepository from '../../../repositories/chemicalRepository';

type CreateChemicalDocumentParameters = {
	companyId: string;
	resourceId: string;
	documents: string[];
};

/**
 * Returns the list of all the existing Document.
 */
class CreateChemicalDocument implements UseCase<CreateChemicalDocumentParameters, void> {
	constructor(private repository: ChemicalRepository) {}

	async Call({ companyId, resourceId, documents }: CreateChemicalDocumentParameters): Promise<void> {
		return await this.repository.createChemicalDocument(companyId, resourceId, documents);
	}
}

export default CreateChemicalDocument;
