export const downloadFileFromURL = async (url: string, token?: string) => {
	try {
		let downloadUrl = url;
		let fileName: string;
		if (token) {
			const response = await fetch(url, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const blob = await response.blob();
			const contentDisposition = response && response.headers.get('Content-Disposition');

			if (contentDisposition) {
				const fileNameFromHeader = getFileNameFromContentDisposition(contentDisposition);
				if (fileNameFromHeader) {
					fileName = fileNameFromHeader;
				}
			}
			downloadUrl = window.URL.createObjectURL(blob);
		}
		createAndClickLink(downloadUrl, fileName);
	} catch (error) {
		console.error('There was an error with the download', error);
	}
};

const createAndClickLink = (url: string, fileName) => {
	const link = document.createElement('a');
	link.download = fileName ?? 'download-file';
	link.href = url;
	link.target = '_blank';

	document.body.append(link);
	link.click();
	link.remove();

	if (url.startsWith('blob:')) {
		window.URL.revokeObjectURL(url);
	}
};

const getFileNameFromContentDisposition = (contentDisposition: string): string | null => {
	const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
	if (match && match[1]) {
		return match[1].replace(/['"]/g, '');
	}
	return null;
};
