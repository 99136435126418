import {
  Box,
  Flex,
  IconButton,
  Text,
  Link,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AiOutlineCloudDownload } from "react-icons/ai";
import {
  DocumentFileLogEntry,
  DocumentFileLogEntryAction,
} from "../../../../domain/entities/documentLogEntry";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { GetDocumentLogFilter } from "../../../../domain/repositories/documentRepository";
import { formatDateBasedOnLanguage } from "../../../../utils";
import { UpdateFilter } from "../../../hooks/Site/useSiteResourceDetailViewModel";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import InfiniteTable from "../../Common/table/InfiniteTable";
import SkeletonTableRow from "../../Common/table/SkeletonTableRow";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import { ReactNode } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { COLORS } from "../../../assets/theme/colors";
import { useMediaQuery } from "@chakra-ui/react";

type DocumentHistoryTableProps = {
  documentFileLogs: DocumentFileLogEntry[];
  updateFilterDocumentLog: UpdateFilter;
  filterDocumentLog: GetDocumentLogFilter;
  sortDocumentLog: SortMeta;
  setSortDocumentLog: (sort: SortMeta) => void;
  documentLogsFetching: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  autosize?: boolean;
};

const DocumentHistoryTable = ({
  documentFileLogs,
  updateFilterDocumentLog,
  filterDocumentLog,
  sortDocumentLog,
  setSortDocumentLog,
  documentLogsFetching,
  hasNextPage,
  fetchNextPage,
  autosize,
}: DocumentHistoryTableProps) => {
  const { t } = useTranslation("documents");
  const logColumns = [
    "actionDate",
    "author",
    "fileName",
    "actionType",
    "expiresAt",
  ];
  const selectOptions = {
    actionType: Object.values(DocumentFileLogEntryAction).map((state) => ({
      option: state,
      label: t(`documentLogAction.${state}`, { ns: "enum" }),
    })),
  };

  const filterColumnTypes = {
    actionDate: "date-range",
    fileName: "text",
    actionType: "select",
    expiresAt: "date-range",
  };
  const excludeFilters = ["expiresAt"];

  const updateCell = (log: DocumentFileLogEntry): ReactNode => {
    const prev = log.oldExpiresAt
      ? formatDateBasedOnLanguage(log.oldExpiresAt)
      : "-";
    const next = log.expiresAt ? formatDateBasedOnLanguage(log.expiresAt) : "-";
    return (
      <Flex alignItems={"center"} justifyContent={"center"}>
        {prev === "-" && next === "-" ? (
          "-"
        ) : (
          <>
            <Box minWidth={"90px"} textAlign={"center"}>
              {prev}
            </Box>
            <ChevronRightIcon color={COLORS.sikuroBlue} />
            <Box minWidth={"90px"} textAlign={"center"}>
              {next}
            </Box>
          </>
        )}
      </Flex>
    );
  };
  const cell = (field: string, log: DocumentFileLogEntry): ReactNode => {
    switch (field) {
      case "actionDate":
        return `${formatDateBasedOnLanguage(log[field] as unknown as string, true)}`;
      case "actionType":
        return t(log[field]);
      case "fileName":
        return (
          <Tooltip label={`${log.name}`}>
            <Flex>
              <Text
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
              >
                {log?.name}
              </Text>
              <span>
                <Link
                  href={log?.uri}
                  isExternal
                  _hover={{ textDecoration: "none" }}
                >
                  <IconButton
                    aria-label="download"
                    icon={<AiOutlineCloudDownload />}
                    variant="ghost"
                  />
                </Link>
              </span>
            </Flex>
          </Tooltip>
        );
      case "expiresAt":
        return updateCell(log);
      case "author":
        return log?.author;
      default:
        return "";
    }
  };

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  return (
    <>
      <Flex
        flexDirection={"column"}
        alignItems={"start"}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        width={isMobile ? "900px" : "100%"}
        position="relative"
        overflow={"hidden"}
        marginTop={4}
      >
        <InfiniteTable
          autosize={autosize}
          infiniteScroll={{
            dataLength: documentFileLogs?.length || 0,
            hasNextPage,
            fetchNextPage,
          }}
          isLoading={documentLogsFetching}
          showEmptyText={documentFileLogs?.length === 0}
          emptyText={t("noDocuments", { ns: "documents" })}
        >
          <Thead>
            <Tr>
              {logColumns.map((key) => (
                <Th key={key}>
                  <TableColumnHeader
                    text={t(key)}
                    filter={
                      excludeFilters.includes(key)
                        ? undefined
                        : {
                            isActive: !!(Array.isArray(filterDocumentLog[key])
                              ? filterDocumentLog[key][1]
                              : filterDocumentLog[key]),
                            component: (
                              <ColumnFilterComponent
                                selectOptions={
                                  selectOptions[key]
                                    ? [...selectOptions[key]]
                                    : undefined
                                }
                                type={filterColumnTypes[key]}
                                value={filterDocumentLog[key] ?? undefined}
                                updateFilter={(value) =>
                                  updateFilterDocumentLog(key, value)
                                }
                              />
                            ),
                          }
                    }
                    sort={
                      excludeFilters.includes(key)
                        ? undefined
                        : {
                            handler: (direction) =>
                              setSortDocumentLog({ field: key, direction }),
                            direction:
                              sortDocumentLog?.field === key
                                ? sortDocumentLog.direction
                                : undefined,
                          }
                    }
                  />
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {Array.isArray(documentFileLogs) && !documentLogsFetching ? (
              documentFileLogs?.map((log) => {
                return (
                  <Tr key={Math.random()}>
                    {logColumns.map((key) => (
                      <Td key={key}>{cell(key, log)}</Td>
                    ))}
                  </Tr>
                );
              })
            ) : (
              <SkeletonTableRow
                columnNumber={4}
                rowNumber={3}
                hasAction={false}
              />
            )}
          </Tbody>
        </InfiniteTable>
      </Flex>
    </>
  );
};

export default DocumentHistoryTable;
