import UseCase from '../../../core/app/usecase';
import Document from '../../entities/document';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import DocumentRepository from '../../repositories/documentRepository';

type ResourceDocumentParameters = {
	companyId: string;
	documentId: string;
	siteId?: string;
	resourceId?: string;
	target?: DocumentTypeCategory;
};

/**
 * Delete the selected Docuemnt
 *
 * @param id the identifier of the document to delete
 */
class DeleteResourceDocument implements UseCase<ResourceDocumentParameters, Document[]> {
	constructor(private repository: DocumentRepository) {}

	async Call({ companyId, documentId, siteId, resourceId, target }: ResourceDocumentParameters): Promise<Document[]> {
		return await this.repository.deleteResourceDocument(companyId, documentId, siteId, resourceId, target);
	}
}

export default DeleteResourceDocument;
