import { BadgeType } from '../badgeType.enum';

export interface PaginatedResults<Result> {
	results: Result[];
	pagination: PaginatedMeta;
	count?: number;
	badgesCount?: number;
  badgeType?: BadgeType;
  message?: string;
}

export interface PaginatedMeta {
	page: number;
	perPage: number;
}

export interface SortMeta {
	field: string;
	direction: SortDirection;
}

export enum SortDirection {
	Descending = 'DESC',
	Ascending = 'ASC',
}
