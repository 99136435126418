import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  DocumentActionLogEntry,
  DocumentFileLogEntry,
} from "../../../domain/entities/documentLogEntry";
import { RenameModel } from "../../../domain/entities/documentType";
import FileEntity from "../../../domain/entities/file";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import Site, { DocumentSites } from "../../../domain/entities/site";
import {
  GetDocumentFilesFilter,
  GetDocumentLogFilter,
} from "../../../domain/repositories/documentRepository";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import { updateFilterWithDelete } from "../../../utils";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { DocumentTypesListViewModel } from "../../viewmodels/documentTypes/DocumentTypesListViewModel";
import { UpdateFilter } from "../Site/useSiteResourceDetailViewModel";

export interface DocumentInfoViewModel {
  getDocumentFiles: (
    companyId: string,
    documentId: string,
    filter: GetDocumentFilesFilter,
    sort: SortMeta,
    siteId?: string,
    target?: string,
    resourceId?: string,
  ) => Promise<FileEntity[]>;
  deleteDocumentFile: (
    companyId: string,
    documentId: string,
    fileId: string,
    siteId?: string,
    target?: string,
    resourceId?: string,
    siteIds?: string[],
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ) => Promise<void>;
  addFileToDocument: (
    companyId: string,
    documentId: string,
    file: FileEntity,
    siteId?: string,
    target?: string,
    resourceId?: string,
    siteIds?: string[],
    toSupplier?: boolean,
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ) => Promise<void>;
  repropagateFileToDocument: (
    companyId: string,
    documentId: string,
    siteId?: string,
    target?: string,
    resourceId?: string,
    siteIds?: string[],
    toSupplier?: boolean,
    selectAll?: boolean,
    selectAllFiles?: boolean,
    fileIds?: string[],
    filters?: GetSitesFilter,
  ) => Promise<void>;
  updateFile(
    companyId: string,
    documentId: string,
    fileId: string,
    updatedFiles: Partial<FileEntity>[],
    siteIds?: string[],
    target?: string,
    resourceId?: string,
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ): Promise<void>;

  getDocumentLogs: (
    companyId: string,
    documentId: string,
    filter: GetDocumentLogFilter,
    sort: SortMeta,
    resourceId?: string,
    siteId?: string,
    target?: string,
    pageParam?: number,
  ) => Promise<DocumentFileLogEntry[]>;
  getDocumentFilesLogs: (
    companyId: string,
    documentId: string,
    filter?: GetDocumentLogFilter,
    sort?: SortMeta,
    pageParam?: number,
  ) => Promise<DocumentActionLogEntry[]>;
  getDocumentSites: (
    companyId: string,
    documentId: string,
    sortSites: SortMeta,
    filterSites: GetSitesFilter,
    pageParam: number,
    includeWorkingSites?: boolean,
    supplierId?: string,
  ) => Promise<DocumentSites>;
}

export type UseDocumentInfo = {
  documentFiles: FileEntity[];
  refetchDocumentFiles: () => void;
  documentFileLogs: DocumentFileLogEntry[];
  documentActionLogs: DocumentActionLogEntry[];
  setHookDocumentId: (documentId: string) => void;
  setDocumentTypeId: (documentId: string) => void;
  handleDocumentTypeId: (documentId: string) => Promise<void>;
  documentLogsFetching: boolean;
  documentFileLogsFetching: boolean;
  documentFilesFetching: boolean;
  fileLogsHasNextPage?: boolean;
  fileLogsFetchNextPage?: () => void;
  docLogsHasNextPage?: boolean;
  docLogsFetchNextPage?: () => void;
  addFileToDocument: (
    documentId: string,
    file: FileEntity,
    siteIds?: string[],
    toSupplier?: boolean,
    selectAll?: boolean,
    filters?: GetSitesFilter
  ) => Promise<void>;
  repropagateFileDocument: (
    documentId: string,
    siteIds?: string[],
    toSupplier?: boolean,
    selectAll?: boolean,
    selectAllFiles?: boolean,
    fileIds?: string[],
    filters?: GetSitesFilter,
  ) => Promise<void>;
  deleteDocumentFile: (
    documentId: string,
    fileId: string,
    siteIds?: string[],
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ) => Promise<void>;
  updateFile(
    documentId: string,
    fileId: string,
    updatedFiles: Partial<FileEntity>[],
    siteIds?: string[],
    selectAll?: boolean,
    filters?: GetSitesFilter
  ): Promise<void>;
  updateFilterFiles: UpdateFilter;
  updateFilterDocumentLog: UpdateFilter;
  filterFiles: GetDocumentFilesFilter;
  filterDocumentLog: GetDocumentLogFilter;
  sortFiles: SortMeta;
  sortDocumentLog: SortMeta;
  setSortFiles: (sort: SortMeta) => void;
  setSortDocumentLog: (sort: SortMeta) => void;
  errorMsg: string;
  setErrorMsg: (msg: string) => void;
  successMsg: string;
  setSuccessMsg: (msg: string | null) => void;
  isAddingFile: boolean;
  documentSites: Site[];
  isFetchingSites: boolean;
  filterSites: GetSitesFilter;
  setFilterSites?: (filter: GetSitesFilter) => void;
  updateFilterSites: UpdateFilter;
  setSortSites: (sort: SortMeta) => void;
  sortSites: SortMeta;
  updateDocumentIsPublic?: (isPublic: boolean, expirationDate: Date) => void;
  setEnableGetDocLogs: (val: boolean) => void;
  setEnableGetFileLogs: (val: boolean) => void;
  deleteIsLoading: boolean;
  hasSites: (action: string, documentTypeId?: string) => Promise<boolean>;
  hasSitesNextPage: boolean;
  fetchSitesNextPage: () => void;
  renameModel: RenameModel;
  refetchRenameModel: () => void;
  totalSiteCount: number;
};

function useDocumentInfo(
  viewModel: DocumentInfoViewModel,
  siteId?: string,
  target?: string,
  resourceId?: string,
): UseDocumentInfo {
  const { companyId } = useAuth();
  const { t } = useTranslation();
  const { supplierId } = useParams();
  const vm = useMemo(() => new DocumentTypesListViewModel(), []);
  const [documentId, setDocumentId] = useState<string>(null);
  const [documentTypeId, setDocumentTypeId] = useState<string>(null);
  const [filterDocumentLog, setFilterDocumentLog] =
    useState<GetDocumentLogFilter>({});
  const [filterFiles, setFilterFiles] = useState<GetDocumentFilesFilter>({});
  const [sortFiles, setSortFiles] = useState<SortMeta>();
  const [sortDocumentLog, setSortDocumentLog] = useState<SortMeta>();
  const [errorMsg, setErrorMsg] = useState<string>();
  const [successMsg, setSuccessMsg] = useState<string>();
  const [filterSites, setFilterSites] = useState<GetSitesFilter>();
  const [sortSites, setSortSites] = useState<SortMeta>();
  const [enableGetDocLogs, setEnableGetDocLogs] = useState(false);
  const [enableGetFileLogs, setEnableGetFileLogs] = useState(false);
  const act = useRef("");

  const {
    data: documentFiles,
    isFetching: documentFilesFetching,
    refetch: refetchDocumentFiles,
  } = useQuery(
    ["document-files", documentId, filterFiles, sortFiles],
    async () => {
      const result = documentId
        ? await viewModel.getDocumentFiles(
          companyId,
          documentId,
          filterFiles,
          sortFiles,
          siteId,
          target,
          resourceId,
        )
        : Promise.resolve([]);
      return result;
    },
    {
      // this is to avoid to trigger this query if we just want to get the file logs
      enabled: !enableGetFileLogs,
    },
  );

  const reloadFiles = async () => {
    await refetchDocumentFiles();
  };

  const {
    data: docFileLogs,
    isFetching: documentLogsFetching,
    error: logDocumentsError,
    refetch: refetchDocumentLogs,
    hasNextPage: fileLogsHasNextPage,
    fetchNextPage: fileLogsFetchNextPage,
  } = useInfiniteQuery(
    ["document-log", documentId, filterDocumentLog, sortDocumentLog],
    async ({ pageParam = 1 }) => {
      const result = documentId
        ? viewModel.getDocumentLogs(
          companyId,
          documentId,
          filterDocumentLog,
          sortDocumentLog,
          resourceId,
          siteId,
          target,
          pageParam,
        )
        : [];
      return result;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: !!documentId && enableGetFileLogs,
    },
  );

  const {
    data: documentLogs,
    isLoading: documentFileLogsFetching,
    error: documentFileLogsError,
    refetch: refetchDocumentFileLogs,
    hasNextPage: docLogsHasNextPage,
    fetchNextPage: docLogsFetchNextPage,
  } = useInfiniteQuery(
    ["document-file-logs", documentId, filterDocumentLog, sortDocumentLog],
    async ({ pageParam = 1 }) => {
      const result = await viewModel.getDocumentFilesLogs(
        companyId,
        documentId,
        filterDocumentLog,
        sortDocumentLog,
        pageParam,
      );
      return result;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      initialData: { pages: [], pageParams: [] },
      enabled: !!documentId && enableGetDocLogs,
    },
  );

  const addFileToDocumentMutation = useMutation(
    ["add-file-to-document"],
    async (params: {
      documentId: string;
      file: FileEntity;
      siteIds: string[];
      toSupplier?: boolean;
      selectAll?: boolean;
      filters?: GetSitesFilter,
    }) => {
      await viewModel.addFileToDocument(
        companyId,
        params.documentId,
        params.file,
        siteId ? siteId : supplierId,
        target,
        resourceId,
        params.siteIds,
        params.toSupplier,
        params.selectAll,
        params.filters,
      );
    },
    {
      onSuccess: async () => {
        setSuccessMsg(t("uploadSuccess", { ns: "common" }));
        await reloadFiles();
      },
      onError: async (err: Error) => {
        setErrorMsg(err.message);
        await reloadFiles();
        console.error(err);
      },
    },
  );


  const repropagateFileToDocumentMutation = useMutation(
    ["add-file-to-document"],
    async (params: {
      documentId: string;
      siteIds: string[];
      toSupplier?: boolean;
      selectAll?: boolean;
      selectAllFiles?: boolean;
      fileIds?: string[];
      filters?: GetSitesFilter,
    }) => {
      await viewModel.repropagateFileToDocument(
        companyId,
        params.documentId,
        siteId ? siteId : supplierId,
        target,
        resourceId,
        params.siteIds,
        params.toSupplier,
        params.selectAll,
        params.selectAllFiles,
        params.fileIds,
        params.filters
      );
    },
    {
      onSuccess: async () => {
        setSuccessMsg(t("repropagationSuccess", { ns: "common" }));
        await reloadFiles();
      },
      onError: async (err: Error) => {
        setErrorMsg(err.message);
        await reloadFiles();
        console.error(err);
      },
    },
  );


  const deleteDocumentFileMutation = useMutation(
    ["delete-document-file"],
    async (params: {
      documentId: string;
      fileId: string;
      siteIds?: string[];
      selectAll?: boolean;
      filters?: GetSitesFilter;
    }) => {
      await viewModel.deleteDocumentFile(
        companyId,
        params.documentId,
        params.fileId,
        siteId,
        target,
        resourceId,
        params.siteIds,
        params.selectAll,
        params.filters,
      );
    },
    {
      onSuccess: () => {
        reloadFiles();
      },
      onError: (err) => {
        reloadFiles();
        console.error(err);
      },
    },
  );

  const updateFileMutation = useMutation(
    async (args: {
      documentId: string;
      fileId: string;
      updatedFiles: Partial<FileEntity>[];
      siteIds?: string[];
      selectAll?: boolean;
      filters?: GetSitesFilter
    }) => {
      await viewModel.updateFile(
        companyId,
        args.documentId,
        args.fileId,
        args.updatedFiles,
        args.siteIds,
        target,
        resourceId,
        args.selectAll,
        args.filters
      );
    },
    {
      onSuccess: () => {
        reloadFiles();
      },
      onError: (err) => {
        reloadFiles();
        console.error(err);
      },
    },
  );

  const addFileToDocument = (
    documentId: string,
    file: FileEntity,
    siteIds?: string[],
    toSupplier?: boolean,
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ) =>
    addFileToDocumentMutation.mutateAsync({
      documentId,
      file,
      siteIds,
      toSupplier,
      selectAll,
      filters,
    });

    const repropagateFileDocument = (
      documentId: string,
      siteIds?: string[],
      toSupplier?: boolean,
      selectAll?: boolean,
      selectAllFiles?: boolean,
      fileIds?: string[],
      filters?: GetSitesFilter,
    ) =>
      repropagateFileToDocumentMutation.mutateAsync({
        documentId,
        siteIds,
        toSupplier,
        selectAll,
        selectAllFiles,
        fileIds,
        filters
      });

  const deleteDocumentFile = (
    documentId: string,
    fileId: string,
    siteIds?: string[],
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ) =>
    deleteDocumentFileMutation.mutateAsync({
      documentId,
      fileId,
      siteIds,
      selectAll,
      filters,
    });

  const updateFile = async (
    documentId: string,
    fileId: string,
    updatedFiles: Partial<FileEntity>[],
    siteIds?: string[],
    selectAll?: boolean,
    filters?: GetSitesFilter
  ) => {
    {
      return await updateFileMutation.mutateAsync({
        documentId,
        fileId,
        updatedFiles,
        siteIds,
        selectAll,
        filters,
      });
    }
  };

  const getDocumentSites = useInfiniteQuery(
    ["document-sites", companyId, documentTypeId, sortSites, filterSites, supplierId],
    async ({ pageParam = 1 }) => {
      const includeWorkingSites = act.current === "add" || act.current === 'repropagateFiles';
      return await viewModel.getDocumentSites(
        companyId,
        documentTypeId,
        sortSites,
        filterSites,
        pageParam,
        includeWorkingSites,
        supplierId
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.results?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: !!sortSites || !!filterSites,
    },
  );

  const hasSites = async (action: string) => {
    act.current = action;
    const data = await getDocumentSites.refetch();
    const sites = data.data?.pages.flatMap((page) => page.results) ?? [];
    return sites.length > 0;
  };

  const {
    data: renameModel,
    error: renameModelsError,
    isLoading: renameModelFetching,
    refetch: refetchRenameModel,
  } = useQuery<RenameModel, Error>(
    ["rename-models", documentTypeId],
    async () => {
      const result = await vm.getRenameModel(companyId, documentTypeId);
      return result;
    },
    {
      enabled: false,
      retry: false,
    },
  );
  const handleDocumentTypeId = async (docTypeId: string): Promise<void> => {
    const id = docTypeId;
    setDocumentTypeId(id);
  };

  const documentSites =
    getDocumentSites.data?.pages?.flatMap((page) => page.results) ?? [];
  const totalSiteCount = getDocumentSites.data?.pages?.[0]?.count ?? 0;
  const documentActionLogs = documentLogs?.pages?.flat() ?? [];
  const documentFileLogs = docFileLogs?.pages?.flat() ?? [];

  return {
    setHookDocumentId: setDocumentId,
    setDocumentTypeId,
    handleDocumentTypeId,
    documentLogsFetching,
    documentFileLogsFetching,
    documentFilesFetching,
    fileLogsHasNextPage,
    docLogsHasNextPage,
    fileLogsFetchNextPage,
    docLogsFetchNextPage,
    addFileToDocument,
    repropagateFileDocument,
    deleteDocumentFile,
    updateFile,
    documentFiles: documentFiles,
    refetchDocumentFiles,
    documentFileLogs: documentFileLogs,
    documentActionLogs: documentActionLogs,
    updateFilterFiles: (field, value) => {
      updateFilterWithDelete(setFilterFiles, field, value);
    },
    updateFilterDocumentLog: (field, value) => {
      updateFilterWithDelete(setFilterDocumentLog, field, value);
    },
    setSortFiles,
    setSortDocumentLog,
    filterFiles,
    filterDocumentLog,
    sortDocumentLog,
    sortFiles,
    errorMsg,
    setErrorMsg,
    successMsg,
    setSuccessMsg,
    isAddingFile: addFileToDocumentMutation.isLoading,
    documentSites,
    isFetchingSites: getDocumentSites.isFetching,
    filterSites,
    setFilterSites,
    updateFilterSites: (field, value) => {
      updateFilterWithDelete(setFilterSites, field, value);
    },
    setSortSites,
    sortSites,
    hasSites,
    hasSitesNextPage: getDocumentSites.hasNextPage,
    fetchSitesNextPage: getDocumentSites.fetchNextPage,
    setEnableGetDocLogs,
    setEnableGetFileLogs,
    deleteIsLoading: deleteDocumentFileMutation.isLoading,
    renameModel,
    refetchRenameModel,
    totalSiteCount,
  };
}

export default useDocumentInfo;
