import UseCase from '../../../../core/app/usecase';
import ChemicalRepository from '../../../repositories/chemicalRepository';

interface Args {
	companyId: string;
	resourceId: string;
	typologyId: string;
}

class RemoveTypologyFromChemical implements UseCase<Args, void> {
	constructor(private repository: ChemicalRepository) {}

	async Call(args: Args): Promise<void> {
		return await this.repository.removeTypologyFromChemical(args.companyId, args.resourceId, args.typologyId);
	}
}

export default RemoveTypologyFromChemical;
