import { COLORS } from "../../../assets/theme/colors";
import { Button, Tooltip } from "@chakra-ui/react";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

const CellPlusButton = ({
  onClick,
  isDisabled = false,
  tooltip,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  tooltip?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip label={tooltip} isDisabled={isDisabled}>
      <Button
        onClick={onClick}
        bg={COLORS.yellow}
        borderRadius="8px"
        isDisabled={isDisabled}
        h="25px"
        w="43px"
      >
        +
      </Button>
    </Tooltip>
  );
};

export default CellPlusButton;
