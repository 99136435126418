import UseCase from '../../../../core/app/usecase';
import repository from '../../../repositories/siteChemicalRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	chemicalId: string;
};

class RestoreSiteChemical implements UseCase<Parameters, void> {
	constructor(private repository: repository) {}

	async Call({ companyId, siteId, chemicalId }: Parameters): Promise<void> {
		return this.repository.restoreSiteChemical(companyId, siteId, chemicalId);
	}
}

export default RestoreSiteChemical;
