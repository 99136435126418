import UseCase from '../../../core/app/usecase';
import Badge from '../../entities/badge';
import BadgeRepository from '../../repositories/badgeRepository';

type ARGS = {
	companyId: string;
	siteId?: string;
};

class GetBadgesNoPaging implements UseCase<ARGS, Badge[]> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, siteId }: ARGS): Promise<Badge[]> {
		return await this.badgeRepository.getBadgesNoPaging(companyId, siteId);
	}
}

export default GetBadgesNoPaging;
