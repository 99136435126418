import UseCase from '../../../../core/app/usecase';
import Document from '../../../entities/document';
import ToolRepository from '../../../repositories/toolRepository';
import { GetDocumentsFilter } from '../../../repositories/documentRepository';
import { SortMeta } from '../../../entities/interfaces/paginatedResults';

type GetToolDocumentsParameters = {
	companyId: string;
	resourceId: string;
	filter?: GetDocumentsFilter;
	sort?: SortMeta;
	pageParam?: number;
};

/**
 * Returns the list of all the existing Document.
 */
class GetToolDocuments implements UseCase<GetToolDocumentsParameters, Document[]> {
	constructor(private repository: ToolRepository) {}

	async Call({ companyId, resourceId, sort, filter, pageParam }: GetToolDocumentsParameters): Promise<Document[]> {
		return await this.repository.getToolDocuments(companyId, resourceId, filter, sort, pageParam);
	}
}

export default GetToolDocuments;
