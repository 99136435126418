import UseCase from "../../../../core/app/usecase";
import AiTaskDocument from "../../../entities/aiTaskDocument";
import DocumentRepository from "../../../repositories/documentRepository";


type ConfirmAiTaskParams = {
  companyId: string;
  taskId: string;
  aiTaskDocuments: AiTaskDocument[];
  siteIds: string[];
}

/**
 * Returns the list of all the existing Document.
 */
class ConfirmAiTask implements UseCase<ConfirmAiTaskParams, void> {
  constructor(private repository: DocumentRepository) { }

  async Call({companyId, taskId, aiTaskDocuments, siteIds}: ConfirmAiTaskParams): Promise<void> {
    return await this.repository.confirmAiTask(companyId, taskId, aiTaskDocuments, siteIds);
  }
}

export default ConfirmAiTask;