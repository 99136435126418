import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import Variant from '../../entities/variant';
type ARGS = {
	companyId: string;
	supplierId: string;
	subject: RequirementSubject;
};
class GetVariantsForCompanySupplierRequirements implements UseCase<ARGS, Variant[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, subject }: ARGS): Promise<Variant[]> {
		return await this.supplierRepository.getVariantsForCompanySupplierRequirements(companyId, supplierId, subject);
	}
}

export default GetVariantsForCompanySupplierRequirements;
