import { Identifiable } from './interfaces/identifiable';

type Staff = Identifiable & {
	name: string;
	surname: string;
	phone: string;
	email: string;
	role: string;
	type: StaffType;
};

export enum StaffType {
	SYSTEM = 'system',
	CUSTOM = 'custom',
	SITE_CUSTOM = 'site_custom',
}

export type SystemStaffRole = 'employer' | 'rls' | 'rspp' | 'doctor' | 'contact';

export default Staff;
