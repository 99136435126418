import User from '../../entities/user';
import UseCase from '../../../core/app/usecase';
import UsersRepository from '../../repositories/usersRepository';

interface Args {
	companyId: string;
	userId: string;
	user: User;
}

class UpdateUser implements UseCase<Args, User> {
	constructor(private repository: UsersRepository) {}

	async Call({ companyId, userId, user }: Args): Promise<User> {
		return await this.repository.updateUser(companyId, userId, user);
	}
}

export default UpdateUser;
