import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';

type Parameters = {
  companyId: string;
  supplierId: string;
  documentId: string;
  siteIds?: string[],
  selectAll?: boolean,
};

class RepropagateEvaluationSupplierDocument implements UseCase<Parameters, void> {
  constructor(private repository: SupplierRepository) {}

  async Call({ companyId, supplierId, documentId, siteIds,selectAll }: Parameters): Promise<void> {
    await this.repository.repropagateEvaluationSupplierDocument(companyId, supplierId, documentId, siteIds ,selectAll);
  }
}

export default RepropagateEvaluationSupplierDocument;
