import Role from "../../../domain/entities/role";
import Permission from "../../../domain/entities/permission";
import {
  makeGetRole,
  makeUpdateRole,
} from "../../../core/app/factory/roles/usecases";

class RoleDetailsViewModel {
  private updateUC = makeUpdateRole();
  private getUC = makeGetRole();

  constructor(
    private companyId: string,
    private roleId?: string,
  ) {}

  async get(): Promise<Role> {
    return this.getUC.Call({ companyId: this.companyId, roleId: this.roleId });
  }

  async update(roleId: string, permissionIds: string[]): Promise<Role> {
    return this.updateUC.Call({
      companyId: this.companyId,
      roleId,
      permissionIds,
    });
  }
}

export default RoleDetailsViewModel;
