import { makeGetCompanySupplierTool } from '../../../core/app/factory/suppliers/useCases';
import { RequirementSubject } from '../../../domain/entities/requirementSubject.enum';
import SupplierResourceViewModel from './SupplierResourceViewModel';
import { SiteToolParams, SupplierTool } from '../../../domain/entities/siteTool';

class SupplierToolViewModel extends SupplierResourceViewModel<SiteToolParams> {
	constructor(
		public companyId: string,
		public supplierId: string,
		public resourceId: string,
	) {
		super(companyId, supplierId, resourceId, RequirementSubject.TOOL);
	}
	async getSupplierResource(): Promise<SupplierTool> {
		return await makeGetCompanySupplierTool().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			resourceId: this.resourceId,
		});
	}
}

export default SupplierToolViewModel;
