import UseCase from '../../../../core/app/usecase';
import { SiteMachine } from '../../../entities/siteMachine';
import SiteMachineRepository, { GetSiteMachinesFilters } from '../../../repositories/siteMachineRepository';
import { PaginatedMeta, PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';

type Parameters = {
	companyId: string;
	siteId: string;
	archived?: boolean;
	filter?: GetSiteMachinesFilters;
	sort?: SortMeta;
	supplierId?: string;
} & PaginatedMeta;

class GetSiteMachines implements UseCase<Parameters, PaginatedResults<SiteMachine> | undefined> {
	constructor(private repository: SiteMachineRepository) {}

	async Call({
		companyId,
		siteId,
		sort,
		perPage,
		archived,
		filter,
		page,
		supplierId,
	}: Parameters): Promise<PaginatedResults<SiteMachine> | undefined> {
		return this.repository.getSiteMachines(companyId, siteId, page, perPage, archived, filter, sort, supplierId);
	}
}

export default GetSiteMachines;
