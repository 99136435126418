import UseCase from '../../../core/app/usecase';
import Company from '../../entities/company';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import SupplierRepository, { InvitedSuppliersFilter } from '../../repositories/supplierRepository';

interface Args {
	siteId: string;
	companyId: string;
	filter?: InvitedSuppliersFilter;
	sort?: SortMeta;
	pageParam?: number;
}

class GetAvailableSuppliers implements UseCase<Args, Company[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ siteId, companyId, filter, sort, pageParam }: Args): Promise<Company[]> {
		return await this.supplierRepository.getAvailableSuppliers(siteId, companyId, filter, sort, pageParam);
	}
}

export default GetAvailableSuppliers;
