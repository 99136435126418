import UseCase from '../../../core/app/usecase';
import RequirementRepository from '../../repositories/requirementRepository';

interface RequirementsTemplateUrl {
    companyId: string,
    siteId: string,
	requirementId: string
    templateId: string
}

class GetRequirementsTemplateUrl implements UseCase<RequirementsTemplateUrl,Promise<string>> {
	private requirementRepository: RequirementRepository;

	constructor(requirementRepository: RequirementRepository) {
		this.requirementRepository = requirementRepository;
	}

	async Call(args: RequirementsTemplateUrl): Promise<any> {
        const {companyId, siteId, requirementId, templateId} = args
		return await this.requirementRepository.getRequirementsTemplateUrl(companyId, siteId, requirementId, templateId);
	}
}

export default GetRequirementsTemplateUrl;
