import { HiFilter, HiOutlineFilter } from "react-icons/hi";
import { IconButton } from "@chakra-ui/react";
import TagType from "../../../../domain/entities/tag";
import TagSelect from "../TagSelect";
import { useState } from "react";

export type LabelFilterProps<T extends TagType> = {
  id: string;
  margin?: string;
  options: Array<T>;
  onChange?: (selected: Array<T>) => void;
  items?: Array<T>;
};

const LabelFilter = ({
  id,
  options,
  onChange,
  margin,
  items = [],
}: LabelFilterProps<TagType>) => {
  const [open, setOpen] = useState(false);
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <IconButton
        size="sm"
        aria-label={id}
        margin={margin}
        color={items.length > 0 && "blue.300"}
        bg="whiteAlpha.100"
        onClick={() => setOpen(!open)}
        maxH="15px"
      >
        {items.length > 0 ? <HiFilter /> : <HiOutlineFilter />}
      </IconButton>
      {open && (
        <TagSelect
          tags={options}
          selectedTags={items.map((i) => i.id)}
          setSelectedTags={(tagIds) =>{
            onChange(tagIds.map((id) => options.find((t) => t.id === id)!))
            setOpen(!open)
          }}
          style={{ position: "absolute", width: "300px", zIndex: "5000" }}
        />
      )}
    </div>
  );
};

export default LabelFilter;
