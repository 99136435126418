import UseCase from '../../../core/app/usecase';
import Evaluation from '../../entities/evaluation';
import DocumentRepository, { GetDocumentEvaluationFilter } from '../../repositories/documentRepository';
import { ResourceDocumentEvaluationState } from '../../entities/resourceDocumentEvaluationState.enum';
import { SortMeta } from '../../entities/interfaces/paginatedResults';

interface Args {
	companyId: string;
	siteId: string;
	resourceType: string;
	resourceId: string;
	documentId: string;
	pageParam?: number;
	filter?: GetDocumentEvaluationFilter;
	sort?: SortMeta;
}

class GetDocumentEvaluations implements UseCase<Args, Evaluation<ResourceDocumentEvaluationState>[]> {
	constructor(private repository: DocumentRepository) {}
	async Call({
		companyId,
		siteId,
		resourceType,
		resourceId,
		documentId,
		pageParam,
		filter,
		sort,
	}: Args): Promise<Evaluation<ResourceDocumentEvaluationState>[]> {
		return this.repository.getDocumentEvaluations(companyId, siteId, resourceType, resourceId, documentId, filter, sort, pageParam);
	}
}

export default GetDocumentEvaluations;
