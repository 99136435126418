import React from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaTimes } from "react-icons/fa";

const cellStyle = {
  padding: "4px",
};

const checkIconStyle = {
  color: "green",
  width: "100%",
};

const timesIconStyle = {
  color: "red",
  width: "100%",
};

const LicenseTableInfo = () => {
  const { t } = useTranslation("navbar");
  return (
    <table
      style={{ width: "100%", borderCollapse: "collapse", textAlign: "center" }}
    >
      <thead style={{ marginRight: 10 }}>
        <tr>
          <th style={cellStyle}></th>
          <th style={cellStyle}>SMART</th>
          <th style={cellStyle}>STANDARD</th>
          <th style={cellStyle}>MASTER</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            {t("main.my company")}
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={checkIconStyle} />
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={checkIconStyle} />
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={checkIconStyle} />
          </td>
        </tr>
        <tr>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            {t("main.my resources")}
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={checkIconStyle} />
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={checkIconStyle} />
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={checkIconStyle} />
          </td>
        </tr>
        <tr>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            {t("timeTable")}
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={{ ...checkIconStyle }} />
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={checkIconStyle} />
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={checkIconStyle} />
          </td>
        </tr>
        <tr>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            {t("exportSections.users", { ns: "common" })}
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={checkIconStyle} />
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={checkIconStyle} />
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={checkIconStyle} />
          </td>
        </tr>
        <tr>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            {t("main.my working sites")}
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaTimes style={timesIconStyle} />
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={checkIconStyle} />
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaCheck style={checkIconStyle} />
          </td>
        </tr>
        <tr>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            {t("main.my sites")}
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaTimes style={timesIconStyle} />
          </td>
          <td style={{ ...cellStyle, textAlign: "center" }}>
            <FaTimes style={timesIconStyle} />
          </td>
          <td>
            <FaCheck style={{ ...checkIconStyle, textAlign: "center" }} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default LicenseTableInfo;
