import UseCase from '../../../../core/app/usecase';
import repository from '../../../repositories/siteMachineRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	machineId: string;
};

class RestoreSiteMachine implements UseCase<Parameters, void> {
	constructor(private repository: repository) {}

	async Call({ companyId, siteId, machineId }: Parameters): Promise<void> {
		return this.repository.restoreSiteMachine(companyId, siteId, machineId);
	}
}

export default RestoreSiteMachine;
