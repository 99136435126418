import {
  Button,
  Checkbox,
  Flex,
  Input,
  SkeletonText,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TbCrane, TbFolders, TbTrash } from "react-icons/tb";
import { useMediaQuery } from "@chakra-ui/react";
import Badge from "../../../../domain/entities/badge";
import { BadgeType } from "../../../../domain/entities/badgeType.enum";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import Tag from "../../../../domain/entities/tag";
import { GetAvailableBadgesFilters } from "../../../../domain/repositories/badgeRepository";
import { COLORS } from "../../../assets/theme/colors";
import { BadgeStatusSite } from "../../../hooks/Badge/useBadgeDetailViewModel";
import TagList from "../../../screens/Common/TagList";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import SimpleTable from "../../Common/table/SimpleTable";
import StatusBadge from "../../Common/StatusBadge";

type SelectBadgesTableProps = {
  badges: Badge[];
  badgesTags?: Tag[];
  badgesHasNextPage?: boolean;
  badgesFetchNextPage?: () => void;
  badgesFilters: { [p: string]: any };
  badgesSort: SortMeta;
  updateBadgesFilter: (
    field: keyof GetAvailableBadgesFilters,
    value: string | string[],
  ) => void;
  setBadgesSort: (sort: SortMeta) => void;
  setBadgeIds: (badgeIds: string[]) => void;
  badgeIds: string[];
  resourcesAmount?: number;
  assocciatedSites?: BadgeStatusSite[];
  setBadgeId?: (badgeId: string) => void;
  badgeSitesIsLoading?: boolean;
  showExtraData?: boolean;
  selectAllAvailable?: boolean;
  parentSelectAllVisibile?: boolean;
  setParentSelectAllVisibile?: (selectAllParent: boolean) => void;
  parentSelectAllMatching?: boolean;
  setParentSelectAllMatching?: (selectAllParent: boolean) => void;
  allSelectableBadgeNumber?: number;
  setSelectedBadgesNumber?: (selectedBadgesNumber: number) => void;
  selectedBadgesNumber?: number;
  autosize?: boolean;
  hideInfiniteLoader?: boolean;
  showOnlySitesExtraData?: boolean;
  paginatedTable?: boolean;
  selectSingleRow?: boolean;
  showTags?: boolean;
  showDelete?: boolean;
  unlinkBadgeResource?: (badgeId: string) => void;
};

const SelectBadgesTable = ({
  badges,
  badgesFilters,
  badgesTags,
  badgesSort,
  badgesHasNextPage,
  hideInfiniteLoader,
  badgesFetchNextPage,
  setBadgesSort,
  updateBadgesFilter,
  setBadgeIds,
  badgeIds,
  resourcesAmount,
  assocciatedSites,
  setBadgeId,
  badgeSitesIsLoading,
  showExtraData,
  selectAllAvailable,
  unlinkBadgeResource,
  parentSelectAllVisibile,
  setParentSelectAllVisibile,
  showOnlySitesExtraData = false,
  showDelete = false,
  parentSelectAllMatching,
  setParentSelectAllMatching,
  paginatedTable,
  selectSingleRow = true,
  showTags = true,
  allSelectableBadgeNumber,
  setSelectedBadgesNumber,
  selectedBadgesNumber,
  autosize,
}: SelectBadgesTableProps) => {
  const { t } = useTranslation("badges");

  const tableColumns: {
    field: keyof GetAvailableBadgesFilters;
    type: "text" | "select" | "tags";
    options?: Record<string, string>;
  }[] = showTags
    ? [
        { field: "code", type: "text" },
        { field: "type", type: "select", options: BadgeType },
        { field: "serial", type: "text" },
        { field: "tagIds", type: "tags" },
      ]
    : [
        { field: "code", type: "text" },
        { field: "type", type: "select", options: BadgeType },
        { field: "serial", type: "text" },
      ];

  const [showResources, setShowResources] = useState(null);
  const [showSites, setShowSites] = useState(null);
  const [insertedBadgeNumber, setInsertedBadgesNumber] = useState(0);
  const [storedSelectedNumber, setStoredSelectedNumber] = useState(false);
  const [showBodyTable, setShowBodyTable] = useState(true);
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const toggleItem = (id: string) => {
    if (!badgeIds.includes(id)) {
      if (
        typeof resourcesAmount === "undefined" ||
        resourcesAmount >= badgeIds.length
      ) {
        setBadgeIds([...badgeIds, id]);
        setSelectedBadgesNumber &&
          setSelectedBadgesNumber([...badgeIds, id].length);
      }
      setParentSelectAllVisibile && setParentSelectAllVisibile(false);
      setStoredSelectedNumber && setStoredSelectedNumber(false);
      setInsertedBadgesNumber && setInsertedBadgesNumber(0);
    } else {
      setParentSelectAllVisibile && setParentSelectAllVisibile(false);
      setSelectedBadgesNumber &&
        setSelectedBadgesNumber(badgeIds.filter((i) => i !== id).length);
      setStoredSelectedNumber(false);
      setParentSelectAllMatching && setParentSelectAllMatching(false);
      setBadgeIds(badgeIds.filter((i) => i !== id));
    }
  };

  const resetSelections = () => {
    setBadgeIds([]);
    setStoredSelectedNumber(false);
    setParentSelectAllMatching && setParentSelectAllMatching(false);
    setParentSelectAllVisibile(false);
    setSelectedBadgesNumber(0);
  };

  const selectAllBadges = (selectAll?: boolean) => {
    setBadgeIds(badges?.map((badge) => badge.id));
    if (resourcesAmount > 0) {
      setSelectedBadgesNumber(
        insertedBadgeNumber > 0
          ? insertedBadgeNumber
          : selectAll
            ? allSelectableBadgeNumber
            : badges?.length,
      );
    } else {
      setSelectedBadgesNumber(0);
    }
  };
  const toggleSelectAll = (value: boolean) => {
    setParentSelectAllVisibile(value);
    setShowBodyTable(true);
    if (!value) {
      resetSelections();
    } else {
      selectAllBadges();
    }
  };

  const handleResourceClick = (badgeId: string) => {
    setShowResources(badgeId === showResources ? null : badgeId);
    setShowSites(null);
  };

  const handleSitesClick = async (badgeId: string) => {
    setBadgeId(badgeId);
    setShowSites(badgeId === showSites ? null : badgeId);
    setShowResources(null);
  };
  const handleSelectionButton = () => {
    if (parentSelectAllMatching) {
      resetSelections();
      setInsertedBadgesNumber(0);
      setShowBodyTable(true);
    } else {
      setParentSelectAllMatching && setParentSelectAllMatching(true);
      setShowBodyTable(false);
      selectAllBadges(true);
    }
  };

  const renderDetails = (badge) => {
    return (
      <>
        {showResources === badge?.id && (
          <Tr
            key={`resources-${badge?.id}`}
            bg={COLORS.lightBlue}
            fontStyle={"italic"}
            width={"100%"}
          >
            <Td colSpan={showTags ? 6 : 4}>
              <Text ml={7}>
                {badge?.resource?.name || t("badgeWithoutResource")}
              </Text>
            </Td>
          </Tr>
        )}
        {showSites === badge?.id &&
        (!assocciatedSites || assocciatedSites.length === 0) ? (
          <Tr
            key={`sites-${badge?.id}`}
            bg={COLORS.lightBlue}
            fontStyle={"italic"}
            width={"100%"}
          >
            <Td colSpan={showTags ? 6 : 4}>
              {!badgeSitesIsLoading ? (
                <Text ml={7}> {t("badgeWithoutSites")}</Text>
              ) : (
                <SkeletonText
                  mt="2"
                  noOfLines={1}
                  skeletonHeight={8}
                  width={"fill"}
                />
              )}
            </Td>
          </Tr>
        ) : (
          showSites === badge?.id &&
          assocciatedSites?.map((item: BadgeStatusSite) => {
            return (
              <Tr
                key={`sites-${badge?.id}`}
                bg={COLORS.lightBlue}
                fontStyle={"italic"}
                width="100%"
              >
                <Td>
                  <Text ml={7}> {item?.site?.name}</Text>
                </Td>
                <Td colSpan={3}>
                  <Flex justifyContent="flex-start" alignItems="center" gap={2}>
                    <StatusBadge value={item?.badgeStatus} />
                  </Flex>
                </Td>
              </Tr>
            );
          })
        )}
      </>
    );
  };

  useEffect(() => {
    if (badges?.length > 0 && parentSelectAllMatching && !insertedBadgeNumber) {
      const ids = badges?.map((badge) => badge.id);
      const allIds = [...ids, ...badgeIds];
      const uniqueBadgeIds = Array.from(new Set(allIds));
      setBadgeIds(uniqueBadgeIds);
    }
  }, [badges?.length]);

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"start"}
      border="1px solid"
      borderRadius="10px"
      borderColor="gray.300"
      width={isMobile ? "1200px" : "100%"}
      marginTop={3}
      position="relative"
      overflow={"hidden"}
    >
      {paginatedTable ? (
        <InfiniteTable
          autosize={autosize}
          tableId="select-badges-table"
          bottomThreshold={220}
          isCheckboxTable={true}
          infiniteScroll={{
            dataLength: badges?.length,
            hasNextPage: badgesHasNextPage,
            fetchNextPage: badgesFetchNextPage,
            hideInfiniteLoader: !showBodyTable || hideInfiniteLoader,
          }}
          isLoading={badgeSitesIsLoading}
          showEmptyText={badges?.length === 0}
        >
          <Thead>
            <Tr width={"100%"}>
              {selectAllAvailable && (
                <Th key={"selectAllCheckbox"} width={10}>
                  <Checkbox
                    borderColor={"gray.500"}
                    isChecked={parentSelectAllVisibile}
                    onChange={() => toggleSelectAll(!parentSelectAllVisibile)}
                  ></Checkbox>
                </Th>
              )}
              {tableColumns.map((column) => (
                <Th key={column.field} width={"100%"}>
                  <TableColumnHeader
                    text={t(`columns.${column.field}`)}
                    filter={{
                      component: (
                        <ColumnFilterComponent
                          type={column.type}
                          value={badgesFilters[column.field]}
                          updateFilter={(value) =>
                            updateBadgesFilter(
                              column.field,
                              value as string | string[],
                            )
                          }
                          tags={badgesTags}
                          selectOptions={column.options}
                          namespace="badges"
                        />
                      ),
                      isActive: !!(Array.isArray(badgesFilters[column.field])
                        ? badgesFilters[column.field][0]
                        : badgesFilters[column.field]),
                    }}
                    sort={{
                      handler: (direction) =>
                        setBadgesSort({ field: column.field, direction }),
                      direction:
                        badgesSort && badgesSort.field === column.field
                          ? badgesSort.direction
                          : null,
                    }}
                  />
                </Th>
              ))}
              {showExtraData && <Td width={100}></Td>}
            </Tr>
          </Thead>

          {
            <Tbody>
              <Tr width={"100%"}>
                {parentSelectAllVisibile && (
                  <Th
                    colSpan={tableColumns.length + 2}
                    backgroundColor={"gray.100"}
                  >
                    <Text color={COLORS.red}>
                      {insertedBadgeNumber > allSelectableBadgeNumber &&
                        t("selectionTooltip", {
                          ns: "badges",
                          count: allSelectableBadgeNumber,
                        })}
                    </Text>
                    <Text textAlign="center" mx="auto">
                      {!parentSelectAllMatching && t("badgesSelectedVisible")}
                      {insertedBadgeNumber < allSelectableBadgeNumber &&
                        parentSelectAllMatching &&
                        !storedSelectedNumber &&
                        t("badgesSelectedNotVisible", {
                          count: allSelectableBadgeNumber,
                        })}
                      {insertedBadgeNumber < allSelectableBadgeNumber &&
                        parentSelectAllMatching &&
                        storedSelectedNumber &&
                        t("badgesSelectedManuallyNotVisible", {
                          countSelected: selectedBadgesNumber,
                          total: allSelectableBadgeNumber,
                        })}
                      {insertedBadgeNumber < allSelectableBadgeNumber &&
                        badgesHasNextPage && (
                          <Button
                            mt="10px"
                            ml="4px"
                            colorScheme="blue"
                            variant="link"
                            onClick={() => handleSelectionButton()}
                          >
                            {t(
                              parentSelectAllMatching
                                ? "clearSelection"
                                : "badgesSelectAll",
                              { ns: "badges" },
                            )}
                          </Button>
                        )}
                    </Text>
                    {parentSelectAllMatching && (
                      <Flex flexDirection={"row"} marginTop={3}>
                        <Text textAlign="center" mx="auto" marginTop={1}>
                          {t("badgesSelectLess")}
                        </Text>
                        <Input
                          marginTop={1}
                          marginLeft={5}
                          min={0}
                          width={"15%"}
                          type="number"
                          defaultValue={insertedBadgeNumber}
                          size="sm"
                          isInvalid={
                            insertedBadgeNumber > allSelectableBadgeNumber
                          }
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setInsertedBadgesNumber(value);
                          }}
                        />
                        <Tooltip
                          label={t("selectionTooltip", {
                            ns: "badges",
                            count: allSelectableBadgeNumber,
                          })}
                          aria-label="Info"
                          isDisabled={
                            insertedBadgeNumber < allSelectableBadgeNumber
                          }
                        >
                          <Button
                            marginLeft={5}
                            colorScheme="blue"
                            isDisabled={
                              insertedBadgeNumber > allSelectableBadgeNumber
                            }
                            onClick={() => {
                              setStoredSelectedNumber(true);
                              setSelectedBadgesNumber(insertedBadgeNumber);
                              setBadgeIds(
                                badges
                                  .slice(0, insertedBadgeNumber)
                                  .map((item) => item.id),
                              );
                            }}
                          >
                            {t("confirm", { ns: "common" })}
                          </Button>
                        </Tooltip>
                      </Flex>
                    )}
                  </Th>
                )}
              </Tr>
              {showBodyTable &&
                badges?.map((badge) => (
                  <>
                    <Tr key={badge?.id} width={"100%"}>
                      {selectAllAvailable && (
                        <Td width={10}>
                          <Checkbox
                            borderColor={"gray.500"}
                            isChecked={
                              badgeIds ? badgeIds?.includes(badge?.id) : false
                            }
                            onChange={() => toggleItem(badge?.id)}
                          ></Checkbox>
                        </Td>
                      )}
                      <Td>{badge?.code}</Td>
                      <Td>{t(badge?.type)}</Td>
                      <Td pl={"14s"}>{badge?.serial}</Td>
                      <Td>
                        <TagList tags={badge?.tags} />
                      </Td>
                      {showExtraData && badges?.length > 0 && (
                        <Td width={100}>
                          <Flex gap={4} justifyContent={"end"}>
                            <Tooltip label={t("sitesBadge")}>
                              <span>
                                <TbCrane
                                  onClick={() => {
                                    handleSitesClick(badge?.id);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </span>
                            </Tooltip>
                            {!showOnlySitesExtraData ? (
                              <Tooltip label={t("resourceBadge")}>
                                <span>
                                  <TbFolders
                                    onClick={() => {
                                      handleResourceClick(badge?.id);
                                    }}
                                    cursor="pointer"
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </Tooltip>
                            ) : null}
                          </Flex>
                        </Td>
                      )}
                    </Tr>
                    {renderDetails(badge)}
                  </>
                ))}
            </Tbody>
          }
        </InfiniteTable>
      ) : (
        <SimpleTable
          autosize
          tableId="resource-evaluation-history-modal-evaluations-table"
          isLoading={badgeSitesIsLoading}
          showEmptyText={badges?.length === 0}
        >
          <Thead>
            <Tr>
              {selectSingleRow && !selectAllAvailable && <Th width={10}></Th>}
              {selectAllAvailable && (
                <Th key={"selectAllCheckbox"} width={10}>
                  <Checkbox
                    borderColor={"gray.500"}
                    isChecked={parentSelectAllVisibile}
                    onChange={() => toggleSelectAll(!parentSelectAllVisibile)}
                  ></Checkbox>
                </Th>
              )}
              {tableColumns?.map((column) => (
                <Th key={column.field} width={"100%"}>
                  <TableColumnHeader
                    text={t(`columns.${column.field}`)}
                    filter={{
                      component: (
                        <ColumnFilterComponent
                          type={column.type}
                          value={badgesFilters[column.field]}
                          updateFilter={(value) =>
                            updateBadgesFilter(
                              column.field,
                              value as string | string[],
                            )
                          }
                          tags={badgesTags}
                          selectOptions={column.options}
                          namespace="badges"
                        />
                      ),
                      isActive: !!(Array.isArray(badgesFilters[column.field])
                        ? badgesFilters[column.field][0]
                        : badgesFilters[column.field]),
                    }}
                    sort={{
                      handler: (direction) =>
                        setBadgesSort({ field: column.field, direction }),
                      direction:
                        badgesSort && badgesSort.field === column.field
                          ? badgesSort.direction
                          : null,
                    }}
                  />
                </Th>
              ))}
              {showExtraData && <Td width={100}></Td>}
            </Tr>
          </Thead>
          <Tbody>
            {badges?.map((badge) => (
              <>
                <Tr key={badge?.id} width={"100%"}>
                  {selectSingleRow && (
                    <Td width={10}>
                      <Checkbox
                        borderColor={"gray.500"}
                        isChecked={
                          badgeIds ? badgeIds?.includes(badge?.id) : false
                        }
                        onChange={() => toggleItem(badge?.id)}
                      ></Checkbox>
                    </Td>
                  )}
                  <Td>{badge?.code}</Td>
                  <Td>{t(badge?.type)}</Td>
                  <Td pl={"14s"}>{badge?.serial}</Td>
                  {showTags && (
                    <Td>
                      <TagList tags={badge?.tags} />
                    </Td>
                  )}
                  {showExtraData && badges?.length > 0 && (
                    <Td width={100}>
                      <Flex gap={4} justifyContent={"end"}>
                        <Tooltip label={t("sitesBadge")}>
                          <span>
                            <TbCrane
                              onClick={() => {
                                handleSitesClick(badge?.id);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </Tooltip>
                        {!showOnlySitesExtraData ? (
                          <Tooltip label={t("resourceBadge")}>
                            <span>
                              <TbFolders
                                onClick={() => {
                                  handleResourceClick(badge?.id);
                                }}
                                cursor="pointer"
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          </Tooltip>
                        ) : null}
                        {showDelete && (
                          <Tooltip
                            label={t("unlinkResource", { ns: "badges" })}
                          >
                            <span>
                              <TbTrash
                                onClick={() => {
                                  unlinkBadgeResource(badge?.id);
                                }}
                                cursor="pointer"
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </Flex>
                    </Td>
                  )}
                </Tr>
                {renderDetails(badge)}
              </>
            ))}
          </Tbody>
        </SimpleTable>
      )}
    </Flex>
  );
};

export default SelectBadgesTable;
