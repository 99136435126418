import { FC, useRef, useState } from "react";
import { CloseIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { EditableFormFieldProps } from "./FormField";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import { UploadImageView } from "../UploadImageView";

interface FormImageFieldProps extends EditableFormFieldProps {
  accept?: string;
}

const FormImageField: FC<FormImageFieldProps> = ({
  accept = "image/png,image/jpg,image/jpeg",
  name,
  label,
  isEditing = false,
  rules,
  onChange,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const errorMessage = errors[name]?.message?.toString();
  const inputProps = register(name, rules);

  const { t } = useTranslation();
  const ref = useRef(null);

  const [display, setDisplay] = useState<string>(t("noFileSelected"));
  const [showUploadView, setShowUploadView] = useState<boolean>(false);

  const performOnchange = (e: { target: any; type?: any }) => {
    const file = e.target.files?.length > 0 ? e.target.files[0] : undefined;
    if (!file) {
      return;
    }

    setValue(name, file);
    setDisplay(file?.name);

    inputProps.onChange(e);
    onChange?.();
  };

  const uploadViewSave = (e: any) => {
    const file: File = (e as unknown as File) ?? undefined;
    if (!file) {
      return;
    }

    setValue(name, file);
    setDisplay(file?.name);
    onChange?.();
  };

  const clearFileSelection = () => {
    setValue(name, undefined);
    onChange?.();
  };

  return (
    <FormControl isInvalid={errors[name] !== undefined} variant="floating">
      {!isEditing && (
        <Text ml="17px" mt="8px" pt={2}>
          {display}
        </Text>
      )}
      {isEditing && (
        <InputGroup>
          <InputLeftAddon cursor="pointer" onClick={clearFileSelection}>
            <CloseIcon />
          </InputLeftAddon>
          <input
            {...inputProps}
            accept={accept}
            placeholder={display}
            type="file"
            ref={ref}
            onChange={performOnchange}
            style={{ display: "none" }}
          />
          <Input onClick={() => setShowUploadView(true)} value={display} />
          <InputRightAddon
            cursor="pointer"
            onClick={() => setShowUploadView(true)}
          >
            <Text fontSize="x-small">{t("choose")}</Text>
          </InputRightAddon>
        </InputGroup>
      )}
      <FormLabel zIndex={1}>{label}</FormLabel>
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}

      {showUploadView && (
        <UploadImageView
          isOpen
          title={t("uploadBoxTitle")}
          onSave={uploadViewSave}
          onCancel={() => setShowUploadView(false)}
        />
      )}
    </FormControl>
  );
};

export default FormImageField;
