import UseCase from '../../../core/app/usecase';
import CompanyRepository from '../../repositories/companyRepository';
import {CustomField} from "../../entities/customFields";

type SetCustomFieldsParams = {
  companyId: string;
  customFields: CustomField[];
};

class SetCustomFields implements UseCase<null, void> {
	private companyRepository: CompanyRepository;

	constructor(companyRepository: CompanyRepository) {
		this.companyRepository = companyRepository;
	}

	async Call({ companyId, customFields }: SetCustomFieldsParams): Promise<void> {
		await this.companyRepository.setCustomFields(companyId, customFields);
	}
}

export default SetCustomFields;
