import UseCase from "../../../core/app/usecase";
import DocumentType from "../../entities/documentType";
import { DocumentTypeCategory } from "../../entities/documentTypeCategory.enum";
import DocumentTypeRepository from "../../repositories/documentTypeRepository";

/**
 * Returns the entire list of DocumentType.
 */

type GetDocumentTypesForAiParameters = {
    companyId: string;
    aiTaskId: string;
    resourceType?: DocumentTypeCategory;
}

class GetDocumentTypesForAi implements UseCase<GetDocumentTypesForAiParameters, DocumentType[]> {
  constructor(private repository: DocumentTypeRepository) { }

  async Call({ companyId, aiTaskId, resourceType }: GetDocumentTypesForAiParameters): Promise<DocumentType[]> {
    return await this.repository.getDocumentTypesForAi(companyId, aiTaskId, resourceType);
  }
}

export default GetDocumentTypesForAi;
