import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { IoMdDownload } from "react-icons/io";
import Document, {
  SiteResourceDocument,
} from "../../../../domain/entities/document";
import PendingEvaluation from "../../../../domain/entities/pending-evaluation";
import { downloadFileFromURL } from "../../../../infrastructure/utilities/fileDownloader";
import { UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import { useAuth } from "../../../providers/Auth0JWTProvider";

interface DownloadFileProps {
  renameModels: string[];
  type: "document" | "requirements" | "evaluation";
  document: Document | SiteResourceDocument | PendingEvaluation;
  downloader: UseDownloader;
  fileId: string;
}

export const DownloadFile = ({
  renameModels,
  type,
  document,
  downloader,
  fileId,
}: DownloadFileProps) => {
  const { t } = useTranslation();
  const { token } = useAuth();

  const downloadFile = (fileId: string, renameModel: string) => {
    let documentId: string;
    documentId = (document as unknown as Document).id;
    if (type === "document") {
      downloader?.getFileURL(
        documentId,
        fileId,
        renameModel,
        (url) => {
          downloadFileFromURL(url, token.access_token);
        },
        "company",
      );
    } else {
      documentId = (document as unknown as SiteResourceDocument).documentId;
      if (!documentId){
        documentId = (document as unknown as PendingEvaluation).document.id;
      }
      downloader?.getFileURL(
        documentId,
        fileId,
        renameModel,
        (url) => {
          downloadFileFromURL(url, token.access_token);
        },
        "site",
      );
    }
  };
  return (
    <Menu placement="auto">
      <Tooltip label={t("downloadFile", { ns: "documents" })}>
        <MenuButton>
          <IoMdDownload fontSize="24px" />
        </MenuButton>
      </Tooltip>
      <MenuList color={"black"}>
        <MenuGroup title={t("downloadFile", { ns: "documents" })}>
          {renameModels?.map((name) => (
            <MenuItem
              key={name}
              minH="48px"
              onClick={() => downloadFile(fileId, name)}
            >
              {name}
            </MenuItem>
          ))}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
