import MainNavBar from "../../components/Common/MainNavBar";
import SitesTopBar from "../../components/Views/Sites/SitesTopBar";
import MainLayout from "../../layout/MainLayout";
import { useEffect } from "react";
import { useApp } from "../../../app";
import { useAuth } from "../../providers/Auth0JWTProvider";

const SitesMainView = () => {
  const { updateContext } = useApp();
  const { updateSiteContext } = useAuth();

  useEffect(() => {
    updateContext({ topBarTitle: undefined, site: undefined });
    updateSiteContext(undefined);
  }, []);

  return <MainLayout header={<SitesTopBar />} nav={<MainNavBar />} />;
};

export default SitesMainView;
