import Role from '../../entities/role';
import UseCase from '../../../core/app/usecase';
import RolesRepository from '../../repositories/rolesRepository';

interface Args {
	roleId: string;
	companyId: string;
	permissionIds: string[];
}

class UpdateRole implements UseCase<Args, Role> {
	constructor(private repository: RolesRepository) {}

	async Call({ companyId, roleId, permissionIds }: Args): Promise<Role> {
		return await this.repository.updateRole(companyId, roleId, permissionIds);
	}
}

export default UpdateRole;
