import MainNavBar from "../../components/Common/MainNavBar";
import MainLayout from "../../layout/MainLayout";
import { useEffect } from "react";
import { useApp } from "../../../app";
import UserSettingsTopBar from "./UserSettingsTopBar";

const UserSettingsMainView = () => {
  const { updateContext } = useApp();

  useEffect(() => {
    updateContext({ topBarTitle: undefined });
  }, []);

  return <MainLayout header={<UserSettingsTopBar />} nav={<MainNavBar />} />;
};

export default UserSettingsMainView;
