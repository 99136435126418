import UseCase from '../../../../core/app/usecase';
import WorkerRepository, { GetWorkersFilter } from '../../../repositories/workerRepository';
import { SortMeta } from '../../../entities/interfaces/paginatedResults';

type ARGS = {
	companyId: string;
	filter?: GetWorkersFilter;
	sort?: SortMeta;
	pageParam?: number;
};

class GetAvailbleWorkersCount implements UseCase<ARGS, number> {
	constructor(private repository: WorkerRepository) {}

	async Call({ companyId, filter, sort, pageParam }: ARGS): Promise<number> {
		return await this.repository.getAvailableWorkersCount(companyId, filter, sort, pageParam);
	}
}

export default GetAvailbleWorkersCount;
