import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../Common/TopBar";
import { getSectionPermissions, Permission } from "../../Permissions/Permissions";
import { useHasPermissions } from "../../Permissions/RenderIf";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";

interface SitesDetailTopBarProps {
  emptyItems?: boolean;
}

const SitesDetailsTopBar = ({ emptyItems = false }: SitesDetailTopBarProps) => {
  const { siteId } = useParams();
  const { t } = useTranslation("sites");
  const isReviewEnabled = localStorage.getItem("isReviewEnabled");

  const hasCsePermission = useHasPermissions(Permission.Sites_CseShowSites, PermissionCheck.All);

  let items : TopBarItem[] = [];

  if (hasCsePermission){
    items = [
      {
        title: t("summary"),
        path: `/sites/${siteId}/details`
      },
      {
        title: t("documents"),
        path: `/sites/${siteId}/documents`
      },
      {
        title: t("resources"),
        path: `/sites/${siteId}/resources`
      },
      {
        title: t("suppliers"),
        path: `/sites/${siteId}/suppliers`
      },
      {
        title: t("access"),
        path: `/sites/${siteId}/access`
      }
    ]
  }
  else
  {
    items = [
      {
        title: t("summary"),
        path: `/sites/${siteId}/details`,
        permissions: getSectionPermissions("sites", "records"),
      },
      {
        title: t("documents"),
        path: `/sites/${siteId}/documents`,
        permissions: getSectionPermissions("sites", [
          "site-documents",
          "eval-owner",
          "overview",
        ]),
      },
      {
        title: t("resources"),
        path: `/sites/${siteId}/resources`,
        permissions: getSectionPermissions("sites", [
          "chemicals",
          "tools",
          "machines",
          "vehicles",
          "workers",
        ]),
      },
      {
        title: t("suppliers"),
        path: `/sites/${siteId}/suppliers`,
        permissions: getSectionPermissions("sites", "suppliers"),
      },
      {
        title: t("requirements"),
        path: `/sites/${siteId}/requirements`,
        permissions: getSectionPermissions("sites", "requirements"),
      },
      {
        title: t("access"),
        path: `/sites/${siteId}/access`,
        permissions: getSectionPermissions("sites", "access"),
      },
      isReviewEnabled === "true" && {
        title: t("approve"),
        path: `/sites/${siteId}/approve`,
        permissions: getSectionPermissions("sites", "review"),
      },
      {
        title: t("audit"),
        path: `/sites/${siteId}/audits`,
        permissions: getSectionPermissions("sites", "reports"),
      },
    ].filter(Boolean);
  }

  return (
    <TopBar
      title={t("site")}
      items={emptyItems ? [] : items}
      backPath="/sites"
    />
  );
};

export default SitesDetailsTopBar;
