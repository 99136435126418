import UseCase from '../../../core/app/usecase';
import Permission from '../../entities/permission';
import UsersRepository from '../../repositories/usersRepository';

interface Args {
	companyId: string;
	userId: string;
	siteId: string;
}

class GetUserSitePermissions implements UseCase<Args, Permission[]> {
	constructor(private repository: UsersRepository) {}

	async Call({ companyId, userId, siteId }: Args): Promise<Permission[]> {
		return await this.repository.getSitePermissions(companyId, userId, siteId);
	}
}

export default GetUserSitePermissions;
