import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';

interface Args {
  companyId: string;
  siteId: string;
  userIds: string[]
}

class GetSiteOptions implements UseCase<Args, void> {
  private siteRepository: SiteRepository;

  constructor(siteRepository: SiteRepository) {
    this.siteRepository = siteRepository;
  }

  async Call({ companyId, siteId, userIds }: Args): Promise<void> {
    return await this.siteRepository.deleteSiteUser(companyId, siteId, userIds);
  }
}

export default GetSiteOptions;
