import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SiteChemicalParams } from "../../../domain/entities/siteChemical";
import SupplierResourcesListView from "../../components/Views/Suppliers/SupplierResourcesListView";
import useSupplierResourcesViewModel from "../../hooks/Suppliers/useSupplierResourcesViewModel";
import SupplierChemicalsViewModel from "../../viewmodels/suppliers/SupplierChemicalsViewModel";

const SupplierChemicalsView = () => {
  const resourceColumns = ["name"];
  return (
    <SupplierResourcesListView<SiteChemicalParams>
      type={DocumentTypeCategory.CHEMICAL}
      useViewModel={useSupplierResourcesViewModel(
        new SupplierChemicalsViewModel(),
        DocumentTypeCategory.CHEMICAL,
      )}
      resourceColumns={resourceColumns}
      namespace={"chemicals"}
    />
  );
};

export default SupplierChemicalsView;
