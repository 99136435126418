import UseCase from '../../../core/app/usecase';
import updateCompanyParameter from '../../../presentation/hooks/Company/updateCompanyParameter';
import Company from '../../entities/company';
import CompanyRepository from '../../repositories/companyRepository';

class UpdateCompany implements UseCase<updateCompanyParameter, Company> {
	private companyRepository: CompanyRepository;

	constructor(companyRepository: CompanyRepository) {
		this.companyRepository = companyRepository;
	}

	async Call(parameters: updateCompanyParameter): Promise<Company> {
		const result = await this.companyRepository.updateCompany(parameters);
		return result;
	}
}

export default UpdateCompany;
