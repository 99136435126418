import UseCase from '../../../core/app/usecase';
import NotificationsRepository, { makeNotificationsRepository } from '../../repositories/notificationsRepository';

interface Args {
	companyId: string;
	notificationId: string;
	status: string;
}

/**
 * Marks a specific notification as "read".
 */
class ReadNotificationUseCase implements UseCase<Args, void> {
	constructor(private repository: NotificationsRepository) {}

	async Call(args: Args): Promise<void> {
		const { companyId, notificationId, status } = args;
		return await this.repository.read(companyId, notificationId, status);
	}
}

export const makeReadNotificationUseCase = (): ReadNotificationUseCase => {
	return new ReadNotificationUseCase(makeNotificationsRepository());
};
