import CreateSpecialization from '../../../../domain/interactors/specialization/createSpecialization';
import GetSpecializations from '../../../../domain/interactors/specialization/getSpecializations';
import { makeSpecializationRepository } from './repositories';
import GetSiteSpecializations from '../../../../domain/interactors/specialization/getSiteSpecializations';

export const makeGetSpecializations = () => {
	return new GetSpecializations(makeSpecializationRepository());
};

export const makeCreateSpecialization = () => {
	return new CreateSpecialization(makeSpecializationRepository());
};

export const makeGetSiteSpecializations = () => {
	return new GetSiteSpecializations(makeSpecializationRepository());
};
