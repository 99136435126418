import UseCase from '../../../core/app/usecase';
import BadgeRepository from '../../repositories/badgeRepository';
import File from '../../entities/file';
import ImportInfo from '../../entities/importInfo';

type ARGS = {
	companyId: string;
	file: File;
};

class ImportBadges implements UseCase<ARGS, ImportInfo> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, file }: ARGS): Promise<ImportInfo> {
		return await this.badgeRepository.importBadges(companyId, file);
	}
}

export default ImportBadges;
