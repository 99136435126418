import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalHeader, useToast
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { PrivacyPolicy, PrivacyPolicyHeader } from "../../PrivacyPolicy";
import { useEffect, useState } from "react";

type Props = {
  onClose: () => void;
  isOpen: boolean;
  url: string;
};

const PdfPreviewModal = (props: Props) => {
  const { t } = useTranslation("onboarding");
  const toast = useToast();
  useEffect(() => {
    if(props.isOpen){
      toast({
        description: t('privacyPolicyToast'),
        status: "info",
        duration: 3000,
        isClosable: false,
        position:"top"
      });
    }
  }, [props.isOpen]);
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="6xl"
      autoFocus={false}
      returnFocusOnClose={false}
      closeOnOverlayClick={false}
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <PrivacyPolicyHeader />
        </ModalHeader>
        <ModalBody>
          <PrivacyPolicy
            onClose={props.onClose}
            url={props.url}
          ></PrivacyPolicy>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PdfPreviewModal;
