import { Button, Link, Text } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { COLORS } from "../../../assets/theme/colors";
import DragDropFileView from "../../Common/DragDropFileView";
import BaseModal from "../../Common/alerts/BaseModal";
import React, { useState } from "react";
import File from "../../../../domain/entities/file";
import LoadingView from "../../../screens/Common/LoadingView";
import { BadgeResourceType } from "../../../../domain/entities/badgeResourceType.enum";

type BadgesImportModalProps = {
  onClose: () => void;
  onConfirm: (file: File) => Promise<void>;
  resourceType?: BadgeResourceType;
};
const ImportLinkResourceBadgeModal = ({
  onClose,
  onConfirm,
  resourceType,
}: BadgesImportModalProps) => {
  const { t } = useTranslation("badges");
  const [file, setFile] = useState<File | null>(null);
  //const [result, setResult] = useState<LinkBadgeResource[]>();
  const [result, setResult] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onUpload = async () => {
    if (file) {
      setIsLoading(true);
      await onConfirm(file);
      setIsLoading(false);
      setResult(true);
    }
  };

  const templatePath = () => {
    switch (resourceType) {
      case BadgeResourceType.WORKER:
        return "/templates/badge-link/lavoratori-badge.xlsx";
      case BadgeResourceType.MACHINE:
        return "/templates/badge-link/macchinari-badge.xlsx";
      case BadgeResourceType.VEHICLE:
        return "/templates/badge-link/veicoli-badge.xlsx";
      default:
        return "/templates/badge-link/lavoratori-badge.xlsx";
    }
  };

  return (
    <BaseModal
      title={result ? t("importedLinkTitle") : t("importLinkTitle")}
      onClose={onClose}
      onCancelLabel={result ? t("close") : undefined}
      onConfirm={!result ? onUpload : undefined}
      footer={
        <>
          <Button
            colorScheme="blue"
            isDisabled={!file || isLoading}
            onClick={result ? onClose : onUpload}
          >
            {t(result ? "close" : "confirm", { ns: "common" })}
          </Button>
          {!result && (
            <Button colorScheme="red" onClick={onClose}>
              {t("cancel", { ns: "common" })}
            </Button>
          )}
        </>
      }
    >
      {isLoading && <LoadingView width="100" height="200" />}

      {!result && !isLoading && (
        <>
          <Text mb={5}>
            <Trans
              i18nKey={
                resourceType === BadgeResourceType.WORKER
                  ? "importLinkMessageWorkers"
                  : "importLinkMessageMachines"
              }
              ns="badges"
              components={{
                a: (
                  <Link
                    sx={{ color: COLORS.sikuroBlue }}
                    href={templatePath()}
                  />
                ),
              }}
            />
          </Text>
          <DragDropFileView
            onUploaded={setFile}
            acceptedExtensions=".xlsx,.xls"
          />
        </>
      )}
    </BaseModal>
  );
};

export default ImportLinkResourceBadgeModal;
