import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../components/Common/TopBar";

const NotificationsTopBar = () => {
  const { t } = useTranslation("notifications");

  const items: TopBarItem[] = [
    {
      title: t("unread"),
      path: "/notifications/unread",
    },
    {
      title: t("archived"),
      path: "/notifications/archived",
    },
  ];

  return <TopBar title={t("notifications")} items={items} />;
};

export default NotificationsTopBar;
