import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  Text,
  Flex,
  Button,
  Stack,
  Skeleton,
  ModalContent,
  ModalOverlay,
  Modal,
  Divider,
} from "@chakra-ui/react";
import AiTask from "../../../../domain/entities/aiTask";
import LoadingView from "../../../screens/Common/LoadingView";
import AiTaskDocument from "../../../../domain/entities/aiTaskDocument";
import DocumentType from "../../../../domain/entities/documentType";
import { COLORS } from "../../../assets/theme/colors";
import DocumentFormCell from "./DocumentFormCell";
import Site from "../../../../domain/entities/site";
import SelectSitesView from "../common/SelectSitesView";

interface AiTaskDetailModalProps {
  title: string;
  aiTask: AiTask;
  aiTaskDetailFetching: boolean;
  onClose: () => void;
  onConfirm: (task: AiTask, siteIds: string[]) => void;
  onConfirmTaskIsLoading: boolean;
  isPropagable?: boolean;
  documentTypesForAi?: DocumentType[];
  sitesWhereToPropagate?: Site[];
  showTaskDetail?: boolean;
}

const AiTaskDetailModal = ({
  title,
  aiTask,
  aiTaskDetailFetching,
  onConfirm,
  onConfirmTaskIsLoading,
  onClose,
  isPropagable,
  documentTypesForAi,
  sitesWhereToPropagate,
  showTaskDetail,
}: AiTaskDetailModalProps) => {
  const { t } = useTranslation("documents");

  const [showSelectSites, setShowSelectSites] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // this property tracks the element that will be used to show the preview
  const [selectedTaskFile, setSelectedTaskFile] = useState<AiTaskDocument>();
  // this property tracks the element that will be used to understand when a line "isEditing" property is TRUE
  const [selectedTaskFileToEdit, setSelectedTaskFileToEdit] =
    useState<AiTaskDocument>();

  const [files, setFiles] = useState<AiTaskDocument[]>(aiTask?.result);
  const [siteIds, setSiteIds] = useState<string[]>([]);

  /**
   * this function will be called from the single file cell (DocumentFormCell) when the "save changes" button will be clicked
   * @param updatedFile
   */
  const updateFile = (updatedFile: AiTaskDocument) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) => (file.id === updatedFile.id ? updatedFile : file))
    );
  };

  useEffect(() => {
    setFiles(aiTask?.result);
  }, [aiTask]);

  return (
    <Modal
      isOpen={showTaskDetail}
      onClose={() => {
        onClose();
      }}
      size={"6xl"}
    >
      <ModalOverlay />
      <ModalContent h={"85vh"} w={"full"}>
        <Flex
          flexDirection={"row"}
          borderRadius={5}
          overflow={"clip"}
          height={"100%"}
        >
          {isLoading && (
            <Flex padding={5} justifyContent={"center"} alignItems={"center"}>
              <LoadingView width="100" height="100" />
            </Flex>
          )}

          <Flex
            justifyContent={"start"} // Align children to the start of the container
            alignItems={"start"}
            width={"40%"}
            flexDirection={"column"}
            h={"85vh"}
          >
            <Flex
              padding={5}
              justifyContent={"flex-start"} // Align items to the start within this flex
              alignItems={"center"}
              height={70}
              backgroundColor={COLORS.darkYellow}
              width={"100%"}
            >
              <Text fontSize={20} fontWeight={"bold"}>
                {t("tasksFiles", { ns: "documents" })}
              </Text>
            </Flex>
            <Flex
              overflowY={"auto"}
              flexDirection={"column"}
              width={"100%"}
              flex={1} // Take up the remaining space
            >
              {aiTaskDetailFetching ? (
                <Stack width={"100%"}>
                  <Skeleton height="140px" />
                  <Skeleton height="140px" />
                  <Skeleton height="140px" />
                  <Skeleton height="140px" />
                </Stack>
              ) : files?.length > 0 ? (
                files.map((file: AiTaskDocument) => (
                  <DocumentFormCell
                    key={file.id}
                    file={file}
                    documentTypesForAi={documentTypesForAi}
                    selectedTaskFile={selectedTaskFile}
                    setSelectedTaskFile={setSelectedTaskFile}
                    selectedTaskFileToEdit={selectedTaskFileToEdit}
                    setSelectedTaskFileToEdit={setSelectedTaskFileToEdit}
                    updateFile={updateFile}
                  />
                ))
              ) : (
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  h={"full"}
                >
                  <Text>{t("noAvailableFiles")}</Text>
                </Flex>
              )}
            </Flex>
          </Flex>

          <Divider orientation="vertical" />

          <Flex
            padding={5}
            justifyContent={"center"}
            alignItems={"flex-start"}
            width={"60%"}
            height={"auto"}
            flexDirection={"column"}
          >
            {!showSelectSites && (
              <iframe src={selectedTaskFile?.uri} width="100%" height="100%" />
            )}
            {showSelectSites && (
              <SelectSitesView
                autosize
                title={t("selectSitesCreateAiDocument", { ns: "documents" })}
                reminderText={t("noSiteSelected", { ns: "common" })}
                alertText={t("siteSelectionAiAlert", { ns: "documents" })}
                siteSelectedAction={(siteIds) => {
                  setSiteIds(siteIds);
                }}
                siteList={sitesWhereToPropagate}
                includeFooterButton={false}
                showSelectAll={false}
              />
            )}
            <Flex flexDirection={"row"} marginTop={4} width={"100%"}>
              <Flex grow={1} />
              <Button
                colorScheme="blue"
                isLoading={onConfirmTaskIsLoading}
                isDisabled={
                  files === undefined ||
                  files?.length == 0 ||
                  !files?.every(
                    (file) =>
                      file?.documentTypeId !== undefined &&
                      file?.documentTypeId !== null
                  )
                }
                onClick={() => {
                  if (showSelectSites || !isPropagable) {
                    const updatedTask = aiTask;
                    updatedTask.result = files;
                    onConfirm(updatedTask, siteIds);
                    setShowSelectSites(false);
                  } else {
                    setShowSelectSites(true);
                  }
                }}
              >
                {t("confirm", { ns: "common" })}
              </Button>
              <Button marginLeft={4} colorScheme="red" onClick={onClose}>
                {t("cancel", { ns: "common" })}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default AiTaskDetailModal;
