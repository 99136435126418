import UseCase from '../../../../core/app/usecase';
import repository from '../../../repositories/siteToolRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	toolId: string;
};

class RestoreSiteTool implements UseCase<Parameters, void> {
	constructor(private repository: repository) {}

	async Call({ companyId, siteId, toolId }: Parameters): Promise<void> {
		return this.repository.restoreSiteTool(companyId, siteId, toolId);
	}
}

export default RestoreSiteTool;
