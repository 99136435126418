import Machine from '../../../entities/machine';
import UseCase from '../../../../core/app/usecase';
import MachineRepository from '../../../repositories/machineRepository';
import GetMachineParameters from './getMachineParameter';

class GetMachine implements UseCase<GetMachineParameters, Machine | undefined> {
	constructor(private repository: MachineRepository) {}

	async Call({ companyId, machineId }: GetMachineParameters): Promise<Machine | undefined> {
		return this.repository.getMachineById(companyId, machineId);
	}
}

export default GetMachine;
