import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

const BookIcon = (props: IconProps) => {
  return (
    <Icon
      viewBox="0 0 35 35"
      {...props}
      fill="none"
      stroke={props.fill ?? "black"}
    >
      <path
        d="M7.29183 8.75033L1.4585 6.56283V26.8964L17.5002 33.542L33.5418 26.8964V5.83366L27.7085 8.75033M17.4272 7.29199V27.7087M7.29183 23.3337V2.91699L17.5002 7.29199L27.7085 2.91699V23.3337L17.5002 27.7087L7.29183 23.3337Z"
        strokeWidth="2.91667"
      />
    </Icon>
  );
};

export default BookIcon;
