import { Notification } from "../../../domain/entities/notification";
import { PaginatedResults } from "../../../domain/entities/interfaces/paginatedResults";
import { makeReadNotificationUseCase } from "../../../domain/interactors/notifications/read";
import { makeDeleteNotificationUseCase } from "../../../domain/interactors/notifications/delete";
import {
  ListNotificationsStatusFilter,
  makeListNotificationsUseCase,
} from "../../../domain/interactors/notifications/list";
import { makeNotificationCountUseCase } from "../../../domain/interactors/notifications/count";

/**
 * Exposes all methods used to list notifications and interact with them.
 */
class NotificationsListViewModel {
  // Use cases - get.
  private listUC = makeListNotificationsUseCase();

  // Use cases - update, delete.
  private readUC = makeReadNotificationUseCase();
  private deleteUC = makeDeleteNotificationUseCase();
  private getCountUC = makeNotificationCountUseCase();

  // Constructor.
  constructor(private companyId: string) {}

  // Exposed methods.
  async list(
    status: ListNotificationsStatusFilter,
    page: number,
    perPage: number,
  ): Promise<PaginatedResults<Notification>> {
    return this.listUC.Call({
      companyId: this.companyId,
      status,
      page,
      perPage,
    });
  }

  async read(notificationId: string, status: string): Promise<void> {
    return this.readUC.Call({
      companyId: this.companyId,
      notificationId,
      status,
    });
  }

  async delete(notificationId: string): Promise<string> {
    return this.deleteUC.Call({ companyId: this.companyId, notificationId });
  }

  async notificationCount(): Promise<number> {
    return this.getCountUC.Call({ companyId: this.companyId });
  }
}

export default NotificationsListViewModel;
