import UseCase from '../../../core/app/usecase';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import Tag from '../../entities/tag';
import RequirementRepository from '../../repositories/requirementRepository';

interface Args {
	companyId: string;
	requirementId: string;
	variant: Tag;
	siteId: string;
	resourceType: DocumentTypeCategory;
}

class AddVariantToSiteRequirement implements UseCase<Args, void> {
	constructor(private repository: RequirementRepository) {}

	async Call(args: Args): Promise<void> {
		return await this.repository.addVariantToSiteRequirement(args.companyId, args.requirementId, args.variant, args.siteId, args.resourceType);
	}
}

export default AddVariantToSiteRequirement;
