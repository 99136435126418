import UseCase from '../../../core/app/usecase';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import RequirementRepository from '../../repositories/requirementRepository';

type DeleteSiteRequirementParameter = {
	companyId: string;
	siteId?: string;
	requirementId: string;
	subject: RequirementSubject;
	resourceId: string;
};
class DeleteSiteSupplierRequirement implements UseCase<DeleteSiteRequirementParameter, void> {
	private requirementRepository: RequirementRepository;

	constructor(requirementRepository: RequirementRepository) {
		this.requirementRepository = requirementRepository;
	}

	async Call({ companyId, siteId, requirementId, subject, resourceId }: DeleteSiteRequirementParameter): Promise<void> {
		return await this.requirementRepository.deleteSiteSupplierRequirement(companyId, siteId, requirementId, subject, resourceId);
	}
}

export default DeleteSiteSupplierRequirement;
