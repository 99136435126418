import UseCase from '../../../core/app/usecase';
import RequirementRepository from '../../repositories/requirementRepository';

interface Args {
	companyId: string;
	requirementId: string;
	variantId: string;
	requirementGroupId: string;
}

class RemoveVariantFromRequirement implements UseCase<Args, void> {
	constructor(private repository: RequirementRepository) {}

	async Call(args: Args): Promise<void> {
		return await this.repository.removeVariantFromRequirement(args.companyId, args.requirementId, args.variantId, args.requirementGroupId);
	}
}

export default RemoveVariantFromRequirement;
