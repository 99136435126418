import { SiteVehicleParams, SupplierVehicle } from '../../../domain/entities/siteVehicle';
import { makeGetCompanySupplierVehicle } from '../../../core/app/factory/suppliers/useCases';
import { RequirementSubject } from '../../../domain/entities/requirementSubject.enum';
import SupplierResourceViewModel from './SupplierResourceViewModel';

class SupplierVehicleViewModel extends SupplierResourceViewModel<SiteVehicleParams> {
	constructor(
		public companyId: string,
		public supplierId: string,
		public resourceId: string,
	) {
		super(companyId, supplierId, resourceId, RequirementSubject.VEHICLE);
	}
	async getSupplierResource(): Promise<SupplierVehicle> {
		return await makeGetCompanySupplierVehicle().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			resourceId: this.resourceId,
		});
	}
}

export default SupplierVehicleViewModel;
