import { useState } from "react";
import Company from "../../../domain/entities/company";
import UpdateCompanyParameter from "./updateCompanyParameter";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import CompanyDetailViewModel from "../../viewmodels/company/CompanyDetailViewModel";
import StaffViewModel from "../../viewmodels/staff/StaffViewModel";
import Staff from "../../../domain/entities/staff";
import { GetStaffFilters } from "../../components/Views/Company/StaffTableView";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { Coords } from "google-map-react";
import { updateFilterWithDelete } from "../../../utils";
import {CustomField} from "../../../domain/entities/customFields";

const useCompanyDetailViewModel = () => {
  const { companyId } = useAuth();
  const companyViewModel = new CompanyDetailViewModel();
  const staffViewModel = new StaffViewModel();

  const [staffFilter, setStaffFilter] = useState<GetStaffFilters>({});
  const [staffSort, setStaffSort] = useState<SortMeta>(null);

  const [systemStaffFilter, setSystemStaffFilter] = useState<GetStaffFilters>(
    {},
  );
  const [systemStaffSort, setSystemStaffSort] = useState<SortMeta>(null);

  const companyDetailQuery = useQuery<Company, Error>(["company-detail", companyId], async () =>
    companyViewModel.getCompany(companyId),
  );

  const updateMutation = useMutation(
    async (updatedCompanyParameter: UpdateCompanyParameter) =>
      companyViewModel.updateCompany(updatedCompanyParameter),
    {
      onError: (err) => {
        // handle error
        console.error(err);
      },
      onSuccess: () => companyDetailQuery.refetch(),
    },
  );

  const updateCompanyField = async (fieldName: string, value: any) => {
    updateMutation.mutate({
      company: {
        ...companyDetailQuery.data,
        [fieldName]: value,
      },
    });
  };

  const updateCompanyFields = async (company: Company) => {
    return updateMutation.mutateAsync({ company });
  };

  const updateCompanyLogo = async (company: Company, imageFile: File) => {
    return updateMutation.mutateAsync({ company, imageFile });
  };

  const updateFilter = (
    field: string,
    value: string | string[] | [Date, Date],
  ) => {
    updateFilterWithDelete(setStaffFilter, field, value);
  };

  const updateSystemFilter = (
    field: string,
    value: string | string[] | [Date, Date],
  ) => {
    updateFilterWithDelete(setSystemStaffFilter, field, value);
  };

  const getStaffQuery = useQuery<Staff[], Error>(
    ["staff-list", companyId, staffFilter, staffSort],
    async () => staffViewModel.getStaff(companyId, staffFilter, staffSort),
  );

  const getCustomFieldsQuery = useQuery<CustomField[], Error>(
    ["company-custom-fields", companyId],
    async () => await companyViewModel.getCustomFields(companyId),
  );

  const createStaffMutation = useMutation(
    async (staffElement: Staff) =>
      staffViewModel.createStaff(companyId, staffElement),
    {
      onSuccess: () => getStaffQuery.refetch(),
    },
  );

  const updateStaffMutation = useMutation(
    async (staffElement: Staff) =>
      staffViewModel.updateStaffElement(companyId, staffElement),
    {
      onError: (err) => {
        // handle error
        console.error(err);
      },
      onSuccess: () => getStaffQuery.refetch(),
    },
  );

  const deleteStaffMutation = useMutation(
    async (staffElementId: string) =>
      staffViewModel.deleteStaffElement(companyId, staffElementId),
    {
      onError: (err) => console.error("cannot delete supplier", err),
      onSuccess: () => getStaffQuery.refetch(),
    },
  );

  const getSystemStaffQuery = useQuery<Staff[], Error>(
    ["system-staff-list", companyId, systemStaffFilter, systemStaffSort],
    async () =>
      staffViewModel.getSystemStaff(
        companyId,
        systemStaffFilter,
        systemStaffSort,
      ),
  );

  const updateSystemStaffMutation = useMutation(
    async (staffElement: Staff) =>
      staffViewModel.updateSystemStaffElement(companyId, staffElement),
    {
      onError: (err) => {
        // handle error
        console.error(err);
      },
      onSuccess: () => getSystemStaffQuery.refetch(),
    },
  );

  const { data: coordinates } = useQuery<Coords, Error>(
    ["coordinates", companyDetailQuery.data?.address],
    async () => {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(`${companyDetailQuery.data.address.street}, ${companyDetailQuery.data.address.city}, ${companyDetailQuery.data.address.cap}`)}&key=AIzaSyD0SMt6OZ4C1BfJNlw5b6ZoAOAPO-F_RrQ`,
      );
      const data = await response.json();
      const { lat = 0, lng = 0 } = data?.results[0]?.geometry?.location ?? {};
      return { lat, lng };
    },
    { enabled: !!companyDetailQuery.data?.address },
  );

  return {
    error: companyDetailQuery.error,
    company: companyDetailQuery.data,
    isFetching: companyDetailQuery.isLoading,
    updateCompanyField,
    updateCompanyFields,
    updateCompanyLogo,
    staff: getStaffQuery.data,
    staffIsFetching: getStaffQuery.isFetching,
    systemStaff: getSystemStaffQuery.data,
    systemStaffIsFetching: getSystemStaffQuery.isFetching,
    createStaff: createStaffMutation.mutateAsync,
    updateFilter,
    staffFilter,
    staffSort,
    setStaffSort,
    updateSystemFilter,
    systemStaffFilter,
    systemStaffSort,
    setSystemStaffSort,
    deleteStaff: deleteStaffMutation.mutateAsync,
    updateStaff: updateStaffMutation.mutateAsync,
    updateSystemStaff: updateSystemStaffMutation.mutate,
    updateStaffIsLoading: updateStaffMutation.isLoading,
    deleteStaffIsLoading: deleteStaffMutation.isLoading,
    createStaffIsLoading: createStaffMutation.isLoading,
    coordinates: coordinates,
    customFields: {
      data: getCustomFieldsQuery.data,
      isLoading: getCustomFieldsQuery.isLoading,
      error: getCustomFieldsQuery.error
    },
    loading: companyDetailQuery.isLoading || getCustomFieldsQuery.isLoading || getSystemStaffQuery.isLoading || getStaffQuery.isLoading,
  };
};

export { useCompanyDetailViewModel };
