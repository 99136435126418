import React from "react";
import {useTranslation} from "react-i18next";
import BaseSelectField, {BaseSelectFieldProps} from "./BaseSelectField";

type Option = {
  value: string;
  label: string;
}

export type EnumSelectFieldProps<IsMulti extends boolean> = Omit<BaseSelectFieldProps<Option, IsMulti>, 'optionKeys' | "selectProps.options"> & {
  enumOptions: Record<string, string | number>
  translation?: {namespace?: string, prefix?: string}
}
const EnumSelectField = <IsMulti extends boolean>({
  selectProps, enumOptions, translation, ...baseProps
}: EnumSelectFieldProps<IsMulti>) => {
  const { t } = useTranslation(translation?.namespace);
  const options = Object.values(enumOptions).map((value) =>
    ({ value, label: t(translation?.prefix ? `${translation.prefix}.${value}` : `${value}`) })) as Option[];
  return (
    <BaseSelectField
      selectProps={{
        ...selectProps,
        options,
    }}
      {...baseProps}
    />
  )
}

export default EnumSelectField;