import UseCase from '../../../core/app/usecase';
import BadgeReader from '../../entities/badgeReader';
import BadgeReaderRepository from '../../repositories/badgeReaderRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	badgeReader: Partial<BadgeReader>;
};

class UpdateBadgeReaderStatus implements UseCase<ARGS, void> {
	private badgeReaderRepository: BadgeReaderRepository;

	constructor(badgeReaderRepository: BadgeReaderRepository) {
		this.badgeReaderRepository = badgeReaderRepository;
	}

	async Call({ companyId, siteId, badgeReader }: ARGS): Promise<void> {
		return await this.badgeReaderRepository.updateBadgeReaderStatus(companyId, siteId, badgeReader);
	}
}

export default UpdateBadgeReaderStatus;
