import User from '../../entities/user';
import UseCase from '../../../core/app/usecase';
import { GetUsersFilter } from '../../repositories/filters';
import UsersRepository from '../../repositories/usersRepository';

interface Args {
	companyId: string;
	filters?: GetUsersFilter;
	pageParam?: number
}

class GetUsers implements UseCase<Args, Array<User>> {
	constructor(private repository: UsersRepository) {}

	async Call({ companyId, filters, pageParam }: Args): Promise<Array<User>> {
		return await this.repository.getUsers(companyId, filters, pageParam);
	}
}

export default GetUsers;
