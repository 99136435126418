import UseCase from '../../../core/app/usecase';
import RequirementsGroup from '../../entities/requirementsGroup';
import RequirementsGroupRepository from '../../repositories/requirementsGroupRepository';

class GetRequirementsGroup implements UseCase<string, RequirementsGroup[]> {
	private requirementsGroupRepository: RequirementsGroupRepository;

	constructor(requirementsGroupRepository: RequirementsGroupRepository) {
		this.requirementsGroupRepository = requirementsGroupRepository;
	}

	async Call(companyId) {
		return await this.requirementsGroupRepository.getRequirementsGroups(companyId);
	}
}

export default GetRequirementsGroup;
