import { ResourceSites } from '../../entities/site';
import UseCase from '../../../core/app/usecase';
import { GetResourceSitesFilter } from '../../repositories/siteRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import SupplierRepository from '../../repositories/supplierRepository';

interface Args {
	companyId: string;
	resourceId: string;
    resourceType: RequirementSubject;
	filter?: GetResourceSitesFilter;
	sort?: SortMeta;
	pageParam?: number;
	includeWorkingSites?: boolean;
	ignoreUserSiteRoleRestrictions?: boolean;
}

class GetSupplierResourceSites implements UseCase<Args, ResourceSites> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, resourceId, resourceType, filter, sort, pageParam, includeWorkingSites, ignoreUserSiteRoleRestrictions }: Args): Promise<ResourceSites> {
		return await this.supplierRepository.getResourceSites(companyId, resourceId, resourceType, filter, sort, pageParam, includeWorkingSites, ignoreUserSiteRoleRestrictions);
	}
}

export default GetSupplierResourceSites;