import { UseWebHookViewModel } from "../../../hooks/WebHook/useWebHookViewModel";
import { Td, Tr } from "@chakra-ui/react";
import DeleteButton from "../../../components/Common/table/DeleteButton";
import EndpointField from "./EndpointField";
import { useTranslation } from "react-i18next";
import EventSelectField from "./EventSelectField";
import SiteSelectField from "./SiteSelectField";

interface Option {
  value: string;
  label: string;
}

interface WebHookRowProps {
  index: number;
  events: Option[];
  ownedSites: UseWebHookViewModel["ownedSites"]["data"];
  remove: (index: number) => void;
}

const WebHookRow = ({ index, events, ownedSites, remove }: WebHookRowProps) => {
  const { t } = useTranslation("webhooks");
  return (
    <Tr>
      <Td w="35%">
        <EndpointField index={index} />
      </Td>
      <Td w="35%">
        <EventSelectField index={index} options={events} />
      </Td>
      <Td w="calc(30% - 60px)">
        <SiteSelectField
          index={index}
          options={ownedSites.map((site) => ({
            value: site.id,
            label: site.name,
          }))}
        />
      </Td>
      <Td w="60px">
        <DeleteButton onClick={() => remove(index)} />
      </Td>
    </Tr>
  );
};

export default WebHookRow;
