import UseCase from '../../../core/app/usecase';

import {
	RequestFilesArchiveContext,
	RequestDocumentFilesArchive,
	RequestResourceFilesArchive,
	RequestSupplierOrSiteFilesArchive,
	RequestDocumentsFiles,
} from './common';

import { DocumentsDownloader, makeDocumentsDownloaderRepository } from '../../repositories/documentsDownloader';

/**
 * Initiates the download request of all files belonging to
 * a document belonging to a specific context.
 */
export class InitiateFilesArchiveDownload implements UseCase<RequestFilesArchiveContext, boolean> {
	constructor(private repository: DocumentsDownloader) {}

	async Call(input: RequestFilesArchiveContext): Promise<boolean> {
		if (input instanceof RequestDocumentFilesArchive) {
			return this.repository.downloadDocumentZippedFiles(input.companyId, input.documentId, input.renameModel);
		}

		if (input instanceof RequestSupplierOrSiteFilesArchive) {
			return this.repository.downloadCompanyOrSiteDocumentZippedFiles(input.companyId, input.siteId, input.requirementId, input.renameModel);
		}

		if (input instanceof RequestResourceFilesArchive) {
			return this.repository.downloadResourceDocumentZippedFiles(
				input.companyId,
				input.siteId,
				input.requirementId,
				input.resourceId,
				input.scope,
				input.renameModel,
			);
		}
		if (input instanceof RequestDocumentsFiles) {
			return this.repository.downloadDocumentsFilesZipped(input.companyId, input.siteId, input.resourceId, input.resourceScope, input.renameModel);
		}

		return Promise.reject(new Error('unsupported'));
	}
}

/**
 * InitiateFilesArchiveDownload factory method.
 * @returns an instance of InitiateFilesArchiveDownload
 */
export const makeInitiateFilesArchiveDownload = (): InitiateFilesArchiveDownload => {
	return new InitiateFilesArchiveDownload(makeDocumentsDownloaderRepository());
};
