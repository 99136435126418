import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  Text,
  ModalOverlay,
  ModalHeader,
  Box,
  IconButton,
  Spacer,
  Skeleton,
  Switch,
  FormControl,
  FormLabel,
  ModalBody,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { MentionsInput, Mention } from "react-mentions";
import { MdSend } from "react-icons/md";
import { COLORS } from "../../../assets/theme/colors";
import { MessageDirection, MessageView } from "./MessageView";
import { darken } from "polished";
import { useAuth } from "../../../providers/Auth0JWTProvider";
import { formatDateBasedOnLanguage } from "../../../../utils";
import Style from "./mentions.module.css";
import "./mentionStyle.css";
import LoadingView from "../../../screens/Common/LoadingView";
import { DocumentCommunicationHook } from "../../../hooks/Document/useDocumentCommunicationVIewModel";
import { DeleteActionAlert } from "../../../screens/Common/DeleteActionAlert";
import { ResourceDocumentsPermissions } from "./SiteResourceDocuments";

type DocumentCommunicationModalProps = {
  onClose: () => void;
  hook: DocumentCommunicationHook;
  permissions?: ResourceDocumentsPermissions;
};

const DocumentCommunicationModal = ({
  onClose,
  hook,
  permissions,
}: DocumentCommunicationModalProps) => {
  const isReady = hook.getNotificationsStatus && hook.resourceDocumentNote;
  return isReady ? (
    <Wrapper hook={hook} onClose={onClose} permissions={permissions} />
  ) : (
    <LoadingModal onClose={onClose} />
  );
};

const Wrapper = ({
  onClose,
  hook,
  permissions,
}: {
  onClose: () => void;
  hook: DocumentCommunicationHook;
  permissions: ResourceDocumentsPermissions;
}) => {
  const { t } = useTranslation("communication");
  const { user } = useAuth();
  const {
    resourceDocumentNote,
    resourceDocumentNoteFetching,
    createResourceDocumentNote,
    createResourceDocumentIsLoading,
    resourceDocumentComments,
    createResourceDocumentComment,
    resourceDocumentCommentsFetching,
    taggableUsers,
    taggableDocuments,
    updateNotificationsStatus,
    getNotificationsStatus,
    isFetching,
  } = hook;
  
  const [statusChat, setStatusChat] = useState(getNotificationsStatus?.status);
  const [textNote, setTextNote] = useState(resourceDocumentNote.note);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [askConfirmNotSave, setAskConfirmNotSave] = useState(false);
  const [textComment, setTextComment] = useState<string>("");
  const [isTablet] = useMediaQuery("(max-width: 1300px)");

  let recoveredUsers = [];
  recoveredUsers = taggableUsers?.map((item) => ({
    id: item.id,
    display: item.name,
  }));

  let recoveredDocuments = [];
  recoveredDocuments = taggableDocuments?.map((item) => ({
    id: item.id,
    display: item.name,
  }));

  const handleClose = async () => {
    if (textNote !== resourceDocumentNote.note) {
      setAskConfirmNotSave(true);
    } else {
      onClose();
    }
  };

  const handleButtonClick = async () => {
    await createResourceDocumentComment(textComment);
    setTextComment("");
  };

  const handleChatStatus = async (active: boolean) => {
    setStatusChat(active);
    await updateNotificationsStatus(active);
  };

  return (
    <>
      <Modal
        isOpen={true}
        onClose={handleClose}
        size={"6xl"}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent
          marginLeft={isTablet ? 0 : 40}
          marginRight={isTablet ? 0 : 40}
        >
          <ModalHeader
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            height={50}
            width="100%"
          >
            
            <Box mb={1}>
              <Text fontSize="xl" fontWeight={500}>
                {t(`noteTitle`)}
              </Text>
              <Text fontSize="sm" marginTop={2}>
                {t(`noteSubtitle`)}
              </Text>
            </Box>

            <FormControl
              display="flex"
              alignItems="center"
              width="auto"
              marginTop={7}
            >
              <FormLabel htmlFor="email-alerts" mb="0">
                {statusChat
                  ? t("activeNotifications", { ns: "communication" })
                  : t("silentNotification", { ns: "communication" })}
              </FormLabel>
              <Switch
                autoFocus={false}
                id="email-alerts"
                isChecked={statusChat}
                onChange={(e) => handleChatStatus(e.target.checked)}
              />
            </FormControl>
          </ModalHeader>

          <ModalCloseButton marginRight={2} />

          <ModalBody sx={{ display: "flex" }}>
              <Flex
                position="relative"
                width={"50%"}
                marginBottom={14}
                marginRight={5}
              >
                <Box flex="1">
                  <ReactQuill
                    style={{ height: "86%", width: "100%" }}
                    value={textNote}
                    onChange={(content) => {
                      setIsButtonDisabled(false), setTextNote(content);
                    }}
                  />
                  <Button
                    spinnerPlacement="start"
                    isDisabled={isButtonDisabled}
                    colorScheme="blue"
                    paddingLeft={10}
                    paddingRight={10}
                    marginTop={65}
                    isLoading={createResourceDocumentIsLoading}
                    onClick={async () => {
                      await createResourceDocumentNote(textNote),
                        setIsButtonDisabled(true);
                    }}
                  >
                    {t("save", { ns: "common" })}
                  </Button>
                </Box>
                {resourceDocumentNoteFetching && <LoadingNote />}
              </Flex>

            <Flex
              width={"50%"}
              flexDirection={"column"}
              marginBottom={3}
            >
              <Flex
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
                height="calc(90vh - 275px)"
              >
                <Box overflowY={"auto"} width={"100%"} paddingTop={2}>
                  {Array.isArray(resourceDocumentComments) &&
                  !resourceDocumentCommentsFetching ? (
                    resourceDocumentComments?.map((msg, index) => (
                      <MessageView
                        key={index}
                        message={msg.comment}
                        author={msg.author?.name}
                        messageDirection={
                          user.id === msg.author?.id
                            ? MessageDirection.SENT
                            : MessageDirection.RECEIVED
                        }
                        messageDate={formatDateBasedOnLanguage(
                          msg.createdAt as unknown as string,
                          true
                        )}
                        messageType={msg.type}
                        messageId={msg.id}
                      />
                    ))
                  ) : (
                    <LoadingComments />
                  )}
                </Box>
              </Flex>
              <Flex
                marginTop={4}
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
              >
                <Flex
                  flexDirection={"row"}
                  width={"88%"}
                  paddingLeft={2}
                  paddingRight={2}
                  className="comment-input-container"
                >
                  <MentionsInput
                    value={textComment}
                    onChange={(event) => setTextComment(event.target.value)}
                    className="mentions"
                    classNames={Style}
                    placeholder={t("mention")}
                    allowSuggestionsAboveCursor
                    forceSuggestionsAboveCursor
                  >
                    <Mention
                      data={recoveredDocuments ?? []}
                      trigger="#"
                      className={Style.mentions__mention}
                      markup="#[__display__](__id__)"
                      appendSpaceOnAdd
                    ></Mention>

                    <Mention
                      data={recoveredUsers}
                      className={Style.mentions__mention}
                      appendSpaceOnAdd
                    />
                  </MentionsInput>
                </Flex>
                <Spacer width={5} />
                <IconButton
                  isDisabled={isFetching || textComment === ""}
                  borderRadius="50%"
                  aria-label="Circular Button"
                  icon={<MdSend />}
                  marginRight={2}
                  alignSelf={"center"}
                  backgroundColor={COLORS.sikuroBlue}
                  color="white"
                  _hover={{ backgroundColor: darken(0.1, COLORS.sikuroBlue) }}
                  onClick={handleButtonClick}
                />
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {askConfirmNotSave && (
        <DeleteActionAlert
          onConfirm={async () => {
            onClose();
          }}
          onCancel={() => {
            setAskConfirmNotSave(false);
          }}
          mainTitle={t("warning", { ns: "common" })}
          title={t("confirmNotSave", { ns: "communication" })}
          leftButtonText={t("confirm", { ns: "common" })}
          rightButtonText={t("cancel", { ns: "common" })}
          isOpen={!!askConfirmNotSave}
        />
      )}
    </>
  );
};

const LoadingModal = ({ onClose }: { onClose?: () => void }) => (
  <Modal isOpen={true} onClose={onClose} size={"6xl"}>
    <ModalOverlay />
    <ModalContent
      style={{ background: "white", padding: "2rem" }}
      marginLeft={40}
      marginRight={40}
      sx={{ height: "70vh" }}
    >
      <LoadingView />
    </ModalContent>
  </Modal>
);

const LoadingNote = () => (
  <Flex
    position="absolute"
    marginTop={14}
    marginLeft={2}
    marginRight={2}
    width="97%"
    borderRadius={8}
    height={"96%"}
    backgroundColor={"white"}
    zIndex={1}
  >
    <Skeleton
      height={"100%"}
      width={"100%"}
      borderRadius={8}
      startColor="gray.200"
      endColor="gray.300"
    />
  </Flex>
);

const SkeletonComment = () => (
  <Skeleton
    height={"15%"}
    width={"50%"}
    borderRadius={8}
    startColor="gray.200"
    endColor="gray.300"
    alignSelf={"start"}
  />
);

const LoadingComments = () => {
  return (
    <Flex
      width={"100%"}
      height={"80%"}
      padding={2}
      flexDirection={"column"}
      alignItems={"start"}
    >
      <SkeletonComment />
      <SkeletonComment />
    </Flex>
  );
};

export default DocumentCommunicationModal;
