import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

const WorkerIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 16 19" {...props}>
      <path d="M12.1434 6.99074C12.1434 11.9568 4.68974 11.9568 4.68974 6.99074H6.55317C6.55317 9.47841 10.28 9.47841 10.28 6.99074M15.8703 15.3762V18.1713H0.962891V15.3762C0.962891 12.8885 5.92892 11.6493 8.41659 11.6493C10.9043 11.6493 15.8703 12.8885 15.8703 15.3762ZM14.1 15.3762C14.1 14.7799 11.1838 13.4196 8.41659 13.4196C5.64941 13.4196 2.73315 14.7799 2.73315 15.3762V16.401H14.1M8.88245 0.46875C9.14333 0.46875 9.34831 0.673727 9.34831 0.934606V3.72975H10.28V1.40046C10.9394 1.70562 11.4918 2.20192 11.8657 2.82495C12.2395 3.44797 12.4174 4.16898 12.3764 4.89439C12.3764 4.89439 13.0286 5.02483 13.0752 6.05903H3.75803C3.75803 5.02483 4.45681 4.89439 4.45681 4.89439C4.41577 4.16898 4.59371 3.44797 4.96753 2.82495C5.34134 2.20192 5.89379 1.70562 6.55317 1.40046V3.72975H7.48488V0.934606C7.48488 0.673727 7.68986 0.46875 7.95074 0.46875" />
    </Icon>
  );
};

export default WorkerIcon;
