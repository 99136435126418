import UseCase from '../../../core/app/usecase';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import Specialization from '../../entities/specialization';
import SpecializationRepository from '../../repositories/specializationRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	type: DocumentTypeCategory;
	search?: string;
};

class GetSpecializations implements UseCase<ARGS, Specialization[]> {
	private specializationRepository: SpecializationRepository;

	constructor(specializationRepository: SpecializationRepository) {
		this.specializationRepository = specializationRepository;
	}

	async Call(args: ARGS): Promise<Specialization[]> {
		return await this.specializationRepository.getSiteSpecializations(args.companyId, args.siteId, args.type, args.search);
	}
}

export default GetSpecializations;
