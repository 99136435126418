import { FaRegSave } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";
import LoadingView from "../../Common/LoadingView";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { COLORS } from "../../../assets/theme/colors";
import DataBox from "../../../components/Common/DataBox";
import ContentLayout from "../../../layout/ContentLayout";
import { useNavigate, useParams } from "react-router-dom";
import { MdCancel, MdClose, MdEdit } from "react-icons/md";
import ActionBar from "../../../components/Common/ActionBar";
import ResourceDocumentsView, {
  ResourceDocumentProps,
} from "../ResourceDocumentsView";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import { FormProvider, useForm } from "react-hook-form";
import { Permission } from "../../../components/Permissions/Permissions";
import Worker from "../../../../domain/entities/worker";
import FormTextField from "../../../components/Common/forms/FormTextField";
import FormSelectField from "../../../components/Common/forms/FormSelectField";
import useWorkerViewModel from "../../../hooks/Resources/Workers/useWorkerViewModel";
import RenderIf from "../../../components/Permissions/RenderIf";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { useDocumentFilesDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import DocumentCommunicationModal from "../../../components/Views/common/DocumentCommunicationModal";
import {
  fiscalCodeCountries,
  validateIdentityCode,
} from "../../../../infrastructure/utilities/validator";
import useWorkerDocumentsViewModel from "../../../hooks/Resources/Workers/useWorkerDocumentsViewModel";
import FormDateField from "../../../components/Common/forms/FormDateField";
import SelectSitesView from "../../../components/Views/common/SelectSitesView";
import BaseModal from "../../../components/Common/alerts/BaseModal";
import DOMPurify from "dompurify";
import SelectBadgesTable from "../../../components/Views/Badge/SelectBadgesTable";
import { BiLink } from "react-icons/bi";
import { Alert } from "../../Common/Alert";
import { DeleteActionAlert } from "../../Common/DeleteActionAlert";
import ResourceSitesList from "../ResourceSitesList";
import { LuPackagePlus } from "react-icons/lu";

interface Props {
  permissions: ResourceDocumentProps["permissions"] & {
    addDocument: Permission;
    setState: Permission;
    editRecord: Permission;
    linkBadge: Permission;
  };
}

const WorkerDetailView: FC<Props> = ({ permissions }) => {
  const { id } = useParams();
  const hook = useWorkerViewModel(id as string)
  return hook.loading ? <LoadingView /> : <WorkerDetailViewMain permissions={permissions} hook={hook} />
}

const WorkerDetailViewMain: FC<Props & {hook: ReturnType<typeof useWorkerViewModel>}> = ({ permissions, hook }) => {
  const { id } = useParams();
  const { t } = useTranslation("workers");
  const navigate = useNavigate();

  const downloader = useDocumentFilesDownloader();
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const [showManageTaskWithAI, setShowManageTaskWithAI] =
    useState<boolean>(false);
  const [showSiteCollection, setShowSiteCollection] = useState(false);
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>();
  const {
    worker,
    isFetching,
    hasSites,
    updateImage,
    updateWorker,
    isFetchingSites,
    hasNextPage,
    fetchNextPage,
    resourceDocumentSites,
    updateFilterResourceDocumentSites,
    filterResourceDocumentSites,
    setSortResourceDocumentSites,
    sortResourceDocumentSites,
    totalResourceDocumentSiteCount,
    isFetchingResourceDocumentSites,
    resourceDocumentSitesHasNextPage,
    resourceDocumentSitesFetchNextPage,
    resourceSites,
    setEnableGetWorkerSites,
    filterResourceSites,
    updateFilterResourceSites,
    setSortResourceSites,
    sortResourceSites,
    siteCollection,
    refetchSiteCollection,
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    setFilterSiteCollection,
    badgeIds,
    setBadgeIds,
    setBadgeId,

    updateFilterSiteCollection,
    siteCollectionHasNextPage,
    availableBadgesResource,
    availableBadgesFilters,
    availableBadgesSort,
    availableBadgesIsLoading,
    updateFilterAvailableBadge,
    setAvailableBadgesSort,
    linkBadgesToResource,
    linkBadgesToResourceIsLoading,
    showInfoSuccess,
    setShowInfoSuccess,
    unlinkBadgeResource,
    unlinkBadgeResourceIsLoading,
    availableBadgesRefetch,
    selectAll,
    setSelectAll,
    siteCollectionFetchNextPage,
    setEnableGetWorkerSiteCollection,
    siteCollectionIsLoading,
    linkWorkerToSites,
    isLinking,
    setFilterResourceDocumentSites,
    setFilterResourceSites,
    resourceLinkableSitesCount,
    customFields
  } = hook;

  const documentsHook = useWorkerDocumentsViewModel(id);

  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("addResourceToSites", {
      ns: "sites",
      resource: `${worker?.firstName} ${worker?.lastName}`,
    }),
  );
  const sanitizedHtmlContentLinkBadges = DOMPurify.sanitize(
    t("linkToBadges", {
      ns: "badges",
      resource: `${worker?.firstName} ${worker?.lastName}`,
    }),
  );
  const sanitizedHtmlContentUnlinkBadges = DOMPurify.sanitize(
    t("unlinkResourceFromBadge", {
      ns: "badges",
      resource: `${worker?.firstName} ${worker?.lastName}`,
    }),
  );

  const [showAddDocumentType, setShowAddDocumentType] =
    useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();
  const [linkBadgeModal, setLinkBadgeModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const goToWorkersList = () => {
    navigate(`/resources/workers`);
  };

  const onSubmit = async (data) => {
    if (uploadedImageFile) {
      await updateImage(
        {
          ...worker,
          ...data,
        },
        uploadedImageFile,
      );
    } else {
      await updateWorker({
        ...worker,
        ...data
      });
    }
    setIsEditing(false);
  };

  const methods = useForm<Worker>({ mode: "all", defaultValues: worker });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const {
    handleSubmit,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { isValid },
  } = methods;

  const fiscalCodeCountryCode = (
    watch("fiscalCodeCountryCode") ?? "-"
  ).toUpperCase();
  const isForeignFiscalCode = fiscalCodeCountryCode === "-";
  const validateFiscalCode = (code: string) => {
    let message = undefined;

    if (isForeignFiscalCode && code?.trim() === "") {
      message = t("fiscalCodeIsRequired", { ns: "companies" });
    } else if (!isForeignFiscalCode && !validateIdentityCode(code)) {
      message = t("notItalianFiscalCode", { ns: "companies" });
    }

    if (message) {
      setError("fiscalCode", { message });
      return message;
    }

    clearErrors("fiscalCode");
    return true;
  };

  const checkFiscalCode = () => {
    validateFiscalCode(worker.fiscalCode);
  };

  const refetchCommentsAndNotes = () => {
      documentsHook.communicationProps.refetchResourceDocumentComments();
      documentsHook.communicationProps.refetchResourceDocumentNotes();
      documentsHook.communicationProps.refetchNotificationStatus();
  };

  useEffect(() => {
    if (!linkBadgesToResourceIsLoading) {
      setLinkBadgeModal(false);
    }
  }, [linkBadgesToResourceIsLoading]);

  if (!worker) {
    return <LoadingView />;
  }

  const handleLinkToSites = async () => {
    await linkWorkerToSites(selectAll ? [] : selectedSiteIds, selectAll);
    setFilterSiteCollection(null);
    setShowSiteCollection(false);
    setSelectedSiteIds([]);
  };

  const goToSiteConnection = (siteId: string) => {
    navigate(`/sites/${siteId}/resources/workers/${id}`)
  }

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={goToWorkersList}
          />

          <RenderIf permissions={permissions.editRecord}>
            <ActionBarItem
              icon={isEditing ? MdCancel : MdEdit}
              onClick={() => {
                setIsEditing(!isEditing);
                reset(worker);
                if (isEditing) {
                  setUploadedImageFile(undefined);
                }
              }}
              description={t(isEditing ? "cancel" : "edit", { ns: "common" })}
            />
          </RenderIf>
          {isEditing && isValid && (
            <ActionBarItem
              description={t("confirm", { ns: "common" })}
              icon={FaRegSave}
              onClick={handleSubmit(onSubmit)}
            />
          )}
          <RenderIf permissions={permissions.documents.chat}>
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => {
                refetchCommentsAndNotes(), setShowCommunicationModal(true);
              }}
            />
          </RenderIf>
          <ActionBarItem
            bgColor={COLORS.yellow}
            color="black"
            icon={LuPackagePlus }
            description={t("addResourceToSitesTooltip", {
              ns: "sites",
              resource: `${worker.firstName} ${worker.lastName}`,
            })}
            onClick={() => {
              setEnableGetWorkerSiteCollection(true), setShowSiteCollection(true);
              refetchSiteCollection();
            }}
          />
          <RenderIf permissions={permissions.linkBadge}>
            <ActionBarItem
              icon={BiLink}
              description={t("linkToBadgesTooltip", {
                ns: "badges",
                resource: `${worker.firstName} ${worker.lastName}`,
              })}
              onClick={() => {
                availableBadgesRefetch();
                setLinkBadgeModal(true);
              }}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      {worker && (
        <Box marginLeft={7}>
          <Grid
            py={5}
            templateAreas={`
          "header header header"
          "documents documents documents"
        `}
            gridRowGap={4}
            gridColumnGap={4}
            templateColumns={"auto auto 250px"}
            paddingBottom={0}
          >
            <GridItem area={"header"}>
              <Box textStyle="h1" mb={2}>
                {worker?.firstName} {worker?.lastName}
              </Box>
              <Box
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
              >
                <FormProvider {...methods}>
                  <DataBox
                    title={t("details", { ns: "common" })}
                    isEditing={isEditing}
                    isLoading={isFetching}
                    image={{
                      url: uploadedImageFile
                        ? URL.createObjectURL(uploadedImageFile)
                        : worker.photo,
                      onUpdate: (f) => setUploadedImageFile(f),
                    }}
                    fields={[
                      <FormTextField
                        key="firstName"
                        name="firstName"
                        label={t("firstName")}
                        rules={requiredRule}
                      />,
                      <FormTextField
                        key="lastName"
                        name="lastName"
                        label={t("lastName")}
                        rules={requiredRule}
                      />,
                      <FormDateField
                        key="dateOfEmployment"
                        name="dateOfEmployment"
                        label={t("dateOfEmployment")}
                      />,
                      <FormTextField
                        key="jobTitle"
                        name="jobTitle"
                        label={t("jobTitle")}
                      />,
                      <FormDateField
                        key="dateOfBirth"
                        name="dateOfBirth"
                        label={t("dateOfBirth")}
                        rules={requiredRule}
                      />,
                      <FormTextField
                        key="placeOfBirth"
                        name="placeOfBirth"
                        label={t("placeOfBirth")}
                      />,

                      <FormTextField
                        key="email"
                        name="email"
                        label={t("email")}
                      />,
                      <FormSelectField
                        key="fiscalCodeCountryCode"
                        onChange={checkFiscalCode}
                        name="fiscalCodeCountryCode"
                        label={t("fiscalCodeCountryCode.title", {
                          ns: "companies",
                        })}
                        displayValue={t(
                          `fiscalCodeCountryCode.${fiscalCodeCountryCode}`,
                          { ns: "companies" },
                        )}
                        options={fiscalCodeCountries.map((c) => {
                          return {
                            id: c.id,
                            name: t(`fiscalCodeCountryCode.${c.name}`, {
                              ns: "companies",
                            }),
                          };
                        })}
                      />,
                      <FormTextField
                        key="fiscalCode"
                        name="fiscalCode"
                        label={t("fiscalCode")}
                        rules={{ validate: validateFiscalCode }}
                      />,
                      <FormTextField
                        key="phone"
                        name="phone"
                        label={t("phone")}
                      />,
                    ]}
                    customFields={customFields.data}
                  />
                </FormProvider>
              </Box>
            </GridItem>
          </Grid>
          <Box
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            padding={5}
          >
            <Tabs height="100%" defaultIndex={0} marginTop={4}isFitted>
              <TabList>
                <Tab>{t("documents", { ns: "common" })}</Tab>
                <Tab onClick={() => setEnableGetWorkerSites(true) }>{t("sites", { ns: "sites" })}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel key={"documents"} paddingLeft={0} paddingRight={0}>
                  <ResourceDocumentsView
                    category={DocumentTypeCategory.WORKER}
                    documentsHook={documentsHook}
                    resourcePath="/resources/workers"
                    showAddDocumentType={showAddDocumentType}
                    setShowAddDocumentType={setShowAddDocumentType}
                    showManageTaskWithAI={showManageTaskWithAI}
                    setShowManageTaskWithAI={setShowManageTaskWithAI}
                    downloader={downloader}
                    resourceSites={resourceDocumentSites}
                    hasSites={hasSites}
                    isFetchingSites={isFetchingResourceDocumentSites}
                    hasNextPage={resourceDocumentSitesHasNextPage}
                    fetchNextPage={resourceDocumentSitesFetchNextPage}
                    siteCount={totalResourceDocumentSiteCount}
                    filterResourceSites={filterResourceDocumentSites}
                    updateFilterResourceSites={updateFilterResourceDocumentSites}
                    setSortResourceSites={setSortResourceDocumentSites}
                    sortResourceSites={sortResourceDocumentSites}
                    permissions={permissions}
                    resourceId={worker.id}
                    setFilterResourceSites={setFilterResourceDocumentSites}
                  />
                </TabPanel>
                <TabPanel key={"site"} paddingLeft={0} paddingRight={0}>
                  <ResourceSitesList
                    sites={resourceSites}
                    sitesFilter={filterResourceSites}
                    updateSitesFilter={updateFilterResourceSites}
                    setSitesSort={setSortResourceSites}
                    sitesSort={sortResourceSites}
                    sitesLoading={isFetchingSites}
                    getSitesHasNextPage={hasNextPage}
                    getSitesFetchNextPage={fetchNextPage}
                    showBadgeColumns
                    navigateAction={(siteId)=> goToSiteConnection(siteId)}
                    showUnlink={true}
                    unlinkBadgeAction={(badgeId) => {
                      setBadgeId(badgeId);
                      setShowDeleteAlert(true);
                    }}
                  ></ResourceSitesList>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      )}

      {showCommunicationModal && (
        <DocumentCommunicationModal
          onClose={() => setShowCommunicationModal(false)}
          hook={documentsHook.communicationProps}
          permissions={permissions.documents}
        />
      )}
      {showSiteCollection && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setShowSiteCollection(false)}
          footer={[
            <Button
              key="confirm"
              isDisabled={!selectedSiteIds || selectedSiteIds?.length === 0}
              colorScheme="blue"
              onClick={() => handleLinkToSites()}
              isLoading={isLinking}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => {
                setFilterSiteCollection(null);
                setShowSiteCollection(false);
              }}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <Text
            fontSize={16}
            marginTop={3}
            marginBottom={0}
            dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
          />
          <SelectSitesView
            autosize
            title={""}
            siteSelectedAction={setSelectedSiteIds}
            siteList={siteCollection}
            isFetchingSites={siteCollectionIsLoading}
            setSortResourceSites={setSortSiteCollection}
            sortResourceSites={sortSiteCollection}
            filterResourceSites={filterSiteCollection}
            updateFilterResourceSites={updateFilterSiteCollection}
            hasNextPage={siteCollectionHasNextPage}
            fetchNextPage={() => siteCollectionFetchNextPage()}
            includeFooterButton={false}
            setSelectAllSites={setSelectAll}
            selectAllSites={selectAll}
            siteCount={resourceLinkableSitesCount}
            showSelectAll={true}
            hideSubtitles={true}
          />
        </BaseModal>
      )}
      {linkBadgeModal && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setLinkBadgeModal(false)}
          title={
            <Text
              fontWeight={"normal"}
              fontSize={16}
              dangerouslySetInnerHTML={{
                __html: sanitizedHtmlContentLinkBadges,
              }}
            />
          }
          footer={[
            <Button
              key="confirm"
              isDisabled={!badgeIds || badgeIds?.length === 0}
              colorScheme="blue"
              onClick={async () => {
                await linkBadgesToResource();
              }}
              isLoading={linkBadgesToResourceIsLoading}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => setLinkBadgeModal(false)}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <SelectBadgesTable
            autosize
            key="step3"
            badges={availableBadgesResource}
            badgesFilters={availableBadgesFilters}
            badgesSort={availableBadgesSort}
            setBadgesSort={setAvailableBadgesSort}
            updateBadgesFilter={updateFilterAvailableBadge}
            badgeSitesIsLoading={availableBadgesIsLoading}
            setBadgeIds={setBadgeIds}
            badgeIds={badgeIds}
            selectAllAvailable={false}
          />
        </BaseModal>
      )}
      {showInfoSuccess && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(showInfoSuccess, { ns: showInfoSuccess === 'resourceAssociatedToSite' ? "siteResources" : "badges"})}
          variant="info"
          onClose={() => {
            setShowInfoSuccess(null);
          }}
        />
      )}
      <DeleteActionAlert
        mainTitle={t("warning", { ns: "common" })}
        title={
          <Text
            fontWeight={"normal"}
            fontSize={20}
            dangerouslySetInnerHTML={{
              __html: sanitizedHtmlContentUnlinkBadges,
            }}
          />
        }
        onConfirm={() => {
          unlinkBadgeResource(), setShowDeleteAlert(false);
        }}
        isOpen={showDeleteAlert}
        isLoading={unlinkBadgeResourceIsLoading}
        onCancel={() => setShowDeleteAlert(false)}
      />
    </ContentLayout>
  );
};

export default WorkerDetailView;
