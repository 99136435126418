import { FC, useState } from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Textarea,
} from "@chakra-ui/react";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { COLORS } from "../../assets/theme/colors";
/**
 * This component is use dot display an input field with an animated label that will be both the placeholder and the upperLabel
 *
 * @param id: this string will be used to identify the control and has to be the name of the field we ant to change in case
 *      we are handling changes with the "handleOnChange" callback;
 * @param label: this string will be the label that will be contained in the input field if no value is inserted and will animate to the top
 *      once we start inserting values;
 * @param isProtected: if true, the text field input will be obfuscated;
 *      this option could be useful for acquiring passwords, but is ignored for multiline fields;
 * @param defaultValue: this will be the initialValue contained in the input field;
 * @param marginTop: this value will be used to set a topMargin to this component;
 * @param showButton: this paremeter is used to show or not the X and V button in the right section of the input field;
 * @param isMultiLine: this value will be used to set multiline input field;
 * @param handleOnChange: this function is the callback called when the value in the input field changes, it uses the "id" field
 *      to understand which field wil change in the original object
 * @param handleDismiss: this function is the callback called when we want to dismiss the change made
 */

export type InputAnimatedProps = {
  id: string;
  label: string;
  width?: string;
  marginTop?: number;
  defaultValue?: string;
  isRequired?: boolean;
  isProtected?: boolean;
  isDisabled?: boolean;
  errorMessages?: string | Array<string>;
  placeholder?: string;
  type?: string;
  showButton?: boolean;
  isMultiLine?: boolean;
  handleOnChange: (fieldName: string, value: string) => void;
  handleDismiss?: () => void;
  action?: {
    icon: any;
    handler: () => void;
  };
  handleBlur?: () => void;
};

const InputAnimatedLabel: FC<InputAnimatedProps> = ({
  id,
  label,
  marginTop,
  defaultValue,
  width,
  isRequired,
  isProtected,
  isDisabled = false,
  errorMessages,
  placeholder,
  type,
  handleOnChange,
  handleDismiss,
  showButton,
  isMultiLine,
  handleBlur,
}) => {
  const [edited, setEdited] = useState(defaultValue);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setEdited(inputValue);
    handleOnChange(id, e.target.value);
  };

  if (!type) {
    type = isProtected ? "password" : "text";
  }

  return (
    <Box marginTop={marginTop} width={width}>
      <FormControl
        id={id}
        variant="floating"
        borderColor="gray.400"
        marginTop={2}
        isInvalid={errorMessages?.length > 0}
        isRequired={isRequired}
      >
        {isMultiLine ? (
          <InputGroup>
            <Textarea
              value={edited}
              borderRadius={5}
              borderColor="gray.200"
              onChange={handleInputChange}
              placeholder={placeholder}
              size="sm"
              isDisabled={isDisabled}
            />
          </InputGroup>
        ) : (
          <InputGroup>
            <Input
              type={type}
              paddingRight={showButton ? 14 : 0}
              color="black"
              backgroundColor="white"
              defaultValue={defaultValue}
              placeholder={placeholder}
              isDisabled={isDisabled}
              onBlur={handleBlur}
              onChange={(e) => {
                if (showButton) {
                  setEdited(e.target.value);
                } else {
                  handleOnChange(id, e.target.value);
                }
              }}
            />
            {showButton && (
              <InputRightElement>
                <IconButton
                  size="5"
                  margin={0}
                  style={{ backgroundColor: "transparent" }}
                  color={COLORS.sikuroBlue}
                  outlineColor="transparent"
                  fontSize="2xl"
                  backgroundColor="transparent"
                  onClick={() => {
                    handleOnChange(id, edited);
                    handleDismiss();
                  }}
                  aria-label="Edit"
                >
                  <MdCheckCircle />
                </IconButton>
                <IconButton
                  size="5"
                  style={{ backgroundColor: "transparent" }}
                  variant="solid"
                  color="red"
                  fontSize="2xl"
                  backgroundColor="transparent"
                  onClick={handleDismiss}
                  marginRight="4"
                  aria-label="Edit"
                >
                  <MdCancel />
                </IconButton>
              </InputRightElement>
            )}
          </InputGroup>
        )}
        <FormLabel backgroundColor="white" borderRadius={4} color="gray.500">
          {label}
        </FormLabel>
        {errorMessages && !Array.isArray(errorMessages) && (
          <FormErrorMessage>{errorMessages}</FormErrorMessage>
        )}

        {errorMessages && Array.isArray(errorMessages) && (
          <FormErrorMessage>
            <ul>
              {errorMessages.map((message) => (
                <li key={message.replaceAll(" ", "-")}>{message}</li>
              ))}
            </ul>
          </FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};

export default InputAnimatedLabel;
