import { FC, useEffect } from "react";
import TopBar from "../Common/TopBar";
import MainNavBar from "../Common/MainNavBar";
import { useTranslation } from "react-i18next";
import MainLayout from "../../layout/MainLayout";
import { useApp } from "../../../app";
import LicenseNotActiveView from "./LicenseNotActiveView";

const LicenseNotActiveMainView: FC = () => {
  const { t } = useTranslation("onboarding");
  const { updateContext } = useApp();

  useEffect(() => {
    updateContext({ topBarTitle: undefined });
  }, []);

  return (
    <MainLayout
      nav={<MainNavBar hideNavItems={true} />}
      header={<TopBar title={undefined ?? t("licenseDisabled")} />}
    >
      <LicenseNotActiveView></LicenseNotActiveView>
    </MainLayout>
  );
};

export default LicenseNotActiveMainView;
