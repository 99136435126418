import UseCase from '../../../core/app/usecase';
import Tag from '../../entities/tag';
import TagRepository from '../../repositories/tagRepository';
type CreateTagParams = {
	companyId: string;
	tag: Tag;
};
class CreateTag implements UseCase<CreateTagParams, Tag> {
	private tagRepository: TagRepository;

	constructor(tagRepository: TagRepository) {
		this.tagRepository = tagRepository;
	}

	async Call({ companyId, tag }: CreateTagParams): Promise<Tag> {
		return await this.tagRepository.createTag(companyId, tag);
	}
}

export default CreateTag;
