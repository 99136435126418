import Vehicle from '../../../entities/vehicle';
import UseCase from '../../../../core/app/usecase';
import VehicleRepository from '../../../repositories/vehicleRepository';
import CreateVehicleParameters from './createVehicleParameter';

class CreateVehicle implements UseCase<CreateVehicleParameters, Vehicle> {
	constructor(private repository: VehicleRepository) {}

	async Call({ companyId, vehicle, photo }: CreateVehicleParameters): Promise<Vehicle> {
		return await this.repository.createVehicle(companyId, vehicle, photo);
	}
}

export default CreateVehicle;
