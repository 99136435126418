import { useMemo, useState } from "react";
import BadgeReader from "../../../domain/entities/badgeReader";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import {
  BadgeReadersFilters,
  VirtualBadgeReaderUsersFilters,
} from "../../../domain/repositories/badgeReaderRepository";
import { SiteBadgeReadersViewModel } from "../../viewmodels/sites/SiteBadgeReadersViewModel";
import { updateFilterWithDelete } from "../../../utils";
import Site from "../../../domain/entities/site";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";

export enum BadgeReadersType {
  physical,
  virtual,
}

const useSitesBadgeReadersViewModel = (siteId: string) => {
  const { companyId } = useAuth();
  const viewModel = useMemo(() => new SiteBadgeReadersViewModel(), []);

  const [filters, setFilters] = useState<BadgeReadersFilters>({});
  const [sorting, setSorting] = useState<SortMeta>(null);

  const [virtualFilters, setVirtualFilters] = useState<BadgeReadersFilters>({});
  const [virtualSorting, setVirtualSorting] = useState<SortMeta>(null);

  const [virtualUsersFilters, setVirtualUsersFilters] =
    useState<VirtualBadgeReaderUsersFilters>({});
  const [searchVirtual, setSearchVirtual] = useState<string>();
  const [searchPhysical, setSearchPhysical] = useState<string>();
  const [virtualUsersSorting, setVirtualUsersSorting] =
    useState<SortMeta>(null);

  const [badgeReaderType, setBadgeReaderType] = useState<BadgeReadersType>(
    BadgeReadersType.physical
  );
  const [error, setError] = useState<string>();

  const getBadgesReadersQuery = useInfiniteQuery(
    ["getBadgeReaders", companyId, filters, searchPhysical, sorting],
    async ({ pageParam = 1 }) => {
      const physicalFilters = searchPhysical
        ? { ...filters, search: searchPhysical }
        : filters;
      const { results } = await viewModel.getSiteBadgeReaders(
        companyId,
        siteId,
        pageParam,
        physicalFilters,
        sorting
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const getVirtualBadgesReadersQuery = useInfiniteQuery(
    [
      "getVirtualBadgeReaders",
      companyId,
      virtualFilters,
      searchVirtual,
      virtualSorting,
    ],
    async ({ pageParam = 1 }) => {
      const filters = searchVirtual
        ? { ...virtualFilters, search: searchVirtual }
        : virtualFilters;
      const { results } = await viewModel.getVirtualSiteBadgeReaders(
        companyId,
        siteId,
        pageParam,
        filters,
        virtualSorting
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const getVirtualBadgesReaderAvailableUsersQuery = useInfiniteQuery(
    ["getVirtualBadgeReadersAvailableUsers", companyId, filters, sorting],
    async ({ pageParam = 1 }) => {
      const { results } = await viewModel.getBadgeReaderVitualAvailableUsers(
        companyId,
        siteId,
        pageParam,
        filters,
        sorting
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    }
  );

  const createBadgeReaderMutation = useMutation(
    ["createBadgeReader", companyId, siteId],
    async (badgeReader: Partial<BadgeReader>) => {
      return await viewModel.createBadgeReader(companyId, siteId, badgeReader);
    },
    {
      onSuccess: () => getBadgesReadersQuery.refetch(),
      onError: (error: Error) => {
        setError(error.message), getBadgesReadersQuery.refetch();
      },
    }
  );

  const createVirtualBadgeReaderMutation = useMutation(
    ["createBadgeReader", companyId, siteId],
    async (badgeReader: Partial<BadgeReader>) => {
      return await viewModel.createVirtualBadgeReader(
        companyId,
        siteId,
        badgeReader
      );
    },
    {
      onSuccess: () => getVirtualBadgesReadersQuery.refetch(),
      onError: (error: Error) => setError(error.message),
    }
  );

  const deleteBadgeReaderMutation = useMutation(
    async ({ badgeReaderId }: { badgeReaderId: string }) =>
      await viewModel.deleteBadgeReader(companyId, siteId, badgeReaderId),
    {
      onSuccess: () => {
        getBadgesReadersQuery.refetch();
        getVirtualBadgesReadersQuery.refetch();
      },
    }
  );

  const unlinkBadgeReaderMutaions = useMutation(
    async ({ badgeReaderId }: { badgeReaderId: string }) =>
      await viewModel.unlinkBadgeReader(companyId, siteId, badgeReaderId),
    {
      onError: (err: Error) => setError(err.message),
      onSuccess: () => {
        getBadgesReadersQuery.refetch();
        getVirtualBadgesReadersQuery.refetch();
      },
    }
  );

  const addFilter = (
    key: keyof BadgeReadersFilters,
    value: string | string[]
  ) => {
    updateFilterWithDelete(setFilters, key, value);
  };

  const addVirtualFilter = (
    key: keyof BadgeReadersFilters,
    value: string | string[]
  ) => {
    updateFilterWithDelete(setVirtualFilters, key, value);
  };

  const addVirtualUsersFilter = (
    key: keyof VirtualBadgeReaderUsersFilters,
    value: string
  ) => {
    updateFilterWithDelete(setVirtualUsersFilters, key, value);
  };

  const filteredBadgeReaders = getBadgesReadersQuery.data?.pages?.flat() ?? [];
  const filteredVirtualBadgeReaders =
    getVirtualBadgesReadersQuery.data?.pages?.flat() ?? [];
  const filteredVirtualBadgeReadersAvailableUsers =
    getVirtualBadgesReaderAvailableUsersQuery.data?.pages?.flat() ?? [];

  return {
    filteredBadgeReaders,
    filteredVirtualBadgeReaders,
    filteredVirtualBadgeReadersAvailableUsers,
    badgeReadersFetching: getBadgesReadersQuery.isLoading,
    virtualBadgeReadersFetching: getVirtualBadgesReadersQuery.isLoading,

    hasNextPage: getBadgesReadersQuery.hasNextPage,
    fetchNextPage: getBadgesReadersQuery.fetchNextPage,
    filters,
    addFilter,
    sorting,
    setSorting,

    virtualHasNextPage: getVirtualBadgesReadersQuery.hasNextPage,
    virtualFetchNextPage: getVirtualBadgesReadersQuery.fetchNextPage,
    virtualFilters,
    addVirtualFilter,
    virtualSorting,
    setVirtualSorting,

    virtualUsersHasNextPage:
      getVirtualBadgesReaderAvailableUsersQuery.hasNextPage,
    virtualUsersFetchNextPage:
      getVirtualBadgesReaderAvailableUsersQuery.fetchNextPage,
    virtualUsersFilters,
    addVirtualUsersFilter,
    virtualUsersSorting,
    setVirtualUsersSorting,

    createBadgeReader: createBadgeReaderMutation.mutateAsync,
    createVirtualBadgeReader: createVirtualBadgeReaderMutation.mutateAsync,
    badgeReaderType,
    setBadgeReaderType,
    error,
    setError,
    deleteBadgeReader: deleteBadgeReaderMutation.mutateAsync,
    unlinkBadgeReader: unlinkBadgeReaderMutaions.mutateAsync,
    setSearchVirtual,
    setSearchPhysical,
  };
};

export default useSitesBadgeReadersViewModel;
