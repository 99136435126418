import UseCase from '../../../../core/app/usecase';
import SiteVehicleRepository from '../../../repositories/siteVehicleRepository';
import { SiteVehicle } from '../../../entities/siteVehicle';

type GetSiteVehicleParameters = {
	companyId: string;
	siteId: string;
	vehicleId: string;
};

class GetSiteVehicle implements UseCase<GetSiteVehicleParameters, SiteVehicle | undefined> {
	constructor(private repository: SiteVehicleRepository) {}

	async Call({ companyId, siteId, vehicleId }: GetSiteVehicleParameters): Promise<SiteVehicle | undefined> {
		return this.repository.getSiteVehicle(companyId, siteId, vehicleId);
	}
}

export default GetSiteVehicle;
