import UseCase from '../../../../core/app/usecase';
import { ResourceDocumentEvaluationState } from '../../../entities/resourceDocumentEvaluationState.enum';
import chemicalRepository from '../../../repositories/chemicalRepository';
import { GetSitesFilter } from "../../../repositories/siteRepository";

type RepropagateEvaluationChemicalDocumentParameters = {
	companyId: string;
	resourceId: string;
	documentId: string,
	siteIds?: string[];
	selectAll?: boolean;
};
class RepropagateEvaluationChemicalDocument implements UseCase<RepropagateEvaluationChemicalDocumentParameters, void> {
	constructor(private repository: chemicalRepository) {}

	async Call({
		companyId,
		resourceId,
		documentId,
		siteIds,
		selectAll
	}: RepropagateEvaluationChemicalDocumentParameters): Promise<void> {
		return await this.repository.repropagateEvaluateChemicalDocument(
			companyId,
			resourceId,
			documentId,
			siteIds,
			selectAll,
		);
	}
}

export default RepropagateEvaluationChemicalDocument;
