import { SiteResource } from './siteResource';
import Badge from './badge';
import { SupplierResource } from './supplierResource';

export type SiteMachineParams = {
	name: string;
	plate: string;
	model: string;
};

export type SiteMachine = SiteResource<SiteMachineParams & { badge?: Badge }>;

export type SupplierMachine = SupplierResource<SiteMachineParams>;
export const mapSiteMachine =
	(siteId: string = null) =>
		(siteMachine): SiteMachine => ({
			name: siteMachine.name,
			plate: siteMachine.plate,
			model: siteMachine.model,
			variant: siteMachine.variant,
			specializations: siteMachine.specializations,
			company: siteMachine.companyName,
			id: siteMachine.id,
			siteId: siteId,
			companyId: siteMachine.companyId,
			state: siteMachine.state,
			isGlobal: siteMachine.isGlobal,
			isForcedEvaluation: siteMachine.isForcedEvaluation,
		});
