import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import Document from '../../entities/document';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { Filter } from '../../../presentation/hooks/Site/useSiteResourceDetailViewModel';

type ARGS = {
	companyId: string;
	supplierId: string;
	resourceId: string;
	type: RequirementSubject;
	pageParam: number;
	sortDocuments: SortMeta;
	filterDocuments: Filter;
};

class GetSupplierResourceDocuments implements UseCase<ARGS, Document[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, resourceId, type, pageParam, sortDocuments, filterDocuments }: ARGS): Promise<Document[]> {
		return await this.supplierRepository.getSupplierResourceDocuments(
			companyId,
			supplierId,
			resourceId,
			type,
			pageParam,
			sortDocuments,
			filterDocuments,
		);
	}
}

export default GetSupplierResourceDocuments;
