import { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import ContentLayout from "../../layout/ContentLayout";
import DashboardCountersView from "./DashboardCountersView";
import DashboardDaysSelector from "./DashboardDaysSelector";
import DashboardTableWidgetView from "./DashboardTableWidgetView";
import { Permission } from "../../components/Permissions/Permissions";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useDashboardViewModel } from "../../hooks/Dashboard/useDashboard";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
} from "@chakra-ui/react";
import { Alert } from "../Common/Alert";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { useMediaQuery } from "@chakra-ui/react";

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardOverviewView = () => {
  const { t } = useTranslation("dashboard");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { company, hasPermissions } = useAuth();
  const [showLicenseNotActive, setShowLicenseNotActive] = useState<boolean>(
    localStorage.getItem("justLoggedIn") === "true" ? true : false
  );

  const {
    ownedSitesCount,
    workingSitesCount,
    suppliersCount,
    unevaluatedDocsCount,
    expiringDocsCount,
    ownedSitesCountFetching,
    workingSitesCountFetching,
    suppliersCountFetching,
    unevaluatedDocsCountFetching,
    expiringDocsCountFetching,
    unevaluatedDocs,
    unevaluatedDocumentsFetching,
    unevaluatedDocsSort,
    setUnevaluatedDocsSort,
    unevaluatedDocsHasNextPage,
    unevaluatedDocsFetchNextPage,
    expiringDocuments,
    expiringDocumentsFetching,
    expiringDocumentsSort,
    setExpiringDocumentsSort,
    expiringDocumentsHasNextPage,
    expiringDocumentsFetchNextPage,
    cseAuthorizations,
    cseAuthorizationsSort,
    setCseAuthorizationsSort,
    cseAuthorizationsFetching,
    cseAuthorizationsHasNextPage,
    cseAuthorizationsFetchNextPage,
    invitedSuppliers,
    invitedSuppliersSort,
    setInvitedSupplierSort,
    invitedSuppliersFetching,
    invitedSuppliersHasNextPage,
    invitedSuppliersFetchNextPage,
    companyExpiringDocs,
    companyExpiringDocsSort,
    setCompanyExpiringDocSort,
    companyExpiringDocsFetching,
    companyExpiringDocsHasNextPage,
    companyExpiringDocsFetchNextPage,
    expiringSupplierResources,
    expiringSupplierResourcesSort,
    setExpiringSupplierResourcesSort,
    expiringSupplierResourcesFilter,
    updateSupplierResourcesFilter,
    expiringSupplierResourcesFetching,
    expiringSupplierResourcesHasNextPage,
    expiringSupplierResourcesFetchNextPage,
    expiringSupplierDocuments,
    expiringSupplierDocumentsSort,
    setExpiringSupplierDocumentsSort,
    expiringSupplierDocumentsFilter,
    updateSupplierDocumentsFilter,
    expiringSupplierDocumentsFetching,
    expiringSupplierDocumentsHasNextPage,
    expiringSupplierDocumentsFetchNextPage,
    expiringCompanyDocuments,
    expiringCompanyDocumentsFetchNextPage,
    expiringCompanyDocumentsFetching,
    expiringCompanyDocumentsHasNextPage,
    expiringCompanyDocumentsSort,
    setExpiringCompanyDocumentsSort,

    expiringDocumentsDays,
    setExpiringDocumentsDays,
    expiringCompanyDocumentsDays,
    setExpiringCompanyDocumentsDays,
    expiringEvaluationsDays,
    setExpiringEvaluationsDays,
    expiringResourcesEvaluationsDays,
    setExpiringResourcesEvaluationsDays,
    expiringCompanyOrSiteEvaluationsDays,
    setExpiringCompanyOrSiteEvaluationsDays,

    setEnableUnevaluatedDocuments,
    setEnableExpiringDocumentsEvaluations,
    setEnableExpiringDocuments,
    setEnableGetExpiringSiteDocuments,
    setEnableExpiringResourceEvaluations,
    setEnableGetExpiringCompanyOrSiteEvaluations,
    setEnableCseAuthorization,
    setEnableInvitedSupplier
  } = useDashboardViewModel();

  const unevaluatedDocumentsColumns = [
    { field: "name", type: "text" as const, label: t("site") },
    {
      field: "count",
      type: "text" as const,
      label: t("unevaluatedDocuments.documentsToCheck"),
    },
  ];
  const expiringDocumentsColumns = [
    { field: "name", type: "text" as const, label: t("site") },
    {
      field: "count",
      type: "text" as const,
      label: t("expiringDocuments.expiredDocuments"),
    },
  ];
  const cseAuthorizationsColumns = [
    { field: "supplier", type: "text" as const, label: t("supplier") },
    {
      field: "date",
      type: "date" as const,
      label: t("cseAuthorizations.evaluationDate"),
    },
    { field: "site", type: "text" as const, label: t("site") },
  ];
  const invitedSuppliersColumns = [
    { field: "supplier", type: "text" as const, label: t("supplier") },
    { field: "site", type: "text" as const, label: t("site") },
    { field: "date", type: "date" as const, label: t("suppliers.date") },
  ];
  const companyExpiringDocsColumns = [
    { field: "name", type: "text" as const, label: t("documentName") },
    { field: "expiresAt", type: "date" as const, label: t("expiringDate") },
    { field: "expiresIn", type: "text" as const, label: t("daysLeft") },
    { field: "resourceName", type: "text" as const, label: t("resource") },
  ];

  const expiringSupplierResourcesColumns = [
    { field: "name", type: "text" as const, label: t("documentName") },
    { field: "expiresAt", type: "date" as const, label: t("expiringDate") },
    { field: "expiresIn", type: "text" as const, label: t("daysLeft") },
    { field: "resource", type: "text" as const },
    { field: "resourceName", type: "text" as const, label: t("resource") },
    { field: "siteName", type: "text" as const, label: t("site") },
  ];

  const expiringSupplierDocumentsColumns = [
    { field: "name", type: "text", label: t("documentName") },
    { field: "expiresAt", type: "date" as const, label: t("expiringDate") },
    { field: "expiresIn", type: "text" as const, label: t("daysLeft") },
    { field: "resource", type: "text" as const },
    { field: "resourceName", type: "text" as const, label: t("company") },
    { field: "siteName", type: "text" as const, label: t("site") },
  ];

  const centerLabel = (topic: string) => {
    return {
      id: "textCenter",
      beforeDatasetsDraw(chart, arg, pluginOptions) {
        const { ctx, data } = chart;
        ctx.save();
        ctx.font = "bold 0.65rem sans-serif";
        ctx.fillStyle = COLORS.sikuroBlue;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(
          topic === "unevaluated"
            ? `${unevaluatedDocsCount.count} ${t("documents", {
                ns: "documents",
              })}`
            : `${expiringDocsCount.count}  ${t("evaluations")}`,
          chart.getDatasetMeta(0).data[0].x,
          chart.getDatasetMeta(0).data[0].y
        );
      },
    };
  };

  const evaluatedDocs = unevaluatedDocsCount
    ? unevaluatedDocsCount.count - unevaluatedDocsCount.partialCount
    : null;

  const notExiping = expiringDocsCount
    ? expiringDocsCount.count - expiringDocsCount.partialCount
    : null;

  const lastTenantSelected = localStorage.getItem("lastTenant");
  const lastUser = localStorage.getItem("lastUser");

  const getCompanyName = () => {
    if (lastTenantSelected && lastUser) {
      try {
        const parsedCompany = JSON.parse(lastTenantSelected);
        const parsedlastUser = JSON.parse(lastUser);
        if (parsedlastUser.id === company.id) {
          return parsedCompany?.name;
        } else {
          return company?.name;
        }
      } catch (error) {
        console.error("Error parsing JSON from localStorage", error);
        return company?.name;
      }
    } else {
      return company?.name;
    }
  };

  return (
    <ContentLayout>
      <Box p={10} width={isMobile ? "1200px" : "100%"}>
        <Flex direction={"row"} gap={2} mb={5}>
          <Text fontSize={30}>{t("welcome")}</Text>
          <Text fontSize={30} fontWeight={"bold"} color={COLORS.sikuroBlue}>
            {getCompanyName()}
          </Text>
        </Flex>
        <DashboardCountersView
          ownedSitesCount={ownedSitesCount}
          workingSitesCount={workingSitesCount}
          suppliersCount={suppliersCount}
          ownedSitesCountFetching={ownedSitesCountFetching}
          workingSitesCountFetching={workingSitesCountFetching}
          suppliersCountFetching={suppliersCountFetching}
          ownedSitesPermissions={[Permission.Dashboard_ShowOwnedSitesCount]}
          workingSitesPermissions={[Permission.Dashboard_ShowWorkingSitesCount]}
          suppliersPermissions={[Permission.Dashboard_ShowSuppliersCount]}
        />

        <Grid
          templateColumns={
            !isMobile
              ? { lg: "repeat(2, 1fr)", xl: "repeat(4, 1fr)" }
              : "repeat(4, 1fr)"
          }
          gap={4}
          py={4}
        >
          
          {hasPermissions([Permission.Dashboard_ShowUnevaluatedDocuments]) && (

            <GridItem colSpan={2} height={"100%"}>

              <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=>setEnableUnevaluatedDocuments(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                          {t("unevaluatedDocuments.title")}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6}>
                      <DashboardTableWidgetView
                        items={unevaluatedDocs}
                        isFetching={unevaluatedDocumentsFetching}
                        sort={unevaluatedDocsSort}
                        setSort={(sort: SortMeta) => setUnevaluatedDocsSort(sort)}
                        columns={unevaluatedDocumentsColumns}
                        hasNextPage={unevaluatedDocsHasNextPage}
                        fetchNextPage={() => unevaluatedDocsFetchNextPage()}
                        widget={"unevaluatedDocuments"}
                      />

                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}

          {hasPermissions([Permission.Dashboard_ShowExpiringEvaluations]) && (
            <GridItem colSpan={2}>

              <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"} >
                    <AccordionButton onClick={()=>setEnableExpiringDocumentsEvaluations(true)}>
                      <HStack spacing={2} flex={1}>
                          <Text fontWeight={600} flexGrow={1} mr={1}>
                            {t("expiringDocuments.title")}
                          </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                      <DashboardDaysSelector
                          days={expiringEvaluationsDays}
                          onSelectDays={setExpiringEvaluationsDays}
                        />
                      <DashboardTableWidgetView
                        items={expiringDocuments}
                        isFetching={expiringDocumentsFetching}
                        sort={expiringDocumentsSort}
                        setSort={(sort: SortMeta) => setExpiringDocumentsSort(sort)}
                        columns={expiringDocumentsColumns}
                        hasNextPage={expiringDocumentsHasNextPage}
                        fetchNextPage={() => expiringDocumentsFetchNextPage()}
                        widget={"expiringDocuments"}
                        daysSelected={expiringEvaluationsDays}
                      />

                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}

          {hasPermissions([Permission.Dashboard_ShowExpiringDocuments]) && (
            <GridItem colSpan={2}>

                <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=>setEnableExpiringDocuments(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                          {t("expiringDocumentsResource.title")}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                      <DashboardDaysSelector
                        days={expiringDocumentsDays}
                        onSelectDays={setExpiringDocumentsDays}
                      />
                      <DashboardTableWidgetView
                        items={companyExpiringDocs}
                        isFetching={companyExpiringDocsFetching}
                        sort={companyExpiringDocsSort}
                        setSort={(sort: SortMeta) =>
                          setCompanyExpiringDocSort(sort)
                        }
                        columns={companyExpiringDocsColumns}
                        hasNextPage={companyExpiringDocsHasNextPage}
                        fetchNextPage={() => companyExpiringDocsFetchNextPage()}
                        widget={"expiringDocumentsResource"}
                      />

                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}

          {hasPermissions([
            Permission.Dashboard_ShowExpiringCompanyDocuments,
          ]) && (
            <GridItem colSpan={2}>

              <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=>setEnableGetExpiringSiteDocuments(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                          {t("expiringCompanyDocuments.title")}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                      <DashboardDaysSelector
                        days={expiringCompanyDocumentsDays}
                        onSelectDays={setExpiringCompanyDocumentsDays}
                      />
                      <DashboardTableWidgetView
                        items={expiringCompanyDocuments}
                        isFetching={expiringCompanyDocumentsFetching}
                        sort={expiringCompanyDocumentsSort}
                        setSort={(sort: SortMeta) =>
                          setExpiringCompanyDocumentsSort(sort)
                        }
                        columns={companyExpiringDocsColumns}
                        hasNextPage={expiringCompanyDocumentsHasNextPage}
                        fetchNextPage={() =>
                          expiringCompanyDocumentsFetchNextPage()
                        }
                        widget={"expiringCompanyDocuments"}
                      />

                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}

          {hasPermissions([
            Permission.Dashboard_ShowExpiringResourcesEvaluations,
          ]) && (
            <GridItem colSpan={2}>

                <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=> setEnableExpiringResourceEvaluations(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                        {t("myExpiringEvaluations.title")}
                      </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                      <DashboardDaysSelector
                        days={expiringResourcesEvaluationsDays}
                        onSelectDays={setExpiringResourcesEvaluationsDays}
                      />
                      <DashboardTableWidgetView
                        items={expiringSupplierResources}
                        isFetching={expiringSupplierResourcesFetching}
                        sort={expiringSupplierResourcesSort}
                        setSort={(sort: SortMeta) =>
                          setExpiringSupplierResourcesSort(sort)
                        }
                        filterFields={expiringSupplierResourcesFilter}
                        updateFilters={(field: string, value: string | string[]) =>
                          updateSupplierResourcesFilter(field, value)
                        }
                        columns={expiringSupplierResourcesColumns}
                        hasNextPage={expiringSupplierResourcesHasNextPage}
                        fetchNextPage={() =>
                          expiringSupplierResourcesFetchNextPage()
                        }
                        widget={"expiringSupplierResources"}
                      />

                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}

          {hasPermissions([
            Permission.Dashboard_ShowExpiringCompanyOrSiteEvaluations,
          ]) && (
            <GridItem colSpan={2}>

              <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=> setEnableGetExpiringCompanyOrSiteEvaluations(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                          {t("companyExpiringEvaluations.title")}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                      <DashboardDaysSelector
                        days={expiringCompanyOrSiteEvaluationsDays}
                        onSelectDays={setExpiringCompanyOrSiteEvaluationsDays}
                      />
                      <DashboardTableWidgetView
                        items={expiringSupplierDocuments}
                        isFetching={expiringSupplierDocumentsFetching}
                        sort={expiringSupplierDocumentsSort}
                        setSort={(sort: SortMeta) =>
                          setExpiringSupplierDocumentsSort(sort)
                        }
                        filterFields={expiringSupplierDocumentsFilter}
                        updateFilters={(field: string, value: string | string[]) =>
                          updateSupplierDocumentsFilter(field, value)
                        }
                        columns={expiringSupplierDocumentsColumns}
                        hasNextPage={expiringSupplierDocumentsHasNextPage}
                        fetchNextPage={() =>
                          expiringSupplierDocumentsFetchNextPage()
                        }
                        widget={"expiringSupplierDocuments"}
                      />

                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}

          {hasPermissions([Permission.Dashboard_ShowPendingEvaluations]) && (
            <GridItem colSpan={2}>

                <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=> setEnableCseAuthorization(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                          {t("cseAuthorizations.title")}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                      <DashboardTableWidgetView
                        items={cseAuthorizations}
                        isFetching={cseAuthorizationsFetching}
                        sort={cseAuthorizationsSort}
                        setSort={(sort: SortMeta) => setCseAuthorizationsSort(sort)}
                        columns={cseAuthorizationsColumns}
                        hasNextPage={cseAuthorizationsHasNextPage}
                        fetchNextPage={() => cseAuthorizationsFetchNextPage()}
                        widget={"cseAuthorizations"}
                      />

                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}

          {hasPermissions([Permission.Dashboard_ShowInvitedSuppliers]) && (
            <GridItem colSpan={2}>

              <Accordion
                  allowToggle
                  borderRadius={8}
                  border="1px solid #E2E8F0"
                  marginTop={2}
                  >
                {
                  <AccordionItem border="none" key={"accordion1"}>
                    <AccordionButton onClick={()=> setEnableInvitedSupplier(true)}>
                      <HStack spacing={2} flex={1}>
                        <Text fontWeight={600} flexGrow={1} mr={1}>
                          {t("suppliers.title")}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                    <Box display={"flex"} alignItems={"center"} gap={6} flexDirection={"column"}>
                    <DashboardTableWidgetView
                      items={invitedSuppliers}
                      isFetching={invitedSuppliersFetching}
                      sort={invitedSuppliersSort}
                      setSort={(sort: SortMeta) => setInvitedSupplierSort(sort)}
                      columns={invitedSuppliersColumns}
                      hasNextPage={invitedSuppliersHasNextPage}
                      fetchNextPage={() => invitedSuppliersFetchNextPage()}
                      widget={"invitedSupplier"}
                    />

                    </Box>
                    </AccordionPanel>
                  </AccordionItem>
                }
              </Accordion>

            </GridItem>
          )}
        </Grid>
      </Box>

      {showLicenseNotActive && company?.state === "disabled" && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t("tenantMissingLicense", { ns: "common" })}
          variant="info"
          onClose={() => {
            setShowLicenseNotActive(false);
            localStorage.setItem("justLoggedIn", "false");
          }}
        />
      )}
    </ContentLayout>
  );
};

export default DashboardOverviewView;
