import UseCase from '../../../core/app/usecase';
import FileEntity from '../../entities/file';
import RequirementRepository from '../../repositories/requirementRepository';

interface Args {
	companyId: string;
	siteId: string;
	requirementId: string;
	file: FileEntity;
}

class AddTemplateToSiteRequirement implements UseCase<Args, void> {
	constructor(private repository: RequirementRepository) {}

	async Call(args: Args): Promise<void> {
		return await this.repository.addTemplateToSiteRequirement(args.companyId, args.siteId, args.requirementId, args.file);
	}
}

export default AddTemplateToSiteRequirement;
