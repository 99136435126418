import UseCase from '../../../../core/app/usecase';
import SiteToolRepository from '../../../repositories/siteToolRepository';
import Evaluation from '../../../entities/evaluation';
import { ResourceEvaluationState } from '../../../entities/resourceEvaluationState.enum';
import { GetResourceEvaluationFilter } from '../../../repositories/siteRepository';
import { SortMeta } from '../../../entities/interfaces/paginatedResults';

type Parameters = {
	companyId: string;
	siteId: string;
	toolId: string;
	pageParam: number;
	sort?: SortMeta;
	filter?: GetResourceEvaluationFilter;
};

class GetSiteToolEvaluations implements UseCase<Parameters, Evaluation<ResourceEvaluationState>[] | undefined> {
	constructor(private repository: SiteToolRepository) {}

	async Call({ companyId, siteId, toolId, pageParam, sort, filter }: Parameters): Promise<Evaluation<ResourceEvaluationState>[] | undefined> {
		return this.repository.getSiteToolEvaluations(companyId, siteId, toolId, pageParam, sort, filter);
	}
}

export default GetSiteToolEvaluations;
