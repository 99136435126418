import { NavItemProps } from "../../Common/NavItem";
import SubNavBar from "../../Common/SubNavBar";
import { useParams } from "react-router-dom";
import WorkerIcon from "../../Common/icons/WorkerIcon";
import VehicleIcon from "../../Common/icons/VehicleIcon";
import MachineIcon from "../../Common/icons/MachineIcon";
import ToolIcon from "../../Common/icons/ToolIcon";
import ChemicalIcon from "../../Common/icons/ChemicalIcon";

const WorkingSiteResourcesSubNav = () => {
  const { siteId } = useParams();

  const items: NavItemProps[] = [
    {
      Icon: WorkerIcon,
      path: `/workingsites/${siteId}/resources/workers`,
      name: "Lavoratori",
    },
    {
      Icon: VehicleIcon,
      path: `/workingsites/${siteId}/resources/vehicles`,
      name: "Veicoli",
    },
    {
      Icon: MachineIcon,
      path: `/workingsites/${siteId}/resources/machines`,
      name: "Macchinari",
    },
    {
      Icon: ToolIcon,
      path: `/workingsites/${siteId}/resources/tools`,
      name: "Attrezzature",
    },
    {
      Icon: ChemicalIcon,
      path: `/workingsites/${siteId}/resources/chemicals`,
      name: "P. Chimici",
    },
  ];

  return <SubNavBar items={items} />;
};

export default WorkingSiteResourcesSubNav;
