import UseCase from '../../../core/app/usecase';
import DocumentRepository from '../../repositories/documentRepository';

interface Args {
	siteId?: string;
	companyId: string;
	resourceType: string;
	resourceId: string;
	status: boolean;
}

class UpdateNotificationsStatus implements UseCase<Args, void> {
	constructor(private repository: DocumentRepository) {}

	async Call({ siteId, companyId, resourceType, resourceId, status }: Args): Promise<void> {
		return await this.repository.updateNotificationsStatus(companyId, resourceType, resourceId, status, siteId);
	}
}

export default UpdateNotificationsStatus;
