import UseCase from '../../../core/app/usecase';
import DocumentRepository from '../../repositories/documentRepository';

interface Args {
	siteId?: string;
	companyId: string;
	resourceType: string;
	resourceId: string;
}

class GetNotificationsStatus implements UseCase<Args, { status: boolean }> {
	constructor(private repository: DocumentRepository) {}

	async Call({ siteId, companyId, resourceType, resourceId }: Args): Promise<{ status: boolean }> {
		return await this.repository.getNotificationsStatus(companyId, resourceType, resourceId, siteId);
	}
}

export default GetNotificationsStatus;
