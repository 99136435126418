import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { RequirementSubject } from '../../entities/requirementSubject.enum';

type ARGS = {
	companyId: string;
	supplierId: string;
	resourceId: string;
	specializationIds: string[];
	type: RequirementSubject;
};

class UpdateCompanySupplierResourceSpecializations implements UseCase<ARGS, void> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, supplierId, resourceId, specializationIds, type }: ARGS): Promise<void> {
		return await this.supplierRepository.updateCompanySupplierResourceSpecializations(companyId, supplierId, resourceId, type, specializationIds);
	}
}

export default UpdateCompanySupplierResourceSpecializations;
