import { container } from 'tsyringe';
import { Notification } from '../entities/notification';
import { PaginatedResults } from '../entities/interfaces/paginatedResults';
import { UserNotification } from '../entities/interfaces/userNotification';
import { UpdateAlertParams } from '../../presentation/hooks/Users/useUserSettingsViewModel';

export interface NotificationsRepository {
	/**
	 * Returns all unread (new) notifications.
	 *
	 * @see HTTPRequest /companies/:companyId/notifications
	 *
	 * @param companyId the context identifier
	 * @param page the requested page of results
	 * @param perPage the page size
	 */
	listNew(companyId: string, page: number, perPage: number): Promise<PaginatedResults<Notification>>;

	/**
	 * Returns all archived (read) notifications.
	 *
	 * @see HTTPRequest /companies/:companyId/notifications/archive
	 *
	 * @param companyId the context identifier
	 * @param page the requested page of results
	 * @param perPage the page size
	 */
	listArchived(companyId: string, page: number, perPage: number): Promise<PaginatedResults<Notification>>;

	/**
	 * Reads a notification.
	 *
	 * @see HTTPRequest /companies/:companyId/notifications/:notificationId/read
	 *
	 * @param companyId the context identifier
	 * @param notificationId the notification identifier
	 */
	read(companyId: string, notificationId: string, status: string): Promise<void>;

	/**
	 * Permanently deletes an existing notification.
	 *
	 * @see HTTPRequest /companies/:companyId/notifications/:notificationId
	 *
	 * @param companyId the context identifier
	 * @param notificationId the notification identifier
	 */
	delete(companyId: string, notificationId: string): Promise<string>;

	/**
	 * Get count of unread notification.
	 *
	 * @see HTTPRequest /companies/:companyId/notifications/count-unread
	 *
	 * @param companyId the context identifier
	 */
	getCount(companyId: string): Promise<number>;

	/**
	 * Get notifications fro the single user.
	 *
	 * @see HTTPRequest /companies/:companyId/notifications/users/:userId/settings
	 *
	 * @param companyId the context identifier
	 * @param userId the user identifier
	 */
	getUserNotifications(companyId: string, userId: string): Promise<UserNotification[]>;

	/**
	 * Enable/disable individual notification for a specific user.
	 *
	 * @see HTTPRequest /companies/:companyId/notifications/users/:userId/settings
	 *
	 * @param companyId the context identifier
	 */
	updateUserNotification(companyId: string, userId: string, notifications: UserNotification[]): Promise<void>;

	/**
	 * Enable/disable individual notification for a specific user.
	 *
	 * @see HTTPRequest /companies/:companyId/notifications/users/:userId/alert-options
	 *
	 * @param companyId the context identifier
	 */
	updateAlertNotification(companyId: string, userId: string, alert: UpdateAlertParams);
}

export default NotificationsRepository;

/**
 * Builds an instance of NotificationsRepository.
 *
 * @returns an instance of the concrete implementation of NotificationsRepository
 */
export const makeNotificationsRepository = (): NotificationsRepository => {
	return container.resolve('NotificationsRepository');
};
