import UseCase from '../../../core/app/usecase';
import WebHookRepository from '../../repositories/webHookRepository';

type ARGS = {
	companyId: string;
};

class GetWebHookEvents implements UseCase<ARGS, string[]> {
	private webHookRepository: WebHookRepository;

	constructor(webHookRepository: WebHookRepository) {
		this.webHookRepository = webHookRepository;
	}

	async Call({ companyId }: ARGS): Promise<string[]> {
		return await this.webHookRepository.getEvents(companyId);
	}
}

export default GetWebHookEvents;
