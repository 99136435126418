import UserIcon from "./icons/UserIcon";
import { GoGear } from "react-icons/go";
import { useTranslation } from "react-i18next";
import Sidebar, { SidebarGroup } from "./Sidebar";
import { getSectionPermissions, Permission } from "../Permissions/Permissions";
import { MdOutlineDashboard, MdOutlineNotifications } from "react-icons/md";
import {
  TbBuildingSkyscraper,
  TbCrane,
  TbJacket,
  TbCalendarEvent,
  TbFolders,
} from "react-icons/tb";

interface MainNavBarProps {
  hideNavItems?: boolean;
}

const MainNavBar = ({ hideNavItems }: MainNavBarProps) => {
  const { t } = useTranslation("navbar");

  const linkItems: Array<SidebarGroup> = [
    {
      links: [
        {
          icon: MdOutlineDashboard,
          path: "/overview",
          name: t("main.dashboard"),
          permissions: getSectionPermissions("dashboard"),
        },
        {
          icon: TbCrane,
          path: "/sites",
          name: t("main.my sites"),
          permissions: getSectionPermissions("sites", "my-sites"),
        },
        {
          icon: TbJacket,
          path: "/workingsites",
          name: t("main.my working sites"),
          permissions: getSectionPermissions("worksite", "worksites"),
        },
        {
          icon: TbBuildingSkyscraper,
          path: "/company",
          name: t("main.my company"),
          permissions: getSectionPermissions("company"),
        },
        {
          icon: TbFolders,
          path: "/resources",
          name: t("main.my resources"),
          permissions: getSectionPermissions("resources"),
        },
        {
          Icon: UserIcon,
          path: "/suppliers",
          name: t("main.suppliers"),
          permissions: getSectionPermissions("supplier"),
        },
        {
          icon: TbCalendarEvent,
          path: "/timetable",
          name: t("timeTable"),
          permissions: getSectionPermissions("calendar"),
        },
        {
          icon: GoGear,
          path: "/settings",
          name: t("main.settings"),
          permissions: getSectionPermissions("settings"),
        },
        {
          icon: MdOutlineNotifications,
          path: "/notifications",
          name: t("main.notifications"),
        },
      ],
    },
  ];

  const bottomLinkItems: Array<SidebarGroup> = [
    {
      links: [],
    },
  ];

  return (
    <Sidebar
      topItems={hideNavItems ? [] : linkItems}
      bottomItems={bottomLinkItems}
      hideNavItems={hideNavItems}
    />
  );
};

export default MainNavBar;
