import { Alert, AlertIcon, Text } from "@chakra-ui/react";
import { FC } from "react";
import { AiOutlineClose } from "react-icons/ai";

interface InfoBannerProps {
  text: string;
  onClose?: () => void;
  fontSize?: string;
}

const InfoBanner: FC<InfoBannerProps> = ({ text, onClose, fontSize }) => {
  return (
    <Alert status="info" borderRadius={8} my={2} display={"flex"}>
      <AlertIcon />
      <Text display={"flex"} flexGrow={1} fontSize={fontSize}>
        {text}
      </Text>
      {onClose && <AiOutlineClose onClick={onClose} cursor={"pointer"} />}
    </Alert>
  );
};

export { InfoBanner };
