import { makeCompanyDocuments } from "../../../core/app/factory/companies/usecases";
import {
  makeGetDocumentTypes,
  makeGetDocumentTypesForAi,
} from "../../../core/app/factory/documentTypes/usecases";
import { makeGetTags } from "../../../core/app/factory/tags/usecases";
import Document from "../../../domain/entities/document";
import DocumentType from "../../../domain/entities/documentType";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import Tag from "../../../domain/entities/tag";

import {
  makeAddFileToDocument,
  makeConfirmAiTask,
  makeCreateAiTask,
  makeCreateDocument,
  makeCreatePropagableDocuments,
  makeDeleteAiTask,
  makeDeleteDocument,
  makeDeleteFileFromDocument,
  makeDocumentSites,
  makeEvaluateGeneralDocument,
  makeGetAiTaskDetail,
  makeGetAiTasks,
  makeGetAiTaskSites,
  makeGetDocumentFiles,
  makeGetDocumentFilesLog,
  makeGetDocumentLog,
  makeReorderDocuments,
  makeRepropagateEvaluationGeneralDocument,
  makeRepropagateFileToDocument,
  makeUpdateDocument,
  makeUpdateFile,
} from "../../../core/app/factory/documents/usecases";
import DocumentTypeWithPublic from "../../../domain/entities/documentTypeWithPublic";
import {
  default as File,
  default as FileEntity,
} from "../../../domain/entities/file";
import {
  DocumentActionLogEntry,
  DocumentFileLogEntry,
} from "../../../domain/entities/documentLogEntry";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { ResourceDocumentEvaluationState } from "../../../domain/entities/resourceDocumentEvaluationState.enum";
import { DocumentSites } from "../../../domain/entities/site";
import {
  GetDocumentFilesFilter,
  GetDocumentLogFilter,
  GetDocumentsFilter,
} from "../../../domain/repositories/documentRepository";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import { AddDocumentTypeViewModel } from "../../hooks/Document/useAddDocumentType";
import { DocumentInfoViewModel } from "../../hooks/Document/useDocumentInfo";
import { DocumentsViewModel } from "../../hooks/Document/useDocuments";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import AiTask from "../../../domain/entities/aiTask";
import AiTaskDocument from "../../../domain/entities/aiTaskDocument";

class CompanyGeneralDocumentsViewModel
  implements
    DocumentsViewModel,
    AddDocumentTypeViewModel,
    DocumentInfoViewModel
{
  private getGeneralDocumentsUseCase = makeCompanyDocuments();
  private deleteDocumentUseCase = makeDeleteDocument();
  private createDocumentUseCase = makeCreateDocument();
  private createPropagableDocumentsUseCase = makeCreatePropagableDocuments();
  private updateDocumentUseCase = makeUpdateDocument();
  private evaluateDocumentUseCase = makeEvaluateGeneralDocument();
  private repropagateEvaluationDocumentUseCase =
    makeRepropagateEvaluationGeneralDocument();
  private getTagsUseCase = makeGetTags();
  private _getDocumentTypes = makeGetDocumentTypes();
  private getDocumentTypesForAiUseCase = makeGetDocumentTypesForAi();

  async getDocuments(
    companyId: string,
    filter?: GetDocumentsFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<Document[]> {
    return await this.getGeneralDocumentsUseCase.Call({
      companyId,
      filter,
      sort,
      pageParam,
    });
  }

  async getDocumentFilesLogs(
    companyId: string,
    documentId: string,
    filter?: GetDocumentLogFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<DocumentActionLogEntry[]> {
    return await makeGetDocumentFilesLog().Call({
      companyId,
      documentId,
      filter,
      sort,
      pageParam,
    });
  }

  async getTags(companyId: string, search?: string): Promise<Tag[]> {
    return await this.getTagsUseCase.Call({ companyId, search });
  }

  async getDocumentTypes(
    companyId: string,
    search?: string,
    tags?: string[],
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<DocumentType[]> {
    return await this._getDocumentTypes.Call({
      companyId,
      filter: DocumentTypeCategory.COMPANY,
      includePublic: true,
      search,
      tags,
      sort,
      pageParam,
    });
  }

  async getDocumentTypesForAi(
    companyId: string,
    aiTaskId: string,
    resourceType: DocumentTypeCategory,
  ): Promise<DocumentType[]> {
    return await this.getDocumentTypesForAiUseCase.Call({
      companyId,
      aiTaskId,
      resourceType,
    });
  }

  async createDocument(companyId: string, documents: string[]): Promise<void> {
    return await this.createDocumentUseCase.Call({ companyId, documents });
  }

  async createPropagableDocuments(
    companyId: string,
    documents: DocumentTypeWithPublic[],
  ): Promise<void> {
    return await this.createPropagableDocumentsUseCase.Call({
      companyId,
      documents,
    });
  }

  async updateDocument(
    companyId: string,
    document: Document,
    siteIds?: string[],
    selectAll?: boolean,
  ): Promise<void> {
    return await this.updateDocumentUseCase.Call({
      companyId,
      document,
      siteIds,
      selectAll,
    });
  }

  async deleteDocument(
    companyId: string,
    documentId: string,
  ): Promise<Document[]> {
    return await this.deleteDocumentUseCase.Call({ companyId, documentId });
  }

  async evaluateDocument(
    companyId: string,
    documentId: string,
    result: ResourceDocumentEvaluationState,
    expiresAt?: Date,
    noEvaluationExpiration?: boolean,
    siteIds?: string[],
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ): Promise<void> {
    return await this.evaluateDocumentUseCase.Call({
      companyId,
      documentId,
      result,
      expiresAt,
      noEvaluationExpiration,
      siteIds,
      selectAll,
      filters,
    });
  }

  async repropagateEvaluationDocument(
    companyId: string,
    documentId: string,
    siteIds: string[],
    selectAll?: boolean,
  ) {
    return await this.repropagateEvaluationDocumentUseCase.Call({
      companyId,
      documentId,
      siteIds,
      selectAll,
    });
  }

  async getDocumentFiles(
    companyId: string,
    documentId: string,
    filter?: GetDocumentFilesFilter,
    sort?: SortMeta,
  ): Promise<File[]> {
    return await makeGetDocumentFiles().Call({
      companyId,
      documentId,
      filter,
      sort,
    });
  }

  async deleteDocumentFile(
    companyId: string,
    documentId: string,
    fileId: string,
    siteId?: string,
    target?: string,
    resourceId?: string,
    siteIds?: string[],
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ) {
    await makeDeleteFileFromDocument().Call({
      companyId,
      documentId,
      fileId,
      siteIds,
      target,
      selectAll,
      filters,
    });
  }

  async addFileToDocument(
    companyId: string,
    documentId: string,
    file: File,
    siteId?: string,
    target?: string,
    resourceId?: string,
    siteIds?: string[],
    toSupplier?: boolean,
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ) {
    await makeAddFileToDocument().Call({
      companyId,
      documentId,
      file,
      siteId,
      target: null,
      resourceId: null,
      siteIds,
      toSupplier: null,
      selectAll,
      filters,
    });
  }

 
  async repropagateFileToDocument(
    companyId: string,
    documentId: string,
    siteId?: string,
    target?: string,
    resourceId?: string,
    siteIds?: string[],
    toSupplier?: boolean,
    selectAll?: boolean,
    selectAllFiles?: boolean,
    fileIds?: string[],
    filters?: GetSitesFilter
  ) {
    await makeRepropagateFileToDocument().Call({
      companyId,
      documentId,
      siteId,
      target: null,
      resourceId: null,
      siteIds,
      toSupplier: null,
      selectAll,
      selectAllFiles,
      fileIds,
      filters
    });
  }

  async getDocumentLogs(
    companyId: string,
    documentId: string,
    filter?: GetDocumentLogFilter,
    sort?: SortMeta,
    resourceId?: string,
    siteId?: string,
    target?: string,
    pageParam?: number,
  ): Promise<DocumentFileLogEntry[]> {
    return await makeGetDocumentLog().Call({
      companyId,
      documentId,
      filter,
      sort,
      pageParam,
    });
  }

  async getDocumentSites(
    companyId: string,
    documentId: string,
    sort?: SortMeta,
    filter?: GetSitesFilter,
    pageParam?: number,
    includeWorkingSites?: boolean,
    supplierId?: string,
  ): Promise<DocumentSites> {
    return await makeDocumentSites().Call({
      companyId,
      documentId,
      sort,
      filter,
      pageParam,
      includeWorkingSites,
      supplierId,
    });
  }

  async updateFile(
    companyId: string,
    documentId: string,
    fileId: string,
    updatedFiles: Partial<FileEntity>[],
    siteIds: string[],
    target: string,
    resourceId: string,
    selectAll: boolean,
    filters?: GetSitesFilter,
  ): Promise<void> {
    return await makeUpdateFile().Call({
      companyId,
      documentId,
      updatedFiles,
      fileId,
      siteIds,
      target,
      resourceId,
      selectAll,
      filters,
    });
  }

  async reorderDocuments(
    companyId: string,
    documents: Document[],
  ): Promise<void> {
    return await makeReorderDocuments().Call({
      companyId,
      documents,
    });
  }

  // AI section fuctions

  async createAiTask(
    companyId: string,
    files: FileEntity,
    sectionCode: AiTaskSectionCode,
    resourceId: string,
    type: DocumentTypeCategory,
  ): Promise<string> {
    return await makeCreateAiTask().Call({
      companyId,
      files,
      sectionCode,
      resourceId,
      resourceType: type as unknown as DocumentTypeCategory,
    });
  }

  async getAiTasks(
    companyId: string,
    sectionCode: AiTaskSectionCode,
  ): Promise<AiTask[]> {
    return await makeGetAiTasks().Call({ companyId, sectionCode });
  }

  async getAiTaskDetail(companyId: string, taskId: string): Promise<AiTask> {
    return await makeGetAiTaskDetail().Call({ companyId, taskId });
  }

  async deleteAiTask(companyId: string, taskId: string): Promise<void> {
    return await makeDeleteAiTask().Call({ companyId, taskId });
  }

  async sendTaskConfirmation(
    companyId: string,
    taskId: string,
    aiTaskDocuments: AiTaskDocument[],
    siteIds: string[],
  ): Promise<void> {
    return await makeConfirmAiTask().Call({
      companyId,
      taskId,
      aiTaskDocuments,
      siteIds,
    });
  }

  async getAiTaskSites(
    companyId: string,
    sectionCode: AiTaskSectionCode,
    taskId: string,
    sortSites: SortMeta,
    filterSites: GetSitesFilter,
    pageParam?: number,
  ) {
    return await makeGetAiTaskSites().Call({
      companyId,
      sectionCode,
      taskId,
      sortSites,
      filterSites,
      pageParam,
    });
  }
}

export { CompanyGeneralDocumentsViewModel };
