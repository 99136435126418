import { Flex, useColorModeValue } from "@chakra-ui/react";
import SubNavItem, { SubNavItemProps } from "./SubNavItem";

const SubNavBar = ({ items }: SubNavBarProps) => {
  const renderItems = (items: Array<SubNavItemProps>) => {
    return items
      .filter((i) => !i.hidden)
      .map((link) => (
        <SubNavItem
          icon={link.icon}
          Icon={link.Icon}
          path={link.path}
          name={link.name}
          key={link.name}
          permissions={link.permissions}
        />
      ));
  };

  return (
    <Flex
      direction="column"
      height="100%"
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
    >
      {renderItems(items)}
    </Flex>
  );
};

interface SubNavBarProps {
  items: Array<SubNavItemProps>;
}

export default SubNavBar;
