import React, { useMemo, useState } from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import ReactSelect, { SingleValueProps } from "react-select";

import Tag from "../../../domain/entities/tag";
import TagComponent from "./TagBadge";
import { GroupBase, MultiValueProps, OptionProps } from "react-select";
import { useTranslation } from "react-i18next";

export type TagSelectProps = {
  tags: Tag[];
  selectedTags: string[];
  setSelectedTags: (tags: string[]) => void;
  createTag?: (tagName: string) => void;
  onUpdate?: (tag: Tag) => void;
  style?: React.CSSProperties;
  hideSelected?: boolean;
  isMulti?: boolean;
  defaultMenuIsOpen?: boolean;
  label?: string;
  usePortal?: boolean;
  marginOnTop?: string;
  marginBottom?: string;
  placeholder?: string;
  editable?: boolean;
  isDisabled?: boolean;
};

export type OptionType = {
  value: string;
  label: string;
  color: string;
};

const TagSelect = ({
  tags,
  selectedTags,
  setSelectedTags,
  createTag,
  style,
  hideSelected,
  isMulti = true,
  defaultMenuIsOpen = true,
  label,
  usePortal,
  marginOnTop,
  marginBottom,
  placeholder,
  onUpdate,
  editable,
  isDisabled,
}: TagSelectProps) => {
  const { t } = useTranslation();
  const Select = createTag ? CreatableSelect : ReactSelect;
  const options = useMemo(() => {
    return Array.isArray(tags)
      ? tags?.map((tag) => ({
          value: tag.id,
          label: tag.name,
          color: tag.color,
        }))
      : [];
  }, [tags]);
  const selectedOptions = useMemo(() => {
    return Array.isArray(options)
      ? options?.filter((option) =>
          Array.isArray(selectedTags)
            ? selectedTags?.includes(option.value)
            : [],
        )
      : [];
  }, [options, selectedTags]);

  const availableOptions = useMemo(() => {
    return Array.isArray(options)
      ? options?.filter((option) =>
          Array.isArray(selectedTags)
            ? !selectedTags?.includes(option.value)
            : [],
        )
      : [];
  }, [options, selectedTags]);
  const onChange = (selectedChoice: OptionType | OptionType[]) => {
    const selectedOpt = Array.isArray(selectedChoice)
      ? selectedChoice?.map((option) => option.value)
      : [selectedChoice.value];
    setSelectedTags(selectedOpt);
  };

  const portalProps: {
    menuPortalTarget?: HTMLElement;
    menuPosition?: "fixed" | "absolute";
  } = usePortal
    ? {
        menuPortalTarget: document.body,
        menuPosition: "fixed",
      }
    : {};

  return (
    <Box
      style={{
        marginTop: marginOnTop ? marginOnTop : "20px",
        width: "100%",
        marginBottom: marginBottom ? marginBottom : "20px",
        backgroundColor: "#fff",
        position: "relative",
        padding: "1px",
        ...style,
        /*
        zIndex: "1" 
        After a series of tests, I noticed that the zIndex was creating an undesired effect. Specifically, if there were two selects, one below the other, 
        the dropdown options would open under the select element positioned below. 
        This made the options unreadable.
        */
      }}
    >
      <Select
        defaultMenuIsOpen={defaultMenuIsOpen}
        {...(createTag ? { formatCreateLabel: (userInput) =>`${t("create", { ns: "common" })} ${userInput}`, onCreateOption: createTag, } : {})}
        isMulti={isMulti}
        options={availableOptions}
        onChange={onChange}
        value={selectedOptions}
        maxMenuHeight={100}
        onCreateOption={createTag}
        isDisabled={isDisabled}
        placeholder={placeholder || t("select", { ns: "common" })}
        {...portalProps}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }}
        components={{
          Option: (
            props: OptionProps<OptionType, true, GroupBase<OptionType>>,
          ) => (
            <div
              {...props?.innerProps}
              style={{ margin: "5px", textTransform: "none", zIndex: 9999 }}
            >
              <TagComponent
                tag={{
                  id: props?.data?.value,
                  name: props?.data?.label,
                  color: props?.data?.color,
                }}
              />
            </div>
          ),
          SingleValue: (
            props: SingleValueProps<
              { value: string; label: string; color: string },
              false,
              GroupBase<OptionType>
            >,
          ) => (
            <div
              style={{
                textTransform: "none",
                alignItems: "center",
                margin: "3px",
              }}
            >
              <TagComponent
                tag={{
                  id: props.data?.value,
                  name: props.data?.label,
                  color: props.data?.color,
                }}
              />
            </div>
          ),
          ValueContainer: (
            props: MultiValueProps<OptionType, true, GroupBase<OptionType>>,
          ) => (
            <HStack gap={1} flexWrap={"wrap"}>
              {props?.children}
            </HStack>
          ),
          MultiValueContainer: (
            props: MultiValueProps<OptionType, true, GroupBase<OptionType>>,
          ) => (
            <HStack
              spacing={3}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
            >
              {props?.children}
            </HStack>
          ),
          MultiValue: (
            props: MultiValueProps<OptionType, true, GroupBase<OptionType>>,
          ) => {
            if (hideSelected) return null;
            const {
              label: name,
              value: id,
              color,
            } = options.find(
              (option: OptionType) => option.value === props.data.value,
            ) as OptionType;
            return (
              <div style={{ margin: "3px", textTransform: "none" }}>
                <TagComponent
                  tag={{ name, id, color }}
                  onUpdate={onUpdate}
                  onDelete={(tag, e) => {
                    props.removeProps.onClick(
                      e as unknown as React.MouseEvent<
                        HTMLDivElement,
                        MouseEvent
                      >,
                    );
                  }}
                  editable={editable}
                />
              </div>
            );
          },
        }}
      />
      {label && (
        <Text
          sx={{
            position: "absolute",
            top: "-8px",
            left: "8px",
            padding: "0 10px",
            backgroundColor: "white",
            color: "gray.700",
          }}
          mb={-2}
          fontSize="10px"
        >
          {label}
        </Text>
      )}
    </Box>
  );
};

export default TagSelect;
