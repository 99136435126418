import UseCase from '../../../core/app/usecase';
import File from '../../entities/file';
import DocumentRepository from '../../repositories/documentRepository';
import { GetSitesFilter } from "../../repositories/siteRepository";

type ARGS = {
	companyId: string;
	documentId: string;
	siteId?: string;
	target?: string;
	resourceId?: string;
	siteIds?: string[];
	selectAll?: boolean;
	toSupplier?: boolean;
	selectAllFiles?: boolean,
	fileIds: string[]
	filters: GetSitesFilter
};
/**
 * Add a file to a document
 */
class RepropagateFileToDocument implements UseCase<ARGS, void> {
	constructor(private repository: DocumentRepository) {}

	async Call(parameter: ARGS): Promise<void> {
		return await this.repository.repropagateFileToDocument(
			parameter.companyId,
			parameter.documentId,
			parameter.siteId,
			parameter.target,
			parameter.resourceId,
			parameter.siteIds,
			parameter.toSupplier,
			parameter.selectAll,
			parameter.selectAllFiles,
			parameter.fileIds,
			parameter.filters
		);
	}
}

export default RepropagateFileToDocument;
