import UseCase from '../../../../core/app/usecase';
import SiteWorkerRepository from '../../../repositories/siteWorkerRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	workerId: string;
};

class DeleteSiteWorker implements UseCase<Parameters, void> {
	constructor(private repository: SiteWorkerRepository) {}

	async Call({ companyId, siteId, workerId }: Parameters): Promise<void> {
		return this.repository.deleteSiteWorker(companyId, siteId, workerId);
	}
}

export default DeleteSiteWorker;
