import Permission from "../../../domain/entities/permission";
import {
  makeGetGeneralPermissions,
  makeGetSitePermissions,
} from "../../../core/app/factory/users/usecases";
import { LoginResponse } from "../../../infrastructure/responses/auth";
import { makePerformLoginWithToken } from "../../../domain/interactors/auth/performLoginWithToken";

class AuthViewModel {
  async getUserGeneralPermissions(
    companyId: string,
    userId: string,
  ): Promise<Permission[]> {
    return await makeGetGeneralPermissions().Call({ companyId, userId });
  }

  async getUserSitePermissions(
    companyId: string,
    userId: string,
    siteId: string,
  ): Promise<Permission[]> {
    return await makeGetSitePermissions().Call({
      companyId,
      userId,
      siteId: siteId,
    });
  }

  async loginSilently(action?: string): Promise<LoginResponse> {
    return makePerformLoginWithToken().Call(action);
  }
}

export default AuthViewModel;
