import UseCase from '../../../core/app/usecase';
import { BadgeLogEntry } from '../../entities/badgeLogEntry';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import BadgeReaderRepository, { GetBadgeReaderHistoryFilters } from '../../repositories/badgeReaderRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	badgeReaderId: string;
	pageParam: number;
	filter?: GetBadgeReaderHistoryFilters;
	sort?: SortMeta;
};

class GetBadgeReaderHistory implements UseCase<ARGS, BadgeLogEntry[]> {
	private badgeReaderRepository: BadgeReaderRepository;

	constructor(badgeReaderRepository: BadgeReaderRepository) {
		this.badgeReaderRepository = badgeReaderRepository;
	}

	async Call({ companyId, siteId, badgeReaderId, pageParam, filter, sort }: ARGS): Promise<BadgeLogEntry[]> {
		return await this.badgeReaderRepository.getBadgeReaderHistory(companyId, siteId, badgeReaderId, pageParam, filter, sort);
	}
}

export default GetBadgeReaderHistory;
