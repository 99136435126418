import UseCase from '../../../core/app/usecase';
import UsersRepository from '../../repositories/usersRepository';

interface Args {
	companyId: string;
	invitationToken: string;
	siteId?: string;
}

class RenewInvitation implements UseCase<Args, void> {
	constructor(private repository: UsersRepository) {}

	async Call({ companyId, invitationToken, siteId }: Args): Promise<void> {
		return await this.repository.renewInvitation(companyId, invitationToken, siteId);
	}
}

export default RenewInvitation;
