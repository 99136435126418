import UseCase from '../../../../core/app/usecase';
import SiteWorkerRepository from '../../../repositories/siteWorkerRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	workerId: string;
	specializationIds?: string[];
};

class UpdateSiteWorkerSpecializations implements UseCase<Parameters, void | undefined> {
	constructor(private repository: SiteWorkerRepository) {}

	async Call({ companyId, siteId, workerId, specializationIds }: Parameters): Promise<void | undefined> {
		return this.repository.updateSiteWorkerSpecializations(companyId, siteId, workerId, specializationIds);
	}
}

export default UpdateSiteWorkerSpecializations;
