import Tag from '../../../domain/entities/tag';

type Requirement = {
	variants: Tag[];
	specializations: Tag[];
};

const useVerifiedVariantsAndSpecializationsForRequirement = (
	requirements: Requirement[],
	variants: Tag[],
	specializations: Tag[],
	requirement: Requirement,
) => {
	// get all possible combinations variant id, specialization id inside requirements
	const combinations = extractCombinations(requirements);
	const isValidVariantCombination = (vId: string) => {
		return !combinations.some(([variantId, specializationId]) => {
			for (const spec of requirement.specializations) {
				if (spec.id === specializationId && vId === variantId) {
					return true;
				}
			}
			return requirement.specializations.length === 0 && vId === variantId && specializationId === null;
		});
	};
	const isValidSpecializationCombination = (sId: string | null) => {
		return !combinations.some(([variantId, specializationId]) => {
			for (const variant of requirement.variants) {
				if (variant.id === variantId && sId === specializationId) {
					return true;
				}
			}
			return false;
		});
	};
	return {
		variants: variants?.filter((variant) => isValidVariantCombination(variant.id)),
		specializations: specializations?.filter((specialization) => isValidSpecializationCombination(specialization.id)),
	};
};

const extractCombinations = (requirements: Requirement[]): [string, string | null][] => {
	const combinations: [string, string][] = [];
	if (!requirements) return;
	for (const requirement of requirements) {
		for (const variant of requirement.variants) {
			const variantId = variant.id;
			for (const specialization of requirement.specializations) {
				const specializationId = specialization.id;

				combinations.push([variantId, specializationId]);
			}
			// If specializations is an empty array, add combination with null specialization
			if (requirement.specializations.length === 0) {
				combinations.push([variantId, null]);
			}
		}
	}

	return combinations;
};

export default useVerifiedVariantsAndSpecializationsForRequirement;
