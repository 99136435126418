import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { RequirementSubject } from '../../entities/requirementSubject.enum';

type Parameters = {
	companyId: string;
	supplierId: string;
	resourceId: string;
	type: RequirementSubject;
};

class AutoEvaluateSupplier implements UseCase<Parameters, void> {
	constructor(private repository: SupplierRepository) {}

	async Call({ companyId, supplierId, resourceId, type }: Parameters): Promise<void> {
		return this.repository.autoEvaluateCompanySupplierResource(companyId, supplierId, resourceId, type);
	}
}

export default AutoEvaluateSupplier;
