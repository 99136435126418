import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	supplierId: string;
	variantId?: string;
	type: 'company' | 'site';
};

class UpdateSupplierVariant implements UseCase<Parameters, void | undefined> {
	constructor(private repository: SupplierRepository) {}

	async Call({ companyId, siteId, supplierId, type, variantId }: Parameters): Promise<void | undefined> {
		return this.repository.updateSupplierVariant(companyId, siteId, supplierId, type, variantId);
	}
}

export default UpdateSupplierVariant;
