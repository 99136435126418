import { Flex } from "@chakra-ui/react";
import ContentLayout from "../../layout/ContentLayout";
import { useWorkingSitesViewModel } from "../../hooks/WorkingSite/useWorkingSites";
import WorkingSitesTable from "../../components/Views/WorkingSites/WorkingSitesTable";
import { useMediaQuery } from "@chakra-ui/react";
import SearchInput from "../Common/SearchInput";
import React from "react";
import PropTypes from "prop-types";

const WorkingSiteListView = () => {
  const props = useWorkingSitesViewModel();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  return (
    <ContentLayout>
      <Flex
        h="100%"
        w="100%"
        bg="white"
        alignItems="start"
        flexDirection="column"
        padding={10}
      >
        <Flex justifyContent={"end"} alignItems="center" w={"100%"} mb={4}>
          <SearchInput onSearch={props.setSearch} />
        </Flex>
        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width={isMobile ? "900px" : "100%"}
          position="relative"
          overflow={"hidden"}
        >
          <WorkingSitesTable {...props} />
        </Flex>
      </Flex>
    </ContentLayout>
  );
};

WorkingSiteListView.propTypes = {
  setSearch: PropTypes.string,
};

export default WorkingSiteListView;
