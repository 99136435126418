import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import homeLogo from "../../../assets/images/home-logo.png";
import homeArrow from "../../../assets/images/home-arrow.png";

const OnboardingBackground = () => {
  return (
    <HStack>
      <Flex backgroundColor="#EECE23" width="50vw" height="100vh">
        <Box margin={8}>
          <img src={homeLogo} />
        </Box>
      </Flex>
      <Flex direction="column" width="50vw" height="100vh">
        <Flex flexGrow={1} />
        <Flex>
          <Flex flexGrow={1} />
          <img src={homeArrow} />
        </Flex>
        <Flex flexGrow={1} />
      </Flex>
    </HStack>
  );
};

export default OnboardingBackground;
