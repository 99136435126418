import UseCase from '../../../../core/app/usecase';
import { SiteTool } from '../../../entities/siteTool';
import SiteToolRepository, { GetSiteToolsFilters } from '../../../repositories/siteToolRepository';
import { PaginatedMeta, PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';

type Parameters = {
	companyId: string;
	siteId: string;
	archived?: boolean;
	filter?: GetSiteToolsFilters;
	sort?: SortMeta;
	supplierId?: string;
} & PaginatedMeta;

class GetSiteTools implements UseCase<Parameters, PaginatedResults<SiteTool> | undefined> {
	constructor(private repository: SiteToolRepository) {}

	async Call({ companyId, siteId, sort, perPage, archived, filter, page, supplierId }: Parameters): Promise<PaginatedResults<SiteTool> | undefined> {
		return this.repository.getSiteTools(companyId, siteId, page, perPage, archived, filter, sort, supplierId);
	}
}

export default GetSiteTools;
