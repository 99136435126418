import React, { ChangeEvent } from "react";
import { Select, SelectProps } from "@chakra-ui/react";

// Generic EnumSelect component
type EnumSelectProps<T extends Record<string, string>> = {
  enumObject: T;
  value: string;
  emptySuggestion: string;
  onChange: (value: string) => void;
  marginTop?: number;
  optionSlot?: (key: string, label: string) => React.ReactNode;
} & SelectProps;

const EnumSelect = <T extends Record<string, string>>({
  enumObject,
  value,
  emptySuggestion,
  onChange,
  marginTop,
  optionSlot,
  ...rest
}: EnumSelectProps<T>) => {
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <Select
      required={true}
      value={
        value
          ? Object.keys(enumObject).find((key) => enumObject[key] === value)
          : ""
      }
      onChange={handleSelectChange}
      {...rest}
      marginTop={marginTop}
      placement="bottom"
    >
      <option value="" disabled>
        {emptySuggestion}
      </option>
      {Object.entries(enumObject).map(([key, optionLabel]) =>
        optionSlot ? (
          optionSlot(key, optionLabel)
        ) : (
          <option key={key} value={key}>
            {key}
          </option>
        ),
      )}
    </Select>
  );
};

export default EnumSelect;
