import UseCase from '../../../../core/app/usecase';
import Document from '../../../entities/document';
import WorkerRepository from '../../../repositories/workerRepository';
import { GetDocumentsFilter } from '../../../repositories/documentRepository';
import { SortMeta } from '../../../entities/interfaces/paginatedResults';

type GetWorkerDocumentsParameters = {
	companyId: string;
	resourceId: string;
	filter?: GetDocumentsFilter;
	sort?: SortMeta;
	pageParam?: number;
};

/**
 * Returns the list of all the existing Document.
 */
class GetWorkerDocuments implements UseCase<GetWorkerDocumentsParameters, Document[]> {
	constructor(private repository: WorkerRepository) {}

	async Call({ companyId, resourceId, sort, filter, pageParam }: GetWorkerDocumentsParameters): Promise<Document[]> {
		return await this.repository.getWorkerDocuments(companyId, resourceId, filter, sort, pageParam);
	}
}

export default GetWorkerDocuments;
