import UseCase from '../../../core/app/usecase';
import DocumentComment from '../../entities/documentComment';
import { PaginatedResults, SortMeta } from '../../entities/interfaces/paginatedResults';
import DocumentRepository from '../../repositories/documentRepository';

interface Args {
	companyId: string;
	groupId: string;
	pageParam?: number;
	sort?: SortMeta;
}

class GetDocumentCommentGroup implements UseCase<Args, PaginatedResults<DocumentComment>> {
	constructor(private repository: DocumentRepository) {}
	async Call({ companyId, groupId, pageParam, sort }: Args): Promise<PaginatedResults<DocumentComment>> {
		return this.repository.getDocumentCommentGroup(companyId, groupId, pageParam, sort);
	}
}

export default GetDocumentCommentGroup;
