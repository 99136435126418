import Badge, {VehicleMetas} from "../../../../domain/entities/badge";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "./badgeStyles";
import React from "react";
import vehiclePlaceholder from "../../../../assets/images/vehicle.png";
import FrontBadgeContainer from "./FrontBadgeContainer";

type NfcBadgeVehicleProps = {
  badge: Badge;
};
const FrontBadgeVehicle = ({ badge }: NfcBadgeVehicleProps) => {
  const { plate, photo } = badge.resource.meta as VehicleMetas;
  return (
    <FrontBadgeContainer badge={badge} photo={photo || vehiclePlaceholder}>
      <View style={styles.badgeDetailsRow}>
        <Text style={{ fontWeight: 700 }}>Veicolo: </Text>
        <Text style={styles.text}>{badge.resource.name}</Text>
      </View>
      <View style={styles.badgeDetailsRow}>
        <Text style={{ fontWeight: 700 }}>Targa: </Text>
        <Text>{plate}</Text>
      </View>
    </FrontBadgeContainer>
  );
};
export default FrontBadgeVehicle;
