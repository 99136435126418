export enum DocumentTypeCategory {
	COMPANY = 'company',
	WORKER = 'worker',
	VEHICLE = 'vehicle',
	MACHINE = 'machine',
	TOOL = 'tool',
	CHEMICAL = 'chemical',
	SITE = 'site',
  SUPPLIER = 'supplier',
	SUPPLIER_COMPANY = 'supplier company',
	SUPPLIER_SITE = 'supplier site',
}
