import UseCase from '../../../../core/app/usecase';
import Requirement from '../../../entities/requirement';
import SiteWorkerRepository from '../../../repositories/siteWorkerRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	workerId: string;
	requirements: {
		documentTypeId: string;
		isOptional: boolean;
		graceDays: number;
	}[]
};

class AddRequirementToSiteWorker implements UseCase<ARGS, Requirement> {
	private siteRepository: SiteWorkerRepository;

	constructor(siteRepository: SiteWorkerRepository) {
		this.siteRepository = siteRepository;
	}

	async Call(parameters: ARGS): Promise<Requirement> {
		return await this.siteRepository.addRequirementToSiteWorker(
			parameters.companyId,
			parameters.siteId,
			parameters.workerId,
			parameters.requirements
		);
	}
}

export default AddRequirementToSiteWorker;
