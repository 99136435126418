import UseCase from '../../../core/app/usecase';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { InvitedSupplier } from '../../entities/invitedSupplier';
import SupplierRepository, { InvitedSuppliersFilter } from '../../repositories/supplierRepository';
import CompanyParameters from './companyParameters';

interface Args {
	siteId: string;
	companyId: string;
	filter?: InvitedSuppliersFilter;
	sort?: SortMeta;
	pageParam?: number;
}

class GetInvitedSuppliers implements UseCase<CompanyParameters, InvitedSupplier[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, siteId, filter, sort, pageParam }: Args): Promise<InvitedSupplier[]> {
		return await this.supplierRepository.getInvitedSuppliers(siteId, companyId, filter, sort, pageParam);
	}
}

export default GetInvitedSuppliers;
