import { EvaluationMode } from '../../domain/entities/evaluation';

export type GetEvaluationsResponse<T> = {
	results: {
		createdAt: string;
		expiresAt: string | null;
		evaluator: string | null;
		mode: EvaluationMode;
		resourceId: string;
		result: T;
	}[];
};

export const mapEvaluation = (evaluation) => ({
	result: evaluation.result,
	createdAt: evaluation.createdAt ? new Date(evaluation.createdAt) : undefined,
	evaluator: evaluation.evaluator,
	mode: evaluation.mode,
	expiresAt: evaluation.expiresAt ? new Date(evaluation.expiresAt) : undefined,
});
