import UseCase from '../../../core/app/usecase';
import DocumentType, { RenameModel } from '../../entities/documentType';
import DocumentTypeRepository from '../../repositories/documentTypeRepository';
import CreateDocumentTypeParameters from './createDocumentTypeParameter';

type Args = {
	companyId: string;
	documentTypeId: string;
	renameModel: RenameModel;
};

class UpsertRenameModel implements UseCase<Args, void> {
	constructor(private repository: DocumentTypeRepository) {}

	async Call({ companyId, documentTypeId, renameModel }: Args): Promise<void> {
		return await this.repository.upsertRenameModel(companyId, documentTypeId, renameModel);
	}
}

export default UpsertRenameModel;
