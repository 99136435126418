import BaseModal from "../../Common/alerts/BaseModal";
import { useTranslation } from "react-i18next";
import TimeTableDocuments, {
  categoryDocuments,
} from "../../../../domain/entities/timeTableDocuments";
import { COLORS } from "../../../assets/theme/colors";
import {
  Flex,
  Text,
  Spacer,
  IconButton,
  Box,
  Divider,
  Tooltip,
} from "@chakra-ui/react";
import { FiChevronRight, FiFile } from "react-icons/fi";
import { formatDateBasedOnLanguage } from "../../../../utils";

const documentsColorsMap = {
  worker: COLORS.active,
  vehicle: COLORS.green,
  machine: COLORS.sikuroBlue,
  tool: COLORS.lightGreen,
  chemical: COLORS.table,
  company: COLORS.active,
  site: COLORS.sikuroBlue,
};

export type TimeTableModalProps = {
  timeTableDocumentsProp: TimeTableDocuments;
  onClose: () => void;
  selectedDate: Date;
  onDocumentSelected?: (resourceId: string, resourceType: string) => void;
};

const TimeTableDayDetailModal = ({
  timeTableDocumentsProp,
  onClose,
  selectedDate,
  onDocumentSelected,
}: TimeTableModalProps) => {
  const { t } = useTranslation("documents");

  const filteredTimeTableDocuments: TimeTableDocuments = {
    chemical: filterCategoryDocuments(timeTableDocumentsProp.chemical),
    machine: filterCategoryDocuments(timeTableDocumentsProp.machine),
    tool: filterCategoryDocuments(timeTableDocumentsProp.tool),
    vehicle: filterCategoryDocuments(timeTableDocumentsProp.vehicle),
    worker: filterCategoryDocuments(timeTableDocumentsProp.worker),
    company: filterCategoryDocuments(timeTableDocumentsProp.company),
    site: filterCategoryDocuments(timeTableDocumentsProp.site),
  };

  function filterCategoryDocuments(
    categories: categoryDocuments[],
  ): categoryDocuments[] {
    return categories
      ?.map((category) => {
        const filteredDocuments = category.documents.filter((document) => {
          const expiresAt = new Date(document?.expiresAt);

          return (
            expiresAt.getDate() == selectedDate.getDate() &&
            expiresAt.getMonth() == selectedDate.getMonth() &&
            expiresAt.getFullYear() == selectedDate.getFullYear()
          );
        });

        return {
          ...category,
          documents: filteredDocuments,
        };
      })
      .filter((category) => category.documents.length > 0);
  }

  return (
    <BaseModal
      title={
        t("timeTableDayDetail", { ns: "documents" }) +
        selectedDate?.toLocaleDateString()
      }
      onClose={onClose}
      size="5xl"
    >
      <div>
        {Object.entries(filteredTimeTableDocuments).map(
          ([resourceType, resources]) => {
            if (resources && resources.length) {
              return (
                <div key={resourceType}>
                  <Text textColor={"black"} fontSize={15} fontWeight={"bold"}>
                    {t(resourceType, { ns: "documents" })}
                  </Text>
                  {resources &&
                    resources.map((resource) => (
                      <Flex
                        direction="column"
                        margin="0.5rem 0"
                        borderRadius="5px"
                        boxShadow="base"
                        padding="0.5rem"
                        key={resource.resourceId}
                        style={{
                          backgroundColor:
                            COLORS.calendar[resourceType] ?? COLORS.gray,
                        }}
                      >
                        <Text
                          textColor="black"
                          fontWeight="normal"
                          marginLeft={5}
                        >
                          {t("resourceName", { ns: "documents" })}
                          <span
                            style={{ fontStyle: "italic", fontWeight: 500 }}
                          >
                            {resource.resourceName}
                          </span>
                        </Text>
                        {resource.documents.map((document, index) => (
                          <Flex
                            key={document.name}
                            direction="column"
                            margin="0 2.5rem"
                          >
                            <Flex
                              alignItems="center"
                              justifyContent="space-evenly"
                              padding={"0.5rem 0"}
                              gap={8}
                            >
                              <Tooltip label={document?.name} fontSize="xs">
                                <Box
                                  flex="1"
                                  display="flex"
                                  alignItems="center"
                                  gap="0.5rem"
                                >
                                  <Box>
                                    <FiFile />
                                  </Box>
                                  <Text
                                    width={"25rem"}
                                    textColor={"black"}
                                    fontWeight={"normal"}
                                    alignContent="start"
                                    textOverflow="ellipsis"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                  >
                                    {document?.name}
                                  </Text>
                                </Box>
                              </Tooltip>
                              <Box flex="1">
                                <Text textColor={"black"} fontWeight={"normal"}>
                                  {document?.expiresAt
                                    ? formatDateBasedOnLanguage(
                                        document.expiresAt,
                                      )
                                    : ""}
                                </Text>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <IconButton
                                  aria-label={"clear"}
                                  icon={<FiChevronRight />}
                                  colorScheme="black"
                                  variant={"outline"}
                                  onClick={() =>
                                    onDocumentSelected(
                                      resource.resourceId,
                                      resourceType,
                                    )
                                  }
                                  size="xs"
                                />
                              </Box>
                            </Flex>
                            {index !== resource.documents.length - 1 ? (
                              <Divider />
                            ) : null}
                          </Flex>
                        ))}
                      </Flex>
                    ))}
                  <Spacer height={5} backgroundColor="white" />
                </div>
              );
            }
          },
        )}
      </div>
    </BaseModal>
  );
};

export default TimeTableDayDetailModal;
