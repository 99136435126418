export enum SupplierStatus {
	ACTIVE = 'active',
	BLOCKED = 'blocked',
	PENDING = 'pending',
	ARCHIVED = 'archived'
}

export enum ActiveSupplierStatus {
	ACTIVE = 'active',
	BLOCKED = 'blocked',
	PENDING = 'pending',
}
