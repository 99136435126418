import UseCase from '../../../../core/app/usecase';
import { ResourceDocumentEvaluationState } from '../../../entities/resourceDocumentEvaluationState.enum';
import ToolRepository from '../../../repositories/toolRepository';
import { GetSitesFilter } from "../../../repositories/siteRepository";

type RepropagateEvaluationToolDocumentParameters = {
	companyId: string;
	resourceId: string;
	documentId: string;
	siteIds: string[];
	selectAll?: boolean;
};

/**
 * Returns the list of all the existing Document.
 */
class RepropagateEvaluationToolDocument implements UseCase<RepropagateEvaluationToolDocumentParameters, void> {
	constructor(private repository: ToolRepository) {}

	async Call({
		companyId,
		resourceId,
		documentId,
		siteIds,
		selectAll,
	}: RepropagateEvaluationToolDocumentParameters): Promise<void> {
		return await this.repository.repropagateEvaluationToolDocument(
			companyId,
			resourceId,
			documentId,
			siteIds,
			selectAll,
		);
	}
}

export default RepropagateEvaluationToolDocument;
