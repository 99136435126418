import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import {CustomField} from "../../../domain/entities/customFields";
import {CustomFieldsViewModel} from "../../viewmodels/customFields/CustomFieldsViewModel";

export type UseCustomFieldsViewModel = ReturnType<typeof useCustomFieldsViewModel>

const useCustomFieldsViewModel = () => {
  const { companyId } = useAuth();
  const viewModel = new CustomFieldsViewModel(companyId);

  const getCustomFields = useQuery(
    ["get-custom-fields", companyId],
    async () => await viewModel.getCustomFields(),
  );

  const setCustomFields = useMutation(
    async (customFields: CustomField[]) =>
      await viewModel.setCustomFields(customFields.map((field) => (
        { ...field, key: field.key ? field.key : slugify(field.label) }
      ))),
    {
      onSuccess: async () => {
        await getCustomFields.refetch();
      },
    },
  );

  return {
    customFields: {
      data: getCustomFields.data,
      status: getCustomFields.status,
      error: getCustomFields.error?.toString(),
    },
    setCustomFields: {
      mutate: setCustomFields.mutateAsync,
      status: setCustomFields.status,
      error: setCustomFields.error?.toString(),
    },
  };
};

function slugify(text) {
  return text
    .toLowerCase() // Convert to lowercase
    .trim() // Trim leading/trailing whitespace
    .replace(/[^\w\s-]/g, '') // Remove special characters (except hyphens and spaces)
    .replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : '')) // Convert spaces/hyphens to camelCase
}

export default useCustomFieldsViewModel;
