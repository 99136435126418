import UseCase from '../../../core/app/usecase';
import { DocumentPresetTarget } from '../../entities/documentPresetTarget.enum';
import Tag from '../../entities/tag';
import DocumentsPresetsRepository from '../../repositories/documentsPresetsRepository';

interface Args {
	companyId: string;
	presetId: string;
	typology: Tag;
	target: DocumentPresetTarget;
}

class AddTypologyToDocumentPreset implements UseCase<Args, void> {
	constructor(private repository: DocumentsPresetsRepository) {}

	async Call(args: Args): Promise<void> {
		await this.repository.addTypologyToDocumentPreset(args.companyId, args.presetId, args.typology, args.target);
	}
}

export default AddTypologyToDocumentPreset;
