import UseCase from '../../../../core/app/usecase';
import ToolRepository from '../../../repositories/toolRepository';

interface Args {
	companyId: string;
	resourceId: string;
	typologyId: string;
}

class AddTypologyToTool implements UseCase<Args, void> {
	constructor(private repository: ToolRepository) {}

	async Call(args: Args): Promise<void> {
		return await this.repository.addTypologyToTool(args.companyId, args.resourceId, args.typologyId);
	}
}

export default AddTypologyToTool;
