import UseCase from '../../../core/app/usecase';
import DocumentType from '../../entities/documentType';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import { PaginatedResults } from '../../entities/interfaces/paginatedResults';
import SiteRepository from '../../repositories/siteRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	resourceType: DocumentTypeCategory;
	supplierId: string;
	pageParam?: number;
};

class GetSiteResourcesDocuments implements UseCase<Parameters, PaginatedResults<DocumentType>> {
	constructor(private repository: SiteRepository) {}

	async Call({ companyId, siteId, resourceType, supplierId, pageParam }: Parameters): Promise<PaginatedResults<DocumentType>> {
		return this.repository.getSiteResourcesDocuments(companyId, siteId, resourceType, supplierId, pageParam);
	}
}

export default GetSiteResourcesDocuments;
