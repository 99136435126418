import UseCase from '../../../core/app/usecase';
import RolesRepository from '../../repositories/rolesRepository';

interface Args {
	companyId: string;
	roleId: string;
	contextRole: string;
	newRoleId?: string;
}

class DeleteRole implements UseCase<Args, boolean> {
	constructor(private repository: RolesRepository) {}

	async Call({ companyId, roleId, contextRole, newRoleId }: Args): Promise<boolean> {
		return await this.repository.deleteRole(companyId, roleId, contextRole, newRoleId);
	}
}

export default DeleteRole;
