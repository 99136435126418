import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { AiOutlineClear } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";

interface SearchInputProps {
  onSearch: (query: string) => void;
  debounceTime?: number;
}

const SearchInput: React.FC<SearchInputProps> = ({
                                                   onSearch,
                                                   debounceTime = 1000,
                                                 }) => {
  const { t } = useTranslation("common");
  const [query, setQuery] = useState<string>("");
  const debouncedSearchRef = useRef<ReturnType<typeof debounce> | null>(null);

  useEffect(() => {
    debouncedSearchRef.current = debounce((newQuery: string) => {
      onSearch(newQuery);
    }, debounceTime);
    return () => {
      debouncedSearchRef.current?.cancel();
    };
  }, [debounceTime, onSearch]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    debouncedSearchRef.current?.(newQuery);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch(query);
    }
  };

  const clearSearch = () => {
    setQuery("");
    onSearch("");
  };

  return (
    <InputGroup w="300px">
      <Input
        type="text"
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={t("search")}
      />
      <InputRightElement>
        {query.length === 0 ? (
          <FiSearch cursor="pointer" />
        ) : (
          <AiOutlineClear cursor="pointer" onClick={clearSearch} />
        )}
      </InputRightElement>
    </InputGroup>
  );
};

SearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
  debounceTime: PropTypes.number,
};

export default SearchInput;
