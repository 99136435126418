import { WorkerDocumentsViewModel } from "../../../viewmodels/workers/WorkerDocumentsViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { useResourceDocumentsViewModel } from "../useResourceDocumentsViewModel";

const useWorkerDocumentsViewModel = (resourceId: string) =>
  useResourceDocumentsViewModel(
    new WorkerDocumentsViewModel(resourceId),
    DocumentTypeCategory.WORKER,
    resourceId,
  );

export default useWorkerDocumentsViewModel;
