import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../Common/TopBar";
import { getSectionPermissions } from "../../Permissions/Permissions";

const ResourcesTopBar = () => {
  const { t } = useTranslation();

  const items: TopBarItem[] = [
    {
      title: t("top.workers", { ns: "navbar" }),
      path: "/resources/workers",
      permissions: getSectionPermissions("resources", "workers"),
    },
    {
      title: t("top.vehicles", { ns: "navbar" }),
      path: "/resources/vehicles",
      permissions: getSectionPermissions("resources", "vehicles"),
    },
    {
      title: t("top.machinery", { ns: "navbar" }),
      path: "/resources/machines",
      permissions: getSectionPermissions("resources", "machines"),
    },
    {
      title: t("top.tools", { ns: "navbar" }),
      path: "/resources/tools",
      permissions: getSectionPermissions("resources", "tools"),
    },
    {
      title: t("top.chemicals", { ns: "navbar" }),
      path: "/resources/chemicals",
      permissions: getSectionPermissions("resources", "chemicals"),
    },
  ];

  return (
    <TopBar title={t("main.my resources", { ns: "navbar" })} items={items} />
  );
};

export default ResourcesTopBar;
