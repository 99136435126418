import UseCase from '../../../core/app/usecase';
import BadgeRepository, { GetBadgeHistoryFilters } from '../../repositories/badgeRepository';
import { BadgeLogEntry } from '../../entities/badgeLogEntry';
import { SortMeta } from '../../entities/interfaces/paginatedResults';

type ARGS = {
	companyId: string;
	badgeId: string;
	filter?: GetBadgeHistoryFilters;
	sort?: SortMeta;
	pageParam?: number;
};

class GetBadgeHistory implements UseCase<ARGS, BadgeLogEntry[]> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, badgeId, filter, sort, pageParam }: ARGS): Promise<BadgeLogEntry[]> {
		return await this.badgeRepository.getBadgeHistory(companyId, badgeId, filter, sort, pageParam);
	}
}

export default GetBadgeHistory;
