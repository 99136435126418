import UseCase from '../../../core/app/usecase';
import { UpdateAlertParams } from '../../../presentation/hooks/Users/useUserSettingsViewModel';
import NotificationsRepository, { makeNotificationsRepository } from '../../repositories/notificationsRepository';

interface Args {
	companyId: string;
	userId: string;
	alert: UpdateAlertParams;
}

/**
 * Update the notification.
 */
class UpdateAlertNotificationUseCase implements UseCase<Args, void> {
	constructor(private repository: NotificationsRepository) {}

	async Call(args: Args): Promise<void> {
		const { companyId, userId, alert } = args;
		return await this.repository.updateAlertNotification(companyId, userId, alert);
	}
}

export const makeUpdateAlertNotificationUseCase = (): UpdateAlertNotificationUseCase => {
	return new UpdateAlertNotificationUseCase(makeNotificationsRepository());
};
