import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useAuth } from '../../providers/Auth0JWTProvider';
import { InfiniteData, QueryObserverResult, useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import Variant from '../../../domain/entities/variant';
import Specialization from '../../../domain/entities/specialization';
import { SiteResource } from '../../../domain/entities/siteResource';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { GetSiteWorkersFilters } from '../../../domain/repositories/siteWorkerRepository';
import { GetSiteMachinesFilters } from '../../../domain/repositories/siteMachineRepository';
import { GetSiteVehiclesFilters } from '../../../domain/repositories/siteVehicleRepository';
import { GetSiteToolsFilters } from '../../../domain/repositories/siteToolRepository';
import { GetSiteChemicalsFilters } from '../../../domain/repositories/siteChemicalRepository';
import { GetResourcesFilter } from '../../../domain/repositories/workerRepository';
import { useSearchParams } from 'react-router-dom';
import { updateFilterWithDelete } from '../../../utils';
import { BadgeType } from '../../../domain/entities/badgeType.enum';

export type GetSiteResourcesFilters = Omit<
	GetSiteWorkersFilters | GetSiteMachinesFilters | GetSiteVehiclesFilters | GetSiteToolsFilters | GetSiteChemicalsFilters,
	'badgeAvailable'
>;

export type NewResource = {
	resourceId: string;
	variantId: string;
	specializationsIds: string[];
};

export interface SiteResourcesViewModel<Resource> {
	getSiteResources: (
		companyId: string,
		siteId: string,
		page: number,
		archived?: boolean,
		filter?: GetSiteResourcesFilters,
		sort?: SortMeta,
		supplierId?: string,
	) => Promise<PaginatedResults<SiteResource<Resource>>>;
	getOwnedResources: (
		companyId: string,
		siteId: string,
		filter?: GetResourcesFilter,
		sort?: SortMeta,
		pageParam?: number,
	) => Promise<PaginatedResults<Resource>>;
	getVariants: (companyId: string, siteId: string) => Promise<Variant[]>;
	getSpecializations: (companyId: string, siteId: string) => Promise<Specialization[]>;
	createSiteResource: (
		companyId: string,
		siteId: string,
		resources: NewResource[],
		selectAll: boolean,
		selectedResourceNumber: number,
		selectAllVariants: string,
		selectAllSpecializations: string[],
		filterSiteResources?: Record<string, string | string[]>,
	) => Promise<boolean>;
	deleteSiteResource: (companyId: string, resourceId: string, siteId: string) => Promise<void>;
	restoreSiteResource: (companyId: string, resourceId: string, siteId: string) => Promise<void>;
}

export type useSiteResourcesViewModel<Resource> = {
	siteResources: SiteResource<Resource>[];
	siteAvailableResourceTotalCount: number;
	ownedResources: Resource[];
	ownedResourcesFetching: boolean;
	ownedResourcesHasNextPage;
	ownedResourcesFetchNextPage;
	deleteSiteResource: (params: { resourceId: string }) => void;
	restoreSiteResource: (params: { resourceId: string }) => void;
	createSiteResource: (
		resources: NewResource[],
		selectAll: boolean,
		selectedAvailableResourceNumber: number,
		selectAllVariants: string,
		selectAllSpecializations: string[],
	) => void;
	variants: Variant[];
	specializations: Specialization[];
	filterSiteResources: Record<string, string | string[]>;
	siteResourcesSort: SortMeta;
	searchOwnedResources: string;
	setSearchOwnedResources: Dispatch<SetStateAction<string>>;
	sortOwnedResources: SortMeta;
	updateFilterSiteResources: (column: string, value: string | string[]) => void;
	setSiteResourcesSort: (sort: SortMeta) => void;
	setSortOwnedResources: (sort: SortMeta) => void;
	showArchived: boolean;
	setShowArchived: Dispatch<SetStateAction<boolean>>;
	isFetching: boolean;
	siteResourcesIsLoading: boolean;
	siteResourcesFetchNextPage: () => void;
	siteResourcesHasNextPage: boolean;
	setSiteResourceCreationActive: Dispatch<SetStateAction<boolean>>;
	deleteCandidateIsLoading: boolean;
	badgesAvailable: number;
	badgesAvailableType: BadgeType;
	setSearchActive?: (search: string) => void;
	setSearchArchived?: (search: string) => void;
};

function createUseSiteResourcesViewModel<Resource>(viewModelFactory: () => SiteResourcesViewModel<Resource>) {
	return function useSiteResourcesViewModel(siteId: string, resourceColumns: string[], type: string): useSiteResourcesViewModel<Resource> {
		const { companyId } = useAuth();

		const [query] = useSearchParams();
		const supplierId = query.get('supplierId');

		const viewModel = useMemo(viewModelFactory, []);
		const [searchActive, setSearchActive] = useState<string>();
		const [searchArchived, setSearchArchived] = useState<string>();
		const [showArchived, setShowArchived] = useState<boolean>(false);
		const [filterSiteResources, setFilterSiteResources] = useState<GetSiteResourcesFilters>({});
		const [siteResourcesSort, setSiteResourcesSort] = useState<SortMeta>(null);
		const [searchOwnedResources, setSearchOwnedResources] = useState('');
		const [sortOwnedResources, setSortOwnedResources] = useState<SortMeta>();
		const [siteResourceCreationActive, setSiteResourceCreationActive] = useState(false);
		const [totalResourceAvailableCount, setTotalResourceAvailableCount] = useState<number>(0);
		const [badgesAvailable, setBadgesAvailable] = useState(0);
		const [badgesAvailableType, setBadgesAvailableType] = useState<BadgeType>();

		const updateFilterSiteResources = (column: string, value: string) => {
			updateFilterWithDelete(setFilterSiteResources, column, value);
		};

		const siteResourcesQuery = useInfiniteQuery(
			['site-resources', type, companyId, filterSiteResources, siteResourcesSort, supplierId, showArchived, searchArchived, searchActive],
			async ({ pageParam = 1 }) => {
				let filters;
				if (showArchived) {
					filters = searchArchived ? { ...filterSiteResources, search: searchArchived } : filterSiteResources;
				} else {
					filters = searchActive ? { ...filterSiteResources, search: searchActive } : filterSiteResources;
				}
				const { results, count } = await viewModel.getSiteResources(
					companyId,
					siteId,
					pageParam,
					showArchived,
					filters,
					siteResourcesSort,
					supplierId,
				);
				setTotalResourceAvailableCount(count);
				return results;
			},
			{
				getNextPageParam: (lastPage, pages) => {
					if (lastPage?.length === 25) {
						return pages.length + 1;
					}
				},
			},
			
		);

		const ownedResourcesQuery = useInfiniteQuery(
			['owned-resources', companyId, siteId, searchOwnedResources, sortOwnedResources, type],
			async ({ pageParam = 1 }) => {
				const { results, count, badgesCount, badgeType } = await viewModel.getOwnedResources(
					companyId,
					siteId,
					searchOwnedResources ? { search: searchOwnedResources } : undefined,
					sortOwnedResources,
					pageParam,
				);
				setTotalResourceAvailableCount(count);
				setBadgesAvailable(badgesCount);
				setBadgesAvailableType(badgeType);
				return results;
			},
			{
				getNextPageParam: (lastPage, pages) => {
					if (lastPage?.length === 25) {
						return pages.length + 1;
					}
				},
				enabled: siteResourceCreationActive,
			},
		);

		const variantsQuery = useQuery(['variants', companyId, type], () => viewModel.getVariants(companyId, siteId), {
			initialData: [],
		});

		const specializationsQuery = useQuery(['specializations', companyId, type], () => 	{
			return viewModel.getSpecializations(companyId, siteId);
		}, {
			initialData: [],
		});

		const createSiteResourceMutation = useMutation(
			async (params: {
				resources: {
					resourceId: string;
					variantId: string;
					specializationsIds: string[];
				}[];
				selectAll: boolean;
				selectedAvailableResourceNumber: number;
				selectAllVariants: string;
				selectAllSpecializations: string[];
				filterSiteResources?: Record<string, string | string[]>;
			}) =>
				await viewModel.createSiteResource(
					companyId,
					siteId,
					params.resources,
					params.selectAll,
					params.selectedAvailableResourceNumber,
					params.selectAllVariants,
					params.selectAllSpecializations,
					filterSiteResources,
				),
			{
				onSuccess: () => {
					siteResourcesQuery.refetch();
					ownedResourcesQuery.refetch();
				},
			},
		);

		const createSiteResource = async (
			resources: {
				resourceId: string;
				variantId: string;
				specializationsIds: string[];
			}[],
			selectAll: boolean,
			selectedAvailableResourceNumber: number,
			selectAllVariants: string,
			selectAllSpecializations: string[],
			filterSiteResources?: Record<string, string | string[]>,
		) => {
			createSiteResourceMutation.mutateAsync({
				resources,
				selectAll,
				selectedAvailableResourceNumber,
				selectAllVariants,
				selectAllSpecializations,
				filterSiteResources,
			});
		};

		const deleteSiteResourceMutation = useMutation(
			async ({ resourceId }: { resourceId: string }) => await viewModel.deleteSiteResource(companyId, resourceId, siteId),
			{
				onSuccess: () => {
					siteResourcesQuery.refetch();
					ownedResourcesQuery.refetch();
				},
			},
		);

		const restoreSiteResourceMutation = useMutation(
			async ({ resourceId }: { resourceId: string }) => await viewModel.restoreSiteResource(companyId, resourceId, siteId),
			{
				onSuccess: () => {
					siteResourcesQuery.refetch();
					ownedResourcesQuery.refetch();
				},
			},
		);
		const variants = variantsQuery.data;

		const specializations = specializationsQuery.data;

		const siteResources = siteResourcesQuery?.data?.pages?.flat() ?? [];
		const ownedResources = ownedResourcesQuery?.data?.pages?.flat() ?? [];
		return {
			siteResources,
			siteAvailableResourceTotalCount: totalResourceAvailableCount,
			siteResourcesHasNextPage: siteResourcesQuery.hasNextPage,
			siteResourcesFetchNextPage: siteResourcesQuery.fetchNextPage,
			isFetching: siteResourcesQuery.isFetching,
			siteResourcesIsLoading: siteResourcesQuery.isLoading,
			ownedResources,
			ownedResourcesFetching: ownedResourcesQuery.isLoading,
			ownedResourcesHasNextPage: ownedResourcesQuery.hasNextPage,
			ownedResourcesFetchNextPage: ownedResourcesQuery.fetchNextPage,
			variants,
			specializations,
			showArchived,
			setShowArchived,
			deleteSiteResource: deleteSiteResourceMutation.mutateAsync,
			restoreSiteResource: restoreSiteResourceMutation.mutate,
			createSiteResource,
			filterSiteResources,
			updateFilterSiteResources,
			siteResourcesSort,
			setSiteResourcesSort,
			searchOwnedResources,
			setSearchOwnedResources,
			sortOwnedResources,
			setSortOwnedResources,
			setSiteResourceCreationActive,
			deleteCandidateIsLoading: deleteSiteResourceMutation.isLoading,
			badgesAvailable,
			badgesAvailableType,
			setSearchActive,
			setSearchArchived,
		};
	};
}

export { createUseSiteResourcesViewModel };
