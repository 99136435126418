import { Box, Checkbox, Flex, Input, Text } from "@chakra-ui/react";
import { startOfDay } from "date-fns";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdModeEdit } from "react-icons/md";
import FileEntity from "../../../../domain/entities/file";
import { formatDateBasedOnLanguage } from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import { UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import DiscardButton from "../../Common/table/DiscardButton";
import SaveButton from "../../Common/table/SaveButton";
import RenderIf from "../../Permissions/RenderIf";
import { DownloadFile } from "./DownloadFile";
import { ResourceDocumentsPermissions } from "./SiteResourceDocuments";
import CustomInputDate from "./CustomDateInput/CustomDateInput";
import { Alert } from "../../../screens/Common/Alert";
import { DeleteIcon } from "@chakra-ui/icons";
import Document, {
  SiteResourceDocument,
} from "../../../../domain/entities/document";
import "./editButton.css";
import PendingEvaluation from "../../../../domain/entities/pending-evaluation";

interface DocumentFileListProps {
  files: FileEntity[];
  activeFile: FileEntity;
  setActiveFile: (file: FileEntity) => void;
  editFileNameAndDate: string;
  updatedFile: { name: string; date: string };
  setEditFileNameAndDate: (value: string | undefined) => void;
  setUpdatedFile: Dispatch<
    SetStateAction<{
      name: string;
      date: string;
    }>
  >;
  docDetailPermissions: ResourceDocumentsPermissions;
  renameModels: string[];
  type: "document" | "requirements" | "evaluation";
  document: Document | SiteResourceDocument | PendingEvaluation;
  downloader: UseDownloader;
  editFileDetails: () => void;
  canAddFile: boolean;
  deleteFileFromDoc: (file: FileEntity) => void;
  setAction: (action: null) => void;
  siteGeneralDocument: boolean;
  selectAllFiles: boolean;
  handleSelectAll: (value: boolean) => void;
  handleCheckItem: (value: string) => void;
  fileIds: string[];
  showRepropagate: boolean
  setShowSelectAllFiles: (value: boolean) => void
  showSelectAllFiles: boolean
}
export const DocumentFileList = ({
  files,
  activeFile,
  setActiveFile,
  editFileNameAndDate,
  updatedFile,
  setEditFileNameAndDate,
  setUpdatedFile,
  docDetailPermissions,
  document,
  downloader,
  renameModels,
  type,
  editFileDetails,
  canAddFile,
  deleteFileFromDoc,
  setAction,
  siteGeneralDocument,
  selectAllFiles,
  handleSelectAll,
  handleCheckItem,
  fileIds,
  showRepropagate,
  setShowSelectAllFiles,
  showSelectAllFiles
}: DocumentFileListProps) => {
  const { t } = useTranslation("documents");
  const [invalidDate, setInvalidDate] = useState<string>();
  const [showErrorDate, setShowErrorDate] = useState(false);
  const [invalidFilename, setInvalidFilename] = useState<boolean>(false);

  const didRenameDocument = (name: string) => {
    // This regex allows strings that may start or end with a space, hyphen, or underscore,
    // while accepting valid combinations of alphanumeric characters, spaces, hyphens, and underscores.
    const validNameRX = /^(?:[\w\s-]*[\w\s])?[\w\s-]*$/i;
    const nameIsValid = validNameRX.test(name);
    if (nameIsValid) {
      setUpdatedFile((current) => ({ ...current, name }));
    }
    setInvalidFilename(!nameIsValid);
  };

  return (
    <>
      {showRepropagate && showSelectAllFiles && docDetailPermissions.addFile && files?.length > 0 && (
        <Flex height={30} bgColor={"lightgray"} p={2} pt={5} pb={5}>
          <Checkbox
            borderColor={"gray.500"}
            isChecked={selectAllFiles}
            onChange={() => handleSelectAll(!selectAllFiles)}
            fontWeight="bold"          
          >
            {t("selectAllFiles")}
          </Checkbox>
        </Flex>
      )}
      {files?.length > 0 ? (
        files.map((file: FileEntity) => (
          <>
          <Flex
            key={file.id}
            p={2}
            position="relative"
            color={activeFile?.id === file.id ? "white" : "unset"}
            backgroundColor={
              activeFile?.id === file.id ? COLORS.sikuroBlue : "unset"
            }
            _hover={{ background: COLORS.sikuroBlue, color: "white" }}
            onClick={() => {
              setActiveFile(file);
              if(editFileNameAndDate && editFileNameAndDate !== file.id) {
                setEditFileNameAndDate(undefined)
              }
            }}
            cursor={"pointer"}
          >
            {showRepropagate && docDetailPermissions.addFile && showSelectAllFiles && (
              <Checkbox
                borderColor={"gray.500"}
                isChecked={
                  fileIds && fileIds.length > 0
                    ? fileIds?.includes(file?.id)
                    : false
                }
                onChange={() => handleCheckItem(file.id)}
                mr={2}
              />
            )}

            {editFileNameAndDate === file.id && activeFile?.id === file.id && (
              <Flex
                position="absolute"
                top="0px"
                bottom="10px"
                right="3"
                zIndex={1}
              >
                <SaveButton
                  buttonColor="white"
                  buttonWidth="22px"
                  iconSize={"16px"}
                  onClick={() => {
                    editFileDetails();
                    setEditFileNameAndDate(undefined);
                  }}
                />
                <DiscardButton
                  buttonColor="white"
                  buttonWidth="22px"
                  iconSize={"16px"}
                  onClick={() => {
                    setEditFileNameAndDate(undefined);
                  }}
                />
              </Flex>
            )}
            <Flex
              flexDirection={"column"}
              gap={1}
              width={"100%"}
              pt={editFileNameAndDate === file.id ? 5 : 0}
              position='relative'
            >
              <Text mt={editFileNameAndDate === file.id ? 2 : 0}>
                <>
                  {editFileNameAndDate !== file.id ? (
                    `${file.name}.${file.ext}`
                  ) : (
                    <Input
                      type="text"
                      size={"md"}
                      color={"black"}
                      backgroundColor="white"
                      width={"100%"}
                      onChange={(e) => didRenameDocument(e.target.value)}
                      value={updatedFile?.name}
                    />
                  )}
                </>
              </Text>

              <Text>
                {t("expireDate")}:{" "}
                {editFileNameAndDate !== file.id ? (
                  file.expiresAt ? (
                    formatDateBasedOnLanguage(file.expiresAt)
                  ) : (
                    "-"
                  )
                ) : (
                  <CustomInputDate
                    value={updatedFile?.date}
                    onChange={(val) =>
                      setUpdatedFile((prev) => ({
                        ...prev,
                        date: val,
                      }))
                    }
                    minDate={startOfDay(new Date())}
                    maxDate={new Date("9999-12-31")}
                    hasError={setShowErrorDate}
                    setValue={setInvalidDate}
                  />
                )}
              </Text>
            </Flex>
            {editFileNameAndDate !== file.id && !siteGeneralDocument && !showSelectAllFiles &&  (
              <Flex alignItems={"center"}>
                <RenderIf permissions={docDetailPermissions.edit}>
                <MdModeEdit
                  className="hover-icon"
                  onClick={() => setEditFileNameAndDate(file.id)}
                  fill={activeFile?.id === file.id ? "white" : "unset"}
                  fontSize="1.2rem"
                  style={{ fill: activeFile?.id === file.id ? "white" : "currentColor" }}
                />
                </RenderIf> 
                <RenderIf permissions={docDetailPermissions.downloadAll}>
                  <DownloadFile
                    renameModels={renameModels}
                    type={type}
                    document={document}
                    downloader={downloader}
                    fileId={file.id}
                  />
                </RenderIf>
                <RenderIf permissions={docDetailPermissions.delete}>
                  {canAddFile && (
                    <DeleteIcon
                      fontSize="1.2rem"
                      fill={activeFile?.id === file.id ? "white" : "unset"}
                      onClick={() => deleteFileFromDoc(file)}
                    />
                  )}
                </RenderIf>
              </Flex>
            )}
          
          </Flex>
          {files[files?.length -1].id !== file.id && <Box width={'100%'} height={0.1} backgroundColor='lightgrey'></Box>}
          </>
        ))
      ) : (
        <Flex justifyContent={"center"} alignItems={"center"} h={"full"}>
          <Text>{t("noAvailableFiles")}</Text>
        </Flex>
      )}
      <Box>
        {showErrorDate && (
          <Text
            color={"#E53E3E"}
            fontSize="0.875rem"
            py="2"
            lineHeight={"normal"}
          >
            {t("invalidDateError", {
              ns: "common",
              date: invalidDate,
            })}
          </Text>
        )}
      </Box>
      {invalidFilename && (
        <Alert
          onClose={() => {
            setInvalidFilename(false);
            setAction(null);
          }}
          title={t("warning", { ns: "common" })}
          message={t("invalidFileName")}
          variant="question"
        />
      )}
    </>
  );
};
