import {
  Box,
  Code,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../assets/theme/colors";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import Select from "react-select";
import { groupOptions } from "./EventSelectField";

interface WebHookInfoButtonProps {
  events: { value: string; label: string }[];
}

const WebHookInfoButton = ({ events }: WebHookInfoButtonProps) => {
  const { t } = useTranslation("webhooks");
  const [event, setEvent] = useState<string>();
  const response = getBody(event);
  const tabIndex = events.findIndex((e) => e.value === event) + 1;
  const eventKey = event?.replace(/:/g, "_");
  return (
    <Popover strategy="fixed" placement="left-start">
      <PopoverTrigger>
        <ActionBarItem
          icon={FaInfoCircle}
          description={t("info.title")}
          bgColor={COLORS.sikuroBlue}
          color={COLORS.white}
        />
      </PopoverTrigger>
      <PopoverContent sx={{ minWidth: "600px" }}>
        <PopoverArrow />
        <PopoverHeader
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text
            sx={{ fontSize: "16px", fontWeight: 600, color: COLORS.sikuroBlue }}
          >
            {tabIndex ? t(`events.${eventKey}.title`) : t(`info.title`)}
          </Text>
          <EventSelect events={events} event={event} setEvent={setEvent} />
        </PopoverHeader>
        <PopoverBody sx={{ padding: "16px", fontSize: "12px" }}>
          <Tabs index={tabIndex}>
            <TabPanels>
              <TabInfo />
              {events.map((ev) => (
                <TabPanel key={ev.value}>
                  <Text mt={1}>{t(`events.${eventKey}.description`)}</Text>
                  {response ? (
                    <>
                      <Text mt={1}>{t(`response`)}</Text>
                      <Code sx={{ mt: 3, p: 3, w: "100%", fontSize: "10px" }}>
                        <pre>{JSON.stringify(response, null, 2)}</pre>
                      </Code>
                    </>
                  ) : (
                    ev.value
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const TabInfo = () => {
  const { t } = useTranslation("webhooks");
  return (
    <TabPanel>
      <Box mb={2}>
        <Text fontWeight="bold">{t("info.description_title")}</Text>
        <Text>{t("info.description")}</Text>
      </Box>
      <Box mb={2}>
        <Text fontWeight="bold">{t("info.how_are_called_title")}</Text>
        <Text>{t("info.how_are_called")}</Text>
      </Box>
      <Box>
        <Text fontWeight="bold">{t("info.api_key_feature_title")}</Text>
        <Text>{t("info.api_key_feature_intro")}</Text>
        <List styleType="disc" pl={4}>
          <ListItem>{t("info.api_key_feature_point1")}</ListItem>
          <ListItem>{t("info.api_key_feature_point2")}</ListItem>
        </List>
      </Box>
    </TabPanel>
  );
};

interface EventSelectProps {
  event: string;
  setEvent: (event: string) => void;
  events: { value: string; label: string }[];
}

const EventSelect = ({ event, setEvent, events }: EventSelectProps) => {
  const { t } = useTranslation("webhooks");
  return (
    <Select
      value={events.find((e) => e.value === event)}
      onChange={(e) => setEvent(e?.value)}
      options={groupOptions(events, t)}
      placeholder={t("info.placeholder")}
      isClearable
      styles={{
        control: (styles) => ({
          ...styles,
          fontSize: "14px",
          borderRadius: 2,
          width: "250px",
        }),
        noOptionsMessage: (styles) => ({
          ...styles,
          fontSize: "14px",
        }),
        option: (styles) => ({
          ...styles,
          fontSize: "14px",
          ":hover": {
            backgroundColor: "rgba(0,95,250,0.25)",
          },
        }),
      }}
    />
  );
};

const documentType = {
  id: "string",
  name: "string",
  duration: "number",
  description: "string",
  isSystem: "boolean",
};
const entities = {
  badge: {
    id: "string",
    code: "string",
    serial: "string",
    status: "string",
    tags: "string",
    type: "string",
  },
  company: {
    id: "string",
    name: "string",
    state: "string",
    type: "string",
    fiscalCode: "string",
    phoneNumber: "string",
    vatCountryCode: "string",
    vat: "string",
    pec: "string",
    email: "string",
    businessSize: "string",
    ccnl: "string",
    street: "string",
    city: "string",
    cap: "string",
  },
  worker: {
    id: "string",
    firstName: "string",
    lastName: "string",
    fiscalCode: "string",
  },
  tool: {
    id: "string",
    name: "string",
    serial: "string",
  },
  vehicle: {
    id: "string",
    name: "string",
    plate: "string",
  },
  machine: {
    id: "string",
    name: "string",
    plate: "string",
  },
  chemical: {
    id: "string",
    name: "string",
  },
  resource: {
    id: "string",
    name: "string",
    type: "string",
  },
  evaluation: {
    result: "string",
    createdAt: "string",
    expiresAt: "string",
    site: {
      id: "string",
      name: "string",
    },
    evaluator: "string",
    mode: "string",
  },
  documentType,
  requirement: {
    id: "string",
    subject: "string",
    documentType: documentType,
    isOptional: "boolean",
    graceDays: "number",
    variants: "string",
    specializations: "string",
    isManual: "boolean",
    reportRemarks: "string",
    parentId: "string",
    template: "string",
  },
  file: {
    id: "string",
    name: "string",
    uri: "string",
    uploadedAt: "string",
    expiresAt: "string",
  },
};

const badgeWithResource = {
  ...entities.badge,
  resource: entities.resource,
};
const siteResource = (resource) => ({
  [resource]: entities[resource],
  evaluation: entities.evaluation,
  company: entities.company,
});

const getBody = (event: string) =>
  payloadMap[event]
    ? {
        event: event,
        ...(event.startsWith("site:")
          ? {
              site_id: "string",
            }
          : {}),
        company_id: "string",
        date: "string",
        payload: payloadMap[event],
      }
    : null;

const payloadMap = {
  "company:badge:deleted": {
    badge_id: "string",
  },
  "company:badge:linked_to_resource": {
    badge: badgeWithResource,
  },
  "company:badge:status_updated": {
    badge: entities.badge,
  },
  "company:badges:unlinked_from_resources": {
    badge_ids: ["string"],
  },
  "site:company:evaluation_updated": {
    company: entities.company,
    evaluation: entities.evaluation,
    motivation: "string",
  },
  "site:worker:evaluation_updated": {
    worker: entities.worker,
    evaluation: entities.evaluation,
    motivation: "string",
  },
  "site:machine:evaluation_updated": {
    machine: entities.machine,
    evaluation: entities.evaluation,
    motivation: "string",
  },
  "site:tool:evaluation_updated": {
    tool: entities.tool,
    evaluation: entities.evaluation,
    motivation: "string",
  },
  "site:vehicle:evaluation_updated": {
    vehicle: entities.vehicle,
    evaluation: entities.evaluation,
    motivation: "string",
  },
  "site:chemical:evaluation_updated": {
    chemical: entities.chemical,
    evaluation: entities.evaluation,
    motivation: "string",
  },
  "site:badges:created": {
    badges: [entities.badge],
  },
  "site:badges:deleted": {
    badge_ids: ["string"],
  },
  "site:badges:linked_to_resources": {
    badges: [entities.badge],
  },
  "site:badge:status_updated": {
    badge: entities.badge,
  },
  "site:workers:created": {
    site_workers: [siteResource("worker")],
  },
  "site:machines:created": {
    site_machines: [siteResource("machine")],
  },
  "site:tools:created": {
    site_tools: [siteResource("tool")],
  },
  "site:vehicles:created": {
    site_vehicles: [siteResource("vehicle")],
  },
  "site:chemicals:created": {
    site_chemicals: [siteResource("chemical")],
  },
  "site:worker:deleted": {
    worker_id: "string",
  },
  "site:machine:deleted": {
    machine_id: "string",
  },
  "site:tool:deleted": {
    tool_id: "string",
  },
  "site:vehicle:deleted": {
    vehicle_id: "string",
  },
  "site:chemical:deleted": {
    chemical_id: "string",
  },
  "site:worker:restored": {
    worker_id: "string",
  },
  "site:machine:restored": {
    machine_id: "string",
  },
  "site:tool:restored": {
    tool_id: "string",
  },
  "site:vehicle:restored": {
    vehicle_id: "string",
  },
  "site:chemical:restored": {
    chemical_id: "string",
  },
  "site:company:requirement_created": {
    resource: entities.company,
    requirement: entities.requirement,
  },
  "site:company:requirement_deleted": {
    resource: entities.company,
    requirementId: "string",
  },
  "site:company:requirement_evaluated": {
    resource: entities.company,
    requirement: entities.requirement,
  },
  "site:company:requirement_file_added": {
    resource: entities.company,
    requirementId: "string",
    file: entities.file,
  },
  "site:company:requirement_file_deleted": {
    resource: entities.company,
    requirementId: "string",
    file: entities.file,
  },
  "site:company:requirement_file_updated": {
    requirement: entities.requirement,
    file: entities.file,
  },
  "site:worker:requirement_created": {
    resource: entities.worker,
    requirement: entities.requirement,
  },
  "site:worker:requirement_deleted": {
    resource: entities.worker,
    requirementId: "string",
  },
  "site:worker:requirement_evaluated": {
    resource: entities.worker,
    requirement: entities.requirement,
  },
  "site:worker:requirement_file_added": {
    resource: entities.worker,
    requirementId: "string",
    file: entities.file,
  },
  "site:worker:requirement_file_deleted": {
    resource: entities.worker,
    requirementId: "string",
    file: entities.file,
  },
  "site:worker:requirement_file_updated": {
    requirement: entities.requirement,
    file: entities.file,
  },
  "site:machine:requirement_created": {
    resource: entities.machine,
    requirement: entities.requirement,
  },
  "site:machine:requirement_deleted": {
    resource: entities.machine,
    requirementId: "string",
  },
  "site:machine:requirement_evaluated": {
    resource: entities.machine,
    requirement: entities.requirement,
  },
  "site:machine:requirement_file_added": {
    resource: entities.machine,
    requirementId: "string",
    file: entities.file,
  },
  "site:machine:requirement_file_deleted": {
    resource: entities.machine,
    requirementId: "string",
    file: entities.file,
  },
  "site:machine:requirement_file_updated": {
    requirement: entities.requirement,
    file: entities.file,
  },
  "site:vehicle:requirement_created": {
    resource: entities.vehicle,
    requirement: entities.requirement,
  },
  "site:vehicle:requirement_deleted": {
    resource: entities.vehicle,
    requirementId: "string",
  },
  "site:vehicle:requirement_evaluated": {
    resource: entities.vehicle,
    requirement: entities.requirement,
  },
  "site:vehicle:requirement_file_added": {
    resource: entities.vehicle,
    requirementId: "string",
    file: entities.file,
  },
  "site:vehicle:requirement_file_deleted": {
    resource: entities.vehicle,
    requirementId: "string",
    file: entities.file,
  },
  "site:vehicle:requirement_file_updated": {
    requirement: entities.requirement,
    file: entities.file,
  },
  "site:tool:requirement_created": {
    resource: entities.tool,
    requirement: entities.requirement,
  },
  "site:tool:requirement_deleted": {
    resource: entities.tool,
    requirementId: "string",
  },
  "site:tool:requirement_evaluated": {
    resource: entities.tool,
    requirement: entities.requirement,
  },
  "site:tool:requirement_file_added": {
    resource: entities.tool,
    requirementId: "string",
    file: entities.file,
  },
  "site:tool:requirement_file_deleted": {
    resource: entities.tool,
    requirementId: "string",
    file: entities.file,
  },
  "site:tool:requirement_file_updated": {
    requirement: entities.requirement,
    file: entities.file,
  },
  "site:chemical:requirement_created": {
    resource: entities.chemical,
    requirement: entities.requirement,
  },
  "site:chemical:requirement_deleted": {
    resource: entities.chemical,
    requirementId: "string",
  },
  "site:chemical:requirement_evaluated": {
    resource: entities.chemical,
    requirement: entities.requirement,
  },
  "site:chemical:requirement_file_added": {
    resource: entities.chemical,
    requirementId: "string",
    file: entities.file,
  },
  "site:chemical:requirement_file_deleted": {
    resource: entities.chemical,
    requirementId: "string",
    file: entities.file,
  },
  "site:chemical:requirement_file_updated": {
    requirement: entities.requirement,
    file: entities.file,
  },
};

export default WebHookInfoButton;
