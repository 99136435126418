import Site, { SiteState } from '../../entities/site';
import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';

interface Args {
	companyId: string;
	siteId: string;
	state: SiteState;
}

class UpdateSiteState implements UseCase<Args, void> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, siteId, state }: Args): Promise<void> {
		await this.siteRepository.updateState(companyId, siteId, state);
	}
}

export default UpdateSiteState;
