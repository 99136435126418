export const formatISODate = (date?: Date): string | undefined => {
	if (!date || isNaN(date?.getTime())) {
		return undefined;
	}

	return date.toISOString().replace(/\.[0-9]{3}/, '');
};

export const dateIntervals = (interval?: [Date, Date]): object => {
	if (!interval) {
		return {};
	}

	const out = {};
	if (interval[0]) {
		out['fromDate'] = formatISODate(interval[0]);
	}

	if (interval[1]) {
		out['toDate'] = formatISODate(interval[1]);
	}

	return out;
};
