import UseCase from '../../../core/app/usecase';
import { AuthRepository, makeAuthRepository } from '../../repositories/authRepository';

export class RequestPasswordReset implements UseCase<string, boolean> {
	constructor(private repository: AuthRepository) {}

	async Call(email: string): Promise<boolean> {
		return await this.repository.requestPasswordReset(email);
	}
}

export const makeRequestPasswordReset = (): RequestPasswordReset => {
	return new RequestPasswordReset(makeAuthRepository());
};
