import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';
import { Evaluable } from '../../entities/evaluable.enum';
import PendingEvaluation from '../../entities/pending-evaluation';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { PendingEvaluationsFilters } from '../../../presentation/hooks/Site/useSiteApproveEvaluationsViewModel';

interface Args {
	companyId: string;
	siteId: string;
	type: Evaluable;
	filters: PendingEvaluationsFilters;
	sort: SortMeta;
	pageParam: number;
}

class GetPendingEvaluations implements UseCase<Args, {results: PendingEvaluation[], count: number}> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, siteId, type, filters, sort, pageParam }: Args): Promise<{results: PendingEvaluation[], count: number}> {
		return await this.siteRepository.getPendingEvaluations(companyId, siteId, type, filters, sort, pageParam);
	}
}

export default GetPendingEvaluations;
