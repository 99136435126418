import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { SiteWorkerParams } from "../../../domain/entities/siteWorker";
import SupplierResourcesListView from "../../components/Views/Suppliers/SupplierResourcesListView";
import useSupplierResourcesViewModel from "../../hooks/Suppliers/useSupplierResourcesViewModel";
import SupplierWorkersViewModel from "../../viewmodels/suppliers/SupplierWorkersViewModel";

const SupplierWorkersView = () => {
  const resourceColumns = ["lastName", "firstName", "fiscalCode", "jobTitle"];
  return (
    <SupplierResourcesListView<SiteWorkerParams>
      type={DocumentTypeCategory.WORKER}
      useViewModel={useSupplierResourcesViewModel(
        new SupplierWorkersViewModel(),
        DocumentTypeCategory.WORKER,
      )}
      resourceColumns={resourceColumns}
      namespace={"workers"}
    />
  );
};

export default SupplierWorkersView;
