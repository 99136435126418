import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

const ToolIcon = (props: IconProps) => {
  return (
    <Icon
      viewBox="0 0 26 26"
      {...props}
      fill="none"
      stroke={props.fill ?? "black"}
    >
      <path
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.0471 11.8904L2.45504 20.4824C2.22905 20.7082 2.04978 20.9762 1.92746 21.2713C1.80515 21.5664 1.74219 21.8827 1.74219 22.2021C1.74219 22.5215 1.80515 22.8378 1.92746 23.1328C2.04978 23.4279 2.22905 23.6959 2.45504 23.9217C2.91083 24.3773 3.52894 24.6333 4.17344 24.6333C4.81794 24.6333 5.43605 24.3773 5.89184 23.9217L14.4839 15.3272M19.7278 15.7708L24.4406 20.4837C24.8963 20.9395 25.1523 21.5576 25.1523 22.2021C25.1523 22.8466 24.8963 23.4647 24.4406 23.9205C23.9848 24.3761 23.3667 24.6321 22.7222 24.6321C22.0777 24.6321 21.4596 24.3761 21.0038 23.9205L13.4582 16.376M7.01597 6.17498L4.43594 7.03297L1.85955 2.73696L3.57795 1.01855L7.87518 3.59616L7.01597 6.17498ZM7.01597 6.17498L10.4552 9.61422"
      />
      <path
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.0476 11.8908C10.0219 9.27427 10.2225 5.84111 12.337 3.72652C14.4516 1.61194 18.3527 1.14892 20.5001 2.43833L16.8056 6.13277L16.4617 9.9135L20.2424 9.57079L23.9369 5.87513C25.2275 8.02375 24.7633 11.9248 22.6487 14.0382C20.5341 16.1527 17.1021 16.3533 14.4857 15.3276"
      />
    </Icon>
  );
};

export default ToolIcon;
