import Form from "./forms/Form";
import ImageBox from "./ImageBox";
import {Box, Flex, Text, useMediaQuery} from "@chakra-ui/react";
import {FC, ReactElement, ReactNode, useEffect, useState} from "react";
import {EditableFormFieldProps} from "./forms/FormField";
import {useAuth} from "../../providers/Auth0JWTProvider";
import {CustomField, FieldType} from "../../../domain/entities/customFields";
import FormTextField from "./forms/FormTextField";
import FormDateField from "./forms/FormDateField";

interface DataBoxProps {
  title: string;
  isEditing?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
  fields: Array<ReactElement<Omit<EditableFormFieldProps, "isEditing">>>;
  customFields?: CustomField[];
  image?: {
    url?: string;
    onUpdate?: (file: File) => void;
  };
}

const DataBox: FC<DataBoxProps> = ({
  title,
  image,
  isLoading,
  isEditing,
  fields,
  customFields,
  children,
}) => {
  const { token } = useAuth();
  const [imageUrl, setImageUrl] = useState<string>();

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    const fetchImage = async (imageUrl: string) => {
      const response = await fetch(imageUrl, {
        headers: { Authorization: `Bearer ${token.access_token}` },
      });

      const blob = await response.blob();
      setImageUrl(URL.createObjectURL(blob));
    };

    if (image.url && token.access_token) {
      fetchImage(image.url);
    }
  }, [image.url, token.access_token]);

  return (
    <Flex width={"100%"} direction="column">
      <Text textStyle="h2" px={8} marginTop={5} marginBottom={3}>
        {title}
      </Text>
      <Flex direction="row" align="start">
        {image && (
          <Box width={"220px"} mx={8} pt={1}>
            <ImageBox
              disableFlag
              onChange={(imageParam) => {
                setImageUrl(undefined);
                image.onUpdate(imageParam);
              }}
              isLoaded={!isLoading}
              isEditing={isEditing}
              imageURL={imageUrl}
            />
          </Box>
        )}

        <Box
          pb={isEditing ? 6 : 8}
          flexGrow={isMobile ? undefined : 1} // Set flexGrow to 1 if testValue is true, otherwise, leave it undefined
          width={isMobile ? "800" : undefined}
          pr={8}
          pl={image ? -4 : 4}
          pt={isEditing ? 4 : 2}
          position="relative"
        >
          <Form withCollapse fields={[...fields, ...getCustomFields(customFields ?? [])]} isEditing={isEditing} />
        </Box>
      </Flex>
      {children}
    </Flex>
  );
};

function getCustomFields(customFields: CustomField[]) {
  return customFields.map(({key, label, type}) => {
    const props = {name: `custom_${key}`, label, key}
    if (type === FieldType.NUMBER)
      return (
        <FormTextField key={key} type="number" {...props} />
      )
    if (type === FieldType.DATE)
      return (
        <FormDateField key={key}  {...props} />
      )
    return (
      <FormTextField key={key} {...props} />
    )
  });
}

export default DataBox;
