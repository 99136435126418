import React, { useState } from "react";
import { Tag as ChakraTag } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import {
  components,
  MultiValueGenericProps,
  MultiValueRemoveProps,
} from "react-select";
import Tag from "../../../../domain/entities/tag";
import { darken } from "polished";
import BaseTagSelect, { BaseTagSelectProps } from "./BaseTagSelect";

type MultiTagSelectProps = Omit<
  BaseTagSelectProps<true>,
  "value" | "onChange"
> & {
  selectedTags: Tag[];
  setSelectedTags: (tags: Tag[]) => void;
  createTag?: (tagName: string) => void;
};

const defaultColor = "#D4D4D4";
const checkColor = (color: any, defaultHex = "#D4D4D4"): string => {
  return !color || color == "" ? defaultHex : color;
};

const MultiTagSelect = ({
  selectedTags,
  setSelectedTags,
  createTag,
  styles,
  ...props
}: MultiTagSelectProps) => {
  const [key, setKey] = useState(0);
  return (
    <BaseTagSelect
      {...props}
      isMulti
      value={selectedTags}
      onChange={(values) => {
        setSelectedTags([...values]);
        setKey(key + 1);
      }}
      key={key}
      createTag={createTag}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      styles={{
        multiValue: (base) => ({
          ...base,
          borderRadius: "6px",
          "& > span": {
            borderTopLeftRadius: "6px",
            borderBottomLeftRadius: "6px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
        }),
        ...styles,
      }}
      components={{
        MultiValueLabel,
        MultiValueRemove,
      }}
    />
  );
};

const MultiValueLabel = (props: MultiValueGenericProps<Tag, true>) => {
  const color = checkColor(props.data.color, defaultColor);

  return (
    <ChakraTag
      size="md"
      color={darken(0.6, color)}
      bg={color}
      sx={{ fontSize: "12px", paddingRight: 0 }}
    >
      {props.children}
    </ChakraTag>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps<Tag, true>) => {
  const color = checkColor(props.data.color, defaultColor);

  return (
    <components.MultiValueRemove
      {...props}
      innerProps={
        {
          ...props.innerProps,
          css: {
            ...props.innerProps["css"],
            paddingRight: "6px",
            backgroundColor: color,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
            "& > svg": {
              opacity: 0.5,
              ":hover": {
                opacity: 1,
              },
            },
            ":hover": undefined,
          },
          onClick: props.selectProps.isLoading
            ? undefined
            : props.innerProps.onClick,
        } as any
      }
    >
      <CloseIcon fill={darken(0.6, color)} width="10px" />
    </components.MultiValueRemove>
  );
};

export default MultiTagSelect;
