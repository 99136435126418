import { COLORS } from "../../assets/theme/colors";
import { BsPlus } from "react-icons/bs";
import { Button, Tooltip } from "@chakra-ui/react";
import React from "react";

const PlusButton = ({
  onClick,
  tooltipMessage,
}: {
  onClick: () => void;
  tooltipMessage?: string;
}) => (
  <Tooltip label={tooltipMessage} aria-label={tooltipMessage}>
    <span>
      <Button
        bgColor={COLORS.yellow}
        fontSize="2xl"
        width="30px"
        borderRadius="10px"
        p={1}
        onClick={onClick}
        aria-label="document-history"
        border="3px solid transparent"
          _hover={{
            border: `3px solid ${COLORS.sikuroBlue}`,
          }}
      >
        <BsPlus />
      </Button>
    </span>
  </Tooltip>
);

export default PlusButton;
