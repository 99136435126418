import { isValid } from 'date-fns';
import { injectable } from 'tsyringe';
import AiTask from '../../domain/entities/aiTask';
import AiTaskDocument from '../../domain/entities/aiTaskDocument';
import { AiTaskSectionCode } from '../../domain/entities/aiTaskSectionCode';
import Document, { SiteResourceDocument } from '../../domain/entities/document';
import DocumentComment from '../../domain/entities/documentComment';
import { DocumentActionLogEntry, DocumentFileLogEntry } from '../../domain/entities/documentLogEntry';
import DocumentNote from '../../domain/entities/documentNote';
import { DocumentTypeCategory } from '../../domain/entities/documentTypeCategory.enum';
import DocumentTypeWithPublic from '../../domain/entities/documentTypeWithPublic';
import Evaluation from '../../domain/entities/evaluation';
import FileEntity from '../../domain/entities/file';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import { ResourceDocumentEvaluationState } from '../../domain/entities/resourceDocumentEvaluationState.enum';
import Site, { DocumentSites } from '../../domain/entities/site';
import { SiteRequirementsResource } from '../../domain/entities/siteRequirementsResource';
import TimeTableDocuments from '../../domain/entities/timeTableDocuments';
import { UserMini } from '../../domain/entities/user';
import DocumentRepository, {
  GetDocumentEvaluationFilter,
  GetDocumentFilesFilter,
  GetDocumentLogFilter,
  GetDocumentsFilter,
  GetSiteRequirementsResourceFilter,
} from '../../domain/repositories/documentRepository';
import { GetSitesFilter } from '../../domain/repositories/siteRepository';
import { dateToRFC3339, removeEmptyAttributes, removeEmptyProperties } from "../../utils";
import { GetEvaluationsResponse, mapEvaluation } from '../responses/evaluations';
import { ApiService } from '../utilities/apiService';
import { dateIntervals, formatISODate } from '../utilities/filters';

@injectable()
class ServerDocumentRepository implements DocumentRepository {
	constructor(private apiService: ApiService) {}

	async evaluateSiteSupplierDocument(
		companyId: string,
		siteId: string,
		documentId: string,
		result: ResourceDocumentEvaluationState,
		expiresAt?: Date,
		noEvaluationExpiration?: boolean,
		target?: string,
		resourceId?: string,
	): Promise<void> {
		const dateParsed = expiresAt && isValid(expiresAt) ? dateToRFC3339(expiresAt, true) : undefined;
		const body = JSON.stringify(
			dateParsed
				? {
						result,
						expiresAt: dateParsed,
						noEvaluationExpiration,
					}
				: {
						result,
						noEvaluationExpiration,
					},
		);
		const evaluateUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${resourceId}/requirements/${documentId}/evaluations`;
		await this.apiService.fetchWithToken(evaluateUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body,
		});
	}

	async getGeneralDocuments(companyId: string): Promise<Document[]> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents`);
		return response.json();
	}

	async getDocumentById(companyId: string, documentId: string): Promise<Document> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${documentId}`,
		);
		return response.json();
	}

	async createDocument(companyId: string, documents: string[]): Promise<void> {
		// FIXME: what is the correct approach? 1) send the document - or - 2) send just documentTypeId?

		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ documentTypesIds: documents }),
		});

		return response.json();
	}

	async createPropagableDocuments(companyId: string, documents: DocumentTypeWithPublic[]): Promise<void> {
		const transformedDocuments = documents.map(({ id, isPublic }) => ({
			documentTypeId: id,
			isPublic,
		}));

		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ documents: transformedDocuments }),
		});

		return response.json();
	}

	async updateDocument(
		companyId: string,
		document: Document,
		resourceId?: string,
		target?: string,
		siteId?: string,
		siteIds?: string[],
		selectAll?: boolean,
	): Promise<void> {
		let updateDocumentUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${document.id}`;
		if (siteId) {
			updateDocumentUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/documents/${document.id}`;
		}
		await this.apiService.fetchWithToken(updateDocumentUrl, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				expiresAt: document?.expiresAt ? dateToRFC3339(document?.expiresAt, true) : '',
				isPublic: document?.isPublic,
				sitesIds: siteIds,
				selectAll: selectAll,
				noExpiration: document?.noExpiration,
			}),
		});
	}

	async deleteDocument(companyId: string, documentId: string, siteId?: string): Promise<Document[]> {
		const deleteUrl = siteId
			? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/documents/${documentId}`
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${documentId}`;
		await this.apiService.fetchWithToken(deleteUrl, {
			method: 'DELETE',
		});

		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents`);
		return response.json();
	}

	async deleteResourceDocument(
		companyId: string,
		documentId: string,
		siteId?: string,
		resourceId?: string,
		target?: DocumentTypeCategory,
	): Promise<Document[]> {
		const deleteUrl = siteId
			? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/${target}s/${resourceId}/documents/${documentId}`
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${target}s/${resourceId}/documents/${documentId}`;
		await this.apiService.fetchWithToken(deleteUrl, {
			method: 'DELETE',
		});

		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents`);
		return response.json();
	}

	async getDocumentFiles(
		companyId: string,
		documentId: string,
		filter?: GetDocumentFilesFilter,
		sort?: SortMeta,
		siteId?: string,
		target?: string,
		resourceId?: string,
	): Promise<FileEntity[]> {
		const { uploadedAt, ...restFilter } = filter ?? {};
		const params = new URLSearchParams({
			...restFilter,
			...dateIntervals(uploadedAt),
			...sort,
		});
		let getFilesUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${documentId}/files?${params.toString()}`;

		const checkTargetNoSupplier =
      target === DocumentTypeCategory.COMPANY ||
        target === DocumentTypeCategory.SITE
				? `${
						process.env.REACT_APP_SERVER_API_ENDPOINT
					}/companies/${companyId}/sites/${siteId}/requirements/${documentId}/files?${params.toString()}`
				: `${
						process.env.REACT_APP_SERVER_API_ENDPOINT
					}/companies/${companyId}/sites/${siteId}/${target}s/${resourceId}/requirements/${documentId}/files?${params.toString()}`;

		const checkTargetWithSupplier =
      target === DocumentTypeCategory.SUPPLIER_COMPANY ||
        target === DocumentTypeCategory.SUPPLIER_SITE
				? `${
						process.env.REACT_APP_SERVER_API_ENDPOINT
					}/companies/${companyId}/sites/${siteId}/suppliers/${resourceId}/requirements/${documentId}/files?${params.toString()}`
				: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${resourceId}/${target
						?.split(' ')
						.pop()}s/requirements/${documentId}/files?${params.toString()}`;

		const checkSupplier = target?.includes('supplier') ? checkTargetWithSupplier : checkTargetNoSupplier;

		const targetExistsCheck =
			target != undefined
				? checkSupplier
				: `${
						process.env.REACT_APP_SERVER_API_ENDPOINT
					}/companies/${companyId}/sites/${siteId}/documents/${documentId}/files?${params.toString()}`;

		getFilesUrl = siteId
			? targetExistsCheck
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${documentId}/files?${params.toString()}`;
		const response = await this.apiService.fetchWithToken(getFilesUrl);

		const { results } = await response.json();
		return (
			results?.map((res: FileEntity) => ({
				...res,
				expiresAt: res.expiresAt ? new Date(res.expiresAt) : null,
			})) ?? []
		);
	}

	async addFileToDocument(
		companyId: string,
		documentId: string,
		file: FileEntity,
		siteId?: string,
		target?: string,
		resourceId?: string,
		siteIds?: string[],
		toSupplier?: boolean,
		selectAll?: boolean,
		filters?: GetSitesFilter,
	): Promise<FileEntity[]> {
		if (!file.binaries || file.binaries?.length <= 0) {
			console.log('Missing file binaries');
			return Promise.reject(new Error('no file binary found, cannot add file to document'));
		}

		const formData = new FormData();
		const files = Array.from(file.binaries);

		files?.forEach((f) => {
			formData.append('files[]', f);
			if (siteIds?.length > 0) {
				siteIds.forEach((s) => {
					formData.append('siteIds[]', s);
				});
			}
		});

		formData.append('selectAll', selectAll ? 'true' : 'false');
		for (const key in filters) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				formData.append(`${key}`, filters[key]);
			}
		}
		const checkTargetNoSupplier =
			target === DocumentTypeCategory.COMPANY ||
        target === DocumentTypeCategory.SITE
				? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirements/${documentId}/files`
				: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/${target}s/${resourceId}/requirements/${documentId}/files`;
		const checkTargetWithSupplier = () => {
			switch (target) {
				case DocumentTypeCategory.SUPPLIER_COMPANY:
				case DocumentTypeCategory.SUPPLIER_SITE:
					return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${resourceId}/requirements/${documentId}/files`;
				case 'company-supplier':
					return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${siteId}/documents/${documentId}/files`;
				default:
					return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${siteId}/${target}s/${resourceId}/documents/${documentId}/files`;
			}
		};

		const checkSupplier = target?.includes('supplier') || toSupplier ? checkTargetWithSupplier() : checkTargetNoSupplier;

		const targetExistsCheck =
			target != undefined
				? checkSupplier
				: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/documents/${documentId}/files`;

		const addFileUrl = siteId
			? targetExistsCheck
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${documentId}/files`;

		try {
			const response = await this.apiService.fetchWithToken(addFileUrl, {
				method: 'POST',
				body: formData,
			});

			if (!response.ok) {
				if (response.status === 413) {
					return Promise.reject(new Error('contentToLarge'));
				} else if (response.status === 422) {
					const { details } = await response.json();
					let errorMessage = '';

					for (const key in details) {
						if (Object.hasOwnProperty.call(details, key)) {
							const fileTypeErrors = details[key];
							if (fileTypeErrors.length > 0) {
								errorMessage += 'wrongFormat';
							}
						}
					}

					return Promise.reject(new Error(errorMessage));
				} else {
					return Promise.reject(new Error('generic'));
				}
			}
		} catch (error) {
			return Promise.reject(new Error('generic'));
		}
	}

	async repropagateFileToDocument(
		companyId: string,
		documentId: string,
		siteId?: string,
		target?: string,
		resourceId?: string,
		siteIds?: string[],
		toSupplier?: boolean,
		selectAll?: boolean,
		selectAllFiles?: boolean,
		fileIds?: string[],
		filters?: GetSitesFilter,
	): Promise<void> {


		const body = {
			sitesIds: siteIds,
			selectAll,
			selectAllFiles,
			fileIds,
			...filters
		}
		
		const checkTargetNoSupplier =
			target === DocumentTypeCategory.COMPANY || target === DocumentTypeCategory.SITE
				? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirements/${documentId}/files`
				: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/${target}s/${resourceId}/requirements/${documentId}/files`;
		const checkTargetWithSupplier = () => {
			switch (target) {
				case DocumentTypeCategory.SUPPLIER_COMPANY:
				case DocumentTypeCategory.SUPPLIER_SITE:
					return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${resourceId}/requirements/${documentId}/files`;
				case 'company-supplier':
					return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${siteId}/documents/${documentId}/files`;
				default:
					return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${siteId}/${target}s/${resourceId}/documents/${documentId}/files`;
			}
		};

		const checkSupplier = target?.includes('supplier') || toSupplier ? checkTargetWithSupplier() : checkTargetNoSupplier;

		const targetExistsCheck =
			target != undefined
				? checkSupplier
				: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/documents/${documentId}/files`;

		const addFileUrl = siteId
			? targetExistsCheck
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${documentId}/files`;

	
			await this.apiService.fetchWithToken(addFileUrl, {
				method: 'PUT',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return
	}

	async addFileToResourceDocument(
		companyId: string,
		documentId: string,
		file: FileEntity,
		siteIds?: string[],
		target?: string,
		resourceId?: string,
		selectAll?: boolean,
		filters?: GetSitesFilter,
	): Promise<FileEntity[]> {
		if (!file.binaries || file.binaries?.length <= 0) {
			return Promise.reject(new Error('no file binary found, cannot add file to document'));
		}

		const formData = new FormData();
		const files = Array.from(file.binaries);
		files.forEach((f) => {
			formData.append('files[]', f);
		});

			if (siteIds?.length > 0) {
				siteIds.forEach((s) => {
					formData.append('siteIds[]', s);
				});
			}

		formData.append('selectAll', selectAll ? 'true' : 'false');

		for (const key in filters) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				formData.append(`${key}`, filters[key]);
			}
		}
		const addFileUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${target}s/${resourceId}/documents/${documentId}/files`;
		try {
			const response = await this.apiService.fetchWithToken(addFileUrl, {
				method: 'POST',
				body: formData,
			});
			if (!response.ok && response.status === 413) {
				const error = 'contentToLarge';
				return Promise.reject(new Error(error));
			}
			if (!response.ok && response.status === 422) {
				const { details } = await response.json();
				let errorMessage = '';
				for (const key in details) {
					if (Object.hasOwnProperty.call(details, key)) {
						const fileTypeErrors = details[key];
						if (fileTypeErrors.length > 0) {
							errorMessage += `wrongFormat`;
						}
					}
				}
				return Promise.reject(new Error(errorMessage));
			}
			return await response.json();
		} catch (error) {
			return Promise.reject(new Error(error));
		}
	}

	async repropagateFileResourceDocument(
		companyId: string,
		documentId: string,
		siteIds?: string[],
		target?: string,
		resourceId?: string,
		selectAll?: boolean,
		selectAllFiles?: boolean,
		fileIds?: string[],
		filters?: GetSitesFilter
	): Promise<void> {
		const body = {
			sitesIds: siteIds,
			fileIds,
			selectAll,
			selectAllFiles,
			...filters
		}

		const addFileUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${target}s/${resourceId}/documents/${documentId}/files`;
	
			await this.apiService.fetchWithToken(addFileUrl, {
				method: 'PUT',
				body: JSON.stringify(body),
				headers: {
					'Content-Type': 'application/json',
				},
			});
			
	}

	async deleteFileFromDocument(
		companyId: string,
		documentId: string,
		fileId: string,
		siteId?: string,
		target?: string,
		resourceId?: string,
		siteIds?: string[],
		selectAll?: boolean,
		filters?: GetSitesFilter,
	): Promise<void> {
		const checkTargetNoSupplier =
      target === DocumentTypeCategory.COMPANY ||
        target === DocumentTypeCategory.SITE
        ? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/requirements/${documentId}/files/${fileId}`
				: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/${target}s/${resourceId}/requirements/${documentId}/files/${fileId}`;

		const checkTargetWithSupplier =
      target === DocumentTypeCategory.SUPPLIER_COMPANY ||
        target === DocumentTypeCategory.SUPPLIER_SITE
        ? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${resourceId}/requirements/${documentId}/files/${fileId}`
				: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/suppliers/${resourceId}/${target
						?.split(' ')
						.pop()}s/requirements/${documentId}/files/${fileId}`;

		const checkSupplier = target?.includes('supplier') ? checkTargetWithSupplier : checkTargetNoSupplier;

		const targetExistsCheck =
			target != undefined
				? checkSupplier
				: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/documents/${documentId}/files/${fileId}`;

		const deleteUrl = siteId
			? targetExistsCheck
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${documentId}/files/${fileId}`;

		// Determine resource type based on target
		const resourceTypeMap: { [key: string]: string } = {
			'company-supplier': 'company',
			'supplier company': 'site',
		};
		const resourceType = resourceTypeMap[target] || target;
		const payload = {
			sitesIds: siteIds,
			resourceType: resourceType,
			resourceId,
			selectAll,
		};

		for (const key in filters) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				payload[key] = filters[key];
			}
		}

		await this.apiService.fetchWithToken(deleteUrl, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		});
	}

	async getDocumentLog(
		companyId: string,
		documentId: string,
		filter?: GetDocumentLogFilter,
		sort?: SortMeta,
		resourceId?: string,
		siteId?: string,
		target?: string,
		pageParam?: number,
	): Promise<DocumentFileLogEntry[]> {
		const { actionDate, expiresAt, ...restFilter } = filter ?? {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...dateIntervals(actionDate),
			...dateIntervals(expiresAt),
			...sort,
		});

		const getLogsUrl = `${
			process.env.REACT_APP_SERVER_API_ENDPOINT
		}/companies/${companyId}/documents/${documentId}/files-history?${params.toString()}`;

		const response = await this.apiService.fetchWithToken(getLogsUrl);

		const { results } = await response.json();
		return results ?? [];
	}

	async getDocumentActionsLog(
		companyId: string,
		documentId: string,
		filter?: GetDocumentLogFilter,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<DocumentActionLogEntry[]> {
		const { actionDate, expiresAt, ...restFilter } = filter ?? {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...dateIntervals(actionDate),
			...sort,
		});
		const url =
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}` + `/documents/${documentId}/document-history?${params.toString()}`;

		const response = await this.apiService.fetchWithToken(url);
		const { results } = await response.json();
		return results ?? [];
	}

	///
	/// After the discussion on the 3rd-4th of September we have decided that to get evaluations we should call the "requirements" route
	/// but to do this we should have a requirementId.
	/// The problem is that at the moment the entire flow on FE it's about documents, so every routes/component/viewModel/hook refers to Document.
	/// To reach our objective we now know that the parameter documentId contains a requirementId, and to be more clear
	///
	///                     documentID -> contains an ID that represents -> RequirementId
	///
	/// that's why we call a route that contains "requirements" in the path.
	/// The entire flow should be adjusted changing this naming problem, but we will leave this task to the hero that will accept the challenge.
	///
	async getDocumentEvaluations(
		companyId: string,
		siteId: string,
		resourceType: string,
		resourceId: string,
		documentId: string,
		filter?: GetDocumentEvaluationFilter,
		sort?: SortMeta,
	): Promise<Evaluation<ResourceDocumentEvaluationState>[]> {
		const { createdAt, ...restFilter } = filter ?? {};

		const params = new URLSearchParams({
			...removeEmptyAttributes(restFilter),
			...dateIntervals(createdAt),
			...sort,
    });
    const categoryType = resourceType.includes('supplier') ? 'supplier' : resourceType;
    let requirementUrl = resourceId
			? `${
					process.env.REACT_APP_SERVER_API_ENDPOINT
				}/companies/${companyId}/sites/${siteId}/${categoryType}s/${resourceId}/requirements/${documentId}/evaluations?${params.toString()}`
			: `${
					process.env.REACT_APP_SERVER_API_ENDPOINT
				}/companies/${companyId}/sites/${siteId}/${resourceType}-requirements/${documentId}/evaluations?${params.toString()}`;

		if (['site', 'company'].includes(categoryType)) {
			requirementUrl = `${
				process.env.REACT_APP_SERVER_API_ENDPOINT
			}/companies/${companyId}/sites/${siteId}/suppliers/${resourceId}/requirements/${documentId}/evaluations?${params.toString()}`;
    }

		const response = await this.apiService.fetchWithToken(requirementUrl);
		const { results }: GetEvaluationsResponse<ResourceDocumentEvaluationState> = await response.json();
		return results?.map(mapEvaluation);
	}

	async getSiteSupplierDocuments(
		companyId: string,
		siteId: string,
		supplierId: string,
		target: DocumentTypeCategory | string,
		filter?: GetDocumentsFilter,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<SiteResourceDocument[]> {
		const { tags, expiresAt, ...restFilter } = filter ?? {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...restFilter,
			...dateIntervals(expiresAt),
			...(tags ? { tags: tags.join(',') } : {}),
			...sort,
		});

		let getDocumentUrl;
		if (target === 'company' || target == 'site' || target == 'supplier') {
			if (target != 'supplier') {
				params.append('source', target);
			}
			target = 'supplier';
			getDocumentUrl = `${
				process.env.REACT_APP_SERVER_API_ENDPOINT
			}/companies/${companyId}/sites/${siteId}/suppliers/${supplierId}/requirements?${params.toString()}`;
		} else {
			getDocumentUrl = `${
				process.env.REACT_APP_SERVER_API_ENDPOINT
			}/companies/${companyId}/sites/${siteId}/suppliers/${supplierId}/${target}-requirements?${params.toString()}`;
		}

		const response = await this.apiService.fetchWithToken(getDocumentUrl);

		const { results } = await response.json();
		return results ?? [];
	}

	async evaluateDocument(
		companyId: string,
		siteId: string,
		documentId: string,
		result: ResourceDocumentEvaluationState,
		expiresAt?: Date,
		noEvaluationExpiration?: boolean,
		target?: string,
		resourceId?: string,
		filters?: GetSitesFilter,
	): Promise<void> {
		const dateParsed = !isNaN(expiresAt?.getTime()) ? dateToRFC3339(expiresAt, true) : undefined;
		const body = dateParsed
			? {
					result,
					expiresAt: dateParsed,
					noEvaluationExpiration,
				}
			: {
					result,
					noEvaluationExpiration,
				};
		for (const key in removeEmptyAttributes(filters)) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				body[key] = filters[key];
			}
		}

		const evaluateUrl = resourceId
			? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/${target}s/${resourceId}/requirements/${documentId}/evaluations`
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${documentId}/sites/${siteId}/evaluations`;

		const response = await this.apiService.fetchWithToken(evaluateUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		});
	}


	async evaluateGeneralDocument(
		companyId: string,
		documentId: string,
		result: ResourceDocumentEvaluationState,
		expiresAt?: Date,
		noEvaluationExpiration?: boolean,
		target?: string,
		resourceId?: string,
		siteIds?: string[],
		selectAll?: boolean,
		filters?: GetSitesFilter,
	): Promise<void> {
		const body = expiresAt
			? {
					result,
					expiresAt: dateToRFC3339(expiresAt, true),
					noEvaluationExpiration,
					sitesIds: siteIds,
					selectAll,
				}
			: {
					result,
					expiresAt: '',
					noEvaluationExpiration,
					sitesIds: siteIds,
					selectAll,
				};
		for (const key in removeEmptyAttributes(filters)) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				body[key] = filters[key];
			}
		}

		const evaluateUrl = resourceId
			? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${target}s/${resourceId}/requirements/${documentId}/evaluations`
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${documentId}/evaluations`;

		await this.apiService.fetchWithToken(evaluateUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		});
	}

	async repropagateEvaluationGeneralDocument(
		companyId: string,
		documentId: string,
		target?: string,
		resourceId?: string,
		siteIds?: string[],
		selectAll?: boolean,
	): Promise<void> {
		const body = {
			sitesIds: siteIds,
			selectAll,
		};

		const evaluateUrl = resourceId
			? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${target}s/${resourceId}/requirements/${documentId}/evaluations`
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${documentId}/evaluations`;

		 await this.apiService.fetchWithToken(evaluateUrl, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		});

	}

	async getTimeTableDocuments(companyId: string, startDate: Date, endDate: Date): Promise<TimeTableDocuments> {
		const startDateParsed = formatISODate(startDate);
		const endDateParsed = formatISODate(endDate);

		const getTimeTableDocumentUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/calendar?fromDate=${startDateParsed}&toDate=${endDateParsed}`;

		 const response = await this.apiService.fetchWithToken(getTimeTableDocumentUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return response.json();
	}

	async updateFile(
		companyId: string,
		documentId: string,
		fileId: string,
		updatedFiles: Partial<FileEntity>[],
		siteIds?: string[],
		target?: string,
		resourceId?: string,
		selectAll?: boolean,
		filters?: GetSitesFilter,
	): Promise<void> {
		const baseURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}`;

		// Filter and map updated files
		const updatedFilesData = updatedFiles
			.filter((file) => file.updated)
			.map((file) => ({
				fileId: file.id,
				newName: file.name,
				expiresAt: file.expiresAt ? dateToRFC3339(file.expiresAt) : '',
			}));

		// Determine resource type based on target
		const resourceTypeMap: { [key: string]: string } = {
			'company-supplier': 'company',
			'supplier company': 'site',
		};
		const resourceType = resourceTypeMap[target] || target;
		const payload = {
			sitesIds: siteIds,
			resourceType: resourceType,
			resourceId,
			files: updatedFilesData,
			selectAll,
		};

		for (const key in filters) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				payload[key] = filters[key];
			}
		}

		const response = await this.apiService.fetchWithToken(`${baseURL}/documents/${documentId}/files`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		});
		return response.ok ? Promise.resolve() : Promise.reject(new Error('Failed to update file'));
	}

	async getSiteRequirementsResource(
		companyId: string,
		siteId: string,
		availableResources: string[],
		pageParam: number,
		sort: SortMeta,
		filter: GetSiteRequirementsResourceFilter,
	): Promise<SiteRequirementsResource[]> {
		const { expiresAt, ...restFilter } = filter ?? {};
		const queryParams = {
			page: String(pageParam ?? 1),
			perPage: String(25),
			...dateIntervals(expiresAt),
			...(filter?.name && { documentTypeName: filter.name }),
			...(filter?.resourceName && { resourceName: filter.resourceName }),
			...(filter?.resourceType && { resourceType: filter.resourceType }),
			...(filter?.resourceOwner && { resourceOwner: filter.resourceOwner }),
			...(filter?.state && { state: filter.state }),
			...(filter?.search && { search: filter.search }),
			...(sort?.field && {
				field: sort.field === 'name' ? 'documentTypeName' : sort.field,
			}),
			...(sort?.direction && { direction: sort.direction }),
		};
		const params = new URLSearchParams(queryParams);

		const response = await this.apiService.fetchWithToken(
			`${
				process.env.REACT_APP_SERVER_API_ENDPOINT
			}/companies/${companyId}/sites/${siteId}/requirements-summary?resources=${availableResources}&${params.toString()}`,
		);
		const payload = await response.json();
		return payload.results.map(
			(item): SiteRequirementsResource => ({
				name: item.documentTypeName,
				description: item.documentTypeDescription,
				resourceId: item.resourceId,
				resourceName: item.resourceName,
				resourceType: item.resource,
				state: item.finalEvaluation,
				expiresAt: item.expiresAt,
				resourceOwner: item.resourceOwner,
			}),
		);
	}

	async getResourceDocumentNote(companyId: string, resourceId: string, resourceType: DocumentTypeCategory, siteId?: string): Promise<DocumentNote> {
		const getResourceDocumentNoteUrl = `${getCommunicationBasePath(companyId, resourceType, resourceId, siteId)}communication/note`;

		const response = await this.apiService.fetchWithToken(getResourceDocumentNoteUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		return response.json();
	}

	async createResourceDocumentNote(
		companyId: string,
		resourceId: string,
		resourceType: DocumentTypeCategory,
		note: string,
		siteId?: string,
	): Promise<void> {
		await this.apiService.fetchWithToken(`${getCommunicationBasePath(companyId, resourceType, resourceId, siteId)}communication/note`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ note }),
		});
	}

	async getResourceDocumentComments(
		companyId: string,
		resourceId: string,
		resourceType: DocumentTypeCategory,
		siteId?: string,
	): Promise<DocumentComment[]> {
		const getResourceDocumentNoteUrl = `${getCommunicationBasePath(companyId, resourceType, resourceId, siteId)}communication/comments`;

		const response = await this.apiService.fetchWithToken(getResourceDocumentNoteUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const { results } = await response.json();

		return results ?? [];
	}

	async getDocumentCommentGroup(
		companyId: string,
		groupId: string,
		pageParam?: number,
		sort?: SortMeta,
	): Promise<PaginatedResults<DocumentComment>> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...sort,
		});

		const getDocumentCommentGroup = `${
			process.env.REACT_APP_SERVER_API_ENDPOINT
		}/companies/${companyId}/communication/actions?groupId=${groupId}&${params.toString()}`;

		const response = await this.apiService.fetchWithToken(getDocumentCommentGroup, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const results = await response.json();

		return results ?? [];
	}

	async createResourceDocumentComment(
		companyId: string,
		resourceId: string,
		resourceType: DocumentTypeCategory,
		comment: DocumentComment,
		siteId?: string,
	): Promise<void> {
		const payload = {
			comment: comment.comment,
			taggedUsers: comment.taggedUsers,
			taggedDocuments: comment.taggedDocuments,
			commentUrl: comment.commentUrl,
		};

		await this.apiService.fetchWithToken(`${getCommunicationBasePath(companyId, resourceType, resourceId, siteId)}communication/comments`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		});
	}

	async getTaggableUsers(companyId: string, siteId?: string, resourceId?: string, resourceType?: DocumentTypeCategory): Promise<UserMini[]> {
		const sitePath = siteId ? `sites/${siteId}/` : '';
		const resourceTypeForPath = resourceType === DocumentTypeCategory.COMPANY ? 'companie' : resourceType;
		const resourcePath = resourceId && siteId ? `${resourceTypeForPath}s/${resourceId}/` : '';
		const getTaggableUsersUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${sitePath}${resourcePath}users`;

		const response = await this.apiService.fetchWithToken(getTaggableUsersUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const { results } = await response.json();

		return results
			? results
					.filter((d: any) => d.state === 'enabled')
					.map(
						(d: any): UserMini => ({
							id: d.id,
							name: d.name,
							email: d.email,
						}),
					)
			: [];
	}

	async getTaggableDocuments(
		companyId: string,
		resourceType: DocumentTypeCategory,
		resourceId: string,
		pageParam: number,
		siteId?: string,
	): Promise<
		{
			id: string;
			name: string;
		}[]
	> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(50),
		});

		const getTaggableDocumentsUrl = `${getCommunicationBasePath(
			companyId,
			resourceType,
			resourceId,
			siteId,
		)}communication/documents?${params.toString()}`;

		const response = await this.apiService.fetchWithToken(getTaggableDocumentsUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const results = await response.json();
		return results ?? [];
	}

	async getNotificationsStatus(
		companyId: string,
		resourceType: DocumentTypeCategory,
		resourceId: string,
		siteId?: string,
	): Promise<{
		status: boolean;
	}> {
		const getNotificationsStatus = `${getCommunicationBasePath(companyId, resourceType, resourceId, siteId)}communication/notification`;
		const response = await this.apiService.fetchWithToken(getNotificationsStatus, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const results = await response.json();
		return results ?? [];
	}

	async updateNotificationsStatus(
		companyId: string,
		resourceType: DocumentTypeCategory,
		resourceId: string,
		status: boolean,
		siteId?: string,
	): Promise<void> {
		const updateNotificationsStatus = `${getCommunicationBasePath(companyId, resourceType, resourceId, siteId)}communication/notification`;

		await this.apiService.fetchWithToken(updateNotificationsStatus, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ notification: status }),
		});
	}

	async getDocumentSites(
		companyId: string,
		documentId: string,
		sort: SortMeta,
		filter: GetSitesFilter,
		pageParam: number,
		includeWorkingSites?: boolean,
		supplierId?: string,
	): Promise<DocumentSites> {
		const getDocumentSitesUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${supplierId ?? companyId}/sites?`;
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...sort,
			...(filter && removeEmptyProperties(filter)),
			documentTypeId: documentId,
		});
		const response = await this.apiService.fetchWithToken(
			getDocumentSitesUrl + params.toString() + `&includeWorkingSites=${includeWorkingSites ? 1 : 0}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			},
		);

		const { results, count } = await response.json();
		return { results, count };
	}

	//Audit section
	async getAuditNote(companyId: string, resourceId: string, resourceType: DocumentTypeCategory, siteId?: string): Promise<DocumentNote> {
		const getResourceDocumentNoteUrl = `${getCommunicationBasePath(companyId, resourceType, resourceId, siteId)}communication/note`;

		const response = await this.apiService.fetchWithToken(getResourceDocumentNoteUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		return response.json();
	}

	async createAuditNote(companyId: string, resourceId: string, resourceType: DocumentTypeCategory, note: string, siteId?: string): Promise<void> {
		await this.apiService.fetchWithToken(`${getCommunicationBasePath(companyId, resourceType, resourceId, siteId)}communication/note`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ note }),
		});
	}

	async reorderDocuments(companyId: string, documents: Document[]): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ documents }),
		});
	}

	//AI section
	async createAiTask(
		companyId: string,
		files: FileEntity,
		sectionCode: AiTaskSectionCode,
		resourceId?: string,
		resourceType?: DocumentTypeCategory,
		siteId?: string,
		isSupplier?: boolean,
		requirementSource?: string,
	): Promise<string> {
		if (!files.binaries || files.binaries?.length <= 0) {
			console.log('Missing file binaries');
			return Promise.reject(new Error('no file binary found, cannot add file to document'));
		}

		const formData = new FormData();
		const binariesFromFile = Array.from(files.binaries);
		binariesFromFile?.forEach((f) => {
			formData.append('files[]', f);
		});

		formData.append('sectionCode', sectionCode);
		if (resourceType === DocumentTypeCategory.WORKER && resourceId) {
			formData.append('workerId', resourceId);
		}
		if (resourceType === DocumentTypeCategory.VEHICLE && resourceId) {
			formData.append('vehicleId', resourceId);
		}
		if (resourceType === DocumentTypeCategory.MACHINE && resourceId) {
			formData.append('machineId', resourceId);
		}
		if (resourceType === DocumentTypeCategory.TOOL && resourceId) {
			formData.append('toolId', resourceId);
		}
		if (resourceType === DocumentTypeCategory.CHEMICAL && resourceId) {
			formData.append('chemicalId', resourceId);
		}
		if (siteId) {
			formData.append('siteId', siteId);
		}
		if (isSupplier) {
			formData.append('supplierId', resourceId);
		}
		if (requirementSource) {
			formData.append('requirementSource', requirementSource);
		}

		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/ai/upload`, {
			method: 'POST',
			body: formData,
		});
		return response.json();
	}

	async getAiTasks(
		companyId: string,
		sectionCode: AiTaskSectionCode,
		resourceType?: DocumentTypeCategory,
		resourceId?: string,
		siteId?: string,
		supplierId?: string,
		requirementSource?: string,
	): Promise<AiTask[]> {
		const params = new URLSearchParams();
		params.append('sectionCode', sectionCode);

		if (resourceType === DocumentTypeCategory.WORKER && resourceId) {
			params.append('workerId', resourceId);
		}
		if (resourceType === DocumentTypeCategory.VEHICLE && resourceId) {
			params.append('vehicleId', resourceId);
		}
		if (resourceType === DocumentTypeCategory.MACHINE && resourceId) {
			params.append('machineId', resourceId);
		}
		if (resourceType === DocumentTypeCategory.TOOL && resourceId) {
			params.append('toolId', resourceId);
		}
		if (resourceType === DocumentTypeCategory.CHEMICAL && resourceId) {
			params.append('chemicalId', resourceId);
		}
		if (siteId) {
			params.append('siteId', siteId);
		}
		if (supplierId) {
			params.append('supplierId', resourceId);
		}
		if (requirementSource) {
			params.append('requirementSource', requirementSource);
		}
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/ai/tasks?${params.toString()}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			},
		);

		const { results } = await response.json();
		return results ?? [];
		// const task1 : AiTask = {id: "1", name: "Task1", status: AiTaskStatus.RUNNING};
		// const task2 : AiTask = {id: "2", name: "Task2", status: AiTaskStatus.TERMINATED};
		// const task3 : AiTask = {id: "3", name: "Task2", status: AiTaskStatus.TERMINATED};
		// return [task1, task2, task3];
	}

	async getAiTaskSites(
		companyId: string,
		taskId: string,
		sectionCode: AiTaskSectionCode,
		sortSites: SortMeta,
		filterSites: GetSitesFilter,
		pageParam?: number,
	): Promise<Site[]> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...sortSites,
			...filterSites,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/ai/task/${taskId}/propagation-sites?${params.toString()}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			},
		);
		const { results } = await response.json();
		return results ?? [];
	}

	async getAiTaskDetail(companyId: string, taskId: string): Promise<AiTask> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/ai/task/${taskId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		return response.json();
	}

	async deleteAiTasks(companyId: string, taskId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/ai/task/${taskId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	async confirmAiTask(companyId: string, taskId: string, aiTaskDocuments: AiTaskDocument[], siteIds: string[]): Promise<void> {
		// Transform the aiTaskDocuments to rename "id" to "fileId"
		const transformedAiTaskDocuments = aiTaskDocuments.map((doc) => ({
			...doc,
			fileId: doc.id, // Add the fileId field
			id: undefined, // Optionally remove the id field
			expiresAt: doc?.expiresAt ? dateToRFC3339(doc?.expiresAt) : null,
		}));

		// Create the request body with the transformed documents
		const body = JSON.stringify({ data: transformedAiTaskDocuments, siteIds });

		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/ai/task/${taskId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: body,
		});
	}
}

function getCommunicationBasePath(companyId: string, resourceType: DocumentTypeCategory, resourceId: string, siteId?: string) {
	const base = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/`;
	const resourcePath = `${resourceType}s/${resourceId}/`;
	if (siteId) return `${base}sites/${siteId}/${resourcePath}`;
	if (resourceType === DocumentTypeCategory.COMPANY) return base;
	return base + resourcePath;
}

export default ServerDocumentRepository;
