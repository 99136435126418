import {SiteResourceDocument} from "../../../../domain/entities/document";
import {ResourceDocumentEvaluationState} from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import {EvaluateDocumentParams} from "../../../hooks/Site/useSiteResourceDetailViewModel";
import React, {useState} from "react";
import {useHasPermissions} from "../../Permissions/RenderIf";
import {useTranslation} from "react-i18next";
import {formatDateBasedOnLanguage} from "../../../../utils";
import ResourceEvaluationToggle from "../../Common/ResourceEvaluationToggle";
import BaseModal from "../../Common/alerts/BaseModal";
import {Box, Button, Text} from "@chakra-ui/react";
import DocumentExpirationDate from "./DocumentExpirationDate";
import {ResourceDocumentsPermissions} from "./SiteResourceDocuments";

type DocumentEvaluationToggleProps = {
  document: SiteResourceDocument;
  setEvaluation: (evaluation: {
    documentId: string;
    result: ResourceDocumentEvaluationState;
    validity: string;
    suggestedValidity: string;
    noEvaluationExpiration: boolean;
    documentDuration: number;
  }) => void;
  evaluateDocument: (params: EvaluateDocumentParams) => void;
  evaluation: {
    documentId: string;
    result: ResourceDocumentEvaluationState;
    validity: string;
    suggestedValidity: string;
    noEvaluationExpiration: boolean;
    documentDuration: number;
  };
  permissions: ResourceDocumentsPermissions;
  evaluateDocumentIsLoading: boolean;
};

const DocumentEvaluationToggle = (
  {document, setEvaluation, evaluateDocument, evaluation, permissions, evaluateDocumentIsLoading,
  }: DocumentEvaluationToggleProps) => {
  const [
    showAddExpirationToDocumentModal,
    setShowAddExpirationToDocumentModal
  ] = useState(false);
  const canEvaluate = useHasPermissions(permissions.evaluate);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { t } = useTranslation();
  const handleStatusChange = (document: SiteResourceDocument) => {
    return (status: ResourceDocumentEvaluationState) => {
      evaluateDoc(document, status);
    };
  };
  const evaluateDoc = (
    document?: SiteResourceDocument,
    status?: ResourceDocumentEvaluationState,
    validity?: string,
    clear?: boolean,
    noExpiration?: boolean
  ) => {
    const evaluation = {
      result: status || document?.evaluationResult,
      documentId: document?.id,
      validity:
        validity ||
        (!clear ? (document?.evaluationExpiresAt as unknown as string) : null),
      suggestedValidity: formatDateBasedOnLanguage(document?.suggestExpiration),
      noEvaluationExpiration: noExpiration,
      documentDuration: document?.documentTypeDuration
    };
    if (
      evaluation.result === ResourceDocumentEvaluationState.AVAILABLE &&
      !validity &&
      !clear
    ) {
      setEvaluation(evaluation);
      setShowAddExpirationToDocumentModal(true);
    } else {
      evaluateDocument(evaluation);
    }
  };
  const evaluateWithConditions = () => {
    if (evaluation?.noEvaluationExpiration) {
      evaluate("");
      return;
    }
    if (evaluation?.validity) {
      evaluate(evaluation?.validity);
    }
  };
  // Handle drag&drop end
  const evaluate = async (validity: string) => {
    if (evaluation) {
      await evaluateDocument({ ...evaluation, validity });
      setShowAddExpirationToDocumentModal(false);
      setShowConfirmModal(false);
      setEvaluation(undefined);
    }
  };
  return (
    <>
      <ResourceEvaluationToggle
        status={document["evaluationResult"]}
        onChange={handleStatusChange(document)}
        type="document"
        validity={document?.evaluationExpiresAt}
        graceDays={
          document?.graceDays ? document?.graceDays : null
        }
        disabled={!canEvaluate}
        updateValidity={(validity, clear, noExpiration) => {
          return evaluateDoc(
            document,
            null,
            validity,
            clear,
            noExpiration
          );
        }}
        documentDuration={document?.documentTypeDuration}
      />

      {showAddExpirationToDocumentModal && (
        <BaseModal
          bodyOverflow={"unset"}
          title={t("addExpirationTitle", { ns: "siteResources" })}
          onClose={() => {
            setShowAddExpirationToDocumentModal(false)
          }}
          footer={
            <>
              <Button
                colorScheme="red"
                onClick={() => {
                  setShowAddExpirationToDocumentModal(false);
                  setEvaluation({ ...evaluation, validity: "" });
                  setShowConfirmModal(false);
                }}
                isDisabled={evaluateDocumentIsLoading}
              >
                {t("cancel", { ns: "common" })}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => evaluateWithConditions()}
                isDisabled={!evaluation.noEvaluationExpiration && !evaluation.validity}
                isLoading={evaluateDocumentIsLoading}
              >
                {t("save")}
              </Button>
            </>
          }
        >
          <Box py={4} overflow={"unset"} zIndex={1000}>
            <DocumentExpirationDate
              expiresAt={new Date(evaluation?.validity)}
              documentDuration={evaluation.documentDuration}
              isDisabled={evaluation.noEvaluationExpiration}
              updateExpirationDate={(data) =>
                setEvaluation({
                  ...evaluation,
                  validity: data?.toLocaleDateString()
                })
              }
              noExpiration={evaluation.noEvaluationExpiration}
              setNoExpiration={(value) => {
                setEvaluation({ ...evaluation, noEvaluationExpiration: value });
              }}
              showNoExpiration={true}
              showButtons={false}
            />
          </Box>
        </BaseModal>
      )}
      {showConfirmModal && evaluation && (
        <BaseModal
          bodyOverflow={"hidden"}
          title={t("confirmTitle", { ns: "siteResources" })}
          onClose={() => setShowConfirmModal(false)}
          isLoading={evaluateDocumentIsLoading}
          onConfirm={() => evaluate(evaluation.validity)}
        >
          <Text>{t("confirmSubtitle", { ns: "siteResources" })}</Text>
        </BaseModal>
      )}
    </>
  )
}

export default DocumentEvaluationToggle;