import Machine from "../../../domain/entities/machine";
import {
  makeGetMachine,
  makeGetMachineDocuments,
  makeMigrateToVehicle,
  makeUpdateMachine,
} from "../../../core/app/factory/machines/usecases";
import {
  makeGetMachineSites,
  makeGetResourceLinkableSites,
} from "../../../core/app/factory/sites/usecases";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import {
  makeAddResourceToSites,
  makeGetAvailableBadgesUseCase,
  makeGetResourceBadge,
  makeLinkBadgesResource,
} from "../../../core/app/factory/workers/usecases";
import { GetMachineVehicleDocumentTypeMapRecordsResult } from "../../../domain/interactors/documentTypes/getMachineVehicleDocumentTypeMapRecords";
import {
  makeGetBadgeSites,
  makeUnlinkBadgeResource,
} from "../../../core/app/factory/badges/usecases";
import { BadgeStatusSite } from "../../hooks/Badge/useBadgeDetailViewModel";
import {
  GetAvailableBadgesFilters,
  GetBadgesFilters,
} from "../../../domain/repositories/badgeRepository";
import Badge from "../../../domain/entities/badge";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import Site, { ResourceSites } from "../../../domain/entities/site";
import { makeGetTypologies } from "../../../core/app/factory/typologies/usecases";
import Typology from "../../../domain/entities/typology";
import Document from "../../../domain/entities/document";
import DocumentType from "../../../domain/entities/documentType";
import { makeGetDocumentTypes } from "../../../core/app/factory/requirements/useCases";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import {
  makeGetMachineVehicleDocumentTypeMapRecords,
  makeUpsertMachineVehicleDocumentTypeMapRecords,
} from "../../../core/app/factory/documentTypes/usecases";
import {CustomField} from "../../../domain/entities/customFields";
import {RequirementSubject} from "../../../domain/entities/requirementSubject.enum";
import {makeGetCustomFields} from "../../../core/app/factory/companies/usecases";

class MachineViewModel {
  private getMachineUseCase = makeGetMachine();
  private updateMachineUseCase = makeUpdateMachine();
  private getMachineSitesUseCase = makeGetMachineSites();
  private getResourceLinkableSitesUseCase = makeGetResourceLinkableSites();
  private linkResourceToSitesUseCase = makeAddResourceToSites();
  private getResourceBadgeUseCase = makeGetResourceBadge();
  private getBadgeSitesUseCase = makeGetBadgeSites();
  private getAvailableBadgesUseCase = makeGetAvailableBadgesUseCase();
  private linkBadgesToResourceUseCase = makeLinkBadgesResource();
  private unlinkBadgeResourceUseCase = makeUnlinkBadgeResource();

  private migrateToVehicleUseCase = makeMigrateToVehicle();
  private getMachineVehicleDocumentTypeMapRecordsUseCase =
    makeGetMachineVehicleDocumentTypeMapRecords();
  private upsertMachineVehicleDocumentTypeMapRecordsUseCase =
    makeUpsertMachineVehicleDocumentTypeMapRecords();
  private getTypologiesUseCase = makeGetTypologies();
  private getMachineDocumentsUseCase = makeGetMachineDocuments();
  private getDocumentTypesUseCase = makeGetDocumentTypes();
  private getCustomFieldsUseCase = makeGetCustomFields();

  async get(companyId: string, machineId: string): Promise<Machine> {
    return await this.getMachineUseCase.Call({ companyId, machineId });
  }

  async getMachineSites(
    companyId: string,
    machineId: string,
    sort: SortMeta,
    filter: GetSitesFilter,
    pageParam: number,
    includeWorkingSites?: boolean,
    ignoreUserSiteRoleRestrictions?: boolean,
    documentTypeId?: string,
  ): Promise<ResourceSites> {
    return await this.getMachineSitesUseCase.Call({
      companyId,
      machineId,
      sort,
      filter,
      pageParam,
      includeWorkingSites,
      ignoreUserSiteRoleRestrictions,
      documentTypeId
    });
  }

  async getResourceBadge(
    companyId: string,
    resource: string,
    resourceId: string,
    sort: SortMeta,
    filter: GetBadgesFilters,
    pageParam: number,
    supplierId?: string,
  ): Promise<PaginatedResults<Badge>> {
    return await this.getResourceBadgeUseCase.Call({
      companyId,
      resource,
      resourceId,
      sort,
      filter,
      pageParam,
      supplierId,
    });
  }
  async getBadgeSites(
    companyId: string,
    badgeId: string,
  ): Promise<BadgeStatusSite[]> {
    return await this.getBadgeSitesUseCase.Call({ companyId, badgeId });
  }
  async getAvailableBadges(
    companyId: string,
    resource: string,
    resourceId: string,
    sort: SortMeta,
    filter: GetAvailableBadgesFilters,
  ): Promise<Badge[]> {
    return await this.getAvailableBadgesUseCase.Call({
      companyId,
      resource,
      resourceId,
      sort,
      filter,
    });
  }
  async update(
    companyId: string,
    machine: Machine,
    imageFile?: File,
  ): Promise<Machine> {
    return await this.updateMachineUseCase.Call({
      companyId,
      machine,
      imageFile,
    });
  }
  async getResourceLinkableSites(
    companyId: string,
    resourceType: string,
    resourceId: string,
    sort: SortMeta,
    filters: GetSitesFilter,
    pageParam: number,
  ): Promise<{results: Site[], count: number }> {
    return await this.getResourceLinkableSitesUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      sort,
      filters,
      pageParam,
    });
  }
  async linkBadgesToResource(
    companyId: string,
    badgeIds: string[],
    resourceType: BadgeResourceType,
    resourceId: string,
  ): Promise<void> {
    return await this.linkBadgesToResourceUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      badgeIds,
    });
  }

  async unlinkBadgeResource(companyId: string, badgeId: string): Promise<void> {
    return await this.unlinkBadgeResourceUseCase.Call({ companyId, badgeId });
  }
  async linkResourceToSites(
    companyId: string,
    workerId: string,
    siteIds: string[],
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ): Promise<void> {
    return await this.linkResourceToSitesUseCase.Call({
      companyId,
      resourceType: "machine",
      resourceId: workerId,
      siteIds,
      selectAll,
      filters,
    });
  }
  async migrateToVehicle(
    companyId: string,
    machineId: string,
    typologyId: string,
  ): Promise<string> {
    return await this.migrateToVehicleUseCase.Call({
      companyId,
      machineId,
      typologyId,
    });
  }
  async getMachineVehicleDocumentTypeMapRecords(
    companyId: string,
  ): Promise<GetMachineVehicleDocumentTypeMapRecordsResult> {
    return await this.getMachineVehicleDocumentTypeMapRecordsUseCase.Call({
      companyId,
    });
  }
  async upsertMachineVehicleDocumentTypeMapRecords(
    companyId: string,
    records: { machineDocumentTypeId: string; vehicleDocumentTypeId: string }[],
  ): Promise<void> {
    return await this.upsertMachineVehicleDocumentTypeMapRecordsUseCase.Call({
      companyId,
      records,
    });
  }
  async getVehicleTypologies(companyId: string): Promise<Typology[]> {
    return await this.getTypologiesUseCase.Call({
      companyId,
      type: DocumentTypeCategory.VEHICLE,
    });
  }
  async getDocuments(
    companyId: string,
    machineId: string,
  ): Promise<Document[]> {
    return await this.getMachineDocumentsUseCase.Call({
      companyId,
      resourceId: machineId,
    });
  }
  async getVehicleDocumentTypes(companyId: string): Promise<DocumentType[]> {
    return await this.getDocumentTypesUseCase.Call({
      companyId,
      filter: DocumentTypeCategory.VEHICLE,
      includePublic: true,
    });
  }
  async getCustomFields(companyId: string): Promise<CustomField[]> {
    return await this.getCustomFieldsUseCase.Call({ companyId, subject: RequirementSubject.MACHINE });
  }
}

export { MachineViewModel };
