import UseCase from '../../../core/app/usecase';
import { GetSiteResourcesAuditFilters } from '../../../presentation/hooks/Site/useSiteAuditsViewModel';
import { PaginatedResults, SortMeta } from '../../entities/interfaces/paginatedResults';
import { SiteWorker } from '../../entities/siteWorker';
import SiteRepository from '../../repositories/siteRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	pageParam: number;
	filterSiteResources: GetSiteResourcesAuditFilters;
	siteResourcesSort: SortMeta;
	supplierId: string;
};

class GetSiteSupplierWorkers implements UseCase<Parameters, PaginatedResults<SiteWorker>> {
	constructor(private repository: SiteRepository) {}

	async Call({
		companyId,
		siteId,
		pageParam,
		filterSiteResources,
		siteResourcesSort,
		supplierId,
	}: Parameters): Promise<PaginatedResults<SiteWorker>> {
		return this.repository.getSiteSupplierWorkers(companyId, siteId, pageParam, filterSiteResources, siteResourcesSort, supplierId);
	}
}

export default GetSiteSupplierWorkers;
