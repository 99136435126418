import { Navigate } from "react-router-dom";
import { FC, ReactNode } from "react";
import { useAuth, PermissionCheck } from "../../providers/Auth0JWTProvider";

interface Props {
  routeName?: string;
  element?: ReactNode;
  permissions: string[];
  check?: PermissionCheck;
}

const RequirePermissions: FC<Props> = ({
  routeName,
  element,
  permissions,
  check,
}) => {
  const { hasPermissions, companyUser } = useAuth();
  const allow =
    hasPermissions(permissions, check) && companyUser.state === "enabled";
  return allow ? (
    <>{element}</>
  ) : (
    <Navigate
      to="/access-denied"
      state={{
        permissions,
        routeName,
        blockedUser: companyUser.state === "blocked",
      }}
      replace
    />
  );
};

export default RequirePermissions;
