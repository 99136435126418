import UseCase from '../../../../core/app/usecase';
import Requirement from '../../../entities/requirement';
import SiteVehicleRepository from '../../../repositories/siteVehicleRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	vehicleId: string;
	requirements: {
		documentTypeId: string;
		isOptional: boolean;
		graceDays: number;
	}[]
};

class AddRequirementToSiteVehicle implements UseCase<ARGS, Requirement> {
	private siteRepository: SiteVehicleRepository;

	constructor(siteRepository: SiteVehicleRepository) {
		this.siteRepository = siteRepository;
	}

	async Call(parameters: ARGS): Promise<Requirement> {
		return await this.siteRepository.addRequirementToSiteVehicle(
			parameters.companyId,
			parameters.siteId,
			parameters.vehicleId,
			parameters.requirements
		);
	}
}

export default AddRequirementToSiteVehicle;
