import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SupplierMainViewModel from "../../viewmodels/suppliers/SupplierMainViewModel";

const useSupplierMainViewModel = (supplierId: string) => {
  const { companyId } = useAuth();
  const viewModel = new SupplierMainViewModel(companyId, supplierId);

  const getSiteQuery = useQuery(
    ["get-supplier-details", companyId, supplierId],
    async () => await viewModel.get(),
  );

  return {
    details: getSiteQuery.data,
  };
};

export default useSupplierMainViewModel;
