import UseCase from '../../../../core/app/usecase';
import ChemicalRepository from '../../../repositories/chemicalRepository';

interface Args {
	companyId: string;
	resourceId: string;
	typologyId: string;
}

class AddTypologyToChemical implements UseCase<Args, void> {
	constructor(private repository: ChemicalRepository) {}

	async Call(args: Args): Promise<void> {
		return await this.repository.addTypologyToChemical(args.companyId, args.resourceId, args.typologyId);
	}
}

export default AddTypologyToChemical;
