import MainLayout from "../../layout/MainLayout";
import MainNavBar from "../../components/Common/MainNavBar";
import SettingsTopBar from "../../components/Views/Settings/SettingsTopBar";
import { useEffect } from "react";
import { useApp } from "../../../app";
import { useAuth } from "../../providers/Auth0JWTProvider";

const SettingsMainView = () => {
  const { updateContext } = useApp();
  const { updateSiteContext } = useAuth();

  useEffect(() => {
    updateContext({ topBarTitle: undefined, site: undefined });
    updateSiteContext(undefined);
  }, []);

  return <MainLayout header={<SettingsTopBar />} nav={<MainNavBar />} />;
};

export default SettingsMainView;
