import UseCase from '../../../core/app/usecase';
import DocumentRepository from '../../repositories/documentRepository';

type ARGS = {
	companyId: string;
	documentId: string;
	resourceId?: string;
	target?: string;
	siteIds?: string[];
	selectAll?: boolean;
};

class RepropagateEvaluationGeneralDocument implements UseCase<ARGS, void> {
	private documentRepository: DocumentRepository;

	constructor(evaluationRepository: DocumentRepository) {
		this.documentRepository = evaluationRepository;
	}

	async Call({ companyId, documentId, resourceId, target, siteIds, selectAll }: ARGS): Promise<void> {
		return await this.documentRepository.repropagateEvaluationGeneralDocument(
			companyId,
			documentId,
			resourceId,
			target,
			siteIds,
			selectAll,
		);
	}
}

export default RepropagateEvaluationGeneralDocument;

