import { COLORS } from "../../assets/theme/colors";
import { TbPackageExport } from "react-icons/tb";
import ActionBarItem from "../../components/Common/ActionBarItem";
import React from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "../../components/Common/alerts/BaseModal";
import { Flex, Text } from "@chakra-ui/react";
import Select from "react-select";
import Typology from "../../../domain/entities/typology";
import Document from "../../../domain/entities/document";
import DocumentType from "../../../domain/entities/documentType";
import TagSelect from "../../components/Common/TagSelect";
import { FaCircleArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

interface MapRecord {
  vehicleDocumentType: DocumentType;
  machineDocumentType: DocumentType;
}
interface MapRecordInput {
  vehicleDocumentTypeId: string;
  machineDocumentTypeId: string;
}

interface MachineVehicleMigrationButtonProps {
  namespace: "machines" | "vehicles";
  migrate: (data: { typologyId: string }) => Promise<string>;
  loading: boolean;
  hasSites: boolean;
  targetTypologies: Typology[];
  documents: Document[];
  documentTypeMapRecords: MapRecord[];
  upsertMapRecords: (records: MapRecordInput[]) => Promise<void>;
  targetDocumentTypes: DocumentType[];
  openMigrationModal: boolean;
  setOpenMigrationModal: (open: boolean) => void;
  records: MapRecordInput[];
  setRecords: (records: MapRecordInput[]) => void;
}

const MachineVehicleMigrationButton = ({
  migrate,
  hasSites,
  loading,
  openMigrationModal,
  setOpenMigrationModal,
  upsertMapRecords,
  documentTypeMapRecords,
  ...props
}: MachineVehicleMigrationButtonProps) => {
  const { namespace, documents, records } = props;
  const navigate = useNavigate();
  const { t } = useTranslation(namespace);
  const [step, setStep] = React.useState(0);
  const [typologyId, setTypologyId] = React.useState<string>();

  const saveRecordsAndContinue = async () => {
    await upsertMapRecords(records);
    setStep(1);
  };

  const handleMigrate = async () => {
    const resourceId = await migrate({ typologyId });
    setOpenMigrationModal(false);
    setStep(0);
    navigate(
      `/resources/${namespace === "machines" ? "vehicles" : "machines"}/${resourceId}/details`,
    );
  };

  const handleConfirm = () =>
    step === 0 ? saveRecordsAndContinue() : handleMigrate();
  const handleCancel = () =>
    step === 0 || hasSites ? setOpenMigrationModal(false) : setStep(0);

  const checkConfirmEnabled = () => {
    if (records.length != documents.length) {
      return false;
    }
    return records.every(
      (item) => item.vehicleDocumentTypeId && item.machineDocumentTypeId,
    );
  };

  return (
    <>
      <ActionBarItem
        bgColor={COLORS.yellow}
        color="black"
        icon={TbPackageExport}
        description={t("migrate", { ns: namespace })}
        onClick={() => setOpenMigrationModal(true)}
      />
      {openMigrationModal && documents && (
        <BaseModal
          onClose={() => {
            setStep(0);
            setTypologyId(undefined);
            setOpenMigrationModal(false);
          }}
          title={t("migrateModal.title")}
          onConfirm={handleConfirm}
          onConfirmLabel={t(`migrateModal.${step === 0 ? "next" : "confirm"}`)}
          onConfirmDisabled={
            (step === 0 && (hasSites || !checkConfirmEnabled())) ||
            (step === 1 && !typologyId)
          }
          onCancel={handleCancel}
          onCancelLabel={t(`migrateModal.${step === 0 ? "back" : "cancel"}`)}
          onCancelDisabled={loading}
          type={hasSites ? "warning" : undefined}
          isLoading={loading}
          avoidCloseOnOnConfirm
          disableOnClose
        >
          {hasSites ? (
            <Text variant="body1" color="textSecondary">
              {t("migrateModal.hasSites")}
            </Text>
          ) : (
            <MigrationWizard
              step={step}
              typologyId={typologyId}
              setTypologyId={setTypologyId}
              records={records}
              {...props}
            />
          )}
        </BaseModal>
      )}
    </>
  );
};

interface MigrationWizardProps {
  targetTypologies: Typology[];
  step: number;
  namespace: "machines" | "vehicles";
  typologyId?: string;
  setTypologyId: (typologyId?: string) => void;
  documents: Document[];
  records: MapRecordInput[];
  setRecords: (records: MapRecordInput[]) => void;
  targetDocumentTypes: DocumentType[];
}

const MigrationWizard = ({
  targetTypologies,
  step,
  namespace,
  setTypologyId,
  typologyId,
  documents,
  targetDocumentTypes,
  records,
  setRecords,
}: MigrationWizardProps) => {
  const { t } = useTranslation(namespace);

  const typeOptions = targetDocumentTypes.map((targetType) => ({
    value: targetType.id,
    label: targetType.name,
  }));

  const handleSelectType = (originTypeId: string, targetTypeId: string) => {
    setRecords(
      records.map((record) => {
        if (
          namespace === "machines" &&
          record.machineDocumentTypeId === originTypeId
        ) {
          return {
            machineDocumentTypeId: originTypeId,
            vehicleDocumentTypeId: targetTypeId,
          };
        }
        if (
          namespace === "vehicles" &&
          record.vehicleDocumentTypeId === originTypeId
        ) {
          return {
            vehicleDocumentTypeId: originTypeId,
            machineDocumentTypeId: targetTypeId,
          };
        }
        return record;
      }),
    );
  };

  const getValue = (originTypeId: string) => {
    return namespace === "machines"
      ? typeOptions.find(
          (o) =>
            o.value ===
            records.find((r) => r.machineDocumentTypeId === originTypeId)
              ?.vehicleDocumentTypeId,
        )
      : typeOptions.find(
          (o) =>
            o.value ===
            records.find((r) => r.vehicleDocumentTypeId === originTypeId)
              ?.machineDocumentTypeId,
        );
  };

  const selectedTargetIds = records.map((r) =>
    namespace === "machines"
      ? r.vehicleDocumentTypeId
      : r.machineDocumentTypeId,
  );

  if (step === 0)
    return (
      <div>
        <Text variant="body1" color="textSecondary">
          {t("migrateModal.intro")}
        </Text>
        {documents.map((document) => (
          <Flex
            gap={5}
            key={document.id}
            justifyContent="space-between"
            alignItems="center"
            my={5}
          >
            <Text variant="body1" color="textSecondary">
              {document.type.name}
            </Text>
            <Flex
              gap={5}
              key={document.id}
              justifyContent="space-between"
              alignItems="center"
            >
              <FaCircleArrowRight color={COLORS.sikuroBlue} fontSize="20px" />
              <Select
                options={typeOptions.filter(
                  (o) => !selectedTargetIds.includes(o.value),
                )}
                onChange={(option) =>
                  handleSelectType(document.type.id, option?.value)
                }
                value={getValue(document.type.id)}
                name="typology-id"
                menuShouldScrollIntoView={false}
                menuPosition="fixed"
                menuPlacement={"auto"}
                placeholder={t("migrateModal.documentPlaceholder")}
                isClearable
                styles={{
                  control: (styles) => ({
                    ...styles,
                    width: "300px",
                    fontSize: "14px",
                    borderRadius: 2,
                  }),
                  noOptionsMessage: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                  }),
                  option: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                    ":hover": {
                      backgroundColor: "rgba(0,95,250,0.25)",
                    },
                  }),
                }}
              />
            </Flex>
          </Flex>
        ))}
      </div>
    );

  return (
    <div>
      <Text variant="body1" color="textSecondary" mb={2}>
        {t("migrateModal.introFinal")}
      </Text>
      <TagSelect
        editable
        tags={targetTypologies}
        selectedTags={[typologyId]}
        setSelectedTags={(tags) => setTypologyId(tags[0] ?? undefined)}
        defaultMenuIsOpen={false}
        isMulti={false}
        style={{ width: 300 }}
        label={t("typologies", { ns: "documents" })}
        usePortal
      />
    </div>
  );
};

export default MachineVehicleMigrationButton;
