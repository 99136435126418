import Staff from "../../../../domain/entities/staff";
import { Box, IconButton, Tooltip, IconProps, Flex } from "@chakra-ui/react";
import { CopyIcon, EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { FC, useState } from "react";

interface ContactFieldProps {
  staff?: Staff;
}

const ContactField = ({ staff }: ContactFieldProps) => (
<Box fontWeight={600} mt={1}>
  {staff ? (
    <Flex
      flexDirection="row" 
      alignItems="center"
      wrap="wrap" 
      gap={2} 
    >
      <span>
        {staff.name} {staff.surname}
      </span>
      {staff.email && staff.email !== "-" && (
        <ContactIcon contact={staff.email} IconValue={EmailIcon} />
      )}
      {staff.phone && staff.phone !== "-" && (
        <ContactIcon contact={staff.phone} IconValue={PhoneIcon} />
      )}
    </Flex>
  ) : (
    "-"
  )}
</Box>
);

const ContactIcon = ({
  contact,
  IconValue,
}: {
  contact: string;
  IconValue: FC<IconProps>;
}) => {
  const [focus, setFocus] = useState(false);
  const IconComponent = focus ? <CopyIcon fontSize="14px" /> :  <IconValue fontSize="14px" />;
  return (
    <Tooltip label={contact} hasArrow >
      <IconButton
        marginLeft={2}
        onMouseEnter={() => setFocus(true)}
        onMouseLeave={() => setFocus(false)}
        onClick={() => {
          navigator.clipboard.writeText(contact);
          setFocus(false);
        }}
        aria-label={contact}
        icon={IconComponent}
        variant="unstyled"
        sx={{ minWidth: 0, height: "auto", padding: "0 10px" }}
      />
    </Tooltip>
  );
};

export default ContactField;
