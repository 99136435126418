import UseCase from '../../../../core/app/usecase';
import SiteToolRepository from '../../../repositories/siteToolRepository';
import Evaluation from '../../../entities/evaluation';
import { ResourceEvaluationState } from '../../../entities/resourceEvaluationState.enum';

type Parameters = {
	companyId: string;
	siteId: string;
	toolId: string;
	value: ResourceEvaluationState;
};

class EvaluateSiteTool implements UseCase<Parameters, Evaluation<ResourceEvaluationState> | undefined> {
	constructor(private repository: SiteToolRepository) {}

	async Call({ companyId, siteId, toolId, value }: Parameters): Promise<Evaluation<ResourceEvaluationState> | undefined> {
		return this.repository.evaluateSiteTool(companyId, siteId, toolId, value);
	}
}

export default EvaluateSiteTool;
