import UseCase from '../../../core/app/usecase';
import DocumentComment from '../../entities/documentComment';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import DocumentRepository from '../../repositories/documentRepository';

interface Args {
	companyId: string;
	siteId?: string;
	resourceId: string;
	resourceType: DocumentTypeCategory;
	comment: DocumentComment;
}

class CreateResourceDocumentComment implements UseCase<Args, void> {
	constructor(private repository: DocumentRepository) {}

	async Call({ companyId, siteId, resourceId, resourceType, comment }: Args): Promise<void> {
		return await this.repository.createResourceDocumentComment(companyId, resourceId, resourceType, comment, siteId);
	}
}

export default CreateResourceDocumentComment;
