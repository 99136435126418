import UseCase from '../../../core/app/usecase';
import { UserNotification } from '../../entities/interfaces/userNotification';
import NotificationsRepository, { makeNotificationsRepository } from '../../repositories/notificationsRepository';

interface Args {
	companyId: string;
	userId: string;
}

/**
 * Get the list of notifications for a specific user.
 */
class GetUserNotificationsUseCase implements UseCase<Args, UserNotification[]> {
	constructor(private repository: NotificationsRepository) {}

	async Call(args: Args): Promise<UserNotification[]> {
		const { companyId, userId } = args;
		return await this.repository.getUserNotifications(companyId, userId);
	}
}

export const makeGetUserNotificationsUseCase = (): GetUserNotificationsUseCase => {
	return new GetUserNotificationsUseCase(makeNotificationsRepository());
};
