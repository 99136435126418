import Supplier from "../../../domain/entities/supplier";
import { makeGetWorkingSites } from "../../../core/app/factory/suppliers/useCases";
import { GetWorkingSiteFilter } from "../../../domain/repositories/supplierRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";

class WorkingSitesListViewModel {
  private getWorkingSitesUseCase = makeGetWorkingSites();

  async list(
    companyId: string,
    filter?: GetWorkingSiteFilter,
    sort?: SortMeta,
  ): Promise<Supplier[]> {
    return await this.getWorkingSitesUseCase.Call({ companyId, filter, sort });
  }
}

export { WorkingSitesListViewModel };
