import UseCase from '../../../../core/app/usecase';
import DocumentTypeWithPublic from '../../../entities/documentTypeWithPublic';
import WorkerRepository from '../../../repositories/workerRepository';

type CreatePropagableWorkerDocumentParameters = {
	companyId: string;
	resourceId: string;
	documents: DocumentTypeWithPublic[];
};

/**
 * Returns the list of all the existing Document.
 */
class CreatePropagableWorkerDocument implements UseCase<CreatePropagableWorkerDocumentParameters, void> {
	constructor(private repository: WorkerRepository) {}

	async Call({ companyId, resourceId, documents }: CreatePropagableWorkerDocumentParameters): Promise<void> {
		return await this.repository.createPropagableWorkerDocument(companyId, resourceId, documents);
	}
}

export default CreatePropagableWorkerDocument;
