import UseCase from '../../../core/app/usecase';
import DocumentPreset from '../../entities/documentPreset';
import { DocumentPresetTarget } from '../../entities/documentPresetTarget.enum';
import DocumentsPresetsRepository from '../../repositories/documentsPresetsRepository';

interface Args {
	companyId: string;
	typologyId: string;
	target: DocumentPresetTarget;
	documentPresents: DocumentPreset[]
}

class UpdatePresetsByTypologyOrder implements UseCase<Args, void> {
	constructor(private repository: DocumentsPresetsRepository) {}

	async Call(args: Args): Promise<void> {
		return await this.repository.updatePresetsByTypologyOrder(args.companyId, args.typologyId, args.target, args.documentPresents);
	}
}

export default UpdatePresetsByTypologyOrder;
