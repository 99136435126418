import Company from '../../domain/entities/company';

export const parseCompany = (payload): Company => {
	return {
		id: payload.id,
		logo: payload.logo,
		name: payload.name,
		fiscalCode: payload.fiscalCode,
		phoneNumber: payload.phoneNumber,
		pec: payload.pec,
		vat: payload.vat,
		email: payload.email,
		address: {
			street: payload.street,
			city: payload.city,
			cap: payload.cap,
		},
		medic: payload.medic,
		rspp: payload.rspp,
		referent: payload.referent,
		employer: payload.employer,
		rls: payload.rls,
		businessSize: payload.businessSize,
		ccnl: payload.ccnl,
		vatCountryCode: payload.vatCountryCode,
    ...Object.keys(payload)
      .filter(key => key.startsWith('custom_'))
      .map(key => ({ [key]: payload[key] })).
      reduce((acc, curr) => ({ ...acc, ...curr }), {}),
	};
};
