import {
  Input,
  InputProps
} from "@chakra-ui/react";
import BaseField, {BaseFieldProps} from "./BaseField";

type BaseInputFieldProps = BaseFieldProps & {
  inputProps?: InputProps;
}

const BaseInputField = ({inputProps, ...props}: BaseInputFieldProps) => (
  <BaseField {...props}>
    <Input {...inputProps} />
  </BaseField>
)

export default BaseInputField;