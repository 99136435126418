import StateTag from "../../Common/StateTag";
import { useTranslation } from "react-i18next";
import Supplier from "../../../../domain/entities/supplier";
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr as ChakraTr,
  useMediaQuery,
} from "@chakra-ui/react";
import DeleteButton from "../../Common/table/DeleteButton";
import { InvitedSupplier } from "../../../../domain/entities/invitedSupplier";
import { UpdateFilter } from "../../../hooks/Site/useSiteResourceDetailViewModel";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import {
  ActiveSubcontractorsFilter,
  InvitedSuppliersFilter,
} from "../../../../domain/repositories/supplierRepository";
import { formatDateBasedOnLanguage } from "../../../../utils";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import { Tr } from "../../Common/table/SimpleTable";
import InfiniteTable from "../../Common/table/InfiniteTable";
import ActionButton from "../../Common/table/ActionButton";
import { FiSend } from "react-icons/fi";
import { Alert } from "../../../screens/Common/Alert";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import SearchInput from "../../../screens/Common/SearchInput";
import { useHasPermissions } from "../../Permissions/RenderIf";
import { Permission } from "../../Permissions/Permissions";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";

export type WorkingSitesTableProps = {
  rows: Array<Supplier>;
  onSelect?: (row: Supplier) => void;
  onSelectInvited?: () => void;
  filterableColumns?: number[];
  activeContractorsFetching: boolean;
  activeContractorsHasNextPage: boolean;
  activeContractorsFetchNextPage: () => void;
  invitedContractors: InvitedSupplier[];
  filterInvited: InvitedSuppliersFilter;
  updateFilterInvited?: UpdateFilter;
  sortInvited: SortMeta;
  setSortInvited?: (sort: SortMeta) => void;
  filterActive: ActiveSubcontractorsFilter;
  updateFilterActive?: UpdateFilter;
  sortActive: SortMeta;
  setSortActive?: (sort: SortMeta) => void;
  invitedContractorsHasNextPage: boolean;
  invitedContractorsFetchNextPage: () => void;
  invitedIsFetching: boolean;
  autosize?: boolean;
  messageInvitation: string | null;
  renewInvitation: (token: string) => void;
  setMessageInvitation: (message: string) => void;
  setSearchActive?: (query: string) => void;
  setSearchInvited?: (query: string) => void;
};

function WorkingSiteSubcontractTable({
  rows,
  onSelect,
  onSelectInvited,
  activeContractorsFetching,
  activeContractorsHasNextPage,
  activeContractorsFetchNextPage,
  invitedContractors,
  invitedIsFetching,
  invitedContractorsHasNextPage,
  invitedContractorsFetchNextPage,
  autosize,
  messageInvitation,
  renewInvitation,
  setMessageInvitation,
  setSortInvited,
  sortInvited,
  filterInvited,
  updateFilterInvited,
  filterActive,
  sortActive,
  setSortActive,
  updateFilterActive,
  setSearchActive,
  setSearchInvited,
}: WorkingSitesTableProps) {
  const { t } = useTranslation("supplier");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [showAskConfirmModal, setShowAskConfirmModal] = useState<boolean>();
  const [invitationToken, setInvitationToken] = useState<string>();
  const hasCsePermission = useHasPermissions([Permission.Sites_CseShowSites, Permission.Worksite_CseShowWorkingSites], PermissionCheck.Some);

  const [searchParams, _] = useSearchParams();
  const selected = (searchParams.get("selected") ?? "").toLowerCase();

  const invitedColumns: {
    field: keyof InvitedSuppliersFilter;
    type: "text" | "select" | "date-range";
  }[] = [
    { field: "name", type: "text" },
    { field: "email", type: "text" },
    { field: "sendDate", type: "date-range" },
  ];

  const activeColumns: {
    field: keyof ActiveSubcontractorsFilter;
    type: "text" | "select";
  }[] = [
    { field: "name", type: "text" },
    { field: "evaluationResult", type: "select" },
    { field: "status", type: "select" },
  ];

  return (
    <>
      <Tabs defaultIndex={selected === "invited" ? 1 : 0}>
        <TabList>
          <Tab width={"100%"} textAlign={"center"}>
            {t("listOfSubcontractors", { ns: "supplier" })}
          </Tab>
          {!hasCsePermission &&
            <Tab width={"100%"} textAlign={"center"}>
              {t("listOfInvitedSubcontractors", { ns: "supplier" })}
            </Tab>
          }
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <Flex justifyContent={"end"} alignItems="center" w={"100%"} mt={4}>
              <SearchInput onSearch={setSearchActive} />
            </Flex>
            <Flex
              flexDirection={"column"}
              alignItems={"start"}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="10px"
              width={isMobile ? "900px" : "100%"}
              position="relative"
              overflow={"hidden"}
              marginTop={6}
            >
              <InfiniteTable
                autosize={autosize}
                tableId="working-site-subcontract-active-contractors-table"
                infiniteScroll={{
                  dataLength: rows.length,
                  hasNextPage: activeContractorsHasNextPage,
                  fetchNextPage: activeContractorsFetchNextPage,
                }}
                showEmptyText={rows?.length === 0}
                isLoading={activeContractorsFetching}
                emptyText={t("noActiveSubcontractors", { ns: "supplier" })}
              >
                <Thead>
                  <ChakraTr>
                    {activeColumns.map((row) => {
                      return (
                        <Th key={row.field}>
                          <TableColumnHeader
                            text={
                              row.field === "evaluationResult"
                                ? t("eligibility")
                                : t(row.field)
                            }
                            filter={{
                              component: (
                                <ColumnFilterComponent
                                  type={row.type}
                                  value={filterActive[row.field]}
                                  updateFilter={(value) =>
                                    updateFilterActive(row.field, value)
                                  }
                                  selectOptions={
                                    row.field === "evaluationResult"
                                      ? [
                                          {
                                            option: "eligible",
                                            label: t(
                                              `combinedEvaluation.eligible`,
                                              {
                                                ns: "enum",
                                              },
                                            ),
                                          },
                                          {
                                            option: "not_eligible",
                                            label: t(
                                              `combinedEvaluation.not_eligible`,
                                              {
                                                ns: "enum",
                                              },
                                            ),
                                          },
                                          {
                                            option: "eligible_with_integration",
                                            label: t(
                                              `combinedEvaluation.eligible_with_integration`,
                                              { ns: "enum" },
                                            ),
                                          },
                                          {
                                            option: "unevaluated",
                                            label: t(
                                              `combinedEvaluation.unevaluated`,
                                              {
                                                ns: "enum",
                                              },
                                            ),
                                          },
                                        ]
                                      : [
                                          {
                                            option: "active",
                                            label: t(`supplierStatus.active`, {
                                              ns: "enum",
                                            }),
                                          },
                                          {
                                            option: "blocked",
                                            label: t(`supplierStatus.blocked`, {
                                              ns: "enum",
                                            }),
                                          },
                                          {
                                            option: "pending",
                                            label: t(`supplierStatus.pending`, {
                                              ns: "enum",
                                            }),
                                          },
                                        ]
                                  }
                                />
                              ),
                              isActive: !!(Array.isArray(
                                filterActive[row.field],
                              )
                                ? filterActive[row.field][1]
                                : filterActive[row.field]),
                            }}
                            sort={{
                              handler: (direction) => {
                                return setSortActive({
                                  field: row.field,
                                  direction,
                                });
                              },
                              direction:
                                sortActive?.field === row.field
                                  ? sortActive.direction
                                  : undefined,
                            }}
                          />
                        </Th>
                      );
                    })}
                  </ChakraTr>
                </Thead>
                <Tbody>
                  {rows?.map((row) => (
                    <Tr key={row.company?.id} data={row} onClick={onSelect}>
                      <Td textColor={"gray.700"}>{row?.company?.name}</Td>
                      <Td textColor={"gray.700"}>
                        <StateTag
                          value={
                            row.evaluationResult
                              ? row.evaluationResult
                              : "unevaluated"
                          }
                          type="supplierEvaluation"
                        />
                      </Td>
                      <Td textColor={"gray.700"}>
                        <StateTag
                          value={row.status ? row.status : "pending"}
                          type="supplierStatus"
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </InfiniteTable>
            </Flex>
          </TabPanel>

          {!hasCsePermission &&
            <TabPanel p={0}>
              <Flex justifyContent={"end"} alignItems="center" w={"100%"} mt={4}>
                <SearchInput onSearch={setSearchInvited} />
              </Flex>
              <Flex
                flexDirection={"column"}
                alignItems={"start"}
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
                width={isMobile ? "900px" : "100%"}
                position="relative"
                overflow={"hidden"}
                marginTop={6}
              >
                <InfiniteTable
                  autosize={autosize}
                  tableId="working-site-subcontract-invited-contractors-table"
                  showEmptyText={invitedContractors?.length === 0}
                  infiniteScroll={{
                    dataLength: invitedContractors.length,
                    hasNextPage: invitedContractorsHasNextPage,
                    fetchNextPage: invitedContractorsFetchNextPage,
                  }}
                  isLoading={invitedIsFetching}
                  emptyText={t("noInvitedSupplier", { ns: "supplier" })}
                >
                  <Thead>
                    <ChakraTr bg={"gray.300"}>
                      {invitedColumns.map((c) => (
                        <Th key={c.field}>
                          <TableColumnHeader
                            text={t(`columns.${c.field}`)}
                            filter={{
                              component: (
                                <ColumnFilterComponent
                                  type={c.type}
                                  value={filterInvited[c.field]}
                                  updateFilter={(value) =>
                                    updateFilterInvited(c.field, value)
                                  }
                                />
                              ),
                              isActive: !!(Array.isArray(filterInvited[c.field])
                                ? filterInvited[c.field][1]
                                : filterInvited[c.field]),
                            }}
                            sort={{
                              handler: (direction) => {
                                return setSortInvited({
                                  field: c.field,
                                  direction,
                                });
                              },
                              direction:
                                sortInvited?.field === c.field
                                  ? sortInvited.direction
                                  : undefined,
                            }}
                          />
                        </Th>
                      ))}
                      <Th width="70px"></Th>
                    </ChakraTr>
                  </Thead>
                  <Tbody>
                    {invitedContractors?.map((row) => (
                      <Tr key={row.email} data={row} onClick={onSelectInvited}>
                        <Td textColor={"gray.700"}>{row?.name}</Td>
                        <Td textColor={"gray.700"}>{row.email}</Td>
                        <Td textColor={"gray.700"}>
                          {formatDateBasedOnLanguage(
                            row?.invitedOn as unknown as string,
                            true,
                          )}
                        </Td>
                        <Td width="70px">
                          {row.invitationToken && (
                            <Tooltip
                              label={t("renewInvitation", { ns: "settings" })}
                              aria-label={t("renewInvitation", {
                                ns: "settings",
                              })}
                              placement="bottom"
                            >
                              <span>
                                <ActionButton
                                  aria-label="send-reminder"
                                  icon={<FiSend />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setInvitationToken(row.invitationToken),
                                      setShowAskConfirmModal(true);
                                  }}
                                />
                              </span>
                            </Tooltip>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </InfiniteTable>
              </Flex>
            </TabPanel>
          }
        </TabPanels>
      </Tabs>

      {messageInvitation && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={
            messageInvitation === "success"
              ? t("renewalInvitationSuccess")
              : t(messageInvitation, { ns: "errors" })
          }
          variant="info"
          onClose={() => {
            setMessageInvitation(undefined);
          }}
        />
      )}
      {showAskConfirmModal && (
        <ConfirmAlert
          onCancel={() => setShowAskConfirmModal(false)}
          onConfirm={() => {
            renewInvitation(invitationToken), setShowAskConfirmModal(false);
          }}
          title={t("warning", { ns: "common" })}
          variant={"question"}
          message={t("reminderSupplier")}
        />
      )}
    </>
  );
}

export default WorkingSiteSubcontractTable;
