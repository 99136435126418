import UseCase from '../../../core/app/usecase';
import DocumentTypeRepository from '../../repositories/documentTypeRepository';

type Args = {
	companyId: string;
	records: { machineDocumentTypeId: string; vehicleDocumentTypeId: string }[];
};
/**
 * Returns the rename model object.
 */

class UpsertMachineVehicleDocumentTypeMapRecords implements UseCase<Args, void> {
	constructor(private repository: DocumentTypeRepository) {}

	async Call({ companyId, records }: Args): Promise<void> {
		await this.repository.upsertMachineVehicleDocumentTypeMapRecords(companyId, records);
	}
}

export default UpsertMachineVehicleDocumentTypeMapRecords;
