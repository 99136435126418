import UseCase from '../../../core/app/usecase';
import RequirementsGroupRepository from '../../repositories/requirementsGroupRepository';
import AddReqToGroupParameters from './addReqToGroupParameter';
import Requirement from '../../entities/requirement';

class AddRequirementToGroup implements UseCase<AddReqToGroupParameters, Requirement> {
	private requirementsGroupRepository: RequirementsGroupRepository;

	constructor(requirementsGroupRepository: RequirementsGroupRepository) {
		this.requirementsGroupRepository = requirementsGroupRepository;
	}

	async Call(parameters: AddReqToGroupParameters): Promise<Requirement> {
		return await this.requirementsGroupRepository.addRequirementToGroup(
			parameters.companyId,
			parameters.requirementGroupId,
			parameters.documents,
			parameters.requirementSubject,
			parameters.siteIds,
			parameters.selectAllSites,
			parameters.filters
		);
	}
}

export default AddRequirementToGroup;
