import UseCase from '../../../../core/app/usecase';
import SiteMachineRepository from '../../../repositories/siteMachineRepository';
import Evaluation from '../../../entities/evaluation';
import { ResourceEvaluationState } from '../../../entities/resourceEvaluationState.enum';

type Parameters = {
	companyId: string;
	siteId: string;
	machineId: string;
	value: ResourceEvaluationState;
};

class EvaluateSiteMachine implements UseCase<Parameters, Evaluation<ResourceEvaluationState> | undefined> {
	constructor(private repository: SiteMachineRepository) {}

	async Call({ companyId, siteId, machineId, value }: Parameters): Promise<Evaluation<ResourceEvaluationState> | undefined> {
		return this.repository.evaluateSiteMachine(companyId, siteId, machineId, value);
	}
}

export default EvaluateSiteMachine;
