import { useParams } from "react-router-dom";
import SupplierResourceView from "./SupplierResourceView";
import { useAuth } from "../../providers/Auth0JWTProvider";
import SupplierMachineViewModel from "../../viewmodels/suppliers/SupplierMachineViewModel";
import { SupplierDocumentsProps } from "../../components/Views/common/SupplierDocumentsTable";

const SupplierMachineDetailView = ({
  permissions,
}: {
  permissions: SupplierDocumentsProps["permissions"];
}) => {
  const { companyId } = useAuth();
  const { supplierId, resourceId } = useParams();

  const resourceColumns = ["name", "model", "plate"];

  return (
    <SupplierResourceView
      viewModel={
        new SupplierMachineViewModel(companyId, supplierId, resourceId)
      }
      columns={resourceColumns}
      permissions={permissions}
    />
  );
};
export default SupplierMachineDetailView;
