import UseCase from '../../../../core/app/usecase';
import { SiteVehicle } from '../../../entities/siteVehicle';
import SiteVehicleRepository, { GetSiteVehiclesFilters } from '../../../repositories/siteVehicleRepository';
import { PaginatedMeta, PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';

type Parameters = {
	companyId: string;
	siteId: string;
	archived?: boolean;
	filter?: GetSiteVehiclesFilters;
	sort?: SortMeta;
	supplierId?: string;
} & PaginatedMeta;

class GetSiteVehicles implements UseCase<Parameters, PaginatedResults<SiteVehicle> | undefined> {
	constructor(private repository: SiteVehicleRepository) {}

	async Call({
		companyId,
		siteId,
		sort,
		perPage,
		archived,
		filter,
		page,
		supplierId,
	}: Parameters): Promise<PaginatedResults<SiteVehicle> | undefined> {
		return this.repository.getSiteVehicles(companyId, siteId, page, perPage, archived, filter, sort, supplierId);
	}
}

export default GetSiteVehicles;
