import { makeGetRoles } from "../../../core/app/factory/roles/usecases";
import {
  makeGetUser,
  makeUpdateUser,
  makeUpdateUserImage,
} from "../../../core/app/factory/users/usecases";
import { UserNotification } from "../../../domain/entities/interfaces/userNotification";
import Role, { RoleContext } from "../../../domain/entities/role";
import User from "../../../domain/entities/user";
import { makePerformLogin } from "../../../domain/interactors/auth/performLogin";
import { makeUpdatePassword } from "../../../domain/interactors/auth/updatePassword";
import { makeGetUserNotificationsUseCase } from "../../../domain/interactors/notifications/getUserNotifications";
import { makeUpdateAlertNotificationUseCase } from "../../../domain/interactors/notifications/updateAlertNotification";
import { makeUpdateUserNotificationUseCase } from "../../../domain/interactors/notifications/updateUserNotification";
import {
  LoginResponse,
  UpdatePasswordResponse,
} from "../../../infrastructure/responses/auth";
import { UpdateAlertParams } from "../../hooks/Users/useUserSettingsViewModel";
import { makeGetUserEmails } from "../../../domain/interactors/users/getUserEmails";
import { makeRemoveEmail } from "../../../domain/interactors/users/removeUserEmail";
import { makeUpdateUserEmail } from "../../../domain/interactors/users/updateUserEmail";
import { makeCreateUserEmail } from "../../../domain/interactors/users/createUserEmail";
import { UserEmailsFilters } from "../../../domain/repositories/usersRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { UserEmail } from "../../../domain/entities/userEmail";

class UserSettingsViewModel {
  private updateUC = makeUpdateUser();
  private getUC = makeGetUser();
  private getRolesUC = makeGetRoles();
  private updateImageUC = makeUpdateUserImage();
  private uc = makeUpdatePassword();
  private getUserNotificationsUC = makeGetUserNotificationsUseCase();
  private updateUserNotificationUC = makeUpdateUserNotificationUseCase();
  private updateAlertNotificationUC = makeUpdateAlertNotificationUseCase();
  private loginUC = makePerformLogin();
  private getUserEmailsUC = makeGetUserEmails();
  private removeUserEmailUC = makeRemoveEmail();
  private updateUserEmailUC = makeUpdateUserEmail();
  private createUserEmailUC = makeCreateUserEmail();

  constructor(
    private companyId: string,
    private userId?: string,
  ) {}

  async update(user: User): Promise<User> {
    return await this.updateUC.Call({
      companyId: this.companyId,
      userId: this.userId,
      user,
    });
  }

  async get(): Promise<User> {
    return await this.getUC.Call({
      companyId: this.companyId,
      userId: this.userId,
    });
  }

  async getRoles(): Promise<Role[]> {
    const roles =
      (await this.getRolesUC.Call({
        companyId: this.companyId,
        filter: {
          contextFilter: RoleContext.GENERAL,
        },
      })) ?? [];

    return roles;
  }

  async updateUserImage(userId: string, image: File): Promise<User> {
    return await this.updateImageUC.Call({
      companyId: this.companyId,
      userId,
      image,
    });
  }

  async login(email: string, password: string): Promise<LoginResponse> {
    return this.loginUC.Call({ email, password });
  }

  async updatePassword(
    oldPassword: string,
    password: string,
    confirmPassword: string,
  ): Promise<UpdatePasswordResponse> {
    return this.uc.Call({ oldPassword, password, confirmPassword });
  }

  async getUserNotifications(
    companyId: string,
    userId: string,
  ): Promise<UserNotification[]> {
    return this.getUserNotificationsUC.Call({ companyId, userId });
  }

  async updateUserNotification(
    companyId: string,
    userId: string,
    notifications: UserNotification[],
  ): Promise<void> {
    return this.updateUserNotificationUC.Call({
      companyId,
      userId,
      notifications,
    });
  }

  async updateAlertNotification(
    companyId: string,
    userId: string,
    alert: UpdateAlertParams,
  ): Promise<void> {
    return this.updateAlertNotificationUC.Call({ companyId, userId, alert });
  }
  async getUserEmails(companyId: string, userId: string, filterEmails?: UserEmailsFilters, sort?: SortMeta, pageParam?: number): Promise<UserEmail[]>{
    return this.getUserEmailsUC.Call({ companyId, userId , filterEmails, sort, pageParam});
  }
  async createUserEmail(companyId: string, userId: string, email: UserEmail): Promise<UserEmail>{
    return this.createUserEmailUC.Call({ companyId, userId, email });
  }
  async removeUserEmail(companyId: string, userId: string, emailId): Promise<void>{
    return this.removeUserEmailUC.Call({ companyId, userId, emailId });
  }
  async updateUserEmail(companyId: string, userId: string, email: UserEmail): Promise<UserEmail>{
    return this.updateUserEmailUC.Call({ companyId, userId, email });
  }
}

export default UserSettingsViewModel;
