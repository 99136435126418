import UseCase from '../../../core/app/usecase';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import Typology from '../../entities/typology';
import TypologyRepository from '../../repositories/typologyRepository';

type ARGS = {
	companyId: string;
	type: DocumentTypeCategory;
	search?: string;
};

class GetTypologies implements UseCase<ARGS, Typology[]> {
	private typologyRepository: TypologyRepository;

	constructor(typologyRepository: TypologyRepository) {
		this.typologyRepository = typologyRepository;
	}

	async Call(args: ARGS): Promise<Typology[]> {
		return await this.typologyRepository.getTypologies(args.companyId, args.type, args.search);
	}
}

export default GetTypologies;
