import { Tooltip, Button, Icon } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { COLORS } from '../../assets/theme/colors';
import { FaWandMagicSparkles } from 'react-icons/fa6';

const opacityAnimation = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

const scaleAnimation = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
`;

const AiButton = ({onClick, tooltipMessage, isAnimated, isDisabled}: {onClick: () => void, tooltipMessage?: string, isAnimated?: boolean, isDisabled?: boolean}) => {
  const defaultBgColor = "#eece23";
  const defaultIconColor = "black";
  const disabledIconColor = "gray.100";
  const disabledBgColor = "#ccc27f";
  const defaultHighlightColor = isDisabled ? "transparent" : COLORS.sikuroBlue;

  return (
    <Tooltip label={tooltipMessage} aria-label={tooltipMessage}>
        <Button
          fontSize="2xl"
          width="30px"
          borderRadius="10px"
          p={1}
          onClick={onClick}
          aria-label="ai-button"
          isDisabled={isDisabled}
          backgroundColor={defaultBgColor}
          animation={isAnimated ? `${opacityAnimation} 2s ease-in-out infinite` : "none"}
          border="3px solid transparent"
          _hover={{
            border: isDisabled ? "3px solid transparent" : `3px solid ${COLORS.sikuroBlue}`,
          }}
        >
            <Icon
              as={FaWandMagicSparkles}
              boxSize={5}
              color={defaultIconColor}
              animation={isAnimated ? `${scaleAnimation} 2s ease-in-out infinite` : "none"}
            />
        </Button>
    </Tooltip>
  );
};

export default AiButton;