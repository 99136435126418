import { makeGetCompanies } from "../../../core/app/factory/companies/usecases";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetTenantsFilters } from "../../components/Views/common/ChangeTenantModal";

export class CompaniesViewModel {
  private companiesUC = makeGetCompanies();

  async getCompanies(
    filter?: GetTenantsFilters,
    sort?: SortMeta,
    pageParam?: number,
  ) {
    return await this.companiesUC.Call({ filter, sort, pageParam });
  }
}
