import { useTranslation } from "react-i18next";
import BaseModal from "../../Common/alerts/BaseModal";
import React, { useState } from "react";
import Badge from "../../../../domain/entities/badge";
import { BadgeType } from "../../../../domain/entities/badgeType.enum";
import Tag from "../../../../domain/entities/tag";
import { BadgeStatus } from "../../../../domain/entities/badgeStatus.enum";
import {
  Box,
  Grid,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
} from "@chakra-ui/react";
import TextInput from "../../Common/TextInput";
import InputControl from "../../Common/InputControl";
import MultiTagSelect from "../../Common/TagSelect/MultiTagSelect";

type CreateBadgeModalProps = {
  onClose: () => void;
  onConfirm: (badge: Badge) => void;
  allTags: Tag[];
  isLoading: boolean;
  createMultipleBadgeQR: (value: number, selectedTags: Tag[]) => void;
};
const CreateBadgeModal = ({
  onClose,
  onConfirm,
  allTags,
  isLoading,
  createMultipleBadgeQR,
}: CreateBadgeModalProps) => {
  const { t } = useTranslation("badges");
  const [type, setType] = useState(BadgeType.NFC);
  const [serial, setSerial] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [nfcCode, setNfcCode] = useState<string>("");
  const [nfcCodeError, setNfcCodeError] = useState<string>("");
  const [quantityBadgeQR, setQuantityBadgeQR] = useState<number>(1);

  const validNfcCode = /^[a-zA-Z0-9]{14}$/;

  const handleChangeCode = (value: string) => {
    setError(null);
    setSerial(value);
  };

  const handleChangeNfcCode = (value: string) => {
    setNfcCode(value);
  };

  const createTag = (tagName: string) => {
    setSelectedTags([
      ...selectedTags,
      { id: tagName, name: tagName, color: "#CCC" },
    ]);
  };

  const handleChangeQuantity = (value: string) => {
    setQuantityBadgeQR(Number(value));
    if(Number(value) > 500) {
      setError(t('notGraterThan500'))
    } else {
      setError(null)
    }
  };

  const handleCreate = async () => {
    if (type.includes("multiple generation")) {
      if (!quantityBadgeQR) {
        setError(t("quantityBadgeQRRequired"));
        throw new Error("quantityBadgeQRRequired");
      } else {
        createMultipleBadgeQR(quantityBadgeQR, selectedTags);
      }
    } else {
      if (!serial) {
        setError(t("codeRequired"));
        throw new Error("Serial is required");
      } else {
        if (!validNfcCode.test(serial) && type === BadgeType.NFC) {
          setError(t("badgeNumberNfcRule"));
          throw new Error(t("badgeNumberNfcRule"));
        } else {
          if (!nfcCode && type === BadgeType.NFC) {
            setNfcCodeError(t("serialRequired"));
            throw new Error("Badge Code is required");
          } else {
            await onConfirm({
              id: "",
              status: BadgeStatus.BLOCKED,
              code: nfcCode,
              type,
              tags: selectedTags,
              serial: serial,
            });
          }
        }
      }
    }
  };

  return (
    <BaseModal
      title={t("createTitle")}
      onClose={() => onClose()}
      onConfirm={handleCreate}
      onConfirmDisabled={Boolean(error)}
      size={"md"}
      isLoading={isLoading}
      closeOnOverlayClick={false}
    >
      <Box>
        <Grid templateColumns="repeat(1fr)" gap={8}>
          <InputControl label={t("badgeType")}>
            <Select
              value={type}
              onChange={(e) => {
                setType(e.target.value as BadgeType), setSerial("");
              }}
            >
              <option value={BadgeType.NFC}>{t(BadgeType.NFC)}</option>
              <option
                value={`${BadgeType.QR}-multiple generation`}
              >{`${t(BadgeType.QR)}`}</option>
            </Select>
          </InputControl>
          {!type.includes("multiple generation") && (
            <InputControl
              isInvalid={Boolean(error)}
              isRequired
              label={t("badgeNumber")}
              error={error}
              tooltip={
                type === BadgeType.NFC
                  ? t("badgeNumberNfcRule")
                  : t("badgeNumberQrCodeRule")
              }
            >
              <TextInput
                value={serial}
                onChange={handleChangeCode}
                disableClear={true}
              />
            </InputControl>
          )}
          {type === BadgeType.NFC && (
            <InputControl
              isInvalid={Boolean(nfcCodeError)}
              isRequired
              label={t("serial")}
              error={nfcCodeError}
            >
              <NumberInput
                value={nfcCode}
                onChange={handleChangeNfcCode}
                min={0}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </InputControl>
          )}
          {type.includes("multiple generation") && (
            <InputControl
              isInvalid={Boolean(error)}
              isRequired
              label={t("badgeQuantity")}
              error={error}
            >
              <NumberInput
                value={quantityBadgeQR}
                onChange={handleChangeQuantity}
                min={1}
                max={500}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </InputControl>
          )}
          <Box>
            <MultiTagSelect
              tags={allTags}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              createTag={createTag}
              label={t("Tag")}
              placeholder={t("tagPlaceholder", { ns: "documents" })}
            />
          </Box>
        </Grid>
      </Box>
    </BaseModal>
  );
};

export default CreateBadgeModal;
