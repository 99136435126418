import ActionButton, { ActionButtonProps } from "./ActionButton";
import { Badge, Icon } from "@chakra-ui/react";
import { AiOutlineSearch } from "react-icons/ai";

const SeeFileButton = ({
  onClick,
  filesCount = 0,
}: ActionButtonProps & { filesCount?: number }) => (
  <ActionButton
    aria-label="add file"
    onClick={onClick}
    icon={
      <div style={{ position: "relative" }}>
        {filesCount > 0 && (
          <Badge
            bg="rgba(50, 162, 219, .8)"
            color="white"
            position="relative"
            top="4px"
            right="-5px"
          >
            {filesCount}
          </Badge>
        )}
        <Icon as={AiOutlineSearch} fontSize="22px" />
      </div>
    }
  />
);
export default SeeFileButton;
