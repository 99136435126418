import UseCase from '../../../../core/app/usecase';
import SiteVehicleRepository from '../../../repositories/siteVehicleRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	vehicleId: string;
	specializationIds?: string[];
};

class UpdateSiteVehicleSpecializations implements UseCase<Parameters, void | undefined> {
	constructor(private repository: SiteVehicleRepository) {}

	async Call({ companyId, siteId, vehicleId, specializationIds }: Parameters): Promise<void | undefined> {
		return this.repository.updateSiteVehicleSpecializations(companyId, siteId, vehicleId, specializationIds);
	}
}

export default UpdateSiteVehicleSpecializations;
