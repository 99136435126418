import CreateAuditNote from '../../../../domain/interactors/audit/createAuditNote';
import { makeAuditsRepository } from './repositories';
import GetAudits from '../../../../domain/interactors/audit/getAudits';
import CreateAudit from '../../../../domain/interactors/audit/createAudit';
import DeleteAudit from '../../../../domain/interactors/audit/deleteAudit';
import GetAuditNote from '../../../../domain/interactors/audit/getAuditNotes';
import UpdateAuditNote from '../../../../domain/interactors/audit/updateAuditNote';
import GetSelectableSuppliers from '../../../../domain/interactors/audit/getSelectableSuppliers';

export const makeGetAudits = () => {
	return new GetAudits(makeAuditsRepository());
};

export const makeCreateAudit = () => {
	return new CreateAudit(makeAuditsRepository());
};

export const makeDeleteAudit = () => {
	return new DeleteAudit(makeAuditsRepository());
};

export const makeGetAuditNotes = () => {
	return new GetAuditNote(makeAuditsRepository());
};

export const makeCreateAuditNote = () => {
	return new CreateAuditNote(makeAuditsRepository());
};

export const makeUpdateAuditNote = () => {
	return new UpdateAuditNote(makeAuditsRepository());
};

export const makeGetSelectableSuppliers = () => {
	return new GetSelectableSuppliers(makeAuditsRepository());
};
