import Site from '../../entities/site';
import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';

interface Args {
	companyId: string;
	image?: File;
	site: Site;
}

class UpdateSite implements UseCase<Args, Site> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, site, image }: Args): Promise<Site> {
		return await this.siteRepository.updateSite(companyId, site, image);
	}
}

export default UpdateSite;
