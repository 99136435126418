import UseCase from '../../../../core/app/usecase';
import SiteWorkerRepository from '../../../repositories/siteWorkerRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	workerId: string;
	variantId?: string;
};

class UpdateSiteWorkerVariant implements UseCase<Parameters, void | undefined> {
	constructor(private repository: SiteWorkerRepository) {}

	async Call({ companyId, siteId, workerId, variantId }: Parameters): Promise<void | undefined> {
		return this.repository.updateSiteWorkerVariant(companyId, siteId, workerId, variantId);
	}
}

export default UpdateSiteWorkerVariant;
