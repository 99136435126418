import UseCase from '../../../../core/app/usecase';
import { WorkerStatus } from '../../../entities/worker';
import WorkerRepository from '../../../repositories/workerRepository';

type Args = {
	companyId: string;
	workerId: string;
	status: WorkerStatus;
};

class UpdateWorkerStatus implements UseCase<Args, void> {
	constructor(private repository: WorkerRepository) {}

	async Call({ companyId, workerId, status }: Args): Promise<void> {
		return await this.repository.updateWorkerStatus(companyId, workerId, status);
	}
}

export default UpdateWorkerStatus;
