import { Dispatch, FC, SetStateAction } from "react";
import Site from "../../../../domain/entities/site";
import { SiteWorker } from "../../../../domain/entities/siteWorker";
import { SiteChemical } from "../../../../domain/entities/siteChemical";
import { SiteMachine } from "../../../../domain/entities/siteMachine";
import { SiteVehicle } from "../../../../domain/entities/siteVehicle";
import { SiteTool } from "../../../../domain/entities/siteTool";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { GetSiteResourcesAuditFilters } from "../../../hooks/Site/useSiteAuditsViewModel";
import { Box, Select } from "@chakra-ui/react";
import { AuditAccordion } from "../../../screens/Site/AuditAccordion";
import { useTranslation } from "react-i18next";
import { ExportItem } from "./ExportDialogData";
import DocumentType from "../../../../domain/entities/documentType";
import Supplier from "../../../../domain/entities/supplier";


interface ExportProps {
    selectedSiteId: string;
    handleFilters: (value: any) => void;
    sitesList?: Site[];
    suppliersList?: Supplier[];
    isWorkingSite?: boolean;

    resourceAccordionTitle: string;
    resourceList?:
    (SiteWorker[]
    | SiteChemical[]
    | SiteMachine[]
    | SiteVehicle[]
    | SiteTool[]);
    resourcesAreLoading?: boolean;
    includedResource?: string[];
    setIncludedResource?: (resourceIds: string[]) => void;
    setSiteResourcesSort?: Dispatch<SetStateAction<SortMeta>>;
    siteResourcesSort?: SortMeta;
    setSiteResourceFilter?: Dispatch<SetStateAction<GetSiteResourcesAuditFilters>>;
    siteResourceFilter?: GetSiteResourcesAuditFilters;
    resourcesHasNextPage: boolean;
    resourcesFetchNextPage: () => void;
    selectedExportItem: ExportItem;

    requirements: DocumentType[];
    requirementsAccordionTitle: string;
    requirementsHasNextPage: boolean;
    requirementsFetchNextPage: () => void;
    requirementsAreLoading: boolean;
    requirementsToExclude: string[];
    setRequirementsToExlude: (requirementIds: string[]) => void;
}

const ExportResourceRequirementsView: FC<ExportProps> = ({
    selectedSiteId,
    handleFilters,
    sitesList,
    suppliersList,
    isWorkingSite,

    resourceAccordionTitle,
    resourceList,
    resourcesAreLoading,
    includedResource,
    setIncludedResource,
    setSiteResourcesSort,
    siteResourcesSort,
    setSiteResourceFilter,
    siteResourceFilter,
    resourcesHasNextPage,
    resourcesFetchNextPage,
    selectedExportItem,

    requirementsAccordionTitle,
    requirements,
    requirementsHasNextPage,
    requirementsFetchNextPage,
    requirementsAreLoading,
    requirementsToExclude,
    setRequirementsToExlude,
}) => {

    const { t } = useTranslation("common");

    return (
        <Box paddingRight={"25"}>
          <Box my={4}>
            {!isWorkingSite &&
            <Select onChange={handleFilters} value={selectedSiteId}>
              <option value={""}>{t("selectSite")}</option>
              {sitesList &&
                Array.isArray(sitesList) &&
                sitesList.map((item) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
            </Select>
            }
            {isWorkingSite &&
              <Box my={4}>
                <Select onChange={handleFilters} value={selectedSiteId}>
                  <option value={""}>{t("selectSite", { ns: "common" })}</option>
                  {suppliersList &&
                    Array.isArray(suppliersList) &&
                    suppliersList.map((item) => (
                      <option key={item?.site.id} value={item?.site.id}>
                        {item?.site.name}
                      </option>
                    ))}
                </Select>
              </Box>
            }
          </Box>
          <Box my={4}>
            <AuditAccordion
              resourceList={resourceList}
              hasNextPage={resourcesHasNextPage}
              fetchNextPage={resourcesFetchNextPage}
              resourcesAreLoading={resourcesAreLoading}
              includedResource={includedResource}
              setIncludedResource={setIncludedResource}
              setSiteResourceFilter={setSiteResourceFilter}
              siteResourceFilter={siteResourceFilter}
              resourceType={selectedExportItem?.params?.[0]?.resourceType}
              title={resourceAccordionTitle}
              type="resource"
            />
          </Box>

          <Box my={4}>
            <AuditAccordion
              documentTypeList={requirements}
              hasNextPage={requirementsHasNextPage}
              fetchNextPage={requirementsFetchNextPage}
              requirementsAreLoading={
                requirementsAreLoading
              }
              excludedDocumentTypes={requirementsToExclude}
              setExcludedDocumentTypes={
                setRequirementsToExlude
              }
              title={requirementsAccordionTitle}
              type="requirement"
            />
          </Box>
        </Box>
      );

}

export default ExportResourceRequirementsView;