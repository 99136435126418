import UseCase from '../../../../core/app/usecase';
import SiteMachineRepository from '../../../repositories/siteMachineRepository';

type Parameters = {
	companyId: string;
	siteId: string;
	machineId: string;
	specializationIds?: string[];
};

class UpdateSiteMachineSpecializations implements UseCase<Parameters, void | undefined> {
	constructor(private repository: SiteMachineRepository) {}

	async Call({ companyId, siteId, machineId, specializationIds }: Parameters): Promise<void | undefined> {
		return this.repository.updateSiteMachineSpecializations(companyId, siteId, machineId, specializationIds);
	}
}

export default UpdateSiteMachineSpecializations;
