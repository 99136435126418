import LinkBadgeReaderToSites from '../../../../domain/interactors/badgeReaders/LinkBadgeReaderToSites';
import UnlinkBadgeReader from '../../../../domain/interactors/badgeReaders/unlinkBadgeReader';
import GetBadgeReadersSites from '../../../../domain/interactors/badgeReaders/getSiteBadgeReadersSites';
import GetBadgeReaderDetail from '../../../../domain/interactors/badgeReaders/getBadgeReaderDetail';
import GetBadgeReaderHistory from '../../../../domain/interactors/badgeReaders/getBadgeReaderHistory';
import GetBadgeReaders from '../../../../domain/interactors/badgeReaders/getSiteBadgeReaders';
import { makeBadgeReaderRepository } from './repositories';
import CreateBadgeReader from '../../../../domain/interactors/badgeReaders/createBadgeReader';
import GetBadgeReadersNotPaginated from '../../../../domain/interactors/badgeReaders/getSiteBadgeReadersNotPaginated';
import DeleteBadgeReaderLogEntry from '../../../../domain/interactors/badgeReaders/deleteBadgeReaderLogEntry';
import CreateBadgeReaderLogEntry from '../../../../domain/interactors/badgeReaders/createBadgeReaderLogEntry';
import UpdateBadgeReaderLogEntry from '../../../../domain/interactors/badgeReaders/updateBadgeReaderLogEntry';
import GetVirtualBadgeReaderAvailableUsers from '../../../../domain/interactors/badgeReaders/getVirtualBadgeReaderAvailableUsers';
import UpdateBadgeReaderStatus from '../../../../domain/interactors/badgeReaders/updateBadgeReaderStatus';
import UpdateBadgeReader from '../../../../domain/interactors/badgeReaders/updateBadgeReader';
import DeleteBadgeReader from '../../../../domain/interactors/badgeReaders/deleteBadgeReader';

export const makeGetBadgeReaders = () => {
	return new GetBadgeReaders(makeBadgeReaderRepository());
};
export const makeGetBadgeReadersSites = () => {
	return new GetBadgeReadersSites(makeBadgeReaderRepository());
};
export const makeGetBadgeReadersNotPaginated = () => {
	return new GetBadgeReadersNotPaginated(makeBadgeReaderRepository());
};

export const makeGetBadgeReaderDetail = () => {
	return new GetBadgeReaderDetail(makeBadgeReaderRepository());
};

export const makeGetBadgeReaderHistory = () => {
	return new GetBadgeReaderHistory(makeBadgeReaderRepository());
};

export const makeUpdateBadgeReaderStatus = () => {
	return new UpdateBadgeReaderStatus(makeBadgeReaderRepository());
};

export const makeGetBadgeReaderVitualAvailableUsers = () => {
	return new GetVirtualBadgeReaderAvailableUsers(makeBadgeReaderRepository());
};

export const makeCreateBadgeReader = () => {
	return new CreateBadgeReader(makeBadgeReaderRepository());
};

export const makeCreateBadgeReaderLogEntry = () => {
	return new CreateBadgeReaderLogEntry(makeBadgeReaderRepository());
};

export const makeDeleteBadgeReader = () => {
	return new DeleteBadgeReader(makeBadgeReaderRepository());
};

export const makeUnlinkBadgeReader = () => {
	return new UnlinkBadgeReader(makeBadgeReaderRepository());
};

export const makeUpdateBadgeReaderLogEntry = () => {
	return new UpdateBadgeReaderLogEntry(makeBadgeReaderRepository());
};

export const makeDeleteBadgeReaderLogEntry = () => {
	return new DeleteBadgeReaderLogEntry(makeBadgeReaderRepository());
};
export const makeUpdateBadgeReader = () => {
	return new UpdateBadgeReader(makeBadgeReaderRepository());
};
export const makeLinkBadgeReaderToSites = () => {
	return new LinkBadgeReaderToSites(makeBadgeReaderRepository());
};
