import UseCase from '../../../core/app/usecase';
import SiteRepository from '../../repositories/siteRepository';
import SiteOptions, { ScheduleEmail } from '../../entities/siteOptions';

interface Args {
	companyId: string;
	siteId: string;
	options: ScheduleEmail;
}

class UpdateScheduledEmail implements UseCase<Args, void> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, siteId, options }: Args): Promise<void> {
		return await this.siteRepository.updateScheduleEmail(companyId, siteId, options);
	}
}

export default UpdateScheduledEmail;
