import MainNavBar from "../../components/Common/MainNavBar";
import ResourcesTopBar from "../../components/Views/Resources/ResourcesTopBar";

import MainLayout from "../../layout/MainLayout";
import { useEffect } from "react";
import { useApp } from "../../../app";
import { useAuth } from "../../providers/Auth0JWTProvider";
const ResourcesMainView = () => {
  const { updateContext } = useApp();
  const { updateSiteContext } = useAuth();

  useEffect(() => {
    updateContext({ topBarTitle: undefined, site: undefined });
    updateSiteContext(undefined);
  }, []);

  return <MainLayout header={<ResourcesTopBar />} nav={<MainNavBar />} />;
};

export default ResourcesMainView;
