import UseCase from '../../../core/app/usecase';
import SupplierRepository from '../../repositories/supplierRepository';
import { ResourceDocumentEvaluationState } from '../../entities/resourceDocumentEvaluationState.enum';
import { RequirementSubject } from '../../entities/requirementSubject.enum';
import { GetSitesFilter } from "../../repositories/siteRepository";

type Parameters = {
	companyId: string;
	supplierId: string;
	resourceId: string;
	type: RequirementSubject;
	requirementId: string;
	siteIds: string[];
	selectAll?: boolean;
};

class RepropagateEvaluationCompanySupplierResourceRequirement implements UseCase<Parameters, void> {
	constructor(private repository: SupplierRepository) {}

	async Call({ companyId, supplierId, resourceId, type, requirementId,siteIds, selectAll }: Parameters): Promise<void> {
		await this.repository.repropagateEvaluationCompanySupplierResourceRequirement(
			companyId,
			supplierId,
			resourceId,
			type,
			requirementId,
			siteIds,
			selectAll
		);
	}
}

export default RepropagateEvaluationCompanySupplierResourceRequirement;
