import { injectable } from 'tsyringe';
import { Notification } from '../../domain/entities/notification';
import { PaginatedResults } from '../../domain/entities/interfaces/paginatedResults';
import NotificationsRepository from '../../domain/repositories/notificationsRepository';
import { ApiService } from '../utilities/apiService';
import { UserNotification } from '../../domain/entities/interfaces/userNotification';
import { UpdateAlertParams } from '../../presentation/hooks/Users/useUserSettingsViewModel';

@injectable()
export class ServerNotificationsRepository implements NotificationsRepository {
	constructor(private apiService: ApiService) {}

	async listNew(companyId: string, page: number, perPage: number): Promise<PaginatedResults<Notification>> {
		const url = this.baseURL(companyId, `/notifications`, `status=unread&page=${page}&perPage=${perPage}`);
		return this.send(url, 'GET');
	}

	async listArchived(companyId: string, page: number, perPage: number): Promise<PaginatedResults<Notification>> {
		const url = this.baseURL(companyId, `/notifications`, `status=archived&page=${page}&perPage=${perPage}`);
		return this.send(url, 'GET');
	}

	async read(companyId: string, notificationId: string, status: string): Promise<void> {
		await this.apiService.fetchWithToken(await this.baseURL(companyId, `/notifications/${notificationId}`), {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ status }),
		});
	}

	async getCount(companyId: string): Promise<number> {
		const url = this.baseURL(companyId, `/notifications/count-unread`);
		return this.send(url, 'GET');
	}

	async delete(companyId: string, notificationId: string): Promise<string> {
		const url = this.baseURL(companyId, `/notifications/${notificationId}`);

		try {
			const response = await this.apiService.fetchWithToken(url, { method: 'DELETE' });
			return !response.ok ? Promise.reject(this.fail(response.status)) : Promise.resolve(notificationId);
		} catch {
			return Promise.reject(this.fail(422));
		}
	}

	async getUserNotifications(companyId: string, userId: string): Promise<UserNotification[]> {
		const response = await this.apiService.fetchWithToken(this.baseURL(companyId, `/notifications/users/${userId}/settings`));
		const { results } = await response.json();
		return results ?? [];
	}

	async updateUserNotification(companyId: string, userId: string, notifications: UserNotification[]): Promise<void> {
		await this.apiService.fetchWithToken(this.baseURL(companyId, `/notifications/users/${userId}/settings`), {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ settings: notifications }),
		});
	}

	async updateAlertNotification(companyId: string, userId: string, alert: UpdateAlertParams): Promise<void> {
		try {
			const response = await this.apiService.fetchWithToken(this.baseURL(companyId, `/notifications/users/${userId}/alert-options`), {
				method: 'PATCH',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					expiringDocumentsDaysBeforeAlert: alert.expiringDocumentsDaysBeforeAlert,
					expiringOwnedSitesDaysBeforeAlert: alert.expiringOwnedSitesDaysBeforeAlert,
					expiringWorkingSitesDaysBeforeAlert: alert.expiringWorkingSitesDaysBeforeAlert,
				}),
			});

			if (!response.ok) {
				let errorMessage = '';

				if (response.status === 500) {
					errorMessage = 'serverError';
				} else if (response.status === 404) {
					errorMessage = 'serverError';
				} else if (response.status === 422) {
					errorMessage = 'notGreatherThen';
				} else {
					errorMessage = 'generic';
				}
				throw new Error(errorMessage);
			}
		} catch (error) {
			if (error instanceof Error) {
				throw error;
			}
			throw new Error('An unexpected error occurred.');
		}
	}

	// Private methods.
	private baseURL = (companyId: string, path: string, query?: string): string => {
		const baseURL = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/`;
		path = path.startsWith('/') ? path.substring(1) : path;
		path = query ? `${path}?${query}` : path;

		return `${baseURL}${path}`;
	};

	/**
	 * Sends a HTTP request.
	 *
	 * @param url the route URL
	 * @param method the request method
	 * @param expect the expected HTTP status code
	 * @param body the optional payload body
	 * @returns
	 */
	private async send<T>(url: string, method: string, body?: any): Promise<T> {
		try {
			const requestOptions = body
				? {
						method: method,
						headers: { 'Content-Type': 'application/json' },
						body: body ? JSON.stringify(body) : '',
					}
				: {
						method: method,
						headers: { 'Content-Type': 'application/json' },
					};

			const response = await this.apiService.fetchWithToken(url, requestOptions);

			if (!response.ok) {
				return Promise.reject(this.fail(response.status));
			}

			const payload: T = await response.json();
			return payload;
		} catch (e) {
			return Promise.reject(this.fail(422));
		}
	}

	private fail(httpStatus: number): Error {
		switch (httpStatus) {
			case 403:
				return new Error('error.owner-mismatch');
			case 404:
				return new Error('error.not-found');
			default:
				// Covers error.repository and other errors.
				return new Error('error.any');
		}
	}
}
