import UseCase from '../../../core/app/usecase';
import BadgeReader from '../../entities/badgeReader';
import BadgeReaderRepository from '../../repositories/badgeReaderRepository';

type Args = {
	siteId: string;
	companyId: string;
	badgeReaderId: string;
};

class GetBadgeReaderDetail implements UseCase<Args, BadgeReader> {
	constructor(private badgeReaderRepository: BadgeReaderRepository) {}

	async Call({ companyId, siteId, badgeReaderId }: Args): Promise<BadgeReader> {
		return await this.badgeReaderRepository.getBadgeReaderDetail(companyId, siteId, badgeReaderId);
	}
}

export default GetBadgeReaderDetail;
