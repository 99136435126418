import { Box, Button, Flex, Image, Text, Link } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import logo from "../../assets/images/logoSikuro.png";
import { COLORS } from "../assets/theme/colors";

interface Props {
  onClose: () => void;
  url: string;
}

export const PrivacyPolicy = ({ onClose, url }: Props) => {
  const { t } = useTranslation("privacyPolicy");

  return (
    <Box p={4}>
      <Box textAlign={"center"}>
        <Text fontSize="3xl" textTransform={"uppercase"} fontWeight={"bold"}>
          {t("suiteSikuro")}
        </Text>
        <Text fontSize="3xl" fontWeight={"bold"}>
          {t("law")}
        </Text>
      </Box>
      <Box p={8}>
        <Text fontSize="lg">{t("info")}</Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("dataControllerTitle")}
        </Text>
        <Flex direction={"column"}>
          <Text fontSize="lg">{t("dataControllerDesc")} </Text>
          <Text textDecoration={"underline"} color={COLORS.sikuroBlue}>
            info@sikuro.it
          </Text>
        </Flex>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("dataProtectionTitle")}
        </Text>
        <Text fontSize="lg">{t("dataProtectionDesc")} </Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("dataTypeTitle")}
        </Text>
        <Text fontSize="lg">
          {" "}
          <div
            dangerouslySetInnerHTML={{
              __html: t("dataTypeDesc"),
            }}
          />
        </Text>

        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("browsingDataTitle")}
        </Text>
        <Text fontSize="lg">
          <div
            dangerouslySetInnerHTML={{
              __html: t("browsingDataDesc"),
            }}
          />
        </Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("purposeProcessingTitle")}
        </Text>
        <Text fontSize="lg">
          <div
            dangerouslySetInnerHTML={{
              __html: t("purposeProcessingDesc"),
            }}
          />
        </Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("legalBasicTitle")}
        </Text>
        <Text fontSize="lg">{t("legalBasicDesc")} </Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("communicationScopeTitle")}
        </Text>
        <Text fontSize="lg">
          <div
            dangerouslySetInnerHTML={{
              __html: t("communicationScopeDesc"),
            }}
          />
        </Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("natureOfDataTitle")}
        </Text>
        <Text fontSize="lg">{t("natureOfDataDesc")}</Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("transferAbroadTitle")}
        </Text>
        <Text fontSize="lg">{t("transferAbroadDesc")}</Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("retentionPeriodTitle")}
        </Text>
        <Text fontSize="lg">
          <div
            dangerouslySetInnerHTML={{
              __html: t("retentionPeriodDesc"),
            }}
          />
        </Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("claimsTitle")}
        </Text>
        <Text fontSize="lg">
          <div
            dangerouslySetInnerHTML={{
              __html: t("claimsDesc"),
            }}
          />
        </Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("waysOfRightTitle")}
        </Text>
        <Box flexDirection={"row"}>
          <Text fontSize="lg">{t("waysOfRightDesc")} </Text>
          <Text
            textDecoration={"underline"}
            color={COLORS.sikuroBlue}
            fontWeight={"bold"}
          >
            privacy@sikuro.eu
          </Text>
        </Box>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("complaintTitle")}
        </Text>
        <Text fontSize="lg">
          <div
            dangerouslySetInnerHTML={{
              __html: t("complaintDesc"),
            }}
          />
        </Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("dataSourceTitle")}
        </Text>
        <Text fontSize="lg">{t("dataSourceDesc")}</Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4} ml={4}>
          {t("cookiesTitle")}
        </Text>
        <Text fontSize="lg">
          <div
            dangerouslySetInnerHTML={{
              __html: t("cookiesDesc"),
            }}
          />
        </Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4}>
          {t("requiredCookiesTitle")}
        </Text>
        <Text fontSize="lg">{t("requiredCookiesDesc")}</Text>
        <Text fontSize="lg" fontWeight={"bold"} mt={4}>
          {t("performanceCookiesTitle")}
        </Text>
        <Text fontSize="lg">{t("performanceCookiesDesc")}</Text>
        <Text fontSize="xl" mt={4}>
          <Trans i18nKey="downloadPdf" ns="translation">
            <a href={url} style={{ color: COLORS.sikuroBlue }}>
              Clicca qui
            </a>{" "}
            per scaricare il pdf.
          </Trans>
        </Text>
      </Box>
      <Flex justifyContent={"flex-end"}>
        <Button
          sx={{ color: "white", background: COLORS.sikuroBlue }}
          onClick={onClose}
        >
          {t("confirm", { ns: "common" })}
        </Button>
      </Flex>
    </Box>
  );
};

export const PrivacyPolicyHeader = () => {
  const { t } = useTranslation("privacyPolicy");
  return (
    <Flex width={"100%"} justifyContent="space-between">
      <Box>
        <Image boxSize="100px" objectFit="cover" src={logo} />
      </Box>
      <Box textAlign={"center"}>
        <Text fontSize="2xl" textTransform={"uppercase"} fontWeight={"bold"}>
          {t("privacyProcedure")}
        </Text>
        <Text fontSize="xl" textTransform={"uppercase"}>
          {t("privacyPolicy")}
        </Text>
      </Box>
      <Box>
        <Text fontSize="xl" fontWeight={"bold"}>
          {t("prd")}
        </Text>
        <Flex fontSize="xl" direction={"row"}>
          <Text fontWeight={"bold"}>{t("rev")} </Text>
          <Text ml={2}>03</Text>
        </Flex>

        <Flex fontSize="xl" direction={"row"}>
          <Text fontWeight={"bold"}>{t("date")} </Text>
          <Text ml={2}> 30/01/2022</Text>
        </Flex>
      </Box>
    </Flex>
  );
};
