import {
  makeCreateResourceDocumentComment,
  makeCreateResourceDocumentNote,
  makeGetNotificationsStatus,
  makeGetResourceDocumentComments,
  makeGetResourceDocumentNote,
  makeGetTaggableDocuments,
  makeGetTaggableUsers,
  makeUpdatetNotificationsStatus,
} from "../../../core/app/factory/documents/usecases";
import DocumentComment from "../../../domain/entities/documentComment";
import DocumentNote from "../../../domain/entities/documentNote";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { UserMini } from "../../../domain/entities/user";

class DocumentCommunicationViewModel {
  async getResourceDocumentNotes(
    companyId: string,
    resourceId: string,
    resourceType: DocumentTypeCategory,
    siteId?: string,
  ): Promise<DocumentNote> {
    return await makeGetResourceDocumentNote().Call({
      companyId,
      siteId,
      resourceId,
      resourceType,
    });
  }

  async createResourceDocumentNote(
    companyId: string,
    resourceId: string,
    resourceType: DocumentTypeCategory,
    note: string,
    siteId?: string,
  ): Promise<void> {
    return await makeCreateResourceDocumentNote().Call({
      companyId,
      siteId,
      resourceId,
      resourceType,
      note,
    });
  }

  async getResourceDocumentComments(
    companyId: string,
    resourceId: string,
    resourceType: DocumentTypeCategory,
    siteId?: string,
  ): Promise<DocumentComment[]> {
    return await makeGetResourceDocumentComments().Call({
      companyId,
      siteId,
      resourceId,
      resourceType,
    });
  }

  async createResourceDocumentComment(
    companyId: string,
    resourceId: string,
    resourceType: DocumentTypeCategory,
    comment: DocumentComment,
    siteId?: string,
  ): Promise<void> {
    return await makeCreateResourceDocumentComment().Call({
      companyId,
      siteId,
      resourceId,
      resourceType,
      comment,
    });
  }

  async getTaggableUsers(
    companyId: string,
    siteId?: string,
    resourceId?: string,
    resourceType?: DocumentTypeCategory,
  ): Promise<UserMini[]> {
    return await makeGetTaggableUsers().Call({
      companyId,
      siteId,
      resourceId,
      resourceType,
    });
  }
  async getTaggableDocuments(
    companyId: string,
    resourceType: string,
    resourceId: string,
    pageParam: number,
    siteId?: string,
  ): Promise<{ id: string; name: string }[]> {
    return await makeGetTaggableDocuments().Call({
      companyId,
      resourceType,
      resourceId,
      pageParam,
      siteId,
    });
  }
  async getNotificationsStatus(
    companyId: string,
    resourceType: string,
    resourceId: string,
    siteId?: string,
  ): Promise<{ status: boolean }> {
    return await makeGetNotificationsStatus().Call({
      companyId,
      siteId,
      resourceType,
      resourceId,
    });
  }
  async updateNotificationsStatus(
    companyId: string,
    resourceType: string,
    resourceId: string,
    status: boolean,
    siteId?: string,
  ): Promise<void> {
    return await makeUpdatetNotificationsStatus().Call({
      companyId,
      siteId,
      resourceType,
      resourceId,
      status,
    });
  }
}

export default DocumentCommunicationViewModel;
