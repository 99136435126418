import { ResourceSites } from '../../entities/site';
import UseCase from '../../../core/app/usecase';
import SiteRepository, { GetResourceSitesFilter } from '../../repositories/siteRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';

interface Args {
	companyId: string;
	machineId: string;
	filter?: GetResourceSitesFilter;
	sort?: SortMeta;
	pageParam?: number;
	includeWorkingSites?: boolean;
	ignoreUserSiteRoleRestrictions?: boolean;
	documentTypeId?: string;
}

class GetMachineSites implements UseCase<Args, ResourceSites> {
	private siteRepository: SiteRepository;

	constructor(siteRepository: SiteRepository) {
		this.siteRepository = siteRepository;
	}

	async Call({ companyId, machineId, sort, filter, pageParam, includeWorkingSites, ignoreUserSiteRoleRestrictions, documentTypeId }: Args): Promise<ResourceSites> {
		return await this.siteRepository.getMachineSites(
			companyId,
			machineId,
			filter,
			sort,
			pageParam,
			includeWorkingSites,
			ignoreUserSiteRoleRestrictions,
			documentTypeId
		);
	}
}

export default GetMachineSites;
