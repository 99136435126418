import UseCase from '../../../core/app/usecase';
import { RenameModel } from '../../entities/documentType';
import DocumentTypeRepository from '../../repositories/documentTypeRepository';

type Args = {
	companyId: string;
	documentTypeId: string;
};
/**
 * Returns the rename model object.
 */

class GetRenameModels implements UseCase<Args, RenameModel> {
	constructor(private repository: DocumentTypeRepository) {}

	async Call({ companyId, documentTypeId }: Args): Promise<RenameModel> {
		return await this.repository.getRenameModel(companyId, documentTypeId);
	}
}

export default GetRenameModels;
