import Role from './role';
import { Named } from './interfaces/named';
import { Identifiable } from './interfaces/identifiable';

export enum UserState {
	BLOCKED = 'blocked',
	ENABLED = 'enabled',
	INVITED = 'invited',
}

export type UserMini = Identifiable &
	Named & {
		email: string;
	};

export type User = UserMini & {
	role: Role;
	photo: string;
	language: string;
	state: UserState;
	lastLogin?: Date;
	isCurrentUser?: boolean;
	invitationToken?: string;
};

export type UserSiteRole = Identifiable & {
	role: {
		id: string;
		name: string;
	};
	site: {
		id: string;
		name: string;
	};
};

export default User;
