import { forwardRef, MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { COLORS } from "../../../assets/theme/colors";
import DocumentExpirationDate from "./DocumentExpirationDate";
import Document from "../../../../domain/entities/document";
import { MdModeEdit } from "react-icons/md";
import { formatDateBasedOnLanguage } from "../../../../utils";

interface ExpirationComponentProps {
  popoverOpen: boolean;
  documentsFetching: boolean;
  document: Document;
  newValidity: string;
  disabledFunctions: boolean;
  action: "add" | "delete" | "evaluate" | "edit" | "editFile" | string | null;
  setPopOverOpen: (value: boolean) => void;
  setAction: (value: "add" | "delete" | "evaluate" | "edit") => void;
  handleEditDocument: (data?: Date) => void;
  readOnly: boolean;
  noExpiration: boolean;
  setNoExpiration: (value: boolean) => void;
  currentNoExpiration: boolean;
}

export const DocumentExpirationComponent = forwardRef(
  (
    {
      popoverOpen,
      documentsFetching,
      document,
      newValidity,
      disabledFunctions,
      action,
      setPopOverOpen,
      setAction,
      handleEditDocument,
      readOnly,
      noExpiration,
      setNoExpiration,
      currentNoExpiration = false,
    }: ExpirationComponentProps,
    ref: MutableRefObject<any>,
  ) => {
    const { t } = useTranslation("documents");
    return (
      <Flex
        flexDirection={"column"}
        bg={COLORS.sikuroBlue}
        color={"white"}
        p={4}
      >
        <Text fontWeight={600} marginRight={8}>
          {t("documentDetails")}:
        </Text>
        <Text fontSize={"14px"} marginTop={2}>
          {t("expireDate")}:
        </Text>

        <Popover isOpen={popoverOpen}>
          <Flex alignItems={"center"}>
            {!documentsFetching ? (
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                {!noExpiration && (
                  <Text marginTop={2} marginBottom={2}>
                    {document?.expiresAt
                      ? formatDateBasedOnLanguage(document?.expiresAt)
                      : "-"}
                  </Text>
                )}
                {noExpiration && (
                  <Text marginTop={2} marginBottom={2}>
                    {t("noExpiration")}
                  </Text>
                )}

                {!readOnly && (
                  <PopoverTrigger>
                    <Button
                      isDisabled={disabledFunctions || action !== null}
                      background={"transparent"}
                      _hover={{ background: "transparent" }}
                      onClick={() => {
                        setPopOverOpen(true), setAction("edit");
                      }}
                    >
                      <MdModeEdit fontSize="18px" color={"white"} />
                    </Button>
                  </PopoverTrigger>
                )}
              </Flex>
            ) : (
              <Skeleton height={"40px"} w={"full"} />
            )}
          </Flex>
          <Portal containerRef={ref}>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>{t("editDocument")}</PopoverHeader>
              <PopoverBody>
                <DocumentExpirationDate
                  setAction={setAction}
                  expiresAt={document?.expiresAt}
                  documentDuration={document?.type.duration}
                  isDisabled={noExpiration}
                  updateExpirationDate={(data) => handleEditDocument(data)}
                  openPopover={(closePopover) => setPopOverOpen(closePopover)}
                  noExpiration={noExpiration}
                  setNoExpiration={setNoExpiration}
                  showNoExpiration={true}
                  showButtons={true}
                />
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Flex>
    );
  },
);

DocumentExpirationComponent.displayName = "ExpirationComponent";
