import UseCase from '../../../../core/app/usecase';
import { SiteMachine } from '../../../entities/siteMachine';
import SiteMachineRepository, { GetWorkingSiteMachinesFilters } from '../../../repositories/siteMachineRepository';
import { PaginatedMeta, PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';

type Parameters = {
	companyId: string;
	siteId: string;
	archived?: boolean;
	filter?: GetWorkingSiteMachinesFilters;
	sort?: SortMeta;
} & PaginatedMeta;

class GetWorkingSiteMachines implements UseCase<Parameters, PaginatedResults<SiteMachine> | undefined> {
	constructor(private repository: SiteMachineRepository) {}

	async Call({ companyId, siteId, sort, perPage, archived, filter, page }: Parameters): Promise<PaginatedResults<SiteMachine> | undefined> {
		return this.repository.getWorkingSiteMachines(companyId, siteId, page, perPage, archived, filter, sort);
	}
}

export default GetWorkingSiteMachines;
