import UseCase from '../../../core/app/usecase';
import DocumentRepository from '../../repositories/documentRepository';

interface Args {
	siteId?: string;
	companyId: string;
	resourceType: string;
	resourceId: string;
	pageParam: number;
}

class GetTaggableDocuments implements UseCase<Args, { id: string; name: string }[]> {
	constructor(private repository: DocumentRepository) {}

	async Call({ siteId, companyId, resourceType, resourceId, pageParam }: Args): Promise<{ id: string; name: string }[]> {
		return await this.repository.getTaggableDocuments(companyId, resourceType, resourceId, pageParam, siteId);
	}
}

export default GetTaggableDocuments;
