import UseCase from '../../../../core/app/usecase';
import DocumentTypeWithPublic from '../../../entities/documentTypeWithPublic';
import VehicleRepository from '../../../repositories/vehicleRepository';

type CreatePropagableVehicleDocumentParameters = {
	companyId: string;
	resourceId: string;
	documents: DocumentTypeWithPublic[];
};

/**
 * Returns the list of all the existing Document.
 */
class CreatePropagableVehicleDocument implements UseCase<CreatePropagableVehicleDocumentParameters, void> {
	constructor(private repository: VehicleRepository) {}

	async Call({ companyId, resourceId, documents }: CreatePropagableVehicleDocumentParameters): Promise<void> {
		return await this.repository.createPropagableVehicleDocument(companyId, resourceId, documents);
	}
}

export default CreatePropagableVehicleDocument;
