import UseCase from '../../../core/app/usecase';
import { DocumentTypeCategory } from '../../entities/documentTypeCategory.enum';
import Typology from '../../entities/typology';
import TypologyRepository from '../../repositories/typologyRepository';

type ARGS = {
	companyId: string;
	resourceId: string;
	type: DocumentTypeCategory;
};

class GetResourceTypologiesAssociated implements UseCase<ARGS, Typology> {
	private typologyRepository: TypologyRepository;

	constructor(typologyRepository: TypologyRepository) {
		this.typologyRepository = typologyRepository;
	}

	async Call(args: ARGS): Promise<Typology> {
		return await this.typologyRepository.getResourceTypologiesAssociated(args.companyId, args.resourceId, args.type);
	}
}

export default GetResourceTypologiesAssociated;
