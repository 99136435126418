import Supplier from '../../entities/supplier';
import UseCase from '../../../core/app/usecase';
import CompanyParameters from './companyParameters';
import SupplierRepository, { ActiveSuppliersFilter } from '../../repositories/supplierRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';

interface Args {
	siteId: string;
	companyId: string;
	filter?: ActiveSuppliersFilter;
	sort?: SortMeta;
	pageParam?: number;
	nested?: boolean;
}

class GetActiveSuppliers implements UseCase<CompanyParameters, Supplier[]> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, siteId, filter, sort, pageParam, nested }: Args): Promise<Supplier[]> {
		return await this.supplierRepository.getActiveSuppliers(siteId, companyId, filter, sort, pageParam, nested);
	}
}

export default GetActiveSuppliers;
