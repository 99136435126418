import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ResourceListView from "../ResourceListView";
import Machine from "../../../../domain/entities/machine";
import { Permission } from "../../../components/Permissions/Permissions";
import FormTextField from "../../../components/Common/forms/FormTextField";
import { EditableFormFieldProps } from "../../../components/Common/forms/FormField";
import { MachinesListViewModel } from "../../../viewmodels/machines/MachinesListViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { GetResourcesFilter } from "../../../../domain/repositories/workerRepository";
import FormImageField from "../../../components/Common/forms/FormImageField";

const MachinesView = () => {
  const { t } = useTranslation("machines");
  const formMethods = useForm<Machine>({ mode: "all" });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const columns = [
    { field: "name", type: "text", label: t("name") },
    { field: "plate", type: "text", label: t("plate") },
    { field: "model", type: "text", label: t("model") },
  ] as {
    field: keyof GetResourcesFilter;
    type: "text" | "tags";
    label: string;
  }[];
  const fields: Array<ReactElement<Omit<EditableFormFieldProps, "isEditing">>> =
    [
      <FormTextField
        key="name"
        label={t("name")}
        name="name"
        rules={requiredRule}
      />,
      <FormTextField
        key="plate"
        label={t("plate")}
        name="plate"
        rules={requiredRule}
      />,
      <FormTextField key="model" label={t("model")} name="model" />,
      <FormImageField
        key="photo"
        label={t("photo")}
        name="photo"
        colSpan={2}
      />,
    ];

  return (
    <ResourceListView<Machine>
      formMethods={formMethods}
      type={DocumentTypeCategory.MACHINE}
      viewModel={new MachinesListViewModel()}
      columns={columns}
      namespace="machines"
      createPermissions={[Permission.Resources_AddMachine]}
      deletePermissions={[Permission.Resources_AddMachine]}
      createFields={fields}
      viewDetailsPermissions={[Permission.Resources_ShowMachineDetails]}
    />
  );
};

export default MachinesView;
