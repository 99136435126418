import UseCase from '../../../../core/app/usecase';
import WorkerRepository from '../../../repositories/workerRepository';

interface Args {
	companyId: string;
	resourceId: string;
	typologyId: string;
}

class RemoveTypologyFromWorker implements UseCase<Args, void> {
	constructor(private repository: WorkerRepository) {}

	async Call(args: Args): Promise<void> {
		return await this.repository.removeTypologyFromWorker(args.companyId, args.resourceId, args.typologyId);
	}
}

export default RemoveTypologyFromWorker;
