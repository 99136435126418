import UseCase from '../../../../core/app/usecase';
import FileEntity from '../../../entities/file';
import ImportInfo from '../../../entities/importInfo';
import VehicleRepository from '../../../repositories/vehicleRepository';

interface Args {
	companyId: string;
	file: FileEntity;
}

class UploadVehicles implements UseCase<Args, ImportInfo> {
	constructor(private repository: VehicleRepository) {}

	async Call(args: Args): Promise<ImportInfo> {
		return await this.repository.uploadVehicles(args.companyId, args.file);
	}
}

export default UploadVehicles;
