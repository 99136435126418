import UseCase from '../../../core/app/usecase';
import Document from '../../entities/document';
import CompanyRepository from '../../repositories/companyRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';
import { GetDocumentsFilter } from '../../repositories/documentRepository';

type GetCompanyDocumentsParameters = {
	companyId: string;
	filter?: GetDocumentsFilter;
	sort?: SortMeta;
	pageParam?: number;
};

/**
 * Returns the list of all the existing Document.
 */
class GetCompanyDocuments implements UseCase<GetCompanyDocumentsParameters, Document[]> {
	constructor(private repository: CompanyRepository) {}

	async Call({ companyId, sort, filter, pageParam }: GetCompanyDocumentsParameters): Promise<Document[]> {
		return await this.repository.getCompanyDocuments(companyId, filter, sort, pageParam);
	}
}

export default GetCompanyDocuments;
