import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { FC } from "react";
import Supplier from "../../../domain/entities/supplier";

interface Props {
  supplier: Supplier;
}
const SupplierTopBarName: FC<Props> = ({ supplier }) => {
  return (
    <Flex cursor="pointer" align="center">
      <Tooltip label={supplier?.company?.name}>
        <Flex direction="column" alignItems="start">
          <Text
            align="start"
            fontSize="small"
            fontWeight="semibold"
            noOfLines={1}
          >
            {supplier?.company?.name}
          </Text>
        </Flex>
      </Tooltip>
    </Flex>
  );
};

export default SupplierTopBarName;
