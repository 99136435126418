import MainNavBar from "../../components/Common/MainNavBar";
import WorkingSitesTopBar from "../../components/Views/WorkingSites/WorkingSitesTopBar";
import MainLayout from "../../layout/MainLayout";
import { useEffect } from "react";
import { useApp } from "../../../app";
import { useAuth } from "../../providers/Auth0JWTProvider";

const WorkingSitesMainView = () => {
  const { updateContext } = useApp();
  const { updateSiteContext } = useAuth();

  useEffect(() => {
    updateContext({ topBarTitle: undefined, site: undefined });
    updateSiteContext(undefined);
  }, []);

  return <MainLayout header={<WorkingSitesTopBar />} nav={<MainNavBar />} />;
};

export default WorkingSitesMainView;
