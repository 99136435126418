import UseCase from '../../../core/app/usecase';
import { AuditType } from '../../entities/audit';
import AuditRepository from '../../repositories/auditRepository';

interface Args {
	companyId: string;
	siteId: string;
	audit: AuditType;
	supplierId: string;
	siteRequirementsToExclude?: string[];
	companyRequirementsToExclude?: string[];
	workersRequirementsToExclude?: string[];
	machinesRequirementsToExclude?: string[];
	vehiclesRequirementsToExclude?: string[];
	toolsRequirementsToExclude?: string[];
	chemicalsRequirementsToExclude?: string[];
	workerResourceIds?: string[];
	machineResourceIds?: string[];
	vehicleResourceIds?: string[];
	toolResourceIds?: string[];
	chemicalResourceIds?: string[];
}

class CreateAudit implements UseCase<Args, boolean> {
	constructor(private repository: AuditRepository) {}

	async Call({
		companyId,
		audit,
		siteId,
		supplierId,
		siteRequirementsToExclude,
		companyRequirementsToExclude,
		workersRequirementsToExclude,
		machinesRequirementsToExclude,
		vehiclesRequirementsToExclude,
		toolsRequirementsToExclude,
		chemicalsRequirementsToExclude,
		workerResourceIds,
		machineResourceIds,
		vehicleResourceIds,
		toolResourceIds,
		chemicalResourceIds,
	}: Args): Promise<boolean> {
		return await this.repository.createAudit(
			companyId,
			audit,
			siteId,
			supplierId,
			siteRequirementsToExclude,
			companyRequirementsToExclude,
			workersRequirementsToExclude,
			machinesRequirementsToExclude,
			vehiclesRequirementsToExclude,
			toolsRequirementsToExclude,
			chemicalsRequirementsToExclude,
			workerResourceIds,
			machineResourceIds,
			vehicleResourceIds,
			toolResourceIds,
			chemicalResourceIds,
		);
	}
}

export default CreateAudit;
