import {
	makeAddFileToDocument,
	makeDeleteFileFromDocument,
	makeDocumentSites,
	makeGetDocumentFiles,
	makeGetDocumentFilesLog,
	makeGetDocumentLog,
	makeRepropagateFileToDocument,
	makeUpdateFile,
} from '../../../core/app/factory/documents/usecases';
import {
	makeCreateSupplierDocuments,
	makeEvaluateCompanySupplierRequirement, makeEvaluateSupplierDocument,
	makeGetCompanySupplier,
	makeGetSupplierDocumentEvaluations,
	makeGetSupplierDocuments,
	makeGetVariantsForCompanySupplierRequirements,
	makeRepropagateEvaluationSupplierDocument,
	makeUpdateCompanySupplierVariant,
	makeUpdateSupplierDocument
} from "../../../core/app/factory/suppliers/useCases";
import Document, { SiteResourceDocument } from '../../../domain/entities/document';
import { DocumentActionLogEntry, DocumentFileLogEntry } from '../../../domain/entities/documentLogEntry';
import Evaluation from '../../../domain/entities/evaluation';
import { SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { RequirementSubject } from '../../../domain/entities/requirementSubject.enum';
import { ResourceDocumentEvaluationState } from '../../../domain/entities/resourceDocumentEvaluationState.enum';
import Site, { DocumentSites } from '../../../domain/entities/site';
import FileEntity from '../../../domain/entities/file';
import Supplier from '../../../domain/entities/supplier';
import Variant from '../../../domain/entities/variant';
import {
	GetDocumentEvaluationFilter,
	GetDocumentFilesFilter,
	GetDocumentLogFilter,
	GetDocumentsFilter,
} from '../../../domain/repositories/documentRepository';
import { DocumentInfoViewModel } from '../../hooks/Document/useDocumentInfo';
import { GetSitesFilter } from '../../../domain/repositories/siteRepository';
import Tag from '../../../domain/entities/tag';
import { makeGetTags } from '../../../core/app/factory/tags/usecases';

class SupplierDetailViewModel implements DocumentInfoViewModel {
	constructor(private companyId: string, private supplierId: string) {}

	async getSupplier(): Promise<Supplier> {
		return await makeGetCompanySupplier().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
		});
	}

	async getSupplierDocuments(filter?: GetDocumentsFilter, sort?: SortMeta, pageParam?: number): Promise<Document[]> {
		return await makeGetSupplierDocuments().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			filter,
			sort,
			pageParam,
		});
	}

	async createSupplierDocument(
		isPublic: boolean,
		documentTypeId: string,
		siteIds: string[],
		result?: ResourceDocumentEvaluationState,
		expiresAt?: Date,
		files?: FileEntity
	): Promise<void> {
		await makeCreateSupplierDocuments().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			isPublic,
			documentTypeId,
			siteIds,
			result,
			expiresAt,
			files,
		});
	}

	async updateSupplierDocument(document: Document, siteIds: string[]): Promise<void> {
		await makeUpdateSupplierDocument().Call({ companyId: this.companyId, supplierId: this.supplierId, document, siteIds });
	}

	async getDocumentEvaluations(
		documentId: string,
		filter: GetDocumentEvaluationFilter,
		sort: SortMeta
	): Promise<Evaluation<ResourceDocumentEvaluationState, Document>[]> {
		return await makeGetSupplierDocumentEvaluations().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			documentId,
			filter,
			sort,
		});
	}


	async evaluateSupplierDocument(
		documentId: string,
		result: ResourceDocumentEvaluationState,
		expiresAt?: Date,
		noEvaluationExpiration?: boolean,
		siteIds?: string[],
		selectAll?: boolean,
		filters?: GetSitesFilter,
	): Promise<void> {
		await makeEvaluateSupplierDocument().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			documentId,
			result,
			expiresAt,
			noEvaluationExpiration,
			siteIds,
			selectAll,
			filters
		});
	}

	async repropagateEvaluationSupplierDocument(
		documentId: string,
		siteIds?: string[],
		selectAll?: boolean,
	): Promise<void> {
		await makeRepropagateEvaluationSupplierDocument().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			documentId,
			siteIds,
			selectAll,
		});
	}

	async evaluateRequirement(requirementId: string, result: ResourceDocumentEvaluationState, expiresAt?: Date): Promise<void> {
		await makeEvaluateCompanySupplierRequirement().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			requirementId,
			result,
			expiresAt,
		});
	}

	async getVariants(): Promise<Variant[]> {
		return await makeGetVariantsForCompanySupplierRequirements().Call({
			companyId: this.companyId,
			supplierId: this.supplierId,
			subject: RequirementSubject.COMPANY,
		});
	}

	async updateVariant(variantId?: string): Promise<void> {
		await makeUpdateCompanySupplierVariant().Call({ companyId: this.companyId, supplierId: this.supplierId, variantId });
	}

	async getDocumentFiles(
		companyId: string,
		documentId: string,
		filter?: GetDocumentFilesFilter,
		sort?: SortMeta,
		siteId?: string,
		target?: string,
		resourceId?: string
	): Promise<FileEntity[]> {
		return await makeGetDocumentFiles().Call({ companyId, documentId, filter, sort, siteId, target, resourceId });
	}

	async getDocumentFilesLogs(
		companyId: string,
		documentId: string,
		filter?: GetDocumentLogFilter,
		sort?: SortMeta,
		pageParam?: number
	): Promise<DocumentActionLogEntry[]> {
		return await makeGetDocumentFilesLog().Call({ companyId, documentId, filter, sort, pageParam });
	}

	async deleteDocumentFile(
		companyId: string,
		documentId: string,
		fileId: string,
		siteId?: string,
		target?: string,
		resourceId?: string,
		siteIds?: string[],
		selectAll?: boolean
	): Promise<void> {
		await makeDeleteFileFromDocument().Call({ companyId, documentId, fileId, siteId, target, resourceId, siteIds, selectAll });
	}

	async addFileToDocument(
		companyId: string,
		documentId: string,
		file: FileEntity,
		siteId?: string,
		target?: string,
		resourceId?: string,
		siteIds?: string[],
		toSupplier?: boolean
	): Promise<void> {
		await makeAddFileToDocument().Call({ companyId, documentId, file, siteId, target, resourceId, siteIds, toSupplier });
	}

	async repropagateFileToDocument(
		companyId: string,
		documentId: string,
		siteId?: string,
		target?: string,
		resourceId?: string,
		siteIds?: string[],
		selectAll?: boolean,
		toSupplier?: boolean,
		selectAllFiles?: boolean,
		fileIds?: string[],
		filters?: GetSitesFilter
	): Promise<void> {
		await makeRepropagateFileToDocument().Call({ companyId, documentId, siteId, target, resourceId, siteIds, selectAll, toSupplier, selectAllFiles, fileIds, filters });
	}

	async getDocumentLogs(
		companyId: string,
		documentId: string,
		filter?: GetDocumentLogFilter,
		sort?: SortMeta,
		resourceId?: string,
		siteId?: string,
		target?: string,
		pageParam?: number
	): Promise<DocumentFileLogEntry[]> {
		return await makeGetDocumentLog().Call({ companyId, documentId, filter, sort, resourceId, siteId, target, pageParam });
	}

	async updateFile(
		companyId: string,
		documentId: string,
		fileId: string,
		updatedFiles: Partial<FileEntity>[],
		siteIds?: string[],
		target?: string,
		resourceId?: string,
		selectAll?: boolean,
		filters?: GetSitesFilter
	): Promise<void> {
		return await makeUpdateFile().Call({ companyId, documentId, updatedFiles, fileId, siteIds, target, resourceId, selectAll, filters });
	}
	async getDocumentSites(
		companyId: string,
		documentId: string,
		sort?: SortMeta,
		filter?: GetSitesFilter,
		pageParam?: number,
		includeWorkingSites?: boolean,
		supplierId?: string
	): Promise<DocumentSites> {
		return await makeDocumentSites().Call({
			companyId,
			documentId,
			sort,
			filter,
			pageParam,
			includeWorkingSites,
			supplierId
		});
	}

	async getSupplierTags(companyId: string, supplierId: string): Promise<Tag[]> {
		return await makeGetTags().Call({ companyId, supplierId });
	}
}

export default SupplierDetailViewModel;
