import UseCase from '../../../core/app/usecase';
import BadgeRepository from '../../repositories/badgeRepository';
import File from '../../entities/file';
import { BadgeResourceType } from '../../entities/badgeResourceType.enum';

type ARGS = {
	companyId: string;
	siteId?: string;
	resourceType: BadgeResourceType;
	file: File;
};

class ImportLinkResourcesBadges implements UseCase<ARGS, void> {
	private badgeRepository: BadgeRepository;

	constructor(badgeRepository: BadgeRepository) {
		this.badgeRepository = badgeRepository;
	}

	async Call({ companyId, siteId, resourceType, file }: ARGS): Promise<void> {
		return await this.badgeRepository.importLinkResourcesBadges(companyId, resourceType, file, siteId);
	}
}

export default ImportLinkResourcesBadges;
