import { darken, lighten } from "polished";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import { TagLabel, Tag as ChakraTag } from "@chakra-ui/react";
import { BadgeReaderState } from "../../../domain/entities/badgeReaderState.enum";

type BadgeReaderStatusProps = {
  value: BadgeReaderState;
  label?: string;
  width?: string;
};

const StatusMap = {
  [BadgeReaderState.ACTIVE]: [COLORS.active, "badges"],
  [BadgeReaderState.INACTIVE]: [COLORS.error, "badges"],
};

const BadgeReaderStatus = ({
  value,
  label,
  width = "max-content",
}: BadgeReaderStatusProps) => {
  const { t } = useTranslation();
  if (value) {
    const [color = "#aaaaaa", ns = "common"] = StatusMap[value];
    return (
      <ChakraTag
        width={width}
        size="md"
        color={darken(0.2, color)}
        bg={lighten(0.2, color)}
      >
        <TagLabel style={{ cursor: "pointer", fontSize: "12px" }}>
          {label ?? t(value ?? "", { ns: "badgeReaders" })}
        </TagLabel>
      </ChakraTag>
    );
  }
};

export default BadgeReaderStatus;
