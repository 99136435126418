import Worker from '../../../entities/worker';
import UseCase from '../../../../core/app/usecase';
import WorkerRepository from '../../../repositories/workerRepository';

type ARGS = {
	companyId: string;
	worker: Worker;
	imageFile: File;
};

class UpdateWorker implements UseCase<ARGS, Worker> {
	constructor(private repository: WorkerRepository) {}

	async Call(parameters: ARGS): Promise<Worker> {
		return await this.repository.updateWorker(parameters.companyId, parameters.worker, parameters.imageFile);
	}
}

export default UpdateWorker;
