import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteMachineParams } from "../../../domain/entities/siteMachine";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import useSiteMachineDetailViewModel from "../../hooks/Site/useSiteMachineDetailViewModel";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const SiteMachineDetailView = () => {
  const { siteId, machineId } = useParams();
  const resourceColumns = ["name", "plate", "model", "companyName", "badge"];

  const downloader = useResourceFilesDownloader(siteId, machineId, "machines");

  return (
    <SiteResourceDetailView<SiteMachineParams>
      downloader={downloader}
      resourceId={machineId}
      type={DocumentTypeCategory.MACHINE}
      useViewModel={useSiteMachineDetailViewModel(
        siteId,
        machineId,
        resourceColumns,
        DocumentTypeCategory.MACHINE,
        AiTaskSectionCode.SITE_RESOURCES_MACHINES
      )}
      resourceColumns={resourceColumns}
      isReadOnly={false}
      permissions={{
        downloadDocuments: Permission.Sites_DownloadMachineDocuments,
        showGlobalEvaluation: Permission.Sites_Manage,
        setGlobalEvaluation: Permission.Sites_SetGlobalMachineEval,
        addRequirement: Permission.Sites_NewMachineRequirement,
        viewRequirements: Permission.Sites_Manage,
        editTags: Permission.Sites_EditMachineRequirements,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        documents: {
          delete: Permission.Sites_DeleteMachineRequirement,
          edit: Permission.Sites_EditMachineRequirements,
          evaluate: Permission.Sites_EditMachineRequirements,
          viewEvaluations: Permission.Sites_EditMachineRequirements,
          downloadAll: Permission.Sites_EditMachineRequirements,
          addFile: Permission.Sites_EditChemicalRequirements,
          chat: Permission.Sites_AccessMachineChat,
          setExpiration: Permission.Sites_EditMachineRequirements,
        },
      }}
    />
  );
};

export default SiteMachineDetailView;
