import UseCase from '../../../core/app/usecase';
import RequirementsGroupRepository from '../../repositories/requirementsGroupRepository';
import DeleteReqToGroupParameters from './deleteReqToGroupParameter';

class DeleteRequirementsGroup implements UseCase<DeleteReqToGroupParameters, void> {
	private requirementsGroupRepository: RequirementsGroupRepository;

	constructor(requirementRepository: RequirementsGroupRepository) {
		this.requirementsGroupRepository = requirementRepository;
	}

	async Call(parameters) {
		return await this.requirementsGroupRepository.deleteRequirementsGroup(parameters.companyId, parameters.requirementsGroupId);
	}
}

export default DeleteRequirementsGroup;
