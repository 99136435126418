import {RegisterOptions, useFormContext} from "react-hook-form";
import BaseInputField from "../BaseInputField";

type HookTextFieldProps = {
  label?: string;
  name: string;
  options?: RegisterOptions;
}

const HookTextField = (props: HookTextFieldProps) => {
  const { register, formState: { errors } } = useFormContext();
  // get error message from errors object, consider that name can be nasted ie. "name.nested"
  const error = getNestedValue(errors, props.name);
  return (
    <BaseInputField
      label={props.label}
      error={error?.message as string || !!error}
      inputProps={{
        id: props.name,
        isInvalid: !!error,
        ...register(props.name, props.options)
      }}
      formControlProps={{
        isRequired: !!props.options?.required,
        isInvalid: !!error
      }}
      formLabelProps={{
        htmlFor: props.name
      }}
    />
  )
}

function getNestedValue(obj: Record<string, any>, path: string): any {
  // Split the path by dots to access each level in the object.
  const keys = path.split('.');

  // Traverse the object based on the keys
  return keys.reduce((acc, key) => {
    if (acc && typeof acc === 'object' && key in acc) {
      return acc[key];
    }
    // If key doesn't exist, return undefined to stop further traversal
    return undefined;
  }, obj);
}

export default HookTextField;