import Worker from '../../../entities/worker';
import UseCase from '../../../../core/app/usecase';
import WorkerRepository from '../../../repositories/workerRepository';
import SiteWorkerRepository, { SiteWorkerMeta } from '../../../repositories/siteWorkerRepository';

type ARGS = {
	companyId: string;
	siteId: string;
	workerId: string;
	meta: SiteWorkerMeta;
};

class UpdateWorker implements UseCase<ARGS, void> {
	constructor(private repository: SiteWorkerRepository) {}

	async Call(parameters: ARGS): Promise<void> {
		return await this.repository.updateSiteWorkerMeta(parameters.companyId, parameters.siteId, parameters.workerId, parameters.meta);
	}
}

export default UpdateWorker;
