import { createUseRequirementsViewModel } from "../Requirements/useRequirementsViewModel";
import { SiteRequirementsViewModel } from "../../viewmodels/sites/SiteRequirementsViewModel";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";

const useSiteRequirementsViewModel = (target: RequirementSubject) =>
  createUseRequirementsViewModel(
    () => new SiteRequirementsViewModel(target)
  );

export default useSiteRequirementsViewModel;
