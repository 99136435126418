import UseCase from '../../../core/app/usecase';
import RequirementsGroup from '../../entities/requirementsGroup';
import RequirementsGroupRepository from '../../repositories/requirementsGroupRepository';
import CreateReqToGroupParameters from './createReqToGroupParameter';

class CreateRequirementsGroup implements UseCase<CreateReqToGroupParameters, RequirementsGroup> {
	private requirementsGroupRepository: RequirementsGroupRepository;

	constructor(requirementsGroupRepository: RequirementsGroupRepository) {
		this.requirementsGroupRepository = requirementsGroupRepository;
	}

	async Call(parameters: CreateReqToGroupParameters): Promise<RequirementsGroup> {
		return await this.requirementsGroupRepository.createRequirementsGroup(parameters.companyId, parameters.requirementsGroup);
	}
}

export default CreateRequirementsGroup;
