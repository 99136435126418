import UseCase from '../../../core/app/usecase';
import Document from '../../entities/document';
import DocumentRepository from '../../repositories/documentRepository';

type DocumentFileParameters = {
	companyId: string;
	documentId: string;
	siteId?: string;
};

/**
 * Delete the selected Docuemnt
 *
 * @param id the identifier of the document to delete
 */
class DeleteDocument implements UseCase<DocumentFileParameters, Document[]> {
	constructor(private repository: DocumentRepository) {}

	async Call({ companyId, documentId, siteId }: DocumentFileParameters): Promise<Document[]> {
		return await this.repository.deleteDocument(companyId, documentId, siteId);
	}
}

export default DeleteDocument;
