import {
  Tag as ChakraTag,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  TagCloseButton,
  TagLabel,
  useToast,
  Text,
} from "@chakra-ui/react";
import { darken } from "polished";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegSave } from "react-icons/fa";
import { FaEllipsisH } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";

export type TagType = {
  id: string;
  name: string;
  color?: string;
  isSystem?: boolean;
};

type TagProps = {
  tag: TagType;
  onClick?: (tag: TagType) => void;
  onDelete?: (tag: TagType, e: React.MouseEvent<HTMLButtonElement>) => void;
  width?: "auto" | "inherit" | "initial" | "unset" | string;
  onUpdate?: (tag: TagType) => void;
  editable?: boolean;
  marginLeft?: string;
};
const TagBadge = ({
  tag,
  onDelete,
  onClick,
  onUpdate,
  width = "max-content",
  editable,
  marginLeft,
}: TagProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [update, setUpdate] = useState(false);
  const [newValue, setNewValue] = useState(tag.name);
  const { t } = useTranslation();
  const toast = useToast();

  return (
    <ChakraTag
      width={width}
      size="md"
      onClick={
        onClick
          ? () => {
              onClick(tag);
            }
          : undefined
      }
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      color={darken(0.6, tag.color ?? "#aaaaaa")}
      cursor={isHovering && editable ? "pointer" : "none"}
      bg={tag.color ?? "#aaaaaa"}
      position="relative"
      style={{ cursor: tag.isSystem ? "default" : "pointer", fontSize: "12px" }}
      marginLeft={marginLeft}
    >
      {!update ? (
        <TagLabel
          style={{
            cursor: tag.isSystem ? "default" : "pointer",
            fontSize: "12px",
          }}
        >
          {tag.name !== newValue ? newValue : tag.name}
        </TagLabel>
      ) : (
        <>
          <Input
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
            }}
            size={"xs"}
            border="none"
            _focusVisible={{ boxShadow: "none" }}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                return e.stopPropagation();
              } else if (e.code === "Space") {
                return e.stopPropagation();
              } else if (e.key === "Enter" && newValue) {
                return onUpdate({ ...tag, name: newValue });
              } else if (e.key === "Enter" && newValue.trim().length === 0) {
                {
                  toast({
                    title: t("emptyTag", { ns: "documents" }),
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                  });
                  return e.stopPropagation();
                }
              }
            }}
          />
          <FaRegSave
            style={{ margin: 4 }}
            onClick={(e) => {
              onUpdate && onUpdate({ ...tag, name: newValue });
              setUpdate(false);
              e.stopPropagation();
            }}
          />
        </>
      )}
      {isHovering && editable && !update && (
        <Menu placement="left">
          {!tag.isSystem && (
            <MenuButton>
              <FaEllipsisH style={{ margin: "0 2px" }} />
            </MenuButton>
          )}
          <MenuList>
            <MenuItem
              onClick={() => {
                setUpdate(true),
                  update === false ? setUpdate(true) : setUpdate(false);
              }}
              onMouseLeave={() => {
                setIsHovering(false);
              }}
            >
              <FaEdit style={{ margin: "0 2px" }} />
              <Text px={2}>{t("renameTag", { ns: "documents" })}</Text>{" "}
            </MenuItem>
          </MenuList>
        </Menu>
      )}
      {!update && onDelete && (
        <TagCloseButton
          onClick={(e) => {
            e.stopPropagation();
            onDelete(tag, e);
          }}
          value={tag.id}
          marginLeft={0}
        />
      )}
    </ChakraTag>
  );
};

export default TagBadge;
