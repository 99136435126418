import { useTranslation } from "react-i18next";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from "@chakra-ui/react";
import { HiKey } from "react-icons/hi";
import { COLORS } from "../../../assets/theme/colors";
import { useFormContext } from "react-hook-form";
import { FormValues } from "./WebHookContainer";

interface EndpointFieldProps {
  index: number;
}

const EndpointField = ({ index }: EndpointFieldProps) => {
  const { t } = useTranslation("webhooks");
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<FormValues>();
  const error = errors["webHooks"]?.[index]?.url?.message;
  const apiSecret = watch(`webHooks.${index}.apiSecret`);
  return (
    <Flex gap={1}>
      <FormControl isInvalid={!!error}>
        <Input
          size="sm"
          placeholder={t("endpointUrl")}
          {...register(`webHooks.${index}.url`, {
            required: t("errors.endpointRequired"),
            pattern: {
              value: /^https?:\/\/[^\s/$.?#].\S*$/,
              message: t("webhook.errors.invalidUrl"),
            },
          })}
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>

      <Popover strategy="fixed">
        <Tooltip label={t("apiSecret")} aria-label={t("apiSecret")}>
          <span>
            <PopoverTrigger>
              <IconButton
                aria-label={t("apiSecret")}
                variant="ghost"
                sx={{ color: apiSecret === "" ? "#767676" : COLORS.sikuroBlue }}
                fill="#767676"
                fontSize="18px"
                minWidth="22px"
                maxHeight="32px"
              >
                <HiKey />
              </IconButton>
            </PopoverTrigger>
          </span>
        </Tooltip>
        <PopoverContent>
          <PopoverArrow backgroundColor={COLORS.white} />
          <PopoverBody backgroundColor={COLORS.white} borderRadius={6}>
            <FormControl>
              <FormLabel sx={{ fontSize: "12px" }}>{t("apiSecret")}</FormLabel>
              <Input
                size="sm"
                placeholder={t("apiSecretPlaceHolder")}
                {...register(`webHooks.${index}.apiSecret`)}
              />
            </FormControl>
          </PopoverBody>
          <PopoverCloseButton />
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default EndpointField;
