import UseCase from '../../../../core/app/usecase';
import VehicleRepository from '../../../repositories/vehicleRepository';

type Args = {
	companyId: string;
	vehicleId: string;
};

class RestoreVehicle implements UseCase<Args, void> {
	constructor(private repository: VehicleRepository) {}

	async Call({ companyId, vehicleId }: Args): Promise<void> {
		return await this.repository.restoreVehicle(companyId, vehicleId);
	}
}

export default RestoreVehicle;
