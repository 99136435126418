export enum ResourceDocumentEvaluationState {
	AVAILABLE = 'available',
	NOT_AVAILABLE = 'not_available',
	NOT_APPLICABLE = 'not_applicable',
	UNDEFINED = 'undefined',
	UNEVALUATED = 'unevaluated',
}

export enum ResDocumentEvaluationState {
	AVAILABLE = 'available',
	NOT_AVAILABLE = 'not_available',
	NOT_APPLICABLE = 'not_applicable',
	UNEVALUATED = 'unevaluated',
}

