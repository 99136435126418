import { Flex } from "@chakra-ui/react";
import ActivationComponent from "./ActivationComponent";
import { useActivationViewModel } from "../../hooks/Activation/useActivationViewModel";
import { useEffect } from "react";
import { useApp } from "../../../app";
import { useAuth } from "../../providers/Auth0JWTProvider";
import MainNavBar from "../../components/Common/MainNavBar";
import MainLayout from "../../layout/MainLayout";
import TopBar from "../../components/Common/TopBar";
import { useTranslation } from "react-i18next";

const ActivationMainView = () => {
  const { action, showExploreBtn, msg, isLoading, redirect, email, username } =
    useActivationViewModel();
  const { t } = useTranslation("common");
  const { updateContext } = useApp();
  const { updateSiteContext } = useAuth();

  useEffect(() => {
    updateContext({ topBarTitle: undefined, site: undefined });
    updateSiteContext(undefined);
  }, []);

  return (
    <MainLayout
      header={<TopBar title={t("activation")} />}
      nav={<MainNavBar />}
    >
      <Flex flexDirection={"column"} overflowY={"scroll"} height={"100%"}>
        <ActivationComponent
          activationFunction={action}
          isLoading={isLoading}
          msg={msg}
          showExploreBtn={showExploreBtn}
          redirectUrl={redirect}
          email={email}
          username={username}

        />
      </Flex>
    </MainLayout>
  );
};

export default ActivationMainView;
