import { Box, Button, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { FaRegSave } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import React, { FC, useState } from "react";
import LoadingView from "../../Common/LoadingView";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import Tool from "../../../../domain/entities/tool";
import { COLORS } from "../../../assets/theme/colors";
import DataBox from "../../../components/Common/DataBox";
import { useNavigate, useParams } from "react-router-dom";
import ContentLayout from "../../../layout/ContentLayout";
import { MdCancel, MdClose, MdEdit } from "react-icons/md";
import ActionBar from "../../../components/Common/ActionBar";
import ResourceDocumentsView, {
  ResourceDocumentProps,
} from "../ResourceDocumentsView";
import RenderIf from "../../../components/Permissions/RenderIf";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Permission } from "../../../components/Permissions/Permissions";
import FormTextField from "../../../components/Common/forms/FormTextField";
import useToolViewModel from "../../../hooks/Resources/Tools/useToolViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import useToolDocumentsViewModel from "../../../hooks/Resources/Tools/useToolDocumentsViewModel";
import { useDocumentFilesDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import DocumentCommunicationModal from "../../../components/Views/common/DocumentCommunicationModal";
import BaseModal from "../../../components/Common/alerts/BaseModal";
import SelectSitesView from "../../../components/Views/common/SelectSitesView";
import DOMPurify from "dompurify";
import ResourceSitesList from "../ResourceSitesList";
import { Alert } from "../../Common/Alert";
import { LuPackagePlus } from "react-icons/lu";

interface Props {
  permissions: ResourceDocumentProps["permissions"] & {
    editRecord: Permission;
    manageDocumentWithAi: Permission;
  };
}

const ToolDetailView: FC<Props> = ({ permissions }) => {
  const { id } = useParams();
  const hook = useToolViewModel(id as string)
  return hook.loading ? <LoadingView /> : <ToolDetailViewMain permissions={permissions} hook={hook} />
}

const ToolDetailViewMain: FC<Props & { hook: ReturnType<typeof useToolViewModel>}> = ({ permissions, hook }) => {
  const { id } = useParams();
  const { t } = useTranslation("tools");
  const {
    tool,
    isFetching,
    updateImage,
    updateTool,

    resourceDocumentSites,
    isFetchingResourceDocumentSites,
    resourceDocumentSitesHasNextPage,
    resourceDocumentSitesFetchNextPage,
    updateFilterResourceDocumentSites,
    filterResourceDocumentSites,
    setSortResourceDocumentSites,
    sortResourceDocumentSites,
    totalResourceDocumentSiteCount,

    resourceSites,
    filterResourceSites,
    updateFilterResourceSites,
    setSortResourceSites,
    sortResourceSites,
    isFetchingSites,
    hasNextPage,
    fetchNextPage,
    setEnableGetToolSites,
    hasSites,
    siteCollection,
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    setFilterSiteCollection,
    updateFilterSiteCollection,
    setEnableGetToolSiteCollection,
    siteCollectionIsLoading,
    siteCollectionHasNextPage,
    siteCollectionFetchNextPage,
    refetchSiteCollection,
    linkToolToSites,
    isLinking,
    selectAll,
    setSelectAll,
    showInfoSuccess,
    setShowInfoSuccess,
    setFilterResourceSites,
    resourceLinkableSitesCount,
    customFields
  } = hook
  const [showSiteCollection, setShowSiteCollection] = useState(false);
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>();
  const documentsHook = useToolDocumentsViewModel(id);
  const [showAddDocumentType, setShowAddDocumentType] =
    useState<boolean>(false);
  const downloader = useDocumentFilesDownloader();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const [showManageTaskWithAI, setShowManageTaskWithAI] =
    useState<boolean>(false);
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();
  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("addResourceToSites", { ns: "sites", resource: `${tool?.name}` })
  );

  const navigate = useNavigate();
  const goToToolsList = () => {
    navigate(`/resources/tools`);
  };

  const onSubmit: SubmitHandler<Tool> = (data) => {
    if (uploadedImageFile) {
      updateImage(
        { ...tool, ...data },
        uploadedImageFile
      );
    } else {
      updateTool({ ...tool, ...data }).then(() => {
        setIsEditing(false);
      });
    }
    setIsEditing(false);
  };

  const methods = useForm<Tool>({ mode: "all", defaultValues: tool });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const refetchCommentsAndNotes = () => {
      documentsHook.communicationProps.refetchResourceDocumentComments(),
      documentsHook.communicationProps.refetchResourceDocumentNotes();
      documentsHook.communicationProps.refetchNotificationStatus();
  };

  if (!tool) {
    return <LoadingView />;
  }

  const handleLinkToSites = async () => {
    await linkToolToSites(selectAll ? [] : selectedSiteIds, selectAll);
    setFilterSiteCollection(null);
    setShowSiteCollection(false);
    setSelectedSiteIds([]);
  };

  const goToSiteConnection = (siteId: string) => {
    navigate(`/sites/${siteId}/resources/tools/${id}`)
  }

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={goToToolsList}
          />

          <RenderIf permissions={permissions.editRecord}>
            <ActionBarItem
              icon={isEditing ? MdCancel : MdEdit}
              onClick={() => {
                setIsEditing(!isEditing);
                if (isEditing) {
                  setUploadedImageFile(undefined);
                }
              }}
              description={t(isEditing ? "cancel" : "edit", { ns: "common" })}
            />
          </RenderIf>
          {isEditing && isValid && (
            <ActionBarItem
              description={t("confirm", { ns: "common" })}
              icon={FaRegSave}
              onClick={handleSubmit(onSubmit)}
            />
          )}
          <RenderIf permissions={permissions.documents.chat}>
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => {
                refetchCommentsAndNotes(), setShowCommunicationModal(true);
              }}
            />
          </RenderIf>
          <ActionBarItem
            bgColor={COLORS.yellow}
            color="black"
            icon={LuPackagePlus }
            description={t("addResourceToSitesTooltip", {
              ns: "sites",
              resource: `${tool?.name}`,
            })}
            onClick={() => {
              setEnableGetToolSiteCollection(true), setShowSiteCollection(true);
              refetchSiteCollection();
            }}
          />
        </ActionBar>
      }
    >
      {tool && (
        <Box px={3} py={5} marginLeft={7}>
          <Box textStyle="h1" mb={2}>
            {tool?.name}
          </Box>
          <Box
            pb={8}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
          >
            <FormProvider {...methods}>
              <DataBox
                title={t("details", { ns: "common" })}
                isEditing={isEditing}
                isLoading={isFetching}
                image={{
                  url: uploadedImageFile
                    ? URL.createObjectURL(uploadedImageFile)
                    : tool.photo,
                  onUpdate: (f) => setUploadedImageFile(f),
                }}
                fields={[
                  <FormTextField
                    key="name"
                    name="name"
                    label={t("name")}
                    rules={requiredRule}
                  />,
                  <FormTextField
                    key="serial"
                    name="serial"
                    label={t("serial")}
                    rules={requiredRule}
                  />,
                ]}
                customFields={customFields.data}
              />
            </FormProvider>
          </Box>

          <Box
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            padding={5}
            marginTop={5}
          >
            <Tabs height="100%" defaultIndex={0} marginTop={4} isFitted>
              <TabList>
                <Tab>{t("documents", { ns: "common" })}</Tab>
                <Tab onClick={()=>setEnableGetToolSites(true)}>{t("sites", { ns: "sites" })}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel key={"documents"} paddingLeft={0} paddingRight={0}>
                  <ResourceDocumentsView
                    category={DocumentTypeCategory.TOOL}
                    documentsHook={documentsHook}
                    resourcePath="/resources/tools"
                    showAddDocumentType={showAddDocumentType}
                    setShowAddDocumentType={setShowAddDocumentType}
                    downloader={downloader}
                    resourceSites={resourceDocumentSites}
                    hasSites={hasSites}
                    isFetchingSites={isFetchingResourceDocumentSites}
                    hasNextPage={resourceDocumentSitesHasNextPage}
                    fetchNextPage={resourceDocumentSitesFetchNextPage}
                    setSelectAllSites={setSelectAll}
                    selectAllSites={selectAll}
                    siteCount={totalResourceDocumentSiteCount}
                    filterResourceSites={filterResourceDocumentSites}
                    updateFilterResourceSites={updateFilterResourceDocumentSites}
                    setSortResourceSites={setSortResourceDocumentSites}
                    sortResourceSites={sortResourceDocumentSites}
                    permissions={permissions}
                    showManageTaskWithAI={showManageTaskWithAI}
                    setShowManageTaskWithAI={setShowManageTaskWithAI}
                    resourceId={tool.id}
                  />
                </TabPanel>
                <TabPanel key={"site"} paddingLeft={0} paddingRight={0}>
                  <ResourceSitesList
                    sites={resourceSites}
                    sitesFilter={filterResourceSites}
                    updateSitesFilter={updateFilterResourceSites}
                    setSitesSort={setSortResourceSites}
                    sitesSort={sortResourceSites}
                    sitesLoading={isFetchingSites}
                    getSitesHasNextPage={hasNextPage}
                    getSitesFetchNextPage={fetchNextPage}
                    navigateAction={(siteId)=> goToSiteConnection(siteId)}
                    showUnlink={false}
                  ></ResourceSitesList>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      )}
      {showCommunicationModal && (
        <DocumentCommunicationModal
          onClose={() => setShowCommunicationModal(false)}
          hook={documentsHook.communicationProps}
          permissions={permissions.documents}
        />
      )}
      {showSiteCollection && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setShowSiteCollection(false)}
          footer={[
            <Button
              key="confirm"
              isDisabled={!selectedSiteIds || selectedSiteIds?.length === 0}
              colorScheme="blue"
              onClick={() => handleLinkToSites()}
              isLoading={isLinking}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => {
                setFilterSiteCollection(null);
                setShowSiteCollection(false);
              }}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <Text
            fontSize={16}
            marginTop={3}
            marginBottom={5}
            dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
          />
          <SelectSitesView
            autosize
            title={""}
            siteSelectedAction={setSelectedSiteIds}
            siteList={siteCollection}
            isFetchingSites={siteCollectionIsLoading}
            setSortResourceSites={setSortSiteCollection}
            sortResourceSites={sortSiteCollection}
            filterResourceSites={filterSiteCollection}
            updateFilterResourceSites={updateFilterSiteCollection}
            hasNextPage={siteCollectionHasNextPage}
            fetchNextPage={() => siteCollectionFetchNextPage()}
            includeFooterButton={false}
            setSelectAllSites={setSelectAll}
            selectAllSites={selectAll}
            siteCount={resourceLinkableSitesCount}
            showSelectAll={true}
            hideSubtitles={true}
          />
        </BaseModal>
      )}
      {showInfoSuccess && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(showInfoSuccess, { ns: "siteResources"})}
          variant="info"
          onClose={() => {
            setShowInfoSuccess(null);
          }}
        />
      )}
    </ContentLayout>
  );
};

export default ToolDetailView;
