import UseCase from "../../../../core/app/usecase";
import DocumentRepository from "../../../repositories/documentRepository";
import AiTask from "../../../entities/aiTask";


type DeleteAiTaskParams = {
  companyId: string;
  taskId: string,
}

/**
 * Returns the list of all the existing Document.
 */
class DeleteAiTask implements UseCase<DeleteAiTaskParams, void> {
  constructor(private repository: DocumentRepository) { }

  async Call({companyId, taskId}: DeleteAiTaskParams): Promise<void> {
    return await this.repository.deleteAiTasks(companyId, taskId);
  }
}

export default DeleteAiTask;