import UseCase from '../../../core/app/usecase';
import RequirementsGroup from '../../entities/requirementsGroup';
import RequirementsGroupRepository from '../../repositories/requirementsGroupRepository';
import UpdateReqToGroupParameters from './updateReqToGroupParameter';

class UpdateRequirementsGroup implements UseCase<UpdateReqToGroupParameters, RequirementsGroup> {
	private requirementsGroupRepository: RequirementsGroupRepository;

	constructor(requirementRepository: RequirementsGroupRepository) {
		this.requirementsGroupRepository = requirementRepository;
	}

	async Call(parameters: UpdateReqToGroupParameters) {
		return await this.requirementsGroupRepository.updateRequirementsGroup(parameters.companyId, parameters.requirementsGroup);
	}
}

export default UpdateRequirementsGroup;
