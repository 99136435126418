import File from '../../entities/file';
import UseCase from '../../../core/app/usecase';
import DocumentRepository, { GetDocumentFilesFilter } from '../../repositories/documentRepository';
import { SortMeta } from '../../entities/interfaces/paginatedResults';

interface Args {
	companyId: string;
	documentId: string;
	filter?: GetDocumentFilesFilter;
	sort?: SortMeta;
	siteId?: string;
	target?: string;
	resourceId?: string;
	pageParam?: number;
}

class GetDocumentFiles implements UseCase<Args, File[]> {
	constructor(private repository: DocumentRepository) {}

	async Call({ companyId, documentId, filter, sort, siteId, target, resourceId }: Args): Promise<File[]> {
		return await this.repository.getDocumentFiles(companyId, documentId, filter, sort, siteId, target, resourceId);
	}
}

export default GetDocumentFiles;
