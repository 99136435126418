import React, {useState} from "react";
import { Tag as ChakraTag, Text } from "@chakra-ui/react";
import {
  components,
  OptionProps,
  InputProps,
  ControlProps, SingleValueProps
} from "react-select";
import { CreatableProps } from "react-select/creatable";
import Tag from "../../../../domain/entities/tag";
import { darken } from "polished";
import  {BaseFieldProps} from "./BaseField";
import BaseSelectField from "./BaseSelectField";

export type BaseTagSelectProps<IsMulti extends boolean> = BaseFieldProps & {
  selectProps: CreatableProps<Tag, IsMulti, undefined>
};


const BaseTagSelect = ({ selectProps, ...baseProps }: BaseTagSelectProps<typeof selectProps.value extends Tag[] ? true : false>) => {
  return (
    <BaseSelectField<Tag, typeof selectProps.value extends Tag[] ? true : false>
      selectProps={{
        getOptionLabel: (option) => option.name,
        getOptionValue: (option) => option.id,
        getNewOptionData: (inputValue) => ({
          name: inputValue,
          color: "#cccccc",
          id: "new",
        }),
        styles: {
          ...selectProps.styles,
          container: (base) => ({
            ...base,
            width: "100%",
            zIndex: "99",
            ...selectProps.styles?.container,
          })
        },
        components: {
          Control,
          Input,
          Option: InputOption,
          SingleValue,
          ...selectProps.components,
        },
        ...selectProps,
      }}
      {...baseProps}
    />
  );
};

const SingleValue = ({ children, ...props }: SingleValueProps<Tag>) => (
  <components.SingleValue {...props}>
    <ChakraTag
      size="md"
      color={darken(0.6, props.data.color)}
      bg={props.data.color}
      sx={{ fontSize: "12px" }}
    >
      {props.data.name}
    </ChakraTag>
  </components.SingleValue>
);

const Control = ({
 children,
 ...props
}: ControlProps<Tag, true, undefined> & { label: string }) => (
  <components.Control {...props}>
    {props.selectProps.name && (
      <Text
        sx={{
          position: "absolute",
          top: "-10px",
          left: "8px",
          padding: "0 10px",
          backgroundColor: "white",
          color: "gray.500",
        }}
        mb={-2}
        fontSize="10px"
      >
        {props.selectProps.name}
      </Text>
    )}
    {children}
  </components.Control>
);

const Input = (props: InputProps<Tag, true>) => (
  <components.Input
    {...props}
    disabled={props.disabled || props.selectProps.isLoading}
  />
);

const InputOption = ({
 getStyles,
 isDisabled,
 isFocused,
 isSelected,
 children,
 innerProps,
 ...rest
}: OptionProps<Tag>) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };
  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={{
        ...props,
        onClick: (e) => {
          if (
            isSelected &&
            rest.selectProps.required &&
            Array.isArray(rest.selectProps.value) &&
            rest.selectProps.value.length === 1
          ) {
            return;
          }
          props.onClick?.(e);
        },
      }}
    >
      <ChakraTag
        size="md"
        color={darken(
          0.6,
          isSelected ? "#eeeeee" : rest.data.color ?? "#eeeeee"
        )}
        bg={isSelected ? "#eeeeee" : rest.data.color}
      >
        {children}
      </ChakraTag>
    </components.Option>
  );
};

export default BaseTagSelect;
