import UseCase from '../../../../core/app/usecase';
import { SiteVehicle } from '../../../entities/siteVehicle';
import SiteVehicleRepository, { GetWorkingSiteVehiclesFilters } from '../../../repositories/siteVehicleRepository';
import { PaginatedMeta, PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';

type Parameters = {
	companyId: string;
	siteId: string;
	archived?: boolean;
	filter?: GetWorkingSiteVehiclesFilters;
	sort?: SortMeta;
} & PaginatedMeta;

class GetWorkingSiteVehicles implements UseCase<Parameters, PaginatedResults<SiteVehicle> | undefined> {
	constructor(private repository: SiteVehicleRepository) {}

	async Call({ companyId, siteId, sort, perPage, archived, filter, page }: Parameters): Promise<PaginatedResults<SiteVehicle> | undefined> {
		return this.repository.getWorkingSiteVehicles(companyId, siteId, page, perPage, archived, filter, sort);
	}
}

export default GetWorkingSiteVehicles;
