import Role, { RoleContext } from '../../entities/role';
import UseCase from '../../../core/app/usecase';
import RolesRepository from '../../repositories/rolesRepository';

interface Args {
	name: string;
	companyId: string;
	context: RoleContext;
}

class CreateRole implements UseCase<Args, Role> {
	constructor(private repository: RolesRepository) {}

	async Call({ companyId, name, context }: Args): Promise<Role> {
		return await this.repository.createRole(companyId, name, context);
	}
}

export default CreateRole;
