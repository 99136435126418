import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

const ChemicalIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 23 27" {...props}>
      <path d="M21.6546 20.5272L15.0638 11.4645V2.64616H16.8867V0.823242H5.94925V2.64616H7.77216V11.4645L1.18141 20.5272C0.783859 21.0735 0.545219 21.7192 0.491893 22.3928C0.438567 23.0664 0.572634 23.7416 0.87926 24.3437C1.18589 24.9459 1.65311 25.4514 2.22925 25.8044C2.80538 26.1575 3.46795 26.3443 4.14365 26.3441H18.6914C19.3671 26.3443 20.0297 26.1575 20.6058 25.8044C21.182 25.4514 21.6492 24.9459 21.9558 24.3437C22.2624 23.7416 22.3965 23.0664 22.3432 22.3928C22.2899 21.7192 22.0512 21.0735 21.6537 20.5272H21.6546ZM9.59508 12.057V2.64616H13.2409V12.057L15.6772 15.4066H7.15875L9.59508 12.057ZM18.6923 24.5212H4.14456C3.80531 24.5208 3.47275 24.4267 3.18359 24.2493C2.89444 24.0718 2.65993 23.818 2.50596 23.5157C2.35198 23.2134 2.28452 22.8744 2.31102 22.5362C2.33752 22.198 2.45696 21.8736 2.65615 21.599L5.83349 17.2295H17.0025L20.1808 21.599C20.3799 21.8736 20.4994 22.198 20.5259 22.5362C20.5524 22.8744 20.4849 23.2134 20.3309 23.5157C20.177 23.818 19.9425 24.0718 19.6533 24.2493C19.3642 24.4267 19.0316 24.5208 18.6923 24.5212Z" />
    </Icon>
  );
};

export default ChemicalIcon;
