import { FC } from "react";
import { FiPlus } from "react-icons/fi";
import ActionBar from "../../Common/ActionBar";
import { useTranslation } from "react-i18next";
import RenderIf from "../../Permissions/RenderIf";
import ActionBarItem from "../../Common/ActionBarItem";
import { Permission } from "../../Permissions/Permissions";

interface Props {
  onAdd?: () => void;
}

const SiteListActionBar: FC<Props> = ({ onAdd }) => {
  const { t } = useTranslation("sites");

  return (
    <ActionBar>
      <RenderIf permissions={[Permission.Sites_Manage]}>
        <ActionBarItem
          description={t("actions.newSite")}
          icon={FiPlus}
          onClick={onAdd}
        />
      </RenderIf>
    </ActionBar>
  );
};

export default SiteListActionBar;
