import UseCase from '../../../core/app/usecase';
import { UserSiteRole } from '../../entities/user';
import { GetSiteRolesFilter } from '../../repositories/filters';
import RolesRepository from '../../repositories/rolesRepository';

interface Args {
	userId: string;
	companyId: string;
	filters?: GetSiteRolesFilter;
}

class GetUserSiteRoles implements UseCase<Args, Array<UserSiteRole>> {
	constructor(private repository: RolesRepository) {}

	async Call({ companyId, userId, filters }: Args): Promise<Array<UserSiteRole>> {
		return await this.repository.getUserSiteRoles(companyId, userId, filters);
	}
}

export default GetUserSiteRoles;
