import UseCase from '../../../core/app/usecase';
import Supplier from '../../entities/supplier';
import SupplierRepository from '../../repositories/supplierRepository';
import SupplierParameters from './supplierParameters';

class DeleteSupplier implements UseCase<SupplierParameters, void> {
	private supplierRepository: SupplierRepository;

	constructor(supplierRepository: SupplierRepository) {
		this.supplierRepository = supplierRepository;
	}

	async Call({ companyId, siteId, supplierId }: SupplierParameters): Promise<void> {
		await this.supplierRepository.deleteSupplier(companyId, siteId, supplierId);
	}
}

export default DeleteSupplier;
